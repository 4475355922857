<template>
  <div class="login_wrap">
    <div class="login_box">
      <div class="login_box-logo">
        <a href="javascript:void(0)">uplus 메시지허브이지</a>
        <p>Easy Messaging Service</p>
      </div>
      <!-- <div v-if="!certify" class="login_box-content">
        <h2 class="title">비밀번호 초기화 인증</h2>
        <div>
          <p class="desc">사용자 정보를 인증하여 비밀번호를 초기화 할 수 있습니다.</p>
          <input type="text" placeholder="아이디를 입력하세요." class="mb12" v-model="userId" @keypress.enter="fnCertifyPwdInit" />
          <input type="text" placeholder="‘-’ 없이 숫자만 입력하세요" class="mb20" v-model="hpNo" @keypress.enter="fnCertifyPwdInit" />
        </div>
        <button type="button" class="btn large_pink" @click="fnCertifyPwdInit">비밀번호 초기화 인증</button>
      </div> -->
      <!-- <div v-else class="login_box-content"> -->
      <div class="login_box-content">
        <h2 class="title with_desc mg10t mg40f">비밀번호 초기화</h2>
        <input type="text" v-model="userId" placeholder="아이디를 입력하세요." class="mb12" style="margin-bottom: 15px" />

        <!-- s : 비밀번호 초기화 2차 인증 개발 2022.10.20 -->
        <div class="verification1">
          <label for="smo" class="label">휴대폰 번호</label>
          <input type="text" v-model="cePhone" id="smo" placeholder="‘-’ 없이 숫자만 입력하세요." :disabled="ceNumberDiv" @keyup.enter="fnCertifyPhone" />
          <button type="button" @click="fnCertifyPhone" :disabled="ceNumberDiv">인증번호 받기</button>
        </div>
        <div class="verification2">
          <label for="snum" class="label">인증번호</label>
          <input type="text" v-model="ceNumber" id="snum" placeholder="인증번호를 입력하세요." />
          <span>{{ TimerStr }}</span>
        </div>
        <!-- e : 비밀번호 초기화 2차 인증 개발 2022.10.20 -->

        <div class="btn_group">
          <button type="button" class="btn medium_pink" @click="fnPwdInitSend">임시 비밀번호 받기</button>
          <button type="button" class="btn small_white" @click="goToLoginPages">취소</button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import confirm from "@/modules/commonUtil/service/confirm";
import { eventBus } from "@/modules/commonUtil/service/eventBus";
import loginApi from "../service/loginApi";

export default {
  name: "passWdInit",
  components: {},
  data() {
    return {
      userId: "",
      hpNo: "",
      errMsg: "",
      cePhone: "",
      ceNumber: "",
      ceNumberDiv: false,
      Timer: null,
      TimeCounter: 180,
      TimerStr: "03분00초",
      failCnt: 0,
    };
  },
  watch: {
    hpNo(val) {
      if (val !== "") {
        let regExp = /^[0-9]+$/;
        if (!regExp.test(val)) {
          confirm.fnAlert("", "휴대폰번호는 숫자만 입력 가능 합니다.", "alert");
          this.hpNo = val.replace(/[^0-9]/g, "");
        }
      }
    },
    cePhone(val) {
      if (val !== "") {
        let regExp = /^[0-9]+$/;
        if (!regExp.test(val)) {
          confirm.fnAlert("", "휴대폰번호는 숫자만 입력 가능 합니다.", "alert");
          this.cePhone = val.replace(/[^0-9]/g, "");
        }
      }
    },
    ceNumber(val) {
      if (val !== "") {
        let regExp = /^[0-9]+$/;
        if (!regExp.test(val)) {
          confirm.fnAlert("", "인증번호는 숫자만 입력 가능합니다.", "alert");
          this.ceNumber = val.replace(/[^0-9]/g, "");
        }
      }
    },
  },
  methods: {
    fnCertifyPwdInit() {
      if (this.userId === "") {
        confirm.fnAlert("", "아이디를 입력해주세요.", "alert");
        return false;
      }
      if (this.hpNo === "") {
        alert("휴대폰 번호를 입력해주세요.");
        return false;
      } else {
        let regExp = /^01([0|1|6|7|8|9])?([0-9]{3,4})?([0-9]{4})$/;
        if (!regExp.test(this.hpNo)) {
          confirm.fnAlert("", "휴대폰 번호를 올바르게 입력하여 주십시오.", "alert");
          return false;
        }
      }
      var params = {
        userId: this.userId,
        hpNo: this.hpNo,
      };

      loginApi.findCertifyPwdInit(params).then((response) => {
        var result = response.data;
        if (result.success) {
          eventBus.$on("callbackEventBus", this.fnPwdInitForm);
          // confirm.fnConfirm("인증 성공.", "인증에 성공하였습니다.", "cbAlert");
          confirm.fnConfirm("", "인증에 성공하였습니다.", "cbAlert");
        } else {
          // confirm.fnAlert("인증 오류.", "입력하신 정보를 가진 사용자 없습니다.", "alert");
          confirm.fnAlert("", "입력하신 정보를 가진 사용자 없습니다.", "alert");
          return false;
        }
      });
    },
    goToLoginPages() {
      this.$router.push({ path: "/login" });
    },
    fnPwdInitForm() {
      this.certify = true;
    },
    fnPwdInitSend() {
      if (this.TimeCounter == 0) {
        this.ceNumberDiv = false;
        this.ceNumber = "";
        this.TimeCounter = 180;
        this.TimerStr = "03분00초";
        confirm.fnAlert("", "인증번호를 입력할 수 있는 시간이 지났습니다. <br /> 인증번호를 다시 받아주세요.", "alert");
        return false;
      }

      if (!this.ceNumberDiv) {
        confirm.fnAlert("", "인증번호 받기를 눌러주세요.", "alert");
        return false;
      }
      if (this.ceNumber == "") {
        //confirm.fnAlert("", "인증번호를 입력하세요.", "alert");
        confirm.fnAlert("", "인증번호를 입력해 주세요.", "alert");
        return false;
      }

      let regExp = /^[0-9]+$/;
      if (!regExp.test(this.ceNumber)) {
        confirm.fnAlert("", "인증번호는 숫자만 입력 가능합니다.", "alert");
        return false;
      }

      var params = {
        userId: this.userId,
        hpNo: this.cePhone,
        certifyNum: this.ceNumber,
      };
      loginApi
        .resetPwdExec(params)
        .then((response) => {
          var result = response.data;
          if (result.success) {
            eventBus.$on("callbackEventBus", this.goToLoginPages);
            confirm.fnConfirm("", "아이디에 등록된 휴대폰 번호로 임시 비밀번호가 <br />적힌 문자메시지를 보냈습니다.", "cbAlert");
          } else {
            confirm.fnAlert("", result.message, "alert");
            return false;
          }
        })
        .catch((error) => {
          confirm.fnAlert("", "오류가 발생하였습니다.", "alert");
          console.log(error);
        });
    },
    fnCertifyPhone() {
      if (this.cePhone == "") {
        confirm.fnAlert("", "인증받을 휴대폰 번호를 입력하여 주십시오.", "alert");
        return false;
      } else {
        let regExp = /^01([0|1|6|7|8|9])?([0-9]{3,4})?([0-9]{4})$/;
        if (!regExp.test(this.cePhone)) {
          confirm.fnAlert("", "휴대폰 번호를 확인해 주세요. <br/> 아이디에 등록된 휴대폰 번호만 인증이 가능합니다.", "alert");
          return false;
        }
      }
      this.ceNumberDiv = true;
      //this.fnTimerStart();
      //confirm.fnAlert("", "인증번호를 문자메시지로 보냈습니다. 테스트!", "alert");
      const parmas = {
        userId: this.userId,
        hpNo: this.cePhone,
      };

      loginApi
        .pwInitIdNumberCheck(parmas)
        .then((response) => {
          const result = response.data;

          if (result.success) {
            eventBus.$on("callbackEventBus", () => {
              document.getElementById("snum").focus();
            });
            confirm.fnConfirm("", "인증번호를 문자메시지로 보냈습니다.", "cbAlert");
            this.fnTimerStart();
          } else {
            this.ceNumberDiv = false;
            confirm.fnAlert("", result.message, "alert");
            return false;
          }
        })
        .catch((error) => {
          this.ceNumberDiv = false;
          confirm.fnAlert("", "오류가 발생하였습니다.", "alert");
          console.log(error);
        });
    },
    fnTimerStart() {
      this.TimerStr = "03분00초";
      // 1초에 한번씩 start 호출
      this.TimeCounter = 180;
      var interval = setInterval(() => {
        this.TimeCounter--; //1초씩 감소
        this.TimerStr = this.fnPrettyTime();
        if (this.TimeCounter <= 0) this.fnTimerStop(interval);
      }, 1000);
      return interval;
    },
    fnTimerStop(Timer) {
      clearInterval(Timer);
      this.TimeCounter = 0;
      this.TimerStr = "시간초과!";
      this.ceNumberDiv = false;
    },
    fnPrettyTime() {
      // 시간 형식으로 변환 리턴
      let time = this.TimeCounter / 60;
      let minutes = parseInt(time);
      let secondes = Math.round((time - minutes) * 60);
      return minutes.toString().padStart(2, "0") + "분" + secondes.toString().padStart(2, "0") + "초";
    },
  },
};
</script>
