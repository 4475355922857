<template>
  <Fragment>
    <div class="loadingDimmed" v-if="loading"></div>
    <div class="sp-3balls" v-if="loading"></div>
  </Fragment>
</template>

<script>
import { Fragment } from "vue-frag";
export default {
  name: "LoadingSpinner",
  props: {
    loading: {
      type: Boolean,
      required: true,
    },
  },
  components: {
    Fragment,
  },
};
</script>

<style scoped>
.loadingDimmed {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.2);
  z-index: 400;
}
.sp-3balls,
.sp-3balls:before,
.sp-3balls:after {
  display: inline-block;
  width: 18px;
  height: 18px;
  background: #946dc2;
  border-radius: 100%;
}
.sp-3balls {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1000;
  opacity: 1;
  animation: spScaleAlpha 2s infinite linear;
}
.sp-3balls:before,
.sp-3balls:after {
  content: "";
  position: absolute;
  opacity: 0.25;
}
.sp-3balls:before {
  right: 30px;
  animation: spScaleAlphaBefore 2s infinite linear;
}
.sp-3balls:after {
  left: 30px;
  animation: spScaleAlphaAfter 2s infinite linear;
}

@keyframes spScaleAlpha {
  0% {
    opacity: 1;
  }
  33% {
    opacity: 0.25;
  }
  66% {
    opacity: 0.25;
  }
  100% {
    opacity: 1;
  }
}

@keyframes spScaleAlphaBefore {
  33% {
    opacity: 0.25;
  }
  66% {
    opacity: 1;
  }
  100% {
    opacity: 0.25;
  }
}
@keyframes spScaleAlphaAfter {
  0% {
    opacity: 0.25;
  }
  33% {
    opacity: 1;
  }
  66% {
    opacity: 0.25;
  }
}
</style>
