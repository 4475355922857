<template>
  <div class="popup adpop">
    <div class="pop-cont">
      <p class="pop-tit tl">광고성 문자 전송시 표기 의무 안내</p>
      <p class="pop-desc dot">정보통신망 이용촉진 및 정보보호 등에 관한 법률 제50조(영리목적의 광고성 정보 전송 제한)에 따라 광고성 문자 표기 의무사항을 준수하시기 바랍니다.</p>
      <div class="scr_wrap">
        <div class="top_cont">
          <div class="img"><img src="../../../assets/images/ad_pop.png" alt="" /></div>
          <div class="txt">
            <p class="mtxt">광고성 문자 표기 의무사항</p>
            <p class="dot">광고성 정보가 시작되는 부분에 (광고) 표시</p>
            <p class="dot">LMS/MMS의 경우 제목 앞부분에 (광고)표시 필수</p>
            <p class="dot">광고 전송자의 명칭, 연락처표시</p>
            <p class="dot">정보 끝 부분에 무료거부 080XXXXXXXX 표시</p>
          </div>
        </div>
        <div class="bottom_cont">
          <p class="pop-tit">표시의무 위반사례</p>
          <div class="table_wrap">
            <table>
              <colgroup>
                <col width="33.333%" />
                <col width="33.333%" />
                <col width="33.333%" />
              </colgroup>
              <tr>
                <th>표기의무 위반문자</th>
                <th>위반내용</th>
                <th class="pink">올바른 광고성 문자</th>
              </tr>
              <tr>
                <td>
                  <div class="display">
                    <p>[web 발신]<br />(광고)[00]003_설PC방버님<br />_최대.월!전!지급<br />☞http://aaa.com/bb<br /><span class="pink">수신거부 </span>(통화)</p>
                  </div>
                </td>
                <td>
                  <p class="dot">‘무료거부’미표기<br />※ ‘통화’ 표시 있는 경우 본문에 080번호 생략 가능</p>
                </td>
                <td>
                  <div class="display">
                    <p>[web 발신]<br />(광고)[00]003_설PC방버님<br />_최대.월!전!지급<br />☞http://aaa.com/bb<br /><span class="pink">무료거부 </span>(통화)</p>
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  <div class="display">
                    <p>[web 발신]<br /><span class="pink">광고)</span>00대리<br />☎1234-1234<br />전화주세요.<br /><span class="pink">철회 </span>0800330338</p>
                  </div>
                </td>
                <td>
                  <p class="dot">‘(광고)’표기오류 :<br />※ (광고) (O), 광고) (X)</p>
                  <p class="dot">‘무료거부’ 미표기</p>
                </td>
                <td>
                  <div class="display">
                    <p>[web 발신]<br /><span class="pink">(광고)</span>00대리<br />☎1234-1234<br />전화주세요.<br /><span class="pink">무료거부 </span>0800330338</p>
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  <div class="display">
                    <p>[web 발신]<br />(광고)00홈쇼핑-00홍삼진<br />30% 최대 할인전!<br />지금방송중!<br /><span class="pink">수신거부 </span>080-868-7700</p>
                  </div>
                </td>
                <td>
                  <p class="dot">‘무료거부’ 미표기</p>
                </td>
                <td>
                  <div class="display">
                    <p>[web 발신]<br />(광고)00홈쇼핑-00홍삼진<br />30% 최대 할인전!<br />지금방송중!<br /><span class="pink">무료거부 </span>080-868-7700</p>
                  </div>
                </td>
              </tr>
            </table>
          </div>
        </div>
      </div>
    </div>
    <div class="pop-btn1">
      <button type="button" class="btn large_pink" @click="ModalClose()">확인</button>
    </div>
  </div>
</template>
<script>
export default {
  name: "adMessagePopup",
  components: {},
  data() {
    return {
      example: "",
    };
  },
  methods: {
    ModalClose() {
      var dimmed = document.getElementsByClassName("dimmed");
      var wrap = document.getElementsByClassName("popup-wrap");
      var obj = wrap[0].childElementCount;
      dimmed[0].style.display = "none";
      wrap[0].style.display = "none";
      for (var i = 0; i < obj; i++) {
        var target = document.getElementsByClassName("popup");
        target[i].style.display = "none";
        target[i].classList.remove("open");
      }
    },
  },
};
</script>
