<template>
  <!--	s: 	contents-->
  <div class="contents">
    <div style="display: flex; flex-direction: column; height: 70vh">
      <div class="tabcont_wrap without_tab inquiry_member">
        <h1 class="title with_desc">휴대폰 본인인증</h1>
        <div class="tabcontTop">
          <ul>
            <li>
              인증 후 결과값은 내부 설정에 따른 값만 리턴받으실 수 있습니다. <br />
              <span class="tabcontTop_extra_desc">일부 결과값이 null로 리턴되는 경우 관리담당자 또는<br />계약부서(02-2122-4615)로 문의바랍니다.</span>
            </li>
          </ul>
        </div>
        <div class="box_wrap email_wrap">
          <div class="box_top">
            <h3 class="tit required">인증 결과</h3>
            <div class="top_right addtooltip">
              <!-- <span class="tooltip_explosion">※ 입력하신 이메일 주소로 답변을 보내 드립니다.</span> -->
              <p>{{ sMessage }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!--	e: 	contents	-->
  <!-- <div>
    <p>{{ sMessage }}</p>
    <table border="1">
     <tr>
        <td>복호화한 시간</td>
        <td>{{ sCipherTime }} (YYMMDDHHMMSS)</td>
      </tr>
      <tr>
        <td>요청 번호</td>
        <td>{{ sRequestNumber }}</td>
      </tr>
      <tr>
        <td>NICE응답 번호</td>
        <td>{{ sResponseNumber }}</td>
      </tr>
      <tr>
        <td>인증수단</td>
        <td>{{ sAuthType }}</td>
      </tr>
      <tr>
        <td>성명</td>
        <td>{{ sName }}</td>
      </tr>
      <tr>
        <td>중복가입 확인값(DI)</td>
        <td>{{ sDupInfo }}</td>
      </tr>
      <tr>
        <td>연계정보 확인값(CI)</td>
        <td>{{ sConnInfo }}</td>
      </tr>
      <tr>
        <td>생년월일(YYYYMMDD)</td>
        <td>{{ sBirthDate }}</td>
      </tr>
      <tr>
        <td>성별</td>
        <td>{{ sGender }}</td>
      </tr>
      <tr>
        <td>내/외국인정보</td>
        <td>{{ sNationalInfo }}</td>
      </tr>
      <tr>
        <td>휴대폰번호</td>
        <td>{{ sMobileNo }}</td>
      </tr>
      <tr>
        <td>통신사</td>
        <td>{{ sMobileCo }}</td>
      </tr> 

      <tr>
        <td colspan="2">
          인증 후 결과값은 내부 설정에 따른 값만 리턴받으실 수 있습니다.
          <br />
          일부 결과값이 null로 리턴되는 경우 관리담당자 또는 계약부서(02-2122-4615)로 문의바랍니다.
        </td>
      </tr>
    </table>
  </div> -->
</template>
<script>
import sendNumberApi from "@/modules/sendNumberManage/service/sendNumberApi";

export default {
  props: {
    EncodeData: String,
    //require: true,
  },
  data() {
    return {
      /* sCipherTime: "",
      sRequestNumber: "",
      sResponseNumber: "",
      sAuthType: "",
      sDupInfo: "",
      sConnInfo: "",
      sBirthDate: "",
      sGender: "",
      sNationalInfo: "",
      sMobileCo: "", */
      sMobileNo: "",
      sName: "",
      responseData: "",
      sMessage: "",
    };
  },
  mounted() {
    this.fnGetNiceCheckSuccess();
  },
  methods: {
    fnGetNiceCheckSuccess() {
      /* let params = {
        projectId: window.opener.document.getElementById("projectId").value,
        EncodeData: this.EncodeData,
      }; */
      //console.log(this.$router.history.current.query.EncodeData);
      const params = {
        EncodeData: this.$router.history.current.query.EncodeData,
      };

      sendNumberApi.getNiceCheckSuccess(params).then((response) => {
        const result = response.data;
        //console.log(response);
        if (result.success) {
          this.sMessage = result.message;
          this.responseData = result.data;
          const data = this.responseData.split(":");
          this.sMobileNo = data[data.findIndex((v) => v.indexOf("MOBILE_NO") > -1) + 1].substring(0, data[data.findIndex((v) => v.indexOf("MOBILE_NO") > -1)].replace(/[^0-9]/g, ""));
          this.sName = data[data.findIndex((v) => v.indexOf("NAME") > -1) + 1].replace(/[0-9]/g, "");

          /* window.opener.document.getElementById("gender").value = this.sGender;
          window.opener.document.getElementById("coInfo").value = this.sConnInfo; */
          if (navigator.userAgent.indexOf("Android") > -1 || navigator.userAgent.indexOf("iPhone") > -1) {
            // 스마트폰인 경우
            opener.document.getElementById("phone").value = this.sMobileNo;
            opener.document.getElementById("name").value = this.sName;
            opener.document.getElementById("message").value = this.sMessage;
            opener.document.getElementById("responseData").value = this.responseData;
            opener.document.getElementById("nice").click();
            self.close();
          } else {
            // 스마트폰 아닐때
            window.opener.document.getElementById("phone").value = this.sMobileNo;
            window.opener.document.getElementById("name").value = this.sName;
            window.opener.document.getElementById("message").value = this.sMessage;
            window.opener.document.getElementById("responseData").value = this.responseData;
            window.opener.document.getElementById("nice").click();
            self.close();
          }
        } else {
          window.opener.document.getElementById("message").value = "본인인증 실패";
          self.close();
          //eventBus.$on('callbackEventBus', this.fnClosePopup);
          //confirm.fnAlert('본인인증 실패', result.message+'\n다시 시도하여주세요.', "CALLBACK", null);
        }
      });
    },
  },
};
</script>
