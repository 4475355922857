<template>
  <div class="login_wrap">
    <div class="login_box">
      <div class="login_box-logo">
        <a href="javascript:void(0)">uplus 메시지허브이지</a>
        <p>Easy Messaging Service</p>
      </div>
      <div v-if="findedLoginId != ''" class="login_box-content">
        <h2 class="title">아이디 찾기</h2>
        <div class="id_info">
          고객님의 아이디는 <span class="info_content">{{ findedLoginId }}</span> 입니다.
          <p class="info_desc">※개인 정보 보호를 위해 아이디 앞 3자리는 *로 표시합니다.</p>
        </div>
        <button type="button" class="btn large_pink" @click="fnGoLogin">로그인</button>
      </div>
      <div v-else class="login_box-content">
        <h2 class="title with_desc">아이디 찾기</h2>
        <p class="desc">가입할 때 등록한 이름과 휴대폰 번호로 아이디를 찾을 수 있습니다.</p>
        <label for="smo" class="label">이름</label>
        <input type="text" v-model="findUserName" id="smo" placeholder="이름을 입력하세요." @keypress.enter="fnFindLoginId()" />
        <label for="snum" class="label mg12t">휴대폰 번호</label>
        <input type="text" v-model="findHpNumber" ref="hpRef" name="phone" id="snum" placeholder="‘-’ 없이 숫자만 입력하세요." @keypress.enter="fnFindLoginId" />
        <button type="button" class="btn large_pink mg20t" @click="fnFindLoginId">아이디 찾기</button>
      </div>
    </div>
  </div>
</template>
<script>
import loginApi from "../service/loginApi";
import confirm from "@/modules/commonUtil/service/confirm";
import { eventBus } from "@/modules/commonUtil/service/eventBus";

export default {
  name: "defaultName",
  components: {},
  data() {
    return {
      findUserName: "",
      findHpNumber: "",
      findedLoginId: "",
      loginUrl: "/login",
    };
  },
  watch: {
    findHpNumber(val) {
      if (val !== "") {
        let regExp = /^[0-9]+$/;
        if (!regExp.test(val)) {
          this.$refs.hpRef.blur();
          eventBus.$on("callbackEventBus", () => {
            this.$refs.hpRef.focus();
          });
          confirm.fnConfirm("", "휴대폰번호는 숫자만 입력 가능 합니다.", "cbAlert");
          this.findHpNumber = val.replace(/[^0-9]/g, "");
        }
      }
    },
  },
  methods: {
    fnFindLoginId() {
      this.findedLoginId = "";
      this.errMsg = "";
      if (this.findUserName === "") {
        confirm.fnAlert("", "이름과 휴대폰 번호를 모두 입력해 주세요.", "alert");
        return;
      } else if (this.findHpNumber === "") {
        confirm.fnAlert("", "이름과 휴대폰 번호를 모두 입력해 주세요.", "alert");
        return;
      }

      var params = {
        userNm: this.findUserName,
        hpNo: this.$gfnCommonUtils.hpNumberRemoveDash(this.findHpNumber),
      };
      loginApi
        .findLoginId(params)
        .then((response) => {
          var result = response.data;
          this.data = response.data.data;
          if (result.success) {
            this.findedLoginId = result.data;
          } else {
            confirm.fnAlert("", result.message, "alert");
            return;
          }
        })
        .catch(() => {
          confirm.fnAlert("", "오류가 발생하였습니다.", "alert");
          //console.log(error);
        });
    },
    fnGoLogin() {
      this.$router.push({ path: "/login" });
    },
  },
};
</script>
