<template>
  <!--	s: m_section	-->
  <section class="m_section">
    <!--	s: 	contents-->
    <div class="contents">
      <MobileGnb />
      <div class="tabcont_wrap without_tab caller_wrap">
        <h1 class="title with_desc">문자메시지</h1>
        <div class="tabcontTop">
          <ul>
            <li>
              발신번호의 명의자가 필요 서류를 제출해야 발신번호 등록을 심사할 수 있습니다. <br />심사 결과에 따라 등록이 승인 또는 거절될 수 있습니다. <br /><span
                >※ 명의자는 사업자(사업자/대표/임직원) 또는 타 사업자여야 합니다.</span
              >
            </li>
            <li>관리자 아이디만 발신번호를 등록, 수정, 삭제할 수 있습니다.</li>
            <li>다른 통신사의 번호를 발신번호로 등록하려면 ‘통신서비스 이용증명원’을 제출해야 합니다. <br />LG유플러스 번호는 제출안하셔도 됩니다.</li>
            <li>필요 서류는 U+기업메시징 고객센터 1544-5992(유료)로 문의해 주세요. <br /><span class="tabcontTop_extra_desc">상담 가능 시간 : 평일 오전 9시~오후 6시, 공휴일과 주말 제외</span></li>
            <li>사업자번호가 동일한 발신번호는 해당 사업자번호를 이용하는 모든 사용자가 쓸 수 있습니다.</li>
            <li>차단상태의 발신번호는 메시지를 발송할 수 없습니다.</li>
          </ul>
        </div>
        <form @submit.prevent="" autocomplete="off" class="search_form">
          <div class="search_box caller">
            <div class="group group01">
              <div class="select_wrap">
                <select name="" id="" class="select">
                  <option value="01" selected>발신번호</option>
                </select>
              </div>
            </div>
            <div class="group group02">
              <input type="text" v-model="srchSndrno" class="search2" placeholder="검색어를 입력하세요." />
            </div>
            <button @click="getSendNumberIdList()" class="btn">조회</button>
          </div>
        </form>
        <div class="list_area">
          <div class="list_info">
            <div class="left_wrap">
              <div class="select_wrap">
                <select v-model="selected" @change="fnSelected" class="select">
                  <option value="30" selected>30</option>
                  <option value="50">50</option>
                  <option value="100">100</option>
                </select>
              </div>
              <p class="total">
                총 <span class="pink">{{ totCnt }} </span>건
              </p>
            </div>
            <div class="right_wrap">
              <router-link v-if="loginDiv && userDiv" class="btn plus" to="/sendNumberManage/sendNumberReg">발신번호 등록</router-link>
              <a href="#" v-else class="btn plus" @click="fnCantReg" rel="opener">발신번호 등록</a>
            </div>
          </div>

          <div class="fixed-table-cover">
            <div class="fixed-table-container" style="width: 1074px">
              <div class="fixed-table-header-bg"></div>
              <div class="fixed-table-wrapper">
                <table class="fixed-table" style="table-layout: fixed">
                  <thead>
                    <tr>
                      <th class="td_chk" style="width: 40px">
                        <div class="th-text">
                          <input type="checkbox" class="chk_all" id="chk_all" v-model="allChecked" :disabled="sendNumberList.length === 0" @click="checkedAll($event.target.checked)" />
                          <label for="chk_all"></label>
                        </div>
                      </th>
                      <th class="td_name" style="width: 20%">
                        <div class="th-text">발신번호 명</div>
                      </th>
                      <th class="td_type" style="width: 10%">
                        <div class="th-text">명의자 구분</div>
                      </th>
                      <th class="td_number" style="width: 20%">
                        <div class="th-text">발신번호</div>
                      </th>
                      <th class="td_state" style="width: 15%">
                        <div class="th-text">차단상태(차단사유)</div>
                      </th>
                      <th class="td_state" style="width: 15%">
                        <div class="th-text">승인상태</div>
                      </th>
                      <th class="td_date" style="width: calc(20% - 40px)">
                        <div class="th-text">승인일</div>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-if="this.sendNumberList.length == 0 && this.srchSndrno == ''" class="empty">
                      <td colspan="5">데이터가 없습니다.</td>
                    </tr>
                    <tr v-if="this.sendNumberList.length == 0 && this.srchSndrno != ''" class="empty">
                      <td colspan="5">검색 결과가 없습니다.</td>
                    </tr>
                    <tr v-for="(data, index) in this.sendNumberList" :key="index">
                      <td class="td_chk">
                        <input type="checkbox" class="chk" :id="'check_' + index" :value="index" v-model="data.selected" @change="selectedChk()" />
                        <label :for="'check_' + index"></label>
                      </td>
                      <td class="td_name">{{ data.sndrnoNm }}</td>
                      <td class="td_type">{{ data.nmineeDivCdNm }}</td>
                      <td class="td_number">{{ data.sndrno }}</td>
                      <td class="td_state">{{ data.blckYn === "Y" ? (data.blckRsnNm ? `차단(${data.blckRsnNm})` : "-") : "사용" }}</td>
                      <td class="td_state">{{ data.sttusNm }}</td>
                      <td class="td_date">{{ data.cmpltDt }}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>

          <!--
          <div class="list_cover">
            <div class="list_th_wrap" @scroll="handleThScroll($event)">
              <ul class="list_th">
                <li class="td_chk w40">
                  <input type="checkbox" class="chk_all" id="chk_all" v-model="allChecked" :disabled="sendNumberList.length === 0" @click="checkedAll($event.target.checked)" />
                  <label for="chk_all"></label>
                </li>
                <li class="td_name w206"><span>발신번호 명</span></li>
                <li class="td_type w206"><span>명의자 구분</span></li>
                <li class="td_number w206"><span>발신번호</span></li>
                <li class="td_state w206"><span>승인상태</span></li>
                <li class="td_date w206"><span>승인일</span></li>
              </ul>
            </div>
            <div class="list_wrap scr_wrap" @scroll="handleScroll($event)">
              <ul class="list">
                <li v-for="(data, index) in this.sendNumberList" :key="index">
                  <span class="td_chk w40">
                    <input type="checkbox" class="chk" :id="'check_' + index" :value="index" v-model="data.selected" @change="selectedChk()" />
                    <label :for="'check_' + index"></label>
                  </span>
                  <span class="td_name w206">{{ data.sndrnoNm }}</span>
                  <span class="td_type w206">{{ data.nmineeDivCdNm }}</span>
                  <span class="td_number w206">{{ data.sndrno }}</span> -->
          <!-- 승인상태 상태값에 따라 세분화 추가 필요 Start -->
          <!-- <span class="td_state w206">{{ data.sttusNm }}</span> -->
          <!-- 승인상태 상태값에 따라 세분화 추가 필요 End -->
          <!-- <span class="td_date w206">{{ data.cmpltDt }}</span> -->
          <!-- 기획 삭제 <span v-if="data.useYn === 'Y'" class="td_state w20p40m">사용</span>
                      <span v-else class="td_state w206">미사용</span> -->
          <!-- </li> 

                <li v-if="this.sendNumberList.length == 0 && this.srchSndrno == ''" class="empty">
                  <span class="w100p">데이터가 없습니다.</span>
                </li>

                <li v-if="this.sendNumberList.length == 0 && this.srchSndrno != ''" class="empty">
                  <span class="w100p">검색 결과가 없습니다.</span>
                </li>
              </ul>
            </div>
          </div>
        -->
          <div class="list_bottom">
            <button class="btn white small" v-if="loginDiv && userDiv" type="button" @click="confirmDeleteSndrno()">삭제</button>
          </div>

          <div class="paging_wrap" v-show="totCnt > listSize">
            <PageLayer ref="updatePaging" @fnClick="fnSearch" :listTotalCnt="totCnt" :selected="listSize" :pageNum="pageNo"></PageLayer>
          </div>
        </div>
      </div>
    </div>
    <!--	e: 	contents	-->
  </section>
  <!--	e: m_section	-->
</template>
<script>
import sendNumberApi from "../service/sendNumberApi";
import store from "@/store";
import PageLayer from "@/components/PageLayer.vue";
import confirm from "@/modules/commonUtil/service/confirm";
import { eventBus } from "@/modules/commonUtil/service/eventBus";
import MobileGnb from "@/components/MobileGnb.vue";

export default {
  name: "defaultName",
  components: {
    PageLayer,
    MobileGnb,
  },
  data() {
    return {
      example: "",
      loginDiv: false,
      userDiv: false,
      subSttusCd: "",
      sendNumberList: [
        {
          selected: false,
          sndrnoNm: "",
          nmineeDivCd: "",
          nmineeDivCdNm: "",
          sndrno: "",
          sttusCd: "",
          sttusNm: "",
          cmpltDt: "",
          regDt: "",
          custSeq: "",
        },
      ],
      srchType: "01",
      listSize: 30, // select 박스 value (출력 갯수 이벤트)
      pageNo: 1, // 현재 페이징 위치
      totCnt: 0, //전체 리스트 수
      offset: 0, //페이지 시작점
      selected: "30",
      srchSndrno: "",
      sndrno: "",
      allChecked: false,
      useYn: "",
      updateData: {},
    };
  },
  created() {
    this.fnInit();
  },
  mounted() {},
  watch: {
    srchSndrno(val) {
      const reg = /[ㄱ-ㅎ|ㅏ-ㅣ|가-힣|a-zA-Z|~/',.;@#$%^&*()-_+|<>?:{}]/;
      // 한글, 영문 체크
      if (reg.exec(val) !== null) this.srchSndrno = val.replace(/[^0-9]/g, "");
      // .... 만 입력하게 될 경우 체크
      if (isNaN(parseFloat(val))) this.srchSndrno = "";
    },
  },
  methods: {
    fnInit() {
      const isAuth = store.getters["login/isAuth"];
      if (isAuth) {
        this.loginDiv = true;
        sendNumberApi.selectAutInfoChnnel().then((response) => {
          var result = response.data;
          if (result.success) {
            this.autCd = result.data.autCd;
            this.subSttusCd = result.data.subSttusCd;
            //not 사용자아이디 비로그인 임시개통 비즈마켓 개통
            if (this.autCd !== "H002" && this.subSttusCd !== "00" && this.subSttusCd !== "01") {
              this.userDiv = true;
            }
          }
        });
      } else {
        this.autCd == "H003";
      }
      this.getSendNumberIdList();
    },
    getSendNumberIdList() {
      if (this.loginDiv) {
        this.allChecked = false;
        //this.pageNo = "1";
        let params = {
          listSize: this.listSize, // select 박스 value (출력 갯수 이벤트)
          pageNo: this.pageNo, // 현재 페이징 위치
          totCnt: this.totCnt, //전체 리스트 수
          offset: this.offset, //페이지 시작점
          srchSndrno: this.srchSndrno,
          sttusCd: this.sttusCd,
        };

        sendNumberApi.getSendNumberIdList(params).then((response) => {
          const result = response.data;

          if (result.success) {
            this.sendNumberList = response.data.data.map((e) => {
              return {
                ...e,
                selected: false,
              };
            });
            this.totCnt = response.data.pageInfo.totCnt;
          }
        });
      }
    },
    //체크박스 전체 선택 기능
    checkedAll(checked) {
      this.allChecked = checked;

      for (let i in this.sendNumberList) {
        this.sendNumberList[i].selected = this.allChecked;
      }
    },
    //체크박스 단일 체크
    selectedChk() {
      if (this.sendNumberList.every((item) => item.selected == true)) {
        this.allChecked = "checked";
      } else {
        this.allChecked = "";
      }
    },
    fnSelected() {
      this.listSize = Number(this.selected);
      this.$refs.updatePaging.fnAllDecrease();
    },
    fnSearch(pageNum) {
      //this.$refs.updatePaging.fnAllDecrease();
      this.pageNo = this.$gfnCommonUtils.defaultIfEmpty(pageNum, "1") * 1;
      this.getSendNumberIdList();
    },
    updateSndrno(useYn) {
      // 선택한 발신 번호 사용, 미사용 업데이트
      this.updateData = this.sendNumberList
        .filter((e) => {
          return e.selected === true;
        })
        .map((e) => {
          return {
            ...e,
            useYn,
          };
        });

      // 미체크
      if (this.updateData.length === 0) {
        confirm.fnAlert("", "삭제할 발신번호를 먼저 선택해 주세요.", "alert");
        return;
      }

      sendNumberApi.updateSendNumber(this.updateData).then((response) => {
        const result = response.data;

        if (result.success) {
          confirm.fnAlert("사용 정보가 업데이트 되었습니다.");
          this.getSendNumberIdList();
        } else {
          confirm.fnAlert("사용 정보 업데이트에 실패하였습니다.");
        }
      });
    },
    confirmDeleteSndrno() {
      // 선택한 발신 번호 삭제
      this.updateData = this.sendNumberList
        .filter((e) => {
          return e.selected === true;
        })
        .map((e) => {
          return {
            ...e,
          };
        });

      // 관리자 아이디인지 확인 : 관리자 아이디만 삭제 가능
      if (this.autCd == "H002") {
        confirm.fnAlert("", "발신번호 등록, 삭제는 관리자 ID에서만 가능합니다.", "alert");
        return;
      }

      if (this.autCd == "H003") {
        confirm.fnAlert("", "로그인 후 이용할 수 있습니다.", "alert");
        return;
      }

      if (this.subSttusCd === "00" || this.subSttusCd === "01") {
        confirm.fnAlert("", "가입절차 확인이 완료된 후 발신번호 등록이 가능합니다.", "alert");
        return;
      }

      // 미체크
      if (this.updateData.length === 0) {
        confirm.fnAlert("", "삭제할 발신번호를 먼저 선택해 주세요.", "alert");
        return;
      }

      eventBus.$on("callbackEventBus", this.deleteSndrno);
      confirm.fnConfirm("", "선택한 발신번호를 삭제하시겠어요?", "del");
    },

    deleteSndrno() {
      sendNumberApi.deleteSendNumber(this.updateData).then((response) => {
        const result = response.data;

        if (result.success) {
          confirm.fnAlert("", result.message, "alert");
          this.getSendNumberIdList();
        } else {
          confirm.fnAlert("", result.message, "alert");
        }
      });
    },
    fnCantReg() {
      if (!this.loginDiv) {
        confirm.fnAlert("", "로그인 후 이용할 수 있습니다.", "alert");
        return;
      }
      if (this.autCd == "H003") {
        confirm.fnAlert("", "로그인 후 이용할 수 있습니다.", "alert");
      }
      if (!this.userDiv && this.autCd == "H002") {
        confirm.fnAlert("", "관리자 아이디만 발신번호를 등록 할 수 있습니다.", "alert");
      }
      if ((!this.userDiv && this.subSttusCd === "00") || this.subSttusCd === "01") {
        confirm.fnAlert("", "가입절차 확인이 완료된 후 발신번호 등록이 가능합니다.", "alert");
        return;
      }
    },
    /* handleScroll(e) {
      const chooseListThWrap = e.target.parentNode.children[0];
      chooseListThWrap.scrollLeft = e.target.scrollLeft;
    },
    handleThScroll(e) {
      const chooseListWrap = e.target.parentNode.children[1];
      chooseListWrap.scrollLeft = e.target.scrollLeft;
    }, */
  },
};
</script>
