<template>
  <div>
    <div class="popup large address">
      <div class="pop-head">
        <h3 class="pop-tit">주소록에서 추가하기</h3>
      </div>
      <div class="tab_addr" v-if="!isMobile">
        <input id="addr_P" type="radio" name="tab_item" checked>
        <label class="tab_item" for="addr_P" @click="fnSendAddrInit">개인 주소록</label>
        <input id="addr_G" type="radio" name="tab_item">
        <label class="tab_item" for="addr_G" @click="fnSendAddrPubInit">공유 주소록</label>
        <label class="tab_item" for="addr_1"></label>
        <label class="tab_item" for="addr_2"></label>
      </div>
      <div v-if="!isMobile" class="search_box">
        <h3>간편등록</h3>
        <div class="group group01">
          <label for="right" class="label">그룹명</label>
          <div class="select_wrap">
            <select class="select" v-model="selRegAddrInfo">
              <option value="A" selected >그룹 선택</option>
              <option value="N" v-if="addrDiv=='P'">'그룹 없음'에 등록</option>
              <option v-for="(regSelAddr, index) in regAddrGrpSelList" :key="index" :value="regSelAddr">
                {{ regSelAddr.groupNm }}
              </option>
            </select>
          </div>
        </div>
        <div class="group group02">
          <label for="right" class="label">휴대폰번호</label>
          <input type="text" v-model="phone" class="input" placeholder="휴대폰 번호 입력 (숫자만 입력)" />
        </div>
        <div class="group group03">
          <label for="right" class="label">이름</label>
          <input type="text" v-model="name" class="input" placeholder="이름 입력" />
        </div>
        <div class="group group04">
          <label for="right" class="label">비고</label>
          <input type="text" v-model="memo" class="input" placeholder="비고 입력 (최대 50byte)" />
        </div>
        <button class="btn plus" :disabled="!(phone != '' && loginDiv)" @click="fnSaveAddrMember">추가</button>
      </div>

      <div class="pop-cont">
        <div class="group_wrap">
          <div class="tab_addr_mobi" v-if="isMobile">
            <input id="addr_P" type="radio" name="tab_item" checked>
            <label class="tab_item_mobi" for="addr_P" @click="fnSendAddrInit">개인 주소록</label>
            <input id="addr_G" type="radio" name="tab_item">
            <label class="tab_item_mobi" for="addr_G" @click="fnSendAddrPubInit">공유 주소록</label>
          </div>
          <div class="group_top1">
            <button class="btn plus" :class="!loginDiv ? 'disabled' : ''" type="button" @click="ModalOpen()">대량 등록</button>
          </div>
          <div class="group_top">
            <div class="search_wrap">
              <div class="search_input">
                <input type="text" class="search" v-model="srchGroupNm" placeholder="그룹명을 입력하세요." />
                <button class="btn_search" @click="fnSrchAddrGroup"></button>
              </div>
            </div>
          </div>
          <div class="group">
            <div class="group_add_wrap">
              <input type="text" class="input" placeholder="그룹명 입력" v-model="groupNm" />
              <button class="btn plus" v-if="loginDiv" @click="fnSaveAddrGroup">추가</button>
              <button class="btn plus" v-else @click="fnCantReg" :disabled="!loginDiv">추가</button>
            </div>
            <div class="group_list scr_wrap">
              <ul class="file_depth1">
                <li class="has_depth" :class="{ on: active }">
                  <div v-if="!grpSearch" class="file_name_wrap">
                    <button class="file_left" @click="fnAddrGrpClk('all')">
                      <div class="btn_file_name">
                        <span class="file_name ellipsis">전체</span>
                        <span v-if="totalCnt > 0" class="count">({{ totalCnt | formatPrice }})</span>
                      </div>
                    </button>
                  </div>
                  <ul v-if="!grpSearch && loginDiv && addrDiv=='P'" class="file_depth2">
                    <li :class="{ 'on ': nAppointActive }">
                      <div class="file_name_wrap">
                        <button class="file_left" @click="fnAddrGrpClk('nat')">
                          <div class="btn_file_name">
                            <span class="file_name ellipsis" >그룹 없음</span>
                            <span v-if="nAppointCnt > 0" class="count">({{ nAppointCnt | formatPrice }})</span>
                          </div>
                        </button>
                      </div>
                    </li>
                  </ul>
                  <ul class="file_depth2">
                    <li v-for="(tree, index) in addrGrpList" :key="index" :class="{ has_depth: tree.childCnt > 0, on: tree.classActive == 'Y' }">
                      <div class="file_name_wrap">
                        <button class="file_left" @click="fnAddrGrpClk(tree)">
                          <div v-if="tree.childCnt > 0" :class="{ btn_file_plus: tree.childCnt > 0 && tree.isActive == 'Y', btn_file_minus: tree.childCnt > 0 && tree.isActive == 'N' }"></div>
                          <div class="btn_file_name">
                            <span class="file_name ellipsis">{{ tree.groupNm | textLengthCut(10, "…") }}</span>
                            <span v-if="tree.addrCnt > 0" class="count">({{ tree.addrCnt | formatPrice }})</span>
                          </div>
                        </button>
                      </div>
                      <ul v-for="(child, index) in addrGrpChildList" :key="index" class="file_depth3">
                        <template v-if="tree.isActive == 'Y' && tree.groupSeq == child.parentGroupSeq">
                          <li :class="{ on: child.classActive == 'Y' }">
                            <div class="file_name_wrap">
                              <button class="file_left" @click="fnAddrGrpClk(child)">
                                <div class="btn_file_name">
                                  <span class="file_name ellipsis">{{ child.groupNm | textLengthCut(10, "…") }}</span>
                                  <span v-if="child.addrCnt > 0" class="count">({{ child.addrCnt | formatPrice }})</span>
                                </div>
                              </button>
                            </div>
                          </li>
                        </template>
                      </ul>
                    </li>
                  </ul>
                </li>
              </ul>
            </div>
            <div class="btn_wrap">
              <button v-if="grpSelBtn && selAddrCnt > 0" class="btn plus" @click="fnSelectGrpAddrMemberAdd()">선택한 그룹 전체 추가</button>
            </div>
          </div>
        </div>

        <div class="search_box accordion" v-if="isMobile" :class="{ on: accordion }">
          <h3 @click="accordionClick">간편등록</h3>
          <template v-if="accordion">
            <div class="accordion_wrap">
              <div class="group group01">
                <label for="right" class="label">그룹명</label>
                <div class="select_wrap">
                  <select class="select" v-model="selRegAddrInfo">
                    <option value="A" selected >그룹 선택</option>
                    <option value="N" v-if="addrDiv=='P'">'그룹 없음'에 등록</option>
                    <option v-for="(regSelAddr, index) in regAddrGrpSelList" :key="index" :value="regSelAddr">
                      {{ regSelAddr.groupNm }}
                    </option>
                  </select>
                </div>
              </div>
              <div class="group group02">
                <label for="right" class="label">휴대폰번호</label>
                <input type="text" v-model="phone" class="input" placeholder="휴대폰 번호 입력 (숫자만 입력)" />
              </div>
              <div class="group group03">
                <label for="right" class="label">이름</label>
                <input type="text" v-model="name" class="input" placeholder="이름 입력" />
              </div>
              <div class="group group04">
                <label for="right" class="label">비고</label>
                <input type="text" v-model="memo" class="input" placeholder="비고 입력 (최대 50byte)" />
              </div>
              <button class="btn plus" :disabled="!(phone != '' && loginDiv)" @click="fnSaveAddrMember">추가</button>
            </div>
          </template>
        </div>
        <!-- // 0718 모바일 간편등록은 이쪽에 노출 -->

        <div class="list_wrap">
          <div v-if="grpSelBtn" class="list_top list_info">
            <div class="left_wrap">
              <div class="select_wrap">
                <select class="select" v-model="selected" @change="fnSelected">
                  <option value="30">30</option>
                  <option value="50">50</option>
                  <option value="100">100</option>
                </select>
              </div>
              <div class="total">
                총 <span class="pink">{{ totCnt | formatPrice }} </span>건
              </div>
              <div v-if="addrDiv=='G'">
                [그룹생성 ID] : {{ userId }}({{ userNm }})
              </div>
            </div>
            <div class="search_wrap">
              <div class="search_input">
                <input type="text" v-model="searchPhone" class="search" placeholder="이름 또는 번호를 입력하세요" />
                <button class="btn_search" @click="fnAddrMemberSearch"></button>
              </div>
            </div>
          </div>

          <div class="fixed-table-cover">
            <div class="fixed-table-container" style="max-width: 765px; min-width: 400px; width: 100%">
              <div class="fixed-table-header-bg"></div>
              <div class="fixed-table-wrapper">
                <table class="fixed-table" style="table-layout: fixed">
                  <thead>
                    <tr :class="{ w100per: !grpSelBtn }">
                      <th v-if="grpSelBtn" class="td_chk" style="width: 40px">
                        <div class="th-text"><input type="checkbox" id="listCheck_all" class="chk" @change="fnListChkAll" v-model="listAllChecked" /><label for="listCheck_all"></label></div>
                      </th>
                      <th class="td_group" :style="grpSelBtn ? 'width : calc(50% - 20px)' : 'width : 50%'">
                        <div class="th-text">그룹명(이름)</div>
                      </th>
                      <th class="td_phone" :style="grpSelBtn ? 'width : calc(50% - 20px)' : 'width : 50%'">
                        <div class="th-text">그룹정보(번호)</div>
                      </th>
                    </tr>
                  </thead>
                  <tbody v-if="grpSelBtn">
                    <tr v-for="(data, index) in addrMemList" :key="index" class="list" :class="{ on: data.tgtCheck }">
                      <td class="td_chk"><input type="checkbox" class="chk" :id="'listCheck_' + index" :value="data" v-model="data.tgtCheck" /><label :for="'listCheck_' + index"></label></td>
                      <td class="td_name">{{ data.name }}</td>
                      <td class="td_phone">{{ data.phone }}</td>
                    </tr>
                  </tbody>
                  <tbody v-if="!grpSelBtn">
                    <tr  v-if= "addrDiv=='P'">
                      <td class="td_group">그룹 없음</td>
                      <td class="td_phone">{{ nAppointCnt | formatPrice }}명</td>
                    </tr>
                    <tr v-for="(tree, index) in addrGrpList" :key="index">
                      <td class="td_group">{{ tree.groupNm | textLengthCut(8, "…") }}</td>
                      <td class="td_phone">{{ tree.addrCnt | formatPrice }}명</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>

          <div v-show="grpSelBtn && totCnt > listSize" class="paging_wrap">
            <PageLayer ref="updatePaging" @fnClick="fnSearch" :listTotalCnt="totCnt" :selected="listSize" :pageNum="pageNo"></PageLayer>
          </div>
        </div>
      </div>
      <div class="pop-btn1">
        <button v-if="grpSelBtn && (selAddrCnt > 0 || realAddrCnt > 0)" type="button" @click="fnSelectAddrMemberAdd()" class="btn large_pink">추가</button>
        <button type="button" class="btn large_white" @click="ModalClose()">취소</button>
      </div>
    </div>

    <!-- s: 주소록 대량등록 -->
    <div v-if="regMemOpen" class="popup tit edit_address open">
      <div class="pop-head">
        <h3 class="pop-tit">대량 등록</h3>
      </div>
      <div class="pop-cont">
        <div class="fixed-table-cover">
          <div class="fixed-table-container" style="width: 947px">
            <div class="fixed-table-header-bg"></div>
            <div class="fixed-table-wrapper">
              <table class="fixed-table nobg" style="table-layout: fixed">
                <colgroup>
                  <col width="33%" />
                  <col width="33%" />
                  <col width="33%" />
                </colgroup>
                <thead>
                  <tr>
                    <th class="td_group" style="width: 33%">
                      <div class="th-text">그룹명</div>
                    </th>
                    <th class="td_phone" style="width: 33%">
                      <div class="th-text">휴대폰 번호</div>
                    </th>
                    <th class="td_name" style="width: 33%">
                      <div class="th-text">이름</div>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(addMemInfo, index) in addMemInfoList" :key="index">
                    <td class="td_group">
                      <select class="select" v-model="addMemInfo.addGroupSeq">
                        <option value="A" selected >그룹 선택</option>
                        <option value="N"  v-if="addrDiv=='P'" >'그룹 없음'에 등록</option>
                        <option v-for="(regSelAddr, index) in regAddrGrpSelList" :key="index" :value="regSelAddr.modiGroupSeq">
                          {{ regSelAddr.groupNm }}
                        </option>
                      </select>
                    </td>
                    <td class="td_phone"><input type="text" class="text" v-model="addMemInfo.phone" @keyup="fnPhoneDash($event, index)" /></td>
                    <td class="td_name"><input type="text" class="input" v-model="addMemInfo.name" /></td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>

        <div class="pop-btn2 center">
          <button class="btn large_pink" @click="fnMassAddrMemReg">등록</button>
          <button class="btn large_white" @click="ModalChildClose">취소</button>
        </div>
      </div>
    </div>
    <!-- e: 주소록 수정 -->
  </div>
</template>

<script>
import store from "@/store";
import PageLayer from "@/components/PageLayer.vue";
import messageSendApi from "../service/messageSendApi";
import confirm from "@/modules/commonUtil/service/confirm";
import { eventBus } from "@/modules/commonUtil/service/eventBus";

export default {
  name: "sendAddress",
  components: {
    PageLayer,
  },
  data() {
    return {
      isMobile: false,
      loginDiv: false,
      regMemOpen: false,
      pcStandard: 896, // PC 화면의 Viewport 기준점
      selected: "30",
      listSize: 30, // select 박스 value (출력 갯수 이벤트)
      pageNo: 1, // 현재 페이징 위치
      totCnt: 0, //전체 리스트 수
      offset: 0, //페이지 시작점
      addrGrpInfo: {},
      addrGrpList: [],
      addrGrpChildList: [],
      addrMemList: [],
      addrMemInfo: {},
      regAddrGrpSelList: {},
      searchPhone: "",
      srchGroupSeq: "",
      srchParentGroupSeq: "",
      srchLevDepth: "0",
      selRegAddrInfo: "A",
      selGroupNm: "",
      srchGroupNm: "",
      selAddrCnt: 0,
      realAddrCnt: 0,
      parentGroupSeq: "",
      groupNm: "",
      lev1: "",
      lev2: "",
      levDepth: "",
      name: "",
      phone: "",
      email: "",
      gname: "",
      memo: "",
      grpSearch: false,
      listAllChecked: false,
      grpSelBtn: false,
      listChkBox: [],
      memSeq: "",
      active: false,
      totalCnt: 0,
      nAppointActive: false,
      nAppointCnt: 0,
      addrInfo: {
        pageNo: 1,
        listSize: 30,
        searchPhone: "",
        srchGroupSeq: "",
        srchParentGroupSeq: "",
        srchLevDepth: "",
        srchGroupNm: "",
        userSeq: "",
        authcd080: "",
        groupSeq: "",
        levDepth: "",
        lev1: "",
        lev2: "",
        addrDiv: "P"
      },
      addMemInfoList: [
        { addGroupSeq: "A", name: "", phone: "" },
        { addGroupSeq: "A", name: "", phone: "" },
        { addGroupSeq: "A", name: "", phone: "" },
        { addGroupSeq: "A", name: "", phone: "" },
        { addGroupSeq: "A", name: "", phone: "" },
        { addGroupSeq: "A", name: "", phone: "" },
        { addGroupSeq: "A", name: "", phone: "" },
        { addGroupSeq: "A", name: "", phone: "" },
        { addGroupSeq: "A", name: "", phone: "" },
        { addGroupSeq: "N", name: "", phone: "" },
      ],
      accordion: false,
      addrDiv: "P",
      userId: "",
      userNm: ""
    };
  },
  created() {
    this.checkIsMobile();
    const isAuth = store.getters["login/isAuth"];
    if (isAuth) {
      this.loginDiv = true;
    }
  },
  mounted() {
    window.addEventListener("resize", this.handleResize);
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.handleResize);
  },
  watch: {
    phone(val) {
      if (val !== "") {
        let regExp = /^[0-9]+$/;
        if (!regExp.test(val)) {
          confirm.fnAlert("", "휴대폰번호는 숫자만 입력 가능 합니다.", "alert");
          this.phone = val.replace(/[^0-9]/g, "");
        }
      }
    },
  },
  methods: {
    fnDataInit() {
      this.regMemOpen = false;
      this.selected = "30";
      this.listSize = 30;
      this.pageNo = 1;
      this.totCnt = 0;
      this.offset = 0;
      this.addrGrpInfo = {};
      this.addrGrpList = [];
      this.addrGrpChildList = [];
      this.addrMemList = [];
      this.addrMemInfo = {};
      this.searchPhone = "";
      this.srchGroupSeq = "";
      this.srchParentGroupSeq = "";
      this.srchLevDepth = "0";
      this.selGroupNm = "";
      this.srchGroupNm = "";
      this.selAddrCnt = 0;
      this.realAddrCnt = 0;
      this.parentGroupSeq = "";
      this.groupNm = "";
      this.lev1 = "";
      this.lev2 = "";
      this.levDepth = "";
      this.grpSearch = false;
      this.listAllChecked = false;
      this.grpSelBtn = false;
      this.listChkBox = [];
      this.memSeq = "";
      this.active = false;
      this.totalCnt = 0;
      this.nAppointActive = false;
      this.nAppointCnt = 0;
      this.addrInfo = {
        pageNo: 1,
        listSize: 30,
        searchPhone: "",
        srchGroupSeq: "",
        srchParentGroupSeq: "",
        srchLevDepth: "",
        srchGroupNm: "",
        userSeq: "",
        authcd080: "",
        groupSeq: "",
        levDepth: "",
        lev1: "",
        lev2: "",
      };
      this.addMemInfoList = [
        { addGroupSeq: "N", name: "", phone: "" },
        { addGroupSeq: "N", name: "", phone: "" },
        { addGroupSeq: "N", name: "", phone: "" },
        { addGroupSeq: "N", name: "", phone: "" },
        { addGroupSeq: "N", name: "", phone: "" },
        { addGroupSeq: "N", name: "", phone: "" },
        { addGroupSeq: "N", name: "", phone: "" },
        { addGroupSeq: "N", name: "", phone: "" },
        { addGroupSeq: "N", name: "", phone: "" },
        { addGroupSeq: "N", name: "", phone: "" },
      ];
    },
    fnAddrInfoInit() {
      this.addrInfo = {
        pageNo: 1,
        listSize: 30,
        searchPhone: "",
        srchGroupSeq: "",
        srchParentGroupSeq: "",
        srchLevDepth: "",
        srchGroupNm: "",
        groupSeq: "",
        levDepth: "",
        lev1: "",
        lev2: "",
      };
    },
    fnSendAddrInit() {
      this.selRegAddrInfo = "A"
      if (this.loginDiv) {
        this.addrDiv = "P"
        this.fnDataInit();
        this.fnSelectAddressGrpList();
        this.fnAddrGrpClk("all");
        this.fnSelectRegAddrGrpList();
      }
    },
    fnSendAddrPubInit() {
      this.selRegAddrInfo = "A"
      if (this.loginDiv) {
        this.addrDiv = "G"
        this.fnDataInit();
        this.fnSelectAddressGrpList();
        this.fnAddrGrpClk("all");
        this.fnSelectRegAddrGrpList();
      }
    },
    //주소 그룹 조회
    fnSelectAddressGrpList() {
      this.fnAddrInfoInit();
      this.addrInfo = {
        userSeq: "",
      };
      if(this.addrDiv=="P"){
        messageSendApi.selectAddressGrpList(this.addrInfo).then((response) => {
          var result = response.data;
          if (result.success) {
            this.addrGrpList = result.data.addrGrpList;
            this.addrGrpChildList = result.data.addrGrpChildList;
            this.totalCnt = result.data.totalCnt;
            this.nAppointCnt = result.data.nAppointCnt;
          } else {
            // confirm.fnAlert("알림", "오류가 발생하였습니다.", "alert");
            confirm.fnAlert("", "오류가 발생하였습니다.", "alert");
          }
        });
      } else if (this.addrDiv=="G"){
        messageSendApi.selectAddressPubGrpList(this.addrInfo).then((response) => {
          var result = response.data;
          if (result.success) {
            this.addrGrpList = result.data.addrGrpList;
            this.addrGrpChildList = result.data.addrGrpChildList;
            this.totalCnt = result.data.totalCnt;
            this.nAppointCnt = result.data.nAppointCnt;
            // this.userId = result.data.userId;
            // this.userNm = result.data.userNm;
          } else {
            // confirm.fnAlert("알림", "오류가 발생하였습니다.", "alert");
            confirm.fnAlert("", "오류가 발생하였습니다.", "alert");
          }
        });
      }
    },
    fnSelectRegAddrGrpList() {
      this.addrInfo = {
        userSeq: "",
      };
      if(this.addrDiv=="P"){
        this.addrInfo = {
          addrDiv: "P",
        };
        messageSendApi.selectAddressGrpSelList(this.addrInfo).then((response) => {
          var result = response.data;
          if (result.success) {
            this.regAddrGrpSelList = result.data.addrGrpSelList;
          } else {
            confirm.fnAlert("", "오류가 발생하였습니다.", "alert");
          }
        });
      } else if(this.addrDiv=="G"){
        this.addrInfo = {
          addrDiv: "G",
        };
         messageSendApi.selectAddressPubGrpSelList(this.addrInfo).then((response) => {
          var result = response.data;
          if (result.success) {
            this.regAddrGrpSelList = result.data.addrGrpSelList;
          } else {
            confirm.fnAlert("", "오류가 발생하였습니다.", "alert");
          }
        });
      }
    },
    //주소 그룹 검색
    fnSrchAddrGroup() {
      this.fnAddrInfoInit();
      this.addrInfo = {
        userSeq: "",
        srchGroupNm: this.srchGroupNm,
      };

      if (this.srchGroupNm == "") {
        this.grpSearch = false;
        if(this.addrDiv=="P"){
          messageSendApi.selectAddressGrpList(this.addrInfo).then((response) => {
            var result = response.data;
            if (result.success) {
              this.addrGrpList = result.data.addrGrpList;
              this.addrGrpChildList = result.data.addrGrpChildList;
              this.totalCnt = result.data.totalCnt;
              this.nAppointCnt = result.data.nAppointCnt;
              this.fnAddrGrpClk("all");
            } else {
              // confirm.fnAlert("알림", "오류가 발생하였습니다.", "alert");
              confirm.fnAlert("", "오류가 발생하였습니다.", "alert");
            }
          });
        } else if (this.addrDiv =="G"){
          messageSendApi.selectAddressPubGrpList(this.addrInfo).then((response) => {
            var result = response.data;
            if (result.success) {
              this.addrGrpList = result.data.addrGrpList;
              this.addrGrpChildList = result.data.addrGrpChildList;
              this.totalCnt = result.data.totalCnt;
              this.nAppointCnt = result.data.nAppointCnt;
              this.fnAddrGrpClk("all");
            } else {
              // confirm.fnAlert("알림", "오류가 발생하였습니다.", "alert");
              confirm.fnAlert("", "오류가 발생하였습니다.", "alert");
            }
          });
        }
      } else {
        this.grpSearch = true;
        if(this.addrDiv =="P"){
          messageSendApi.searchAddressGrpList(this.addrInfo).then((response) => {
            var result = response.data;
            if (result.success) {
              this.addrGrpList = result.data.addrGrpList;
              this.addrGrpChildList = result.data.addrGrpChildList;
              this.fnAddrGrpClk("srch");
            } else {
              // confirm.fnAlert("알림", "오류가 발생하였습니다.", "alert");
              confirm.fnAlert("", "오류가 발생하였습니다.", "alert");
            }
          });
        } else if (this.addrDiv=="G"){
          messageSendApi.searchAddressPubGrpList(this.addrInfo).then((response) => {
            var result = response.data;
            if (result.success) {
              this.addrGrpList = result.data.addrGrpList;
              this.addrGrpChildList = result.data.addrGrpChildList;
              this.fnAddrGrpClk("srch");
            } else {
              // confirm.fnAlert("알림", "오류가 발생하였습니다.", "alert");
              confirm.fnAlert("", "오류가 발생하였습니다.", "alert");
            }
          });
        }
      }
    },
    //주소록 명단 카테고리 클릭
    fnAddrGrpClk(treeObj) {
      this.userId = treeObj.userId;
      this.userNm = treeObj.userNm;
      this.addrGrpInfo = Object.assign({}, treeObj);
      this.addrMemList = [];
      this.searchPhone = "";
      this.mineYn = treeObj.mineYn;

      this.addrGrpList.forEach(function (tree) {
        tree.classActive = "N";
      });
      this.addrGrpChildList.forEach(function (tree) {
        tree.classActive = "N";
      });

      this.addrGrpInfo = Object.assign({}, treeObj);
      this.addrMemList = [];
      this.searchPhone = "";

      if (treeObj == "all") {
        //전체
        this.active = true;
        this.nAppointActive = false;
        this.grpSelBtn = false;
        this.srchGroupSeq = "";
        this.srchLevDepth = "0";
        this.selGroupNm = "전체";
        this.$refs.updatePaging.fnAllDecrease();
      } else if (treeObj == "nat") {
        //그룹 없음
        this.nAppointActive = true;
        this.active = false;
        this.grpSelBtn = true;
        this.parentGroupSeq = "0";
        this.srchGroupSeq = "0";
        this.srchLevDepth = "1";
        this.selGroupNm = "그룹 없음";
        this.selAddrCnt = this.nAppointCnt;
        this.realAddrCnt = this.nAppointCnt;
        this.$refs.updatePaging.fnAllDecrease();
      } else if (treeObj == "srch") {
        //검색시 그룹 초기화(선택 그룹 X)
        this.nAppointActive = false;
        this.active = false;
        this.parentGroupSeq = "";
        this.srchGroupSeq = "";
        this.srchLevDepth = "";
        this.selGroupNm = "";
      } else {
        //트리 그룹
        this.parentGroupSeq = treeObj.parentGroupSeq + "";
        this.srchGroupSeq = treeObj.groupSeq + "";
        this.srchLevDepth = treeObj.levDepth;
        this.selGroupNm = treeObj.groupNm;
        this.selAddrCnt = treeObj.addrCnt;
        this.realAddrCnt = treeObj.realAddrCnt;
        this.grpSelBtn = true;
        this.active = false;
        this.nAppointActive = false;

        if ("N" == treeObj.classActive) {
          treeObj.classActive = "Y";
        } else {
          treeObj.classActive = "N";
        }

        if ("Y" == treeObj.isActive) {
          treeObj.isActive = "N";
        } else {
          treeObj.isActive = "Y";
        }
        this.$refs.updatePaging.fnAllDecrease();
      }
      // 체크박스 초기화
      this.listAllChecked = false;
      this.listChkBox = [];
    },
    //리스트 전체 체크박스
    fnListChkAll() {
      if (this.listAllChecked) {
        this.addrMemList.forEach(function (memberInfo) {
          memberInfo.tgtCheck = true;
        });
      } else {
        this.addrMemList.forEach(function (memberInfo) {
          memberInfo.tgtCheck = false;
        });
      }
    },
    fnSelected() {
      this.listSize = Number(this.selected);
      this.$refs.updatePaging.fnAllDecrease();
    },
    fnAddrMemberSearch() {
      this.$refs.updatePaging.fnAllDecrease();
    },
    fnSearch(pageNum) {
      this.pageNo = this.$gfnCommonUtils.defaultIfEmpty(pageNum, "1") * 1;
      this.fnSrchAddrMemberList();
    },
    //주소록 명단 리스트 조회
    async fnSrchAddrMemberList() {
      this.fnAddrInfoInit();
      /*
      if(this.searchPhone != ""){
        let regExp = /^[0-9]+$/;
        if (!regExp.test(this.searchPhone)) {
          confirm.fnAlert("검색어는 숫자만 입력 가능 합니다.");
          return false;
        }
      }*/

      this.addrInfo = {
        pageNo: this.pageNo,
        listSize: this.listSize,
        searchPhone: this.searchPhone,
        srchGroupSeq: this.srchGroupSeq,
        srchLevDepth: this.srchLevDepth,
      };
      if(this.addrDiv=="P"){
        await messageSendApi.selectAddrMemberList(this.addrInfo).then((response) => {
          var result = response.data;
          if (result.success) {
            this.addrMemList = Object.assign([], result.data.addrMemList);
            this.totCnt = result.data.pageInfo.totCnt;
            this.offset = result.data.pageInfo.offset;
          } else {
            // confirm.fnAlert("알림", "오류가 발생하였습니다.", "alert");
            confirm.fnAlert("", "오류가 발생하였습니다.", "alert");
          }
        });
      } else if (this.addrDiv =="G"){
        await messageSendApi.selectAddrPubMemberList(this.addrInfo).then((response) => {
          var result = response.data;
          if (result.success) {
            this.addrMemList = Object.assign([], result.data.addrMemList);
            this.totCnt = result.data.pageInfo.totCnt;
            this.offset = result.data.pageInfo.offset;
          } else {
            // confirm.fnAlert("알림", "오류가 발생하였습니다.", "alert");
            confirm.fnAlert("", "오류가 발생하였습니다.", "alert");
          }
        });
      }
    },
    //주소록 구성원 추가
    fnSaveAddrMember() {
      this.fnAddrInfoInit();
      if (this.name == "") {
        confirm.fnAlert("", "이름을 입력해 주세요.", "alert");
        return false;
      } else {
        if (this.fnGetStringLength(this.name) > 20) {
          confirm.fnAlert("", "이름 입력 내용이 20byte를 초과했습니다.", "alert");
          return false;
        }
      }

      if (this.phone == "") {
        confirm.fnAlert("", "휴대폰 번호를 입력해 주세요.", "alert");
        return false;
      } else {
        let regExp = /^01([0|1|6|7|8|9])-?([0-9]{3,4})-?([0-9]{4})$/;
        if (!regExp.test(this.phone)) {
          confirm.fnAlert("", "휴대폰 번호를 확인해주세요.", "alert");
          return false;
        }
      }

      if (this.memo != "") {
        if (this.fnGetStringLength(this.memo) > 50) {
          confirm.fnAlert("", "비고 입력 내용이 50byte를 초과했습니다.", "alert");
          return false;
        }
      }
      if (this.selRegAddrInfo == "A") {
        confirm.fnAlert("", "그룹을 선택해주세요", "alert");
        return false;
      } else if (this.selRegAddrInfo == "N") {
        this.addrInfo = {
          levDepth: "1",
          parentGroupSeq: "0",
          groupSeq: "0",
          phone: this.phone.replace(/-/gi, ""),
          name: this.name,
          memo: this.memo,
        };
      } else {
        this.addrInfo = {
          levDepth: this.selRegAddrInfo.levDepth,
          parentGroupSeq: this.selRegAddrInfo.parentGroupSeq,
          groupSeq: this.selRegAddrInfo.groupSeq,
          phone: this.phone.replace(/-/gi, ""),
          name: this.name,
          memo: this.memo,
        };
      }
      if(this.addrDiv=="P"){
        messageSendApi.registerAddrMember(this.addrInfo).then((response) => {
          var result = response.data;
          if (result.success) {
            confirm.fnAlert("", "주소를 등록했습니다.", "alert");
            this.phone = "";
            this.name = "";
            this.memo = "";
            if (this.selRegAddrInfo == "N") {
              //그룹 없음
              this.fnSelectAddressGrpListReset(1, 0, 0);
            } else {
              this.fnSelectAddressGrpListReset(this.selRegAddrInfo.levDepth, this.selRegAddrInfo.groupSeq, this.selRegAddrInfo.parentGroupSeq);
            }
          } else {
            confirm.fnAlert("", result.message, "alert");
          }
        });
      } else if (this.addrDiv=="G"){
        messageSendApi.registerAddrPubMember(this.addrInfo).then((response) => {
          var result = response.data;
          if (result.success) {
            confirm.fnAlert("", "주소를 등록했습니다.", "alert");
            this.phone = "";
            this.name = "";
            this.memo = "";
            if (this.selRegAddrInfo == "N") {
              //그룹 없음
              this.fnSelectAddressGrpListReset(1, 0, 0);
            } else {
              this.fnSelectAddressGrpListReset(this.selRegAddrInfo.levDepth, this.selRegAddrInfo.groupSeq, this.selRegAddrInfo.parentGroupSeq);
            }
          } else {
            confirm.fnAlert("", result.message, "alert");
          }
        });
      }
    },
    //주소록 그룹 추가
    fnSaveAddrGroup() {
      if(this.mineYn != 'Y' && this.addrDiv == 'G'){
        confirm.fnAlert("", "본인이 등록한 그룹에만 추가할 수 있습니다.", "alert");
      } else if (this.mineYn == 'Y' || this.addrDiv == 'P'){
        this.fnAddrInfoInit();
        if (!this.fnInputCheckReq(this.groupNm)) return false;

        if (this.srchLevDepth == "" || this.selGroupNm == "") {
          confirm.fnAlert("", "추가하려는 그룹의 상위 그룹을 아래 트리에서 선택해주세요.", "alert");
          return;
        }

        if (this.srchLevDepth == "1" && this.selGroupNm == "그룹 없음") {
          confirm.fnAlert("", "그룹 없음 그룹에는 그룹을 추가 할 수 없습니다.", "alert");
          return;
        }

        if (this.srchLevDepth == "2") {
          confirm.fnAlert("", "2단계 까지만 그룹을 추가하실 수 있습니다.", "alert");
          return false;
        }
        if (this.srchLevDepth == "0") {
          //전체 선택인 경우는 lev1 에 그룹명
          this.levDepth = "1";
          this.lev1 = this.groupNm;
          this.lev2 = "";
        } else {
          //1단계 선택인 경우는 lev1에 선택그룹명 lev2에 추가하는 그룹명
          this.levDepth = "2";
          this.lev1 = this.selGroupNm;
          this.lev2 = this.groupNm;
        }
        this.addrInfo = {
          levDepth: this.levDepth,
          lev1: this.lev1,
          lev2: this.lev2,
        };
        if(this.addrDiv=="P"){
          messageSendApi.registerAddrGrp(this.addrInfo).then((response) => {
            var result = response.data;
            if (result.success) {
              this.groupNm = "";
              confirm.fnAlert("", "새 그룹을 추가했습니다.", "alert");
              if (this.srchLevDepth == "0") {
                this.fnSelectAddressGrpListReset(0, "", "");
                this.fnSelectRegAddrGrpList();
              } else {
                this.fnSelectAddressGrpListReset(this.addrGrpInfo.levDepth, this.addrGrpInfo.groupSeq, this.addrGrpInfo.parentGroupSeq);
                this.fnSelectRegAddrGrpList();
              }
            } else {
              confirm.fnAlert("", "새 그룹을 추가중 오류가 발생하였습니다.", "alert");
            }
          });
        } else if (this.addrDiv=="G"){
          messageSendApi.registerAddrPubGrp(this.addrInfo).then((response) => {
            var result = response.data;
            if (result.success) {
              this.groupNm = "";
              confirm.fnAlert("", "새 그룹을 추가했습니다.", "alert");
              if (this.srchLevDepth == "0") {
                this.fnSelectAddressGrpListReset(0, "", "");
                this.fnSelectRegAddrGrpList();
              } else {
                this.fnSelectAddressGrpListReset(this.addrGrpInfo.levDepth, this.addrGrpInfo.groupSeq, this.addrGrpInfo.parentGroupSeq);
                this.fnSelectRegAddrGrpList();
              }
            } else {
              confirm.fnAlert("", "새 그룹을 추가중 오류가 발생하였습니다.", "alert");
            }
          });
        }
      }
    },
    fnMassAddrMemReg() {
      let nameNull = true;
      let nameStrChk = true;
      let phoneNull = true;
      let phoneForm = true;
      let groupNull = true;
      let addrMemArray = [];
      this.addMemInfoList.forEach(function (data) {
        if (data.phone != "" || data.name != "") {
          if (data.name == "") {
            nameNull = false;
          } else {
            if (data.name.replace(/[\0-\x7f]|([0-\u07ff]|(.))/g, "$&$1$2").length > 20) {
              nameStrChk = false;
            }
          }
          if (data.phone == "") {
            phoneNull = false;
          } else {
            let regExp = /^01([0|1|6|7|8|9])-?([0-9]{3,4})-?([0-9]{4})$/;
            if (!regExp.test(data.phone)) {
              phoneForm = false;
            } else {
              data.phone = data.phone.replace(/-/gi, "");
            }
          }
          if(data.addGroupSeq == "A"){
            groupNull = false;
          }
          addrMemArray.push(Object.assign({}, data));
        }
      });

      if (!nameNull) {
        confirm.fnAlert("", "이름을 입력해 주세요.", "alert");
        return false;
      }
      if (!nameStrChk) {
        confirm.fnAlert("", "이름 입력 내용이 20byte를 초과했습니다.", "alert");
        return false;
      }
      if (!phoneNull) {
        confirm.fnAlert("", "휴대폰 번호를 입력해 주세요.", "alert");
        return false;
      }
      if (!phoneForm) {
        confirm.fnAlert("", "휴대폰 번호를 확인해주세요.", "alert");
        return false;
      }
      if (!groupNull) {
        confirm.fnAlert("", "그룹을 선택해주세요", "alert");
        return false;
      }

      this.addrInfo = {
        addressDtoArray: addrMemArray,
      };
      if(this.addrDiv=="P"){
        messageSendApi.massRegAddrMember(this.addrInfo).then((response) => {
          var result = response.data;
          if (result.success) {
            confirm.fnAlert("", "수신인(수신번호)을 등록 했습니다.", "alert");
            this.fnSelectAddressGrpListReset(0, 0, 0);
            this.$refs.updatePaging.fnAllDecrease();
            this.ModalChildClose();
          } else {
            confirm.fnAlert("", result.message, "alert");
          }
        });
      } else if (this.addrDiv=="G"){
        messageSendApi.massRegAddrPubMember(this.addrInfo).then((response) => {
          var result = response.data;
          if (result.success) {
            confirm.fnAlert("", "수신인(수신번호)을 등록 했습니다.", "alert");
            this.fnSelectAddressGrpListReset(0, 0, 0);
            this.$refs.updatePaging.fnAllDecrease();
            this.ModalChildClose();
          } else {
            confirm.fnAlert("", result.message, "alert");
          }
        });
      }
    },
    //유효성 체크
    fnInputCheckReq(groupNm) {
      const chkGroupNm = groupNm;
      let isGroupNm = true;
      const regExp = /[`!*★“”‘’|\\'\\";:\\/?]/gi;

      if (chkGroupNm == "") {
        confirm.fnAlert("", "그룹명을 입력해 주세요.", "alert");
        return false;
      } else {
        if (regExp.test(chkGroupNm)) {
          confirm.fnAlert("", "특수문자(',\",*,!,/,★)는 사용이 안됩니다.", "alert");
          return false;
        }
        if (this.fnGetStringLength(chkGroupNm) > 30) {
          confirm.fnAlert("", "그룹명 입력 내용이 30byte를 초과했습니다.", "alert");
          return false;
        }
      }

      if (chkGroupNm == "전체") {
        confirm.fnAlert("", " 전체 그룹명은 사용할 수 없습니다.", "alert");
        return false;
      }
      if (chkGroupNm == "그룹 없음" || chkGroupNm == "그룹없음") {
        confirm.fnAlert("", " 그룹 없음 그룹명은 사용할 수 없습니다.", "alert");
        return false;
      }

      this.addrGrpList.forEach(function (tree) {
        if (tree.groupNm.toUpperCase() == chkGroupNm.toUpperCase()) {
          isGroupNm = false;
        }
      });
      this.addrGrpChildList.forEach(function (tree) {
        if (tree.groupNm.toUpperCase() == chkGroupNm.toUpperCase()) {
          isGroupNm = false;
        }
      });

      if (!isGroupNm) {
        confirm.fnAlert("", "중복된 그룹명이 있습니다.", "alert");
        return false;
      }
      return true;
    },
    fnGetStringLength(str) {
      var strLength = 0;
      for (var i = 0; i < str.length; i++) {
        var code = str.charCodeAt(i);
        var ch = str.substr(i, 1).toUpperCase();
        code = parseInt(code);
        if ((ch < "0" || ch > "9") && (ch < "A" || ch > "Z") && (code > 255 || code < 0)) {
          strLength = strLength + 2;
        } else {
          strLength = strLength + 1;
        }
      }
      return strLength;
    },
    fnPhoneDash(e, index) {
      const pattern_notNumber = /[^-0-9]/g; // 숫자가 아닌것 체크
      // 숫자 외 입력시
      if (pattern_notNumber.test(e.target.value)) {
        confirm.fnAlert("", "휴대폰번호는 숫자만 입력 가능 합니다.", "alert");
        this.addMemInfoList[index].phone = e.target.value.replace(/[^0-9]/g, "");
      }
    },
    fnSelectAddrMemberAdd() {
      let checkDiv = 0;
      this.addrMemList.forEach(function (addrInfo) {
        if (addrInfo.tgtCheck) checkDiv++;
      });
      if (checkDiv == 0) {
        // confirm.fnAlert("알림", "주소록을 선택해 주세요.", "alert", "!");
        confirm.fnAlert("", "수신인(수신번호)을 선택해 주세요.", "alert");
        return false;
      }

      eventBus.$on("callbackEventBus", this.fnSelectAddrMember);
      // confirm.fnConfirm("알림", "선택한 주소록을 추가하시겠습니까?");
      confirm.fnConfirm("", "선택한 수신인(수신번호)을 추가하시겠습니까?");
    },
    fnSelectAddrMember() {
      let selectAddrMemList = [];
      this.addrMemList.forEach(function (data) {
        if (data.tgtCheck) {
          let selectAddrMemInfo = {
            phone: data.phone,
            /* name: data.name, */
            status: "",
            validator: true,
            selected: false,
          };
          selectAddrMemList.push(selectAddrMemInfo);
        }
      });
      this.$emit("sendAddrInfoSet", selectAddrMemList);
    },
    fnSelectGrpAddrMemberAdd() {
      if (this.totCnt > 20000) {
        confirm.fnAlert("", "최대 전송할 수 있는 건수가 초과되어 전송이 불가합니다.", "alert");
        return;
      }

      eventBus.$on("callbackEventBus", this.fnSelectGrpAddrMember);
      confirm.fnConfirm("", "선택한 그룹의 모든 수신인(수신번호)을 추가하시겠습니까?");
    },
    fnSelectGrpAddrMember() {
      this.addrInfo = {
        srchLevDepth: this.srchLevDepth,
        srchGroupSeq: this.srchGroupSeq,
        srchParentGroupSeq: this.parentGroupSeq,
      };
      if(this.addrDiv=="P"){
        messageSendApi.selectGrpAddrMemberList(this.addrInfo).then((response) => {
          var result = response.data;
          if (result.success) {
            let selectAddrMemList = [];
            result.data.grpAddrMemList.forEach(function (data) {
              let selectAddrMemInfo = {
                phone: data.phone,
                name: data.name,
                status: "",
                validator: true,
                selected: false,
              };
              selectAddrMemList.push(selectAddrMemInfo);
            });
            this.$emit("sendAddrInfoSet", selectAddrMemList);
          } else {
            // confirm.fnAlert("알림", "오류가 발생하였습니다.", "alert");
            confirm.fnAlert("", "오류가 발생하였습니다.", "alert");
          }
        });
      } else if (this.addrDiv=="G"){
        messageSendApi.selectGrpAddrPubMemberList(this.addrInfo).then((response) => {
          var result = response.data;
          if (result.success) {
            let selectAddrMemList = [];
            result.data.grpAddrMemList.forEach(function (data) {
              let selectAddrMemInfo = {
                phone: data.phone,
                name: data.name,
                status: "",
                validator: true,
                selected: false,
              };
              selectAddrMemList.push(selectAddrMemInfo);
            });
            this.$emit("sendAddrInfoSet", selectAddrMemList);
          } else {
            // confirm.fnAlert("알림", "오류가 발생하였습니다.", "alert");
            confirm.fnAlert("", "오류가 발생하였습니다.", "alert");
          }
        });
      }
    },
    ModalClose() {
      var dimmed = document.getElementsByClassName("dimmed");
      var wrap = document.getElementsByClassName("popup-wrap");
      var obj = wrap[0].childElementCount;
      dimmed[0].style.display = "none";
      wrap[0].style.display = "none";
      for (var i = 0; i < obj; i++) {
        var target = document.getElementsByClassName("popup");
        target[i].style.display = "none";
        target[i].classList.remove("open");
      }
      this.$emit("setImageInfo", this.imgFileList);
    },
    accordionClick() {
      this.accordion = !this.accordion;
    },
    checkIsMobile: function () {
      this.isMobile = window.innerWidth < this.pcStandard || screen.width < this.pcStandard;
    },
    handleResize: function () {
      this.$nextTick(function () {
        this.checkIsMobile();
      });
    },
    //주소 그룹 리셋 조회
    fnSelectAddressGrpListReset(levDepth, groupSeq, parentGroupSeq) {
      let addrGrpInfoHash = {};
      this.addrGrpInfo = {};
      this.listAllChecked = false;
      this.searchPhone = "";
      this.parentGroupSeq = "";
      this.srchGroupSeq = "";
      this.srchLevDepth = "";
      this.selGroupNm = "";
      this.selAddrCnt = 0;
      this.realAddrCnt = 0;
      this.srchGroupNm = "";
      this.grpSearch = false;
      this.srchLevDepth = levDepth;
      this.srchGroupSeq = groupSeq;
      this.addrInfo = {
        userSeq: "",
      };
      if(this.addrDiv=="P"){
        messageSendApi.selectAddressGrpList(this.addrInfo).then((response) => {
          var result = response.data;
          if (result.success) {
            this.addrGrpList = result.data.addrGrpList.map((e) => {
              return {
                ...e,
                selected: false,
                moGroupNm: e.groupNm,
              };
            });
            this.addrGrpChildList = result.data.addrGrpChildList.map((e) => {
              return {
                ...e,
                selected: false,
                moGroupNm: e.groupNm,
              };
            });
            this.totalCnt = result.data.totalCnt;
            this.nAppointCnt = result.data.nAppointCnt;
  
            if (levDepth == 1 && groupSeq == 0 && parentGroupSeq == 0) {
              this.grpSelBtn = false;
              this.nAppointActive = true;
              this.active = false;
              this.parentGroupSeq = "0";
              this.srchGroupSeq = "0";
              this.srchLevDepth = "1";
              this.selAddrCnt = this.nAppointCnt;
              this.realAddrCnt = this.nAppointCnt;
              this.selGroupNm = "그룹 없음";
            } else {
              this.grpSelBtn = true;
              if (levDepth == 1) {
                this.addrGrpList.forEach(function (tree) {
                  if (tree.groupSeq == groupSeq) {
                    tree.isActive = "Y";
                    tree.classActive = "Y";
                    addrGrpInfoHash = Object.assign({}, tree);
                  }
                });
                this.addrGrpInfo = Object.assign({}, addrGrpInfoHash);
                this.active = false;
                this.nAppointActive = false;
                this.parentGroupSeq = this.addrGrpInfo.parentGroupSeq + "";
                this.srchGroupSeq = this.addrGrpInfo.groupSeq + "";
                this.srchLevDepth = this.addrGrpInfo.levDepth;
                this.selGroupNm = this.addrGrpInfo.groupNm;
                this.selAddrCnt = this.addrGrpInfo.addrCnt;
                this.realAddrCnt = this.addrGrpInfo.realAddrCnt;
              } else if (levDepth == 2) {
                this.addrGrpList.forEach(function (tree) {
                  if (tree.groupSeq == parentGroupSeq) {
                    tree.isActive = "Y";
                  }
                });
                this.addrGrpChildList.forEach(function (tree) {
                  if (tree.groupSeq == groupSeq) {
                    tree.isActive = "Y";
                    tree.classActive = "Y";
                    addrGrpInfoHash = Object.assign({}, tree);
                  }
                });
                this.addrGrpInfo = Object.assign({}, addrGrpInfoHash);
                this.active = false;
                this.nAppointActive = false;
                this.parentGroupSeq = this.addrGrpInfo.parentGroupSeq + "";
                this.srchGroupSeq = this.addrGrpInfo.groupSeq + "";
                this.srchLevDepth = this.addrGrpInfo.levDepth;
                this.selGroupNm = this.addrGrpInfo.groupNm;
                this.selAddrCnt = this.addrGrpInfo.addrCnt;
                this.realAddrCnt = this.addrGrpInfo.realAddrCnt;
              } else {
                this.active = true;
                this.nAppointActive = false;
                this.srchGroupSeq = "";
                this.srchLevDepth = "0";
                this.selGroupNm = "전체";
              }
            }
            this.$refs.updatePaging.fnAllDecrease();
          } else {
            confirm.fnAlert("", "오류가 발생하였습니다.", "alert");
          }
        });
      } else if (this.addrDiv=="G"){
        messageSendApi.selectAddressPubGrpList(this.addrInfo).then((response) => {
          var result = response.data;
          if (result.success) {
            this.addrGrpList = result.data.addrGrpList.map((e) => {
              return {
                ...e,
                selected: false,
                moGroupNm: e.groupNm,
              };
            });
            this.addrGrpChildList = result.data.addrGrpChildList.map((e) => {
              return {
                ...e,
                selected: false,
                moGroupNm: e.groupNm,
              };
            });
            this.totalCnt = result.data.totalCnt;
            this.nAppointCnt = result.data.nAppointCnt;
  
            if (levDepth == 1 && groupSeq == 0 && parentGroupSeq == 0) {
              this.grpSelBtn = false;
              this.nAppointActive = true;
              this.active = false;
              this.parentGroupSeq = "0";
              this.srchGroupSeq = "0";
              this.srchLevDepth = "1";
              this.selAddrCnt = this.nAppointCnt;
              this.realAddrCnt = this.nAppointCnt;
              this.selGroupNm = "그룹 없음";
            } else {
              this.grpSelBtn = true;
              if (levDepth == 1) {
                this.addrGrpList.forEach(function (tree) {
                  if (tree.groupSeq == groupSeq) {
                    tree.isActive = "Y";
                    tree.classActive = "Y";
                    addrGrpInfoHash = Object.assign({}, tree);
                  }
                });
                this.addrGrpInfo = Object.assign({}, addrGrpInfoHash);
                this.active = false;
                this.nAppointActive = false;
                this.parentGroupSeq = this.addrGrpInfo.parentGroupSeq + "";
                this.srchGroupSeq = this.addrGrpInfo.groupSeq + "";
                this.srchLevDepth = this.addrGrpInfo.levDepth;
                this.selGroupNm = this.addrGrpInfo.groupNm;
                this.selAddrCnt = this.addrGrpInfo.addrCnt;
                this.realAddrCnt = this.addrGrpInfo.realAddrCnt;
              } else if (levDepth == 2) {
                this.addrGrpList.forEach(function (tree) {
                  if (tree.groupSeq == parentGroupSeq) {
                    tree.isActive = "Y";
                  }
                });
                this.addrGrpChildList.forEach(function (tree) {
                  if (tree.groupSeq == groupSeq) {
                    tree.isActive = "Y";
                    tree.classActive = "Y";
                    addrGrpInfoHash = Object.assign({}, tree);
                  }
                });
                this.addrGrpInfo = Object.assign({}, addrGrpInfoHash);
                this.active = false;
                this.nAppointActive = false;
                this.parentGroupSeq = this.addrGrpInfo.parentGroupSeq + "";
                this.srchGroupSeq = this.addrGrpInfo.groupSeq + "";
                this.srchLevDepth = this.addrGrpInfo.levDepth;
                this.selGroupNm = this.addrGrpInfo.groupNm;
                this.selAddrCnt = this.addrGrpInfo.addrCnt;
                this.realAddrCnt = this.addrGrpInfo.realAddrCnt;
              } else {
                this.active = true;
                this.nAppointActive = false;
                this.srchGroupSeq = "";
                this.srchLevDepth = "0";
                this.selGroupNm = "전체";
              }
            }
            this.$refs.updatePaging.fnAllDecrease();
          } else {
            confirm.fnAlert("", "오류가 발생하였습니다.", "alert");
          }
        });
      }
    },
    ModalOpen() {
      this.addMemInfoList = [
        { addGroupSeq: "A", name: "", phone: "" },
        { addGroupSeq: "A", name: "", phone: "" },
        { addGroupSeq: "A", name: "", phone: "" },
        { addGroupSeq: "A", name: "", phone: "" },
        { addGroupSeq: "A", name: "", phone: "" },
        { addGroupSeq: "A", name: "", phone: "" },
        { addGroupSeq: "A", name: "", phone: "" },
        { addGroupSeq: "A", name: "", phone: "" },
        { addGroupSeq: "A", name: "", phone: "" },
        { addGroupSeq: "A", name: "", phone: "" },
      ];
      this.regMemOpen = true;
    },
    ModalChildClose() {
      this.regMemOpen = false;
    },
    fnCantReg() {
      confirm.fnAlert("", "로그인 후 이용할 수 있습니다.", "alert");
      return;
    },
    // handleScroll(e) {
    //   const chooseListThWrap = e.target.parentNode.children[0];
    //   chooseListThWrap.scrollLeft = e.target.scrollLeft;
    // },
    // handleThScroll(e) {
    //   const chooseListWrap = e.target.parentNode.children[1];
    //   chooseListWrap.scrollLeft = e.target.scrollLeft;
    // },
  },
};
</script>
<style scoped>
  .tab_addr {
    margin-top: 50px;
    background-color: #ffffff;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    margin: 0 auto;
    position: relative;}

  .tab_addr_mobi {
    margin-top: 0px;
    background-color: #ffffff;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    position: relative;
    margin-bottom: 70px;
  }
  /* 탭 스타일 */
  .tab_item {
    width: 25%;
    height: 50px;
    border-bottom: 3px solid #35354f;
    background-color: #f8f8f8;
    line-height: 50px;
    font-size: 16px;
    text-align: center;
    color: #35354f;
    display: block;
    float: left;
    text-align: center;
    font-weight: bold;
    transition: all 0.2s ease;
    border-radius: 5px 5px 0px 0px;}

  .tab_item_mobi {
    width: 50%;
    height: 50px;
    border-bottom: 3px solid #35354f;
    background-color: #f8f8f8;
    line-height: 50px;
    font-size: 16px;
    text-align: center;
    color: #35354f;
    display: block;
    float: left;
    text-align: center;
    font-weight: bold;
    transition: all 0.2s ease;
    border-radius: 5px 5px 0px 0px;}

  input[name="tab_item"] {
  display: none;
  }
  .tab_item:hover {
  opacity: 0.9;
  }

  input[name="tab_item_mobi"] {
  display: none;
  }
  .tab_item_mobi:hover {
  opacity: 0.9;
  }
  .tab_addr input:checked + .tab_item {
  background-color: #35354f;
  color: #fff;
  } 
  .tab_addr_mobi input:checked + .tab_item_mobi {
  background-color: #35354f;
  color: #fff;
  } 
</style>
