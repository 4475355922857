<template>
  <!--	s: m_section	-->
  <section class="m_section">
    <!--	s: 	contents-->
    <div class="contents">
      <MobileGnb />
      <div class="tabmenu_wrap">
        <ul class="tabmenu2">
          <li class="tabl" @click="fnMoveTab('send')"><span>전송 메시지 내역</span></li>
          <li class="tabl on"><span>예약 메시지 내역</span></li>
          <li class="tabl" @click="fnMoveTab('stat')"><span>사용자 전송 통계</span></li>
        </ul>
      </div>
      <div class="tabcont_wrap reservation_wrap">
        <div class="tabcont on">
          <h1 class="title with_desc">예약 메시지 내역</h1>
          <div class="tabcontTop">
            <ul>
              <li>예약 시간 5분 전까지 메시지를 수정하거나 예약 전송을 취소할 수 있습니다.</li>
              <li>예약을 취소하면 목록에서 사라지며, 입력한 메시지는 복구할 수 없습니다.</li>
            </ul>
          </div>
          <!-- s: search_box -->
          <div class="search_box">
            <div class="line">
              <div class="group group01">
                <label for="right" class="label">날짜</label>
                <div class="date_wrap">
                  <input
                    type="date"
                    :max="rsvTmLast"
                    class="date"
                    v-model="rsvTmFirst"
                    @click="fndatePick"
                    @change="fnRsvSendMessageSearch"
                  /><span></span>
                  <input
                    type="date"
                    :min="rsvTmFirst"
                    class="date"
                    v-model="rsvTmLast"
                    @click="fndatePick"
                    @change="fnRsvSendMessageSearch"
                  />
                </div>
              </div>
              <div class="group group02">
                <label for="right" class="label"></label>
                <div class="radio_wrap">
                  <ul>
                    <li><input type="radio" class="radio" v-model="dateRange" id="week" value="week" @change="fnRagnge" /><label for="week">1주일</label></li>
                    <li><input type="radio" class="radio" v-model="dateRange" id="days15" value="halfmon" @change="fnRagnge" /><label for="days15">15일</label></li>
                    <li><input type="radio" class="radio" v-model="dateRange" id="month" value="month" @change="fnRagnge" /><label for="month">1개월</label></li>
                  </ul>
                </div>
              </div>
            </div>
            <div class="line">
              <div class="group group03">
                <label for="right" class="label">문자메시지 종류</label>
                <div class="select_wrap">
                  <select v-model="chStr" class="select" @change="fnRagnge">
                    <option value="" selected>문자메시지 종류 전체</option>
                    <option value="SMS">단문 메시지(SMS)</option>
                    <option value="LMS">장문 메시지(LMS)</option>
                    <option value="MMS">사진/영상 메시지(MMS)</option>
                    <option value="KKO_NOTI">알림톡</option>
                  </select>
                </div>
              </div>
              <div class="group group04">
                <label for="right" class="label">제목 / 카카오 비즈메시지 템플릿명 / 내용</label>
                <div class="search_wrap">
                  <input type="text" v-model="srchStr" class="search2" placeholder="제목, 템플릿명, 내용 중 하나를 입력하세요." @keypress.enter="fnRsvSendMessageSearch" />
                </div>
              </div>
              <div class="group group05">
                <label for="right" class="label"></label>
                <button class="btn" @click="fnRsvSendMessageSearch">조회</button>
              </div>
            </div>
          </div>
          <!-- e: search_box -->
          <!-- s: list_area -->
          <div class="list_area">
            <div class="list_info">
              <div class="left_wrap">
                <div class="select_wrap">
                  <select v-model="selected" @change="fnSelected" class="select">
                    <option value="30" selected>30</option>
                    <option value="50">50</option>
                    <option value="100">100</option>
                  </select>
                </div>
                <p class="total">
                  전체 <span class="pink">{{ totCnt }} </span>건
                </p>
              </div>
            </div>

            <div class="fixed-table-cover">
              <div class="fixed-table-container" style="width: 1074px">
                <div class="fixed-table-header-bg"></div>
                <div class="fixed-table-wrapper">
                  <table class="fixed-table" style="table-layout: fixed">
                    <thead>
                      <tr>
                        <th class="td_chk" style="width: 40px">
                          <div class="th-text">
                            <input type="checkbox" id="listCheck_all" class="chk_all" @change="fnListChkAll" v-model="listAllChecked" :disabled="totCnt == 0" /><label for="listCheck_all"></label>
                          </div>
                        </th>
                        <th class="td_date" style="width: 15%">
                          <div class="th-text">예약일자</div>
                        </th>
                        <th class="td_sender" style="width: 10%">
                          <div class="th-text">전송자 명</div>
                        </th>
                        <th class="td_name" style="width: 27%">
                          <div class="th-text">제목(템플릿명)</div>
                        </th>
                        <th class="td_reservation" style="width: 7%">
                          <div class="th-text">예약 유형</div>
                        </th>
                        <th class="td_type" style="width: 7%">
                          <div class="th-text line130">문자메시지 종류</div>
                        </th>
                        <th class="td_count" style="width: 7%">
                          <div class="th-text">전송 건수</div>
                        </th>
                        <th class="td_cancel" style="width: 7%">
                          <div class="th-text">예약 취소</div>
                        </th>
                        <th class="td_edit" style="width: 7%">
                          <div class="th-text line130">일반문자 수정</div>
                        </th>
                        <th class="td_edit" style="width: calc(13% - 40px)">
                          <div class="th-text">상태</div>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-if="totCnt == 0" class="empty">
                        <td colspan="10">검색 결과가 없습니다.</td>
                      </tr>
                      <tr v-for="(data, index) in sendRsvMessageList" :key="index" :class="{ on: data.targetChk }">
                        <td class="td_chk"><input type="checkbox" class="chk" :id="'listCheck_' + index" v-model="data.targetChk" /><label :for="'listCheck_' + index"></label></td>
                        <td class="td_date">{{ data.rsvTm }}</td>
                        <td class="td_sender">{{ data.userNm }}</td>
                        <td class="td_name">
                          <p class="ellipsis">{{ data.title | emptyString("제목없음") }}</p>
                        </td>
                        <td class="td_reservation">{{ data.rptYn }}</td>
                        <td class="td_type">{{ data.chStrNm }}</td>
                        <td class="td_count">{{ data.sendCnt }}건</td>
                        <td class="td_cancel"><button class="btn white del" @click="fnRsvCancelConfirm(data)"></button></td>
                        <td class="td_edit"><a class="btn white edit" @click="fnModiefy(data)"></a></td>
                        <td class="td_count">{{ data.sttusCdNm }}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>

            <!--
            <div class="list_cover">
              <div class="list_th_wrap" @scroll="handleThScroll($event)">
                <ul class="list_th">
                  <li class="td_chk w40">
                    <input type="checkbox" id="listCheck_all" class="chk_all" @change="fnListChkAll" v-model="listAllChecked" :disabled="totCnt == 0" /><label for="listCheck_all"></label>
                  </li>
                  <li class="td_date w170"><span>예약일자</span></li>
                  <li class="td_sender w100"><span>전송자 명</span></li>
                  <li class="td_name w278"><span>제목(템플릿명)</span></li>
                  <li class="td_reservation w90"><span>예약 유형</span></li>
                  <li class="td_type w78"><span>문자메시지 종류</span></li>
                  <li class="td_count w78"><span>전송 건수</span></li>
                  <li class="td_cancel w78"><span>예약 취소</span></li>
                  <li class="td_edit w78"><span>일반문자 수정</span></li>
                  <li class="td_edit w78"><span>상태</span></li>
                </ul>
              </div>
              <div class="list_wrap scr_wrap" @scroll="handleScroll($event)">
                <ul class="list">
                  <li v-if="totCnt == 0" class="empty">
                    <p>검색 결과가 없습니다.</p>
                  </li>
                  <li v-for="(data, index) in sendRsvMessageList" :key="index" :class="{ on: data.targetChk }">
                    <span class="td_chk w40"> <input type="checkbox" class="chk" :id="'listCheck_' + index" v-model="data.targetChk" /><label :for="'listCheck_' + index"></label> </span>
                    <span class="td_date w170">{{ data.rsvTm }}</span>
                    <span class="td_sender w100">{{ data.userNm }}</span>
                    <span class="td_name w278"
                      ><p class="ellipsis">{{ data.title | emptyString("제목없음") }}</p></span
                    >
                    <span class="td_reservation w90">{{ data.rptYn }}</span>
                    <span class="td_type w78">{{ data.chStrNm }}</span>
                    <span class="td_count w78">{{ data.sendCnt }}건</span>
                    <span class="td_cancel w78"><button class="btn white del" @click="fnRsvCancelConfirm(data)"></button></span>
                    <span class="td_edit w78"><a class="btn white edit" @click="fnModiefy(data)"></a></span>
                    <span class="td_count w78">{{ data.sttusCdNm }}</span>
                  </li>
                </ul>
              </div>
            </div> -->
            <div class="list_bottom">
              <button class="btn white small" type="button" @click="fnChkRsvCancelConfirm">예약 취소</button>
            </div>
            <div class="paging_wrap" v-show="totCnt > listSize">
              <!-- 페이징 컴퍼넌트 -->
              <PageLayer ref="updatePaging" @fnClick="fnSearch" :listTotalCnt="totCnt" :selected="listSize" :pageNum="pageNo"></PageLayer>
            </div>
          </div>
          <!-- e: list_area -->
        </div>
      </div>
    </div>
    <!--	e: 	contents	-->
  </section>
  <!--	e: m_section	-->
</template>
<script>
import store from "@/store";
import sendManageApi from "@/modules/sendManage/service/sendManageApi";
import confirm from "@/modules/commonUtil/service/confirm";
import { eventBus } from "@/modules/commonUtil/service/eventBus";
import PageLayer from "@/components/PageLayer.vue";
import MobileGnb from "@/components/MobileGnb.vue";

export default {
  name: "rsvSendMsg",
  components: { PageLayer, MobileGnb },
  props: {
    autCd: String,
  },
  data() {
    return {
      listView: false,
      smsModiView: false,
      alarmTalkView: false,
      // 개편
      userDiv: true,
      selected: "30",
      listSize: 30, // select 박스 value (출력 갯수 이벤트)
      pageNo: 1, // 현재 페이징 위치
      totCnt: 0, //전체 리스트 수
      offset: 0, //페이지 시작점,
      rsvTmFirst: this.$gfnCommonUtils.getCurretDate(), //검색 시작일
      rsvTmLast: this.$gfnCommonUtils.getCurretDate(), //검색 종료일
      chStr: "", //검색 메세지 타입
      chStrNm: "",
      srchStr: "", //검색 템플릿명 내용
      dateRange: "month", //검색 날짜 범위
      sendRsvMessageList: [], //예약전송내역 리스트 초기 변수
      listAllChecked: false,
      listChkBox: [],
      rsvIds: [], //취소 rsvId list
      listScrollPostion: 0,
    };
  },
  created() {
    this.fnInit();
  },
  mounted() {
    this.listView = true;
  },
  methods: {
    fnListView() {
      this.rsvId = "";
      this.listView = true;
      this.smsModiView = false;
      this.alarmTalkView = false;
      this.$refs.rsvList.fnSearch();
    },
    fnModifyMove(modiDiv, rsvId) {
      if (modiDiv == "SMS") {
        this.smsModiView = true;
        this.listView = false;
        this.alarmTalkView = false;
        this.$refs.smsModi.fnInit(rsvId);
      } else {
        this.rsvId = rsvId;
        this.listView = false;
        this.smsModiView = false;
        this.alarmTalkView = true;
      }
    },
    fnInit() {
      const isAuth = store.getters["login/isAuth"];
      if (isAuth) {
        if (this.autCd == "H002") {
          this.userDiv = false;
        }
      }
      this.rsvTmLast = this.$gfnCommonUtils.strDateAddMonthDt(this.rsvTmFirst, 1);
      this.fnRsvMessageList();
    },
    fnRsvMessageList() {
      const isAuth = store.getters["login/isAuth"];
      if (isAuth) {
        if (this.$gfnCommonUtils.isEmpty(this.rsvTmFirst)) {
          confirm.fnAlert("", "검색 시작일을 선택해 주세요.", "alert");
          return false;
        }

        if (this.$gfnCommonUtils.isEmpty(this.rsvTmLast)) {
          confirm.fnAlert("", "검색 종료일을 선택해 주세요.", "alert");
          return false;
        }

        if (this.rsvTmFirst.replaceAll("-", "") > this.rsvTmLast.replaceAll("-", "")) {
          confirm.fnAlert("", "검색 시작일이 종료일보다 클 수 없습니다.", "alert");
          return false;
        }
        /* 사업부 요청으로 인한 검색 기간 제한 삭제 2023-11-24
        if (this.rsvTmLast.replaceAll("-", "") > this.$gfnCommonUtils.strDateAddMonthDt(this.rsvTmFirst, 6).replaceAll("-", "")) {
          confirm.fnAlert("", "날짜 검색 범위는 6개월 이상을 검색할 수 없습니다.", "alert");
          return false;
        }
        */
        let param = {
          pageNo: this.pageNo, //페이징 필수 값
          listSize: this.listSize, //페이징 필수 값
          rsvTmFirst: this.rsvTmFirst,
          rsvTmLast: this.rsvTmLast,
          chStr: this.chStr,
          srchStr: this.srchStr,
        };
        sendManageApi.selectRsvMessageList(param).then((response) => {
          var result = response.data;
          if (result.success) {
            if (!this.$gfnCommonUtils.isEmpty(result.data.sendRsvMessageList)) {
              //서버 조회 리스트 셋팅
              this.sendRsvMessageList = result.data.sendRsvMessageList.map((e) => {
                return {
                  ...e,
                  targetChk: false,
                };
              });
            } else {
              this.sendRsvMessageList = [];
            }
            this.totCnt = result.data.pageInfo.totCnt; //전체 카운트
            this.offset = result.data.pageInfo.offset; //페이지 번호
          } else {
            confirm.fnAlert("", "오류가 발생하였습니다.", "alert");
          }
        });
      }
    },
    fnSelected() {
      //리스트 갯수 선택
      this.listSize = Number(this.selected);
      this.$refs.updatePaging.fnAllDecrease();
    },
    fnRsvSendMessageSearch() {
      this.$refs.updatePaging.fnAllDecrease();
    },
    fnSearch(pageNum) {
      const isAuth = store.getters["login/isAuth"];
      if (isAuth) {
        // 검색
        this.pageNo = this.$gfnCommonUtils.defaultIfEmpty(pageNum, "1") * 1;
        this.fnRsvMessageList();
      }
    },
    fnRagnge() {
      //날짜 검색 범위 변경에 따른 날짜 처리
      if (this.dateRange == "week") {
        this.rsvTmLast = this.$gfnCommonUtils.strDateAddDay(this.rsvTmFirst, 7);
      }
      if (this.dateRange == "halfmon") {
        this.rsvTmLast = this.$gfnCommonUtils.strDateAddDay(this.rsvTmFirst, 15);
      }
      if (this.dateRange == "month") {
        this.rsvTmLast = this.$gfnCommonUtils.strDateAddMonthDt(this.rsvTmFirst, 1);
      }
      this.fnRsvSendMessageSearch();
    },
    fndatePick() {
      //날짜 클릭 시 날짜 범위 선택 라디오 초기화
      this.dateRange = "";
    },
    //리스트 전체 체크박스
    fnListChkAll() {
      if (this.listAllChecked) {
        this.sendRsvMessageList.forEach(function (rsvInfo) {
          rsvInfo.targetChk = true;
        });
      } else {
        this.sendRsvMessageList.forEach(function (rsvInfo) {
          rsvInfo.targetChk = false;
        });
      }
    },
    fnRsvCancelConfirm(rsvInfo) {
      var rsvIds = [];
      var rsvDt = new Date(rsvInfo.realRsvTm);
      var today = new Date();
      var diffTime = Math.round((rsvDt.getTime() - today.getTime()) / (1000 * 60));

      if (diffTime <= 5) {
        confirm.fnAlert("", "예약 메시지는 전송 5분전에는 취소가 불가합니다.", "alert");
        return false;
      }
      rsvIds.push(rsvInfo.rsvId);
      eventBus.$on("callbackEventBus", () => {
        this.fnRsvCancel(rsvIds);
      });
      confirm.fnConfirm("", "예약 메시지 전송을 취소하시겠습니까?<br />취소할 경우 메시지 복구가 불가능합니다.", "confirm");
    },
    fnChkRsvCancelConfirm() {
      var check = false;
      var rsvIds = [];
      let checkDiv = 0;
      this.sendRsvMessageList.forEach(function (rsvInfo) {
        if (rsvInfo.targetChk) checkDiv++;
      });
      if (checkDiv == 0) {
        confirm.fnAlert("", "취소 할 예약 메시지를 선택해 주세요.", "alert");
        return false;
      }

      this.sendRsvMessageList.forEach(function (rsvInfo) {
        if (rsvInfo.targetChk) {
          rsvIds.push(rsvInfo.rsvId);
          var rsvDt = new Date(rsvInfo.realRsvTm);
          var today = new Date();
          var diffTime = Math.round((rsvDt.getTime() - today.getTime()) / (1000 * 60));
          if (diffTime <= 5) {
            check = true;
            return false;
          }
        }
      });
      if (check) {
        confirm.fnAlert("", "예약 메시지는 전송 5분전에는 취소가 불가합니다.", "alert");
        return false;
      }
      eventBus.$on("callbackEventBus", () => {
        this.fnRsvCancel(rsvIds);
      });
      confirm.fnConfirm("", "예약 메시지 전송을 취소하시겠습니까?<br />취소할 경우 메시지 복구가 불가능합니다.", "confirm");
    },
    fnRsvCancel(rsvIds) {
      let param = {
        rsvIds: rsvIds,
      };
      sendManageApi.cancelRsvMessage(param).then((response) => {
        var result = response.data;
        if (result.success) {
          this.$emit("chargeInfoCall");
          eventBus.$on("callbackEventBus", this.fnSearch);
          confirm.fnConfirm("", "예약메시지가 취소되었습니다.", "cbAlert");
        } else {
          eventBus.$on("callbackEventBus", this.fnRsvMessageList);
          confirm.fnConfirm("", "이미 예약 취소된 건입니다.", "cbAlert");
        }
      });
    },
    fnModiefy(rsvInfo) {
      var rsvDt = new Date(rsvInfo.realRsvTm);
      var today = new Date();
      var diffTime = Math.round((rsvDt.getTime() - today.getTime()) / (1000 * 60));
      if (diffTime <= 5) {
        confirm.fnAlert("", "전송 5분전에는 수정할 수 없습니다.", "alert");
        return;
      }
      if (rsvInfo.chStr === "KKO_NOTI") {
        this.$router.push({ path: `/sendManage/rsvAlimModi/${rsvInfo.rsvId}` });
      } else {
        this.$router.push({ path: `/sendManage/rsvMsgSmsModi/${rsvInfo.rsvId}` });
      }
    },
    fnMoveTab(type) {
      if (type == "rsv") {
        this.$router.push({ path: "/sendManage/rsvSendMessage" });
      } else if (type == "stat") {
        this.$router.push({ path: "/sendManage/sendMessageStats" });
        /* if (this.userDiv) {
          this.$router.push({ path: "/sendManage/sendMessageStats" });
        } else {
          confirm.fnAlert("", "관리자ID만 접근 가능합니다.", "alert");
          return;
        } */
      } else {
        this.$router.push({ path: "/sendManage/sendMessage" });
      }
    },
    /*handleScroll(e) {
      const chooseListThWrap = e.target.parentNode.children[0];
      chooseListThWrap.scrollLeft = e.target.scrollLeft;
    },
    handleThScroll(e) {
      const chooseListWrap = e.target.parentNode.children[1];
      chooseListWrap.scrollLeft = e.target.scrollLeft;
    },*/
  },
};
</script>
