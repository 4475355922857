<template>
  <!--	s: nav	-->
  <nav class="nav_wrap on" :class="{ login: isAuth, used: isAuth, logout: !isAuth }">
    <button class="nav_btn on" @click="navToggle()"></button>
    <button class="btn_prev off" @click="btnPrev($event)"></button>
    <div class="nav">
      <ul class="nav_cont_wrap">
        <li class="nav_cont nav_cont01">
          <div class="nav_icon nav_icon01">
            <a @click="ModalOpen('price_pop')" style="cursor: pointer">
              <div class="tooltip l_tooltip">
                <p>사용 가능 금액</p>
              </div>
            </a>
          </div>
          <div class="nav_detail">
            <div class="greeting">
              <p class="g_txt" :style="!rsvSendDiv ? 'width : 100%' : ''">
                <span class="user_name" v-if="isAuth">
                  <span class="user_name_bold" :style="!rsvSendDiv ? 'max-width : 90%' : ''">{{ userNm }}({{ userId | textLengthCut(8, "…") }})</span>님<br />
                </span>
                안녕하세요
              </p>
              <div v-if="rsvSendDiv && isAuth" class="btn_alarm tt_btn on" @mouseover="AlarmDetailOpen($event)" @mouseout="AlarmDetailClose($event)">
                <!-- class="on" : 핑크색 dot 추가-->
                <div class="tooltip l_tooltip" :style="navTooltip ? 'display : block' : ''">
                  <p>
                    이번 달 <span class="b">{{ rsvSendStr }}</span> 예약 전송 대기 중 입니다.
                  </p>
                  <router-link to="/sendManage/rsvSendMessage" class="link">[예약 메시지 보기]</router-link>
                  <ul class="tt_notice">
                    <li>이번 달 요금제를 다 사용하면 예약된 모든 메시지는 자동 취소됩니다.</li>
                    <li>요금제와 예약건수를 미리 확인해주세요</li>
                  </ul>
                </div>
              </div>
            </div>
            <div class="amount_wrap">
              <div class="amount_tit">
                <!--로그인-->
                <p class="type" v-if="isAuth && chargeNm != ''">{{ chargeNm }}</p>
                <!--비 로그인-->
                <p class="type" v-else>-</p>
                <div class="flex_container">
                  <!-- <a href="#" title="요금제명">요금제명</a> -->
                  <div class="change_wrap">
                    <p style="display: block; color: #fff; font-size: 12px; font-weight: 400; letter-spacing: -0.005em; line-height: 18px">요금제명</p>
                    <div class="btn_question tt_btn">
                      <div class="tooltip l_tooltip">
                        <p>요금제는 월 1회만</p>
                        <p>변경할 수 있습니다.</p>
                      </div>
                    </div>
                  </div>
                  <!-- 선불 요금제일 경우에만 노출 -->
                  <router-link v-if="joinDiv == '02'" to="/charge/chargeProc" class="btn plus">충전</router-link>
                </div>
              </div>
              <!--비 로그인-->
              <ul v-if="!isAuth" class="amount no_login">
                <li>
                  <div class="left">
                    <div class="left_flex">
                      <p class="left_title">남은 금액</p>
                      <div class="btn_question tt_btn">
                        <div class="tooltip l_tooltip">
                          <p>'기본'과 '이월'의 합이</p>
                          <p>가입 요금제의 2배를 초과할 수 없습니다.</p>
                        </div>
                      </div>
                    </div>
                    <p class="left_price">-</p>
                  </div>
                  <div class="right">
                    <p><span>기본</span><span>-</span></p>
                    <p><span>이월</span><span>-</span></p>
                  </div>
                </li>
                <li>
                  <div class="left">
                    <p class="left_title">사용한 금액</p>
                    <p class="left_price">-</p>
                  </div>
                  <div class="right">
                    <p><span>기본</span><span>-</span></p>
                    <p><span>이월</span><span>-</span></p>
                    <p><span>추가</span><span>-</span></p>
                  </div>
                </li>
              </ul>
              <!-- 로그인 -->
              <ul v-if="isAuth" class="amount">
                <li class="amount_li" v-if="chargeNm != '자유요금제'"><!-- 230614 수정 / 20240125 자유요금제 추가 -->  
                  <div class="left">
                    <div class="left_flex">
                      <p class="left_title">남은 금액</p>
                      <div class="btn_question tt_btn">
                        <div class="tooltip l_tooltip">
                          <p>'기본'과 '이월'의 합이</p>
                          <p>가입 요금제의 2배를 초과할 수 없습니다.</p>
                        </div>
                      </div>
                    </div>
                    <p class="left_price" style="font-size: 15px; font-weight: normal" v-if="subSttusCd === '00' || subSttusCd === '01'">서비스 체험</p>
                    <p class="left_price" style="font-size: 15px; font-weight: normal" v-else-if="subSttusCd === '03'">이용 중지</p>
                    <p class="left_price" style="font-size: 15px; font-weight: normal" v-else-if="subSttusCd === '04'">미납 정지</p>
                    <!-- <p class="left_price" :style="subSttusCd === '02' || openDay ? 'font-size: 15px; font-weight: normal; line-height: 1.2;' : ''" v-else-if="subSttusCd === '02' || openDay">
                      쓴 만큼 <br />(또는 종량제)
                    </p> -->
                    <p class="left_price" v-else>{{ remainingAmt ? remainingAmt : "-" | formatPrice }}</p>
                  </div>
                  <div v-if="joinDiv == '01'" class="right">
                    <p>
                      <span>기본</span><span>{{ pricePlanBalance ? pricePlanBalance : "-" | formatPrice }}</span>
                    </p>
                    <p>
                      <span>이월</span><span>{{ cFwdBalance ? cFwdBalance : "-" | formatPrice }}</span>
                    </p>
                    <p v-show="chrgBalance>0">
                      <span>충전</span><span>{{ chrgBalance | formatPrice }}</span>
                    </p>
                  </div>
                  <div v-if="joinDiv == '02'" class="right">
                    <p>
                      <span>충전</span><span>{{ chrgBalance+pricePlanBalance+cFwdBalance | formatPrice }}</span>
                    </p>
                  </div>                  
                  <!-- 230614 수정 -->
                  <div class="info_area" v-if="subSttusCd === '04'">
                    <div class="content_box">
                      <div class="content_box_left">
                        <div class="icon_box"></div>
                      </div>
                      <div class="content_box_right">
                        <p class="text_box">
                          요금 완납 후 1시간 후<br>메시지 발송이 가능합니다.
                        </p>
                      </div>
                    </div>
                  </div>
                </li>
                <li class="amount_li" v-else><!-- 20240125 자유요금제 추가 -->  
                  <div class="left">
                    <div class="left_flex">
                      <p class="left_title">사용한 금액</p>
                    </div>
                    <p class="left_price" style="font-size: 15px; font-weight: normal" v-if="subSttusCd === '00' || subSttusCd === '01'">서비스 체험</p>
                    <p class="left_price" style="font-size: 15px; font-weight: normal" v-else-if="subSttusCd === '03'">이용 중지</p>
                    <p class="left_price" style="font-size: 15px; font-weight: normal" v-else-if="subSttusCd === '04'">미납 정지</p>
                    <!-- <p class="left_price" :style="subSttusCd === '02' || openDay ? 'font-size: 15px; font-weight: normal; line-height: 1.2;' : ''" v-else-if="subSttusCd === '02' || openDay">
                      쓴 만큼 <br />(또는 종량제)
                    </p> -->
                    <p class="left_price">{{ usedAmt ? usedAmt : "-" | formatPrice }}</p>
                  </div>
                  <!-- 230614 수정 -->
                  <div class="info_area" v-if="subSttusCd === '04'">
                    <div class="content_box">
                      <div class="content_box_left">
                        <div class="icon_box"></div>
                      </div>
                      <div class="content_box_right">
                        <p class="text_box">
                          요금 완납 후 1시간 후<br>메시지 발송이 가능합니다.
                        </p>
                      </div>
                    </div>
                  </div>
                </li>
                <li v-if="chargeNm != '자유요금제'">
                  <div class="left">
                    <p class="left_title">사용한 금액</p>
                    <p class="left_price">{{ usedAmt ? usedAmt : "-" | formatPrice }}</p>
                  </div>
                  <div v-if="joinDiv == '01'" class="right">
                    <p>
                      <span>기본</span><span>{{ pricePlanUsedAmt ? pricePlanUsedAmt : "-" | formatPrice }}</span>
                    </p>
                    <p>
                      <span>이월</span><span>{{ cFwdBalanceUsedAmt ? cFwdBalanceUsedAmt : "-" | formatPrice }}</span>
                    </p>
                    <p>
                      <span>추가</span><span>{{ addUsedAmt ? addUsedAmt : "-" | formatPrice }}</span>
                    </p>
                    <p v-show="chrgAmt>0">
                      <span>충전</span><span>{{ chrgAmt | formatPrice }}</span>
                    </p>
                  </div>
                  <div v-if="joinDiv == '02'" class="right">
                    <p>
                      <span>충전</span><span>{{ chrgAmt+pricePlanUsedAmt+cFwdBalanceUsedAmt | formatPrice }}</span>
                    </p>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </li>
        <li class="nav_cont nav_cont02">
          <div class="nav_icon nav_icon02">
            <a @click="ModalOpen('message_pop')" style="cursor: pointer">
              <div class="tooltip l_tooltip">
                <p>사용할 수 있는 메시지 종류</p>
              </div>
            </a>
          </div>
          <div class="nav_detail">
            <div class="nav_cont_tit">사용할 수 있는 메시지 종류</div>
            <ul class="service_wrap">
              <li class="service"><div class="toggle message" :class="{ used: isSms && isAuth }">일반문자</div></li>
              <li class="service"><div class="toggle kakao" :class="{ used: isAlt && isAuth }">카카오 비즈 메시지</div></li>
              <!-- <li class="service disabled"><div class="toggle disabled">친구톡</div></li> -->
              <!-- <li class="service disabled" ><div class="toggle disabled">RCS</div></li> -->
              <!-- class="disabled" 비활성화 -->
            </ul>
          </div>
        </li>
        <template v-for="(item, i) in treeData">
          <li v-if="subSttusCd !== '00' && subSttusCd !== '01' && ((item.menuNo !== '2000') || (joinDiv == '02' && item.menuNo == '2000'))" :class="'nav_cont nav_cont0' + (i + 3) + ' ' + ($route.path.indexOf(item.menuUrl) > -1 ? 'on' : '')" :key="i">
            <div :class="'nav_icon nav_icon0' + (i + 3)" @click="fnNavCtl(item.menuUrl)" :style="navHide ? 'cursor:pointer' : ''">
              <router-link :to="item.menuUrl">
                <div class="tooltip l_tooltip">
                  <p>{{ item.menuNm }}</p>
                </div>
              </router-link>
            </div>
            <div class="nav_detail">
              <div class="nav_cont_tit" :class="{ active: $route.path.includes(item.menuUrl) }" @click="navMenuOn">
                <button class="nav_conta01">{{ item.menuNm }}<span class="arrow"></span></button>
              </div>
              <div class="depth2_wrap">
                <ul class="depth2">
                  <li class="nav_contl02" v-for="(childern, j) in item.children" :key="j" :class="{ on: $route.path.indexOf(childern.menuUrl) > -1 }">
                    <router-link :to="childern.menuUrl" class="nav_conta02">{{ childern.menuNm }}</router-link>
                  </li>
                </ul>
              </div>
            </div>
          </li>
          <li v-else-if="item.menuNo !== '1800' && item.menuNo !== '2000'" :class="'nav_cont nav_cont0' + (i + 3) + ' ' + ($route.path.indexOf(item.menuUrl) > -1 ? 'on' : '')" :key="i + '_'">
            <div :class="'nav_icon nav_icon0' + (i + 3)" @click="fnNavCtl(item.menuUrl)" :style="navHide ? 'cursor:pointer' : ''">
              <router-link :to="item.menuUrl">
                <div class="tooltip l_tooltip">
                  <p>{{ item.menuNm }}</p>
                </div>
              </router-link>
            </div>
            <div class="nav_detail">
              <div class="nav_cont_tit" :class="{ active: $route.path.includes(item.menuUrl) }" @click="navMenuOn">
                <button class="nav_conta01">{{ item.menuNm }}<span class="arrow"></span></button>
              </div>
              <div class="depth2_wrap">
                <ul class="depth2">
                  <li class="nav_contl02" v-for="(childern, j) in item.children" :key="j" :class="{ on: $route.path.indexOf(childern.menuUrl) > -1 }">
                    <router-link :to="childern.menuUrl" class="nav_conta02">{{ childern.menuNm }}</router-link>
                  </li>
                </ul>
              </div>
            </div>
          </li>
        </template>
      </ul>
    </div>
    <button class="btn_next" @click="btnNext"></button>
  </nav>
  <!--	e: nav	-->
</template>
<script>
import { mapGetters } from "vuex";
import loginApi from "@/modules/login/service/loginApi";

export default {
  name: "NavigationMenu",
  components: {},
  props: {
    menuList: Array,
    isSms: Boolean,
    isAlt: Boolean,
    chargeNm: String,
    rsvSendDiv: Boolean,
    rsvSendStr: String,
    joinDiv: String,
    remainingAmt: Number,
    pricePlanBalance: Number,
    cFwdBalance: Number,
    chrgBalance : Number,
    usedAmt: Number,
    pricePlanUsedAmt: Number,
    cFwdBalanceUsedAmt: Number,
    addUsedAmt: Number,
    chrgAmt : Number,
    userNm: String,
    userId: String,
    isMobile: Boolean,
    subSttusCd: String,
    openDay: Boolean,
    sendLimitAmt: Number
  },
  data() {
    return {
      treeData: [],
      myMenuList: [],
      isAuth: false,
      currentIndex: "",
      alimTooltip: false,
      navHide: false,
      navTooltip: false,
      navTimeEvent: null,
    };
  },
  computed: {
    ...mapGetters({
      getIsAuth: "login/isAuth",
    }),
  },
  watch: {
    isMobile(data) {
      if (!data) {
        let navUl = document.querySelector(".nav_cont_wrap");
        navUl.style.transform = "translateX(0px)";
      }
    },
    getIsAuth(data) {
      if (data != null && data != "" && data == true) {
        this.isAuth = true;
      } else {
        this.isAuth = false;
      }
    },
    menuList: function (data) {
      this.myMenuList = data;
      this.fnMenuList();
    },
  },
  mounted() {},
  methods: {
    fnMenuList() {
      const isAuth = this.$store.getters["login/isAuth"];
      if (isAuth) {
        this.isAuth = true;
      }
      var treeList = [];
      this.myMenuList.forEach(function (tree) {
        if (tree.menuLvl == "1" && tree.menuGubun == "L") {
          treeList.push(tree);
        }
      });
      this.treeData = treeList;
    },
    logout() {
      this.$store.commit("login/isAuth", false);
      const isAuth = this.$store.getters["login/isAuth"];
      if (!isAuth) {
        loginApi.logout({}).then((response) => {
          if (response.success) {
            //console.log("");
          }
        });
        this.$router.push({ path: "/login" });
      }
    },
    //좌측 네비게이션 온오프
    navToggle() {
      const nav = document.querySelector(".nav_wrap"),
        navBtn = document.querySelector(".nav_btn");
      nav.classList.toggle("on");
      navBtn.classList.toggle("on");
      this.tmCenter();
      this.navHide = !this.navHide;
    },
    //tm_wrap center
    tmCenter() {
      const tmWrap = document.querySelector(".tm_wrap");
      var contents = document.querySelector(".contents"),
        conL = contents.offsetLeft,
        conW = contents.clientWidth;
      tmWrap.style.left = conW * 0.5 + conL + "px";
    },
    navMenuOn(e) {
      // 화살표 버튼 눌렀을때
      const arrowBtn = e.target.classList.contains("arrow");

      // lnb 메뉴 토글이 안되서 다시 작성 220720 디자인비 김해주
      const acc = document.querySelectorAll(".nav_cont_tit");
      let parent = "";
      let grandParent = "";

      if (arrowBtn) {
        parent = e.target.parentElement.parentElement;
        grandParent = e.target.parentElement.parentElement.parentElement.parentElement;
      } else {
        parent = e.target.parentElement;
        grandParent = e.target.parentElement.parentElement.parentElement;
      }

      if (parent.classList.contains("active")) {
        parent.classList.remove("active");
        grandParent.classList.remove("on");
        return false;
      }

      for (let i = 0; i < acc.length; i++) {
        acc[i].classList.remove("active");
        acc[i].parentElement.parentElement.classList.remove("on");
      }

      parent.classList.add("active");
      grandParent.classList.add("on");
    },
    moveSlide(num) {
      let navUl = document.querySelector(".nav_cont_wrap");
      let slideW = document.querySelector(".nav_cont01").clientWidth;
      navUl.style.transform = "translateX(-" + num * slideW + "px)";
      this.currentIndex = num;
    },
    btnClassOffAdd() {
      const btnPrev = document.querySelector(".nav_wrap .btn_prev");
      const btnNext = document.querySelector(".nav_wrap .btn_next");
      btnPrev.classList.remove("off");
      btnNext.classList.remove("off");
    },
    btnPrev(e) {
      if (this.currentIndex !== 0 && this.currentIndex > 0) this.currentIndex--;
      if (this.currentIndex == 0) {
        e.target.classList.add("off");
      } else {
        this.btnClassOffAdd();
      }
      this.moveSlide(this.currentIndex);
    },
    btnNext(e) {
      let navLi = document.querySelectorAll(".nav_cont");
      let slideCount = navLi.length;
      let viewCount = 4;
      let limit = slideCount - viewCount;
      if (this.currentIndex !== slideCount - 1 && this.currentIndex < limit) this.currentIndex++;
      this.moveSlide(this.currentIndex);
      if (this.currentIndex == viewCount) {
        e.target.classList.add("off");
      } else {
        this.btnClassOffAdd();
      }
      this.moveSlide(this.currentIndex);
    },
    //모달창
    ModalOpen(target) {
      var navDimmed = document.getElementsByClassName("navDimmed");
      var wrap = document.getElementsByClassName("nav-popup-wrap");
      var obj = document.getElementsByClassName(target);
      obj[0].classList.add("open");
      navDimmed[0].style.display = "block";
      wrap[0].style.display = "block";
      obj[0].style.display = "block";
      this.ModalHeight();
      if (target == "address") {
        this.$refs.sendAddr.fnSendAddrInit();
      }
    },
    ModalClose() {
      var navDimmed = document.getElementsByClassName("navDimmed");
      var wrap = document.getElementsByClassName("nav-popup-wrap");
      var obj = wrap[0].childElementCount;
      navDimmed[0].style.display = "none";
      wrap[0].style.display = "none";
      for (var i = 0; i < obj; i++) {
        var target = document.getElementsByClassName("popup");
        target[i].style.display = "none";
        target[i].classList.remove("open");
      }
    },
    ModalHeight() {
      //팝업 heght가 device height보다 클때
      var winH = window.innerHeight,
        obj = document.querySelector(".popup.open"),
        objH = obj.clientHeight;
      if (objH >= winH) {
        obj.classList.add("over");
      }
    },
    AlarmDetailOpen(e) {
      // 알림 아이콘 마우스 오버시 이벤트
      if (e.target.classList.contains("btn_alarm")) {
        //console.log("over");
        this.navTooltip = true;
        clearTimeout(this.navTimeEvent);
      }
    },
    AlarmDetailClose(e) {
      // 알림 아이콘 마우스 아웃시 이벤트
      if (e.target.classList.contains("btn_alarm")) {
        this.navTimeEvent = setTimeout(() => {
          this.navTooltip = false;
        }, 3000);
      }
    },
    fnNavCtl(type) {
      if (this.navHide) {
        this.$router.push({ path: type });
      }
    },
  },
};
</script>
