<template>
  <section class="m_section">
    <!--	s: 	contents-->
    <div class="contents">
      <MobileGnb />
      <div class="tabcont_wrap without_tab regist_wrap">
        <h1 class="title with_desc">발신번호 등록</h1>
        <div class="tabcontTop">
          <ul>
            <li>등록할 발신번호의 명의자가 필수 서류를 제출해야 번호 등록 여부를 심사할 수 있습니다.</li>
            <li>필수 서류는 모두 3개월 이내 발급한 서류로 제출하셔야 합니다.</li>
            <li>심사는 평일 기준 48시간 안에 완료되지만, LG유플러스 사정에 따라 늦어질 수 있습니다.</li>
            <li>
              통신서비스 이용증명원은 이용 중인 통신사 고객센터에 전화하여 발급받을 수 있습니다. <br />
              <span class="tabcontTop_extra_desc">SKT 통신서비스 이용증명원은 SKT 고객센터에 생년월일 마스킹 해제를 요청하여 발급받아야 합니다.</span>
            </li>
            <li><span>※ 첨부 파일형식 : jpg, png, pdf, tiff / 파일 크기 최대 5MB</span></li>
            <!-- <li>SKT 통신서비스 이용증명원은 SKT 고객센터에 생년월일 마스킹 해제를 요청한 후 제출해야 합니다.</li> -->
          </ul>
        </div>
        <form @submit.prevent="" autocomplete="off">
          <router-link to="/sendNumberManage/sendNumberManyReg" class="btn white plus">발신번호 대량등록</router-link>
          <!-- 휴대폰 본인인증 -->
          <div class="box_wrap inputbox_wrap">
            <div class="box_top flw">
              <h3 class="tit required">휴대폰 본인인증</h3>
              <div class="top_right">
                <div class="empty">
                  <span>본인 명의의 휴대폰 번호만 발신번호 등록이 가능합니다.</span>
                </div>
              </div>
              <div class="top_right">
                <!-- 인증전 -->
                <div class="left_wrap" v-if="!selfAuth">
                  <button class="btn" type="button" @click="fnPopup">휴대폰 본인인증</button>
                  <div style="display: flex; flex-direction: column">
                    <input type="hidden" id="message" value="" />
                    <input type="hidden" id="phone" value="" />
                    <input type="hidden" id="name" value="" />
                    <input type="hidden" id="responseData" value="" />
                  </div>
                  <button id="nice" style="display: none" @click="fnMessage"></button>
                  <!-- 휴대폰번호인증 필수값 -->
                  <form name="form_chk" method="post">
                    <!-- <input type="hidden" name="recvMethodType" value="get" /> -->
                    <input type="hidden" name="m" value="checkplusService" />
                    <input type="hidden" name="EncodeData" v-model="sEncData" />
                  </form>
                </div>
                <!-- 인증완료 -->
                <div class="left_wrap" v-if="selfAuth">
                  <button class="btn" type="button" disabled>휴대폰 본인인증</button>
                  <div class="complete">인증 완료</div>
                </div>
              </div>
            </div>
          </div>
          <!-- 명의자 구분 -->
          <div class="box_wrap radio_wrap">
            <div class="box_top flw mg30t">
              <h3 class="tit required">명의자 구분</h3>
              <div class="top_right">
                <ul>
                  <li>
                    <input type="radio" name="businessman" class="radio" id="businessman1" value="01" v-model="nmineeDivCd" @change="contView('01')" />
                    <label for="businessman1">사업자(본인,대표,임직원)</label>
                  </li>
                  <li>
                    <input type="radio" name="businessman" class="radio" id="businessman2" value="02" v-model="nmineeDivCd" @change="contView('02')" />
                    <label for="businessman2">타사업자</label>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <!-- 사업자등록증 인증 전 -->
          <div class="box_wrap inputbox_wrap">
            <div class="box_top flw">
              <h3 class="tit required">사업자등록증</h3>
              <div class="top_right jfs">
                <div class="left_wrap">
                  <!-- <label for="right" class="label">사업자등록증</label> -->
                  <div class="btn plus" :class="selfAuth ? (regFile.data01.file ? 'disabled' : '') : 'disabled'">
                    파일 첨부
                    <input type="file" accept="image/jpeg, image/png, image/pdf, image/tiff" @change="onFileChange" class="form-control-file" id="data01" ref="data01" />
                  </div>
                </div>
                <div class="right_wrap">
                  <div class="file_name" v-if="regFile.data01.file">
                    <p class="ellipsis">{{ regFile.data01.file.name | fileTextLengthCut(8, "…") }} ({{ regFile.data01.file.size | formatBytes(0) }})</p>
                    <button class="btn_close" @click="removeImage('data01')"></button>
                  </div>
                  <!-- <div class="empty" v-else>등록된 파일이 없습니다.</div> -->
                </div>
              </div>
            </div>
            <!-- 사업자등록증 인증 후 -->
            <!-- <div class="box_top flw" v-if="bizrAuthYn === 'Y'">
              <h3 class="tit required">사업자등록증</h3>
              <div class="top_right">
                <div class="complete">사업자 인증 완료</div>
              </div>
            </div> -->
          </div>

          
          <!-- 신분증 (임직원 신청시) -->
          <!-- 신분증 삭제 (2023.04.10)
          <div class="box_wrap inputbox_wrap">
            <div class="box_top flw mg25t" v-if="bizrAuthYn !== 'Y'">
              <h3 class="tit">
                <span>신분증 <br />(임직원 신청시)</span>
              </h3>
              <div class="top_right">
                <div class="empty">
                  <span>임직원 신청시 신분증을 제출해주세요.</span>
                </div>
              </div>
              <div class="top_right jfs mg0t">
                <div class="left_wrap">
                  <div class="btn plus" :class="selfAuth ? (regFile.data02.file ? 'disabled' : '') : 'disabled'">
                    
                    파일 첨부
                    <input type="file" accept="image/jpeg, image/png, image/pdf, image/tiff" @change="onFileChange" class="form-control-file" id="data02" ref="data02" />
                  </div>
                </div>
                <div class="right_wrap">
                  <div class="file_name" v-if="regFile.data02.file">
                    <p class="ellipsis">{{ regFile.data02.file.name | fileTextLengthCut(8, "…") }} ({{ regFile.data02.file.size | formatBytes(0) }})</p>
                    <button class="btn_close" @click="removeImage('data02')"></button>
                  </div>
                </div>
              </div>
            </div>-->
            <!-- 신분증 (임직원 신청시) 인증 후 -->
            <!-- 신분증 삭제 (2023.04.10)
            <div class="box_top flw mg25t" v-if="bizrAuthYn === 'Y'">
              <h3 class="tit">
                <span>신분증 <br />(임직원 신청시)</span>
              </h3>
              <div class="top_right">
                <div class="complete">제출 완료</div>
              </div>
            </div>
          </div>-->

          <!-- 통신서비스 이용증명원-->
          <div class="box_wrap inputbox_wrap">
            <div class="box_top flw mg30t">
              <h3 class="tit">
                <span>통신서비스 이용증명원</span>
              </h3>
              <div class="top_right">
                <div class="empty">
                  <span>타 통신사는 이용증명원 제출해야 하고, U+의 번호자원은 제출하지 않아도 됩니다.</span>
                </div>
              </div>
              <div class="top_right jfs mg0t">
                <div class="left_wrap">
                  <div class="btn plus" :class="regFile.data01.file || ( selfAuth === true) ? (bsDoc.data04.file ? 'disabled' : '') : 'disabled'">
                    파일 첨부 <input type="file" accept="image/jpeg, image/png, image/pdf, image/tiff" @change="onFileChange" class="form-control-file" id="data04" ref="data04" />
                  </div>
                  <!-- class="btn"에  "disabled" 추가시 비활성화 -->
                </div>
                <div class="right_wrap">
                  <div class="file_name" v-if="bsDoc.data04.file">
                    <p class="ellipsis">{{ bsDoc.data04.file.name | fileTextLengthCut(8, "…") }} ({{ bsDoc.data04.file.size | formatBytes(0) }})</p>
                    <button class="btn_close" @click="removeImage('data04')"></button>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <!-- 재직증명서 (임직원 신청시) -->
          <div class="box_wrap inputbox_wrap">
            <div class="box_top flw mg30t" >
              <h3 class="tit">
                <span>재직증명서 (임직원 신청시)</span>
              </h3>
              <div class="top_right">
                <div class="empty">
                  <span>임직원은 재직증명서를 제출해야 하고, 대표자는 제출하지 않아도 됩니다.</span>
                </div>
              </div>
              <div class="top_right jfs mg0t">
                <div class="left_wrap">
                  <div class="btn plus" :class="selfAuth ? (regFile.data03.file ? 'disabled' : '') : 'disabled'">
                    파일 첨부
                    <input type="file" accept="image/jpeg, image/png, image/pdf, image/tiff" @change="onFileChange" class="form-control-file" id="data03" ref="data03" />
                  </div>
                </div>
                <div class="right_wrap">
                  <div class="file_name" v-if="regFile.data03.file">
                    <p class="ellipsis">{{ regFile.data03.file.name | fileTextLengthCut(8, "…") }} ({{ regFile.data03.file.size | formatBytes(0) }})</p>
                    <button class="btn_close" @click="removeImage('data03')"></button>
                  </div>
                </div>
              </div>
            </div>
            <!-- 재직증명서 (임직원 신청시) 인증 후 -->
            <!-- <div class="box_top flw mg30t" v-if="bizrAuthYn === 'Y'">
              <h3 class="tit">
                <span>재직증명서 <br />(임직원 신청시)</span>
              </h3>
              <div class="top_right">
                <div class="complete">제출 완료</div>
              </div>
             </div> -->
          </div>

          
          

          <!-- 명의자 : 사업자 선택시 -->
          <!-- 사업자 선택 시 추가 서류 없음 2023.04.10
          <span class="businessman1 box_wrap inputbox_wrap" v-show="nmineeDivCd === '01'">
            <div class="box_wrap inputbox_wrap">
              <div class="box_top flw">
                <h3 class="tit">제출서류</h3>
                <div class="top_right">
                  <div class="empty">
                    <span
                      >아래의 필수 서류를 등록해주세요.<br />* 통신서비스 이용증명원을 제출해야 심사 후 발신번호로 등록할 수 있습니다.<br />* 위에 입력한 발신번호와 통신서비스 이용증명원에 적힌 번호가
                      동일해야 발신번호로 등록할 수 있습니다.<br />* 임직원은 재직증명서를 제출해야 하고, 대표자는 제출하지 않아도 됩니다.<br />※ 파일 형식 : jpg, png, pdf, tiff / 파일 크기 최대
                      5MB</span
                    >
                  </div>
                </div>
                <div class="top_right jfs">
                  <div class="left_wrap">
                    <label for="right" class="label">통신서비스 이용증명원</label>
                    <div class="btn plus" :class="regFile.data01.file || (bizrAuthYn === 'Y' && selfAuth === true) ? (bsDoc.data04.file ? 'disabled' : '') : 'disabled'">
                      파일 첨부 <input type="file" accept="image/jpeg, image/png, image/pdf, image/tiff" @change="onFileChange" class="form-control-file" id="data04" ref="data04" />
                    </div>
                  </div>
                  <div class="right_wrap">
                    <div class="file_name" v-if="bsDoc.data04.file">
                      <p class="ellipsis">{{ bsDoc.data04.file.name | fileTextLengthCut(8, "…") }} ({{ bsDoc.data04.file.size | formatBytes(0) }})</p>
                      <button class="btn_close" @click="removeImage('data04')"></button>
                    </div>
                  </div>
                </div>
                <div class="top_right jfs">
                  <div class="left_wrap">
                    <label for="right" class="label">재직증명서</label>

                    <div class="btn plus" :class="regFile.data01.file || (bizrAuthYn === 'Y' && selfAuth === true) ? (bsDoc.data05.file ? 'disabled' : '') : 'disabled'">
                      파일 첨부 <input type="file" accept="image/jpeg, image/png, image/pdf, image/tiff" @change="onFileChange" class="form-control-file" id="data05" ref="data05" />
                    </div>
                  </div>
                  <div class="right_wrap">
                    <div class="file_name" v-if="bsDoc.data05.file">
                      <p class="ellipsis">{{ bsDoc.data05.file.name | fileTextLengthCut(8, "…") }} ({{ bsDoc.data05.file.size | formatBytes(0) }})</p>
                      <button class="btn_close" @click="removeImage('data05')"></button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </span>-->

          <!-- 명의자 : 타사업자 선택시 -->
          <span class="businessman2 box_wrap inputbox_wrap" v-show="nmineeDivCd === '02'">
            <!-- 제출서류 -->
            <div class="box_wrap inputbox_wrap">
              <div class="box_top flw mg30t">
                <h3 class="tit required" >위임장</h3>
                <div class="top_right jfs">
                  <div class="left_wrap">
                    <div class="btn plus" :class="regFile.data01.file || ( selfAuth === true) ? (ebsDoc.data12.file ? 'disabled' : '') : 'disabled'">
                      파일 첨부
                      <input type="file" accept="image/jpeg, image/png, image/pdf, image/tiff" @change="onFileChange" class="form-control-file" id="data12" ref="data12" />
                    </div>
                  </div>
                  <div class="right_wrap">
                    <div class="file_name" v-if="ebsDoc.data12.file">
                      <p class="ellipsis">{{ ebsDoc.data12.file.name | fileTextLengthCut(8, "…") }} ({{ ebsDoc.data12.file.size | formatBytes(0) }})</p>
                      <button class="btn_close" @click="removeImage('data12')"></button>
                    </div>
                    <!-- <div class="empty" v-else>등록된 파일이 없습니다.</div> -->
                  </div>
                </div>
              </div>
            </div>
            <div class="box_wrap inputbox_wrap">
              <div class="box_top flw mg30t">
                <h3 class="tit required" >위임-수임업체 관계 확인 문서</h3>
                <div class="top_right jfs">
                  <div class="left_wrap">
                    <div class="btn plus" :class="regFile.data01.file || ( selfAuth === true) ? (ebsDoc.data11.file ? 'disabled' : '') : 'disabled'">
                      파일 첨부 <input type="file" accept="image/jpeg, image/png, image/pdf, image/tiff" @change="onFileChange" class="form-control-file" id="data11" ref="data11" />
                    </div>
                    <!-- class="btn"에  "disabled" 추가시 비활성화 -->
                  </div>
                  <div class="right_wrap">
                    <div class="file_name" v-if="ebsDoc.data11.file">
                      <p class="ellipsis">{{ ebsDoc.data11.file.name | fileTextLengthCut(8, "…") }} ({{ ebsDoc.data11.file.size | formatBytes(0) }})</p>
                      <button class="btn_close" @click="removeImage('data11')"></button>
                    </div>
                    <!-- <div class="empty" v-else>등록된 파일이 없습니다.</div> -->
                  </div>
                </div>
              </div>
            </div>
                
                <!-- <div class="top_right jfs">
                  <div class="left_wrap">
                    <label for="right" class="label required">1-3) 법인인감증명서</label>
                    <div class="btn plus" :class="regFile.data01.file || (bizrAuthYn === 'Y' && selfAuth === true) ? (ebsDoc.data13.file ? 'disabled' : '') : 'disabled'">
                      파일 첨부
                      <input type="file" accept="image/jpeg, image/png, image/pdf, image/tiff" @change="onFileChange" class="form-control-file" id="data13" ref="data13" />
                    </div>
                  </div>
                  <div class="right_wrap">
                    <div class="file_name" v-if="ebsDoc.data13.file">
                      <p class="ellipsis">{{ ebsDoc.data13.file.name | fileTextLengthCut(8, "…") }} ({{ ebsDoc.data13.file.size | formatBytes(0) }})</p>
                      <button class="btn_close" @click="removeImage('data13')"></button>
                    </div>
                    <div class="empty" v-else>등록된 파일이 없습니다.</div>
                  </div>
                </div> -->
            <div class="box_wrap inputbox_wrap">
              <div class="box_top flw mg30t">
                <h3 class="tit required">위임업체 사업자등록증</h3>
                <div class="top_right jfs">
                  <div class="left_wrap">
                    <div class="btn plus" :class="regFile.data01.file || (selfAuth === true) ? (ebsDoc.data21.file ? 'disabled' : '') : 'disabled'">
                      파일 첨부
                      <input type="file" accept="image/jpeg, image/png, image/pdf, image/tiff" @change="onFileChange" class="form-control-file" id="data21" ref="data21" />
                    </div>
                  </div>
                  <div class="right_wrap">
                    <div class="file_name" v-if="ebsDoc.data21.file">
                      <p class="ellipsis">{{ ebsDoc.data21.file.name | fileTextLengthCut(8, "…") }} ({{ ebsDoc.data21.file.size | formatBytes(0) }})</p>
                      <button class="btn_close" @click="removeImage('data21')"></button>
                    </div>
                    <!-- <div class="empty" v-else>등록된 파일이 없습니다.</div> -->
                  </div>
                </div>
              </div>
            </div>
                <!-- 2023.04.10 수임사 사업자등록증 삭제
                <div class="top_right jfs">
                  <div class="left_wrap">
                    <label for="right" class="label required">2-2) 수임사 사업자등록증</label>

                    <div class="btn plus" :class="regFile.data01.file || (bizrAuthYn === 'Y' && selfAuth === true) ? (ebsDoc.data22.file ? 'disabled' : '') : 'disabled'">
                      파일 첨부 <input type="file" accept="image/jpeg, image/png, image/pdf, image/tiff" @change="onFileChange" class="form-control-file" id="data22" ref="data22" />
                    </div>
                  </div>
                  <div class="right_wrap">
                    <div class="file_name" v-if="ebsDoc.data22.file">
                      <p class="ellipsis">{{ ebsDoc.data22.file.name | fileTextLengthCut(8, "…") }} ({{ ebsDoc.data22.file.size | formatBytes(0) }})</p>
                      <button class="btn_close" @click="removeImage('data22')"></button>
                    </div>
                  </div>
                </div>-->
                <!-- 2023.04.10 통신서비스 이용증명원 공통으로 빠짐
                <div class="top_right jfs">
                  <div class="left_wrap">
                    <label for="right" class="label">3) 발신번호의 통신서비스 이용증명원</label>
                    <div class="btn plus" :class="regFile.data01.file || (bizrAuthYn === 'Y' && selfAuth === true) ? (ebsDoc.data30.file ? 'disabled' : '') : 'disabled'">
                      파일 첨부 <input type="file" accept="image/jpeg, image/png, image/pdf, image/tiff" @change="onFileChange" class="form-control-file" id="data30" ref="data30" />
                    </div>
                  </div>
                  <div class="right_wrap">
                    <div class="file_name" v-if="ebsDoc.data30.file">
                      <p class="ellipsis">{{ ebsDoc.data30.file.name | fileTextLengthCut(8, "…") }} ({{ ebsDoc.data30.file.size | formatBytes(0) }})</p>
                      <button class="btn_close" @click="removeImage('data30')"></button>
                    </div>
                  </div>
                </div>-->
                <!--기획 삭제 22.07.26_PC_모바일_화면검수_발신번호관리.pptx 로 인해 다시 open -->
            <div class="box_wrap inputbox_wrap">
              <div class="box_top flw mg30t">
                <h3 class="tit">위임업체 대리인 신분증 사본 <br>(임직원 신청시)</h3>
                <div class="top_right">
                  <div class="empty">
                    <span>임직원은 재직증명서를 제출해야 하고, 대표자는 제출하지 않아도 됩니다.</span>
                  </div>
                </div>
                <div class="top_right jfs mg0t">
                  <div class="left_wrap">
                  <div class="btn plus" :class="regFile.data01.file || (selfAuth === true) ? (ebsDoc.data42.file ? 'disabled' : '') : 'disabled'">
                    파일 첨부
                    <input type="file" accept="image/jpeg, image/png, image/pdf, image/tiff" @change="onFileChange" class="form-control-file" id="data42" ref="data42" />
                  </div>
                </div>
                <div class="right_wrap">
                  <div class="file_name" v-if="ebsDoc.data42.file">
                    <p class="ellipsis">{{ ebsDoc.data42.file.name | fileTextLengthCut(8, "…") }} ({{ ebsDoc.data42.file.size | formatBytes(0) }})</p>
                    <button class="btn_close" @click="removeImage('data42')"></button>
                  </div>
                  <!-- <div class="empty" v-else>등록된 파일이 없습니다.</div> -->
                </div>
              </div>
            </div>
            <div class="box_wrap inputbox_wrap">
              <div class="box_top flw mg30t">
                <h3 class="tit">수임업체 대리인 신분증 사본 <br>(임직원 신청시)</h3>
                <div class="top_right">
                  <div class="empty">
                    <span>임직원은 재직증명서를 제출해야 하고, 대표자는 제출하지 않아도 됩니다.</span>
                  </div>
                </div>
                <div class="top_right jfs mg0t">
                  <div class="left_wrap">
                    <div class="btn plus" :class="regFile.data01.file || (selfAuth === true) ? (ebsDoc.data52.file ? 'disabled' : '') : 'disabled'">
                      파일 첨부
                      <input type="file" accept="image/jpeg, image/png, image/pdf, image/tiff" @change="onFileChange" class="form-control-file" id="data52" ref="data52" />
                    </div>
                  </div>
                  <div class="right_wrap">
                    <div class="file_name" v-if="ebsDoc.data52.file">
                      <p class="ellipsis">{{ ebsDoc.data52.file.name | fileTextLengthCut(8, "…") }} ({{ ebsDoc.data52.file.size | formatBytes(0) }})</p>
                      <button class="btn_close" @click="removeImage('data52')"></button>
                    </div>
                    <!-- <div class="empty" v-else>등록된 파일이 없습니다.</div> -->
                  </div>
                </div>
              </div>
            </div>
            <div class="box_wrap inputbox_wrap">
              <div class="box_top flw mg30t">
                <h3 class="tit">위임업체 대리인 재직증명서<br>(임직원 신청시)</h3>
                <div class="top_right">
                  <div class="empty">
                    <span>임직원은 재직증명서를 제출해야 하고, 대표자는 제출하지 않아도 됩니다.</span>
                  </div>
                </div>
                <div class="top_right jfs mg0t">
                  <div class="left_wrap">
                    <div class="btn plus" :class="regFile.data01.file || ( selfAuth === true) ? (ebsDoc.data41.file ? 'disabled' : '') : 'disabled'">
                      파일 첨부
                      <input type="file" accept="image/jpeg, image/png, image/pdf, image/tiff" @change="onFileChange" class="form-control-file" id="data41" ref="data41" />
                    </div>
                  </div>
                  <div class="right_wrap">
                    <div class="file_name" v-if="ebsDoc.data41.file">
                      <p class="ellipsis">{{ ebsDoc.data41.file.name | fileTextLengthCut(8, "…") }} ({{ ebsDoc.data41.file.size | formatBytes(0) }})</p>
                      <button class="btn_close" @click="removeImage('data41')"></button>
                    </div>
                    <!-- <div class="empty" v-else>등록된 파일이 없습니다.</div> -->
                  </div>
                </div>
              </div>
            </div>
            <div class="box_wrap inputbox_wrap">
              <div class="box_top flw mg30t">
                <h3 class="tit">수임업체 대리인 재직증명서 <br>(임직원 신청시)</h3>
                <div class="top_right">
                  <div class="empty">
                    <span>임직원은 재직증명서를 제출해야 하고, 대표자는 제출하지 않아도 됩니다.</span>
                  </div>
                </div>
                <div class="top_right jfs mg0t">
                  <div class="left_wrap">
                    <div class="btn plus" :class="regFile.data01.file || ( selfAuth === true) ? (ebsDoc.data51.file ? 'disabled' : '') : 'disabled'">
                      파일 첨부
                      <input type="file" accept="image/jpeg, image/png, image/pdf, image/tiff" @change="onFileChange" class="form-control-file" id="data51" ref="data51" />
                    </div>
                  </div>
                  <div class="right_wrap">
                    <div class="file_name" v-if="ebsDoc.data51.file">
                      <p class="ellipsis">{{ ebsDoc.data51.file.name | fileTextLengthCut(8, "…") }} ({{ ebsDoc.data51.file.size | formatBytes(0) }})</p>
                      <button class="btn_close" @click="removeImage('data51')"></button>
                    </div>
                    <!-- <div class="empty" v-else>등록된 파일이 없습니다.</div> -->
                  </div>
                </div>
              </div>
                <!--<div class="top_right">
                    <div class="empty">※ 사업자등록증과 재직증명서는 위임업체, 수임업체 모두 총4장 제출해야 합니다.</div>
                  </div>-->
              </div>
            </div>
          </span>
          <!-- 발신번호 -->
          <div class="box_wrap inputbox_wrap">
            <div class="box_top flw mg30t">
              <h3 class="tit required">발신번호 등록</h3>
              <div class="top_right" v-for="(data, index) in callerIdList" :key="index">
                <div class="left_wrap">
                  <input ref="sndrnoNm" type="text" class="input" v-model="data.sndrnoNm" placeholder="발신번호명을 입력하세요 (최대 20bytes)" @input="setSndrnoNm($event, index)" />
                  <input ref="sndrno" type="text" class="input" v-model="data.sndrno" placeholder="발신번호를 입력하세요 (숫자만 입력)" @input="setSndrno($event, index)" />
                  <button class="btn plus" :class="callerIdList.length == 10 && 'disabled'" v-if="index === 0" @click="sendNumberAdd">추가</button>
                  <button class="btn minus" style="line-height: 0px" v-if="index !== 0" @click="sendNumberDel(index)">삭제</button>
                </div>
              </div>
            </div>
          </div>

          <div class="btn_wrap">
            <button type="button" class="btn large_pink" @click="fnSendReq">승인요청</button>
            <button type="button" class="btn large_white" @click="fnRegCancel">취소</button>
          </div>
        </form>
      </div>
    </div>
    <!--	e: 	contents	-->
  </section>
</template>
<script>
import store from "@/store";
import sendNumberApi from "../service/sendNumberApi";
import confirm from "@/modules/commonUtil/service/confirm";
import { eventBus } from "@/modules/commonUtil/service/eventBus";
import MobileGnb from "@/components/MobileGnb.vue"; // 로그인 토큰 정보

export default {
  name: "sendNumberReg",
  components: { MobileGnb },
  created() {
    this.fnInit();
  },
  mounted() {
    this.fnAdminCheck();
    this.fnGetNiceCheck();
  },
  data() {
    return {
      selfAuth: false,
      loginDiv: false, //관리자 인증 변수 H001 일 경우  true
      userDiv: false, // 나이스본인인증 암호화 정보
      sEncData: "", // 나이스 인증 성공시 받는 사용자 정보
      hashNum: new Map(), // 연락처 중복 처리 변수 값 (errorCheck 할 경우 function 마다 초기화 진행)
      message: "",
      phone: "01012341234",
      name: "",
      bizrAuthYn: "",
      autCd: "",
      nmineeDivCd: "01",
      responseData: "",
      subSttusCd: "",
      callerIdList: [{ sndrnoNm: "", sndrno: "" }],
      sndrnoNmByte: "0",
      sndrnoByte: "0",

      //발신번호등록
      regFile: {
        data01: { docTpCd: "", file: "" }, // 사업자등록증
        // data02: { docTpCd: "", file: "" }, // 신분증 (임직원 신청시)(2023.04.11 삭제처리)
        data03: { docTpCd: "", file: "" }, // 재직증명서
      },

      // 사업자 제출서류
      bsDoc: {
        data04: { docTpCd: "", file: "" }, // 통신서비스 이용증명원
        // data05: { docTpCd: "", file: "" }, // 재직증명서(2023.04.11 삭제처리)
      },

      // 타사업자 제출서류
      ebsDoc: {
        data11: { docTpCd: "", file: "" }, // 1-1) 위임-수임사간 관계 확인 문서
        data12: { docTpCd: "", file: "" }, // 1-2) 위임장
        //data13: { docTpCd: "", file: "" }, // 1-3) 법인인감증명서 (2022.11.14 삭제처리)
        data21: { docTpCd: "", file: "" }, // 2-1) 위임사 사업자등록증
        // data22: { docTpCd: "", file: "" }, // 2-2) 수임사 사업자등록증 (2023.04.11 삭제처리)
        // data30: { docTpCd: "", file: "" }, // 3-0) 발신번호의 통신서비스 이용증명원(2023.04.11 삭제처리)
        data41: { docTpCd: "", file: "" }, // 4-1) 위임사 재직증명서(임직원 신청시)
        data42: { docTpCd: "", file: "" }, // 4-2) 위임사 신분증(임직원 신청시)
        data51: { docTpCd: "", file: "" }, // 5-1) 수임사 대리인 재직증명서
        data52: { docTpCd: "", file: "" }, // 5-2) 수임사 대리인 신분증
      },
    };
  },
  methods: {
    fnInit() {
      const isAuth = store.getters["login/isAuth"];
      if (isAuth) {
        this.loginDiv = true;
        sendNumberApi
          .selectAutInfo()
          .then((response) => {
            var result = response.data;
            if (result.success) {
              this.autCd = result.data.autCd;
              this.subSttusCd = result.data.subSttusCd;
              this.bizrAuthYn = result.data.bizrAuthYn;
              //not 사용자아이디 비로그인 임시개통 비즈마켓 개통
              if (this.autCd !== "H002" && this.subSttusCd !== "00" && this.subSttusCd !== "01") {
                this.userDiv = true;
              }
              this.fnAdminCheck();
            }
          })
          .catch((error) => {
            if (error.response != undefined && error.response != null) {
              if (error.response.status == 403) {
                eventBus.$on("callbackEventBus", () => {
                  this.$router.go(-1);
                });
                confirm.fnConfirm("", "관리자 아이디만 접근 가능합니다.", "cbAlert");
              } else {
                confirm.fnAlert("", "오류가 발생하였습니다.", "alert");
              }
            } else {
              confirm.fnAlert("", "오류가 발생하였습니다.", "alert");
            }
          });
      } else {
        this.autCd == "H003";
        confirm.fnAlert("", "다시 로그인 해주세요.", "alert");
      }
    },
    fnAdminCheck() {
      if (this.autCd == "H003") {
        eventBus.$on("callbackEventBus", () => {
          this.$router.push({ path: "/sendNumberManage/sendNumber" });
        });
        confirm.fnConfirm("", "로그인 후 발신번호를 등록 할 수 있습니다.", "cbAlert");
      }
      if (!this.userDiv && this.autCd == "H002") {
        eventBus.$on("callbackEventBus", () => {
          this.$router.push({ path: "/sendNumberManage/sendNumber" });
        });
        confirm.fnConfirm("", "관리자 아이디만 발신번호를 등록 할 수 있습니다.", "cbAlert");
      }
      if ((!this.userDiv && this.subSttusCd === "00") || this.subSttusCd === "01") {
        eventBus.$on("callbackEventBus", () => {
          this.$router.push({ path: "/sendNumberManage/sendNumber" });
        });
        confirm.fnConfirm("", "가입절차 확인이 완료된 후 발신번호 등록이 가능합니다.", "cbAlert");
        return;
      }
      // if (!this.userDiv && this.autCd == "H005") {
      //   eventBus.$on("callbackEventBus", () => {
      //     this.$router.push({ path: "/sendNumberManage/sendNumber" });
      //   });
      //   confirm.fnConfirm("", "정지된 아이디는 발신번호를 등록 할 수 없습니다.", "cbAlert");
      // }
    },
    // NICE 인증 데이터 가져오기
    async fnGetNiceCheck() {
      sendNumberApi.getNiceCheck({}).then((response) => {
        const result = response.data;
        if (result.success) {
          this.sEncData = response.data.data.sEncData;
        } else {
          confirm.fnAlert("휴대폰 인증", result.message);
        }
      });
    },
    // 본인인증 팝업
    fnPopup() {
      window.open("", "popupChk", "width=480, height=800, top=100, left=100, fullscreen=no, menubar=no, status=no, toolbar=no, titlebar=yes, location=no, scrollbar=no,  rel=opener");
      document.form_chk.action = "https://nice.checkplus.co.kr/CheckPlusSafeModel/checkplus.cb";
      document.form_chk.target = "popupChk";
      document.form_chk.methods = "post";
      document.form_chk.submit();
    },
    fnMessage() {
      //this.showModal = false;
      this.message = document.querySelector("#message").value;
      this.responseData = document.querySelector("#responseData").value;
      this.name = document.querySelector("#name").value;
      this.phone = document.querySelector("#phone").value;
      if (this.message === "본인인증 실패") {
        confirm.fnAlert("", this.message, "alert");
      } else {
        this.selfAuth = true;
      }
    },
    onFileChange(event) {
      const files = event.target.files[0];
      const filePath = event.target.value;
      const filePathSplit = filePath.split("\\");
      const filePathLength = filePathSplit.length;
      const fileNameSplit = filePathSplit[filePathLength - 1].split(".");
      const fileExt = fileNameSplit[1] != null ? fileNameSplit[1].toLowerCase() : fileNameSplit[1];

      if (files.size > 5242880) {
        confirm.fnAlert("", "최대 5MB 크기의 파일만 첨부할 수 있습니다.", "alert");
        return false;
      }
      if (!(fileExt === "jpg" || fileExt === "png" || fileExt === "pdf" || fileExt === "tiff")) {
        // confirm.fnAlert("첨부 파일 형식을 확인해 주세요.", "파일 형식 : jpg, png, pdf, tiff / 최대 크기 5MB", "alert");
        confirm.fnAlert("", "첨부 파일 형식을 확인해 주세요. <br />파일 형식 : jpg, png, pdf, tiff / 최대 크기 5MB", "alert");
        event.target.value = "";
        return false;
      }
      this.files = files;
      this.fileNm = event.target.files[0].name;

      this.regImage(event);
    },
    regImage(event) {
      const type = event.target.id;
      var input = event.target;
      if (input.files) {
        var reader = new FileReader();
        /* reader.onload = (e) => {
          this.preview = e.target.result;
        };  이미지 URL*/

        // 사업자등록증
        if (type === "data01") {
          this.regFile.data01.file = input.files[0];
          this.regFile.data01.docTpCd = this.nmineeDivCd === "01" ? "06" : "07";
          // 신분증 (임직원 신청시)
        /*} else if (type === "data02") {(2023.04.11 삭제처리)
          this.regFile.data02.file = input.files[0];
          this.regFile.data02.docTpCd = "13";*/
          // 재직증명서 (임직원 신청시)
        } else if (type === "data03") {
          this.regFile.data03.file = input.files[0];
          this.regFile.data03.docTpCd = "14";
          // 통신서비스 이용증명원
        } else if (type === "data04") {
          this.bsDoc.data04.file = input.files[0];
          this.bsDoc.data04.docTpCd = "01";
          // 재직증명서
        /*} else if (type === "data05") { (2023.04.11 삭제처리)
          this.bsDoc.data05.file = input.files[0];
          this.bsDoc.data05.docTpCd = "02";*/
          // ***[타사업자 선택] 1-1) 위임-수임사간 관계 확인 문서
        } else if (type === "data11") {
          this.ebsDoc.data11.file = input.files[0];
          this.ebsDoc.data11.docTpCd = "03";

          // ***[타사업자 선택] 1-2) 위임장
        } else if (type === "data12") {
          this.ebsDoc.data12.file = input.files[0];
          this.ebsDoc.data12.docTpCd = "04";

          // ***[타사업자 선택] 1-3) 법인인감증명서 (2022.11.14 삭제처리)
          // } else if (type === "data13") {
          //   this.ebsDoc.data13.file = input.files[0];
          //   this.ebsDoc.data13.docTpCd = "12";

          // ***[타사업자 선택] 2-1) 위임사 사업자등록증
        } else if (type === "data21") {
          this.ebsDoc.data21.file = input.files[0];
          this.ebsDoc.data21.docTpCd = "10";

          // ***[타사업자 선택] 2-2) 수임사 사업자등록증
        /*} else if (type === "data22") { 2023.04.11 삭제 처리
          this.ebsDoc.data22.file = input.files[0];
          this.ebsDoc.data22.docTpCd = "11";*/

          // ***[타사업자 선택] 3)   발신번호의  통신서비스 이용증명원
        /*} else if (type === "data30") {   2023.04.11 삭제 처리
          this.ebsDoc.data30.file = input.files[0];
          this.ebsDoc.data30.docTpCd = "01";*/

          // ***[타사업자 선택] 4-1) 위임사 재직증명서 (임직원 신청시)
        } else if (type === "data41") {
          this.ebsDoc.data41.file = input.files[0];
          this.ebsDoc.data41.docTpCd = "09";

          // ***[타사업자 선택] 4-2) 위임사 신분증 (임직원 신청시)
        } else if (type === "data42") {
          this.ebsDoc.data42.file = input.files[0];
          this.ebsDoc.data42.docTpCd = "08";

          // ***[타사업자 선택] 5-1) 수임사 대리인 재직증명서
        } else if (type === "data51") {
          this.ebsDoc.data51.file = input.files[0];
          this.ebsDoc.data51.docTpCd = "15";

          // ***[타사업자 선택] 5-2) 수임사 대리인 신분증
        } else if (type === "data52") {
          this.ebsDoc.data52.file = input.files[0];
          this.ebsDoc.data52.docTpCd = "16";
        }

        reader.readAsDataURL(input.files[0]);
      }
    },
    removeImage(type) {
      if (type === "data01") {
        this.$refs.data01.value = "";
        this.regFile.data01.file = "";
      /*} else if (type === "data02") { (2023.04.11 삭제처리)
        this.$refs.data02.value = "";
        this.regFile.data02.file = "";*/
      } else if (type === "data03") {
        this.$refs.data03.value = "";
        this.regFile.data03.file = "";

        // 사업자 선택시
      } else if (type === "data04") {
        this.$refs.data04.value = "";
        this.bsDoc.data04.file = "";
      /*} else if (type === "data05") { (2023.04.11 삭제처리)
        this.$refs.data05.value = "";
        this.bsDoc.data05.file = "";*/

        // 타사업자 선택시
      } else if (type === "data11") {
        this.$refs.data11.value = "";
        this.ebsDoc.data11.file = "";
      } else if (type === "data12") {
        this.$refs.data12.value = "";
        this.ebsDoc.data12.file = "";
        // } else if (type === "data13") {  2022.11.14 삭제처리
        //   this.$refs.data13.value = "";
        //   this.ebsDoc.data13.file = "";
      } else if (type === "data21") {
        this.$refs.data21.value = "";
        this.ebsDoc.data21.file = "";
      /*} else if (type === "data22") {   2023.04.11 삭제처리
        this.$refs.data22.value = "";
        this.ebsDoc.data22.file = "";*/
      /*} else if (type === "data30") {   2023.04.11 삭제처리
        this.$refs.data30.value = "";
        this.ebsDoc.data30.file = "";*/
      } else if (type === "data41") {
        this.$refs.data41.value = "";
        this.ebsDoc.data41.file = "";
      } else if (type === "data42") {
        this.$refs.data42.value = "";
        this.ebsDoc.data42.file = "";
      } else if (type === "data51") {
        this.$refs.data51.value = "";
        this.ebsDoc.data51.file = "";
      } else if (type === "data52") {
        this.$refs.data52.value = "";
        this.ebsDoc.data52.file = "";
      }
    },
    contView(type) {
      if (type === "01") {
        this.nmineeDivCd === type;
      } else {
        this.nmineeDivCd === type;
      }
    },
    sendNumberAdd() {
      const addSendNumber = { sndrno: "", sndrnoNm: "" };
      this.callerIdList.push(addSendNumber);
    },
    sendNumberDel(index) {
      this.callerIdList.splice(index, 1);
    },

    fnValidator() {
      let phoneCheck = false;
      this.hashNum = new Map();

      this.callerIdList.forEach((e) => {
        if (this.hashNum.has(e.sndrno.replace(/-/g, ""))) {
          phoneCheck = true;
          return false;
        } else {
          this.hashNum.set(e.sndrno.replace(/-/g, ""), "sndrno");
        }
      });

      let title = "";
      let msg = "";

      const sendNumberUse = this.callerIdList.every((e) => {
        return e.sndrno !== "" && e.sndrnoNm !== "";
      });

      // 발신번호가 휴대전화 번호일 경우, 본인인증 번호만 승인 요청 처리
      const patten_phone = /^(01[016789]{1}|070)-?[0-9]{3,4}-?[0-9]{4}$/; //핸드폰 번호, 하이픈 상관 X
      let callerIdListLen = this.callerIdList.filter((e) => {
        return patten_phone.test(e.sndrno);
      }).length;
      let useHpNumber = true;

      if (callerIdListLen > 0) {
        useHpNumber = this.callerIdList
          .filter((e) => {
            return patten_phone.test(e.sndrno);
          })
          .every((e) => {
            return this.$gfnCommonUtils.hpNumberRemoveDash(this.phone) === this.$gfnCommonUtils.hpNumberRemoveDash(e.sndrno);
          });
      }

      if (!this.selfAuth) {
        title = "";
        msg = "휴대폰 본인인증을 해주세요.";
      } else if (!this.regFile.data01.file) {
        title = "";
        msg = "[사업자등록증]을 입력해 주세요";
      // } else if(!this.bsDoc.data04.file) {         2023.05.18 통신서비스 이용증명원 필수 제외
      //   title = "";
      //   msg = "[통신서비스 이용증명원]를 등록해 주세요";
      } else if (this.nmineeDivCd === "02") {
        // 명의자 구분 타사업자의 경우
        if (!this.ebsDoc.data12.file) {
          title = "";
          msg = "[위임장]을 등록해 주세요";
          // } else if (!this.ebsDoc.data13.file) {  2022.11.14 삭제 처리
          //   title = "";
          //   msg = "[법인인감증명서]을 등록해 주세요";
        } else if (!this.ebsDoc.data11.file) {
          title = "";
          msg = "[위임-수임업체 관계 확인 문서]를 등록해 주세요";
        } else if (!this.ebsDoc.data21.file) {
          title = "";
          msg = "[위임업체 사업자등록증]을 등록해 주세요";
        } else if (!sendNumberUse) {
          title = "";
          msg = "[발신번호]을 입력해 주세요";
        } else if (phoneCheck) {
          title = "";
          msg = "중복된 발신번호가 있습니다.";
        } else if (!useHpNumber) {
          title = "";
          msg = "승인 요청하신 발신번호와<br />본인인증을 진행한 휴대폰 번호가 다릅니다.<br />본인인증을 진행한 휴대폰 번호만 심사가 가능합니다.";
        }
        /*else if (!this.ebsDoc.data22.file) {
          title = "";
          msg = "[수임사 사업자등록증]을 등록해 주세요";
        }*/
        /* else if (!this.ebsDoc.data30.file) {
          title = "";
          msg = "[발신번호의  통신서비스 이용증명원]을 등록해 주세요";
        } */
      } else if (!sendNumberUse) {
        title = "";
        msg = "[발신번호]을 입력해 주세요";
      } else if (phoneCheck) {
        title = "";
        msg = "중복된 발신번호가 있습니다.";
      } else if (!useHpNumber) {
        title = "";
        msg = "승인 요청하신 발신번호와<br />본인인증을 진행한 휴대폰 번호가 다릅니다.<br />본인인증을 진행한 휴대폰 번호만 심사가 가능합니다.";
      }

      return { title, msg };
    },
    fnSendReq() {
      const validator = this.fnValidator();
      if (validator.msg) {
        confirm.fnAlert(validator.title, validator.msg, "alert");
        return;
      }
      eventBus.$on("callbackEventBus", this.fnSend);
      confirm.fnConfirm("", "발신번호 승인을 요청하시겠어요?<br/>서류 심사를 통해 승인 또는 거절될 수 있습니다.", "agreeReq");
    },
    fnSend() {
      const fd = new FormData();
      // 발신번호 등록에서 발신번호 명 또는 발신번호가 미기입 되었을 경우 전송되지 않습니다.
      const callList = this.callerIdList
        .filter((e) => {
          return e.sndrno !== "" && e.sndrnoNm !== "";
        })
        .map((e) => {
          return {
            ...e,
            sndrno: this.$gfnCommonUtils.hpNumberRemoveDash(e.sndrno),
          };
        });

      fd.append("callerIdList", JSON.stringify(callList)); //발신번호
      fd.append("slfAuthYn", this.selfAuth ? "Y" : "N"); // 본인인증 여부
      fd.append("slfAuthHp", this.phone); // 본인인증 휴대폰번호
      fd.append("nmineeDivCd", this.nmineeDivCd); // 명의자 코드 01 : 사업자 / 02 : 타사업자


      if (this.regFile.data01.file) {
        fd.append("files", this.regFile.data01.file); //사업자 인증
        fd.append("docTpCd", this.regFile.data01.docTpCd); // 사업자 코드
      }
      /*if (this.regFile.data02.file) { (2023.04.11 삭제처리)
        fd.append("files", this.regFile.data02.file); //신분증 임직원
        fd.append("docTpCd", this.regFile.data02.docTpCd); // 신분증 임직원
      }*/
      if (this.regFile.data03.file) {
        fd.append("files", this.regFile.data03.file); //재직증명서 임직원
        fd.append("docTpCd", this.regFile.data03.docTpCd); // 재직증명서 임직원
      }
      if (this.nmineeDivCd === "01") {
        if (this.bsDoc.data04.file) {
          fd.append("files", this.bsDoc.data04.file);
          fd.append("docTpCd", this.bsDoc.data04.docTpCd);
        }
        /*if (this.bsDoc.data05.file) { 2023.04.11 삭제 처리
          fd.append("files", this.bsDoc.data05.file);
          fd.append("docTpCd", this.bsDoc.data05.docTpCd);
        }*/
      } else if (this.nmineeDivCd === "02") {
        if (this.bsDoc.data04.file) {
          fd.append("files", this.bsDoc.data04.file);
          fd.append("docTpCd", this.bsDoc.data04.docTpCd);
        }
        if (this.ebsDoc.data11.file) {
          fd.append("files", this.ebsDoc.data11.file);
          fd.append("docTpCd", this.ebsDoc.data11.docTpCd);
        }
        if (this.ebsDoc.data12.file) {
          fd.append("files", this.ebsDoc.data12.file);
          fd.append("docTpCd", this.ebsDoc.data12.docTpCd);
        }

        /* if (this.ebsDoc.data13.file) { 2022.11.14 삭제 처리
          fd.append("files", this.ebsDoc.data13.file);
          fd.append("docTpCd", this.ebsDoc.data13.docTpCd);
        } */

        if (this.ebsDoc.data21.file) {
          fd.append("files", this.ebsDoc.data21.file);
          fd.append("docTpCd", this.ebsDoc.data21.docTpCd);
        }

        /*if (this.ebsDoc.data22.file) {  2023.04.11 삭제 처리
          fd.append("files", this.ebsDoc.data22.file);
          fd.append("docTpCd", this.ebsDoc.data22.docTpCd);
        }*/

        /*if (this.ebsDoc.data30.file) {  2023.04.11 삭제 처리
          fd.append("files", this.ebsDoc.data30.file);
          fd.append("docTpCd", this.ebsDoc.data30.docTpCd);
        }*/

        if (this.ebsDoc.data41.file) {
          fd.append("files", this.ebsDoc.data41.file);
          fd.append("docTpCd", this.ebsDoc.data41.docTpCd);
        }

        if (this.ebsDoc.data42.file) {
          fd.append("files", this.ebsDoc.data42.file);
          fd.append("docTpCd", this.ebsDoc.data42.docTpCd);
        }

        if (this.ebsDoc.data51.file) {
          fd.append("files", this.ebsDoc.data51.file);
          fd.append("docTpCd", this.ebsDoc.data51.docTpCd);
        }

        if (this.ebsDoc.data52.file) {
          fd.append("files", this.ebsDoc.data52.file);
          fd.append("docTpCd", this.ebsDoc.data52.docTpCd);
        }
      }

      // for (var pair of fd.entries()) {
      //   console.log(pair[0] + " : " + pair[1]);
      // }

      sendNumberApi.callerIdReg(fd).then((response) => {
        if (response.data.success) {
          eventBus.$on("callbackEventBus", () => {
            this.$router.go(-1);
          });
          confirm.fnConfirm("", "승인 요청하신 발신번호는 심사 후 사용 가능합니다.", "cbAlert");
        } else {
          confirm.fnAlert("", response.data.message, "alert");
        }
      });
    },

    setSndrnoNm(e, i) {
      this.callerIdList[i].sndrnoNm = this.$gfnCommonUtils.cutStr(e.target.value, 20);
    },

    setSndrno(e, i) {
      this.callerIdList[i].sndrno = this.$gfnCommonUtils.hpNumberAddDash(this.$gfnCommonUtils.cutStr(e.target.value.replace(/[^-0-9]/g, ""), 13));
    },
    // 취소 확인 팝업
    fnRegCancel() {
      eventBus.$on("callbackEventBus", this.fnCancel);
      confirm.fnConfirm("", "등록을 취소하시겠습니까??", "cReg");
    },
    fnCancel() {
      this.$router.push({ path: "/sendNumberManage/sendNumber" });
    },
  },
};
</script>
