import httpClient from "@/common/http-client";

const selectAutInfo = (params) => {
  return httpClient.post("/api/statistics/selectAutInfo", params, { headers: { "show-layer": "Yes" } });
};

const selectSendMsgStatusList = (params) => {
  return httpClient.post("/api/msgStatus/selectSendMsgStatusList", params, { headers: { "show-layer": "Yes" } });
};

const selectSendMsgStatusExcelList = (params) => {
  return httpClient.post("/api/msgStatus/selectSendMsgStatusExcelList", params, { headers: { "show-layer": "Yes" } });
};

const selectMessageDetailInfo = (params) => {
  return httpClient.post("/api/msgStatus/selectSendMsgDetail", params, { headers: { "show-layer": "Yes" } });
};

const selectSendMsgDetailList = (params) => {
  return httpClient.post("/api/msgStatus/selectSendMsgDetailList", params, { headers: { "show-layer": "Yes" } });
};

const selectRsvMessageList = (params) => {
  return httpClient.post("/api/rsvMessage/selectRsvMessageList", params, { headers: { "show-layer": "Yes" } });
};

// 080 수신거부 전화번호 조회
const rejectPhoneList = (params) => {
  return httpClient.post("/api/ars080reject/rejectPhoneList", params, { headers: { "show-layer": "Yes" } });
};

//예약전송 수정 상세 내용 조회
const selectRsvMsgDetail = (params) => {
  return httpClient.post("/api/rsvMessage/selectRsvMsgDetail", params, { headers: { "show-layer": "Yes" } });
};

const cancelRsvMessage = (params) => {
  return httpClient.post("/api/rsvMessage/cancelRsvMessage", params, { headers: { "show-layer": "Yes" } });
};

const selectSendMsgDayStatusList = (params) => {
  return httpClient.post("/api/statistics/custDayStat", params, { headers: { "show-layer": "Yes" } });
};

const selectSendMsgMonStatusList = (params) => {
  return httpClient.post("/api/statistics/custMonthStat", params, { headers: { "show-layer": "Yes" } });
};

const selectSendMsgDayStatusExcelList = (params) => {
  return httpClient.post("/api/statistics/selectSendMsgDayStatusExcelList", params, { headers: { "show-layer": "Yes" } });
};

const selectSendMsgMonStatusExcelList = (params) => {
  return httpClient.post("/api/statistics/selectSendMsgMonStatusExcelList", params, { headers: { "show-layer": "Yes" } });
};

// 예상 금액 조회
const getPreAmount = (params) => {
  return httpClient.post("/api/sendMessage/getPreAmount", params, { headers: { "show-layer": "Yes", activity: "READ" } });
};

// 사용 요금 조회
const getUseCharge = (params) => {
  return httpClient.post("/api/sendMessage/getUseCharge", params, { headers: { "show-layer": "Yes", activity: "READ" } });
};

// 발신번호 조회
const selectCallbackList = (params) => {
  return httpClient.post("/api/sendMessage/selectCallbackList", params, { headers: { "show-layer": "yse" } });
};

// 최신 전송그룹 조회
const selectRecentSendGroup = (params) => {
  return httpClient.post("/api/sendMessage/selectRecentSendGroup", params, { headers: { "show-layer": "Yes" } });
};
// 최신 전송그룹 리스트 조회
const selectRecentSendRecvInfo = (params) => {
  return httpClient.post("/api/sendMessage/selectRecentSendRecvInfo", params, { headers: { "show-layer": "Yes" } });
};

// 발신번호 조회
const callerIdList = (params) => {
  return httpClient.post("/api/callerId/callerIdList", params, { headers: { "show-layer": "Yes" } });
};

//주소록 조회
const selectAddressGrpList = (params) => {
  return httpClient.post("/api/address/selectAddressGrpList", params, { headers: { "show-layer": "Yes" } });
};
const searchAddressGrpList = (params) => {
  return httpClient.post("/api/address/searchAddressGrpList", params, { headers: { "show-layer": "Yes" } });
};
const selectAddrMemberList = (params) => {
  return httpClient.post("/api/address/selectAddrMemberList", params, { headers: { "show-layer": "Yes" } });
};
const selectGrpAddrMemberList = (params) => {
  return httpClient.post("/api/address/selectGrpAddrMemberList", params, { headers: { "show-layer": "Yes" } });
};
const modifyRsvMessage = (params) => {
  return httpClient.post("/api/rsvMessage/modifyRsvMessage", params, { headers: { "show-layer": "Yes" } });
};

export default {
  selectAutInfo,
  selectSendMsgStatusList,
  selectSendMsgStatusExcelList,
  selectMessageDetailInfo,
  selectSendMsgDetailList,
  selectRsvMessageList,
  cancelRsvMessage,
  selectRsvMsgDetail,
  selectSendMsgDayStatusList,
  selectSendMsgMonStatusList,
  selectSendMsgDayStatusExcelList,
  selectSendMsgMonStatusExcelList,
  //예약전송 수정 컴포넌트 쪽 API
  selectCallbackList,
  selectAddressGrpList,
  searchAddressGrpList,
  selectAddrMemberList,
  selectGrpAddrMemberList,
  selectRecentSendGroup,
  selectRecentSendRecvInfo,
  callerIdList,
  modifyRsvMessage,
  getPreAmount,
  getUseCharge,
  rejectPhoneList,
};
