<template>
  <section class="m_section">
    <!--	s: 	contents-->
    <div class="contents">
      <div class="tabcont_wrap policy_wrap">
        <div class="policy privacyPolicy">
          <div class="policy_top">
            <h3 class="policy_title">개인정보처리방침</h3>
            <p class="policy_sub">LGU+는 이용자들의 개인정보를 소중히 다루고 관련 법령을 준수하고 있습니다.</p>
            <p class="location">HOME - 개인정보처리방침</p>
          </div>
          <div class="requestWrap">
            <div class="requestTextArea">
              <div v-html="policyContents"></div>
              <div> 개인정보처리방침 버전번호 : {{ policyVersion }}<br> 개인정보처리방침 시행일: {{ applyDtStr }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--	e: 	contents	-->
    <confirm></confirm>
  </section>
</template>

<script>
import popupApi from "../service/popupApi";
import confirm from "@/modules/commonUtil/service/confirm";
import { eventBus } from "@/modules/commonUtil/service/eventBus";
export default {
  name: "privacyPolicyPopup",
  components: {},
  data() {
    return {
      policyContents: "",
      policyVersion: "",
      applyDtStr: ""
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {},
  mounted() {
    this.getSelectPrivacyPolicy();
  },
  beforeUpdate() {},
  updated() {},
  beforeUnmount() {},
  unmounted() {},
  methods: {
    getSelectPrivacyPolicy() {
      const params = {
        applyDt : this.$route.query.applyDt
      }
      popupApi.selectPrivacyPolicy(params).then((response) => {
        var result = response.data;
        if (result.success) {
          this.policyContents = result.data.contents;
          this.policyVersion = result.data.policyVersion;
          this.applyDtStr = result.data.applyDtStr;
        }else{
          confirm.fnConfirm("개인정보처리방침 오류", result.message, "cbAlert");
          eventBus.$on("callbackEventBus", this.fnClosePopup);
        }
      });
    },
    fnClosePopup() {
      self.close();
    }
  }
};
</script>
