import httpClient from '@/common/http-client';

const selectAddressGrpList = (params) => {
	return httpClient.post('/api/address/selectAddressGrpList', params, { headers: {"show-layer": "Yes", "activity":"READ"} });
};

const searchAddressGrpList = (params) => {
	return httpClient.post('/api/address/searchAddressGrpList', params, { headers: {"show-layer": "Yes", "activity":"READ"} });
};

const selectAddrMemberList = (params) => {
	return httpClient.post('/api/address/selectAddrMemberList', params, { headers: {"show-layer": "Yes", "activity":"READ"} });
};
const selectAddrMemberExcelList = (params) => {
	return httpClient.post('/api/address/selectAddrMemberExcelList', params, { headers: {"show-layer": "Yes", "activity":"READ"} });
};

const registerAddrGrp = (params) => {
	return httpClient.post('/api/address/registerAddrGrp', params, { headers: {"show-layer": "Yes", "activity":"SAVE"} });
};

const modifyAddrGrp = (params) => {
	return httpClient.post('/api/address/modifyAddrGrp', params, { headers: {"show-layer": "Yes", "activity":"SAVE"} });
};

const deleteAddressGrp = (params) => {
	return httpClient.post('/api/address/deleteAddressGrp', params, { headers: {"show-layer": "Yes", "activity":"SAVE"} });
};
const selectAddressGrpSelList = (params) => {
	return httpClient.post('/api/address/selectAddressGrpSelList', params, { headers: {"show-layer": "Yes", "activity":"READ"} });
};

const selectModiAddrGrpSelList = (params) => {
	return httpClient.post('/api/address/selectModiAddrGrpSelList', params, { headers: {"show-layer": "Yes", "activity":"READ"} });
};

const addressGrpExec = (params) => {
	return httpClient.post('/api/address/addressGrpExec', params, { headers: {"show-layer": "Yes", "activity":"READ"} });
};

const registerAddrMember = (params) => {
	return httpClient.post('/api/address/registerAddrMember', params, { headers: {"show-layer": "Yes", "activity":"SAVE"} });
};

const modifyAddrMember = (params) => {
	return httpClient.post('/api/address/modifyAddrMember', params, { headers: {"show-layer": "Yes", "activity":"SAVE"} });
};

const deleteAddressMem = (params) => {
	return httpClient.post('/api/address/deleteAddressMem', params, { headers: {"show-layer": "Yes", "activity":"SAVE"} });
};

const addressMemExec = (params) => {
	return httpClient.post('/api/address/addressMemExec', params, { headers: {"show-layer": "Yes", "activity":"READ"} });
};

const selectAddressSelList = (params) => {
	return httpClient.post('/api/address/selectAddressSelList', params, { headers: {"show-layer": "Yes", "activity":"READ"} });
};

const massRegisterAddrMember = (params) => {
	return httpClient.post('/api/address/massRegisterAddrMember', params, { headers: {"show-layer": "Yes", "activity":"SAVE"} });
};



export default {
	selectAddressGrpList,
	searchAddressGrpList,
	selectAddrMemberList,
	selectAddrMemberExcelList,
	registerAddrGrp,
	modifyAddrGrp,
	deleteAddressGrp,
	selectAddressGrpSelList,
	selectModiAddrGrpSelList,
	addressGrpExec,
	registerAddrMember,
	modifyAddrMember,
	deleteAddressMem,
	addressMemExec,
	selectAddressSelList,
	massRegisterAddrMember,
};


