<template>
  <div class="popup tit messagebox">
    <div class="pop-head">
      <h3 class="pop-tit">문자 보관함</h3>
    </div>
    <div class="scroll-wrapper" :class="{'overflow-css' : overflowCSS}">
      <div class="pop-cont">
        <div class="pop_cont_header">
          <div class="left_group">
            <div class="message_tab">
              <ul>
                <li :class="{'on': activeTab === 'ALL'}" @click="setActiveTab('ALL')"><a href="#" @click="handleClick">전체</a></li>
                <li :class="{'on': activeTab === 'SMS'}" @click="setActiveTab('SMS')"><a href="#" @click="handleClick">SMS</a></li>
                <li :class="{'on': activeTab === 'LMS'}" @click="setActiveTab('LMS')"><a href="#" @click="handleClick">LMS</a></li>
                <li :class="{'on': activeTab === 'MMS'}" @click="setActiveTab('MMS')"><a href="#" @click="handleClick">MMS</a></li>
              </ul>
            </div>
            <div class="search_wrap">
              <input type="text" class="search2" placeholder="제목 또는 내용 직접입력" v-model="inputText" @input="handleInputChange" @keypress.enter="fnMsgStrSearch"/>
              <button class="delete_btn" :class="{hidden: isButtonHidden}" @click="handleTextDelete"></button>
              <button class="search_btn" @click="fnMsgStrSearch"></button>
            </div>
          </div>
          <div class="right_group">
              <div class="chkbox">
                <input type="checkbox" id="all-chk" class="chk" v-model="selectAll" @click="checkedAll($event.target.checked)"/>
                <label for="all-chk">전체 선택</label>
              </div>
              <button type="button" class="btn white small" @click="handleDataDelete()">선택삭제</button>
          </div>
        </div>
        <div style="margin-left: 0px; text-align: left; font-size: 12px;"><br>※ 문자보관함의 문자는 1년까지 저장됩니다. 사용하신지 1년이 지난 문자는 삭제됩니다.</div>
        <div class="card_wrapper">
          <div v-if="this.totCnt > 0">
            <div class="card_layout">
              <div v-for="card in cards" :key="card.id" class="card" :class="{active: card.checked}">
                <!-- SMS 카드 -->
                <div v-if="card.messageType === 'SMS'" class=" sms_card">
                  <div class="card_content">
                    <div class="chkbox">
                      <input type="checkbox" :id="'checkbox-' + card.id" :value="card.id" v-model="card.checked" class="chk" @change="selectCheck()"/>
                      <label :for="'checkbox-' + card.id"></label>
                    </div>
                    
                    <div class="card_content_wrapper">
                      <div class="content_body"> {{ card.text }}</div>
                    </div>
                    <div class="card_content_footer">
                      <div class="footer_left">
                        <div class="message_type">
                          {{ card.messageType }}
                        </div>
                        <div class="card_date">
                          {{ card.dateSubStr }}
                        </div>
                      </div>
                      <div class="footer_right btn_wrap">
                        <button type="button" class="btn white small btn_del" @click="fnDeleteOne(card.msgSeq)">삭제</button>
                        <button type="button" class="btn white small" @click="handleApplyMsg(card.id,card.msgSeq)">적용</button>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- LMS 카드 -->
                <div v-if="card.messageType === 'LMS'" class=" lms_card">
                  <div class="card_content">
                    <div class="chkbox">
                      <input type="checkbox" :id="'checkbox-' + card.id" :value="card.id" v-model="card.checked" class="chk" @change="selectCheck()"/>
                      <label :for="'checkbox-' + card.id"></label>
                    </div>
                    
                    <div class="card_content_wrapper">
                      <h3 class="card_title" v-if="card.title">{{ card.title }}</h3>
                      <div class="content_body"> {{ card.text }}</div>
                    </div>
                    
                    <div class="card_content_footer">
                      <div class="footer_left">
                        <div class="message_type">
                          {{ card.messageType }}
                        </div>
                        <div class="card_date">
                          {{ card.dateSubStr }}
                        </div>
                      </div>
                      <div class="footer_right btn_wrap">
                        <button type="button" class="btn white small btn_del" @click="fnDeleteOne(card.msgSeq)">삭제</button>
                        <button type="button" class="btn white small" @click="handleApplyMsg(card.id,card.msgSeq)">적용</button>
                      </div>
                    </div>
                  </div>
                </div>        
                <!-- MMS 카드 -->
                <div v-if="card.messageType === 'MMS'" class=" mms_card">
                  <div class="card_content">
                    <div class="chkbox">
                      <input type="checkbox" :id="'checkbox-' + card.id" :value="card.id" v-model="card.checked" class="chk" @change="selectCheck()"/>
                      <label :for="'checkbox-' + card.id"></label>
                    </div>
                    <div class="card_content_wrapper">
                      <!-- v-if 로 MMS일 경우에만 보여주도록  -->
                      <!-- s: swiper 영역 하드코딩 -->
                      <div v-if="card.reqProduct === 'MMS'">
                        <swiper class="pcont_top" :options="swiperOption">
                          <swiper-slide class="pcont_img"  v-for="(file, index) in card.fileList" :key="index">
                            <img :src="file.src" class="img-fluid"  />
                          </swiper-slide>
                          <div class="swiper-pagination pc_pagination" v-if="card.reqProduct === 'MMS'" slot="pagination"></div>
                        </swiper>
                      </div>
                      <!-- e: swiper 영역 하드코딩 -->
                      <h3 class="card_title" v-if="card.title">{{ card.title }}</h3>
                      <div class="content_body"> {{ card.text }}</div>
                    </div>
                    
                    <div class="card_content_footer">
                      <div class="footer_left">
                        <div class="message_type">
                          {{ card.messageType }}
                        </div>
                        <div class="card_date">
                          {{ card.dateSubStr }}
                        </div>
                      </div>
                      <div class="footer_right btn_wrap">
                        <button type="button" class="btn white small btn_del" @click="fnDeleteOne(card.msgSeq)">삭제</button>
                        <button type="button" class="btn white small" @click="handleApplyMsg(card.id,card.msgSeq)">적용</button>
                      </div>
                    </div>
                  </div>
                </div>  
              </div>

            </div>
          </div>
          <div v-if="this.totCnt == 0"  class="no_data_message">
      
            <!-- 검색결과가 없을경우 -->
            <div v-if="srchStrCnt" class="no_category">
              "<span>{{ this.srchStr }}</span>"에 대한 검색결과가 없습니다.
              <p v-if="this.srchStr.length > 0">제목 또는 내용을 확인해주세요</p>
            </div>    
            <!-- 해당 데이터가 없을경우  -->
            <div v-else-if="srchChCnt" class="no_search_result">
              "<span>{{ this.srchCh }}</span>"에 대한 검색결과가 없습니다.
            </div>
            <!-- 저장된 문자가 없을경우 -->
            <div v-else-if="!srchChCnt && !srchStrCnt" class="no_data_result">
              <span>저장 된 문자가 없습니다.</span>
            </div>

          </div>
        </div>
        <div class="paging_wrap">
          <!-- <ul class="paging"><li><button class="btn btn_first"></button></li><li><button class="btn btn_prev"></button></li><li><button class="btn on"> 1 </button></li><li><button class="btn"> 2 </button></li><li><button class="btn"> 3 </button></li><li><button class="btn"> 4 </button></li><li><button class="btn btn_next"></button></li><li><button class="btn btn_last"></button></li></ul> -->
          <PageLayer ref="updatePaging" @fnClick="fnSearch" :listTotalCnt="totCnt" :selected=6 :pageNum="pageNo"></PageLayer>
        </div>
      </div>
      <div class="pop-btn2 center">
        <button type="button" class="btn large_white" @click="ModalClose()">닫기</button>
      </div>
    </div>

  </div>
</template>
<script>
import store from "@/store";
// import MessageBoxCardLayoutVue from './MessageBoxCardLayout.vue';
import { swiper, swiperSlide } from "vue-awesome-swiper";
import confirm from "@/modules/commonUtil/service/confirm";
import messageSendApi from "../service/messageSendApi";
import PageLayer from "@/components/PageLayer.vue";
import { eventBus } from "@/modules/commonUtil/service/eventBus";

export default {
  name: "MessageBoxPopup",
  components:{
    // MessageBoxCardLayoutVue, // 하위 카드 레이아웃 랜더링하는 컴포넌트
    PageLayer,
    swiper,
    swiperSlide,
  },
  data() {
    return {
      inputText: "", // 검색 인풋 박스
      activeTab: "ALL", // 문자 타입 탭
      selectAll: false, // 전체 선택 체크박스
      //예시 카드 데이터 ID / Type / 타이틀 / 본문 / mms imageurl / date / 초기 check상태
      cards: [],

      // 데이터 없을 경우
      //cards: [],
      overflowCSS: false,
      isButtonHidden: true,
      // listSize: 6, // select 박스 value (출력 갯수 이벤트)
      // selected: 6,
      pageNo: 1, // 현재 페이징 위치
      totCnt: 0, //전체 리스트 수
      offset: 0, //페이지 시작점,
      msgSeq : "",
      fileInfoList : [],
      params: {},
      // mms 카드 스와이퍼
      swiperOption: {
        slidesPerView: 1,
        spaceBetween: 6,
        slidesOffsetBefore: 0,
        slidesOffsetAfter: 0,
        centerInsufficientSlides: true,
        autoHeight:true,
        pagination: {
          el: ".swiper-pagination.pc_pagination",
          clickable: true,
          renderBullet: function (index, className) {
            return '<span class="' + className + '">' + (index + 1) + "</span>";
          },
        },
        observer: true,
        observeParents: true,
      },
      srchStr: "",
      srchCh: "",
      srchStrCnt : false,
      srchChCnt : false,
    };
  },
  // 모바일에서 card 데이터가 없을 때  scroll 처리 없애기 위해 특정 CSS 추가 
  watch:{
    cards:{
      immediate: true,
      handler(){
        this.overflowCSS = this.totCnt === 0;
      }
    },
    inputText(inputText) {
      if (inputText.length > 0) {
        this.isButtonHidden = false;
      } else {
        this.isButtonHidden = true;
      }
    }
  },
  mounted(){
    this.fnInit();
  },
  methods: {
    //파라미터 초기화
    fnInit(){                   
      this.activeTab = "ALL";
      this.inputText = "";
      this.pageNo = "1";
      this.listSize = "6";
      this.selectAll = false;
    },
    // 팝업 닫기
    ModalClose() {
      var dimmed = document.getElementsByClassName("dimmed");
      var wrap = document.getElementsByClassName("popup-wrap");
      var obj = wrap[0].childElementCount;
      dimmed[0].style.display = "none";
      wrap[0].style.display = "none";
      for (var i = 0; i < obj; i++) {
        var target = document.getElementsByClassName("popup");
        target[i].style.display = "none";
        target[i].classList.remove("open");
      }
      this.fnInit();
      document.body.style.overflow = "auto"; //팝업 종료시 body 스크롤 해제 
    },

    //탭 메뉴 및 버튼 처리
    setActiveTab(tab){ // 탭 메뉴 클릭 active 효과
      this.activeTab = tab;
      this.$refs.updatePaging.fnAllDecrease();
      this.selectAll = false;
    },
    // 탭메뉴 a 태그 이벤트 막기
    handleClick(event){ 
      event.preventDefault();
    },

    // 체크박스 전체 선택
    checkedAll(checked){ 
      this.selectAll = checked;
      for(let i = 0; this.cards.length > i; i++ ){
        this.cards[i].checked = this.selectAll;
      }
    },
    //체크박스 단일 체크
    selectCheck() {
      if (this.cards.every((item) => item.selected == true)) {
        this.selectAll = "checked";
      } else {
        this.selectAll = "";
      }
    },

    // 검색 input 입력된 텍스트 감지해서 삭제 버튼 none / block 처리
    handleInputChange(){ 
      if(this.inputText.length > 0){
        this.isButtonHidden = false;
      }else{
        this.isButtonHidden = true;
      }
    },
    // 삭제 버튼 클릭 시 인풋 내용 삭제
    handleTextDelete(){ 
      this.inputText = "";
    },

    //문자 보관함 목록 조회
    async fnSelectMsgList(){
      // const isAuth = store.getters["login/isAuth"];
      let param ={
        srchCh : this.activeTab,
        srchStr : this.inputText,
        pageNo: this.pageNo, //페이징 필수 값
        listSize: this.listSize, //페이징 필수 값
      }
      if(this.activeTab == "ALL"){
        param.srchCh = null;
      }
      await messageSendApi.selectMsgStorage(param).then((response) => {
        const result = response.data;
        // this.msgList = result.data.msgStorageList;
        if(result.success) {

          let i =0;
          this.cards = result.data.msgStorageList;
          this.totCnt = result.data.pageInfo.totCnt;
          this.offset = result.data.pageInfo.offset;

          for(i = 0; this.totCnt > i; i++ ){
            this.cards[i].id = i;
            this.cards[i].messageType = result.data.msgStorageList[i].reqProduct;
            this.cards[i].title       = result.data.msgStorageList[i].title;
            this.cards[i].text        = result.data.msgStorageList[i].content;
            this.cards[i].date        = result.data.msgStorageList[i].regDt;
            this.cards[i].adYn        = result.data.msgStorageList[i].adYn;
            this.cards[i].checked     = false;
            
            if(this.cards[i].adYn== 'Y'){
              this.cards[i].ad = "true";
            } else {
              this.cards[i].ad = "false";
            }
            const fileName = this.cards[i].fileName ? this.cards[i].fileName.split(",") : "";

            
            let dateSubStr = this.cards[i].date;
            this.cards[i].dateSubStr = dateSubStr.substr(0,10);
            
            // const fileName  = i;
            
            if(this.cards[i].messageType=="MMS"){
              this.fileInfoList = result.data.msgStorageList[i].legacyFiles
                ? result.data.msgStorageList[i].legacyFiles
                    .split(",")
                    .filter((file) => file !== "")
                    .map((file, index) => {
                      return {
                        name: fileName !== "" ? fileName[index] : file,
                        src: `/api/v1/fo/preview/${file}`,
                        fileName: file,
                      };
                    })
                : [];
                this.cards[i].fileList = this.fileInfoList
            }
          }  

          this.srchStr = param.srchStr;
          this.srchCh =  param.srchCh;

          if(this.srchStr && this.totCnt==0){
            this.srchStrCnt = true
            this.srchChCnt = false;
          } else if (this.srchCh && this.totCnt==0){
            this.srchChCnt = true
            this.srchStrCnt = false;
          } else {
            this.srchChCnt = false;
            this.srchStrCnt = false;
          }
          

        } else {
          confirm.fnAlert("", "오류가 발생하였습니다.", "alert");
        }
      })
    },
    //페이징
    fnMsgStrSearch() {
      this.$refs.updatePaging.fnAllDecrease();
    },
    //검색
    fnSearch(pageNum) {
      const isAuth = store.getters["login/isAuth"];
      if (isAuth) {
        // 검색
        this.pageNo = this.$gfnCommonUtils.defaultIfEmpty(pageNum, "1") * 1;
        this.fnSelectMsgList();
      }
    },

    
    //다중 체크 삭제 - 확인
    handleDataDelete(){ // 삭제 confirm
      const tmp = this.cards.some((e) => {
        return e.checked === true;
      });
      if (this.totCnt === 0) {
        confirm.fnAlert("", "알림톡 템플릿 리스트가 없습니다.", "alert");
        return;
      } else if (!tmp) {
        confirm.fnAlert("", "템플릿을 선택해주세요.", "alert");
        return;
      }
      eventBus.$on("callbackEventBus", () => {
        this.fnDeleteMsgCheckParam()});
      confirm.fnConfirm("", "정말로 삭제하시겠습니까?", "del");
    },


    //다중 체크 삭제 - 선택 리스트
    fnDeleteMsgCheckParam(){
      const delMsgList = this.cards
        .filter((e) => {
          return e.checked === true;
        });

      let msgSeqs = [];

      delMsgList.forEach((e) => {
        msgSeqs.push(e.msgSeq)
      })

      this.fndeleteMsgStorage(msgSeqs);
    },

    //단일 삭제 확인
    fnDeleteOne(msgSeq){

      let msgSeqs = [];
      msgSeqs.push(msgSeq)
      eventBus.$on("callbackEventBus", () => {
        this.fndeleteMsgStorage(msgSeqs)});
      confirm.fnConfirm("", "정말로 삭제하시겠습니까?", "del");
    },

    //문자 삭제
    fndeleteMsgStorage(msgSeqs){

      let param = {
        msgSeqs : msgSeqs
      }
      //삭제 시에는 파라미터 값을 msgSeqs(List)로 던져야 한다.
      messageSendApi.deleteMsgStorage(param).then((response) => {
        var result = response.data;
        
        if (result.success) {
            eventBus.$on("callbackEventBus", () => {
              this.fnInit();
              this.fnSelectMsgList();
            });
            confirm.fnConfirm("", "삭제했습니다.", "cbAlert");
          } else {
            confirm.fnAlert("", result.message, "alert");
          }
        })
        .catch((error) => {
          // confirm.fnAlert("오류", "삭제 실패했습니다." + error, "alert");
          confirm.fnAlert("", "삭제 실패했습니다." + error, "alert");
        });
    },

    handleApplyMsg(index, msgSeq){
      
      eventBus.$on("callbackEventBus", () => {
        this.applyMsg(index);
      });
      confirm.fnConfirm("", "기존 내용에 덮어 씌워집니다. <br/>계속 진행하시겠습니까?", "confirm");
      let param = {
        msgSeq : msgSeq,
      }
      //문자 적용 시 문자보관함 테이블 REG_DT 업데이트
      messageSendApi.updateMsgStoreRegDt(param).then((response) => {
        var result = response.data;

        if(result.success) {
          // console.log("적용")
        } else {
          confirm.fnAlert("", result.message, "alert");
        }
      }).catch((error) => {
          confirm.fnAlert("", "적용에 실패했습니다." + error, "alert");
        });
    },

    //메시지 보내기 창 적용
    applyMsg(index){
      this.$emit("fnApplyMsg",this.cards[index]);
      this.ModalClose();
    }
  },
};
</script>
