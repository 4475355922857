import addressManage from "../views/addressManage";
import addressPubManage from "../views/addressPubManage";

export default [
  {
    path: "/addressManage/addressManage",
    component: addressManage,
  },
  {
    path: "/addressManage/addressPubManage",
    component: addressPubManage
  },


];
