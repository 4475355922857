<template>
  <div class="login_wrap">
    <div class="login_box">
      <div class="login_box-logo">
        <a href="javascript:void(0)">uplus 메시지허브이지</a>
        <p>Easy Messaging Service</p>
      </div>
      <div class="login_box-content">
        <form @submit.prevent="loginSubmit" autocomplete="off">
          <h2 class="title">로그인</h2>
          <label for="smo" class="label">아이디</label>
          <input type="text" v-model="userId" placeholder="아이디를 입력하세요." class="mb12" />
          <label for="smo" class="label mg12t">비밀번호</label>
          <input type="password" autoComplete="off" v-model="pwd" placeholder="비밀번호를 입력하세요." />
          <div class="id_password">
            <div class="chkbox">
              <input type="checkbox" checked id="id" class="chk" ref="chkSaveId" />
              <label for="id">아이디 저장</label>
            </div>
            <router-link to="/login/passWdInit">비밀번호 초기화</router-link>
          </div>
          <button type="submit" class="btn large_pink">로그인</button>
          <div class="search_join">
            <router-link to="/login/findUserId" class="id_search">아이디 찾기</router-link>
            <a :href="serviceJoinUrl" target="_blank" class="join">서비스 가입하기</a>
          </div>
          <p class="notice">*U+메시지허브이지를 이용하려면 [서비스 가입하기]를 눌러<br />화면 안내에 따라 가입해주세요. 서비스 가입은 비즈마켓 홈페이지에서 진행됩니다.</p>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import { createNamespacedHelpers, mapState } from "vuex";
import loginApi from "../service/loginApi";
import confirm from "@/modules/commonUtil/service/confirm";

const { mapActions } = createNamespacedHelpers("login");

export default {
  data: function () {
    return {
      serviceJoinUrl: "/",
      errors: [],
      errMsg: "",
      userId: "",
      pwd: "",
    };
  },
  created(){
    this.fnLogout();
  },
  mounted() {
    window.addEventListener("resize", this.handleResize);
    const baseUrl = window.location.origin;
    // 개발기일 경우 서비스 가입 주소
    if (baseUrl.indexOf("mhez-dev.uplus.co.kr") > -1) {
      this.serviceJoinUrl = "https://dev.bizmarket.uplus.co.kr/bizSvcDetail?sltnId=BPZ0000015";
      // 검수기일 경우 서비스 가입 주소
    } else if (baseUrl.indexOf("mhez-qa.uplus.co.kr") > -1) {
      this.serviceJoinUrl = "https://staging.bizmarket.uplus.co.kr/bizSvcDetail?sltnId=BPZ0000015";
      // 상용기일 경우 서비스 가입 주소
    } else if (baseUrl.indexOf("mhez.uplus.co.kr") > -1) {
      this.serviceJoinUrl = "https://bizmarket.uplus.co.kr/bizSvcDetail?sltnId=BPZ0000015";
    }

    if (localStorage.wtmpltUserId) {
      this.$refs.chkSaveId.checked = true;
      this.userId = localStorage.wtmpltUserId;
    } else {
      this.$refs.chkSaveId.checked = false;
    }

    this.$store.commit("login/isAuth", false);
  },
  computed: {
    ...mapState(["isLogin", "isLoginError"]),
  },
  methods: {
    // 로그인 설정은 Store Action에서 사용될 수 있도록 변경 작업 진행
    ...mapActions(["login"]),

    formCheck: function () {
      this.errors = [];
      if (!this.userId) this.errors.push("아이디를 입력해 주세요");
      if (!this.pwd) this.errors.push("비밀번호를 입력해 주세요");
      return this.errors.length == 0;
    },
    chgChkUserId() {
      if (this.$refs.chkSaveId.checked == true) {
        localStorage.wtmpltUserId = this.userId;
      } else {
        delete localStorage.wtmpltUserId;
      }
    },
    async loginSubmit() {
      document.activeElement.blur();
      // FormData 객체를 파라미터로 넘기면 Content-Type: multipart/form-data; 요청을 한다.
      // 일반 Object를 파라미터로 넘기면 Content-Type: application/json;charset=UTF-8 요청을 한다.
      this.chgChkUserId();
      await this.login({ userId: this.userId, pwd: this.pwd });
    },
    fnChangePwd() {
      this.$router.push({
        path: "/login/passWdReset",
      });
    },
    fnLogout() {
      this.$store.commit("login/isAuth", false);
      this.$store.commit("hubEzData", []);
      loginApi.logout({}).then((response) => {
        var result = response.data;
        console.log(result);
      });
    },
    bizReady() {
      confirm.fnAlert("", "비즈마켓 서비스 신규 오픈 작업으로 이용이 제한됩니다. <br/>(서비스 오픈일 : 2022년 12월 12일)", "alert");
    },
  },
};
</script>
