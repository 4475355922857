import confirm from "@/modules/commonUtil/service/confirm";
import { eventBus } from "@/modules/commonUtil/service/eventBus";
import loginApi from "../service/loginApi";
import router from "../../../router/index.js";

const state = {
  userId: "",
  isLogin: false,
  isLoginError: false,
  isAuth: false,
  isErrorPage: false,
};

const getters = {
  getUserId: (state) => state.userId,
  isAuth: (state) => state.isAuth,
  isLogin: (state) => state.isLogin,
  isErrorPage: (state) => state.isErrorPage,
};

const mutations = {
  // 1차 로그인 id pw 입력시 변경
  isLogin: (state, value) => {
    state.isLogin = value;
  },
  // 2차 휴대폰 인증시 변경
  isAuth(state, isAuth) {
    state.isAuth = isAuth;
  },
  isErrorPage: (state, value) => {
    state.isErrorPage = value;
  },

  //로그인 유저 아이디 초기화
  userId: (state, value) => {
    state.userId = value;
  },
  //로그인 성공했을때
  loginSuccess(state, payload) {
    state.isLogin = true;
    state.isLoginError = false;
    state.userId = payload;
  },
  //로그인 실패했을때
  loginError(state) {
    state.isLogin = false;
    state.isLoginError = true;
  },
};

const actions = {
  //로그인 시도
  login(
    {
      //state,
      commit,
    },
    signinObj
  ) {
    loginApi
      .login(signinObj)
      .then((response) => {
        const result = response.data;
        if (result.code == "SUCCESS") {
          commit("loginSuccess", signinObj.userId);
          router.push({ path: "/login/loginCertify" });
        } else if (result.code == "SUCCESS_CHG_PWD") {
          //비밀번호 변경 90일 초과 - 비밀번호 재설정 화면으로 이동
          commit("loginSuccess", signinObj.userId);
          eventBus.$on("callbackEventBus", () => {
            router.push({ path: "/login/passWdReset" });
          });
          confirm.fnConfirm("", "비밀번호를 3개월 이상 변경하지 않았습니다.<br />지금 비밀번호를 변경해 주세요.", "cbAlert");
        } else if (result.code == "CE_CHG_PWD") {
          //비밀번호 초기화 접속
          commit("loginSuccess", signinObj.userId);
          eventBus.$on("callbackEventBus", () => {
            router.push({ path: "/login/passWdReset" });
          });
          confirm.fnConfirm("", "최초 로그인 및 임시비밀번호로 로그인할 경우 <br />비밀번호를 변경 후 서비스 이용이 가능합니다.", "cbAlert");
        } else if (result.code == "CE_TO_LOCK") {
          //비밀번호 5회 실패 초기화 화면 이동
          commit("loginError");
          eventBus.$on("callbackEventBus", () => {
            router.push({ path: "/login/passWdInit" });
          });
          confirm.fnConfirm("", "아이디나 비밀번호를 5회 잘못 입력하여 로그인할 수 없습니다. <br />비밀번호 초기화를 눌러 비밀번호를 변경해주세요.", "cbAlert");
        } else {
          //로그인 실패
          commit("loginError");
          let msg = null;
          let next = null;
          //console.log(result.code);
          switch (result.code) {
            case "CE_ID_PWD": // ID/PWD 불일치
              msg = "아이디나 비밀번호를 잘못 입력했습니다. <br />정확하게 다시 입력해 주세요.";
              break;
            case "SS_NOT_AUTH": //사용자 정보 찾을수 없음
              msg = "사용자 정보를 찾을 수 없습니다. ID를 확인해 주세요.";
              break;
            // case "SS_NOT_USE": // 사용 중지 계정
            //   msg = "사용 정지된 아이디입니다. <br />기업이나 매장 내 서비스 관리자에게 문의해주세요.";
            //   break;
            // case "TEMPORARY_OPENING_USER": // 임시 개통 계정
            //   msg = "유큐브 개통 후 로그인 할 수 있습니다.";
            //   break;
            // case "BIZ_OPENING_USER": // 비즈마켓 개통 계정
            //   msg = "유큐브 개통 후 로그인 할 수 있습니다.";
            //   break;
            case "SS_DEL_USE": // 삭제 된 계정
              msg = "삭제된 계정입니다.";
              break;
            /*
            case "PAUSE_USER": // 일시 정지
              msg = "일시 정지된 아이디입니다. <br />기업이나 매장 내 서비스 관리자에게 문의해주세요.";
              break;
            case "NOT_PAYMENT_USER": // 미납 정지
              msg = "미납 정지된 아이디입니다. <br />기업이나 매장 내 서비스 관리자에게 문의해주세요.";
              break;
            */
            case "CANCEL_USER": // 취소
              msg = "취소된 계정입니다.";
              break;
            case "TERMINATION_USER": // 해지
              msg = "청약 해지된 계정입니다.";
              break;
            case "SE_INTERNAL": // 최근 접속일시 업데이트 실패
              msg = "데이터베이스와 통신에 실패하였습니다.";
              break;
            case "SE_UNKNOWN": // 알 수 없는 오류
              msg = "알 수 없는 오류가 발생하였습니다.";
              break;
            default:
              msg = "알 수 없는 오류가 발생하였습니다.";
              break;
          }
          if (msg != null) {
            confirm.fnAlert("", msg, "alert");
            commit("loginError");
            if (next != null) {
              next();
            }
          }
        }
      })
      .catch((error) => {
        //console.log(error);
        this.errMsg = error;
      });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
