<template>
  <Fragment>
    <section class="m_section">
      <div class="contents">
        <div class="cont_wrap without_tab address">
          <h1 class="title with_desc">공유 주소록</h1>
          <div class="tabcontTop">
            <ul>
              <li>공유 주소록을 등록, 수정, 삭제할 수 있습니다.</li>
              <li>공유 주소록에 등록된 주소록은 사업자번호 내 모든 사용자들에게 공유됩니다.</li>
              <li>그룹 생성 시 해당 그룹을 생성한 계정만이 해당 그룹 내 연락처를 관리(추가,수정,삭제)할 수 있습니다.</li>
            </ul>
            <button class="btn_way" @click="fnAddressUploadMethod">
              <div class="btn_question"></div>
              휴대폰 주소록을 업로드하는 방법 보기
            </button>
          </div>
          <div class="search_box">
            <h3>번호 간편 등록</h3>
            <div class="group group01">
              <label for="right" class="label">그룹명</label>
              <div class="select_wrap">
                <select class="select" v-model="selRegAddrInfo">
                  <option value="N" selected>그룹선택</option>
                  <option v-for="(regSelAddr, index) in regAddrGrpMstSelList" :key="index" :value="regSelAddr">
                    {{ regSelAddr.groupNm }}
                  </option>
                </select>
              </div>
            </div>
            <div class="group group02">
              <label for="right" class="label">휴대폰 번호</label>
              <input type="text" v-model="phone" class="input" placeholder="휴대폰 번호 입력 (‘-’없이 숫자만 입력)" />
            </div>
            <div class="group group03">
              <label for="right" class="label">이름</label>
              <input type="text" v-model="name" class="input" placeholder="이름 입력" />
            </div>
            <div class="group group04">
              <label for="right" class="label">비고</label>
              <input type="text" v-model="memo" class="input" placeholder="비고 입력 (최대 50byte)" />
            </div>

            <!-- 0705 button/ group5 div에서 분리 -->
            <button class="btn plus" :disabled="!(phone != '' && loginDiv)" @click="fnSaveAddrMember">추가</button>
          </div>
          <div class="address_wrap">
            <div class="group_wrap">
              <div class="group_top1">
                <button class="btn plus" v-if="loginDiv" @click="ModalOpen('excelpop')" type="button">주소록 대량 등록</button>
                <button class="btn plus" v-else @click="fnCantReg" type="button" :disabled="!loginDiv">주소록 대량 등록</button>
              </div>
              <div class="group_top">
                <div class="search_wrap">
                  <div class="search_input">
                    <input type="text" v-model="srchGroupNm" class="search" placeholder="그룹명 검색" />
                    <button class="btn_search" @click="fnSrchAddrGroup"></button>
                  </div>
                </div>
              </div>
              <div class="group">
                <div class="group_add_wrap">
                  <input type="text" class="input" placeholder="그룹명 입력" v-model="groupNm" />
                  <button class="btn plus" v-if="loginDiv" @click="fnSaveAddrGroup" >추가</button>
                  <button class="btn plus" v-else @click="fnCantReg" :disabled="!loginDiv">추가</button>
                </div>

                <div class="group_list scr_wrap">
                  <ul class="file_depth1">
                    <li class="has_depth" :class="{ on: active }">
                      <div v-if="!grpSearch" class="file_name_wrap">
                        <button class="file_left" @click="fnAddrGrpClk('all')">
                          <div class="btn_file_name">
                            <span class="file_name ellipsis">전체</span>
                            <span v-if="totalCnt > 0" class="count">({{ totalCnt | formatPrice }})</span>
                          </div>
                        </button>
                      </div>
                      <!-- <ul v-if="!grpSearch && nAppointCnt > 0" class="file_depth2">
                        <li :class="{ 'on ': nAppointActive }">
                          <div class="file_name_wrap">
                            <button class="file_left" @click="fnAddrGrpClk('nat')">
                              <div class="btn_file_name">
                                <span class="file_name ellipsis">그룹 없음</span>
                                <span class="count">({{ nAppointCnt | formatPrice }})</span>
                              </div>
                            </button>
                          </div>
                        </li>
                      </ul> -->
                      <ul class="file_depth2">
                        <li v-for="(tree, index) in addrGrpList" :key="index" :class="{ has_depth: tree.childCnt > 0, on: tree.classActive == 'Y' }">
                          <div class="file_name_wrap">
                            <button class="file_left">
                              <div v-if="tree.childCnt > 0" :class="{ btn_file_plus: tree.childCnt > 0 && tree.isActive == 'Y', btn_file_minus: tree.childCnt > 0 && tree.isActive == 'N' }"></div>
                              <div class="btn_file_name">
                                <!-- <span v-if="!tree.selected" class="file_name ellipsis" :title="tree.groupNm" @click="fnAddrGrpClk(tree)">{{ tree.groupNm | textLengthCut(10, "…") }}</span> -->
                                <span v-if="!tree.selected" class="file_name" :class="{ mine_yn : tree.mineYn == 'Y'}" :title="tree.groupNm" @click="fnAddrGrpClk(tree)">{{ tree.groupNm | textLengthCut(10, "…") }}</span>
                                <span v-else class="file_name ellipsis edit">
                                  <input type="text" v-model="tree.moGroupNm" placeholder="수정 그룹명 입력" @keyup.enter="fnAddrGrpModify(tree)" />
                                </span>
                                <span v-if="tree.addrCnt > 0 && !tree.selected" @click="fnAddrGrpClk(tree)" class="count" :class="{ mine_yn : tree.mineYn == 'Y'}">({{ tree.addrCnt | formatPrice }})</span>
                              </div>
                            </button>
                            <div class="file_right">
                              <button v-if="tree.classActive == 'Y' && !tree.selected" v-show="mineYn=='Y'" class="btn_edit" @click="fnModifyAddrGrpAct('P', index)"></button>
                              <button v-if="tree.classActive == 'Y' && tree.selected" v-show="mineYn=='Y'" class="btn_edit" @click="fnAddrGrpModify(tree)"></button>
                              <button v-if="tree.classActive == 'Y'" class="btn_del" v-show="mineYn=='Y'" @click="fnDeleteAddrGrp"></button>
                            </div>
                          </div>
                          <ul v-for="(child, index) in addrGrpChildList" :key="index" class="file_depth3">
                            <template v-if="tree.isActive == 'Y' && tree.groupSeq == child.parentGroupSeq">
                              <li :class="{ on: child.classActive == 'Y' }">
                                <div class="file_name_wrap">
                                  <button class="file_left">
                                    <div class="btn_file_name">
                                      <span v-if="!child.selected" class="file_name" :class="{ mine_yn : tree.mineYn == 'Y'}" @click="fnAddrGrpClk(child)">{{ child.groupNm | textLengthCut(10, "…") }}</span>
                                      <span v-else class="file_name ellipsis edit">
                                        <input type="text" v-model="child.moGroupNm" placeholder="수정 그룹명 입력" />
                                      </span>
                                      <span v-if="child.addrCnt > 0 && !child.selected" @click="fnAddrGrpClk(child)" class="count" :class="{ mine_yn : tree.mineYn == 'Y'}">({{ child.addrCnt }})</span>
                                    </div>
                                  </button>
                                  <div class="file_right">
                                    <button v-if="child.classActive == 'Y' && !child.selected" v-show="mineYn=='Y'" class="btn_edit" @click="fnModifyAddrGrpAct('C', index)"></button>
                                    <button v-if="child.classActive == 'Y' && child.selected" v-show="mineYn=='Y'" class="btn_edit" @click="fnAddrGrpModify(child)"></button>
                                    <button v-if="child.classActive == 'Y'" class="btn_del" v-show="mineYn=='Y'" @click="fnDeleteAddrGrp"></button>
                                  </div>
                                </div>
                              </li>
                            </template>
                          </ul>
                        </li>
                      </ul>
                    </li>
                  </ul>
                </div>
                <div class="btn_wrap">
                  <button class="btn white" v-if="loginDiv" type="button" @click="fnAddrGrpExec('M')">그룹이동</button>
                  <button class="btn white" v-if="loginDiv" type="button" @click="fnAddrGrpExec('C')">그룹복사</button>
                </div>
              </div>
            </div>
            <div class="list_wrap">
              <div class="list_top list_info">
                <div class="left_wrap">
                  <div class="select_wrap">
                    <select v-model="selected" @change="fnSelected" class="select">
                      <option value="30">30</option>
                      <option value="50">50</option>
                      <option value="100">100</option>
                    </select>
                  </div>
                  <p class="total">
                    총 <span class="pink">{{ totCnt | formatPrice }} </span>건
                  </p>
                  <div v-if="addrDiv=='G'">
                    [그룹생성 ID] : {{ userId }}({{ userNm }})
                  </div>
                </div>
                <div class="search_wrap">
                  <div class="search_input">
                    <input type="text" v-model="searchPhone" class="search" placeholder="번호를 입력하세요." />
                    <button class="btn_search" @click="fnAddrMemberSearch"></button>
                  </div>
                </div>
              </div>

              <div class="fixed-table-cover">
                <div class="fixed-table-container" style="width: 738px;">
                  <div class="fixed-table-header-bg"></div>
                  <div class="fixed-table-wrapper">
                    <table class="fixed-table" style="table-layout: fixed">
                      <thead>
                        <tr>
                          <th class="td_chk" style="width: 40px">
                            <div class="th-text">
                              <input type="checkbox" id="listCheck_all" class="chk_all" @change="fnListChkAll" v-model="listAllChecked" /><label for="listCheck_all"></label>
                            </div>
                          </th >
                          <th class="td_group" style="width: 20%">
                            <div class="th-text">그룹명</div>
                          </th >
                          <th class="td_phone" style="width: 25%">
                            <div class="th-text">휴대폰 번호</div>
                          </th>
                          <th class="td_name" style="width: 18%">
                            <div class="th-text">이름</div>
                          </th>
                          <th class="td_5" style="width: 17%">
                            <div class="th-text">비고</div>
                          </th>
                          <th class="td_6" style="width: calc(20% - 40px)">
                            <div class="th-text">수신거부</div>
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="(data, index) in addrMemList" :key="index" class="list" :class="{ on: data.tgtCheck }">
                          <td class="td_chk">
                            <input type="checkbox" class="chk" :id="'listCheck_' + index" :value="data" v-model="data.tgtCheck" /><label :for="'listCheck_' + index"></label>
                          </td>
                          <td class="td_group">{{ data.groupNm | textLengthCut(8, "…") }}</td>
                          <td v-if = "mineYn=='Y'" class="td_phone"><a @click="fnUpdateAddrMember(data)">{{ data.phone }}</a></td>
                          <td v-if = "mineYn=='N'" class="td_phone">{{ data.phone }}</td>
                          <td class="td_name">{{ data.name }}</td>
                          <td class="td_5 btn_question1 tt_btn">{{ data.memo | textLengthCut(5, "…") }}
                              <div v-if="data.memo.length>0" class="tooltip1 t_tooltip">
                                <p>{{ data.memo }}</p>
                              </div>
                          </td>
                          <td v-if="data.blockDiv == 'BLOCK'" class="td_6">●</td>
                          <td v-if="data.blockDiv == 'PASS'" class="td_6"></td>
                        </tr>
                        
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              <div class="list_bottom">
                <div class="left_wrap" v-show="mineYn=='Y'">
                  <button class="btn white small" v-if="loginDiv" @click="fnDeleteAddrMember" type="button">삭제</button>
                  <button class="btn white small" v-if="loginDiv" @click="fnAddrMemberExec('M')" type="button">이동</button>
                  <button class="btn white small" v-if="loginDiv" @click="fnAddrMemberExec('C')" type="button">복사</button>
                  <button class="btn white small" v-if="loginDiv" type="button" @click="fnModifyAddrList">수정</button>
                </div>
                <div class="left_wrap" v-show="mineYn=='N'">
                  <button class="btn white small" v-if="loginDiv" @click="fnAddrMemberExec('C')" type="button">복사</button>
                </div>
                <button class="btn white small" v-if="loginDiv" type="button" @click="excelDownload">엑셀 파일로 내려받기</button>
              </div>
              <div class="paging_wrap" v-show="totCnt > listSize">
                <PageLayer ref="updatePaging" @fnClick="fnSearch" :listTotalCnt="totCnt" :selected="listSize" :pageNum="pageNo"></PageLayer>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- <div class="dimmed" @click="ModalClose()"></div> -->
    <div class="popup-wrap">
      <!-- s:그룹 삭제 -->
      <div class="popup popup modal03 caution" style="display: block">
        <div class="pop-head"></div>
        <div class="pop-cont">
          <!-- <p class="pop-tit">그룹 삭제</p> -->
          <p class="pop-desc" v-html="deleteMsg"></p>
        </div>
        <div class="pop-btn3">
          <button type="button" class="btn large_pink" @click="fnDeleteGrpExec('AG')">모두 삭제</button>
          <button type="button" class="btn large_white" @click="fnDeleteGrpExec('G')">그룹만 삭제</button>
          <button type="button" class="btn large_white" @click="ModalClose">취소</button>
        </div>
      </div>
      <!-- e:그룹 삭제 -->

      <!-- s: 주소록 수정 -->
      <div class="popup tit edit_address">
        <div class="pop-head">
          <h3 class="pop-tit">주소록 수정</h3>
        </div>
        <div class="pop-cont">


          <div class="fixed-table-cover">
            <div class="fixed-table-container" style="width: 950px;">
              <div class="fixed-table-header-bg"></div>
              <div class="fixed-table-wrapper">
                <table class="fixed-table" style="table-layout: fixed">
                  <thead>
                    <tr>
                      <th class="td_group" style="width: 25%">
                        <div class="th-text">그룹명</div>
                      </th >
                      <th class="td_phone" style="width: 25%">
                        <div class="th-text">휴대폰 번호</div>
                      </th>
                      <th class="td_name" style="width: 25%">
                        <div class="th-text">이름</div>
                      </th>
                      <th class="td_5" style="width: 25%">
                        <div class="th-text">비고</div>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(modiMemInfo, index) in modiMemeList" :key="index">
                      <td class="td_group">
                        <select class="select" v-model="modiMemInfo.modiGroupSeq">
                          <!-- <option value="N">그룹 없음</option> -->
                          <option v-for="(regSelAddr, index) in regAddrGrpSelList" :key="index" :value="regSelAddr.modiGroupSeq">
                            {{ regSelAddr.groupNm }}
                          </option>
                        </select>
                      </td>
                      <td class="td_phone"><input type="text" class="text" v-model="modiMemInfo.phone" @input="fnPhoneDash($event, index)" /></td>
                      <td class="td_name"><input type="text" class="input" v-model="modiMemInfo.name" /></td>
                      <td class="td_5"><input type="text" class="input" v-model="modiMemInfo.memo" /></td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div class="pop-btn2 center">
            <button class="btn large_pink" @click="fnAddrMemModify">수정</button>
            <button class="btn large_white" @click="ModalClose">취소</button>
          </div>
        </div>
      </div>
      <!-- e: 주소록 수정 -->

      <!-- s: 그룹 이동, 복사 -->
      <div class="popup tit move_address grpmove">
        <div class="pop-head">
          <h3 v-if="grpExecDiv == 'M'" class="pop-tit">그룹 이동</h3>
          <h3 v-if="grpExecDiv == 'C'" class="pop-tit">그룹 복사</h3>
        </div>
        <div class="group_wrap" style="display:flex; margin-bottom: 0px; border-bottom: none;">
          <div class="radio_wrap">
            <ul>
              <li>
                <p>구분선택</p>
              </li>
              <li><input type="radio" name="addrGrp" id="addrGrp1" value="P"  v-model="addrDiv" class="radio" @click="fnAddrGrpSelect('P')" /><label for="addrGrp1">개인 주소록</label></li>
              <li><input type="radio" name="addrGrp" id="addrGrp2" value="G"  v-model="addrDiv" checked="checked" class="radio" @click="fnAddrGrpSelect('G')" /><label for="addrGrp2" >공유 주소록</label></li>
            </ul>
          </div>
        </div>
        <div class="pop-cont">
          <!--div class="pop_top"></div-->
          <div class="group_wrap">
            <p>그룹선택</p>
            <div class="select_wrap">
              <select v-model="selAddrInfo" class="select" >
                <option v-if="grpExecDiv == 'M'" value="defaultGrp" selected>이동할 그룹을 선택하세요</option>
                <option v-if="grpExecDiv == 'C'" value="defaultGrp" selected>복사할 그룹을 선택하세요</option>
                <!-- <option v-if="!nAppointActive" value="N">그룹 없음</option> -->
                <option v-for="(selAddr, index) in addrGrpSelList" :key="index" :value="selAddr">
                  {{ selAddr.groupNm }}
                </option>
              </select>
            </div>
          </div>
          <div class="pop-btn2 center">
            <button v-if="grpExecDiv == 'M'" class="btn large_pink" @click="fnAddrGrpMove">이동</button>
            <button v-if="grpExecDiv == 'C'" class="btn large_pink" @click="fnAddrGrpCopy">복사</button>
            <button @click="ModalClose" class="btn large_white">취소</button>
          </div>
        </div>
      </div>
      <!-- e: 그룹 이동, 복사 -->

      <!-- s: 주소록 업로드방법 0629 추가 -->
      <div class="popup tit address_upload">
        <div class="pop-head">
          <h3 class="pop-tit">휴대폰 주소록 가져오기</h3>
        </div>
        <div class="pop-cont">
          <p class="pop-desc dot">휴대폰에 연락처가 있으신 경우, 주소록에 연락처를 추가해 주세요.</p>
          <div class="scr_wrap">
            <div class="step_box step01 pc">
              <div class="txt">
                <div class="step">STEP 01</div>
                <div class="desc">
                  <p class="bold">휴대폰 연락처를 네이버 주소록에 업로드</p>
                  <p class="stxt">모바일에서 네이버 ‘주소록’에 업로드해주세요.</p>
                </div>
              </div>
              <div class="img"><img src="@/assets/images/address_01pc.png" alt="" /></div>
            </div>
            <div class="step_box step01 mobile">
              <div class="txt">
                <div class="step">STEP 01</div>
                <div class="desc">
                  <p class="bold">휴대폰 연락처를 네이버 주소록에 업로드</p>
                  <p class="stxt">모바일에서 네이버 ‘주소록’에 업로드해주세요.</p>
                </div>
              </div>
              <div class="swiper step_swiper">
                <template>
                  <swiper class="swiper-wrapper" :options="addressHowUpload">
                    <swiper-slide class="swiper-slide">
                      <img src="@/assets/images/address_0101.png" alt="" />
                    </swiper-slide>
                    <swiper-slide class="swiper-slide">
                      <img src="@/assets/images/address_0102.png" alt="" />
                    </swiper-slide>
                    <swiper-slide class="swiper-slide">
                      <img src="@/assets/images/address_0103.png" alt="" />
                    </swiper-slide>
                    <swiper-slide class="swiper-slide">
                      <img src="@/assets/images/address_0104.png" alt="" />
                    </swiper-slide>
                    <swiper-slide class="swiper-slide">
                      <img src="@/assets/images/address_0105.png" alt="" />
                    </swiper-slide>
                  </swiper>
                  <div class="address_how_upload-pagination swiper-pagination"></div>
                </template>
              </div>
            </div>
            <div class="step_box step02">
              <div class="txt">
                <div class="step">STEP 02</div>
                <div class="desc">
                  <p class="bold">네이버 주소록에서 XLSX 형식 파일로 다운로드</p>
                  <p class="stxt">PC로 접속하여 네이버 주소록에서 ‘연락처 저장·내보내기’ 클릭 후, ‘XLSL’형식의 파일로 주소록을 다운로드 해주세요.</p>
                </div>
              </div>
              <div class="img"><img src="@/assets/images/address_02.png" alt="" /></div>
            </div>
            <div class="step_box step03">
              <div class="txt">
                <div class="step">STEP 03</div>
                <div class="desc">
                  <p class="bold">다운받은 주소록 파일을 양식에 맞춰 파일 업로드</p>
                  <p class="stxt">메시지허브이지 주소록 페이지의 대량등록에서 샘플파일 다운로드 후 양식에 맞게 수정하여 업로드 해주세요.</p>
                </div>
              </div>
              <div class="img"><img src="@/assets/images/address_03.png" alt="" /></div>
            </div>
          </div>
          <div class="pop-btn1">
            <button class="btn large_pink" @click="ModalClose">확인</button>
          </div>
        </div>
      </div>
      <!-- e: 주소록 업로드방법 -->

      <!-- s: 주소록 이동, 복사 -->
      <div class="popup tit move_address addrmove">
        <div class="pop-head">
          <h3 v-if="memExecDiv == 'M'" class="pop-tit">번호 이동</h3>
          <h3 v-if="memExecDiv == 'C'" class="pop-tit">번호 복사</h3>
        </div>
        <div class="group_wrap" style="display:flex; margin-bottom: 0px; border-bottom: none;">
          <div class="radio_wrap">
            <ul>
              <li>
                <p>구분선택</p>
              </li>
              <li><input type="radio" name="addr" id="addr1" value="P"  v-model="addrDiv" class="radio" @click="fnAddrGrpSelect('P')" /><label for="addr1">개인 주소록</label></li>
              <li><input type="radio" name="addr" id="addr2" value="G"  v-model="addrDiv" checked="checked" class="radio" @click="fnAddrGrpSelect('G')" /><label for="addr2">공유 주소록</label></li>
            </ul>
          </div>
        </div>
        <div class="pop-cont">
          <!--div class="pop_top"></div-->
          <div class="group_wrap">
            <p>그룹선택</p>
            <div class="select_wrap">
              <select v-model="selAddrInfo" class="select">
                <option v-if="memExecDiv == 'M'" value="defaultGrp" selected>이동할 그룹을 선택하세요</option>
                <option v-if="memExecDiv == 'C'" value="defaultGrp" selected>복사할 그룹을 선택하세요</option>
                <!-- <option value="N">그룹 없음</option> -->
                <option v-for="(selAddr, index) in addrGrpSelList" :key="index" :value="selAddr">
                  {{ selAddr.groupNm }}
                </option>
              </select>
            </div>
          </div>
          <div class="pop-btn2 center">
            <button v-if="memExecDiv == 'M'" class="btn large_pink" @click="fnAddrMemMove">이동</button>
            <button v-if="memExecDiv == 'C'" class="btn large_pink" @click="fnAddrMemCopy">복사</button>
            <button @click="ModalClose" class="btn large_white">취소</button>
          </div>
        </div>
      </div>
      <!-- e:주소록 이동, 복사 -->

      <AddrExcelUpload ref="addrUpload" @close="fnModalCloseUpload" /><!-- 주소록 대량 업로드 -->
    </div>
  </Fragment>
</template>

<script>
import * as XLSX from "xlsx";
import store from "@/store";
import confirm from "@/modules/commonUtil/service/confirm";
import { eventBus } from "@/modules/commonUtil/service/eventBus";
import PageLayer from "@/components/PageLayer.vue";
import AddrExcelUpload from "../components/addressPubExcelUpload.vue";
import addressApi from "../service/addressPubApi";
import { Fragment } from "vue-frag";
import { swiper, swiperSlide } from "vue-awesome-swiper";

export default {
  name: "addressManagePubList",
  components: {
    PageLayer,
    AddrExcelUpload,
    Fragment,
    swiper,
    swiperSlide,
  },
  data() {
    return {
      //주소록관리
      selected: "30",
      listSize: 30, // select 박스 value (출력 갯수 이벤트)
      pageNo: 1, // 현재 페이징 위치
      totCnt: 0, //전체 리스트 수
      offset: 0, //페이지 시작점
      loginDiv: false,
      addrGrpInfo: {},
      addrGrpList: [],
      addrGrpChildList: [],
      addrMemList: [],
      modiMemeList: [],
      addrMemInfo: {},
      regAddrGrpSelList: {},
      regAddrGrpMstSelList: {},
      addrGrpSelList: [],
      selRegAddrInfo: "N",
      selAddrInfo: "",
      grpExecDiv: "",
      memExecDiv: "",
      searchPhone: "",
      srchGroupSeq: "",
      srchLevDepth: "0",
      selGroupNm: "",
      srchGroupNm: "",
      selAddrCnt: 0,
      realAddrCnt: 0,
      parentGroupSeq: "",
      groupNm: "",
      lev1: "",
      lev2: "",
      levDepth: "",
      name: "",
      phone: "",
      email: "",
      gname: "",
      memo: "",
      deleteMsg: "",
      grpSearch: false,
      addrGrpDeleteOpen: false,
      addrGrpExecOpen: false,
      addrMemModifyOpen: false,
      addrMemExecOpen: false,
      listAllChecked: false,
      memSeq: "",
      active: false,
      totalCnt: 0,
      nAppointActive: false,
      nAppointCnt: 0,
      addrDiv: "G",
      userId: "",
      userNm: "",
      userSeq: "",
      addrInfo: {
        pageNo: 1,
        listSize: 30,
        searchPhone: "",
        srchGroupSeq: "",
        srchLevDepth: "",
        srchGroupNm: "",
        authcd080: "",
        userSeq: "",
        groupSeq: "",
        levDepth: "",
        lev1: "",
        lev2: "",
        bfGropuNm: "",
        parentGroupSeq: "",
        selParentGroupSeq: "",
        selGroupSeq: "",
        grpExecDiv: "",
        memExecDiv: "",
        name: "",
        phone: "",
        email: "",
        gname: "",
        memo: "",
        seqNo: "",
        addrMemList: [],
        deleteDiv: "",
        addrDiv : "G",
        selUserSeq : "",
      },
      visibleHeadProps: [],
      instance: undefined,
      excelList: [],
      header: [
        {
          key: "groupNm",
          name: "그룹",
        },
        {
          key: "phone",
          name: "휴대폰번호",
        },
        {
          key: "name",
          name: "이름",
        },
        {
          key: "memo",
          name: "비고",
        },
        {
          key: "blockDiv",
          name: "수신거부",
        },
      ],
      addressHowUpload: {
        slidesPerView: 1,
        loop: false,
        speed: 800,
        allowTouchMove: false,
        observer: true,
        observeParents: true,
        pagination: {
          clickable: true,
          el: ".address_how_upload-pagination",
          renderBullet: function (index, className) {
            return '<span class="' + className + '">' + "0" + (index + 1) + "</span>";
          },
        },
      },
      listScrollPostion: 0,
      mineYn: "N",
    };
  },
  mounted() {
    this.fnInit();
    this.fnSelectAddressGrpList();
    this.fnSelectRegAddrGrpList();
    this.fnAddrGrpClk("all");
  },
  watch: {
    searchPhone(val) {
      if (val !== "") {
        let regExp = /^[0-9]+$/;
        if (!regExp.test(val)) {
          confirm.fnAlert("", "검색어는 숫자만 입력 가능 합니다.", "alert");
          this.searchPhone = val.replace(/[^0-9]/g, "");
        }
      }
    },
    phone(val) {
      if (val !== "") {
        let regExp = /^[0-9]+$/;
        if (!regExp.test(val)) {
          confirm.fnAlert("", "휴대폰번호는 숫자만 입력 가능 합니다.", "alert");
          this.phone = val.replace(/[^0-9]/g, "");
        }
      }
    },
  },
  methods: {
    fnInit() {
      const isAuth = store.getters["login/isAuth"];
      if (isAuth) {
        this.loginDiv = true;
      }
    },
    fnAddrInfoInit() {
      this.totCnt = 0;
      this.addrInfo = {
        pageNo: 1,
        listSize: 30,
        searchPhone: "",
        srchGroupSeq: "",
        srchLevDepth: "",
        userSeq: "",
        groupSeq: "",
        levDepth: "",
        lev1: "",
        lev2: "",
        bfGropuNm: "",
        parentGroupSeq: "",
        selParentGroupSeq: "",
        selGroupSeq: "",
        grpExecDiv: "",
        memExecDiv: "",
        name: "",
        phone: "",
        email: "",
        gname: "",
        memo: "",
        seqNo: "",
        addrMemList: [],
        deleteDiv: "",
      };
    },
    //주소 그룹 조회
    fnSelectAddressGrpList() {
      this.fnAddrInfoInit();
      this.addrInfo = {
        userSeq: "",
      };
      addressApi.selectAddressPubGrpList(this.addrInfo).then((response) => {
        var result = response.data;
        if (result.success) {
          this.addrGrpList = result.data.addrGrpList.map((e) => {
            return {
              ...e,
              selected: false,
              moGroupNm: e.groupNm,
            };
          });
          this.addrGrpChildList = result.data.addrGrpChildList.map((e) => {
            return {
              ...e,
              selected: false,
              moGroupNm: e.groupNm,
            };
          });
          this.totalCnt = result.data.totalCnt;
          this.nAppointCnt = result.data.nAppointCnt;
          // this.userId = result.data.userId;
          // this.userNm = result.data.userNm;
        } else {
          confirm.fnAlert("", "오류가 발생하였습니다.", "alert");
        }
      });
    }, //간편 등록 그룹 전체 조회
    fnSelectRegAddrGrpList() {
      this.addrInfo = {
        userSeq: "",
        addrDiv: "G"
      };
      addressApi.selectAddressPubGrpSelList(this.addrInfo).then((response) => {
        var result = response.data;
        if (result.success) {
          this.regAddrGrpMstSelList = result.data.addrGrpSelList;
        } else {
          confirm.fnAlert("", "오류가 발생하였습니다.", "alert");
        }
      });
    },
    //주소 그룹 검색
    fnSrchAddrGroup() {
      this.fnAddrInfoInit();
      this.addrInfo = {
        userSeq: "",
        srchGroupNm: this.srchGroupNm,
      };

      if (this.srchGroupNm == "") {
        this.grpSearch = false;
        addressApi.selectAddressPubGrpList(this.addrInfo).then((response) => {
          var result = response.data;
          if (result.success) {
            this.addrGrpList = result.data.addrGrpList.map((e) => {
              return {
                ...e,
                selected: false,
                moGroupNm: e.groupNm,
              };
            });
            this.addrGrpChildList = result.data.addrGrpChildList.map((e) => {
              return {
                ...e,
                selected: false,
                moGroupNm: e.groupNm,
              };
            });
            this.totalCnt = result.data.totalCnt;
            this.nAppointCnt = result.data.nAppointCnt;
            this.fnAddrGrpClk("all");
          } else {
            confirm.fnAlert("", "오류가 발생하였습니다.", "alert");
          }
        });
      } else {
        this.grpSearch = true;
        addressApi.searchAddressPubGrpList(this.addrInfo).then((response) => {
          var result = response.data;
          if (result.success) {
            this.addrGrpList = result.data.addrGrpList.map((e) => {
              return {
                ...e,
                selected: false,
                moGroupNm: e.groupNm,
              };
            });
            this.addrGrpChildList = result.data.addrGrpChildList.map((e) => {
              return {
                ...e,
                selected: false,
                moGroupNm: e.groupNm,
              };
            });
            this.fnAddrGrpClk("srch");
          } else {
            confirm.fnAlert("", "오류가 발생하였습니다.", "alert");
          }
        });
      }
    },
    //주소록 명단 카테고리 클릭
    fnAddrGrpClk(treeObj) {
      this.userId = treeObj.userId;
      this.userNm = treeObj.userNm;
      this.userSeq = treeObj.userSeq;
      this.addrGrpInfo = Object.assign({}, treeObj);
      this.addrMemList = [];
      this.searchPhone = "";
      this.mineYn = treeObj.mineYn;

      this.addrGrpList.forEach(function (tree) {
        tree.classActive = "N";
        tree.selected = false;
      });
      this.addrGrpChildList.forEach(function (tree) {
        tree.classActive = "N";
        tree.selected = false;
      });
      this.addrGrpInfo = Object.assign({}, treeObj);
      this.addrMemList = [];
      this.searchPhone = "";

      if (treeObj == "all") {
        //전체
        this.active = true;
        this.nAppointActive = false;
        this.srchGroupSeq = "";
        this.srchLevDepth = "0";
        this.selGroupNm = "전체";
        this.$refs.updatePaging.fnAllDecrease();
        this.mineYn = "N"
      } else if (treeObj == "nat") {
        //그룹 없음
        this.nAppointActive = true;
        this.active = false;
        this.parentGroupSeq = "0";
        this.srchGroupSeq = "0";
        this.srchLevDepth = "1";
        this.selGroupNm = "그룹 없음";
        this.selAddrCnt = this.nAppointCnt;
        this.realAddrCnt = this.nAppointCnt;
        this.$refs.updatePaging.fnAllDecrease();
      } else if (treeObj == "srch") {
        //검색시 그룹 초기화(선택 그룹 X)
        this.nAppointActive = false;
        this.active = false;
        this.parentGroupSeq = "";
        this.srchGroupSeq = "";
        this.srchLevDepth = "";
        this.selGroupNm = "";
      } else {
        //트리 그룹
        this.parentGroupSeq = treeObj.parentGroupSeq + "";
        this.srchGroupSeq = treeObj.groupSeq + "";
        this.srchLevDepth = treeObj.levDepth;
        this.selGroupNm = treeObj.groupNm;
        this.selAddrCnt = treeObj.addrCnt;
        this.realAddrCnt = treeObj.realAddrCnt;
        this.active = false;
        this.nAppointActive = false;

        if ("N" == treeObj.classActive) {
          treeObj.classActive = "Y";
        } else {
          treeObj.classActive = "N";
        }

        if ("Y" == treeObj.isActive) {
          treeObj.isActive = "N";
        } else {
          treeObj.isActive = "Y";
        }
        this.$refs.updatePaging.fnAllDecrease();
      }
      // 체크박스 초기화
      this.listAllChecked = false;
      this.fnListChkAll();
    },
    fnSelected() {
      this.listSize = Number(this.selected);
      this.$refs.updatePaging.fnAllDecrease();
    },
    //리스트 전체 체크박스
    fnListChkAll() {
      if (this.listAllChecked) {
        this.addrMemList.forEach(function (memberInfo) {
          memberInfo.tgtCheck = true;
        });
      } else {
        this.addrMemList.forEach(function (memberInfo) {
          memberInfo.tgtCheck = false;
        });
      }
    },
    fnAddrMemberSearch() {
      this.$refs.updatePaging.fnAllDecrease();
    },
    fnSearch(pageNum) {
      this.pageNo = this.$gfnCommonUtils.defaultIfEmpty(pageNum, "1") * 1;
      this.fnSrchAddrMemberList();
    },
    //주소록 명단 리스트 조회
    fnSrchAddrMemberList() {
      this.fnAddrInfoInit();

      this.addrInfo = {
        pageNo: this.pageNo,
        listSize: this.listSize,
        searchPhone: this.searchPhone,
        srchGroupSeq: this.srchGroupSeq,
        srchLevDepth: this.srchLevDepth,
      };

      addressApi.selectAddrPubMemberList(this.addrInfo).then((response) => {
        var result = response.data;
        if (result.success) {
          this.addrMemList = Object.assign([], result.data.addrMemList);
          //this.excelList = Object.assign([], result.data.excelList);
          this.totCnt = result.data.pageInfo.totCnt;
          this.offset = result.data.pageInfo.offset;
        } else {
          confirm.fnAlert("", "오류가 발생하였습니다.", "alert");
        }
      });
    },
    //주소록 그룹 추가
    fnSaveAddrGroup() {
      if(this.srchLevDepth == "0"){
        this.mineYn = 'Y'
      }
      if(this.mineYn != 'Y'){
        confirm.fnAlert("", "본인이 등록한 그룹만 복사할 수 있습니다.", "alert");
      } else if (this.mineYn == 'Y'){
        this.fnAddrInfoInit();
        if (!this.fnInputCheckReq(this.groupNm)) return false;

        if (this.srchLevDepth == "" || this.selGroupNm == "") {
          confirm.fnAlert("", "추가하려는 그룹의 상위 그룹을 아래 트리에서 선택해주세요.", "alert");
          return;
        }

        if (this.srchLevDepth == "1" && this.selGroupNm == "그룹 없음") {
          confirm.fnAlert("", "그룹 없음 그룹에는 그룹을 추가 할 수 없습니다.", "alert");
          return;
        }

        if (this.srchLevDepth == "2") {
          confirm.fnAlert("", "2단계 까지만 그룹을 추가하실 수 있습니다.", "alert");
          return false;
        }
        if (this.srchLevDepth == "0") {
          //전체 선택인 경우는 lev1 에 그룹명
          this.levDepth = "1";
          this.lev1 = this.groupNm;
          this.lev2 = "";
        } else {
          //1단계 선택인 경우는 lev1에 선택그룹명 lev2에 추가하는 그룹명
          this.levDepth = "2";
          this.lev1 = this.selGroupNm;
          this.lev2 = this.groupNm;
        }

        this.addrInfo = {
          levDepth: this.levDepth,
          lev1: this.lev1,
          lev2: this.lev2,
        };

        addressApi.registerAddrPubGrp(this.addrInfo).then((response) => {
          var result = response.data;
          if (result.success) {
            this.groupNm = "";
            confirm.fnAlert("", "새 그룹을 추가했습니다.", "alert");
            if (this.srchLevDepth == "0") {
              this.fnSelectAddressGrpListReset(0, "", "");
            } else {
              this.fnSelectAddressGrpListReset(this.addrGrpInfo.levDepth, this.addrGrpInfo.groupSeq, this.addrGrpInfo.parentGroupSeq);
            }
          } else {
            confirm.fnAlert("", "새 그룹을 추가중 오류가 발생하였습니다.", "alert");
          }
        });
      }
    },
    //유효성 체크
    fnInputCheckReq(groupNm) {
      const chkGroupNm = groupNm;
      let isGroupNm = true;
      //const regExp = /[`~!@#$%^&*|\\'\\";:\\/?]/gi; // 특수문자
      const regExp = /[`!*★“”‘’|\\'\\";:\\/?]/gi;
      if (chkGroupNm == "") {
        confirm.fnAlert("", "그룹명을 입력해 주세요.", "alert");
        return false;
      } else {
        if (regExp.test(chkGroupNm)) {
          confirm.fnAlert("", "특수문자(',\",*,!,/,★)는 사용이 안됩니다.", "alert");
          return false;
        }
        if (this.fnGetStringLength(chkGroupNm) > 30) {
          confirm.fnAlert("", "그룹명 입력 내용이 30byte를 초과했습니다.", "alert");
          return false;
        }
      }

      if (chkGroupNm == "전체") {
        confirm.fnAlert("", " 전체 그룹명은 사용할 수 없습니다.", "alert");
        return false;
      }
      if (chkGroupNm == "그룹 없음" || chkGroupNm == "그룹없음") {
        confirm.fnAlert("", " 그룹 없음 그룹명은 사용할 수 없습니다.", "alert");
        return false;
      }

      this.addrGrpList.forEach(function (tree) {
        if (tree.groupNm.toUpperCase() == chkGroupNm.toUpperCase()) {
          isGroupNm = false;
        }
      });
      this.addrGrpChildList.forEach(function (tree) {
        if (tree.groupNm.toUpperCase() == chkGroupNm.toUpperCase()) {
          isGroupNm = false;
        }
      });

      if (!isGroupNm) {
        confirm.fnAlert("", "중복된 그룹명이 있습니다.", "alert");
        return false;
      }
      return true;
    },
    fnModifyAddrGrpAct(moDiv, index) {
      if (moDiv == "P") {
        this.addrGrpList[index].selected = true;
      } else {
        this.addrGrpChildList[index].selected = true;
      }
    },
    fnAddrGrpModify(data) {
      this.fnAddrInfoInit();
      if (!this.fnInputCheckReq(data.moGroupNm)) return false;
      if (this.addrGrpInfo.levDepth == "1") {
        this.addrInfo = {
          groupSeq: this.addrGrpInfo.groupSeq,
          levDepth: this.addrGrpInfo.levDepth,
          lev1: data.moGroupNm,
          lev2: "",
          bfGropuNm: this.addrGrpInfo.groupNm,
        };
      } else {
        this.addrInfo = {
          groupSeq: this.addrGrpInfo.groupSeq,
          levDepth: this.addrGrpInfo.levDepth,
          lev1: this.addrGrpInfo.lev1,
          lev2: data.moGroupNm,
        };
      }
      addressApi.modifyAddrPubGrp(this.addrInfo).then((response) => {
        var result = response.data;
        if (result.success) {
          confirm.fnAlert("", "그룹명을 수정했습니다.", "alert");
          this.fnSelectAddressGrpListReset(this.addrGrpInfo.levDepth, this.addrGrpInfo.groupSeq, this.addrGrpInfo.parentGroupSeq);
        } else {
          confirm.fnAlert("", "그룹명 수정 중 오류가 발생하였습니다.", "alert");
        }
      });
    },
    fnDeleteAddrGrp() {
      this.deleteMsg = "";
      if (this.srchLevDepth == "1") {
        this.deleteMsg = "선택한 그룹을 삭제하면 하위 그룹도 모두 삭제됩니다.<br />그룹 내 주소를 모두 삭제하시겠어요?";
      } else {
        this.deleteMsg = "선택한 그룹을 삭제합니다.<br />그룹 내 주소를 모두 삭제하시겠어요?";
      }
      this.ModalOpen("caution");
    },
    fnDeleteGrpExec(deleteDiv) {
      let delComplteMsg = "";

      if (deleteDiv == "G") {
        delComplteMsg = "선택한 그룹을 삭제했습니다.<br />그룹 내 주소는 모두 ‘그룹 없음’으로 변경됩니다.";
      } else {
        delComplteMsg = "선택한 그룹과 번호를 모두 삭제했습니다.";
      }
      this.fnAddrInfoInit();
      this.addrInfo = {
        deleteDiv: deleteDiv,
        levDepth: this.srchLevDepth,
        groupSeq: this.srchGroupSeq,
      };
      addressApi.deleteAddressPubGrp(this.addrInfo).then((response) => {
        var result = response.data;
        if (result.success) {
          confirm.fnAlert("", delComplteMsg, "alert");
          this.srchGroupNm = "";
          this.grpSearch = false;
          this.fnSelectAddressGrpList();
          this.fnSelectSelGrpReset();
          this.fnAddrGrpClk("all");
          this.ModalClose();
        } else {
          confirm.fnAlert("", "그룹 삭제중 오류가 발생하였습니다.", "alert");
        }
      });
    },

    //메인화면에서 그룹 이동, 복사 버튼 선택 시
    fnAddrGrpExec(grpExecDiv) {
      this.fnAddrInfoInit();
      this.addrGrpSelList = [];
      var textStr = "";
      if (grpExecDiv == "C") {
        textStr = "복사";
      } else {
        textStr = "이동";
      }
      if (this.srchLevDepth == "0") {
        //전체 선택인 경우 이동,복사 불가
        confirm.fnAlert("", "전체 그룹은 " + textStr + "할 수 없습니다.", "alert");
        return false;
      }
      if (this.realAddrCnt == 0) {
        confirm.fnAlert("", "선택하신 그룹에 등록된 주소가 없습니다.", "alert");
        return false;
      }
      this.addrDiv = "G";
      this.grpExecDiv = grpExecDiv;
      this.selAddrInfo = "defaultGrp";
      this.addrInfo = {
        groupSeq: this.srchGroupSeq,
        addrDiv : 'G'
      };

      if(grpExecDiv != "C" && this.mineYn =="N"){
        confirm.fnAlert("", "주소록을 등록한 ID만 그룹 이동이 가능합니다.<br>등록한 아이디 : "+this.userId, "alert");
      } else {
        addressApi.selectAddressPubGrpSelList(this.addrInfo).then((response) => {
          var result = response.data;
          if (result.success) {
            this.addrGrpSelList = result.data.addrGrpSelList;
          } else {
            confirm.fnAlert("", "오류가 발생하였습니다.", "alert");
          }
        });
        this.ModalOpen("grpmove");
      }
    },

    //팝업 화면에서 이동 복사 시 주소록 그룹 목록 조회(그룹, 주소록 동일)
    async fnAddrGrpSelect(addrDiv){
      this.addrDiv = addrDiv
      this.addrInfo = {
        groupSeq: this.srchGroupSeq,
        addrDiv : this.addrDiv
      }
      await addressApi.selectAddressPubGrpSelList(this.addrInfo).then((response) => {
          var result = response.data;
          if (result.success) {
            this.addrGrpSelList = result.data.addrGrpSelList;
          } else {
            confirm.fnAlert("", "오류가 발생하였습니다.", "alert");
          }
        });
      this.selAddrInfo = "defaultGrp"
    },

    fnAddrGrpMove() {
      this.fnAddrInfoInit();
      let resulTxt = "";

      if (this.selAddrInfo == "" || this.selAddrInfo =="defaultGrp") {
        confirm.fnAlert("", "이동할 그룹을 선택해주세요.", "alert");
        return false;
      }
      if (this.selAddrInfo == "N") {
        this.addrInfo = {
          groupSeq: this.srchGroupSeq,
          levDepth: "1",
          selParentGroupSeq: "0",
          selGroupSeq: "0",
          grpExecDiv: this.grpExecDiv,
          addrDiv : this.addrDiv,
          selUserSeq : this.userSeq
        };
      } else {
        this.addrInfo = {
          groupSeq: this.srchGroupSeq,
          levDepth: this.selAddrInfo.levDepth,
          selParentGroupSeq: this.selAddrInfo.parentGroupSeq,
          selGroupSeq: this.selAddrInfo.groupSeq,
          grpExecDiv: this.grpExecDiv,
          addrDiv : this.addrDiv,
          selUserSeq : this.userSeq
        };
      }

      addressApi.addressPubGrpExec(this.addrInfo).then((response) => {
        var result = response.data;
        if (result.success) {
          resulTxt = result.data.addrGrpCnt;
          confirm.fnAlert("", resulTxt + "개의 주소를 이동했습니다.", "alert");
          if(this.addrDiv =="G"){
            if (this.selAddrInfo == "N") {
              //그룹 없음
              this.fnSelectAddressGrpListReset(1, 0, 0);
            } else {
              this.fnSelectAddressGrpListReset(this.selAddrInfo.levDepth, this.selAddrInfo.groupSeq, this.selAddrInfo.parentGroupSeq);
            }
          } else {
          this.fnSelectAddressGrpListReset(0, 0, 0);
          }
          this.ModalClose();
        } else {
          confirm.fnAlert("", result.message, "alert");
        }
      });
    },
    fnAddrGrpCopy() {
      this.fnAddrInfoInit();
      let resulTxt = "";

      if (this.selAddrInfo == "" || this.selAddrInfo == "defaultGrp") {
        confirm.fnAlert("", "복사할 그룹을 선택해주세요.", "alert");
        return false;
      }

      if (this.selAddrInfo == "N") {
        this.addrInfo = {
          groupSeq: this.srchGroupSeq,
          levDepth: "1",
          selParentGroupSeq: "0",
          selGroupSeq: "0",
          grpExecDiv: this.grpExecDiv,
          addrDiv : this.addrDiv,
          selUserSeq : this.userSeq
        };
      } else {
        this.addrInfo = {
          groupSeq: this.srchGroupSeq,
          levDepth: this.selAddrInfo.levDepth,
          selParentGroupSeq: this.selAddrInfo.parentGroupSeq,
          selGroupSeq: this.selAddrInfo.groupSeq,
          grpExecDiv: this.grpExecDiv,
          addrDiv : this.addrDiv,
          selUserSeq : this.userSeq
        };
      }

      addressApi.addressPubGrpExec(this.addrInfo).then((response) => {
        var result = response.data;
        if (result.success) {
          resulTxt = result.data.addrGrpCnt;
          confirm.fnAlert("", resulTxt + "개의 주소를 복사했습니다.", "alert");
          if(this.addrDiv =="G"){
            if (this.selAddrInfo == "N") {
              //그룹 없음
              this.fnSelectAddressGrpListReset(1, 0, 0);
            } else {
              this.fnSelectAddressGrpListReset(this.selAddrInfo.levDepth, this.selAddrInfo.groupSeq, this.selAddrInfo.parentGroupSeq);
            }
          } else {
          this.fnSelectAddressGrpListReset(0, 0, 0);
          }
          this.ModalClose();
        } else {
          confirm.fnAlert("", result.message, "alert");
        }
      });
    },
    fnGetStringLength(str) {
      var strLength = 0;
      for (var i = 0; i < str.length; i++) {
        var code = str.charCodeAt(i);
        var ch = str.substr(i, 1).toUpperCase();
        code = parseInt(code);
        if ((ch < "0" || ch > "9") && (ch < "A" || ch > "Z") && (code > 255 || code < 0)) {
          strLength = strLength + 2;
        } else {
          strLength = strLength + 1;
        }
      }
      return strLength;
    },
    //주소록 구성원 추가
    fnSaveAddrMember() {
      this.fnAddrInfoInit();
      if (this.fnGetStringLength(this.name) > 20) {
        confirm.fnAlert("", "이름 입력 내용이 20byte를 초과했습니다.", "alert");
        return false;
      }

      if (this.phone == "") {
        confirm.fnAlert("", "휴대폰 번호를 입력해 주세요.", "alert");
        return false;
      } else {
        let regExp = /^01([0|1|6|7|8|9])-?([0-9]{3,4})-?([0-9]{4})$/;
        if (!regExp.test(this.phone)) {
          confirm.fnAlert("", "휴대폰 번호를 확인해주세요.", "alert");
          return false;
        }
      }

      if (this.memo != "") {
        if (this.fnGetStringLength(this.memo) > 50) {
          confirm.fnAlert("", "비고 입력 내용이 50byte를 초과했습니다.", "alert");
          return false;
        }
      }
      if (this.selRegAddrInfo == "N") {
        confirm.fnAlert("", "그룹을 선택해주세요", "alert");
        return false;
        // this.addrInfo = {
        //   levDepth: "1",
        //   parentGroupSeq: "0",
        //   groupSeq: "0",
        //   phone: this.phone.replace(/-/gi, ""),
        //   name: this.name,
        //   memo: this.memo,
        // };
      } else {
        this.addrInfo = {
          levDepth: this.selRegAddrInfo.levDepth,
          parentGroupSeq: this.selRegAddrInfo.parentGroupSeq,
          groupSeq: this.selRegAddrInfo.groupSeq,
          phone: this.phone.replace(/-/gi, ""),
          name: this.name,
          memo: this.memo,
        };
      }

      // eventBus.$on("callbackEventBus", this.fnAddrRegExec);
      // confirm.fnConfirm("주소 등록", "주소를 등록 하시겠습니까?", "confirm");

      addressApi.registerAddrPubMember(this.addrInfo).then((response) => {
        var result = response.data;
        if (result.success) {
          confirm.fnAlert("", "주소를 등록했습니다.", "alert");
          this.phone = "";
          this.name = "";
          this.memo = "";
          if (this.selRegAddrInfo == "N") {
            //그룹 없음
            this.fnSelectAddressGrpListReset(1, 0, 0);
          } else {
            this.fnSelectAddressGrpListReset(this.selRegAddrInfo.levDepth, this.selRegAddrInfo.groupSeq, this.selRegAddrInfo.parentGroupSeq);
          }
        } else {
          confirm.fnAlert("", result.message, "alert");
        }
      });
    },
    fnUpdateAddrMember(memInfo) {
      this.modiMemeList = [];
      let modiMemArray = [];
      modiMemArray.push(Object.assign({}, memInfo));
      this.modiMemeList = modiMemArray.map((e) => {
        return {
          ...e,
          phone: e.phone.replace(/-/gi, ""),
        };
      });
      this.addrInfo = {
        userSeq: "",
      };
      addressApi.selectModiAddrPubGrpSelList(this.addrInfo).then((response) => {
        var result = response.data;
        if (result.success) {
          this.regAddrGrpSelList = result.data.modiAddrGrpSelList;
        } else {
          confirm.fnAlert("", "오류가 발생하였습니다.", "alert");
        }
      });
      this.ModalOpen("edit_address");
    },
    fnModifyAddrList() {
      this.modiMemeList = [];
      this.fnAddrInfoInit();
      let checkDiv = 0;
      let modiMemArray = [];
      this.addrMemList.forEach(function (memInfo) {
        if (memInfo.tgtCheck) {
          modiMemArray.push(Object.assign({}, memInfo));
          checkDiv++;
        }
      });
      this.modiMemeList = modiMemArray.map((e) => {
        return {
          ...e,
          phone: e.phone.replace(/-/gi, ""),
        };
      });
      if (checkDiv == 0) {
        confirm.fnAlert("", "수정이 필요한 해당 전화번호를 선택하여 주세요.", "alert");
        return false;
      }
      this.addrInfo = {
        userSeq: "",
      };
      addressApi.selectModiAddrPubGrpSelList(this.addrInfo).then((response) => {
        var result = response.data;
        if (result.success) {
          this.regAddrGrpSelList = result.data.modiAddrGrpSelList;
        } else {
          confirm.fnAlert("", "오류가 발생하였습니다.", "alert");
        }
      });
      this.ModalOpen("edit_address");
    },
    fnPhoneDash(e, index) {
      const pattern_notNumber = /[^-0-9]/g; // 숫자가 아닌것 체크
      // 숫자 외 입력시
      if (pattern_notNumber.test(e.target.value)) {
        confirm.fnAlert("", "휴대폰번호는 숫자만 입력 가능 합니다.", "alert");
        this.modiMemeList[index].phone = e.target.value.replace(/[^0-9]/g, "");
      }
    },
    fnAddrMemModify() {
      this.fnAddrInfoInit();
      // let nameNull = true;
      let nameStrChk = true;
      let phoneNull = true;
      let phoneForm = true;
      let memoStrChk = true;
      let nameStrLength = 0;
      let memoStrLength = 0;

      this.modiMemeList.forEach(function (memInfo) {

        for(var i = 0; i < memInfo.name.length; i++){
          var nameCode = memInfo.name.charCodeAt(i);
          var nameCh = memInfo.name.substr(i, 1).toUpperCase();
          nameCode = parseInt(nameCode);
          if ((nameCh < "0" || nameCh > "9") && (nameCh < "A" || nameCh > "Z") && (nameCode > 255 || nameCode < 0)) {
            nameStrLength = nameStrLength + 2;
          } else {
            nameStrLength = nameStrLength + 1;
          }
        }
        if(nameStrLength > 20) {
          nameStrChk = false;
        }
        if (memInfo.phone == "") {
          phoneNull = false;
        } else {
          let regExp = /^01([0|1|6|7|8|9])-?([0-9]{3,4})-?([0-9]{4})$/;
          if (!regExp.test(memInfo.phone)) {
            phoneForm = false;
          } else {
            memInfo.phone = memInfo.phone.replace(/-/gi, "");
          }
        }
        if (memInfo.memo != "") {
          for(var j = 0; j < memInfo.memo.length; j++){
            var code = memInfo.memo.charCodeAt(j);
            var ch = memInfo.memo.substr(j, 1).toUpperCase();
            code = parseInt(code);
            if ((ch < "0" || ch > "9") && (ch < "A" || ch > "Z") && (code > 255 || code < 0)) {
              memoStrLength = memoStrLength + 2;
            } else {
              memoStrLength = memoStrLength + 1;
            }
          }
          if(memoStrLength > 50) {
            memoStrChk = false;
          }
        }
      });

      // if (!nameNull) {
      //   confirm.fnAlert("", "이름을 입력해 주세요.", "alert");
      //   return false;
      // }
      if (!nameStrChk) {
        confirm.fnAlert("", "이름 입력 내용이 20byte를 초과했습니다.", "alert");
        return false;
      }
      if (!phoneNull) {
        confirm.fnAlert("", "휴대폰 번호를 입력해 주세요.", "alert");
        return false;
      }
      if (!phoneForm) {
        confirm.fnAlert("", "휴대폰 번호를 확인해주세요.", "alert");
        return false;
      }
      if (!memoStrChk) {
        confirm.fnAlert("", "비고 입력 내용이 50byte를 초과했습니다.", "alert");
        return false;
      }
      eventBus.$on("callbackEventBus", this.fnAddrModifyExec);
      confirm.fnConfirm("", "수정한 내용을 저장하시겠어요?", "save");
    },
    fnAddrModifyExec() {
      this.addrInfo = {
        userSeq: "",
        addressDtoArray: this.modiMemeList,
      };
      addressApi.modifyAddrPubMember(this.addrInfo).then((response) => {
        var result = response.data;
        if (result.success) {
          if (this.nAppointActive) {
            //그룹 없음
            this.fnSelectAddressGrpListReset(1, 0, 0);
          } else {
            this.fnSelectAddressGrpListReset(this.addrGrpInfo.levDepth, this.addrGrpInfo.groupSeq, this.addrGrpInfo.parentGroupSeq);
          }
          this.$refs.updatePaging.fnAllDecrease();
          this.ModalClose();
        } else {
          confirm.fnAlert("", result.message, "alert");
        }
      });
    },
    fnDeleteAddrMember() {
      this.fnAddrInfoInit();
      let checkDiv = 0;
      this.addrMemList.forEach(function (addrInfo) {
        if (addrInfo.tgtCheck) checkDiv++;
      });
      if (checkDiv == 0) {
        confirm.fnAlert("", "주소를 선택해 주세요.", "alert");
        return false;
      }

      eventBus.$on("callbackEventBus", this.fnDeleteAddrMemberExec);
      confirm.fnConfirm("", "선택한 주소를 삭제하면 복구할 수 없습니다.<br />그래도 삭제하시겠어요?", "del");
    },
    fnDeleteAddrMemberExec() {
      let selectAddrMemList = [];
      this.addrMemList.forEach(function (data) {
        if (data.tgtCheck) {
          selectAddrMemList.push(data.seqNo);
        }
      });
      this.addrInfo = {
        addrMemList: selectAddrMemList,
      };
      addressApi.deleteAddressPubMem(this.addrInfo).then((response) => {
        var result = response.data;
        if (result.success) {
          confirm.fnAlert("", "선택하신 주소가 삭제 되었습니다.", "alert");
          if (this.nAppointActive) {
            //그룹 없음
            this.fnSelectAddressGrpListReset(1, 0, 0);
          } else {
            this.fnSelectAddressGrpListReset(this.addrGrpInfo.levDepth, this.addrGrpInfo.groupSeq, this.addrGrpInfo.parentGroupSeq);
          }
        } else {
          confirm.fnAlert("", "주소 삭제중 오류가 발생하였습니다.", "alert");
        }
      });
    },
    fnAddrMemberExec(memExecDiv) {
      this.fnAddrInfoInit();
      this.addrGrpSelList = [];
      // var textStr = "";
      if (memExecDiv == "C") {
        // textStr = "복사";
      } else {
        // textStr = "이동";
      }

      let checkDiv = 0;
      this.addrMemList.forEach(function (addrInfo) {
        if (addrInfo.tgtCheck) checkDiv++;
      });
      if (checkDiv == 0) {
        // confirm.fnAlert("주소 " + textStr, textStr + " 할 주소를 선택해 주세요.", "alert");
        confirm.fnAlert("", "주소를 선택해 주세요.", "alert");
        return false;
      }

      this.memExecDiv = memExecDiv;
      this.selAddrInfo = "defaultGrp";

      this.addrInfo = {
        groupSeq: this.srchGroupSeq,

        addrDiv : 'G'
      };
      this.addrDiv = "G"
      addressApi.selectAddressPubGrpSelList(this.addrInfo).then((response) => {
        var result = response.data;
        if (result.success) {
          this.addrGrpSelList = result.data.addrGrpSelList;
        } else {
          confirm.fnAlert("", "오류가 발생하였습니다.", "alert");
        }
      });
      this.ModalOpen("addrmove");
    },
    fnAddrMemMove() {
      if (this.selAddrInfo == "" || this.selAddrInfo =="defaultGrp") {
        confirm.fnAlert("", "이동할 그룹을 선택해주세요.", "alert");
        return false;
      }
      this.fnAddrInfoInit();

      let selectAddrMemList = [];
      this.addrMemList.forEach(function (data) {
        if (data.tgtCheck) {
          selectAddrMemList.push(data.seqNo);
        }
      });
      if (this.selAddrInfo == "N") {
        this.addrInfo = {
          addrMemList: selectAddrMemList,
          levDepth: "1",
          selParentGroupSeq: "0",
          selGroupSeq: "0",
          memExecDiv: this.memExecDiv,
          addrDiv : this.addrDiv
        };
      } else {
        this.addrInfo = {
          addrMemList: selectAddrMemList,
          levDepth: this.selAddrInfo.levDepth,
          selParentGroupSeq: this.selAddrInfo.parentGroupSeq,
          selGroupSeq: this.selAddrInfo.groupSeq,
          memExecDiv: this.memExecDiv,
          addrDiv : this.addrDiv
        };
      }

      addressApi.addressPubMemExec(this.addrInfo).then((response) => {
        var result = response.data;
        if (result.success) {
          confirm.fnAlert("주소 이동", "총 " + selectAddrMemList.length + "개의 주소가 이동 되었습니다.", "alert");
          if(this.addrDiv =="G"){
            if (this.selAddrInfo == "N") {
              //그룹 없음
              this.fnSelectAddressGrpListReset(1, 0, 0);
            } else {
              this.fnSelectAddressGrpListReset(this.selAddrInfo.levDepth, this.selAddrInfo.groupSeq, this.selAddrInfo.parentGroupSeq);
            }
          } else {
          this.fnSelectAddressGrpListReset(0, 0, 0);
          }
          this.ModalClose();
        } else {
          confirm.fnAlert("", result.message, "alert");
        }
      });
    },
    fnAddrMemCopy() {
      if (this.selAddrInfo == "" || this.selAddrInfo =="defaultGrp") {
        confirm.fnAlert("", "복사할 그룹을 선택해주세요.", "alert");
        return false;
      }
      this.fnAddrInfoInit();
      let selectAddrMemList = [];
      this.addrMemList.forEach(function (data) {
        if (data.tgtCheck) {
          selectAddrMemList.push(data.seqNo);
        }
      });
      if (this.selAddrInfo == "N") {
        this.addrInfo = {
          addrMemList: selectAddrMemList,
          levDepth: "1",
          selParentGroupSeq: "0",
          selGroupSeq: "0",
          memExecDiv: this.memExecDiv,
          addrDiv : this.addrDiv
        };
      } else {
        this.addrInfo = {
          addrMemList: selectAddrMemList,
          levDepth: this.selAddrInfo.levDepth,
          selParentGroupSeq: this.selAddrInfo.parentGroupSeq,
          selGroupSeq: this.selAddrInfo.groupSeq,
          memExecDiv: this.memExecDiv,
          addrDiv : this.addrDiv
        };
      }

      addressApi.addressPubMemExec(this.addrInfo).then((response) => {
        var result = response.data;
        if (result.success) {
          confirm.fnAlert("", "총 " + selectAddrMemList.length + "개의 주소가 복사 되었습니다.", "alert");
          if(this.addrDiv =="G"){
            if (this.selAddrInfo == "N") {
              //그룹 없음
              this.fnSelectAddressGrpListReset(1, 0, 0);
            } else {
              this.fnSelectAddressGrpListReset(this.selAddrInfo.levDepth, this.selAddrInfo.groupSeq, this.selAddrInfo.parentGroupSeq);
            }
          } else {
          this.fnSelectAddressGrpListReset(0, 0, 0);
          }
          this.ModalClose();
        } else {
          confirm.fnAlert("", result.message, "alert");
        }
      });
    },
    //주소 그룹 리셋 조회
    fnSelectAddressGrpListReset(levDepth, groupSeq, parentGroupSeq) {
      let addrGrpInfoHash = {};
      this.addrGrpInfo = {};
      this.listAllChecked = false;
      this.searchPhone = "";
      this.parentGroupSeq = "";
      this.srchGroupSeq = "";
      this.srchLevDepth = "";
      this.selGroupNm = "";
      this.selAddrCnt = 0;
      this.realAddrCnt = 0;
      this.srchGroupNm = "";
      this.grpSearch = false;
      this.srchLevDepth = levDepth;
      this.srchGroupSeq = groupSeq;
      this.addrInfo = {
        userSeq: "",
      };
      addressApi.selectAddressPubGrpList(this.addrInfo).then((response) => {
        var result = response.data;
        if (result.success) {
          this.addrGrpList = result.data.addrGrpList.map((e) => {
            return {
              ...e,
              selected: false,
              moGroupNm: e.groupNm,
            };
          });
          this.addrGrpChildList = result.data.addrGrpChildList.map((e) => {
            return {
              ...e,
              selected: false,
              moGroupNm: e.groupNm,
            };
          });
          this.totalCnt = result.data.totalCnt;
          this.nAppointCnt = result.data.nAppointCnt;

          if (levDepth == 1 && groupSeq == 0 && parentGroupSeq == 0) {
            this.nAppointActive = true;
            this.active = false;
            this.parentGroupSeq = "0";
            this.srchGroupSeq = "0";
            this.srchLevDepth = "1";
            this.selAddrCnt = this.nAppointCnt;
            this.realAddrCnt = this.nAppointCnt;
            this.selGroupNm = "그룹 없음";
          } else {
            if (levDepth == 1) {
              this.addrGrpList.forEach(function (tree) {
                if (tree.groupSeq == groupSeq) {
                  tree.isActive = "Y";
                  tree.classActive = "Y";
                  addrGrpInfoHash = Object.assign({}, tree);
                }
              });
              this.addrGrpInfo = Object.assign({}, addrGrpInfoHash);
              this.active = false;
              this.nAppointActive = false;
              this.parentGroupSeq = this.addrGrpInfo.parentGroupSeq + "";
              this.srchGroupSeq = this.addrGrpInfo.groupSeq + "";
              this.srchLevDepth = this.addrGrpInfo.levDepth;
              this.selGroupNm = this.addrGrpInfo.groupNm;
              this.selAddrCnt = this.addrGrpInfo.addrCnt;
              this.realAddrCnt = this.addrGrpInfo.realAddrCnt;
            } else if (levDepth == 2) {
              this.addrGrpList.forEach(function (tree) {
                if (tree.groupSeq == parentGroupSeq) {
                  tree.isActive = "Y";
                }
              });
              this.addrGrpChildList.forEach(function (tree) {
                if (tree.groupSeq == groupSeq) {
                  tree.isActive = "Y";
                  tree.classActive = "Y";
                  addrGrpInfoHash = Object.assign({}, tree);
                }
              });
              this.addrGrpInfo = Object.assign({}, addrGrpInfoHash);
              this.active = false;
              this.nAppointActive = false;
              this.parentGroupSeq = this.addrGrpInfo.parentGroupSeq + "";
              this.srchGroupSeq = this.addrGrpInfo.groupSeq + "";
              this.srchLevDepth = this.addrGrpInfo.levDepth;
              this.selGroupNm = this.addrGrpInfo.groupNm;
              this.selAddrCnt = this.addrGrpInfo.addrCnt;
              this.realAddrCnt = this.addrGrpInfo.realAddrCnt;
            } else {
              this.active = true;
              this.nAppointActive = false;
              this.srchGroupSeq = "";
              this.srchLevDepth = "0";
              this.selGroupNm = "전체";
            }
          }
          this.$refs.updatePaging.fnAllDecrease();
        } else {
          confirm.fnAlert("", "오류가 발생하였습니다.", "alert");
        }
      });
      this.fnSelectSelGrpReset();
    },
    fnSelectSelGrpReset() {
      this.addrInfo = {
        addrDiv : 'G'
      }
      addressApi.selectAddressPubGrpSelList(this.addrInfo).then((response) => {
        var result = response.data;
        if (result.success) {
          this.regAddrGrpMstSelList = result.data.addrGrpSelList;
        } else {
          confirm.fnAlert("", "오류가 발생하였습니다.", "alert");
        }
      });
    },
    async excelDownload() {
      this.addrInfo = {
        pageNo: this.pageNo,
        listSize: this.listSize,
        searchPhone: this.searchPhone,
        srchGroupSeq: this.srchGroupSeq,
        srchLevDepth: this.srchLevDepth,
      };

      await addressApi.selectAddrPubMemberExcelList(this.addrInfo).then((response) => {
        var result = response.data;
        if (result.success) {
          this.excelList = Object.assign([], result.data.excelList);
          let options = {
            header: this.header,
            headProps: "header",
          };
          this.excelExport(this.excelList, options);
        } else {
          confirm.fnAlert("", "오류가 발생하였습니다.", "alert");
        }
      });
    },
    excelExport(data, options) {
      // data row별 header에 바인딩 될 매핑값 설정
      let headProps = [];
      if (Array.isArray(options.headProps)) {
        headProps = options.headProps;
      } else if (options.headProps === "header") {
        for (let h of this.header) {
          headProps.push(h.key);
        }
      } else {
        headProps = Object.keys(data[0]);
      }
      this.visibleHeadProps = headProps;
      this.instance = document.createElement("table");
      // Header 세팅
      let header = [];
      if (!Array.isArray(this.header[0])) {
        header.push(this.header);
      } else {
        header = this.header;
      }

      let thead = document.createElement("thead");
      for (let row of header) {
        let tr = document.createElement("tr");
        for (let h of row) {
          let rowspan = h.rowspan || "1";
          let colspan = h.colspan || "1";
          let th = document.createElement("th");
          th.setAttribute("rowspan", rowspan);
          th.setAttribute("colspan", colspan);
          th.setAttribute("align", "center");
          th.innerText = h.name;
          tr.appendChild(th);
        }
        thead.appendChild(tr);
      }
      this.instance.appendChild(thead);
      // Body 세팅
      let tbody = document.createElement("tbody");
      for (let row of data) {
        let tr = document.createElement("tr");
        for (let cellkey of this.visibleHeadProps) {
          let td = document.createElement("td");
          td.innerText = row[cellkey];
          tr.appendChild(td);
        }
        tbody.appendChild(tr);
      }
      this.instance.appendChild(tbody);
      // instance에 만들어진 table을 엑셀파일로 저장
      let config = { raw: true, type: "string" };
      let ws = XLSX.utils.table_to_sheet(this.instance, config);
      let wb = XLSX.utils.book_new();

      XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
      XLSX.writeFile(wb, "주소록.xlsx");
    },
    ModalOpen(target) {
      var dimmed = document.getElementsByClassName("dimmed");
      var wrap = document.getElementsByClassName("popup-wrap");
      var obj = document.getElementsByClassName(target);
      obj[0].classList.add("open");
      dimmed[0].style.display = "block";
      wrap[0].style.display = "block";
      obj[0].style.display = "block";
      this.ModalHeight();
      if (target == "excelpop") {
        this.$refs.addrUpload.getSelectAddressCateGrpList();
      }
    },
    ModalClose() {
      this.selAddrInfo = "defaultGrp";
      var dimmed = document.getElementsByClassName("dimmed");
      var wrap = document.getElementsByClassName("popup-wrap");
      var obj = wrap[0].childElementCount;
      dimmed[0].style.display = "none";
      wrap[0].style.display = "none";
      for (var i = 0; i < obj; i++) {
        var target = document.getElementsByClassName("popup");
        target[i].style.display = "none";
        target[i].classList.remove("open");
      }
    },
    ModalHeight() {
      //팝업 heght가 device height보다 클때
      var winH = window.innerHeight,
        obj = document.querySelector(".popup.open"),
        objH = obj.clientHeight;
      if (objH >= winH) {
        obj.classList.add("over");
      }
    },
    fnModalCloseUpload() {
      var dimmed = document.getElementsByClassName("dimmed");
      var wrap = document.getElementsByClassName("popup-wrap");
      var obj = wrap[0].childElementCount;
      dimmed[0].style.display = "none";
      wrap[0].style.display = "none";
      for (var i = 0; i < obj; i++) {
        var target = document.getElementsByClassName("popup");
        target[i].style.display = "none";
        target[i].classList.remove("open");
      }
      this.fnSelectAddressGrpListReset(0, "", "");
    },
    fnCantReg() {
      confirm.fnAlert("", "로그인 후 이용할 수 있습니다.", "alert");
      return;
    },
    fnAddressUploadMethod() {
      this.ModalOpen("address_upload");
    },
    /*handleScroll(e) {
      const chooseListThWrap = e.target.parentNode.children[0];
      chooseListThWrap.scrollLeft = e.target.scrollLeft;
    },
    handleThScroll(e) {
      const chooseListWrap = e.target.parentNode.children[1];
      chooseListWrap.scrollLeft = e.target.scrollLeft;
    },*/
  },
};
</script>

<style scoped>
.popup-btn-wrap {
  width: 500px;
  margin: auto;
  padding: 100px 0;
}
.popup-btn-wrap button {
  width: 100%;
  margin-bottom: 10px;
  height: 50px;
  border-radius: 5px;
  box-shadow: none;
  border: 1px solid #000;
}
.popup-btn-wrap button:hover {
  background: #000;
  color: #fff;
}
</style>
