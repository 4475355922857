<template>
  <div class="popup tit sendpop">
    <div class="pop-head">
      <h3 class="pop-tit">전송 확인</h3>
    </div>
    <div class="pop-cont">
      <!-- <div class="sendpop_top">
        <ul>
          <li><p>알림톡 대신 문자메시지로 전환 전송되었거나 메시지 전송이 실패한 경우 아래 요금과 다르게 청구될 수 있습니다.</p></li>
          <li><p>080수신거부번호에 등록하여 수신을 거부한 고객에게는 메시지가 전송되지 않습니다.</p></li>
          <li><p>중복된 수신번호에는 메시지가 한 번만 보내집니다.</p></li>
          <li><p>광고 메시지는 오후 9시부터 오전 8시까지 전송할 수 없습니다.</p></li>
          <li><p>요금 차감은 지난 달 남은 금액(이월 금액), 이번 달 월정액 순으로 진행됩니다. 이후에는 전송한 만큼 추가로 요금이 청구됩니다.</p></li>
          <li><p>정상 전송된 메시지만 요금을 청구합니다.</p></li>
        </ul>
      </div> -->
      <div class="list_cont">
        <div class="fixed-table-cover">
          <div class="fixed-table-container" style="width: 938px">
            <div class="fixed-table-header-bg"></div>
            <!-- [전송 설정이 반복예약전송 & 잔액이 있는 경우] 와 [전송 설정이 반복예약전송 & 잔액이 없는 경우] (내부에 툴팁이 있는 경우 over class를 추가)  -->
            <div class="fixed-table-wrapper" :class="{ over: sendTimeData.rsvtType === 'P' }">
              <table class="fixed-table" style="table-layout: fixed">
                <thead>
                  <!-- 전송 설정이 일반전송 & 잔액이 있는 경우 -->
                  <template v-if="sendTimeData.rsvtType !== 'P' && Number(useChargeData.remainingAmt) - preSendingAmt > 0">
                    <tr>
                      <th class="td_phone" style="width: 10%">
                        <div class="th-text">전송 유형</div>
                      </th>
                      <th class="td_name" style="width: 10%">
                        <div class="th-text">메시지 종류</div>
                      </th>
                      <th class="td_1" style="width: 10%">
                        <div class="th-text">1건 당 요금</div>
                      </th>
                      <th class="td_2" style="width: 10%">
                        <div class="th-text">총 전송 건수</div>
                      </th>
                      <th class="td_3" style="width: 10%">
                        <div class="th-text">정상 전송 건수</div>
                      </th>
                      <th class="td_4" style="width: 10%">
                        <div class="th-text">오류 발생 건수</div>
                      </th>
                      <th class="td_5" style="width: 13.3%">
                        <div class="th-text line130">현재 사용 가능 금액 (A)</div>
                      </th>
                      <th class="td_6" style="width: 13.3%">
                        <div class="th-text">예상 사용 금액 (B)</div>
                      </th>
                      <th class="td_7" style="width: 13.4%">
                        <div class="th-text line130">예상 잔액<br />(C=A-B)</div>
                      </th>
                    </tr>
                  </template>

                  <!-- 전송 설정이 일반전송 & 잔액이 없는 경우 -->
                  <template v-if="sendTimeData.rsvtType !== 'P' && Number(useChargeData.remainingAmt) - preSendingAmt <= 0">
                    <tr>
                      <th class="td_phone" style="width: 12%">
                        <div class="th-text">전송 유형</div>
                      </th>
                      <th class="td_name" style="width: 12%">
                        <div class="th-text">메시지 종류</div>
                      </th>
                      <th class="td_1" style="width: 12%">
                        <div class="th-text">1건 당 요금</div>
                      </th>
                      <th class="td_2" style="width: 12%">
                        <div class="th-text">총 전송 건수</div>
                      </th>
                      <th class="td_3" style="width: 12%">
                        <div class="th-text">정상 전송 건수</div>
                      </th>
                      <th class="td_4" style="width: 12%">
                        <div class="th-text">오류 발생 건수</div>
                      </th>
                      <th class="td_5" style="width: 14%">
                        <div class="th-text">현재 사용 가능 금액</div>
                      </th>
                      <th class="td_6" style="width: 14%">
                        <div v-if="subsInfo.joinDiv == '02'" class="th-text">예상 청구 금액</div>
                        <div v-else class="th-text">예상 종량 청구 금액</div>
                      </th>
                    </tr>
                  </template>

                  <!-- 전송 설정이 반복예약전송 & 잔액이 있는 경우 -->
                  <template v-if="sendTimeData.rsvtType === 'P' && Number(useChargeData.remainingAmt) - prePrice > 0">
                    <tr>
                      <th class="td_phone" style="width: 10%">
                        <div class="th-text">전송 유형</div>
                      </th>
                      <th class="td_name" style="width: 10%">
                        <div class="th-text">메시지 종류</div>
                      </th>
                      <th class="td_1" style="width: 10%">
                        <div class="th-text">1건 당 요금</div>
                      </th>
                      <th class="td_2" style="width: 10%">
                        <div class="th-text">총 전송 건수</div>
                      </th>
                      <th class="td_3" style="width: 10%">
                        <div class="th-text">정상 전송 건수</div>
                      </th>
                      <th class="td_4" style="width: 10%">
                        <div class="th-text">오류 발생 건수</div>
                      </th>
                      <th class="td_5" style="width: 13.3%">
                        <div class="th-text line130">현재 사용 가능 금액 (A)</div>
                      </th>
                      <th class="td_6" style="width: 13.3%">
                        <div class="th-text line130">매월 예상 사용 금액 (반복 예약,B)</div>
                      </th>
                      <th class="td_7" style="width: 13.4%">
                        <div class="th-text line130">이번 달 예상 잔액<br />(C=A-B)</div>
                      </th>
                    </tr>
                  </template>

                  <!-- 전송 설정이 반복예약전송 & 잔액이 없는 경우 -->
                  <template v-if="sendTimeData.rsvtType === 'P' && Number(useChargeData.remainingAmt) - prePrice <= 0">
                    <tr>
                      <th class="td_phone" style="width: 12%">
                        <div class="th-text">전송 유형</div>
                      </th>
                      <th class="td_name" style="width: 12%">
                        <div class="th-text">메시지 종류</div>
                      </th>
                      <th class="td_1" style="width: 12%">
                        <div class="th-text">1건 당 요금</div>
                      </th>
                      <th class="td_2" style="width: 12%">
                        <div class="th-text">총 전송 건수</div>
                      </th>
                      <th class="td_3" style="width: 12%">
                        <div class="th-text">정상 전송 건수</div>
                      </th>
                      <th class="td_4" style="width: 12%">
                        <div class="th-text">오류 발생 건수</div>
                      </th>
                      <th class="td_5" style="width: 14%">
                        <div class="th-text">현재 사용 가능 금액 (A)</div>
                      </th>
                      <th class="td_6" style="width: 14%">
                        <div v-if="subsInfo.joinDiv == '02'" class="th-text">예상 청구 금액</div>
                        <div v-else class="th-text">예상 종량 청구 금액</div>
                      </th>
                    </tr>
                  </template>
                </thead>

                <tbody>
                  <!-- 전송 설정이 일반전송 & 잔액이 있는 경우 -->
                  <template v-if="sendTimeData.rsvtType !== 'P' && Number(useChargeData.remainingAmt) - preSendingAmt > 0">
                    <tr>
                      <td class="td_phone">{{ rsvtType }}</td>
                      <td class="td_name">{{ alimSendData ? "알림톡" : reqProduct }}</td>
                      <td class="td_1">{{ unitPrice | formatPrice }}원</td>
                      <td class="td_2">{{ totalCount | formatPrice }}건</td>
                      <td class="td_3">{{ successAddr | formatPrice }}건</td>
                      <td class="td_4">
                        <p class="red" @click="excelDownload(errorAddr)" :style="errorAddr > 0 ? 'cursor:pointer' : ''">{{ errorAddr | formatPrice }}건</p>
                      </td>
                      <td class="td_5">{{ Number(useChargeData.remainingAmt) | formatPrice }}원</td>
                      <td class="td_6">
                        <p class="pink">{{ preSendingAmt | formatPrice }}원</p>
                      </td>
                      <td class="td_7">{{ (Number(useChargeData.remainingAmt) - preSendingAmt) | formatPrice }}원</td>
                    </tr>
                  </template>

                  <!-- 전송 설정이 일반전송 & 잔액이 없는 경우 -->
                  <template v-if="sendTimeData.rsvtType !== 'P' && Number(useChargeData.remainingAmt) - preSendingAmt <= 0">
                    <tr>
                      <td class="td_phone">{{ rsvtType }}</td>
                      <td class="td_name">{{ alimSendData ? "알림톡" : reqProduct }}</td>
                      <td class="td_1">{{ unitPrice | formatPrice }}원</td>
                      <td class="td_2">{{ totalCount | formatPrice }}건</td>
                      <td class="td_3">{{ successAddr | formatPrice }}건</td>
                      <td class="td_4">
                        <p class="red" @click="excelDownload(errorAddr)" :style="errorAddr > 0 ? 'cursor:pointer' : ''">{{ errorAddr | formatPrice }}건</p>
                      </td>
                      <td class="td_5">{{ Number(useChargeData.remainingAmt) | formatPrice }}원<br /><div v-if="subsInfo.joinDiv !== '02'">(종량과금가능)</div></td>
                      <td class="td_6">
                        <p class="pink">{{ (preSendingAmt - Number(useChargeData.remainingAmt)) | formatPrice }}원</p>
                      </td>
                    </tr>
                  </template>

                  <!-- 전송 설정이 반복예약전송 & 잔액이 있는 경우 -->
                  <template v-if="sendTimeData.rsvtType === 'P' && Number(useChargeData.remainingAmt) - prePrice > 0">
                    <tr>
                      <td class="td_phone">{{ rsvtType }}</td>
                      <td class="td_name">{{ alimSendData ? "알림톡" : reqProduct }}</td>
                      <td class="td_1">{{ unitPrice | formatPrice }}원</td>
                      <td class="td_2">{{ totalCount | formatPrice }}건</td>
                      <td class="td_3">{{ successAddr | formatPrice }}건</td>
                      <td class="td_4">
                        <p class="red" @click="excelDownload(errorAddr)" :style="errorAddr > 0 ? 'cursor:pointer' : ''">{{ errorAddr | formatPrice }}건</p>
                      </td>
                      <td class="td_5">{{ Number(useChargeData.remainingAmt) | formatPrice }}원</td>
                      <td class="td_6">
                        <div class="tt_btn" @click="listTooltipOpen($event)">
                          <p class="pink">{{ prePrice | formatPrice }}원</p>
                          <div v-if="subsInfo.joinDiv == '02'" class="tooltip t_tooltip">
                            <p>반복 예약을 설정하면 매월 사용 금액이 발생합니다.</p>
                            <p>예약 사용 금액을 확인하여 선불 충전하여</p>
                            <p>정상 발송되도록 해주세요.</p>
                            <button class="t_tooltip_close" @click="listTooltipClose"><img src="@/assets/images/icon_close.svg" alt="" /></button>
                          </div>
                          <div v-else class="tooltip t_tooltip">
                            <p>반복 예약을 설정하면 매월 사용 금액이 발생합니다.</p>
                            <p>예약 사용 금액을 확인하여 추가 요금(종량 요금)이</p>
                            <p>청구되지 않도록 해주세요.</p>
                            <button class="t_tooltip_close" @click="listTooltipClose"><img src="@/assets/images/icon_close.svg" alt="" /></button>
                          </div>
                        </div>
                      </td>
                      <td class="td_7">{{ (Number(useChargeData.remainingAmt) - prePrice) | formatPrice }}원</td>
                    </tr>
                  </template>

                  <!-- 전송 설정이 반복예약전송 & 잔액이 없는 경우 -->
                  <template v-if="sendTimeData.rsvtType === 'P' && Number(useChargeData.remainingAmt) - prePrice <= 0">
                    <tr>
                      <td class="td_phone">{{ rsvtType }}</td>
                      <td class="td_name">{{ alimSendData ? "알림톡" : reqProduct }}</td>
                      <td class="td_1">{{ unitPrice | formatPrice }}원</td>
                      <td class="td_2">{{ totalCount | formatPrice }}건</td>
                      <td class="td_3">{{ successAddr | formatPrice }}건</td>
                      <td class="td_4">
                        <p class="red" @click="excelDownload(errorAddr)" :style="errorAddr > 0 ? 'cursor:pointer' : ''">{{ errorAddr | formatPrice }}건</p>
                      </td>
                      <td class="td_5">{{ Number(useChargeData.remainingAmt) | formatPrice }}원<br /><div v-if="subsInfo.joinDiv !== '02'">(종량과금가능)</div></td>
                      <td class="td_6">
                        <div class="tt_btn" @click="listTooltipOpen($event)">
                          <p class="pink">{{ (prePrice - Number(useChargeData.remainingAmt)) | formatPrice }}원</p>
                          <div v-if="subsInfo.joinDiv == '02'" class="tooltip t_tooltip last">
                            <p>반복 예약을 설정하면 추가 금액이 발생 합니다.</p>
                            <p>요금을 충전하여 정상 발송되도록 해주세요.</p>
                            <button class="t_tooltip_close" @click="listTooltipClose"><img src="@/assets/images/icon_close.svg" alt="" /></button>
                          </div>
                          <div v-else class="tooltip t_tooltip last">
                            <p>반복 예약을 설정하면 추가 금액이 발생(종량 요금) 합니다.</p>
                            <p>요금제를 변경하여 좀 더 저렴하게 이용 가능합니다.</p>
                            <button class="t_tooltip_close" @click="listTooltipClose"><img src="@/assets/images/icon_close.svg" alt="" /></button>
                          </div>
                        </div>
                      </td>
                    </tr>
                  </template>
                </tbody>
              </table>
            </div>
          </div>
        </div>

        <template v-if="errorMemFilter.length > 0">
          <div class="list_top" :class="{ mar_up: sendTimeData.rsvtType === 'P' }">
            <h4 class="list_tit">오류 확인</h4>
          </div>
          <p>※ 오류건수는 최대 200개까지만 보여집니다.</p>

          <div class="fixed-table-cover error">
            <div class="fixed-table-container" :style="autoMsg.length > 5 ? `width: ${938 + autoMsg.length * 50}px` : 'width: 938px'">
              <div class="fixed-table-header-bg"></div>
              <div class="fixed-table-wrapper">
                <table class="fixed-table nobg" style="table-layout: fixed">
                  <thead>
                    <tr>
                      <th class="td_num" style="width: 5%">
                        <div class="th-text">No</div>
                      </th>
                      <th class="td_phone" id="_td_phone" :style="`width:${80 / (autoMsg.length + 1)}%`">
                        <div class="th-text">휴대폰 번호</div>
                      </th>
                      <th :class="index === 0 ? 'td_name' : 'td_' + index" v-for="(msg, index) in autoMsg" :key="index" :style="`width:${80 / (autoMsg.length + 1)}%`">
                        <div class="th-text">{{ msg | textLengthCut(5) }}</div>
                      </th>
                      <th class="td_state" style="width: 15%">
                        <div class="th-text">상태</div>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr class="on" v-for="(addrMem, index) in errorMemFilter.slice(0, 200)" :key="index">
                      <td class="td_num">{{ index + 1 }}</td>
                      <td class="td_phone"><input type="text" class="input" v-model="addrMem.phone" @input="fnPhoneDash($event, addrMem.index)" /></td>
                      <td :class="index === 0 ? 'td_name' : 'td_' + index" v-for="(msg, index) in autoMsg" :key="index">
                        <input type="text" class="input" v-model="addrMem[msg]" :placeholder="$gfnCommonUtils.textLengthCut(msg, 5)" />
                      </td>
                      <td class="td_state">
                        <p class="red" v-if="!addrMem.validator" v-html="addrMem.status"></p>
                        <p v-else>{{ addrMem.status }}</p>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>

          <div class="list_info">
            <!-- <button class="btn white small" @click="removeAddress">삭제</button> -->
            <div class="right_wrap">
              <div class="total">
                총 <span class="pink">{{ errorAddr | formatComma }} </span>건
              </div>
              <button class="btn white small" @click="errorReCheck()">오류검사</button>
            </div>
          </div>
        </template>

        <div class="list_top">
          <h4 class="list_tit">전송 그룹명 설정</h4>
          <input type="text" class="input" placeholder="전송 그룹명을 입력하세요." v-model="sendNm" @input="fnSendNmCutStr" />
        </div>
        <p>※전송 그룹명 입력시 최근 전송그룹에 추가됩니다. 다음에 메시지를 전송할 때 이번 전송그룹을 곧바로 수신번호로 설정할 수 있습니다.</p>
      </div>
    </div>
    <div class="pop-btn2">
      <button type="button" class="btn large_pink" @click="fnPriceCheck" v-if="prePriceNoti">전송</button>
      <button type="button" class="btn large_pink" @click="fnSendMessage" v-if="!prePriceNoti">전송</button>
      <button type="button" class="btn large_white" @click="ModalClose">취소</button>
    </div>
  </div>
</template>
<script>
import confirm from "@/modules/commonUtil/service/confirm";
import { eventBus } from "@/modules/commonUtil/service/eventBus";
import sendManageApi from "../service/sendManageApi";
import * as XLSX from "xlsx";

export default {
  name: "sendConfirm",
  props: {
    rsvId: String,
    ad: Boolean,
    msg: String,
    title: String,
    recvInfoList: Array,
    fileList: Array,
    callbackNumber: String,
    reqProduct: String,
    adBottom: String,
    authNumber: String,
    sendTimeData: Object,
    alimSendData: Object,
    tmplt: Object,
    rejectPhoneList: Array,
    adByte: Number,
    autoMsg: Array,
  },
  computed: {
    errorMemFilter() {
      return this.sendMemberList.filter((e) => e.validator === false);
    },
    successAddr: {
      get() {
        return this.sendMemberList.filter((e) => e.validator === true).length;
      },
      set() {
        this.sendMemberList.filter((e) => e.validator === true).length;
      },
    },
    errorAddr: {
      get() {
        return this.sendMemberList.filter((e) => e.validator === false).length;
      },
      set() {
        this.sendMemberList.filter((e) => e.validator === false).length;
      },
    },
    rsvtType() {
      let type = "";
      if (this.sendTimeData.rsvtType === "N") {
        type = "일반전송";
      } else if (this.sendTimeData.rsvtType === "R") {
        type = "예약전송";
      } else if (this.sendTimeData.rsvtType === "P") {
        type = "반복예약전송";
      }
      return type;
    },
    totalCount() {
      return this.recvInfoList.length;
    },
    prePrice() {
      let prePriceData = 0;
      if (this.sendTimeData.rsvtType === "P") {
        if (this.sendTimeData.rsvCfg1 === "D") {
          prePriceData = Math.round((this.preSendingAmt * 30) / this.sendTimeData.mada ?? 30);
        } else if (this.sendTimeData.rsvCfg1 === "W") {
          prePriceData = Math.round((this.preSendingAmt * this.sendTimeData.rsvCfg2dayWeek.length * 4) / this.sendTimeData.mada ?? 1);
        } else {
          prePriceData = this.preSendingAmt;
        }
      } else {
        prePriceData = this.preSendingAmt;
      }
      return prePriceData;
    },
  },
  watch: {
    recvInfoList: {
      handler: function (newrecvInfoList) {
        this.sendMemberList = newrecvInfoList;
      },
      deep: true,
    },
  },
  data() {
    return {
      preSendingAmt: 0, // 예상 차감금액
      useAvailableAmt: 0, // 현재 잔액
      unitPrice: 0, // 건당 단가
      prePriceNoti: false, //예상 가격이 사용가능요금보다 클 경우 ( Y : 알림창오픈 / N : 통과)
      sendMemberList: [],
      hashNum: new Map(), // 연락처 중복 처리 변수 값 (errorCheck 할 경우 function 마다 초기화 진행)
      allChecked: false,
      sendNm: "",
      paramsData: {}, // 예상 차감금액으로 전달된 파라미터 값 저장
      useOverCharge: false, // 전송건수 예상금액 > 요금제 * 2 클 경우 판단 변수
      useChargeData: 0,

      // 엑셀 다운로드
      visibleHeadProps: [],
      instance: undefined,
      excelList: [],
      subsInfo: {}
    };
  },
  methods: {
    // 예상 금액 조회
    async fnGetPreAmount(params) {
      this.paramsData = {
        ...params,
      };
      await sendManageApi.getPreAmount(this.paramsData).then((response) => {
        if (response.data.success) {
          this.preSendingAmt = response.data.data.preAmount.preAmountInfo.preSendingAmt ? response.data.data.preAmount.preAmountInfo.preSendingAmt : 0;
          if (response.data.data.preAmount.preAmountInfo.channelDiv === "SMS") {
            this.unitPrice = response.data.data.preAmount.unitPrice.smsUnitPrice;
          } else if (response.data.data.preAmount.preAmountInfo.channelDiv === "LMS") {
            this.unitPrice = response.data.data.preAmount.unitPrice.lmsUnitPrice;
          } else if (response.data.data.preAmount.preAmountInfo.channelDiv === "MMS") {
            this.unitPrice = response.data.data.preAmount.unitPrice.mmsUnitPrice;
          } else {
            this.unitPrice = response.data.data.preAmount.unitPrice.altUnitPrice;
          }
          // 예상 금액 조회시 사용 가능 금액보다 예상 금액이 클 경우 확인창 노출되도록 변수 변경
          this.useChargeData = response.data.data.chargeInfo;
          if (this.preSendingAmt > Number(this.useChargeData.remainingAmt) && !this.$cookies.isKey("prePriceNoti")) {
            this.prePriceNoti = true;
          } else {
            this.prePriceNoti = false;
          }

          this.subsInfo = response.data.data.subsInfo;

          // 전송시 요금제 * 2 보다 전송 요금이 클 경우
          const sendLimitAmt = this.useChargeData.sendLimitAmt ? this.useChargeData.sendLimitAmt : 0;
          if (Number(this.preSendingAmt) > Number(sendLimitAmt)) {
            this.useOverCharge = true;
          }
        } else {
          // confirm.fnAlert("예상차감금액", "예상차감금액 가져오기에 실패하였습니다", "alert");
          confirm.fnAlert("", "예상차감금액 가져오기에 실패하였습니다", "alert");
          this.ModalClose();
        }
      });
      await this.ModalOpen("sendpop");
    },
    errorReCheck() {
      this.$store.commit("startSpinner");
      // 중복 함수 초기화
      this.hashNum = new Map();
      // 연락처 중복 처리 변수 값 (errorCheck 할 경우 function 마다 초기화 진행)
      setTimeout(() => {
        this.sendMemberList = this.sendMemberList.map((e) => {
          return {
            // 유효성 체크 항목 추가
            ...e,
            status: this.errorCheck(e["phone"], { ...e }),
            validator: this.validator,
            selected: false,
          };
        });
        /* .sort(function (a, b) {
            if (a.validator < b.validator) return -1;
            if (a.validator > b.validator) return 1;
            if (a.validator === b.validator) return 0;
          }); */
        const preAmountParams = {
          channelDiv: this.alimSendData ? "ALIMTALK" : this.reqProduct,
          preSendingCnt: this.successAddr, // 예상 전송 건수
          repSendingYN: this.alimSendData ? (this.alimSendData.repSendingYN == "true" ? "Y" : "N") : "N",
          repSendingChnnel: this.reqProduct, // 대체 전송 채널(SMS, LMS, MMS, ATLK)
        };
        this.fnGetPreAmount(preAmountParams); // 예상 금액 재설정
        this.$store.commit("endSpinner");
      }, 100);
    },
    // 휴대폰 번호 유효성 체크 항목
    errorCheck(phoneNumber, data) {
      const patten_phone = /^(01[016789]{1}|070)-?[0-9]{3,4}-?[0-9]{4}$/; //핸드폰 번호, 하이픈 상관 X
      const pattern_eng = /[a-zA-Z]/; // 문자
      const pattern_spc = /[~!@#$%^&*()_+|<>?:{}]/; // 특수문자
      const pattern_kor = /[ㄱ-ㅎ|ㅏ-ㅣ|가-힣]/; // 한글체크
      let errorText = "";

      const rejectPhone = this.rejectPhoneList.some((e) => {
        return this.$gfnCommonUtils.hpNumberRemoveDash(e.rcvblckno) === this.$gfnCommonUtils.hpNumberRemoveDash(phoneNumber);
      });

      if (rejectPhone) {
        errorText = "080 수신거부번호";
        this.validator = false;
      } else if (phoneNumber == "" || phoneNumber == undefined) {
        errorText = "번호없음";
        this.validator = false;
        return errorText;
      } else if (pattern_eng.test(phoneNumber) || pattern_spc.test(phoneNumber) || pattern_kor.test(phoneNumber)) {
        errorText = "번호오류";
        this.validator = false;
        return errorText;
      } else if (!patten_phone.test(phoneNumber)) {
        errorText = "번호오류";
        this.validator = false;
        return errorText;
      } else if (this.hashNum.has(phoneNumber.replace(/-/g, ""))) {
        errorText = "번호중복";
        this.validator = false;
        return errorText;
      } else {
        // 자동 변경 정보 체크
        let acText = [];
        this.autoMsg.forEach((element) => {
          if (!data[element]) {
            acText.push(element);
          }
        });

        if (!this.$gfnCommonUtils.isEmpty(acText)) {
          errorText = `자동변경정보 누락 (${acText.join(", ")})`;
          this.validator = false;
          return errorText;
        }

        // 자동변경정보를 통한 byte 계산
        // const pattern = /#\{([^#{}]*)\}/g;
        let autoMsgByte = 0;
        let autoMsgText = "";

        //알림톡 byte 체크
        if (this.alimSendData) {
          autoMsgText = this.tmplt.tmpltSbst.replace(/((#{).*?(\}))/g, (match) => {
            const matches = /#\{([^#{}]*)\}/g.exec(match);
            return data[matches[1]];
          });
          autoMsgByte = this.$gfnCommonUtils.getTextLen(autoMsgText);
          if (autoMsgByte > 1000) {
            errorText = `자동 변경 정보 삽입으로<br/>1000자 초과(${autoMsgByte}자)`;
            this.validator = false;
            return errorText;
          }
        }

        autoMsgText = this.msg.replace(/((#{).*?(\}))/g, (match) => {
          const matches = /#\{([^#{}]*)\}/g.exec(match);
          return data[matches[1]];
        });

        if (this.ad) {
          autoMsgByte = this.$gfnCommonUtils.getByte(autoMsgText) + this.adByte;
        } else {
          autoMsgByte = this.$gfnCommonUtils.getByte(autoMsgText);
        }

        // 문자타입이 SMS이고 90Byte 보다 클 경우 에러 처리
        if (this.reqProduct === "SMS" && autoMsgByte > 90) {
          errorText = `자동 변경 정보 삽입으로<br/>90Byte 초과(${autoMsgByte}Byte)`;
          this.validator = false;
          return errorText;
        }

        // 문자타입이 LMS , MMS 이고 2000byte 보다 클 경우 에러 처리
        if ((this.reqProduct === "LMS" || this.reqProduct === "MMS") && autoMsgByte > 2000) {
          errorText = `자동 변경 정보 삽입으로<br/>2000Byte 초과(${autoMsgByte}Byte)`;
          this.validator = false;
          return errorText;
        }

        errorText = "정상";
        this.validator = true;
        this.hashNum.set(phoneNumber.replace(/-/g, ""), "phone");
      }

      return errorText;
    },
    ModalClose() {
      var dimmed = document.getElementsByClassName("dimmed");
      var wrap = document.getElementsByClassName("popup-wrap");
      var obj = wrap[0].childElementCount;
      dimmed[0].style.display = "none";
      wrap[0].style.display = "none";
      for (var i = 0; i < obj; i++) {
        var target = document.getElementsByClassName("popup");
        target[i].style.display = "none";
        target[i].classList.remove("open");
      }
    },
    //체크박스 전체 선택 기능
    checkedAll(checked) {
      this.allChecked = checked;
      for (let i in this.errorMemFilter) {
        this.errorMemFilter[i].selected = this.allChecked;
      }
    },
    //체크박스 단일 체크
    selected() {
      for (let i in this.sendMemberList) {
        if (!this.sendMemberList[i].selected) {
          this.allChecked = false;
          return;
        } else {
          this.allChecked = true;
          return;
        }
      }
    },
    // 선택된 주소록 삭제
    removeAddress() {
      this.sendMemberList = this.sendMemberList.filter((item) => {
        return item.selected === false; // 체크 안된 리스트
      });
      //this.$forceUpdate();
    },
    // 예상 가격 부족 알림
    fnPriceCheck() {
      eventBus.$on("callbackEventBus", this.fnSendMessage);
      confirm.fnConfirm("", "남은 금액이 부족하여 지금부터 보내는 메시지는 건 당<br/>요금(종량 요금)으로 청구됩니다.<br/>그래도 보내시겠어요?", "send");
    },

    // 메세지 전송
    fnSendMessage() {
      this.$store.commit("startSpinner");

      const sendMembers = this.sendMemberList.filter((e) => e.validator === true).length;
      if (sendMembers === 0) {
        setTimeout(() => {
          confirm.fnAlert("", "정상 전송 건수가 없습니다.", "alert");
        }, 100);
        this.$store.commit("endSpinner");
        return;
      }

      if (this.useOverCharge) {
        setTimeout(() => {
          confirm.fnAlert("", "가입한 요금제만큼 추가 요금이 발생하여 <br/>더 이상 메시지를 보낼 수 없습니다. ", "alert");
        }, 100);
        this.$store.commit("endSpinner");
        return;
      }

      const fd = new FormData();
      fd.append("rsvId", this.rsvId);
      // 알림톡 전송일 경우
      if (this.alimSendData) {
        fd.append("sndrprofKey", this.alimSendData.sndrprofKey); // 채널ID 센더키
        fd.append("tmpltKey", this.alimSendData.tmpltKey); // 템플릿 키
        fd.append("tmpltName", this.alimSendData.tmpltName); // 템플릿 명
        fd.append("chnlId", this.alimSendData.chnlId); // 채널ID
        fd.append("repSendingYN", this.alimSendData.repSendingYN == "true" ? "Y" : "N");
        fd.append("msg", this.tmplt.tmpltSbst);
        fd.append("reqProduct", "KKO_NOTI"); // 알림톡 유형 설정
        if (this.sendNm !== "") {
          fd.append("sendNm", this.sendNm);
        }
        //대체문자 사용시
        if (this.alimSendData.repSendingYN == "true") {
          fd.append("fbProduct", this.reqProduct); // 문자 유형 (문자 : SMS, LMS, MMS ...)
          fd.append("fbTitle", this.title);
          fd.append("ad", this.ad);
          if (this.ad) {
            // 광고 메시지의 경우
            fd.append("fbMsg", this.msg + "\n" + this.adBottom + "\n" + this.authNumber); // 내용
            fd.append("adBottom", this.adBottom);
            fd.append("authNumber", this.authNumber);
          } else {
            // 광고가 아닐 경우
            fd.append("fbMsg", this.msg); // 내용
            fd.append("adBottom", "");
            fd.append("authNumber", "");
          }
          fd.append("content", this.msg);
          fd.append("callback", this.callbackNumber.replace(/[^0-9]/g, "")); // 발신번호
        }
      }
      //메시지 전송일 경우
      else {
        if (this.sendNm !== "") {
          fd.append("sendNm", this.sendNm);
        }
        fd.append("reqProduct", this.reqProduct); // 문자 유형 (문자 : SMS, LMS, MMS ...)
        fd.append("title", this.title);
        fd.append("ad", this.ad);
        if (this.ad) {
          fd.append("msg", this.msg + "\n" + this.adBottom + "\n" + this.authNumber); // 내용
          fd.append("adBottom", this.adBottom);
          fd.append("authNumber", this.authNumber);
        } else {
          fd.append("msg", this.msg); // 내용
          fd.append("adBottom", "");
          fd.append("authNumber", "");
        }
        fd.append("content", this.msg);
        fd.append("callback", this.callbackNumber.replace(/[^0-9]/g, "")); // 발신번호
      }

      // 공통 Data
      fd.append("rsvtType", this.sendTimeData.rsvtType);
      fd.append("rptYn", this.sendTimeData.rsvtType === "P" ? "Y" : "N"); // 반복예약전송일 경우 Y 로 전달 추후 삭제 *********************************
      fd.append("rsvYn", this.sendTimeData.rsvtType === "R" ? "Y" : "N"); // 예약전송일 경우 Y 로 전달 추후 삭제 *********************************

      if (this.sendTimeData.rsvtType === "R") {
        /*  예약 전송  */
        fd.append("rsvTm", this.sendTimeData.reqDt); // 즉시전송일시 전송 날짜 , 예약전송일 경우 날짜 전달
        fd.append("rsvCfg3", this.sendTimeData.rsvCfg3); // 예약 전송시간 시분  예) 0130
      } else if (this.sendTimeData.rsvtType === "P") {
        /*  반복 예약 전송  */
        fd.append("rsvCfg1", this.sendTimeData.rsvCfg1); // d, w, m , 매일 매주 매달
        fd.append("rsvCfg2", this.sendTimeData.rsvCfg2); // 예) 일, 수, 토 체크의 경우 -> 0,3,6 로 전달  (일:0 월:1 ~ 토:6) 체크된 값 콤마로 구분하여 전달
        fd.append("rsvCfg3", this.sendTimeData.rsvCfg3); // 예약 전송시간 시분  예) 0130
        fd.append("rsvStart", this.sendTimeData.rsvStart); // 반복예약전송 일 경우 사용
        fd.append("rsvEnd", this.sendTimeData.rsvEnd); // 반복예약전송 일 경우 사용
        fd.append("monDiv", this.sendTimeData.monDiv);
        fd.append("mada", this.sendTimeData.mada);
        fd.append("tomada", this.sendTimeData.tomada);
        fd.append("rsvCfg2dayWeek", this.sendTimeData.rsvCfg2dayWeek); // 매주 : 0,3,6  (일:0 월:1 ~ 토:6) 체크된 값 콤마로 구분하여 전달
        fd.append("dayOfTheWeek", this.sendTimeData.dayOfTheWeek); // 숫자 요일 일:0, 월:1, 화:2
        fd.append("dayOfTheWeekStr", this.sendTimeData.dayOfTheWeekStr); // 한글 요일 => 월,화,수
        fd.append("dayOfTheWeekNum", this.sendTimeData.dayOfTheWeekNum); // 몇번째 주인지 확인
      } else {
        fd.append("reqDt", this.$gfnCommonUtils.getCurretDate()); // 즉시전송일시 전송 날짜 , 예약전송일 경우 날짜 전달
      }
      //정상적인 주소록만 전송하도록 필터링 진행
      fd.append(
        "recvInfoList",
        JSON.stringify(
          this.sendMemberList
            .filter((e) => e.validator === true)
            .map((e) => {
              let var5JsonObject = {};
              this.autoMsg.forEach((key) => {
                var5JsonObject[key] = e[key];
              });
              return {
                ...e,
                phone: e.phone.replace(/[^0-9]/g, ""),
                var5: JSON.stringify(var5JsonObject), // 자동 변수 정보 JSON 타입으로 전달
              };
            })
        )
      );

      fd.append(
        "fileName",
        this.fileList
          .map((file) => {
            return file.name;
          })
          .join(",")
      );

      let legacyFiles = [];

      for (let i = 0; i < this.fileList.length; i++) {
        if (!this.alimSendData) {
          // 문자 전송
          if (this.fileList[i].fileName) {
            legacyFiles.push(this.fileList[i].fileName);
          } else {
            fd.append("files", this.fileList[i]);
          }
        } else {
          if (this.fileList[i].fileName) {
            legacyFiles.push(this.fileList[i].fileName);
          } else {
            fd.append("files", this.fileList[i]);
          }
        }
      }

      if (legacyFiles) {
        fd.append("legacyFiles", legacyFiles.join(","));
      }
      /* for (var pair of fd.entries()) {
        console.log(pair[0] + " : " + pair[1]);
      } */

      sendManageApi
        .modifyRsvMessage(fd)
        .then((response) => {
          if (response.data.success) {
            this.ModalClose();
            this.sendNm = "";
            eventBus.$on("callbackEventBus", this.fnDone);
            // confirm.fnConfirm("수정 완료", response.data.message, "cbAlert");
            confirm.fnConfirm("", response.data.message, "cbAlert");
            this.$emit("fnSuccess", "success");
          } else {
            // confirm.fnAlert("수정 실패", response.data.message, "alert");
            confirm.fnAlert("", response.data.message, "alert");
          }
          this.$store.commit("endSpinner");
        })
        .catch((error) => {
          // confirm.fnAlert("수정 실패", error, "alert");
          confirm.fnAlert("", error, "alert");
          this.$store.commit("endSpinner");
        });
    },
    fnDone() {
      this.$router.push({ path: "/sendManage/rsvSendMessage" });
    },
    fnPhoneDash(e, index) {
      this.sendMemberList[index].phone = this.$gfnCommonUtils.hpNumberAddDash(this.$gfnCommonUtils.cutStr(e.target.value.replace(/[^-0-9]/g, ""), 13));
    },
    excelDownload(count) {
      if (count === 0 || count === "0") {
        return;
      }

      let options = { header: this.header, headProps: "header" };
      const data = this.sendMemberList
        .filter((e) => e.validator === false)
        .map((e) => {
          return {
            ...e,
            index: e.index + 1,
          };
        });
      // data row별 header에 바인딩 될 매핑값 설정
      let headProps = [];
      if (Array.isArray(options.headProps)) {
        headProps = options.headProps;
      } else if (options.headProps === "header") {
        for (let h of this.header) {
          headProps.push(h.key);
        }
      } else {
        headProps = Object.keys(data[0]);
      }
      this.visibleHeadProps = headProps;
      this.instance = document.createElement("table");
      // Header 세팅
      let header = [];
      if (!Array.isArray(this.header[0])) {
        header.push(this.header);
      } else {
        header = this.header;
      }

      let thead = document.createElement("thead");
      for (let row of header) {
        let tr = document.createElement("tr");
        for (let h of row) {
          let rowspan = h.rowspan || "1";
          let colspan = h.colspan || "1";
          let th = document.createElement("th");
          th.setAttribute("rowspan", rowspan);
          th.setAttribute("colspan", colspan);
          th.setAttribute("align", "center");
          th.innerText = h.name;
          tr.appendChild(th);
        }
        thead.appendChild(tr);
      }
      this.instance.appendChild(thead);
      // Body 세팅
      let tbody = document.createElement("tbody");
      for (let row of data) {
        let tr = document.createElement("tr");
        for (let cellkey of this.visibleHeadProps) {
          let td = document.createElement("td");
          td.innerText = row[cellkey];
          tr.appendChild(td);
        }
        tbody.appendChild(tr);
      }
      this.instance.appendChild(tbody);
      // instance에 만들어진 table을 엑셀파일로 저장
      let config = { raw: true, type: "string" };
      let ws = XLSX.utils.table_to_sheet(this.instance, config);
      let wb = XLSX.utils.book_new();

      XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
      XLSX.writeFile(wb, "오류내역.xlsx");
    },
    //모달창
    ModalOpen(target) {
      var dimmed = document.getElementsByClassName("dimmed");
      var wrap = document.getElementsByClassName("popup-wrap");
      var obj = document.getElementsByClassName(target);
      obj[0].classList.add("open");
      dimmed[0].style.display = "block";
      wrap[0].style.display = "block";
      obj[0].style.display = "block";
    },
    fnSendNmCutStr(e) {
      this.sendNm = this.$gfnCommonUtils.cutStr(e.target.value, 20);
      e.target.value = this.sendNm;
    },
    /*handleScroll(e) {
      const chooseListThWrap = e.target.parentNode.children[0];
      chooseListThWrap.scrollLeft = e.target.scrollLeft;
    },
    handleThScroll(e) {
      const chooseListWrap = e.target.parentNode.children[1];
      chooseListWrap.scrollLeft = e.target.scrollLeft;
    },*/
    listTooltipOpen(e) {
      if (e.target.classList.contains("pink")) {
        const nextTooltip = e.target.nextSibling;
        nextTooltip.style.display = "block";
      }
    },
    listTooltipClose(e) {
      if (e.target.parentNode.classList.contains("t_tooltip")) {
        e.target.parentNode.style.display = "none";
      }
    },
  },
};
</script>
