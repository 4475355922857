import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import VueCookies from "vue-cookies";
import { coreUiMixin } from "@/common/vue-mixins";
import common from "@/common/common";
import VueApexCharts from "vue-apexcharts";
import Toast from "vue-toastification";
import AOS from "aos";
import "aos/dist/aos.css";
import "vue-toastification/dist/index.css";
import "./common/filters.js";
// 퍼블리싱 CSS
import "@/assets/css/common.css";
import "@/assets/css/contents.css";
import "@/assets/css/layout.css";
import "@/assets/css/styles.css";
import "@/assets/css/error.css";
// swiper 전역 설정
import "swiper/dist/css/swiper.css";
// google Analitics
import VueGtag from 'vue-gtag'
import VueGtm from 'vue-gtm'


// 기본설정
Vue.config.productionTip = false;

Vue.use(VueCookies);
// 30 day after, expire
Vue.$cookies.config("30d");
Vue.use(common);
Vue.$cookies.config("1d");
Vue.config.productionTip = false;
Vue.use(AOS);
Vue.use(VueApexCharts);
// eslint-disable-next-line vue/multi-word-component-names
Vue.component("apexcharts", VueApexCharts);

Vue.use(Toast, {
  transition: "Vue-Toastification__fade",
  maxToasts: 20,
  newestOnTop: true,
  position: "bottom-right",
  timeout: 3000,
  closeOnClick: true,
  pauseOnFocusLoss: true,
  pauseOnHover: true,
  draggable: true,
  draggablePercent: 0.5,
  showCloseButtonOnHover: false,
  hideProgressBar: true,
  closeButton: "button",
  icon: false,
  rtl: false,
  toastClassName: "toast",
});

Vue.use(VueGtag, {
  config: {  id: 'G-L14631ZJVF'  }
});

Vue.use(VueGtm, {
  id: 'GTM-5DLDZDD',
  enabled: true,
  debug: true,
  loadScript: true
});

Vue.mixin(coreUiMixin);

new Vue({
  router,
  store,
  render: (h) => h(App),
  mounted() {
    AOS.init();
  },
}).$mount("#app");
