<template>
  <!--	s: 	contents-->
  <div class="contents">
    <MobileGnb />
    <div class="tabcont_wrap without_tab inquiry_wrap">
      <h1 class="title with_desc">비회원 문의하기</h1>
      <!-- 0624 title 변경 후 추가 -->
      <div class="tabcontTop">
        <!-- 0624 tabcontTop 위치 이동 -->
        <ul>
          <li>
            U+기업메시징 고객센터 운영 시간에 최대한 빠르게 확인하여 답변 드리겠습니다.<br />
            <span class="tabcontTop_extra_desc">운영 시간 : 평일 오전 9시~오후 6시, 점심시간 낮 12시~오후 1시 제외</span>
          </li>
        </ul>
      </div>
      <div class="tabcont on">
        <!-- 문의유형 -->
        <!-- 0624 기존 title 삭제 -->
        <span class="required required_desc">필수 입력</span>
        <!-- 0624 필수입력 위치 수정 -->
        <div class="box_wrap">
          <div class="box_top">
            <h3 class="tit required">문의유형</h3>
            <div class="top_right jfs">
              <div class="select_wrap">
                <select class="select" v-model="categoryM">
                  <option value="" selected>선택</option>
                  <option value="가입">가입</option>
                  <option value="요금/청구">요금/청구</option>
                  <option value="회원정보">회원정보</option>
                  <option value="기술/장애">기술/장애</option>
                  <option value="기타">기타</option>
                </select>
              </div>
              <div v-if="categoryM == '가입'" class="select_wrap cate2_wrap" style="display: block">
                <select class="select cate2" v-model="categoryS">
                  <option value="" selected>선택</option>
                  <option value="견적요청">견적요청</option>
                  <option value="서류접수문의">서류접수문의</option>
                </select>
              </div>
              <div v-if="categoryM == '요금/청구'" class="select_wrap cate2_wrap" style="display: block">
                <select class="select cate2" v-model="categoryS">
                  <option value="" selected>선택</option>
                  <option value="요금제/단가">요금제/단가</option>
                  <option value="청구/납부">청구/납부</option>
                  <option value="청구서/영수증">청구서/영수증</option>
                  <option value="환불">환불</option>
                </select>
              </div>
              <div v-if="categoryM == '회원정보'" class="select_wrap cate2_wrap" style="display: block">
                <select class="select cate2" v-model="categoryS">
                  <option value="" selected>선택</option>
                  <option value="담당자변경">담당자변경</option>
                  <option value="비밀번호변경/초기화">비밀번호변경/초기화</option>
                  <option value="요금제/단가변경">요금제/단가변경</option>
                  <option value="명의(사업자정보)변경">명의(사업자정보)변경</option>
                  <option value="전송한도변경">전송한도변경</option>
                  <option value="일시정지/정지해제">일시정지/정지해제</option>
                  <option value="해지">해지</option>
                </select>
              </div>
              <div v-if="categoryM == '기술/장애'" class="select_wrap cate2_wrap" style="display: block">
                <select class="select cate2" v-model="categoryS">
                  <option value="" selected>선택</option>
                  <option value="전송실패/지연">전송실패/지연</option>
                  <!-- <option value="웹/메신저오류">웹/메신저오류</option> -->
                  <option value="접속오류">접속오류</option>
                  <option value="미수신문의">미수신문의</option>
                </select>
              </div>
            </div>
          </div>
        </div>
        <!-- 이름 -->
        <div class="box_wrap">
          <div class="box_top">
            <h3 class="tit required">이름</h3>
            <div class="top_right">
              <div class="input_wrap">
                <input type="text" class="input" v-model="userName" placeholder="이름을 입력하세요." />
              </div>
            </div>
          </div>
        </div>
        <!-- 휴대폰번호 -->
        <div class="box_wrap">
          <div class="box_top">
            <h3 class="tit required">휴대폰번호</h3>
            <div class="top_right">
              <div class="input_wrap">
                <input type="text" class="input" v-model="phone" placeholder="휴대폰번호를 입력하세요." />
              </div>
            </div>
          </div>
        </div>
        <!-- 이메일 -->
        <div class="box_wrap email_wrap">
          <div class="box_top">
            <h3 class="tit required">
              이메일 주소
              <!-- div class="btn_question tt_btn">
                <div class="tooltip l_tooltip">입력하신 이메일 주소로 답변을 보내 드립니다.</div>
              </div -->
            </h3>
            <div class="top_right jfs addtooltip">
              <span class="tooltip_explosion">※ 입력하신 이메일 주소로 답변을 보내 드립니다.</span>
              <div class="left_wrap">
                <div class="input_wrap"><input type="text" v-model="email1" class="input" /><span>@</span><input type="text" v-model="email2" class="input domain_txt" /></div>
              </div>
              <div class="right_wrap">
                <div class="select_wrap">
                  <select class="select domain_list" v-model="email3">
                    <option value="" selected>직접입력</option>
                    <option value="gmail.com">gmail.com</option>
                    <option value="hanmail.net">hanmail.net</option>
                    <option value="daum.net">daum.net</option>
                    <option value="naver.com">naver.com</option>
                    <option value="nate.com">nate.com</option>
                    <option value="outlook.com">outlook.com</option>
                    <option value="hotmail.com">hotmail.com</option>
                    <option value="yahoo.com">yahoo.com</option>
                    <option value="korea.com">korea.com</option>
                  </select>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="box_wrap manager_wrap">
          <div class="box_top">
            <h3 class="tit">서비스 담당자</h3>
            <div class="top_right jfs">
              <div class="left_wrap">
                <div class="input_wrap">
                  <input type="text" v-model="csDept" class="input" placeholder="부서명" />
                </div>
              </div>
              <div class="right_wrap">
                <div class="input_wrap">
                  <input type="text" v-model="csChrgNm" class="input" placeholder="담당자명" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- 제목 -->
        <div class="box_wrap">
          <div class="box_top">
            <h3 class="tit required">제목</h3>
            <div class="top_right">
              <div class="input_wrap">
                <input type="text" class="input" v-model="csTitle" placeholder="제목을 입력하세요." />
              </div>
            </div>
          </div>
        </div>
        <!-- 문의내용 -->
        <div class="box_wrap inputbox_wrap">
          <div class="box_top">
            <h3 class="tit required">문의내용</h3>
          </div>
          <div class="box_cont inputbox">
            <div class="input_con">
              <textarea v-model="csCont" cols="30" rows="10" class="input_cont scr_wrap" placeholder="문의내용을 입력하세요."></textarea>
              <div class="input_bottom">
                <!-- 0705 언어검수 수정 -->
                <!-- div class="count">
                  <span class="byte"><span class="byte_info">0 </span>/ 1,000자</span>
                </div -->
              </div>
            </div>
          </div>
        </div>
        <!-- 첨부파일 -->
        <div class="box_wrap inputbox_wrap">
          <div class="box_top flw">
            <h3 class="tit">
              첨부파일
              <!-- <div class="btn_question tt_btn">
                <div class="tooltip l_tooltip">jpg, png, pdf 최대 5MB</div>
              </div> -->
            </h3>
            <div class="top_right jfs">
              <div class="left_wrap">
                <div class="btn plus">파일 올리기 <input type="file" /></div>
                <input type="file" accept=".png, .jpg, .pdf" @change="onFileChange" ref="imageUploader" />
              </div>
              <div v-if="fileNm != ''" class="right_wrap">
                <div class="file_name">
                  <p class="ellipsis">{{ fileNm }}</p>
                  <button class="btn_close" @click="fileRemove"></button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- tablcontTop 위치 위로 이동 -->
        <div class="btn_wrap">
          <button class="btn large_pink" type="button" @click="fnConfirm">상담 접수</button>
          <button class="btn large_white" type="button" @click="fnInquiryCancel">취소</button>
        </div>
      </div>
    </div>
  </div>
  <!--	e: 	contents	-->
</template>
<script>
import confirm from "@/modules/commonUtil/service/confirm";
import { eventBus } from "@/modules/commonUtil/service/eventBus";
import MobileGnb from "@/components/MobileGnb.vue";
import svcCntApi from "../service/serviceCenterApi";

export default {
  name: "InquiryNotMember",
  components: { MobileGnb },
  data() {
    return {
      files: "",
      fileNm: "",
      category: "",
      userName: "",
      phone: "",
      userEmail: "",
      csDept: "",
      csChrgNm: "",
      csManager: this.csDept + " " + this.csChrgNm, //this.csDept+" "+this.csChrgNm""
      csTitle: "",
      csCont: "",
      categoryM: "",
      categoryS: "",
      email1: "",
      email2: "",
      email3: "",
    };
  },
  watch: {
    phone(val) {
      if (val !== "") {
        let regExp = /^[0-9]+$/;
        if (!regExp.test(val)) {
          confirm.fnAlert("", "휴대폰번호는 숫자만 입력 가능 합니다.", "alert");
          this.phone = val.replace(/[^0-9]/g, "");
        }
      }
    },
  },
  mounted() {},
  methods: {
    onFileChange(event) {
      const files = event.target.files[0];
      const filePath = event.target.value;
      const filePathSplit = filePath.split("\\");
      const filePathLength = filePathSplit.length;
      const fileNameSplit = filePathSplit[filePathLength - 1].split(".");
      const fileExt = fileNameSplit[1] != null ? fileNameSplit[1].toLowerCase() : fileNameSplit[1];

      if (files.size > 5242880) {
        confirm.fnAlert("", "최대 5MB 크기의 파일만 첨부할 수 있습니다.", "alert");
        return false;
      }
      if (!(fileExt === "jpg" || fileExt === "png" || fileExt === "pdf")) {
        confirm.fnAlert("", "jpg, png, pdf 형식의 파일만 </ br>첨부할 수 있습니다.", "alert");
        event.target.value = "";
        return false;
      }
      this.files = files;
      this.fileNm = event.target.files[0].name;
    },
    fileRemove() {
      this.files = "";
      this.fileNm = "";
    },
    fnConfirm() {
      if (this.email3 == "") {
        this.userEmail = this.email1 + "@" + this.email2;
      } else {
        this.userEmail = this.email1 + "@" + this.email3;
      }

      if (this.categoryM == "") {
        confirm.fnAlert("", "문의 유형을 선택해 주세요.", "alert");
        return false;
      } else {
        if (this.categoryM != "기타" && this.categoryS == "") {
          confirm.fnAlert("", "문의 상세 유형을 선택해 주세요.", "alert");
          return false;
        }
        if (this.categoryM == "기타") {
          this.category = this.categoryM;
        } else {
          this.category = this.categoryM + " " + this.categoryS;
        }
      }
      if (this.userName == "") {
        confirm.fnAlert("", "이름을 입력해 주세요.", "alert");
        return false;
      } else {
        if (this.fnGetStringLength(this.userName) > 16) {
          confirm.fnAlert("", "이름 입력 내용이 16byte를 초과했습니다.", "alert");
          return false;
        }
      }
      if (this.phone == "") {
        confirm.fnAlert("", "휴대폰번호를 입력해 주세요.", "alert");
        return false;
      } else {
        let regExp = /^01([0|1|6|7|8|9])-?([0-9]{3,4})-?([0-9]{4})$/;
        if (!regExp.test(this.phone)) {
          confirm.fnAlert("", "휴대폰 번호를 올바르게 입력하여 주십시오.", "alert");
          return false;
        }
      }
      if (this.userEmail == "") {
        confirm.fnAlert("", "이메일을 입력해 주세요.", "alert");
        return false;
      } else {
        let regExp = /^([\w-]+(?:\.[\w-]+)*)@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$/i;
        if (!regExp.test(this.userEmail)) {
          confirm.fnAlert("", "이메일 주소 형식이 잘못되었습니다.<br>확인 후 다시 입력해 주세요.", "alert");
          return false;
        }
      }
      if (this.csDept !== "" && this.fnGetStringLength(this.csDept) > 16) {
        confirm.fnAlert("", "서비스 담당자 부서명 입력 내용이 16byte를 초과했습니다.", "alert");
        return false;
      }
      if (this.csChrgNm !== "" && this.fnGetStringLength(this.csChrgNm) > 16) {
        confirm.fnAlert("", "서비스 담당자명 입력 내용이 16byte를 초과했습니다.", "alert");
        return false;
      }
      if (this.csTitle == "") {
        confirm.fnAlert("", "제목을 입력해 주세요.", "alert");
        return false;
      } else {
        if (this.fnGetStringLength(this.csTitle) > 50) {
          confirm.fnAlert("", "제목 입력 내용이 50byte를 초과했습니다.", "alert");
          return false;
        }
      }
      if (this.csCont == "") {
        confirm.fnAlert("", "문의내용을 입력해 주세요.", "alert");
        return false;
      } else {
        if (this.fnGetStringLength(this.csCont) > 2000) {
          confirm.fnAlert("", "문의내용 입력 내용이 2,000byte를 초과했습니다.", "alert");
          return false;
        }
      }

      eventBus.$on("callbackEventBus", this.fnInquiryExec);
      confirm.fnConfirm("", "작성한 내용으로 상담을 접수하시겠어요?", "accept");
    },
    fnInquiryExec() {
      const fd = new FormData();
      fd.append("category", this.category);
      fd.append("userName", this.userName);
      fd.append("phone", this.phone);
      fd.append("userEmail", this.userEmail);
      fd.append("csManager", this.csManager);
      fd.append("csTitle", this.csTitle);
      fd.append("csCont", this.csCont);
      if (this.files != "") {
        fd.append("files", this.files);
      }

      svcCntApi.sendInquiry(fd).then((response) => {
        var result = response.data;
        if (result.success) {
          confirm.fnAlert("", "상담내용이 접수 되었습니다.", "alert");
          this.$router.push({ path: "/" });
        } else {
          confirm.fnAlert("", result.message, "alert");
        }
      });
    },
    fnInquiryCancel() {
      eventBus.$on("callbackEventBus", this.fnCancel);
      confirm.fnConfirm("", "취소하면 작성한 모든 내용이 삭제됩니다.<br />그래도 취소하시겠어요?", "write");
    },
    fnCancel() {
      this.$router.push({ path: "/" });
    },
    fnGetStringLength(str) {
      var strLength = 0;
      for (var i = 0; i < str.length; i++) {
        var code = str.charCodeAt(i);
        var ch = str.substr(i, 1).toUpperCase();
        code = parseInt(code);
        if ((ch < "0" || ch > "9") && (ch < "A" || ch > "Z") && (code > 255 || code < 0)) {
          strLength = strLength + 2;
        } else {
          strLength = strLength + 1;
        }
      }
      return strLength;
    },
  },
};
</script>
