<template>
  <div class="contents" v-if="pwdConfirm">
    <MobileGnb />
    <div class="login_wrap">
      <div class="login_box">
        <div class="login_box-logo">
          <a href="javascript:void(0)">uplus 메시지허브이지</a>
          <p>Easy Messaging Service</p>
        </div>
        <div class="login_box-content">
          <form @submit.prevent="" autocomplete="off">
            <h2 class="title">비밀번호 확인</h2>
            <input type="password" v-model="passWrd" placeholder="비밀번호를 입력하세요." @keyup.enter="fnConfirmPwdExec" @focus="fnInputScroll" />
            <div class="btn_group">
              <button type="button" class="btn large_pink" @click="fnConfirmPwdExec">확인</button>
              <button type="button" class="btn large_white" @click="fnCancel">취소</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import myInfoApi from "@/modules/myInfo/service/myInfoApi";
import loginApi from "@/modules/login/service/loginApi";
import confirm from "@/modules/commonUtil/service/confirm";
import { eventBus } from "@/modules/commonUtil/service/eventBus";
import MobileGnb from "@/components/MobileGnb.vue";
import store from "@/store";

export default {
  name: "passwordConfirm",
  props: {
    pwdConfirm: {
      type: Boolean,
      require: true,
    },
  },
  data() {
    return {
      passWrd: "",
      userServiceInfo: {
        cfUserPwd: "",
      },
      mobile: false,
    };
  },
  computed: {
    isMobile: function () {
      return store.getters["getIsMobile"];
    },
  },
  watch: {
    isMobile(value) {
      this.mobile = value;
    },
  },
  mounted() {
    this.mobile = store.getters["getIsMobile"];
  },
  methods: {
    fnCancel() {
      this.$emit("confirmCancel");
    },
    fnConfirmPwdExec() {
      if (!this.passWrd) {
        confirm.fnAlert("", "비밀번호를 입력하세요.", "alert");
        return;
      }
      this.userServiceInfo = {
        cfUserPwd: this.passWrd,
      };
      myInfoApi.confirmPwd(this.userServiceInfo).then((response) => {
        var result = response.data;
        if (result.success) {
          this.$emit("confirmPwd", this.passWrd);
        } else {
          if (result.code == "CE_TO_LOCK") {
            eventBus.$on("callbackEventBus", this.fnLogout);
            confirm.fnConfirm("", "비밀번호 5회 오류로 비밀번호가 초기화되었습니다.", "cbAlert");
          } else if (result.code == "CE_WRONG_PWD") {
            confirm.fnAlert("", "비밀번호 5회 오류 시 비밀번호가 초기화됩니다.", "alert");
          }
        }
      });
    },
    fnLogout() {
      this.$store.commit("login/isAuth", false);
      this.$store.commit("hubEzData", []);
      loginApi.logout({}).then(() => {
        /* var result = response.data;
        console.log(result); */
        this.$router.push({ path: "/login" });
      });
    },
    fnInputScroll() {
      if (this.mobile) window.scrollTo({ top: 180 });
    },
  },
  components: { MobileGnb },
};
</script>
