<template>
  <!--	s: m_section	-->
  <section class="m_section">
    <!--	s: 	contents-->
    <div class="contents">
      <MobileGnb />
      <div class="tabmenu_wrap">
        <ul class="tabmenu2">
          <li class="tabl" @click="fnMoveTab('send')"><span>전송 메시지 내역</span></li>
          <li class="tabl" @click="fnMoveTab('rsv')"><span>예약 메시지 내역</span></li>
          <li class="tabl on"><span>사용자 전송 통계</span></li>
        </ul>
      </div>
      <div class="tabcont_wrap statistics_wrap">
        <div class="tabcont on">
          <h1 class="title with_desc">사용자 전송 통계</h1>
          <div class="tabcontTop">
            <ul>
              <li>사용자 별 전송 통계를 확인할 수 있습니다.</li>
            </ul>
          </div>
          <!-- s: search_box -->
          <div class="search_box">
            <div class="line">
              <div class="group group01">
                <label for="right" class="label">날짜</label>
                <div v-if="dateDiv == 'day'" class="date_wrap day">
                  <input
                    type="date"
                    :min="this.$gfnCommonUtils.strDateAddDaySrch(this.sumYmdLast, -6)"
                    :max="this.$gfnCommonUtils.strDateAddDaySrch(this.sumYmdLast, 0)"
                    class="date"
                    v-model="sumYmdFirst"
                    @change="fnSendMessageStatsSearch"
                  /><span></span>
                  <input
                    type="date"
                    :min="this.$gfnCommonUtils.strDateAddDaySrch(this.sumYmdFirst, 0)"
                    :max="this.$gfnCommonUtils.strDateAddDaySrch(this.sumYmdFirst, 6)"
                    class="date"
                    v-model="sumYmdLast"
                    @change="fnSendMessageStatsSearch"
                  />
                </div>
                <div v-if="dateDiv == 'mon'" class="date_wrap month">
                  <input
                    type="month"
                    :min="this.$gfnCommonUtils.strDateAddMonthDtSrch(this.sumYmLast + '-01', -6)"
                    :max="this.$gfnCommonUtils.strDateAddMonthDtSrch(this.sumYmLast + '-01', 0)"
                    class="date"
                    v-model="sumYmFirst"
                    @change="fnSendMessageStatsSearch"
                  /><span></span>
                  <input
                    type="month"
                    :min="this.$gfnCommonUtils.strDateAddMonthDtSrch(this.sumYmFirst + '-01', 0)"
                    :max="this.$gfnCommonUtils.strDateAddMonthDtSrch(this.sumYmFirst + '-01', 6)"
                    class="date"
                    v-model="sumYmLast"
                    @change="fnSendMessageStatsSearch"
                  />
                </div>
              </div>
              <div class="group group02">
                <label for="right" class="label"></label>
                <div class="radio_wrap">
                  <ul>
                    <li><input type="radio" class="radio" v-model="dateDiv" id="day" value="day" @change="fnSendMessageStatsSearch" /><label for="day">일별</label></li>
                    <li><input type="radio" class="radio" v-model="dateDiv" id="month" value="mon" @change="fnSendMessageStatsSearch" /><label for="month">월별</label></li>
                  </ul>
                </div>
              </div>
            </div>
            <div class="line">
              <div class="group group03">
                <label for="right" class="label">메시지 종류</label>
                <div class="select_wrap">
                  <select v-model="sndChCd" class="select" @change="fnSendMessageStatsSearch">
                    <option value="" selected>메시지 종류 전체</option>
                    <option value="SMS">단문 메시지(SMS)</option>
                    <option value="LMS">장문 메시지(LMS)</option>
                    <option value="MMS">사진/영상 메시지(MMS)</option>
                    <option value="ALIMTALK">알림톡</option>
                    <!-- <option value="KKO_NOTI">알림톡</option> -->
                  </select>
                </div>
              </div>
              <div class="group group04">
                <label for="right" class="label">검색어</label>
                <div class="search_wrap">
                  <input type="text" v-model="srchStr" class="search2" placeholder="보낸 사람 이름 또는 아이디를 입력하세요." @keypress.enter="fnSendMessageStatsSearch" />
                </div>
              </div>
              <div class="group group05">
                <label for="right" class="label"></label>
                <button class="btn" @click="fnSendMessageStatsSearch">조회</button>
              </div>
            </div>
          </div>
          <!-- e: search_box -->
          <!-- s: list_area -->
          <div class="list_area">
            <div class="list_info">
              <div class="left_wrap">
                <div class="select_wrap">
                  <select v-model="selected" @change="fnSelected" class="select">
                    <option value="30" selected>30</option>
                    <option value="50">50</option>
                    <option value="100">100</option>
                  </select>
                </div>
                <p class="total">
                  전체 <span class="pink">{{ totCnt }} </span>건
                </p>
              </div>
              <button class="btn white download" @click="excelDownLoadSelect">엑셀 파일로 내려받기</button>
            </div>

            <div class="fixed-table-cover">
              <div class="fixed-table-container row2" style="width: 1074px;">
                <div class="fixed-table-header-bg"></div>
                <div class="fixed-table-wrapper">
                  <table class="fixed-table" style="table-layout: fixed">
                    <thead>
                      <tr>
                        <th class="td_sender" style="width: 10%">
                          <div class="th-text">전송자 명<br />(아이디)</div>
                        </th >
                        <th class="td_date" style="width: 13%">
                          <div class="th-text">전송일자</div>
                        </th >
                        <th class="td_type" style="width:9%">
                          <div class="th-text">메시지 종류</div>
                        </th>
                        <th class="td_alt" style="width:6%">
                          <div class="th-text">전환 전송</div>
                        </th>
                        <th class="td_count" style="width: 6%">
                          <div class="th-text">전송 건수</div>
                        </th>
                        <th class="td_alt" style="width: 23%">
                          <div class="th-text row">
                            <p>전송 결과</p>
                            <div class="col">
                              <span class="td_alt_success">성공 건수/성공률</span>
                              <span class="td_alt_fail">실패 건수/실패율</span>
                            </div>
                          </div>
                        </th>
                        <th class="td_alt" style="width: 23%">
                          <div class="th-text row">
                            <p>문자메시지 전환 전송</p>
                            <div class="col">
                              <span class="td_alt_success">성공 건수/성공률</span>
                              <span class="td_alt_fail">실패 건수/실패율</span>
                            </div>
                          </div>
                        </th>
                        <th class="td_wait" style="width: 8%">
                          <div class="th-text line130">대기 건수<br />/대기율</div>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-if="totCnt == 0" class="empty">
                        <td colspan="8">검색 결과가 없습니다.</td>
                      </tr>
                      <tr v-if="totCnt > 0" class="on">
                        <td class="td_sender" colspan="4">총합계</td>
                        <td class="td_count">{{ totalSum.sndCnt | formatPrice }}</td>
                        <td class="td_alt">
                          <div class="row">
                            <div class="col">
                              <span class="td_alt_success blue">{{ totalSum.succCnt | formatPrice }}건 &#47; {{ totalSum.succRt }}%</span>
                              <span class="td_alt_fail red">{{ totalSum.failCnt | formatPrice }}건 &#47; {{ totalSum.failRt }}%</span>
                            </div>
                          </div>
                        </td>
                        <td class="td_alt">
                          <div class="row">
                            <div class="col">
                              <span class="td_alt_success blue">{{ totalSum.fbackSuccCnt | formatPrice }}건 &#47; {{ totalSum.fbackSuccRt }}%</span>
                              <span class="td_alt_fail red">{{ totalSum.fbackFailCnt | formatPrice }}건 &#47; {{ totalSum.fbackFailRt }}%</span>
                            </div>
                          </div>
                        </td>
                        <td class="td_wait">{{ totalSum.readyCnt | formatPrice }}건 &#47; {{ totalSum.readyRt }}%</td>
                      </tr>

                      <tr v-for="(data, index) in sendMsgStatsList" :key="index">
                        <td class="td_sender">{{ data.userNm }}<br />({{data.userId}})</td>
                        <td class="td_date">{{ data.sumYmd }}</td>
                        <td class="td_type">{{ data.sndChCd }}</td>
                        <td class="td_alt">{{ data.rplcsndChCd }}</td>
                        <td class="td_count">{{ data.sndCnt | formatPrice }}</td>
                        <td class="td_alt">
                          <div class="row">
                            <div class="col">
                              <span class="td_alt_success blue">{{ data.succCnt | formatPrice }}건 &#47; {{ data.succRt }}%</span>
                              <span class="td_alt_fail red">{{ data.failCnt | formatPrice }}건 &#47; {{ data.failRt }}%</span>
                            </div>
                          </div>
                        </td>
                        <td class="td_alt">
                          <div class="row">
                            <div class="col">
                              <span class="td_alt_success blue">{{ data.fbackSuccCnt | formatPrice }}건 &#47; {{ data.fbackSuccRt }}%</span>
                              <span class="td_alt_fail red">{{ data.fbackFailCnt | formatPrice }}건 &#47; {{ data.fbackFailRt }}%</span>
                            </div>
                          </div>
                        </td>
                        <td class="td_wait">{{ data.readyCnt | formatPrice }}건 &#47; {{ data.readyRt }}%</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>

            <!--
            <div class="list_cover">
              <div class="list_th_wrap" @scroll="handleThScroll($event)">
                <ul class="list_th">
                  <li class="td_sender w80"><span>전송자 명</span></li>
                  <li class="td_date w150"><span>전송일자</span></li>
                  <li class="td_type w125"><span>메시지 종류</span></li>
                  <li class="td_alt w100"><span>전환 전송</span></li>
                  <li class="td_count w80"><span>전송 건수</span></li>
                  <li class="td_alt w225 row">
                    <p><span>전송 결과</span></p>
                    <div class="col">
                      <span class="td_alt_success w112">성공 건수/성공률</span>
                      <span class="td_alt_fail w112">실패 건수/실패율</span>
                    </div>
                  </li>
                  <li class="td_alt w225 row">
                    <p><span>문자메시지 전환 전송</span></p>
                    <div class="col">
                      <span class="td_alt_success w112">성공 건수/성공률</span>
                      <span class="td_alt_fail w112">실패 건수/실패율</span>
                    </div>
                  </li>
                  <li class="td_wait w83">
                    <span>대기 건수<br />/대기율</span>
                  </li>
                </ul>
              </div>
              <div class="list_wrap scr_wrap" @scroll="handleScroll($event)">
                <ul class="list list2">
                  <li v-if="totCnt == 0" class="empty">
                    <p>검색 결과가 없습니다.</p>
                  </li>
                  <li v-if="totCnt > 0" class="on">
                    <span class="td_sender" style="width: 460px">총합계</span>
                    <span class="td_count w80">{{ totalSum.sndCnt | formatPrice }}</span>
                    <span class="td_success w112 blue">{{ totalSum.succCnt | formatPrice }}건 &#47; {{ totalSum.succRt }}%</span>
                    <span class="td_fail w112 red">{{ totalSum.failCnt | formatPrice }}건 &#47; {{ totalSum.failRt }}%</span>
                    <span class="td_alt_success w112 blue">{{ totalSum.fbackSuccCnt | formatPrice }}건 &#47; {{ totalSum.fbackSuccRt }}%</span>
                    <span class="td_alt_fail w112 red">{{ totalSum.fbackFailCnt | formatPrice }}건 &#47; {{ totalSum.fbackFailRt }}%</span>
                    <span class="td_wait w83">{{ totalSum.readyCnt | formatPrice }}건 &#47; {{ totalSum.readyRt }}%</span>
                  </li>
                  <li v-for="(data, index) in sendMsgStatsList" :key="index">
                    <span class="td_sender w80">{{ data.userNm }}</span>
                    <span class="td_date w150">{{ data.sumYmd }}</span>
                    <span class="td_type w125">{{ data.sndChCd }}</span>
                    <span class="td_alt w100">{{ data.rplcsndChCd }}</span>
                    <span class="td_count w80">{{ data.sndCnt | formatPrice }}</span>
                    <span class="td_success w112 blue">{{ data.succCnt | formatPrice }}건 &#47; {{ data.succRt }}%</span>
                    <span class="td_fail w112 red">{{ data.failCnt | formatPrice }}건 &#47; {{ data.failRt }}%</span>
                    <span class="td_alt_success w112 blue">{{ data.fbackSuccCnt | formatPrice }}건 &#47; {{ data.fbackSuccRt }}%</span>
                    <span class="td_alt_fail w112 red">{{ data.fbackFailCnt | formatPrice }}건 &#47; {{ data.fbackFailRt }}%</span>
                    <span class="td_wait w83">{{ data.readyCnt | formatPrice }}건 &#47; {{ data.readyRt }}%</span>
                  </li>
                </ul>
              </div>
            </div> -->
            <div class="paging_wrap" v-show="totCnt > listSize">
              <!-- 페이징 컴퍼넌트 -->
              <PageLayer ref="updatePaging" @fnClick="fnSearch" :listTotalCnt="totCnt" :selected="listSize" :pageNum="pageNo"></PageLayer>
            </div>
          </div>
          <!-- e: list_area -->
        </div>
      </div>
    </div>
    <!--	e: 	contents	-->
  </section>
  <!--	e: m_section	-->
</template>
<script>
import * as XLSX from "xlsx";
import store from "@/store";
import sendManageApi from "@/modules/sendManage/service/sendManageApi";
import { eventBus } from "@/modules/commonUtil/service/eventBus";
import confirm from "@/modules/commonUtil/service/confirm";
import PageLayer from "@/components/PageLayer.vue";
import MobileGnb from "@/components/MobileGnb.vue";

export default {
  name: "sendMessage",
  components: {
    PageLayer,
    MobileGnb,
  },
  data() {
    return {
      selected: "30",
      listSize: 30, // select 박스 value (출력 갯수 이벤트)
      pageNo: 1, // 현재 페이징 위치
      totCnt: 0, //전체 리스트 수
      offset: 0, //페이지 시작점,
      dateDiv: "day",
      autcd: "",
      sumYmdFirst: this.$gfnCommonUtils.getCurretDate(), //검색 시작일
      sumYmdLast: this.$gfnCommonUtils.getCurretDate(), //검색 종료일
      sumYmFirst: this.$gfnCommonUtils.getCurretMonth(), //검색 시작월
      sumYmLast: this.$gfnCommonUtils.getCurretMonth(), //검색 종료월
      sndChCd: "", //검색 메세지 타입
      srchStr: "", //검색 템플릿명 내용
      sendMsgStatsList: [], //사용자 통계 리스트 초기 변수
      totalSum: {}, //사용자 통계 합계
      excelList: [], //사용자 통계 엑셀 리스트 초기 변수
      // 엑셀 다운로드 필요 변수
      visibleHeadProps: [],
      instance: undefined,
      header: [],
      headProps: [
        "userNm",
        "sumYmd",
        "sndChCd",
        "rplcsndChCd",
        "sndCnt",
        "succCnt",
        "succRt",
        "failCnt",
        "failRt",
        "fbackSuccCnt",
        "fbackSuccRt",
        "fbackFailCnt",
        "fbackFailRt",
        "readyCnt",
        "readyRt",
      ],
      headRow1: [
        {
          key: "userNm",
          name: "전송자명(아이디)",
          colspan: 1,
          rowspan: 2,
        },
        {
          key: "sumYmd",
          name: "전송일자",
          colspan: 1,
          rowspan: 2,
        },
        {
          key: "sndChCd",
          name: "메시지종류",
          colspan: 1,
          rowspan: 2,
        },
        {
          key: "rplcsndChCd",
          name: "전환전송",
          colspan: 1,
          rowspan: 2,
        },
        {
          key: "sndCnt",
          name: "전송건수",
          colspan: 1,
          rowspan: 2,
        },
        {
          name: "전송결과",
          colspan: 4,
          rowspan: 1,
        },
        {
          name: "문자메시지 전환전송",
          colspan: 4,
          rowspan: 1,
        },
        {
          name: "대기건수",
          colspan: 2,
          rowspan: 1,
        },
      ],
      headRow2: [
        {
          key: "succCnt",
          name: "성공건수",
          colspan: 1,
          rowspan: 1,
        },
        {
          key: "succRt",
          name: "성공률",
          colspan: 1,
          rowspan: 1,
        },
        {
          key: "failCnt",
          name: "실패건수",
          colspan: 1,
          rowspan: 1,
        },
        {
          key: "failRt",
          name: "실패율",
          colspan: 1,
          rowspan: 1,
        },
        {
          key: "fbackSuccCnt",
          name: "성공건수",
          colspan: 1,
          rowspan: 1,
        },
        {
          key: "fbackSuccRt",
          name: "성공률",
          colspan: 1,
          rowspan: 1,
        },
        {
          key: "fbackFailCnt",
          name: "실패건수",
          colspan: 1,
          rowspan: 1,
        },
        {
          key: "fbackFailRt",
          name: "실패율",
          colspan: 1,
          rowspan: 1,
        },
        {
          key: "readyCnt",
          name: "대기건수",
          colspan: 1,
          rowspan: 1,
        },
        {
          key: "readyRt",
          name: "대기율",
          colspan: 1,
          rowspan: 1,
        },
      ],
    };
  },
  created() {
    //this.fnInit();
    this.fnSendMessageList();
  },
  mounted() {},
  methods: {
    fnInit() {
      sendManageApi
        .selectAutInfo()
        .then((response) => {
          var result = response.data;
          if (result.success) {
            this.autCd = result.data.autCd;
          } else {
            confirm.fnAlert("", "오류가 발생하였습니다.", "alert");
          }
        })
        .catch((error) => {
          if (error.response != undefined && error.response != null) {
            if (error.response.status == 403) {
              eventBus.$on("callbackEventBus", () => {
                this.$router.go(-1);
              });
              confirm.fnConfirm("", "관리자ID만 접근 가능합니다.", "cbAlert");
            } else {
              confirm.fnAlert("", "오류가 발생하였습니다.", "alert");
            }
          } else {
            confirm.fnAlert("", "오류가 발생하였습니다.", "alert");
          }
        });
    },
    fnSendMessageList() {
      const isAuth = store.getters["login/isAuth"];
      if (isAuth) {
        if (this.dateDiv == "day") {
          if (this.$gfnCommonUtils.isEmpty(this.sumYmdFirst)) {
            confirm.fnAlert("", "검색 시작일을 선택해 주세요.", "alert");
            return false;
          }
          if (this.$gfnCommonUtils.isEmpty(this.sumYmdLast)) {
            confirm.fnAlert("", "검색 종료일을 선택해 주세요.", "alert");
            return false;
          }
          if (this.sumYmdFirst.replaceAll("-", "") > this.sumYmdLast.replaceAll("-", "")) {
            confirm.fnAlert("", "검색 시작일이 종료일보다 클 수 없습니다.", "alert");
            return false;
          }

          if (this.sumYmdLast.replaceAll("-", "") > this.$gfnCommonUtils.strDateAddMonthDt(this.sumYmdFirst, 6).replaceAll("-", "")) {
            confirm.fnAlert("", "날짜 검색 범위는 6개월 이상을 검색할 수 없습니다.", "alert");
            return false;
          }
          let param = {
            pageNo: this.pageNo, //페이징 필수 값
            listSize: this.listSize, //페이징 필수 값
            sumYmdFirst: this.sumYmdFirst,
            sumYmdLast: this.sumYmdLast,
            sndChCd: this.sndChCd,
            srchStr: this.srchStr,
          };
          sendManageApi.selectSendMsgDayStatusList(param).then((response) => {
            var result = response.data;
            if (result.success) {
              this.totalSum = result.data.totalSum; //합계
              this.sendMsgStatsList = result.data.custStatList; //서버 조회 리스트 셋팅
              this.totCnt = result.data.pageInfo.totCnt; //전체 카운트
              this.offset = result.data.pageInfo.offset; //페이지 번호
            } else {
              confirm.fnAlert("", "오류가 발생하였습니다.", "alert");
            }
          });
        } else {
          if (this.$gfnCommonUtils.isEmpty(this.sumYmFirst)) {
            confirm.fnAlert("", "검색 시작월을 선택해 주세요.", "alert");
            return false;
          }
          if (this.$gfnCommonUtils.isEmpty(this.sumYmLast)) {
            confirm.fnAlert("", "검색 종료월을 선택해 주세요.", "alert");
            return false;
          }
          if (this.sumYmFirst.replaceAll("-", "") > this.sumYmLast.replaceAll("-", "")) {
            confirm.fnAlert("", "검색 시작월이 종료월보다 클 수 없습니다.", "alert");
            return false;
          }
          if (this.sumYmLast.replaceAll("-", "") + "-01" > this.$gfnCommonUtils.strDateAddMonthDt(this.sumYmFirst + "-01", 12).replaceAll("-", "")) {
            confirm.fnAlert("", "날짜 검색 범위는 1년이상을 검색할 수 없습니다.", "alert");
            return false;
          }
          let param = {
            pageNo: this.pageNo, //페이징 필수 값
            listSize: this.listSize, //페이징 필수 값
            sumYmFirst: this.sumYmFirst + "-01",
            sumYmLast: this.sumYmLast + "-01",
            sndChCd: this.sndChCd,
            srchStr: this.srchStr,
          };
          sendManageApi.selectSendMsgMonStatusList(param).then((response) => {
            var result = response.data;
            if (result.success) {
              this.totalSum = result.data.totalSum; //합계
              this.sendMsgStatsList = result.data.custStatList; //서버 조회 리스트 셋팅
              this.totCnt = result.data.pageInfo.totCnt; //전체 카운트
              this.offset = result.data.pageInfo.offset; //페이지 번호
            } else {
              confirm.fnAlert("", "오류가 발생하였습니다.", "alert");
            }
          });
        }
      }
    },
    fnSelected() {
      //리스트 갯수 선택
      this.listSize = Number(this.selected);
      this.$refs.updatePaging.fnAllDecrease();
    },
    fnSendMessageStatsSearch() {
      this.$refs.updatePaging.fnAllDecrease();
    },
    fnSearch(pageNum) {
      const isAuth = store.getters["login/isAuth"];
      if (isAuth) {
        // 검색
        this.pageNo = this.$gfnCommonUtils.defaultIfEmpty(pageNum, "1") * 1;
        this.fnSendMessageList();
      }
    },
    excelDownLoadSelect() {
      if (!this.totCnt > 0) {
        confirm.fnAlert("", "다운로드할 데이터가 없습니다.", "alert", "");
        return false;
      }
      if (this.dateDiv == "day") {
        let param = {
          pageNo: this.pageNo, //페이징 필수 값
          listSize: this.listSize, //페이징 필수 값
          sumYmdFirst: this.sumYmdFirst,
          sumYmdLast: this.sumYmdLast,
          sndChCd: this.sndChCd,
          srchStr: this.srchStr,
        };
        sendManageApi.selectSendMsgDayStatusExcelList(param).then((response) => {
          var result = response.data;
          if (result.success) {
            this.excelList = result.data.excelList; //서버 조회 엑셀 리스트 셋팅
            this.excelDownload();
          } else {
            confirm.fnAlert("", "오류가 발생하였습니다.", "alert");
          }
        });
      } else {
        let param = {
          pageNo: this.pageNo, //페이징 필수 값
          listSize: this.listSize, //페이징 필수 값
          sumYmFirst: this.sumYmFirst + "-01",
          sumYmLast: this.sumYmLast + "-01",
          sndChCd: this.sndChCd,
          srchStr: this.srchStr,
        };
        sendManageApi.selectSendMsgMonStatusExcelList(param).then((response) => {
          var result = response.data;
          if (result.success) {
            this.excelList = result.data.excelList; //서버 조회 엑셀 리스트 셋팅
            this.excelDownload();
          } else {
            confirm.fnAlert("", "오류가 발생하였습니다.", "alert");
          }
        });
      }
    },
    excelDownload() {
      this.header = [];
      this.header.push(this.headRow1);
      this.header.push(this.headRow2);

      let options = {
        header: this.header,
        headProps: this.headProps,
      };
      this.excelExport(this.excelList, options);
    },

    excelExport(data, options) {
      // data row별 header에 바인딩 될 매핑값 설정
      let headProps = [];
      if (Array.isArray(options.headProps)) {
        headProps = options.headProps;
      } else if (options.headProps === "header") {
        for (let h of this.header) {
          headProps.push(h.key);
        }
      } else {
        headProps = Object.keys(data[0]);
      }
      this.visibleHeadProps = headProps;
      this.instance = document.createElement("table");
      // Header 세팅
      let header = [];
      if (!Array.isArray(this.header[0])) {
        header.push(this.header);
      } else {
        header = this.header;
      }

      let thead = document.createElement("thead");
      for (let row of header) {
        let tr = document.createElement("tr");
        for (let h of row) {
          let rowspan = h.rowspan || "1";
          let colspan = h.colspan || "1";
          let th = document.createElement("th");
          th.setAttribute("rowspan", rowspan);
          th.setAttribute("colspan", colspan);
          th.setAttribute("align", "center");
          th.innerText = h.name;
          tr.appendChild(th);
        }
        thead.appendChild(tr);
      }
      this.instance.appendChild(thead);
      // Body 세팅
      let rowFst = true;
      let tdRowFst = true;
      let tbody = document.createElement("tbody");
      for (let row of data) {
        let tr = document.createElement("tr");
        for (let cellkey of this.visibleHeadProps) {
          let td = "";
          if (rowFst) {
            if (tdRowFst) {
              if (cellkey === "userNm") {
                td = document.createElement("td");
                td.setAttribute("colspan", "4");
                td.innerText = "합계";
                tdRowFst = false;
                tr.appendChild(td);
              }
            } else {
              if (cellkey !== "sumYmd" && cellkey !== "sndChCd" && cellkey !== "rplcsndChCd") {
                td = document.createElement("td");
                td.innerText = row[cellkey];
                tr.appendChild(td);
              }
            }
          } else {
            td = document.createElement("td");
            td.innerText = row[cellkey];
            tr.appendChild(td);
          }
        }
        tbody.appendChild(tr);
        rowFst = false;
      }
      this.instance.appendChild(tbody);
      // instance에 만들어진 table을 엑셀파일로 저장
      let config = { raw: true, type: "string" };
      let ws = XLSX.utils.table_to_sheet(this.instance, config);
      let wb = XLSX.utils.book_new();

      XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
      if (this.dateDiv == "day") {
        XLSX.writeFile(wb, "사용자 일별 통계.xlsx");
      } else {
        XLSX.writeFile(wb, "사용자 월별 통계.xlsx");
      }
    },
    fnMoveTab(type) {
      if (type == "rsv") {
        this.$router.push({ path: "/sendManage/rsvSendMessage" });
      } else if (type == "stat") {
        this.$router.push({ path: "/sendManage/sendMessageStats" });
      } else {
        this.$router.push({ path: "/sendManage/sendMessage" });
      }
    },
    handleScroll(e) {
      const chooseListThWrap = e.target.parentNode.children[0];
      chooseListThWrap.scrollLeft = e.target.scrollLeft;
    },
    handleThScroll(e) {
      const chooseListWrap = e.target.parentNode.children[1];
      chooseListWrap.scrollLeft = e.target.scrollLeft;
    },
  },
};
</script>
