<template>
  <Fragment>
    <!--	s: popup	-->
    <div class="navDimmed" @click="ModalClose()"></div>
    <div class="nav-popup-wrap">
      <!-- s: 사용가능 금액 -->
      <div class="popup tit price_pop nav-popup" @click="AlarmDetail($event)">
        <div class="pop-cont">
          <div class="greeting">
            <p class="g_txt">
              <span class="user_name" v-if="isAuth">
                <span class="user_name_bold">{{ userNm }}({{ userId }})</span>님<br />
              </span>
              안녕하세요
            </p>
            <div v-if="rsvSendDiv" class="btn_alarm tt_btn on">
              <!-- class="on" : 핑크색 dot 추가-->
              <div class="tooltip l_tooltip" :style="alimTooltip ? 'display : block' : ''">
                <p>
                  이번 달 <span class="b">{{ rsvSendStr }}</span> 예약 전송 대기 중 입니다.
                </p>
                <router-link to="/sendManage/rsvSendMessage" class="link">[예약 메시지 보기]</router-link>
                <ul class="tt_notice">
                  <li>이번 달 요금제를 다 사용하면 예약된 모든 메시지는 자동 취소됩니다.</li>
                  <li>요금제와 예약건수를 미리 확인해주세요</li>
                </ul>
              </div>
            </div>
          </div>
          <div class="amount_wrap">
            <div class="amount_tit">
              <p class="type" v-if="isAuth && chargeNm != ''">{{ chargeNm }}</p>
              <p class="type" v-else>-</p>
              <div class="change_wrap">
                <a href="">요금제명</a>
                <div class="btn_question tt_btn">
                  <div class="tooltip l_tooltip">
                    <p>요금제는 월 1회만</p>
                    <p>변경할 수 있습니다.</p>
                  </div>
                </div>
              </div>
            </div>
            <!--비 로그인-->
            <ul v-if="!isAuth" class="amount no_login">
              <li>
                <div class="left">
                  <div class="left_flex">
                    <p class="left_title">남은 금액</p>
                    <div class="btn_question tt_btn">
                      <div class="tooltip l_tooltip">
                        <p>'기본'과 '이월'의 합이</p>
                        <p>가입 요금제의 2배를 초과할 수 없습니다.</p>
                      </div>
                    </div>
                  </div>
                  <p class="left_price">-</p>
                </div>
                <div class="right">
                  <p><span>기본</span><span>-</span></p>
                  <p><span>이월</span><span>-</span></p>
                </div>
              </li>
              <li>
                <div class="left">
                  <p class="left_title">사용한 금액</p>
                  <p class="left_price">-</p>
                </div>
                <div class="right">
                  <p><span>기본</span><span>-</span></p>
                  <p><span>이월</span><span>-</span></p>
                  <p><span>추가</span><span>-</span></p>
                </div>
              </li>
            </ul>
            <ul class="amount" v-if="isAuth">
              <!-- 로그인 -->
              <li class="amount_li"><!-- 230614 수정 --> 
                <div class="left">
                  <div class="left_flex">
                    <p class="left_title">남은 금액</p>
                    <div class="btn_question tt_btn">
                      <div class="tooltip l_tooltip">
                        <p>'기본'과 '이월'의 합이</p>
                        <p>가입 요금제의 2배를 초과할 수 없습니다.</p>
                      </div>
                    </div>
                  </div>
                  <p class="left_price" style="font-size: 20px" v-if="subSttusCd === '00' || subSttusCd === '01'">서비스 체험</p>
                  <p class="left_price" style="font-size: 20px" v-else-if="subSttusCd === '03'">이용 중지</p>
                  <p class="left_price" style="font-size: 20px" v-else-if="subSttusCd === '04'">미납 정지</p>
                  <!-- <p class="left_price" :style="subSttusCd === '02' && openDay ? 'font-size: 20px;' : ''" v-else-if="subSttusCd === '02' && openDay">쓴 만큼<br />(또는 종량제)</p> -->
                  <p class="left_price" v-else>{{ remainingAmt ? remainingAmt : "-" | formatPrice }}</p>
                </div>
                <div v-if="joinDiv == '01'" class="right">
                  <p>
                    <span>기본</span><span>{{ pricePlanBalance ? pricePlanBalance : "-" | formatPrice }}</span>
                  </p>
                  <p>
                    <span>이월</span><span>{{ cFwdBalance ? cFwdBalance : "-" | formatPrice }}</span>
                  </p>
                  <p>
                    <span>충전</span><span>{{ chrgBalance ? chrgBalance : "-" | formatPrice }}</span>
                  </p>
                </div>
                <div v-if="joinDiv == '02'" class="right">
                  <p>
                    <span>충전</span><span>{{ chrgBalance+pricePlanBalance+cFwdBalance | formatPrice }}</span>
                  </p>
                </div>
                <!-- 230614 추가 -->
                <div class="info_area" v-if="subSttusCd === '04'">
                  <div class="content_box">
                    <div class="content_box_left">
                      <div class="icon_box"></div>
                    </div>
                    <div class="content_box_right">
                      <p class="text_box">
                        요금 완납 후 1시간 후 메시지 발송이 가능합니다.
                      </p>
                    </div>
                  </div>
                </div>
              </li>
              <li>
                <div class="left">
                  <p class="left_title">사용한 금액</p>
                  <p class="left_price">{{ usedAmt ? usedAmt : "-" | formatPrice }}</p>
                </div>
                <div v-if="joinDiv == '01'" class="right">
                  <p>
                    <span>기본</span><span>{{ pricePlanUsedAmt ? pricePlanUsedAmt : "-" | formatPrice }}</span>
                  </p>
                  <p>
                    <span>이월</span><span>{{ cFwdBalanceUsedAmt ? cFwdBalanceUsedAmt : "-" | formatPrice }}</span>
                  </p>
                  <p>
                    <span>추가</span><span>{{ addUsedAmt ? addUsedAmt : "-" | formatPrice }}</span>
                  </p>
                  <p>
                    <span>충전</span><span>{{ chrgAmt ? chrgAmt : "-" | formatPrice }}</span>
                  </p>
                </div>
                <div v-if="joinDiv == '02'" class="right">
                  <p>
                    <span>충전</span><span>{{ chrgAmt+pricePlanUsedAmt+cFwdBalanceUsedAmt | formatPrice }}</span>
                  </p>
                </div>
              </li>
            </ul>
          </div>
        </div>
        <div class="pop-btn1">
          <button type="button" class="btn large_pink" @click="ModalClose()">확인</button>
        </div>
      </div>
      <!-- e: 사용가능 금액 -->
      <!-- s: 사용가능 메시지 -->
      <div class="popup tit message_pop nav-popup">
        <div class="pop-head">
          <h3 class="pop-tit">사용할 수 있는 메시지 종류</h3>
        </div>
        <div class="pop-cont">
          <ul class="service_wrap">
            <li class="service"><div class="toggle message" :class="{ used: isSms && isAuth }">일반문자</div></li>
            <li class="service"><div class="toggle kakao" :class="{ used: isAlt && isAuth }">카카오 비즈메시지</div></li>
            <!-- class="used" 사용중 -->
            <!-- <li class="service"><div class="toggle">친구톡</div></li> -->
            <!-- <li class="service"><div class="toggle disabled">RCS</div></li> -->
            <!-- class="disabled" 비활성화 -->
          </ul>
        </div>
        <div class="pop-btn1">
          <button type="button" class="btn large_pink" @click="ModalClose()">확인</button>
        </div>
      </div>
      <!-- e: 사용가능 메시지 -->
    </div>
    <!--	e: popup	-->
  </Fragment>
</template>
<script>
import { Fragment } from "vue-frag";
import { mapGetters } from "vuex";
//import loginApi from "@/modules/login/service/loginApi";

export default {
  name: "NavPopup",
  components: { Fragment },
  props: {
    isSms: Boolean,
    isAlt: Boolean,
    chargeNm: String,
    rsvSendDiv: Boolean,
    rsvSendStr: String,
    joinDiv: String,
    remainingAmt: Number,
    pricePlanBalance: Number,
    cFwdBalance: Number,
    chrgBalance : Number,
    usedAmt: Number,
    pricePlanUsedAmt: Number,
    cFwdBalanceUsedAmt: Number,
    addUsedAmt: Number,
    chrgAmt : Number,
    userNm: String,
    userId: String,
    isMobile: Boolean,
    subSttusCd: String,
    openDay: Boolean,
  },
  data() {
    return {
      isAuth: this.$store.getters["login/isAuth"],
      alimTooltip: false,
    };
  },
  computed: {
    ...mapGetters({
      getIsAuth: "login/isAuth",
    }),
  },
  watch: {
    getIsAuth(data) {
      if (data != null && data != "" && data == true) {
        this.isAuth = true;
      } else {
        this.isAuth = false;
      }
    },
  },
  mounted() {},
  methods: {
    ModalClose() {
      var navDimmed = document.getElementsByClassName("navDimmed");
      var wrap = document.getElementsByClassName("nav-popup-wrap");
      var obj = wrap[0].childElementCount;
      navDimmed[0].style.display = "none";
      wrap[0].style.display = "none";
      for (var i = 0; i < obj; i++) {
        var target = document.getElementsByClassName("nav-popup");
        target[i].style.display = "none";
        target[i].classList.remove("open");
      }
    },
    AlarmDetail(e) {
      // 알림 아이콘 클릭시 이벤트
      if (e.target.classList.contains("btn_alarm")) {
        this.alimTooltip = !this.alimTooltip;
      } else {
        this.alimTooltip = false;
      }
    },
  },
};
</script>

<style scoped>
.navDimmed {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.5);
  display: none;
  z-index: 200;
}
</style>
