<template>
  <div class="m_preview_alimtalk">
    <button class="btn_close" @click="mPreAlimClose()"></button>
    <div class="m_preview previews" v-if="alimSendData.repSendingYN === 'true'">
      <div class="swiper m_preview_swiper">
        <swiper class="swiper-wrapper" :options="swiperOption">
          <swiper-slide class="swiper-slide preview_swiper talk_preview">
            <p class="preview_title">알림톡 미리보기</p>
            <div class="preview_top">
              <input type="radio" name="preview_type" id="btn_sent" checked="" /><label for="btn_sent"
                ><span>{{ alimSendData.chnlId !== "null" ? alimSendData.chnlId : "채널아이디" }}</span>
                <input type="text" placeholder="채널 아이디" :value="alimSendData.chnlId !== 'null' ? alimSendData.chnlId : ''" readonly
              /></label>
            </div>

            <div class="preview_cont" style="display: block">
              <div class="talk_channel">
                <div class="img"><img src="../../../assets/images/preview_icon.png" alt="" /></div>
                <p class="channel_name"></p>
              </div>
              <div class="talk_wrap">
                <div class="talk_top">
                  <span>알림톡 도착</span>
                </div>
                <div class="talk_cont">
                  <div class="talk_tit" v-if="tmplt.emphsTpCd === 'TEXT'">
                    <p class="t_sub" :style="tmplt.emphsSubtle ? 'color: rgb(18, 16, 6);' : ''">{{ tmplt.emphsSubtle ? tmplt.emphsSubtle : "강조표기 보조문구 미리보기" }}</p>
                    <p class="t_tit" :style="tmplt.emphsTitle ? 'color: rgb(18, 16, 6);' : ''">{{ tmplt.emphsTitle ? tmplt.emphsTitle : "제목 미리보기" }}</p>
                  </div>
                  <div class="talk_txt">
                    <p class="cont" :style="tmplt.tmpltSbst ? 'color: rgb(18, 16, 6);' : ''">{{ tmplt.tmpltSbst ? tmplt.tmpltSbst : "내용 미리보기" }}</p>
                    <p class="sub_info" v-if="tmplt.tmpltTpCd === 'EX' || tmplt.tmpltTpCd === 'MI'" :style="tmplt.etcInfo ? 'color: rgb(18, 16, 6);' : ''">
                      {{ tmplt.etcInfo ? tmplt.etcInfo : "부가정보 미리보기" }}
                    </p>
                    <p class="ad_msg" v-if="tmplt.tmpltTpCd === 'AD' || tmplt.tmpltTpCd === 'MI'" :style="adMsg ? 'color: rgb(18, 16, 6);' : ''">
                      {{ adMsg ? adMsg : "광고 메시지 미리보기" }}
                    </p>
                    <p class="ad_msg" style="color: rgb(18, 16, 6)"></p>
                  </div>
                  <div class="talk_btn" v-for="(button, index) in buttonList" :key="index">{{ button.name ? button.name : button.linkTypeName }}</div>
                </div>
              </div>
            </div>
          </swiper-slide>
          <swiper-slide class="swiper-slide preview_swiper talk_history">
            <p class="preview_title">알림톡 최근 작성메시지</p>
            <!--	s: 최근전송내역	-->
            <div class="preview_cont">
              <div class="history_wrap">
                <div class="history">
                  <div class="history_items_wrap scr_wrap" v-if="recentSendAlimList.length > 0">
                    <swiper class="history_items swiper-wrapper" :options="swiperOption2" ref="rcsMsgList">
                      <swiper-slide class="history_item swiper-slide" v-for="(message, index) in recentSendAlimList" :key="index">
                        <div class="history_cont">
                          <div class="txt_wrap">
                            <p class="btxt">{{ message.tmpltName }}</p>
                            <p class="stxt">{{ message.msg }}</p>
                          </div>
                        </div>
                        <div class="history_desc">
                          <div class="history_txt">
                            <p class="date">{{ message.regDt }}</p>
                            <p class="tit">{{ message.chnlId }}</p>
                          </div>
                          <div class="type kakao"></div>
                        </div>
                      </swiper-slide>
                    </swiper>
                  </div>
                  <div class="history_items_wrap scr_wrap" v-else>
                    <!-- <p>최근작성문자 없음</p> -->
                  </div>
                  <div class="btn_wrap" v-if="recentSendAlimList.length > 0">
                    <button class="btn_up swiper-alim-button-next"></button>
                    <button class="btn_down swiper-alim-button-prev"></button>
                  </div>
                </div>
              </div>
            </div>

            <!-- 	e: 최근전송내역 	-->
          </swiper-slide>
          <div class="swiper-slide preview_swiper sms_preview">
            <p class="preview_title">문자메시지 미리보기</p>
            <div class="preview_top">
              <input type="radio" name="preview_type" id="btn_sent" checked /><label for="btn_sent"
                ><span>{{ callbackNumber !== "null" ? callbackNumber : "발신번호" }}</span> <input type="text" placeholder="발신번호" :value="callbackNumber !== 'null' ? callbackNumber : ''" readonly
              /></label>
            </div>
            <!--	s: 미리보기	 -->
            <div class="preview_cont">
              <!--	s: 이미지 첨부시 -->
              <div class="pcont_top" :style="fileList.length > 0 ? 'display:block' : 'display:none'">
                <swiper class="pcont_top" ref="previewSwiper" v-show="fileList.length > 0" :options="swiperFileOption">
                  <swiper-slide class="pcont_img" v-for="(image, index) in fileList" :key="index"><img :src="image.src" class="img-fluid" /></swiper-slide>
                </swiper>
                <div class="swiper-pagination" v-show="fileList.length > 0"></div>
              </div>
              <!-- 	e: 이미지 첨부시 -->
              <div class="pcont_bottom" :class="fileList.length === 0 ? 'sms' : ''">
                <!-- 이미지 없을때 class="sms" 추가 -->
                <div class="pcont_tit" v-if="reqProduct !== 'SMS'">
                  <p class="output_tit" :style="title ? 'color:#121006' : ''" v-html="previewTitle ? previewTitle : '제목 미리보기'"></p>
                </div>
                <div class="pcont_txt scr_wrap">
                  <p class="cont" :style="msg ? 'color:#121006' : ''" v-html="previewMsg ? previewMsg : '내용 미리보기'"></p>
                  <div class="fixed_txt" v-if="ad">
                    <p>{{ adBottom }}</p>
                    <p v-if="authNumber">{{ authNumber }}</p>
                  </div>
                </div>
              </div>
              <div class="pcont_info">
                <span class="type">{{ reqProduct }}</span>
                <span class="date">{{ today }}</span>
              </div>
            </div>
            <!--	e: 미리보기	 -->
          </div>
          <div class="swiper-slide preview_swiper sms_history">
            <p class="preview_title">최근 작성메시지</p>
            <!--	s: 최근전송내역	-->
            <div class="preview_cont">
              <div class="history_wrap">
                <div class="history">
                  <div class="history_items_wrap scr_wrap" v-if="recentSendMessageList.length > 0">
                    <swiper class="history_items swiper-wrapper" :options="swiperOption3">
                      <swiper-slide class="history_item swiper-slide" v-for="(message, index) in recentSendMessageList" :key="index">
                        <template v-if="message.reqProduct === 'MMS'">
                          <div class="history_cont">
                            <div class="txt_wrap img_wrap">
                              <img :src="`/api/v1/fo/preview/${message.legacyFiles.split(',')[0]}`" style="width: 100%; height: 100%; object-fit: cover" alt="" />
                            </div>
                          </div>
                          <div class="history_desc">
                            <div class="history_txt">
                              <p class="date">{{ message.regDt }}</p>
                            </div>
                            <div class="type MMS"></div>
                          </div>
                        </template>
                        <template v-if="message.reqProduct === 'SMS' || message.reqProduct === 'LMS'">
                          <div class="history_cont">
                            <div class="txt_wrap">
                              <p class="stxt">{{ message.content }}</p>
                            </div>
                          </div>
                          <div class="history_desc">
                            <div class="history_txt">
                              <p class="date">{{ message.regDt }}</p>
                            </div>
                            <div class="type" :class="message.reqProduct"></div>
                          </div>
                        </template>
                      </swiper-slide>
                    </swiper>
                  </div>
                  <div class="btn_wrap" v-if="recentSendMessageList.length > 0">
                    <button class="btn_up swiper-button-next"></button>
                    <button class="btn_down swiper-button-prev"></button>
                  </div>
                </div>
              </div>
            </div>

            <!-- 	e: 최근전송내역 	-->
          </div>
        </swiper>
      </div>
      <div class="m_preview_alimtalk_pagination"></div>
    </div>
    <div class="m_preview previews" v-if="alimSendData.repSendingYN === 'false'">
      <div class="swiper m_preview_swiper">
        <div class="swiper-wrapper">
          <div class="swiper-slide preview_swiper talk_preview">
            <p class="preview_title">알림톡 미리보기</p>
            <div class="preview_top">
              <input type="radio" name="preview_type" id="btn_sent" checked="" /><label for="btn_sent"
                ><span>{{ alimSendData.chnlId !== "null" ? alimSendData.chnlId : "채널아이디" }}</span>
                <input type="text" placeholder="채널 아이디" :value="alimSendData.chnlId !== 'null' ? alimSendData.chnlId : ''" readonly
              /></label>
            </div>

            <div class="preview_cont" style="display: block">
              <div class="talk_channel">
                <div class="img"><img src="../../../assets/images/preview_icon.png" alt="" /></div>
                <p class="channel_name">{{ tmplt.chnlId }}</p>
              </div>
              <div class="talk_wrap">
                <div class="talk_top">
                  <span>알림톡 도착</span>
                </div>
                <div class="talk_cont">
                  <div class="talk_tit" v-if="tmplt.emphsTpCd === 'TEXT'">
                    <p class="t_sub" :style="tmplt.emphsSubtle ? 'color: rgb(18, 16, 6);' : ''">{{ tmplt.emphsSubtle ? tmplt.emphsSubtle : "강조표기 보조문구 미리보기" }}</p>
                    <p class="t_tit" :style="tmplt.emphsTitle ? 'color: rgb(18, 16, 6);' : ''">{{ tmplt.emphsTitle ? tmplt.emphsTitle : "제목 미리보기" }}</p>
                  </div>
                  <div class="talk_txt">
                    <p class="cont" :style="tmplt.tmpltSbst ? 'color: rgb(18, 16, 6);' : ''">{{ tmplt.tmpltSbst ? tmplt.tmpltSbst : "내용 미리보기" }}</p>
                    <p class="sub_info" v-if="tmplt.tmpltTpCd === 'EX' || tmplt.tmpltTpCd === 'MI'" :style="tmplt.etcInfo ? 'color: rgb(18, 16, 6);' : ''">
                      {{ tmplt.etcInfo ? tmplt.etcInfo : "부가정보 미리보기" }}
                    </p>
                    <p class="ad_msg" v-if="tmplt.tmpltTpCd === 'AD' || tmplt.tmpltTpCd === 'MI'" :style="adMsg ? 'color: rgb(18, 16, 6);' : ''">
                      {{ adMsg ? adMsg : "광고 메시지 미리보기" }}
                    </p>
                    <p class="ad_msg" style="color: rgb(18, 16, 6)"></p>
                  </div>
                  <div class="talk_btn" v-for="(button, index) in buttonList" :key="index">{{ button.name ? button.name : button.linkTypeName }}</div>
                </div>
              </div>
            </div>
          </div>
          <div class="swiper-slide preview_swiper talk_history">
            <p class="preview_title">알림톡 최근 작성메시지</p>
            <!--	s: 최근전송내역	-->
            <div class="preview_cont">
              <div class="history_wrap">
                <div class="history">
                  <div class="history_items_wrap scr_wrap" v-if="recentSendAlimList.length > 0">
                    <swiper class="history_items swiper-wrapper" :options="swiperOption2" ref="rcsMsgList">
                      <swiper-slide class="history_item swiper-slide" v-for="(message, index) in recentSendAlimList" :key="index">
                        <div class="history_cont">
                          <div class="txt_wrap">
                            <p class="btxt">{{ message.tmpltName }}</p>
                            <p class="stxt">{{ message.msg }}</p>
                          </div>
                        </div>
                        <div class="history_desc">
                          <div class="history_txt">
                            <p class="date">{{ message.regDt }}</p>
                            <p class="tit">{{ message.chnlId }}</p>
                          </div>
                          <div class="type kakao"></div>
                        </div>
                      </swiper-slide>
                    </swiper>
                  </div>
                  <div class="history_items_wrap scr_wrap" v-else>
                    <!-- <p>최근작성문자 없음</p> -->
                  </div>
                  <div class="btn_wrap" v-if="recentSendAlimList.length > 0">
                    <button class="btn_up swiper-alim-button-next"></button>
                    <button class="btn_down swiper-alim-button-prev"></button>
                  </div>
                </div>
              </div>
            </div>

            <!-- 	e: 최근전송내역 	-->
          </div>
        </div>
      </div>
      <!-- <div class="m_preview_alimtalk_pagination"></div> -->
    </div>
  </div>
</template>
<script>
import { swiper, swiperSlide } from "vue-awesome-swiper";
import { eventBus } from "@/modules/commonUtil/service/eventBus";
import confirm from "@/modules/commonUtil/service/confirm";

export default {
  name: "MobilePreviewAlim",
  components: { swiper, swiperSlide },
  props: {
    alimSendData: Object,
    tmplt: Object,
    fileList: Array,
    title: String,
    ad: Boolean,
    msg: String,
    adBottom: String,
    authNumber: String,
    reqProduct: String,
    today: String,
    callbackNumber: String,
    recentSendMessageList: Array,
    recentSendAlimList: Array,
    modiAuth: {
      type: Boolean,
      default: true,
    },
    adMsg: String,
  },
  data() {
    const vm = this;
    return {
      // 미리보기 화면 swiper
      swiperOption: {
        slidesPerView: 1,
        spaceBetween: 10,
        observer: true,
        observeParents: true,
        pagination: {
          el: ".m_preview_alimtalk_pagination",
          clickable: true,
        },
      },
      swiperOption2: {
        slidesOffsetBefore: 0, // slidesOffsetBefore는 첫번째 슬라이드의 시작점에 대한 변경할 때 사용
        slidesOffsetAfter: 0, // slidesOffsetAfter는 마지막 슬라이드 시작점 + 마지막 슬라이드 너비에 해당하는 위치의 변경이 필요할 때 사용
        //freeMode: true, // freeMode를 사용시 스크롤하는 느낌으로 구현 가능
        centerInsufficientSlides: true, // 컨텐츠의 수량에 따라 중앙정렬 여부를 결정함
        loop: false,
        direction: "vertical",
        slidesPerView: 3,
        spaceBetween: 12,
        // Navigation arrows
        navigation: {
          nextEl: ".swiper-alim-button-prev",
          prevEl: ".swiper-alim-button-next",
        },
        observer: true,
        observeParents: true,
        on: {
          click: function () {
            if (vm.modiAuth) {
              const clickedIndex = this.clickedIndex;
              vm.handleAlimClickSlide(clickedIndex);
            }
          },
        },
      },
      swiperOption3: {
        slidesOffsetBefore: 0, // slidesOffsetBefore는 첫번째 슬라이드의 시작점에 대한 변경할 때 사용
        slidesOffsetAfter: 0, // slidesOffsetAfter는 마지막 슬라이드 시작점 + 마지막 슬라이드 너비에 해당하는 위치의 변경이 필요할 때 사용
        //freeMode: true, // freeMode를 사용시 스크롤하는 느낌으로 구현 가능
        centerInsufficientSlides: true, // 컨텐츠의 수량에 따라 중앙정렬 여부를 결정함
        loop: false,
        direction: "vertical",
        slidesPerView: 3,
        spaceBetween: 12,
        // Navigation arrows
        navigation: {
          nextEl: ".swiper-button-prev",
          prevEl: ".swiper-button-next",
        },
        observer: true,
        observeParents: true,
        on: {
          click: function () {
            if (vm.modiAuth) {
              const clickedIndex = this.clickedIndex;
              vm.handleSmsClickSlide(clickedIndex);
            }
          },
        },
      },
      swiperFileOption: {
        slidesPerView: "auto",
        spaceBetween: 6, // swiper-slide 사이의 간격 지정
        slidesOffsetBefore: 0, // slidesOffsetBefore는 첫번째 슬라이드의 시작점에 대한 변경할 때 사용
        slidesOffsetAfter: 0, // slidesOffsetAfter는 마지막 슬라이드 시작점 + 마지막 슬라이드 너비에 해당하는 위치의 변경이 필요할 때 사용
        //freeMode: true, // freeMode를 사용시 스크롤하는 느낌으로 구현 가능
        centerInsufficientSlides: true, // 컨텐츠의 수량에 따라 중앙정렬 여부를 결정함
        observer: true,
        observeParents: true,
        pagination: {
          el: ".swiper-pagination",
          clickable: true,
          renderBullet: function (index, className) {
            return '<span class="' + className + '">' + (index + 1) + "</span>";
          },
        },
      },
    };
  },
  computed: {
    // 미리보기 화면 줆바꿈 띄어쓰기 가능하도록 변경
    previewTitle() {
      return this.title ? this.title.split(" ").join("&nbsp;").split("\n").join("<br/>") : "";
    },
    previewMsg() {
      return this.msg ? this.msg.split(" ").join("&nbsp;").split("\n").join("<br/>") : "";
    },
    buttonList() {
      return this.tmplt.tmpltJsonDat ? JSON.parse(this.tmplt.tmpltJsonDat) : [];
    },
  },
  created() {},
  mounted() {},
  methods: {
    mPreAlimClose() {
      const mAlimPreview = document.querySelector(".m_preview_alimtalk");
      const dimmed = document.querySelector(".dimmed");

      mAlimPreview.classList.remove("on");
      dimmed.classList.remove("open");
    },
    handleAlimClickSlide(index) {
      eventBus.$on("callbackEventBus", () => {
        this.fnSendAlimData(index);
      });
      // confirm.fnConfirm("최근작성문자 가져오기", "최근 작성문자로 기존 내용에 덮어 씌워집니다. <br/>계속 진행하시겠습니까?", "confirm");
      confirm.fnConfirm("", "최근 작성문자로 기존 내용에 덮어 씌워집니다. <br/>계속 진행하시겠습니까?", "confirm");
    },
    fnSendAlimData(index) {
      //console.log(this.recentSendAlimList[index]);
      this.$emit("recentSendAlimList", this.recentSendAlimList[index]);
      this.mPreAlimClose();
    },
    handleSmsClickSlide(index) {
      eventBus.$on("callbackEventBus", () => {
        this.fnSendSmsData(index);
      });
      // confirm.fnConfirm("최근작성문자 가져오기", "최근 작성문자로 기존 내용에 덮어 씌워집니다. <br/>계속 진행하시겠습니까?", "confirm");
      confirm.fnConfirm("", "최근 작성문자로 기존 내용에 덮어 씌워집니다. <br/>계속 진행하시겠습니까?", "confirm");
    },
    fnSendSmsData(index) {
      //console.log(this.recentSendMessageList[index]);
      this.$emit("recentSendAlimList", this.recentSendMessageList[index]);
      this.mPreAlimClose();
    },
  },
};
</script>
