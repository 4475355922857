<template>
  <header class="header_wrap">
    <div class="header">
      <div class="logo">
        <router-link to="/"></router-link>
      </div>
      <button class="btn_mn" @click="mnToggle()"></button>
      <div class="tm_wrap">
        <ul class="tm">
          <li class="tml01" v-for="(item, i) in treeData" :key="i" :class="{ on: $route.path.includes(item.menuUrl) }">
            <router-link :to="item.menuUrl" class="tma01">{{ item.menuNm }}</router-link>
            <div class="depth2_wrap" :style="gnbShow ? 'display:none' : 'display:block'">
              <ul class="depth2">
                <template v-for="(childern, j) in item.children">
                  <li class="tml02" v-if="subSttusCd !== '00' && subSttusCd !== '01'" :key="j" :class="{ on: $route.path == childern.menuUrl }" @click="fnGnbShow(childern.menuUrl)">
                    <router-link :to="childern.menuUrl" class="tma02">{{ childern.menuNm }}</router-link>
                  </li>
                  <li class="tml02" v-else-if="childern.menuNo !== '1103'" :key="'_' + j" :class="{ on: $route.path == childern.menuUrl }" @click="fnGnbShow(childern.menuUrl)">
                    <router-link :to="childern.menuUrl" class="tma02">{{ childern.menuNm }}</router-link>
                  </li>
                </template>
              </ul>
            </div>
          </li>
        </ul>

        <!-- 모바일 -->
        <div class="mo_nav">
          <div class="nav_top">
            <div class="home">
              <router-link to="/" class="logo"><span></span></router-link>
            </div>
            <button class="btn_close" @click="mnClose()"></button>
          </div>
          <div class="greeting">
            <p class="name" v-if="isAuth">{{ userNm }}({{ userId | textLengthCut(8, "…") }})</p>
            안녕하세요?
            <p class="login" v-if="!isAuth" :style="!isAuth ? 'display:block' : ''"><router-link to="/login">로그인</router-link></p>
          </div>

          <div class="price_wrap" @click="mnClose()">
            <router-link v-if="chargeNm != '자유요금제'" to="/myInfo/dashBoard" class="price_link">
              <div class="nav_icon nav_icon000"></div>
              <p>
                <span class="stxt">남은 금액</span>
                <template v-if="isAuth">
                  <span class="pink" v-if="subSttusCd === '00' || subSttusCd === '01'">서비스 체험</span>
                  <span class="pink" v-else-if="subSttusCd === '03'">이용 중지</span>
                  <span class="pink" v-else-if="subSttusCd === '04'">미납 정지</span>
                  <!-- <span class="pink" v-else-if="subSttusCd === '02' && openDay">쓴 만큼(또는 종량제)</span> -->
                  <span class="pink" v-else>{{ remainingAmt ? remainingAmt : "-" | formatPrice }}원</span>
                </template>
                <template v-if="!isAuth">
                  <span class="pink">없음</span>
                </template>

                <span class="stxt" style="margin-top: 15px">사용한 금액</span>
                <span class="pink" v-if="isAuth">{{ usedAmt ? usedAmt : "-" | formatPrice }}원</span>
                <span class="pink" v-if="!isAuth">없음</span>
              </p>
              <span class="arrow"></span>
            </router-link>
            <router-link v-else to="/myInfo/dashBoard" class="price_link">
              <div class="nav_icon nav_icon000"></div>
              <p>
                <span class="stxt" style="margin-top: 15px">사용한 금액</span>
                <template v-if="isAuth">
                  <span class="pink" v-if="subSttusCd === '00' || subSttusCd === '01'">서비스 체험</span>
                  <span class="pink" v-else-if="subSttusCd === '03'">이용 중지</span>
                  <span class="pink" v-else-if="subSttusCd === '04'">미납 정지</span>
                  <!-- <span class="pink" v-else-if="subSttusCd === '02' && openDay">쓴 만큼(또는 종량제)</span> -->
                </template>
                <span class="pink" v-if="isAuth">{{ usedAmt ? usedAmt : "-" | formatPrice }}원</span>
                <span class="pink" v-if="!isAuth">없음</span>
              </p>
              <span class="arrow"></span>
            </router-link>
          </div>
          <!-- gnb1 -->
          <div class="mo_gnb1_wrap">
            <ul class="mo_gnb1">
              <li class="gnbl11" v-for="(item, i) in treeData" :key="i">
                <button class="gnba11" @click="navMenuOn">
                  <div class="nav_icon" :class="'nav_icon00' + (i + 1)"></div>
                  <p>{{ item.menuNm }}</p>
                  <span class="arrow"></span>
                </button>
                <div class="depth2_wrap">
                  <ul class="depth2">
                    <template v-for="(childern, j) in item.children">
                      <li v-if="subSttusCd !== '00' && subSttusCd !== '01'" class="gnba12" :key="j" @click="mnClose()">
                        <router-link :to="childern.menuUrl" class="tma02">{{ childern.menuNm }}</router-link>
                      </li>
                      <li v-else-if="childern.menuNo !== '1103'" class="gnba12" :key="'_' + j" @click="mnClose()">
                        <router-link :to="childern.menuUrl" class="tma02">{{ childern.menuNm }}</router-link>
                      </li>
                    </template>
                  </ul>
                </div>
              </li>
            </ul>
          </div>
          <!-- lnb  -->
          <div class="mo_lnb_wrap">
            <ul class="">
              <template v-for="(item, i) in ltreeData">
                <li v-if="subSttusCd !== '00' && subSttusCd !== '01' && ((item.menuNo !== '2000') || (joinDiv == '02' && item.menuNo == '2000'))" class="mo_lnb mo_lnb01" :key="i">
                  <button class="gnba11" @click="navMenuOn">
                    <div class="nav_icon" :class="'nav_icon00' + (i + 4)"></div>
                    <p>{{ item.menuNm }}</p>
                    <span class="arrow"></span>
                  </button>
                  <div class="depth2_wrap">
                    <ul class="depth2">
                      <li class="nav_contl02" v-for="(childern, j) in item.children" :key="j" @click="mnClose()">
                        <router-link :to="childern.menuUrl" class="nav_conta02">{{ childern.menuNm }}</router-link>
                      </li>
                    </ul>
                  </div>
                </li>
                <li v-else-if="item.menuNo !== '1800' && item.menuNo !== '2000'" class="mo_lnb mo_lnb01" :key="'_' + i">
                  <button class="gnba11" @click="navMenuOn">
                    <div class="nav_icon" :class="'nav_icon00' + (i + 4)"></div>
                    <p>{{ item.menuNm }}</p>
                    <span class="arrow"></span>
                  </button>
                  <div class="depth2_wrap">
                    <ul class="depth2">
                      <li class="nav_contl02" v-for="(childern, j) in item.children" :key="j" @click="mnClose()">
                        <router-link :to="childern.menuUrl" class="nav_conta02">{{ childern.menuNm }}</router-link>
                      </li>
                    </ul>
                  </div>
                </li>
              </template>
            </ul>
          </div>

          <!-- gnb2 -->
          <div class="mo_gnb2_wrap">
            <ul class="mo_gnb2">
              <li class="gnbl21">
                <button class="gnba11">
                  <div class="nav_icon nav_icon010"></div>
                  <p>고객지원</p>
                  <span class="arrow"></span>
                </button>
                <div class="depth2_wrap">
                  <ul class="depth2">
                    <li class="gnba21" @click="mnClose()"><router-link to="/serviceCenter/notice" class="tma02">공지사항</router-link></li>
                    <li class="gnba21" @click="mnClose()"><router-link to="/serviceCenter/faq" class="tma02">자주하는 질문</router-link></li>
                    <li class="gnba21" @click="mnClose()"><router-link to="/serviceCenter/consulting" class="tma02">상담하기</router-link></li>
                  </ul>
                </div>
              </li>
            </ul>
          </div>
          <!-- 로그아웃 -->
          <div class="mo_gnb3_wrap">
            <ul class="mo_gnb3">
              <li class="gnba31 logout" v-if="isAuth" :style="isAuth ? 'display:block' : ''"><a @click="fnLogOut">로그아웃</a></li>
              <li class="gnba31 join" v-if="!isAuth" :style="!isAuth ? 'display:block' : ''">
                <router-link to="/login/registGuide" class="tma02">회원가입</router-link>
              </li>
              <!-- <li class="gnba31 login" v-if="!isAuth" :style="!isAuth ? 'display:block' : ''"><router-link to="/login">로그인</router-link></li> -->
            </ul>
          </div>
        </div>
      </div>

      <div class="hr_wrap">
        <ul class="hr">
          <li v-if="!isAuth" class="tml01 hr_user">
            <a href="#" class="tma01">로그인<span class="arrow"></span></a>
            <div class="depth2_wrap">
              <ul class="depth2">
                <li class="tml02" style="padding-left: 11px"><router-link to="/login" class="tma02">로그인</router-link></li>
                <li class="tml02" style="padding-left: 11px">
                  <router-link to="/login/registGuide" class="tma02">회원가입</router-link>
                </li>
              </ul>
            </div>
          </li>
          <li v-if="isAuth" class="tml01 hr_user">
            <a href="#" class="tma01">내 정보 <span class="arrow"></span></a>
            <div class="depth2_wrap">
              <ul class="depth2">
                <li class="tml02" @click="fnLogOut"><span class="tma02">로그아웃</span></li>
                <li class="tml02" :class="{ on: $route.path == '/myInfo/dashBoard' }"><router-link to="/myInfo/dashBoard" class="tma02">대시보드</router-link></li>
                <li class="tml02" :class="{ on: $route.path == '/myInfo/memInfo' }"><router-link to="/myInfo/memInfo" class="tma02">회원정보수정</router-link></li>
              </ul>
            </div>
          </li>
          <li class="tml01">
            <button class="tma01">고객지원</button>
            <div class="depth2_wrap">
              <ul class="depth2">
                <li class="tml02" :class="{ on: $route.path == '/serviceCenter/notice' }"><router-link to="/serviceCenter/notice" class="tma02">공지사항</router-link></li>
                <li class="tml02" :class="{ on: $route.path == '/serviceCenter/faq' }"><router-link to="/serviceCenter/faq" class="tma02">자주하는 질문</router-link></li>
                <li class="tml02" :class="{ on: $route.path == '/serviceCenter/consulting' }"><router-link to="/serviceCenter/consulting" class="tma02">상담하기</router-link></li>
                <!--li class="tml02"><a href="https://msghub.uplus.co.kr/" class="tma02" target="_blank">메시지허브</a></li-->
              </ul>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </header>
</template>
<script>
import { mapGetters } from "vuex";
import loginApi from "@/modules/login/service/loginApi";
import confirm from "@/modules/commonUtil/service/confirm";
import { eventBus } from "@/modules/commonUtil/service/eventBus";

export default {
  data() {
    return {
      treeData: [],
      ltreeData: [],
      myMenuList: [],
      isAuth: false,
      isActive: true,
      link: "",
      show: true,
      gnbShow: false,
    };
  },
  props: {
    menuList: Array,
    remainingAmt: Number,
    pricePlanBalance: Number,
    cFwdBalance: Number,
    chrgBalance: Number,
    usedAmt: Number,
    pricePlanUsedAmt: Number,
    cFwdBalanceUsedAmt: Number,
    addUsedAmt: Number,
    chrgAmt: Number,
    userNm: String,
    subSttusCd: String,
    openDay: Boolean,
    userId: {
      type: String,
      default: "",
    },
    joinDiv: String,
    chargeNm: String
  },
  created() {},
  computed: {
    ...mapGetters({
      getIsAuth: "login/isAuth",
    }),
  },
  watch: {
    getIsAuth(data) {
      if (data != null && data != "" && data == true) {
        this.isAuth = true;
      } else {
        this.isAuth = false;
      }
    },
    menuList: function (data) {
      this.myMenuList = data;
      this.fnMenuList();
    },
  },
  mounted() {
    this.authView();
    let winW = window.innerWidth;
    window.addEventListener("resize", () => {
      winW = window.innerWidth;
      if (winW > 896) {
        this.tmCenter();
      }
    });
  },
  methods: {
    authView() {
      const isAuth = this.$store.getters["login/isAuth"];
      if (!isAuth) {
        this.show = false;
      }
    },
    fnMenuList() {
      const isAuth = this.$store.getters["login/isAuth"];
      if (isAuth) {
        this.isAuth = true;
      } else {
        this.isAuth = false;
      }
      var treeList = [];
      var ltreeList = [];

      this.myMenuList.forEach(function (tree) {
        if (tree.menuLvl == "1" && tree.menuGubun == "G") {
          treeList.push(tree);
        }
        if (tree.menuLvl == "1" && tree.menuGubun == "L") {
          ltreeList.push(tree);
        }
      });
      this.treeData = treeList;
      this.ltreeData = ltreeList;
      this.tmCenter();
    },
    fnLogOut() {
      eventBus.$on("callbackEventBus", this.logout);
      confirm.fnConfirm("", "로그아웃하시겠어요?", "logout");
    },
    logout() {
      this.$store.commit("login/isAuth", false);
      this.$store.commit("hubEzData", []);
      loginApi.logout().then((response) => {
        if (response.data.success) {
          if (!(this.$router.history.current.fullPath.indexOf("/main") > -1)) {
            this.$router.push({
              path: "/",
            });
          }
        }
      });
    },
    // gnb 버튼
    mnToggle() {
      const tmWrap = document.querySelector(".tm_wrap"),
        dimmed = document.querySelector(".dimmed");

      tmWrap.classList.add("on");
      dimmed.classList.add("open");
    },
    mnClose() {
      const tmWrap = document.querySelector(".tm_wrap"),
        dimmed = document.querySelector(".dimmed");

      tmWrap.classList.remove("on");
      dimmed.classList.remove("open");
    },
    //tm_wrap center
    tmCenter() {
      const tmWrap = document.querySelector(".tm_wrap");
      var contents = document.querySelector(".contents"),
        conL = contents.offsetLeft,
        conW = contents.clientWidth;
      tmWrap.style.left = conW * 0.5 + conL + "px";
    },
    navMenuOn(e) {
      const gnbBtn = document.getElementsByClassName("gnba11");
      let panel = e.currentTarget.nextElementSibling;
      if (e.currentTarget.classList.contains("active")) {
        e.currentTarget.classList.remove("active");
        e.currentTarget.parentElement.classList.remove("on");
        panel.style.maxHeight = null;
        return false;
      }

      // 클릭이 일어났을 때 기존에 열려 있던 아코디언을 접는다. (1개의 아코디언만 열리게)
      for (var j = 0; j < gnbBtn.length; j++) {
        // 버튼 상태에 입혀진 active 라는 클래스를 지운다.
        gnbBtn[j].classList.remove("active");
        // 버튼 다음에 있는 div 콘텐츠 높이를 0으로 만든다. == 아코디언을 접는다.
        if (this !== gnbBtn[j]) {
          gnbBtn[j].nextElementSibling.style.maxHeight = null;
          gnbBtn[j].parentElement.classList.remove("on");
        }
      }
      e.currentTarget.classList.add("active");
      e.currentTarget.parentElement.classList.add("on");
      panel.style.maxHeight = panel.scrollHeight + "px";
    },
    fnGnbShow(routePath) {
      if (routePath == this.$route.path && routePath.indexOf("/messagesend") > -1) {
        eventBus.$on("callbackEventBus", this.fnInit);
        confirm.fnConfirm("", "모든 내용이 삭제됩니다.", "confrim");
      }
      this.gnbShow = true;
      setTimeout(() => {
        this.gnbShow = false;
      }, 1000);
    },
    fnInit() {
      this.$router.go();
    },
    bizReady() {
      confirm.fnAlert("", "비즈마켓 서비스 신규 오픈 작업으로 이용이 제한됩니다. <br/>(서비스 오픈일 : 2022년 12월 12일)", "alert");
    },
  },
};
</script>
