<template>
  <div>
    <router-view />
    <spinner :loading="$store.state.LoadingStatus" />
  </div>
</template>

<script>
import Spinner from "@/components/LoadingSpinner";
export default {
  components: {
    Spinner,
  },
};
</script>
