import Vue from "vue";
import VueRouter from "vue-router";
import PublicView from "../layout/PublicLayout.vue";
//import MainView from "../layout/MainLayout.vue";
import LoginView from "../layout/LoginLayout.vue";
import NiceView from "../layout/NiceLayout.vue";
import PopupView from "../layout/PopupLayout.vue";

import main from "../modules/main/router/index";

/*** 상단 메뉴 ***/
import messageSend from "../modules/messageSend/router/index"; // 메세지 전송
import sendMessage from "../modules/sendManage/router/index"; // 전송관리
import serviceInfo from "../modules/serviceInfo/router/index"; // 서비스소개
import serviceCenter from "../modules/servcieCenter/router/index"; // 고객센터

/*** 좌측 메뉴***/
import myInfo from "../modules/myInfo/router/index"; // 내정보
import sendNumberManage from "../modules/sendNumberManage/router/index"; // 발신번호관리
import templeteManage from "../modules/templeteManage/router/index"; // 템플릿관리
import addressManage from "../modules/addressManage/router/index"; //주소록
import sendRejectManage from "../modules/sendRejectManage/router/index"; // 080수신거부
import userManage from "../modules/userManage/router/index"; //  사용자관리
import charge from "../modules/charge/router/index"; //  요금충전

/*** 하단 정책 (이용약관, 요금신고내역, 개인정보처리방침, 스팸정책)  ***/
import policy from "../modules/policy/router/index";
import store from "../store/index";
import loginRoutes from "@/modules/login/router"; // 로그인
import commonUtil from "@/modules/commonUtil/router/index";
import confirm from "@/modules/commonUtil/service/confirm"; // alert / confirm 팝업
//import cookies from "vue-cookies";

/** 로그인 체크 */
import loginApi from "@/modules/login/service/loginApi"; //api호출

/** 사이트 맵 */
import sitemap from "../modules/sitemap/router/index";

import Popup from "../modules/popup/router/index";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    redirect: { path: "/main" },
  },
  {
    path: "/main",
    component: PublicView,
    meta: { public: true },
    children: [...main],
  },
  {
    path: "/messageSend",
    redirect: { path: "/messagesend/sms" },
    component: PublicView,
    children: [...messageSend],
  },
  {
    path: "/sendMessage",
    component: PublicView,
    meta: { public: true },
    children: [...sendMessage],
  },
  {
    path: "/serviceInfo",
    component: PublicView,
    meta: { public: true },
    children: [...serviceInfo],
  },
  {
    path: "/serviceCenter",
    component: PublicView,
    meta: { public: true },
    children: [...serviceCenter],
  },
  {
    path: "/myInfo",
    redirect: { path: "/myInfo/dashBoard" },
    component: PublicView,
    children: [...myInfo],
  },
  {
    path: "/sendNumberManage",
    redirect: { path: "/sendNumberManage/sendNumber" },
    component: PublicView,
    meta: { public: true },
    children: [...sendNumberManage],
  },
  {
    path: "/templeteManage",
    component: PublicView,
    meta: { public: true },
    children: [...templeteManage],
  },
  {
    path: "/sendRejectManage",
    component: PublicView,
    meta: { public: true },
    children: [...sendRejectManage],
  },
  {
    path: "/userManage",
    component: PublicView,
    meta: { public: true },
    children: [...userManage],
  },
  {
    path: "/charge",
    component: PublicView,
  
    children: [...charge],
  },
  {
    path: "/login",
    component: LoginView,
    meta: { public: true },
    children: [...loginRoutes],
  },
  {
    path: "/addressManage",
    component: PublicView,
    meta: { public: true },
    children: [...addressManage],
  },
  {
    path: "/commonUtil",
    component: NiceView,
    children: [...commonUtil],
  },
  {
    path: "/policy",
    component: PublicView,
    meta: { public: true },
    children: [...policy],
  },
  {
    path: "/sitemap",
    component: PublicView,
    meta: { public: true },
    children: [...sitemap],
  },
  {
    path: "/popup",
    component: PopupView,
    meta: { public: true },
    children: [...Popup],
  },
  {
    path: "/404",
    component: () => import("../layout/ErrorPage404.vue"),
    meta: { public: true },
  },
  {
    path: "/500",
    component: () => import("../layout/ErrorPage500.vue"),
    meta: { public: true },
  },
  {
    path: "/errorWork",
    component: () => import("../layout/ErrorWorkPage.vue"),
    meta: { public: true },
  },
  //설정되지 않는 Route 장애 처리
  {
    path: "/:catchAll(.*)",
    component: () => import("../layout/ErrorPage404.vue"),
    meta: { public: true },
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
  routes,
});

router.beforeEach(async function (to, from, next) {
  let subSttusCd = "";

  //let workState = cookies.get("workState");
  //if (workState == "" || workState == null || workState == undefined){
    if (!(to.path.indexOf("/errorWork") > -1) && !(to.path.indexOf("/500") > -1) && !(to.path.indexOf("/404") > -1 )) {
      await loginApi.workStateCheck().then((response) => {
        var result = response.data;
        if (result.success) {
          if(response.data.data.workState == "NOTUSE"){
            router.push({path: "/errorWork"});
            store.commit("endSpinner");
          }
          /*else{
            let date = new Date();
            date.setHours(date.getHours() + 8);
            cookies.set("workState", "Y", date);
          }*/
        } else {
          confirm.fnAlert("", "오류가 발생하였습니다.", "alert");
        }
      });
    }
  //}

  if (!(to.path.indexOf("/login") > -1) && !(to.path.indexOf("/errorWork") > -1) && !(to.path.indexOf("/500") > -1) && !(to.path.indexOf("/404") > -1  )) {
    await loginApi.selectAutInfo().then((response) => {
      var result = response.data;
      if (result.success) {
        if (result.data.autCd !== "H003") {
          store.commit("login/isAuth", true);
          subSttusCd = result.data.subSttusCd;
        } else {
          store.commit("login/isAuth", false);
        }
      } else {
        confirm.fnAlert("", "오류가 발생하였습니다.", "alert");
      }
    });
  }

  const isAuth = store.getters["login/isAuth"];
  const isPublic = to.matched.some((record) => record.meta.public);
  store.commit("startSpinner");

  if (!isPublic) {
    if (to.path !== "/login") {
      if (!isAuth) {
        store.commit("endSpinner");
        confirm.fnAlert("알림", "로그인 후 이용 가능합니다.", "alert");
      } else {
        setTimeout(() => {
          next();
        }, 1);
      }
    } else {
      setTimeout(() => {
        next();
      }, 1);
    }
  } else {
    if (to.path == "/userManage" || to.path == "/sendManage/sendMessageStats") {
      if (subSttusCd == "00" || subSttusCd == "01") {
        store.commit("endSpinner");
        confirm.fnAlert("", "유큐브 개통 후 이용 가능합니다.", "alert");
      } else {
        setTimeout(() => {
          next();
        }, 1);
      }
    } else if (to.path == "/login/passWdResetTemp") {
      if (!isAuth) {
        store.commit("endSpinner");
        router.push({
          path: "/login",
        });
      } else {
        setTimeout(() => {
          next();
        }, 1);
      }
    } else {
      setTimeout(() => {
        next();
      }, 1);
    }
  }

  // to: 이동할 url에 해당하는 라우팅 객체
  /* if (to.matched.some(function(routeInfo) {
      return routeInfo.meta.authRequired;
    })) {
      // 이동할 페이지에 인증 정보가 필요하면 경고 창을 띄우고 페이지 전환은 하지 않음
      alert('Login Please!');
    } else {
      console.log("routing success : '" + to.path + "'");
      next(); // 페이지 전환
    }; */
});

router.afterEach(() => {
  store.commit("endSpinner");
});

export default router;
