<template>
  <!--	s: m_section	-->
  <section class="m_section">
    <!--	s: 	contents-->
    <div class="contents">
      <MobileGnb />
      <div class="tabmenu_wrap">
        <ul class="tabmenu2">
          <li class="tabl on"><span>전송 메시지 내역</span></li>
          <li class="tabl" @click="fnMoveTab('rsv')"><span>예약 메시지 내역</span></li>
          <li class="tabl" @click="fnMoveTab('stat')"><span>사용자 전송 통계</span></li>
        </ul>
      </div>
      <div class="tabcont_wrap send_wrap">
        <div class="tabcont on">
          <h1 class="title with_desc">전송 메시지 내역</h1>
          <div class="tabcontTop">
            <ul>
              <li>최근 1년 동안 보낸 문자메시지만 조회할 수 있습니다.</li>
              <li>전송 성공 건만 요금이 청구되며, 전송 실패 건은 요금이 청구되지 않습니다.</li>
            </ul>
          </div>
          <!-- s: search_box -->
          <div class="search_box">
            <div class="line">
              <div class="group group01">
                <label for="right" class="label">날짜</label>
                <div class="date_wrap">
                  <input
                    type="date"
                    :min="this.$gfnCommonUtils.strDateAddDaySrch(this.$gfnCommonUtils.getCurretDate(), -12)"
                    :max="this.$gfnCommonUtils.getCurretDate()"
                    class="date"
                    v-model="reqDtFirst"
                    @click="fndatePick"
                    @change="fnSendMessageSearch"
                  /><span></span>
                  <input
                    type="date"
                    :min="this.$gfnCommonUtils.strDateAddDaySrch(this.reqDtFirst, 0)"
                    :max="this.$gfnCommonUtils.getCurretDate()"
                    class="date"
                    v-model="reqDtLast"
                    @click="fndatePick"
                    @change="fnSendMessageSearch"
                  />
                </div>
              </div>
              <div class="group group02">
                <label for="right" class="label"></label>
                <div class="radio_wrap">
                  <ul>
                    <li><input type="radio" class="radio" v-model="dateRange" id="week" value="week" @change="fnRagnge" /><label for="week">1주일</label></li>
                    <li><input type="radio" class="radio" v-model="dateRange" id="days15" value="halfmon" @change="fnRagnge" /><label for="days15">15일</label></li>
                    <li><input type="radio" class="radio" v-model="dateRange" id="month" value="month" @change="fnRagnge" /><label for="month">1개월</label></li>
                  </ul>
                </div>
              </div>
            </div>
            <div class="line">
              <div class="group group03">
                <label for="right" class="label">문자메시지 종류</label>
                <div class="select_wrap">
                  <select v-model="chStr" class="select" @change="fnRagnge">
                    <option value="" selected>문자메시지 종류 전체</option>
                    <option value="SMS">단문 메시지(SMS)</option>
                    <option value="LMS">장문 메시지(LMS)</option>
                    <option value="MMS">사진/영상 메시지(MMS)</option>
                    <option value="KKO_NOTI">알림톡</option>
                  </select>
                </div>
              </div>
              <div class="group group04">
                <label for="right" class="label">제목 / 카카오 비즈메시지 템플릿명 / 내용</label>
                <div class="search_wrap">
                  <input type="text" v-model="srchStr" class="search2" placeholder="제목, 템플릿명, 내용 중 하나를 입력하세요." @keypress.enter="fnSendMessageSearch" />
                </div>
              </div>
              <div class="group group05">
                <label for="right" class="label"></label>
                <button class="btn" @click="fnSendMessageSearch">조회</button>
              </div>
            </div>
          </div>
          <!-- e: search_box -->
          <!-- s: list_area -->
          <div class="list_area">
            <div class="list_info">
              <div class="left_wrap">
                <div class="select_wrap">
                  <select v-model="selected" @change="fnSelected" class="select">
                    <option value="30" selected>30</option>
                    <option value="50">50</option>
                    <option value="100">100</option>
                  </select>
                </div>
                <p class="total">
                  전체 <span class="pink">{{ totCnt }} </span>건
                </p>
              </div>
              <button class="btn white download" @click="excelDownLoadSelect">엑셀 파일로 내려받기</button>
            </div>

            <div class="fixed-table-cover">
              <div class="fixed-table-container row2" style="width: 1074px">
                <div class="fixed-table-header-bg"></div>
                <div class="fixed-table-wrapper">
                  <table class="fixed-table" style="table-layout: fixed">
                    <thead>
                      <tr>
                        <th class="td_date" style="width: 16%">
                          <div class="th-text">전송 일자</div>
                        </th>
                        <th class="td_sender" style="width: 8%">
                          <div class="th-text">전송자 명</div>
                        </th>
                        <th class="td_name" style="width: 15%">
                          <div class="th-text">제목(템플릿명)</div>
                        </th>
                        <th class="td_type" style="width: 7%">
                          <div class="th-text">문자메시지<br />종류</div>
                        </th>
                        <th class="td_call" style="width: 15%">
                          <div class="th-text">발신번호(채널아이디)</div>
                        </th>
                        <th class="td_count" style="width: 6%">
                          <div class="th-text">전송 건수</div>
                        </th>
                        <th class="td_alt" style="width: 12%">
                          <div class="th-text row">
                            <p>전송 결과</p>
                            <div class="col">
                              <span class="td_alt_success">성공 건수</span>
                              <span class="td_alt_fail">실패 건수</span>
                            </div>
                          </div>
                        </th>
                        <th class="td_alt" style="width: 13%">
                          <div class="th-text row">
                            <p>일반문자 전환 전송</p>
                            <div class="col">
                              <span class="td_alt_success">성공 건수</span>
                              <span class="td_alt_fail">실패 건수</span>
                            </div>
                          </div>
                        </th>
                        <th class="td_wait" style="width: 4%">
                          <div class="th-text">대기</div>
                        </th>
                        <th class="td_wait" style="width: 4%">
                          <div class="th-text">상세 내역</div>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-if="totCnt == 0" class="empty">
                        <td colspan="10">검색 결과가 없습니다.</td>
                      </tr>
                      <tr v-for="(data, index) in sendMsgList" :key="index">
                        <td class="td_date">{{ data.reqDt }}</td>
                        <td class="td_sender">
                          <p class="ellipsis">{{ data.userNm }}</p>
                        </td>
                        <td class="td_name">
                          <p class="ellipsis">{{ data.title | emptyString("제목없음") }}</p>
                        </td>
                        <td class="td_type">{{ data.chStrNm }}</td>
                        <td class="td_call">{{ data.callback }}</td>
                        <td class="td_count">{{ data.sendCnt }}</td>
                        <td class="td_alt_success">
                          <div class="row">
                            <div class="col">
                              <span class="td_alt_success blue">{{ data.succCnt }}</span>
                              <span class="td_alt_fail red">{{ data.failCnt }}</span>
                            </div>
                          </div>
                        </td>
                        <td class="td_alt_fail blue">
                          <div class="row">
                            <div class="col">
                              <span class="td_alt_success blue">{{ data.fbSuccCnt }}</span>
                              <span class="td_alt_fail red">{{ data.fbFailCnt }}</span>
                            </div>
                          </div>
                        </td>
                        <td class="td_wait">{{ data.waitCnt }}</td>
                        <td class="td_detail"><router-link :to="{ name: 'sendMessageDetail', params: { webReqId: data.webReqId } }" class="btn white search"></router-link></td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>

            <!--
            <div class="list_cover">
              <div class="list_th_wrap" @scroll="handleThScroll($event)">
                <ul class="list_th">
                  <li class="td_date w175"><span>전송 일자</span></li>
                  <li class="td_sender w80"><span>전송자 명</span></li>
                  <li class="td_name w157"><span>제목(템플릿명)</span></li>
                  <li class="td_type w75">
                    <span style="line-height: 1.3">문자메시지<br />종류</span>
                  </li>
                  <li class="td_call w140"><span>발신번호(채널아이디)</span></li>
                  <li class="td_count w60"><span>전송 건수</span></li>
                  <li class="td_alt w130 row">
                    <p><span>전송 결과</span></p>
                    <div class="col">
                      <span class="td_alt_success w65">성공 건수</span>
                      <span class="td_alt_fail w65">실패 건수</span>
                    </div>
                  </li>
                  <li class="td_alt w135 row">
                    <p><span>일반문자 전환 전송</span></p>
                    <div class="col">
                      <span class="td_alt_success w65">성공 건수</span>
                      <span class="td_alt_fail w65">실패 건수</span>
                    </div>
                  </li>
                  <li class="td_wait w55"><span>대기</span></li>
                  <li class="td_detail w60"><span>상세 내역</span></li>
                </ul>
              </div>
              <div class="list_wrap scr_wrap" @scroll="handleScroll($event)">
                <ul class="list list2">
                  <li v-if="totCnt == 0" class="empty">
                    <p>검색 결과가 없습니다.</p>
                  </li>
                  <li v-for="(data, index) in sendMsgList" :key="index">
                    <span class="td_date w175">{{ data.reqDt }}</span>
                    <span class="td_sender w80">
                      <p class="ellipsis">{{ data.userNm }}</p></span
                    >
                    <span class="td_name w157">
                      <p class="ellipsis">{{ data.title | emptyString("제목없음") }}</p></span
                    >
                    <span class="td_type w75">{{ data.chStrNm }}</span>
                    <span class="td_call w140">{{ data.callback }}</span>
                    <span class="td_count w60">{{ data.sendCnt }}</span>
                    <span class="td_success w65 blue">{{ data.succCnt }}</span>
                    <span class="td_fail w65 red">{{ data.failCnt }}</span>
                    <span class="td_alt_success w65 blue">{{ data.fbSuccCnt }}</span>
                    <span class="td_alt_fail w65 red">{{ data.fbFailCnt }}</span>
                    <span class="td_wait w55">{{ data.waitCnt }}</span>
                    <span class="td_detail w60"><router-link :to="{ name: 'sendMessageDetail', params: { webReqId: data.webReqId } }" class="btn white search"></router-link></span>
                  </li>
                </ul>
              </div>
            </div> -->
            <div class="paging_wrap" v-show="totCnt > listSize">
              <!-- 페이징 컴퍼넌트 -->
              <PageLayer ref="updatePaging" @fnClick="fnSearch" :listTotalCnt="totCnt" :selected="listSize" :pageNum="pageNo"></PageLayer>
            </div>
          </div>
          <!-- e: list_area -->
        </div>
      </div>
    </div>
    <!--	e: 	contents	-->
  </section>
  <!--	e: m_section	-->
</template>
<script>
import * as XLSX from "xlsx";
import store from "@/store";
import sendManageApi from "@/modules/sendManage/service/sendManageApi";
import confirm from "@/modules/commonUtil/service/confirm";
import PageLayer from "@/components/PageLayer.vue";
import MobileGnb from "@/components/MobileGnb.vue";

export default {
  name: "sendMessage",
  components: {
    PageLayer,
    MobileGnb,
  },
  props: {
    autCd: String,
  },
  data() {
    return {
      userDiv: true,
      selected: "30",
      listSize: 30, // select 박스 value (출력 갯수 이벤트)
      pageNo: 1, // 현재 페이징 위치
      totCnt: 0, //전체 리스트 수
      offset: 0, //페이지 시작점,
      reqDtFirst: this.$gfnCommonUtils.getCurretDate(), //검색 시작일
      reqDtLast: this.$gfnCommonUtils.getCurretDate(), //검색 종료일
      chStr: "", //검색 메세지 타입
      chStrNm: "",
      srchStr: "", //검색 템플릿명 내용
      dateRange: "", //검색 날짜 범위
      sendMsgList: [], //전송내역 리스트 초기 변수
      excelList: [], //전송내역 엑셀 리스트 초기 변수
      // 엑셀 다운로드 필요 변수
      header: [],
      headProps: ["reqDt", "userNm", "title", "chStrNm", "callback", "sendCnt", "succCnt", "failCnt", "fbSuccCnt", "fbFailCnt", "waitCnt"],
      headRow1: [
        { key: "reqDt", name: "전송일자", colspan: 1, rowspan: 2 },
        { key: "userNm", name: "전송자명", colspan: 1, rowspan: 2 },
        { key: "title", name: "제목(템플릿명)", colspan: 1, rowspan: 2 },
        { key: "chStrNm", name: "메시지종류", colspan: 1, rowspan: 2 },
        { key: "callback", name: "발신번호(채널ID)", colspan: 1, rowspan: 2 },
        { key: "sendCnt", name: "전송건수", colspan: 1, rowspan: 2 },
        { key: "", name: "전송결과", colspan: 2, rowspan: 1 },
        { key: "", name: "문자메시지 전환전송", colspan: 2, rowspan: 1 },
        { key: "waitCnt", name: "대기", colspan: 1, rowspan: 2 },
      ],
      headRow2: [
        { key: "succCnt", name: "성공건수", colspan: 1, rowspan: 1 },
        { key: "failCnt", name: "실패건수", colspan: 1, rowspan: 1 },
        { key: "fbSuccCnt", name: "성공건수", colspan: 1, rowspan: 1 },
        { key: "fbFailCnt", name: "실패건수", colspan: 1, rowspan: 1 },
      ],
      visibleHeadProps: [],
      instance: undefined,
      listScrollPostion: 0,
    };
  },
  created() {
    this.fnInit();
  },
  mounted() {},
  methods: {
    fnInit() {
      const isAuth = store.getters["login/isAuth"];
      if (isAuth) {
        if (this.autCd == "H002") {
          this.userDiv = false;
        }
      }
      this.fnSendMessageList();
    },
    fnSendMessageList() {
      const isAuth = store.getters["login/isAuth"];
      if (isAuth) {
        if (this.$gfnCommonUtils.isEmpty(this.reqDtFirst)) {
          confirm.fnAlert("", "검색 시작일을 선택해 주세요.", "alert");
          return false;
        }

        if (this.$gfnCommonUtils.isEmpty(this.reqDtLast)) {
          confirm.fnAlert("", "검색 종료일을 선택해 주세요.", "alert");
          return false;
        }

        if (this.reqDtFirst.replaceAll("-", "") > this.reqDtLast.replaceAll("-", "")) {
          this.reqDtLast = this.reqDtFirst;
          confirm.fnAlert("", "검색 시작일이 종료일보다 클 수 없습니다.", "alert");
          return false;
        }
        /* 2024-01-11 #53277 [기능개선] [허브이지] 전송관리 내 기간 문의
        * 요청으로 인한 6개월 기간 제한 삭제
        if (this.reqDtLast.replaceAll("-", "") > this.$gfnCommonUtils.strDateAddMonthDt(this.reqDtFirst, 6).replaceAll("-", "")) {
          this.reqDtLast = this.$gfnCommonUtils.strDateAddDaySrch(this.reqDtFirst, 6);
          confirm.fnAlert("", "날짜 검색 범위는 6개월 이상을 검색할 수 없습니다.", "alert");
          return false;
        }
        */
        let param = {
          pageNo: this.pageNo, //페이징 필수 값
          listSize: this.listSize, //페이징 필수 값
          reqDtFirst: this.reqDtFirst,
          reqDtLast: this.reqDtLast,
          chStr: this.chStr,
          srchStr: this.srchStr,
        };
        sendManageApi.selectSendMsgStatusList(param).then((response) => {
          var result = response.data;
          if (result.success) {
            this.sendMsgList = result.data.sendWebMsgList; //서버 조회 리스트 셋팅
            this.totCnt = result.data.pageInfo.totCnt; //전체 카운트
            this.offset = result.data.pageInfo.offset; //페이지 번호
          } else {
            confirm.fnAlert("", "오류가 발생하였습니다.", "alert");
          }
        });
      }
    },
    fnSelected() {
      //리스트 갯수 선택
      this.listSize = Number(this.selected);
      this.$refs.updatePaging.fnAllDecrease();
    },
    fnSendMessageSearch() {
      this.$refs.updatePaging.fnAllDecrease();
    },
    fnSearch(pageNum) {
      const isAuth = store.getters["login/isAuth"];
      if (isAuth) {
        // 검색
        this.pageNo = this.$gfnCommonUtils.defaultIfEmpty(pageNum, "1") * 1;
        this.fnSendMessageList();
      }
    },
    fnRagnge() {
      //날짜 검색 범위 변경에 따른 날짜 처리
      if (this.dateRange == "week") {
        this.reqDtFirst = this.$gfnCommonUtils.strDateAddDay(this.reqDtLast, -7);
      }
      if (this.dateRange == "halfmon") {
        this.reqDtFirst = this.$gfnCommonUtils.strDateAddDay(this.reqDtLast, -15);
      }
      if (this.dateRange == "month") {
        this.reqDtFirst = this.$gfnCommonUtils.strDateAddMonthDt(this.reqDtLast, -1);
      }
      this.fnRsvSendMessageSearch();
    },
    fndatePick() {
      //날짜 클릭 시 날짜 범위 선택 라디오 초기화
      this.dateRange = "";
    },
    excelDownLoadSelect() {
      if (!this.totCnt > 0) {
        confirm.fnAlert("", "다운로드할 데이터가 없습니다.", "alert", "");
        return false;
      }
      let param = {
        pageNo: this.pageNo, //페이징 필수 값
        listSize: this.listSize, //페이징 필수 값
        reqDtFirst: this.reqDtFirst,
        reqDtLast: this.reqDtLast,
        chStr: this.chStr,
        srchStr: this.srchStr,
      };
      sendManageApi.selectSendMsgStatusExcelList(param).then((response) => {
        var result = response.data;
        if (result.success) {
          this.excelList = result.data.sendWebMsgExcelList; //서버 조회 엑셀 리스트 셋팅
          this.excelDownload();
        } else {
          confirm.fnAlert("", "오류가 발생하였습니다.", "alert");
        }
      });
    },
    excelDownload() {
      this.header = [];
      this.header.push(this.headRow1);
      this.header.push(this.headRow2);

      let options = {
        header: this.header,
        headProps: this.headProps,
      };
      this.excelExport(this.excelList, options);
    },
    excelExport(data, options) {
      // data row별 header에 바인딩 될 매핑값 설정
      let headProps = [];
      if (Array.isArray(options.headProps)) {
        headProps = options.headProps;
      } else if (options.headProps === "header") {
        for (let h of this.header) {
          headProps.push(h.key);
        }
      } else {
        headProps = Object.keys(data[0]);
      }
      this.visibleHeadProps = headProps;
      this.instance = document.createElement("table");
      // Header 세팅
      let header = [];
      if (!Array.isArray(this.header[0])) {
        header.push(this.header);
      } else {
        header = this.header;
      }

      let thead = document.createElement("thead");
      for (let row of header) {
        let tr = document.createElement("tr");
        for (let h of row) {
          let rowspan = h.rowspan || "1";
          let colspan = h.colspan || "1";
          let th = document.createElement("th");

          th.setAttribute("rowspan", rowspan);
          th.setAttribute("colspan", colspan);
          th.setAttribute("align", "center");
          th.innerText = h.name;
          tr.appendChild(th);
        }
        thead.appendChild(tr);
      }
      this.instance.appendChild(thead);
      // Body 세팅
      let tbody = document.createElement("tbody");
      for (let row of data) {
        let tr = document.createElement("tr");
        for (let cellkey of this.visibleHeadProps) {
          let td = document.createElement("td");
          td.innerText = row[cellkey];
          tr.appendChild(td);
        }
        tbody.appendChild(tr);
      }
      this.instance.appendChild(tbody);
      // instance에 만들어진 table을 엑셀파일로 저장
      let config = { raw: true, type: "string" };
      let ws = XLSX.utils.table_to_sheet(this.instance, config);
      let wb = XLSX.utils.book_new();

      XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
      XLSX.writeFile(wb, "전송내역.xlsx");
    },
    fnMoveTab(type) {
      if (type == "rsv") {
        this.$router.push({ path: "/sendManage/rsvSendMessage" });
      } else if (type == "stat") {
        this.$router.push({ path: "/sendManage/sendMessageStats" });
        /* if (this.userDiv) {
          this.$router.push({ path: "/sendManage/sendMessageStats" });
        } else {
          confirm.fnAlert("", "관리자ID만 접근 가능합니다.", "alert");
          return;
        } */
      } else {
        this.$router.push({ path: "/sendManage/sendMessage" });
      }
    },
    fnRsvSendMessageSearch() {
      this.$refs.updatePaging.fnAllDecrease();
    },
    /*handleScroll(e) {
      const chooseListThWrap = e.target.parentNode.children[0];
      chooseListThWrap.scrollLeft = e.target.scrollLeft;
    },
    handleThScroll(e) {
      const chooseListWrap = e.target.parentNode.children[1];
      chooseListWrap.scrollLeft = e.target.scrollLeft;
    },*/
  },
};
</script>
