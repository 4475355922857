<template>
  <div class="login_wrap">
    <div class="login_box">
      <div class="login_box-logo">
        <a href="javascript:void(0)">uplus 메시지허브이지</a>
        <p>Easy Messaging Service</p>
      </div>
      <div class="login_box-content">
        <h2 class="title with_desc">휴대폰 문자 인증</h2>
        <p class="desc">안전한 서비스 이용을 위해 휴대폰 문자 인증을 진행합니다.<br />아이디에 등록된 휴대폰 번호를 입력해주세요.</p>
        <div class="verification1">
          <label for="smo" class="label">휴대폰 번호</label>
          <input type="text" v-model="cePhone" id="smo" placeholder="‘-’ 없이 숫자만 입력하세요." :disabled="ceNumberDiv" @keyup.enter="fnCertifyPhone" />
          <button type="button" @click="fnCertifyPhone" :disabled="ceNumberDiv">인증번호 받기</button>
        </div>
        <div class="verification2">
          <label for="snum" class="label">인증번호</label>
          <input type="text" v-model="ceNumber" id="snum" placeholder="인증번호를 입력하세요." />
          <span>{{ TimerStr }}</span>
        </div>
        <div class="btn_group">
          <button type="button" class="btn large_pink" @click="fnCertifyNumber">인증</button>
          <button type="button" class="btn large_white" @click="fnLogoutConfirm">취소</button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import confirm from "@/modules/commonUtil/service/confirm";
import { eventBus } from "@/modules/commonUtil/service/eventBus";
import loginApi from "../service/loginApi";

export default {
  name: "certifyPhone",
  data() {
    return {
      cePhone: "",
      ceNumber: "",
      ceNumberDiv: false,
      Timer: null,
      TimeCounter: 180,
      TimerStr: "03분00초",
      failCnt: 0,
    };
  },
  watch: {
    cePhone(val) {
      if (val !== "") {
        let regExp = /^[0-9]+$/;
        if (!regExp.test(val)) {
          confirm.fnAlert("", "휴대폰번호는 숫자만 입력 가능 합니다.", "alert");
          this.cePhone = val.replace(/[^0-9]/g, "");
        }
      }
    },
    ceNumber(val) {
      if (val !== "") {
        let regExp = /^[0-9]+$/;
        if (!regExp.test(val)) {
          confirm.fnAlert("", "인증번호는 숫자만 입력 가능합니다.", "alert");
          this.ceNumber = val.replace(/[^0-9]/g, "");
        }
      }
    },
  },
  created() {
    this.fnInit();
  },
  methods: {
    fnInit() {
      loginApi.firstCertifyConfirm().then((response) => {
        var result = response.data;
        if (!result.success) {
          eventBus.$on("callbackEventBus", this.fnLogout);
          confirm.fnConfirm("", "오류가 발생하였습니다. 로그아웃 처리 됩니다.", "cbAlert");
        }
      });
    },
    fnCertifyPhone() {
      if (this.cePhone == "") {
        confirm.fnAlert("", "인증받을 휴대폰 번호를 입력하여 주십시오.", "alert");
        return false;
      } else {
        let regExp = /^01([0|1|6|7|8|9])?([0-9]{3,4})?([0-9]{4})$/;
        if (!regExp.test(this.cePhone)) {
          confirm.fnAlert("", "휴대폰 번호를 확인해 주세요. <br/> 아이디에 등록된 휴대폰 번호만 인증이 가능합니다.", "alert");
          return false;
        }
      }
      this.ceNumberDiv = true;
      var param = {
        hpNo: this.cePhone,
      };
      loginApi
        .secondCertify(param)
        .then((response) => {
          var result = response.data;
          if (result.success) {
            eventBus.$on("callbackEventBus", () => {
              document.getElementById("snum").focus();
            });
            confirm.fnConfirm("", "인증번호를 문자메시지로 보냈습니다.", "cbAlert");
            this.fnTimerStart();
          } else {
            this.ceNumberDiv = false;
            confirm.fnAlert("", result.message, "alert");
            return false;
          }
        })
        .catch((error) => {
          this.ceNumberDiv = false;
          confirm.fnAlert("", "오류가 발생하였습니다.", "alert");
          console.log(error);
        });
    },
    fnTimerStart() {
      this.TimerStr = "03분00초";
      // 1초에 한번씩 start 호출
      this.TimeCounter = 180;
      var interval = setInterval(() => {
        this.TimeCounter--; //1초씩 감소
        this.TimerStr = this.fnPrettyTime();
        if (this.TimeCounter <= 0) this.fnTimerStop(interval);
      }, 1000);
      return interval;
    },
    fnTimerStop(Timer) {
      clearInterval(Timer);
      this.TimeCounter = 0;
      this.TimerStr = "시간초과!";
      this.ceNumberDiv = false;
    },
    fnPrettyTime() {
      // 시간 형식으로 변환 리턴
      let time = this.TimeCounter / 60;
      let minutes = parseInt(time);
      let secondes = Math.round((time - minutes) * 60);
      return minutes.toString().padStart(2, "0") + "분" + secondes.toString().padStart(2, "0") + "초";
    },
    fnCertifyNumber() {
      if (this.TimeCounter == 0) {
        this.ceNumberDiv = false;
        this.ceNumber = "";
        this.TimeCounter = 180;
        this.TimerStr = "03분00초";
        confirm.fnAlert("", "인증번호를 입력할 수 있는 시간이 지났습니다. <br /> 인증번호를 다시 받아주세요.", "alert");
        return false;
      }

      if (!this.ceNumberDiv) {
        confirm.fnAlert("", "인증번호 받기를 눌러주세요.", "alert");
        return false;
      }
      if (this.ceNumber == "") {
        //confirm.fnAlert("", "인증번호를 입력하세요.", "alert");
        confirm.fnAlert("", "인증번호를 입력해 주세요.", "alert");
        return false;
      }

      let regExp = /^[0-9]+$/;
      if (!regExp.test(this.ceNumber)) {
        confirm.fnAlert("", "인증번호는 숫자만 입력 가능합니다.", "alert");
        return false;
      }

      var param = {
        hpNo: this.cePhone,
        certifyNum: this.ceNumber,
      };

      loginApi
        .certifyNumber(param)
        .then((response) => {
          var result = response.data;
          if (result.success) {
            //2차인증 store저장
            eventBus.$on("callbackEventBus", this.goToPages);
            confirm.fnConfirm("", "인증 성공하였습니다.", "cbAlert");
          } else {
            if (result.data.failCode == "EXP_TIME_FAIL") {
              this.ceNumberDiv = false;
              this.cePhone = "";
              this.ceNumber = "";
              this.TimeCounter = 180;
              // confirm.fnAlert("인증시간 초과되었습니다.", "다시 휴대폰번호를 입력해주세요.", "alert");
              confirm.fnAlert("", "다시 휴대폰번호를 입력해주세요.", "alert");
              return false;
            } else {
              if (this.failCnt >= 4) {
                eventBus.$on("callbackEventBus", this.fnLogout);
                //confirm.fnConfirm("인증번호 5회 실패하였습니다.", "로그아웃되며, 다시 로그인해 주세요.", "cbAlert");
                confirm.fnConfirm("", "인증번호를 5회 잘못 입력하여 자동 로그아웃됩니다.", "cbAlert");
              } else {
                //confirm.fnAlert("잘못된 인증번호 입니다.", "5회 실패 시 로그아웃됩니다.", "alert");
                confirm.fnAlert("", "인증번호를 잘못 입력했습니다. <br />정확하게 다시 입력해주세요. <br /> ※ 인증번호를 5회 잘못 입력하면 자동 로그아웃됩니다.", "alert");
                this.failCnt++;
                return false;
              }
            }
          }
        })
        .catch((error) => {
          confirm.fnAlert("", "오류가 발생하였습니다.", "alert");
          console.log(error);
        });
    },
    goToPages() {
      this.$store.commit("login/isAuth", true);
      this.$router.push({
        path: "/messagesend/sms",
      });
    },
    fnLogoutConfirm() {
      eventBus.$on("callbackEventBus", this.fnLogout);
      confirm.fnConfirm("", "로그아웃하시겠어요?", "logout");
    },
    fnLogout() {
      this.$store.commit("login/isAuth", false);
      this.$store.commit("hubEzData", []);
      loginApi.logout({}).then((response) => {
        var result = response.data;
        if (result.success) {
          this.$router.push({ path: "/login" });
        } else {
          confirm.fnAlert("", "오류가 발생하였습니다.", "alert");
        }
      });
    },
  },
};
</script>

<style scoped>
.content {
  width: 100%;
  height: 80vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
</style>
