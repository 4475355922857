<template>
  <!--	s: m_section	-->
  <section class="m_section">
    <!--	s: 	contents-->
    <div class="contents">
      <MobileGnb />
      <div class="tabcont_wrap without_tab reject080_wrap">
        <h1 class="title with_desc">080수신거부 관리</h1>
        <div class="tabcontTop">
          <ul>
            <!-- 220705 디자인비 수정/p.violet 순서 변경 및 li 문구 변경, 추가 -->
            <li>고객님의 수신거부번호와 인증코드는 아래와 같습니다.</li>
            <p class="violet" v-if="reject080Number && authcd080">수신거부번호 : {{ reject080Number }} , 인증코드 : {{ authcd080 }}</p>
            <li>문자메시지를 받은 고객이 위 수신거부번호로 전화하여 인증코드를 입력하면 자동으로 수신 차단됩니다.</li>
          </ul>
        </div>
        <div class="search_box reject1">
          <div class="group group01">
            <label for="right" class="label">수신거부 목록에 등록할 번호</label>
            <input type="text" class="search" v-model="creRcvblckno" maxlength="11" placeholder="‘-’없이 휴대폰 번호 숫자만 입력 " />
          </div>
          <button type="button" v-if="loginDiv" class="btn plus" @click="fnCreateReject">등록</button>
          <button type="button" v-else class="btn plus" @click="fnCantReg">등록</button>
        </div>
        <div class="search_box reject2">
          <div class="group group01">
            <label for="right" class="label">날짜</label>
            <div class="date_wrap">
              <input type="date" class="date" v-model="regDtFirst" /><span></span>
              <input type="date" class="date" v-model="regDtLast" :max="$gfnCommonUtils.getCurretDate()" />
            </div>
          </div>
          <div class="group group02">
            <label for="right" class="label">등록방법</label>
            <div class="select_wrap">
              <select v-model="regTpCd" class="select">
                <option value="" selected>전체</option>
                <option value="02">직접등록</option>
                <option value="01">ARS</option>
              </select>
            </div>
          </div>
          <div class="group group03">
            <label for="right" class="label">수신번호</label>
            <input type="text" v-model="rcvblckno" class="search2" placeholder="수신번호를 입력하세요." @keyup.enter="fnrejectSearch" />
          </div>
          <button class="btn" @click="fnrejectSearch">조회</button>
        </div>
        <div class="list_area">
          <div class="list_info">
            <div class="left_wrap">
              <div class="select_wrap">
                <select v-model="selected" @change="fnSelected" class="select">
                  <option value="30" selected>30</option>
                  <option value="50">50</option>
                  <option value="100">100</option>
                </select>
              </div>
              <p class="total">
                총 <span class="pink">{{ totCnt }} </span>건
              </p>
            </div>
          </div>

          <div class="fixed-table-cover">
              <div class="fixed-table-container" style="width: 1074px;">
                <div class="fixed-table-header-bg"></div>
                <div class="fixed-table-wrapper">
                  <table class="fixed-table" style="table-layout: fixed">
                    <thead>
                      <tr>
                        <th class="td_chk" style="width: 40px">
                          <div class="th-text">
                            <input type="checkbox" id="listCheck_all" class="chk_all" @change="fnListChkAll" v-model="listAllChecked" /><label for="listCheck_all"></label>
                          </div>
                        </th >
                        <th class="td_num" style="width: 8%">
                          <div class="th-text">No.</div>
                        </th >
                        <th class="td_phone" style="width: calc(26% - 40px)">
                          <div class="th-text">수신거부 휴대폰 번호</div>
                        </th>
                        <th class="td_way" style="width: 22%">
                          <div class="th-text">등록방법</div>
                        </th>
                        <th class="td_writer" style="width: 16%">
                          <div class="th-text">등록자명</div>
                        </th>
                        <th class="td_date" style="width: 20%">
                          <div class="th-text">등록일</div>
                        </th>
                        <th class="td_del" style="width: 8%">
                          <div class="th-text">삭제</div>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-if="totCnt == 0" class="empty">
                        <td colspan="6">검색 결과가 없습니다.</td>
                      </tr>
                      <tr v-for="(data, index) in sendRejectList" :key="index" :class="{ on: data.targetChk }">
                        <td class="td_chk">
                          <input type="checkbox" class="chk" :id="'listCheck_' + index" v-model="data.targetChk" /><label :for="'listCheck_' + index"></label>
                        </td>
                        <td class="td_num">{{ index + 1 }}</td>
                        <td class="td_phone">{{ data.rcvblckno }}</td>
                        <td class="td_way">{{ data.dtlCdNm }}</td>
                        <td class="td_writer" v-show="data.regTpCd == '01'">자동</td>
                        <td class="td_writer" v-show="data.regTpCd == '02'">{{ data.regId }}</td>
                        <td class="td_date">{{ data.regDt }} {{ data.week }}</td>
                        <td class="td_del">
                          <button class="btn white del2" @click="fnRejectCancelConfirm(data)"></button>
                        </td>
                      </tr>
                      
                    </tbody>
                  </table>
                </div>
              </div>
            </div>

          <!--
          <div class="list_cover">
            <div class="list_th_wrap" @scroll="handleThScroll($event)">
              <ul class="list_th">
                <li class="td_chk w40">
                  <input type="checkbox" id="listCheck_all" class="chk_all" :disabled="totCnt == 0" @change="fnListChkAll" v-model="listAllChecked" /><label for="listCheck_all"></label>
                </li>
                <li class="td_num w80"><span>No.</span></li>
                <li class="td_phone w270"><span>수신거부 휴대폰 번호</span></li>
                <li class="td_way w230"><span>등록방법</span></li>
                <li class="td_writer w165"><span>등록자명</span></li>
                <li class="td_date w202"><span>등록일</span></li>
                <li class="td_del w80"><span>삭제</span></li>
              </ul>
            </div>
            <div class="list_wrap scr_wrap" @scroll="handleScroll($event)">
              <ul class="list">-->
                <!-- <li>
                  <span class="td_chk w40"><input type="checkbox" class="chk" id="chk06" /><label for="chk06"></label></span>
                  <span class="td_num w80">6</span>
                  <span class="td_phone w270">010-1234-1234</span>
                  <span class="td_way w230">ARS</span>
                  <span class="td_writer w165">허나래</span>
                  <span class="td_date w202">2021.12.01</span>
                  <span class="td_del w80"><button class="btn white del2"></button></span>
                </li>
                <li>
                  <span class="td_chk w40"><input type="checkbox" class="chk" id="chk07" /><label for="chk07"></label></span>
                  <span class="td_num w80">5</span>
                  <span class="td_phone w270">010-1234-1234</span>
                  <span class="td_way w230">직접등록</span>
                  <span class="td_writer w165">안미래</span>
                  <span class="td_date w202">2021.05.01</span>
                  <span class="td_del w80"><button class="btn white del2"></button></span>
                </li> -->
                <!--<li v-for="(data, index) in sendRejectList" :key="index" :class="{ on: data.targetChk }">
                  사용자ID , 등록방법: 수동: 02
                  <span class="td_chk w40">
                    <input type="checkbox" class="chk" :id="'listCheck_' + index" v-model="data.targetChk" /><label :for="'listCheck_' + index"></label>
                  </span>
                  사용자ID , 등록방법: 자동: 01
                  <span class="td_chk w40" v-show="data.regTpCd == '01'"> 
                    <input type="checkbox" class="chk" disabled /><label></label> 
                  </span> 
                  <span class="td_num w80">{{ index + 1 }}</span>
                  <span class="td_phone w270">{{ data.rcvblckno }}</span>
                  <span class="td_way w230">{{ data.dtlCdNm }}</span>
                  <span class="td_writer w165" v-show="data.regTpCd == '01'">자동</span>
                  <span class="td_writer w165" v-show="data.regTpCd == '02'">{{ data.regId }}</span>
                  <span class="td_date w202">{{ data.regDt }} {{ data.week }}</span>

                  <span class="td_del w80">
                    <button class="btn white del2" @click="fnRejectCancelConfirm(data)"></button>
                  </span>
                </li>
                <li v-if="totCnt == 0" class="empty">
                  <span class="w100p">검색 결과가 없습니다.</span>
                </li>
              </ul>
            </div>
          </div>
           -->

          <div class="list_bottom">
            <button class="btn white small" type="button" @click="fnChkRejectCalcelConfirm">번호 삭제</button>
          </div>
          <div class="paging_wrap" v-show="totCnt > listSize">
            <!-- 페이징 컴포넌트 -->
            <PageLayer ref="updatePaging" @fnClick="fnSearch" :listTotalCnt="totCnt" :selected="listSize" :pageNum="pageNo"></PageLayer>
          </div>
        </div>
      </div>
    </div>
    <!--	e: 	contents	-->
  </section>
  <!--	e: m_section	-->
</template>
<script>
import store from "@/store";
import sendRejectManageApi from "@/modules/sendRejectManage/service/sendRejectManageApi";
import confirm from "@/modules/commonUtil/service/confirm";
import PageLayer from "@/components/PageLayer.vue";
//이벤트 버스 안에 호출하는 함수 명을 바로 적용할 수 있다.
import { eventBus } from "@/modules/commonUtil/service/eventBus";
import MobileGnb from "@/components/MobileGnb.vue";

export default {
  name: "sendReject",
  components: {
    PageLayer, //페이징 컴포넌트
    MobileGnb,
  },
  data() {
    return {
      loginDiv: false,
      selected: "30",
      listSize: 30, // select 박스 value (출력 갯수 이벤트)
      pageNo: 1, // 현재 페이징 위치
      totCnt: 0, //전체 리스트 수
      offset: 0, //페이지 시작점
      // userTpCd: "02",
      userId: "",
      authcd080Div: false,
      authcd080: "", //수신 거부 검색 기본 값
      reject080Number: this.$store.getters["getHubEzData"].reject080Number ? this.$store.getters["getHubEzData"].reject080Number : "",
      autCd: "",
      regDtFirst: this.$gfnCommonUtils.getCurretDate(), //검색 시작일
      regDtLast: this.$gfnCommonUtils.getCurretDate(), //검색 종료일
      regId: "", //등록자 ID
      regTpCd: "", //등록 유형 코드
      dtlCdNm: "",
      rcvblckno: "",
      useYn: "Y", //검색 수신번호
      sendRejectList: [],
      listAllChecked: false,
      delrejectInfo: [],
      creRcvblckno: "",
      nowDate: "",
    };
  },
  created() {
    this.fnInit();
  },
  mounted() {
    this.getNowTimes();
  },
  watch: {
    creRcvblckno(val) {
      const reg = /[ㄱ-ㅎ|ㅏ-ㅣ|가-힣|a-zA-Z|~/',.;@#$%^&*()_+|<>?:{}]/;
      // 한글, 영문 체크
      if (reg.exec(val) !== null) this.creRcvblckno = val.replace(/[^0-9]/g, "");
      // .... 만 입력하게 될 경우 체크
      if (isNaN(parseFloat(val))) this.creRcvblckno = "";
    },
  },
  methods: {
    fnInit() {
      const isAuth = store.getters["login/isAuth"];
      if (isAuth) {
        this.loginDiv = true;
        this.fnInitRejectList();
      }
    },
    fnRejectInit() {
      this.creRcvblckno = "";
    },
    //초기 리스트
    fnInitRejectList() {
      if (this.loginDiv) {
        let param = {
          pageNo: this.pageNo, //페이징 필수 값
          listSize: this.listSize,
          regDtFirst: this.regDtFirst,
          regDtLast: this.regDtLast,
        };
        //받아오는 값. 비동기
        sendRejectManageApi.selectRejectPhoneList(param).then((response) => {
          var result = response.data;
          if (result.success) {
            //vue 사용 list..   | 서버에서 넣어주는 data값. 서버와 명칭 동일하게 사용
            this.sendRejectList = result.data.rejectPhoneList;
            this.totCnt = result.data.pageInfo.totCnt; //전체 카운트
            this.offset = result.data.pageInfo.offset; //페이지 번호
            if (result.data.authcd080 !== "" && result.data.authcd080 !== null && result.data.authcd080 !== undefined) {
              this.authcd080Div = true;
              this.authcd080 = result.data.authcd080;
            } else {
              this.authcd080 = "없음";
            }

            this.userId = result.data.userId;
            this.autCd = result.data.autCd;
          } else {
            confirm.fnAlert("", "오류가 발생하였습니다.", "alert");
          }
        });
      }
    },
    //검색 조회
    async fnSendRejectList() {
      if (this.loginDiv) {
        if (this.$gfnCommonUtils.isEmpty(this.regDtFirst)) {
          confirm.fnAlert("", "검색 시작일을 선택해 주세요.", "alert");
          return false;
        }

        if (this.$gfnCommonUtils.isEmpty(this.regDtLast)) {
          confirm.fnAlert("", "검색 종료일을 선택해 주세요.", "alert");
          return false;
        }

        if (this.regDtFirst.replaceAll("-", "") > this.regDtLast.replaceAll("-", "")) {
          confirm.fnAlert("", "검색 시작일이 종료일보다 클 수 없습니다.", "alert");
          return false;
        }

        if (this.regDtFirst.replaceAll("-", "") > this.nowDate.replaceAll("-", "")) {
          confirm.fnAlert("", "검색 시작일이 현재 날짜보다 클 수 없습니다.", "alert");
          return false;
        }

        if (this.regDtLast.replaceAll("-", "") > this.nowDate.replaceAll("-", "")) {
          confirm.fnAlert("", "검색 종료일이 현재 날짜보다 클 수 없습니다.", "alert");
          return false;
        }

        let param = {
          pageNo: this.pageNo, //페이징 필수 값
          listSize: this.listSize,
          regDtFirst: this.regDtFirst,
          regDtLast: this.regDtLast,
          regTpCd: this.regTpCd,
          rcvblckno: this.rcvblckno,
          dtlCdNm: this.dtlCdNm,
        };

        //받아오는 값. 비동기
        await sendRejectManageApi.selectRejectPhoneList(param).then((response) => {
          var result = response.data;
          if (result.success) {
            //vue 사용 list..   | 서버에서 넣어주는 data값. 서버와 명칭 동일하게 사용
            this.sendRejectList = result.data.rejectPhoneList;
            this.totCnt = result.data.pageInfo.totCnt; //전체 카운트
            this.offset = result.data.pageInfo.offset; //페이지 번호
            if (result.data.authcd080 !== "" && result.data.authcd080 !== null && result.data.authcd080 !== undefined) {
              this.authcd080Div = true;
              this.authcd080 = result.data.authcd080;
            } else {
              this.authcd080 = "없음";
            }

            this.userId = result.data.userId;
            this.autCd = result.data.autCd;
          } else {
            confirm.fnAlert("", "오류가 발생하였습니다.", "alert");
          }
        });
      }
    },
    fnrejectSearch() {
      this.$refs.updatePaging.fnAllDecrease();
    },
    fnSelected() {
      //리스트 갯수 선택
      this.listSize = Number(this.selected);
      this.$refs.updatePaging.fnAllDecrease();
    },
    fnSearch(pageNum) {
      //검색
      this.pageNo = this.$gfnCommonUtils.defaultIfEmpty(pageNum, "1") * 1;
      this.fnSendRejectList();
    },

    fnListChkAll() {
      if (this.listAllChecked) {
        this.sendRejectList.forEach(function (rejectInfo) {
          if (rejectInfo.regTpCd == "02") {
            rejectInfo.targetChk = true;
          }
        });
      } else {
        this.sendRejectList.forEach(function (rejectInfo) {
          rejectInfo.targetChk = false;
        });
      }
    },

    //수신거부번호 등록
    fnCreateReject() {
      if (!this.fnInputCheckReq()) return false;

      if (!this.authcd080Div) {
        confirm.fnAlert("", "인증코드 발급후 등록 가능합니다.", "alert");
        return;
      }

      let param = {
        regTpCd: "02", //홈페이지 에서 등록은 직접등록만 가능
        rcvblckno: this.creRcvblckno,
        useYn: this.useYn,
      };

      sendRejectManageApi.insertRejectPhone(param).then((response) => {
        var result = response.data;
        if (result.success) {
          this.fnRejectInit();
          confirm.fnAlert("", "수신번호가 등록되었습니다.", "alert");
          this.fnSendRejectList();
        } else {
          if (result.data.errChk === "DUP") {
            confirm.fnAlert("", result.message, "alert");
          } else {
            confirm.fnAlert("", "오류가 발생하였습니다.", "alert");
          }
        }
      });
    },

    //수신거부번호 등록 유효성 검사
    fnInputCheckReq() {
      const patten_phone = /^(01[016789]{1})-?[0-9]{4}-?[0-9]{4}$/; //핸드폰 번호, 하이픈 상관 X

      if (this.creRcvblckno == "") {
        confirm.fnAlert("", "등록할 휴대폰 번호를 입력하여 주십시오.", "alert");
        return false;
      } else {
        let regExp = /([0-9])$/;
        if (!regExp.test(this.creRcvblckno)) {
          confirm.fnAlert("", "숫자만 입력해 주세요.", "alert");
          return false;
        }
      }

      if (!patten_phone.test(this.creRcvblckno)) {
        confirm.fnAlert("", "휴대폰번호를 확인해주세요.", "alert");
        return;
      }

      if (this.creRcvblckno.length != 10 && this.creRcvblckno.length != 11) {
        confirm.fnAlert("", "휴대폰번호의 길이를 확인해주세요.", "alert");
        return false;
      }

      return true;
    },

    //개별 삭제
    fnRejectCancelConfirm(rejectInfo) {
      var delrejectInfo = [];
      //전화번호 '-' 제외하여 데이터 전달
      let chgrcvblckno = rejectInfo.rcvblckno.replace(/[^0-9]/g, "");
      var delInfo = {
        rcvblckno: chgrcvblckno,
        regTpCd: rejectInfo.regTpCd,
        authcd080: rejectInfo.authcd080,
      };
      delrejectInfo.push(delInfo);
      //유형이 관리자이면 등록자가 달라도 삭제 가능
      if (this.autCd == "H001" || this.autCd == "H004") {
        eventBus.$on("callbackEventBus", () => {
          this.fnRejectCancel(delrejectInfo);
        });
        confirm.fnConfirm("", "선택한 번호를 수신거부 목록에서 삭제하시겠어요?", "del");

        //유형이 사용자이면 등록자가 동일할 때만 삭제 가능
      } else if (this.autCd == "H002") {
        if (this.userId == rejectInfo.regId) {
          eventBus.$on("callbackEventBus", () => {
            this.fnRejectCancel(delrejectInfo);
          });
          confirm.fnConfirm("", "선택한 번호를 수신거부 목록에서 삭제하시겠어요?", "del");
        } else {
          confirm.fnAlert("", "본인이 등록한 수신번호가 아닙니다.", "alert");
        }
      }
    },

    //체크박스 삭제
    fnChkRejectCalcelConfirm() {
      var userChk = false;
      var delrejectInfo = [];

      let checkDiv = 0;
      let user = this.autCd;
      let userIdChk = this.userId;
      this.sendRejectList.forEach(function (rejectInfo) {
        if (rejectInfo.targetChk) checkDiv++;
      });
      if (checkDiv == 0) {
        confirm.fnAlert("", "삭제할 번호를 선택해 주세요.", "alert");
        return false;
      }

      this.sendRejectList.forEach(function (rejectInfo) {
        let chgrcvblckno = rejectInfo.rcvblckno.replace(/[^0-9]/g, "");
        if (rejectInfo.targetChk) {
          var delInfo = {
            rcvblckno: chgrcvblckno,
            regTpCd: rejectInfo.regTpCd,
            authcd080: rejectInfo.authcd080,
          };
          delrejectInfo.push(delInfo);

          if (user == "H002") {
            if (userIdChk !== rejectInfo.regId) {
              userChk = true;
              return false;
            }
          }
        }
      });

      if (userChk) {
        confirm.fnAlert("", "본인이 등록한 수신번호가 아닙니다.", "alert");
        return false;
      }

      eventBus.$on("callbackEventBus", () => {
        this.fnRejectCancel(delrejectInfo);
      });

      confirm.fnConfirm("", "선택한 번호를 수신거부 목록에서 삭제하시겠어요?", "confirm");
    },

    //삭제(사용 상태 Y -> N)
    fnRejectCancel(delrejectInfo) {
      let param = {
        delrejectInfo: delrejectInfo,
      };
      sendRejectManageApi.deleteRejectPhoneList(param).then((response) => {
        var result = response.data;

        if (result.success) {
          //삭제 문구 확인하기
          eventBus.$on("callbackEventBus", this.fnSearch);
          confirm.fnConfirm("", "수신거부번호가 삭제되었습니다.", "cbAlert");
        } else {
          confirm.fnAlert("", "오류가 발생하였습니다.", "alert");
        }
      });
    },
    fnCantReg() {
      confirm.fnAlert("", "로그인 후 이용할 수 있습니다.", "alert");
      return;
    },
    handleScroll(e) {
      const chooseListThWrap = e.target.parentNode.children[0];
      chooseListThWrap.scrollLeft = e.target.scrollLeft;
    },
    handleThScroll(e) {
      const chooseListWrap = e.target.parentNode.children[1];
      chooseListWrap.scrollLeft = e.target.scrollLeft;
    },
    getNowTimes() {
      let myDate = new Date();
      let yy = String(myDate.getFullYear());
      let tmpMm = myDate.getMonth() + 1;
      let mm = String(tmpMm < 10 ? "0" + tmpMm : tmpMm);
      let dd = String(myDate.getDate() < 10 ? "0" + myDate.getDate() : myDate.getDate());
      this.nowDate = yy + "-" + mm + "-" + dd;
    },
  },
};
</script>
