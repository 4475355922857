<template>
  <!--	s: 	contents	-->
  <div class="contents">
    <MobileGnb />
    <!--	s: msect01 - 메인배너 	-->
    <div class="msect msect01">
      <div class="swiper main_slide">
        <swiper class="swiper-wrapper" :options="Mswiper" :pagination="false">
          <swiper-slide class="swiper-slide mslide01">
            <div class="cont">
              <div class="txt">
                <p class="mtxt">사장님부터 매장 직원까지<br />누구나 쉽게 보내는</p>
                <p class="btxt">U<span>+</span>메시지허브이지</p>
                <p class="stxt">언제 어디서나 쉽게 보낼 수 있는<br />중소기업/소상공인 전용 문자메시지 서비스</p>
              </div>
              <div class="img"><img src="../../../assets/images/msect0101.png?ver=0" alt="" /></div>
            </div>
          </swiper-slide>
          <!-- <swiper-slide class="swiper-slide mslide01">
            <div class="cont">
              <div :style="!isMobile ? 'position: absolute; top: 630px' : 'position: absolute; bottom: -30px; width: 100%;'">
                <a :href="serviceJoinUrl" target="_blank" class="btn link" data-aos="fade-up" data-aos-duration="1000">이벤트 참여하기</a>
              </div>
              <div class="img" style="width: 100%" v-if="!isMobile"><img src="../../../assets/images/banner/banner3.png?ver=0" alt="" /></div>
              <div class="img" :style="isMobile ? 'width: 100%; display: flex; justify-content: center;' : ''" v-if="isMobile">
                <img src="../../../assets/images/banner/banner2_m.png?ver=0" :style="isMobile ? 'width: 85%' : ''" alt="" />
              </div>
            </div>
          </swiper-slide> -->
          <!-- <swiper-slide class="swiper-slide mslide01">
            <div class="cont">
              <div class="txt">
                <p class="mtxt">사장님부터 매장 직원까지<br />누구나 쉽게 보내는</p>
                <p class="btxt">U<span>+</span>메시지허브이지3</p>
                <p class="stxt">언제 어디서나 쉽게 보낼 수 있는<br />중소기업/소상공인 전용 문자메시지 서비스</p>
              </div>
              <div class="img"><img src="../../../assets/images/msect0101.png?ver=0" alt="" /></div>
            </div>
          </swiper-slide>
          <swiper-slide class="swiper-slide mslide01">
            <div class="cont">
              <div class="txt">
                <p class="mtxt">사장님부터 매장 직원까지<br />누구나 쉽게 보내는</p>
                <p class="btxt">U<span>+</span>메시지허브이지4</p>
                <p class="stxt">언제 어디서나 쉽게 보낼 수 있는<br />중소기업/소상공인 전용 문자메시지 서비스</p>
              </div>
              <div class="img"><img src="../../../assets/images/msect0101.png?ver=0" alt="" /></div>
            </div>
          </swiper-slide> -->
        </swiper>
        <!-- <div class="swiper-pagination"></div>
        <div class="swiper-controll">
          <button class="swiper-controll-btn pause" aria-pressed="false"></button>
        </div> -->
      </div>
    </div>
    <!--	e: msect01 - 메인배너 	-->
    <!--	s: msect02 - 결합 	-->
    <div class="msect msect02">
      <div class="cont">
        <div class="txt">
          <p class="btxt" data-aos="fade-up" data-aos-duration="1000">U<span class="lg">+</span>기업 서비스와 결합하면 할인</p>
          <div class="icon_wrap">
            <div data-aos="fade-up" data-aos-duration="1000"><img src="../../../assets/images/m_msg.png?ver=0" alt="" /></div>
            <div data-aos="fade-up" data-aos-duration="1200"><img src="../../../assets/images/msect0201.png?ver=0" alt="" /></div>
            <div data-aos="fade-up" data-aos-duration="1400"><img src="../../../assets/images/m_wifi.png?ver=0" alt="" /></div>
            <div data-aos="fade-up" data-aos-duration="1600"><img src="../../../assets/images/msect0201.png?ver=0" alt="" /></div>
            <div data-aos="fade-up" data-aos-duration="1800"><img src="../../../assets/images/m_call.png?ver=0" alt="" /></div>
          </div>
          <p class="mtxt" data-aos="fade-up" data-aos-duration="1000">
            사무실이나 매장에서 이용하고 있는 U+ 기업 서비스와<span class="block"></span>결합하면 <span class="pink">‘최대 20% 할인’</span> 받을 수 있어요
          </p>
          <a :href="serviceJoinUrl" target="_blank" class="btn link" data-aos="fade-up" data-aos-duration="1000">지금 가입하기</a>
          <p class="stxt" data-aos="fade-up" data-aos-duration="1000">
            결합할인은 U+기업서비스(오피스넷/기업070/클라우드고객센터)를 사용하시는<br />
            고객에게 제공됩니다.
          </p>
          <!--삭제 <p class="stxt" data-aos="fade-up" data-aos-duration="1000">자세한 결합 할인 내용은 U+기업메시징 고객센터 1544-5992(유료)로 문의해 주세요.</p>
          <p class="stxt" data-aos="fade-up" data-aos-duration="1000">상담 가능 시간 : 평일 오전 9시~오후 6시, 점심시간 낮 12시~오후 1시 (주말과 공휴일은 제외)</p>-->
        </div>
        <div class="img" data-aos="fade-up" data-aos-duration="1000"><img src="../../../assets/images/msect02.png?ver=0" alt="" /></div>
      </div>
    </div>
    <!--	e: msect02 - 결합 	-->
    <!--	s: msect03 - 휴대폰 	-->
    <div class="msect msect03">
      <div class="cont">
        <div class="txt">
          <p class="btxt" data-aos="fade-up" data-aos-duration="1000">휴대폰, 태블릿에서도<br />PC 화면 그대로 이용</p>
          <p class="mtxt" data-aos="fade-up" data-aos-duration="1000">
            업계 최초로 개발한 <span class="pink">‘반응형 웹’</span>으로 어떤 모바일 기기로<br />
            접속해도 PC와 똑같은 화면으로 이용할 수 있어요. <br />원하는 메시지 채널과 유형을 선택하여 대량 전송하고,<br />
            결과를 확인할 수 있어요
          </p>
          <div class="icon_wrap">
            <div data-aos="fade-up" data-aos-duration="1000"><img src="../../../assets/images/m_pc.png?ver=0" alt="" /></div>
            <div data-aos="fade-up" data-aos-duration="1300"><img src="../../../assets/images/m_tablet.png?ver=0" alt="" /></div>
            <div data-aos="fade-up" data-aos-duration="1600"><img src="../../../assets/images/m_mobile.png?ver=0" alt="" /></div>
          </div>
        </div>
        <div class="img" data-aos="fade-left" data-aos-duration="1000">
          <img src="../../../assets/images/msect03.png?ver=0" alt="" class="pc" />
          <img src="../../../assets/images/msect03_m.png" alt="" class="mobile" />
        </div>
      </div>
    </div>
    <!--	e: msect03 - 휴대폰 	-->
    <!--	s: msect04 - 문자채널 	-->
    <div class="msect msect04">
      <div class="txt">
        <p class="btxt" data-aos="fade-up" data-aos-duration="1000">효과적인 메시지 전달을 위해 내용에 맞는 채널 선택</p>
        <p class="mtxt" data-aos="fade-up" data-aos-duration="1000">공지사항, 이벤트, 광고 등 어떤 내용이든 내 고객이 선호하는 메시지 채널로 더욱 효과적으로 전달할 수 있어요</p>
      </div>
      <div class="tab_list" data-aos="fade-up" data-aos-duration="1000">
        <ul>
          <li class="tab_li on" ref="tabcont41" @click="openTab($event, 'tabcont41')">일반문자</li>
          <li class="tab_li" @click="openTab($event, 'tabcont42')">카카오 비즈메시지</li>
          <li class="tab_li" @click="openTab($event, 'tabcont43')">RCS</li>
        </ul>
      </div>
      <div class="tab_cont">
        <div class="tabcont tabcont41" id="tabcont41" data-aos="fade-up" data-aos-duration="1000">
          <div class="topTxt">
            <p class="mtxt">문자메시지로 전송 실패 없이 안정적으로 보낼 수 있습니다.</p>
            <router-link to="/serviceInfo/main">자세히 보기&nbsp;&nbsp;&nbsp;></router-link>
          </div>
          <div class="tabcon">
            <div class="tab_swiper01">
              <template v-if="!isMobile">
                <ul class="swiper-wrapper">
                  <li class="swiper-slide">
                    <div class="img"><img src="../../../assets/images/msect0411.png" alt="" /></div>
                    <div class="txt">
                      <div class="tit">SMS</div>
                      <div class="desc">한글 45자</div>
                    </div>
                  </li>
                  <li class="swiper-slide">
                    <div class="img"><img src="../../../assets/images/msect0412.png" alt="" /></div>
                    <div class="txt">
                      <div class="tit">LMS</div>
                      <div class="desc">한글 1,000자</div>
                    </div>
                  </li>
                  <li class="swiper-slide">
                    <div class="img"><img src="../../../assets/images/msect0413.png" alt="" /></div>
                    <div class="txt">
                      <div class="tit">MMS</div>
                      <div class="desc">한글 1,000자 + 사진3장, 최대 합계 300kbyte</div>
                    </div>
                  </li>
                </ul>
              </template>
              <template v-if="isMobile">
                <swiper class="swiper-wrapper" :options="tabSwiper01">
                  <swiper-slide class="swiper-slide">
                    <div class="img"><img src="../../../assets/images/msect0411.png" alt="" /></div>
                    <div class="txt">
                      <div class="tit">SMS</div>
                      <div class="desc">한글 45자</div>
                    </div>
                  </swiper-slide>
                  <swiper-slide class="swiper-slide">
                    <div class="img"><img src="../../../assets/images/msect0412.png" alt="" /></div>
                    <div class="txt">
                      <div class="tit">LMS</div>
                      <div class="desc">한글 1,000자</div>
                    </div>
                  </swiper-slide>
                  <swiper-slide class="swiper-slide">
                    <div class="img"><img src="../../../assets/images/msect0413.png" alt="" /></div>
                    <div class="txt">
                      <div class="tit">MMS</div>
                      <div class="desc">한글 1,000자 + 사진3장, 최대 합계 300kbyte</div>
                    </div>
                  </swiper-slide>
                </swiper>
                <div class="tab01_pagination"></div>
              </template>
            </div>
          </div>
        </div>
        <div class="tabcont tabcont42" id="tabcont42" style="opacity: 0" data-aos="fade-up" data-aos-duration="1000">
          <div class="topTxt">
            <p class="mtxt">매일 쓰는 카카오톡에서 배송, 주문 등 중요 정보는 알림톡으로<span class="block"></span>이벤트 등 광고는 친구톡으로 보낼 수 있어요.</p>
            <router-link to="/serviceInfo/main">자세히 보기&nbsp;&nbsp;&nbsp;></router-link>
          </div>
          <div class="tabcon">
            <div class="tab_swiper02">
              <template v-if="!isMobile">
                <ul class="swiper-wrapper">
                  <li class="swiper-slide">
                    <div class="img"><img src="../../../assets/images/msect0421.png?ver=1" alt="" /></div>
                    <div class="txt"><div class="tit">알림톡</div></div>
                  </li>
                  <li class="swiper-slide">
                    <div class="img"><img src="../../../assets/images/msect0422.png?ver=1" alt="" /></div>
                    <div class="txt"><div class="tit">친구톡 &nbsp;<img src="../../../assets/images/icon_service_before.png?ver=1" alt=""/></div></div>
                  </li>
                </ul>
              </template>
              <template v-if="isMobile">
                <swiper class="swiper-wrapper" :options="tabSwiper02">
                  <swiper-slide class="swiper-slide">
                    <div class="img"><img src="../../../assets/images/msect0421.png?ver=1" alt="" /></div>
                    <div class="txt"><div class="tit">알림톡</div></div>
                  </swiper-slide>
                  <swiper-slide class="swiper-slide">
                    <div class="img"><img src="../../../assets/images/msect0422.png?ver=1" alt="" /></div>
                    <div class="txt"><div class="tit">친구톡 &nbsp;<img src="../../../assets/images/icon_service_before.png?ver=1" alt=""/></div></div>
                  </swiper-slide>
                </swiper>
                <div class="tab02_pagination"></div>
              </template>
            </div>
          </div>
        </div>
        <div class="tabcont tabcont43" id="tabcont43" style="opacity: 0" data-aos="fade-up" data-aos-duration="1000">
          <div class="topTxt">
            <p class="mtxt">매장/기업 정보, 바로가기 버튼, 100자 SMS 등<span class="block"></span>더 많은 정보를 더 읽기 쉽게 전달할 수 있어요.</p>
            <router-link to="/serviceInfo/main">자세히 보기&nbsp;&nbsp;&nbsp;></router-link>
          </div>
          <div class="tabcon">
            <div class="tab_swiper03">
              <template v-if="!isMobile">
                <ul class="swiper-wrapper">
                  <li class="swiper-slide">
                    <div class="img"><img src="../../../assets/images/msect0431.png?ver=1" alt="" /></div>
                    <div class="txt">
                      <div class="tit">SMS &nbsp;<img src="../../../assets/images/icon_service_before.png?ver=1" alt=""/></div>
                    </div>
                  </li>
                  <li class="swiper-slide">
                    <div class="img"><img src="../../../assets/images/msect0432.png?ver=1" alt="" /></div>
                    <div class="txt">
                      <div class="tit">LMS &nbsp;<img src="../../../assets/images/icon_service_before.png?ver=1" alt=""/></div>
                    </div>
                  </li>
                  <li class="swiper-slide">
                    <div class="img"><img src="../../../assets/images/msect0433.png?ver=1" alt="" /></div>
                    <div class="txt">
                      <div class="tit">MMS &nbsp;<img src="../../../assets/images/icon_service_before.png?ver=1" alt=""/></div>
                    </div>
                  </li>
                  <li class="swiper-slide">
                    <div class="img"><img src="../../../assets/images/msect0434.png?ver=1" alt="" /></div>
                    <div class="txt">
                      <div class="tit">템플릿 &nbsp;<img src="../../../assets/images/icon_service_before.png?ver=1" alt=""/></div>
                    </div>
                  </li>
                </ul>
              </template>
              <template v-if="isMobile">
                <swiper class="swiper-wrapper" :options="tabSwiper03">
                  <swiper-slide class="swiper-slide">
                    <div class="img"><img src="../../../assets/images/msect0431.png?ver=1" alt="" /></div>
                    <div class="txt">
                      <div class="tit">단문메시지</div>
                      <!-- <div class="desc">한글 45자</div> -->
                    </div>
                  </swiper-slide>
                  <swiper-slide class="swiper-slide">
                    <div class="img"><img src="../../../assets/images/msect0432.png?ver=1" alt="" /></div>
                    <div class="txt">
                      <div class="tit">장문 메시지</div>
                      <!-- <div class="desc">한글 1,000자</div> -->
                    </div>
                  </swiper-slide>
                  <swiper-slide class="swiper-slide">
                    <div class="img"><img src="../../../assets/images/msect0433.png?ver=1" alt="" /></div>
                    <div class="txt">
                      <div class="tit">사진메시지</div>
                      <!-- <div class="desc">한글 1,000자 + 사진/동영상 최대 1MB</div> -->
                    </div>
                  </swiper-slide>
                  <swiper-slide class="swiper-slide">
                    <div class="img"><img src="../../../assets/images/msect0434.png?ver=1" alt="" /></div>
                    <div class="txt">
                      <div class="tit">템플릿</div>
                      <!-- <div class="desc">한글 1,000자 + 사진/동영상 최대 1MB</div> -->
                    </div>
                  </swiper-slide>
                </swiper>
                <div class="tab03_pagination"></div>
              </template>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--	e: msect04 - 문자채널 	-->
    <!--	s: msect05 - 유플러스니까 	-->
    <div class="msect msect05">
      <div class="cont">
        <p class="btxt">유플러스만의<span class="m_block"></span> 다양한 혜택과 기능 이용</p>
        <div class="uplus_slide swiper">
          <swiper class="swiper-wrapper" :options="sect4swiper">
            <swiper-slide class="swiper-slide card">
              <div class="img"><img src="../../../assets/images/m_bill.png?ver=0" alt="" /></div>
              <div class="txt">
                <b>통합 청구서</b>
                <p class="desc">사용 중인 U+ 기업 서비스<br />이용요금과 함께 청구되어<br />한 번에 확인할 수 있어요</p>
              </div>
            </swiper-slide>
            <swiper-slide class="swiper-slide card">
              <div class="img"><img src="../../../assets/images/m_calendar.png?ver=00" alt="" /></div>
              <div class="txt">
                <b>메시지 반복<br />예약전송 지원</b>
                <p class="desc">고객에게 한 번의 설정으로 일정한<br />시간마다 메시지를 보낼 수 있어요</p>
              </div>
            </swiper-slide>
            <swiper-slide class="swiper-slide card">
              <div class="img"><img src="../../../assets/images/m_alt.png?ver=0" alt="" /></div>
              <div class="txt">
                <b>대체문자 전송 지원</b>
                <p class="desc">알림톡 전송 실패 시 일반문자 자동전환<br />되어 전송 성공율을 높일 수 있어요</p>
              </div>
            </swiper-slide>
            <swiper-slide class="swiper-slide card">
              <div class="img"><img src="../../../assets/images/m_080.png?ver=0" alt="" /></div>
              <div class="txt">
                <b>080수신거부번호 무료</b>
                <p class="desc">광고메시지에 꼭 추가해야 하는<br />080무료수신거부 번호를 별도 신청<br />없이 무료로 바로 사용할 수 있어요</p>
              </div>
            </swiper-slide>
            <swiper-slide class="swiper-slide card">
              <div class="img"><img src="../../../assets/images/m_over.png?ver=0" alt="" /></div>
              <div class="txt">
                <b>남은 금액은<br />다음 달에도 사용</b>
                <p class="desc">이번 달에 다 쓰지 못한 금액은<br />최대 6개월까지 이월할 수 있어요</p>
              </div>
            </swiper-slide>
          </swiper>
          <div class="swiper-button-prev"></div>
          <div class="swiper-button-next"></div>
        </div>
      </div>
    </div>
    <!--	e: msect05 - 유플러스니까 	-->
    <!--	s: msect06 - 가입하러가기 	-->
    <div class="msect msect06">
      <div class="cont">
        <div class="txt">
          <p class="mtxt" data-aos="fade-up" data-aos-duration="1000">사장님부터 매장 직원까지 누구나 쉽게 보내는</p>
          <img src="../../../assets/images/msect0601.png?ver=0" alt="" data-aos="fade-up" data-aos-duration="1000" />
          <a :href="serviceJoinUrl" target="_blank" class="btn link" data-aos="fade-up" data-aos-duration="1000">지금 가입하기</a>
        </div>
      </div>
      <div class="img" data-aos="fade-left" data-aos-duration="1000">
        <img class="pc" src="../../../assets/images/msect0602.png?ver=0" alt="" />
        <img class="mobile" src="../../../assets/images/msect0602_m.png?ver=0" alt="" />
      </div>
    </div>
    
    <!-- 공지사항 팝업 s -->
    <!-- <div class="popup-wrap">
      <div v-for="(popup, index) in rePopupInfo" :key="index">
        <div :class="'popup tit noticePopUp' + index">
          <NoticePopup ref="NoticePopup" :popup="popup" :index="index">
            <template v-slot:title>{{ popup.title }}</template>
            <template v-slot:ntSbst><p v-html="popup.ntSbst"></p></template>
          </NoticePopup>
        </div>
      </div>
    </div> -->
    <!--  e: popup  -->
    <div class="dim" v-if="rePopupInfo.length > 0">
      <div v-for="(popup, index) in rePopupInfo" :key="index">
        <div :class="'notice-modal noticePopUp' + index">
          <div class="modal-content">
            <div class="modal-header">
              <button v-if="!isMobile" type="button" class="btn-close" @click="popModalClose(index, popup.ntNo)">
                <span class="blind">닫기</span>
              </button>
              <h2>{{ popup.title }}</h2>
            </div>
            <div class="modal-body">
              <div class="content" v-html="popup.ntSbst"></div>
            </div>
            <div class="modal-footer">
              <label>
                <input type="checkbox" id="todayClose" v-model="checked[index]"/>
                <span>오늘 하루동안 보지 않기</span>
              </label>
            </div>
            <div v-if="isMobile" class="mo_footer mo_only">
              <button @click="popModalClose(index, popup.ntNo)">닫기</button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!--	e: msect06 - 가입하러가기 	-->
    <!-- s: Layer Popup 레이어팝업 -->
    <!-- <LayerPopup /> -->
    <!-- e: Layer Popup 레이어팝업 -->
  </div>
  <!--	e: 	contents	-->
</template>
<script>
import { swiper, swiperSlide } from "vue-awesome-swiper";
import MobileGnb from "@/components/MobileGnb.vue";
import confirm from "@/modules/commonUtil/service/confirm";
// import NoticePopup from "../../servcieCenter/components/noticePopUp.vue";
import popupApi from "../../servcieCenter/service/serviceCenterApi";
//import LayerPopup from "@/components/LayerPopup.vue";

export default {
  name: "serviceInfo",
  components: { swiper, swiperSlide, MobileGnb, },
  data() {
    return {
      serviceJoinUrl: "/",
      isMobile: false,
      pcStandard: 896, // PC 화면의 Viewport 기준점
      Mswiper: {
        loop: false,
        /* speed: 1000,
        pagination: {
          clickable: true,
          el: ".swiper-pagination",
        },
        observer: true,
        observeParents: true,
        autoplay: {
          delay: 3000,
          disableOnInteraction: false,
        },
        on: {
          init: function () {
            this.thisSlide = this;
            this.autoPlayBtn = document.querySelector(".swiper-controll-btn");
            this.autoPlayBtn.addEventListener("click", () => {
              this.autoPlayState = this.autoPlayBtn.getAttribute("aria-pressed");
              if (this.autoPlayState == "false") {
                this.autoPlayBtn.setAttribute("aria-pressed", "true");
                this.autoPlayBtn.classList.add("play");
                this.autoPlayBtn.classList.remove("pause");
                this.thisSlide.autoplay.stop();
              } else if (this.autoPlayState === "true") {
                this.autoPlayBtn.setAttribute("aria-pressed", "false");
                this.autoPlayBtn.classList.add("pause");
                this.autoPlayBtn.classList.remove("play");
                this.thisSlide.autoplay.start();
              }
            });
          },
        }, */
      },
      sect4swiper: {
        slidesPerView: 3,
        centeredSlides: true,
        spaceBetween: 30,
        loop: false,
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
        speed: 800,
        breakpoints: {
          896: {
            slidesPerView: 1.8,
            centeredSlides: true,
          },
          450: {
            slidesPerView: 1.5,
            centeredSlides: true,
          },
          370: {
            slidesPerView: 1,
            centeredSlides: true,
            spaceBetween: 10,
          },
        },
      },
      tabSwiper01: {
        slidesPerView: 1.4,
        centeredSlides: true,
        loop: false,
        speed: 800,
        observer: true,
        observeParents: true,
        pagination: {
          clickable: true,
          el: ".tab01_pagination",
        },
      },
      tabSwiper02: {
        slidesPerView: 1.4,
        centeredSlides: true,
        loop: false,
        speed: 800,
        observer: true,
        observeParents: true,
        pagination: {
          clickable: true,
          el: ".tab02_pagination",
        },
      },
      tabSwiper03: {
        slidesPerView: 1.4,
        centeredSlides: true,
        loop: false,
        speed: 800,
        observer: true,
        observeParents: true,
        pagination: {
          clickable: true,
          el: ".tab03_pagination",
        },
      },
      cSeq: [],
      popCloSeq: [],
      popSeq: [],
      popupInfo: [],
      rePopupInfo: [],
      checked : [false, false, false, false, false],
      closeCnt : 0,
    };
  },
  created() {
    this.checkIsMobile();
  },
  mounted() {
    window.addEventListener("resize", this.handleResize);
    this.$refs.tabcont41.click();
    const baseUrl = window.location.origin;
    // 개발기일 경우 서비스 가입 주소
    if (baseUrl.indexOf("mhez-dev.uplus.co.kr") > -1) {
      this.serviceJoinUrl = "https://dev.bizmarket.uplus.co.kr/bizSvcDetail?sltnId=BPZ0000015";
      // 검수기일 경우 서비스 가입 주소
    } else if (baseUrl.indexOf("mhez-qa.uplus.co.kr") > -1) {
      this.serviceJoinUrl = "https://staging.bizmarket.uplus.co.kr/bizSvcDetail?sltnId=BPZ0000015";
      // 상용기일 경우 서비스 가입 주소
    } else if (baseUrl.indexOf("mhez.uplus.co.kr") > -1) {
      this.serviceJoinUrl = "https://bizmarket.uplus.co.kr/bizSvcDetail?sltnId=BPZ0000015";
    }
    this.fnSelectPopup();
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.handleResize);
  },
  methods: {
    // 문자채널 탭
    openTab(evt, tabName) {
      var i, tabcontent, tablinks;
      tabcontent = document.getElementsByClassName("tabcont");
      for (i = 0; i < tabcontent.length; i++) {
        tabcontent[i].style.opacity = "0";
        tabcontent[i].style.zIndex = "0";
        tabcontent[i].classList.remove("aos-init");
        tabcontent[i].classList.remove("aos-animate");
      }
      tablinks = document.getElementsByClassName("tab_li");
      for (i = 0; i < tablinks.length; i++) {
        tablinks[i].className = tablinks[i].className.replace(" on", "");
      }
      document.getElementById(tabName).style.opacity = "1";
      document.getElementById(tabName).style.zIndex = "1";
      document.getElementById(tabName).classList.add("aos-init");
      document.getElementById(tabName).classList.add("aos-animate");
      evt.currentTarget.className += " on";
    },
    checkIsMobile: function () {
      this.isMobile = window.innerWidth < this.pcStandard || screen.width < this.pcStandard;
    },
    handleResize: function () {
      this.$nextTick(function () {
        this.checkIsMobile();
      });
    },
    bizReady() {
      confirm.fnAlert("", "비즈마켓 서비스 신규 오픈 작업으로 이용이 제한됩니다. <br/>(서비스 오픈일 : 2022년 12월 12일)", "alert");
    },


    //공지사항 팝업 대상조회
    async fnSelectPopup(){
      let params ={}
			await popupApi.getPopupInfo(params).then((response) => {
				var result = response.data;
				if (result.success) {
          this.popupInfo = result.data.popupInfo;
          for(let i=0; i<result.data.popupInfo.length; i++){
            this.cSeq.push(result.data.popupInfo[i].ntNo);
          }
        }})
        this.fnPopupModalOpen();
    },

    //팝업처리 시작
    async fnPopupModalOpen(){
      //1. 쿠키에 남아있는 팝업 시퀀스 가져오기
      for(var i=0; i < this.cSeq.length; i++){
          let ntno = this.cSeq[i];
          this.popCloSeq.push(this.$cookies.get("popupYn"+ntno));
      }
      //2. 공지사항 목록에서 쿠키 존재하는 팝업 시퀀스 제외하기
      this.popSeq = this.cSeq.filter(x=>!this.popCloSeq.includes(x));

      //3. 쿠키에 존재하지 않는 팝업 정보만 가지기
      for(let i=0;i<this.popupInfo.length;i++){
        for(let j=0;j<this.popSeq.length;j++){ 
          if(this.popSeq[j] === this.popupInfo[i].ntNo){
            //await 안해주면 rePopupInfo의 인덱스를 못잡아서 class를 못찾음
            await this.rePopupInfo.push(this.popupInfo[i]);
          }
        }
      }

      //4. 모달 창 호출
      for(let i=0;i<this.rePopupInfo.length; i++){
        this.popModalOpen("noticePopUp"+i);
      }
    },


    //모달창
    popModalOpen(target) {
      var wrap = document.getElementsByClassName("notice-modal");
      var obj = document.getElementsByClassName(target);
      obj[0].classList.add("open");
      wrap[0].style.display = "block";
      obj[0].style.display = "block";
    },

    popModalClose(index, ntNo) {
      
			//오늘 하루 보지 않기 선택 시, 쿠키의 ntNo값(공지사항 시퀀스) 셋팅
			if(this.checked[index]){
				let date = new Date();
				date.setDate(date.getDate() + 1);
				this.$cookies.set("popupYn"+ntNo, ntNo, date);
			}
			// var target = document.getElementsByClassName("notice-modal");
      // var dimmed = document.getElementsByClassName("dim");
			var target = document.getElementsByClassName("noticePopUp" + index);
      // dimmed[0].style.display = "none";
			target[0].style.display = "none";
			target[0].classList.remove("open");
			this.checked[index] = false;

      this.closeCnt++;
      if(this.closeCnt==this.popSeq.length){
        var dimmed = document.getElementsByClassName("dim");
        dimmed[0].style.display = "none";
      }
		},

  },
};
</script>