import axios from "axios";
import store from "@/store/index";
//import * as utils from "@/common/utils";
import confirm from "@/modules/commonUtil/service/confirm";
import { eventBus } from "@/modules/commonUtil/service/eventBus";
//import { consts } from "@/common/config";

const config = {
  // baseURL: apiBaseUrl,
  headers: {
    "X-Requested-With": "XMLHttpRequest",
    "show-layer": "Yes",
  },
  timeout: 0, // timeout은 무제한으로 설정
};

const httpClient = axios.create(config);

const setLoginInterceptor = (config) => {
  return config;
};

const authInterceptor = (config) => {
  // frontend와 backend의 origin이 다른 경우
  // devServer.proxy 설정을 하던지 baseURL과 withCredentials 설정을 해야 한다.
  // cookie, header 등에 자격정보 설정이 필요한 api는 true 설정으로 호출해야 하고
  // 자격정보 설정이 필요없는 api는 withCredentials=false 설정으로 호출해야 한다.
  // config.withCredentials = !config.url.startsWith('/api/public/');
  return config;
};

const loggerInterceptor = (config) => {
  return config;
};

const loadingLayerInterceptor = (config) => {
  //loadingLayer(true, config);
  store.commit("startSpinner");
  return config;
};

/** Adding the request interceptors */
httpClient.interceptors.request.use(authInterceptor);
httpClient.interceptors.request.use(loggerInterceptor);
httpClient.interceptors.request.use(loadingLayerInterceptor);
httpClient.interceptors.request.use(setLoginInterceptor);

/** Adding the response interceptors */
httpClient.interceptors.response.use(
  (response) => {
    store.commit("endSpinner");
    if (response.data.code != undefined && response.data.code != null) {
      if (response.data.code == "NOT_LOGIN") {
        store.commit("login/isAuth", false);
      }
    }
    return response;
  },
  (error) => {
    if (error.response != undefined && error.response != null) {
      store.commit("endSpinner");
    } else {
      store.commit("endSpinner");
    }
    
    if (error.response.status == 402) {
      alert("세션이 만료되었습니다.");
      window.top.location.href = "/login";
      return Promise.reject(error);
    }else{
      if (error.code === "ECONNABORTED") {
        confirm.fnAlert("", "서비스가 지연되고 있습니다. 잠시 후 확인하시고 다시 시도해주세요. <br/> (ECONNABORTED)", "alert");
        return Promise.reject(error);
      } else if (error.response.status == 401 || error.response.status == 418) {
        eventBus.$on("callbackEventBus", () => {
          window.top.location.href = "/login";
        });
        confirm.fnConfirm("", "세션이 만료되었습니다.", "cbAlert");
      } else if (error.response.status == 402) {
        eventBus.$on("callbackEventBus", () => {
          window.top.location.href = "/login";
        });
        confirm.fnConfirm("", "로그아웃 되었습니다.", "cbAlert");
      } else if (error.response.status == 500) {
        if (error.response.data != null && error.response.data.message == "511 NETWORK_AUTHENTICATION_REQUIRED") {
          confirm.fnAlert("", "웹템플릿 IP가 브랜드포털에 등록이 필요합니다. 기술지원에 문의해주세요.", "alert");
          return Promise.reject(error);
        } else {
          window.top.location.href = "/500";
        }
      } else if (error.response.status == 504) {
        confirm.fnAlert("", "서비스가 지연되고 있습니다. 잠시 후 확인하시고 다시 시도해주세요. (504)", "alert");
        return Promise.reject(error);
      } else if (error.message == "Network Error") {
        confirm.fnAlert("", "네트워크 오류가 발생했습니다. 잠시 후 다시 시도해주세요.", "alert");
        return Promise.reject(error);
      } else {
        return Promise.reject(error);
      }
    } 
    store.commit("endSpinner");
  }
);

export default httpClient;
