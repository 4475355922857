/*
공통 컨펌창

간단하게 사용할 수 있는 공통 컨펌 창

해당 파일을 import한 뒤
import confirm from "@/modules/commonUtil/service/confirm"

alert창을 쓸 경우
confirm.fnAlert("타이틀", "메세지");

confirm창을 쓸 경우 이벤트버스를 import하고
import {eventBus} from "@/modules/commonUtil/service/eventBus";

eventBus.$on('callbackEventBus', this.fnCallback);
confirm.fnConfirm("타이틀", "메세지", "callback 버튼텍스트");

로 사용할 수 있다.

callback메소드는 반드시 해당 파일을 imoport한 페이지에 존재하여야 한다.

예시는 테스트 메뉴를 확인

2021-04-29
1. alert 후 callback이나 alert 후 alert 기능 추가
2. 메시지에 개행(\n) 처리되게 변경
//cTitle(필수) - 타이틀
//cMessage(필수) - 메세지
//cAfterType - CALLBACK 또는 ALERT(alert 후 callback이나 alert이 필요한 경우 사용)
//cParam - (callback function 에 파라미터를 넘겨야되는 경우 사용)
confirm.fnAlert(cTitle, cMessage, cAfterType, cParam)
*/
import Vue from "vue";
import { eventBus } from "./eventBus";

const fnAlert = (cTitle, cMessage, cAfterType, cParam) => {
  var confirmData = { cTitle: cTitle, cMessage: cMessage, cButtonView: false, cButton: "", cAfterType: cAfterType ? cAfterType : "", cParam: cParam };
  eventBus.$emit("confirmEventBus", confirmData);
};

const fnConfirm = (cTitle, cMessage, cButton, cParam) => {
  var confirmData = { cTitle: cTitle, cMessage: cMessage, cButtonView: true, cButton: cButton, cParam: cParam };
  eventBus.$emit("confirmEventBus", confirmData);
};

const fnConfirmCancle = (cTitle, cMessage, cButton, cParam) => {
  var confirmData = { cTitle: cTitle, cMessage: cMessage, cButtonView: true, cButton: cButton, cParam: cParam };
  eventBus.$emit("callbackCancleEventBus", confirmData);
};

// eslint-disable-next-line vue/multi-word-component-names
Vue.component("confirm", {
  /* template:
    '<div v-if="confirmOpen" class="modalStyle" id="confirm" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true" data-backdrop="static" style="position: absolute; top: 0; left: 0; width: 100%; height: 100%; background-color: dimgray; z-index: 10; opacity: 0.8;">' +
    '<div class="modal-dialog" style="position: absolute;width: 20%;padding: 20px;z-index: 99;top: 50%;left: 50%;transform: translate(-50%, -50%);background-color: white;display: flex;justify-content: center;">' +
    '<div class="modal-content">' +
    '<div class="modal-body">' +
    '<div class="float-left">' +
    '<h5 class="lc-1">{{cTitle}}</h5>' +
    '<p class="mt5 lc-1"><pre>{{cMessage}}</pre></p>' +
    "</div>" +
    '<div class="float-right">' +
    '<a class="btnStyle4 red" v-if="cButtonView" @click="fnCallback">{{cButton}}</a>' +
    '<a class="btnStyle4 gray ml5" v-if="cAfterType==\'CALLBACK\'" @click="fnAfterCallback">닫기</a>' +
    '<a class="btnStyle4 gray ml5" v-else-if="cAfterType==\'ALERT\'" @click="fnAfterAlert">닫기</a>' +
    '<a class="btnStyle4 gray ml5" v-else @click="fnConfirmHide">닫기</a>' +
    "</div>" +
    "</div>" +
    "</div>" +
    "</div>" +
    "</div>", */
  template: `
  <div v-if="confirmOpen">
    <div class="dimmed" style="display:block; z-index:300"></div>
    <template v-if="cAfterType === 'info'">
      <div class="popup-wrap" style="display:block">
        <div class="popup wide" style="display:block">
          <div class="pop-cont">
            <p class="pop-list-tit">이미지 첨부 기준 안내</p>
            <ul class="pop-list">
              <li>1,500(가로)x1,440(세로)px 이하 크기만 첨부할 수 있습니다.</li>
              <li>최대 3장 첨부할 수 있습니다. 전체 크기 합계가 300KB 이하여야 합니다.</li>
              <li>jpg파일 형식만 첨부할 수 있습니다.</li>
            </ul>
          </div>
          <div class="pop-btn1">
            <button type="button" class="btn large_pink" @click="fnConfirmHide">확인</button>
          </div>
        </div>
      </div>
    </template>
    <template v-else-if="cAfterType === 'alimInfo'">
      <div class="popup-wrap" style="display:block">
        <div class="popup wide" style="display:block">
          <div class="pop-cont">
            <p class="pop-list-tit">이미지 첨부 기준 안내</p>
            <ul class="pop-list">
              <li>1,500(가로)x1,440(세로)px 이하 크기만 첨부할 수 있습니다.</li>
              <li>최대 1장 첨부할 수 있습니다. 크기가 500KB 이하여야 합니다.</li>
              <li>jpg파일 형식만 첨부할 수 있습니다.</li>
            </ul>
          </div>
          <div class="pop-btn1">
            <button type="button" class="btn large_pink" @click="fnConfirmHide">확인</button>
          </div>
        </div>
      </div>
    </template>
    <template v-else>
      <div class="popup-wrap" style="display:block">
        <div class="popup" :class="cParam === '!' ? 'caution' : ''" style="display:block">
          <div class="pop-head" v-if="cParam === '!'"></div>
          <div class="pop-cont">
            <p class="pop-tit" v-html="cTitle"></p>
            <p class="pop-desc" v-html="cMessage"></p>
          </div>
          <div class="pop-btn1" v-if="cAfterType === 'alert' || cAfterType === 'message'">
            <button type="button" class="btn large_pink" @click="fnConfirmHide">확인</button>
          </div>
          <div class="pop-btn1" v-else-if="cButton === 'cbAlert'">
            <button type="button" class="btn large_pink" @click="fnAfterCallback">확인</button>
          </div>
          <div class="pop-btn2" v-else-if="cButton === 'confirm'">
            <button type="button" class="btn large_white" @click="fnConfirmHide">취소</button>
            <button type="button" class="btn large_pink" @click="fnAfterCallback">확인</button>
          </div>
          <div class="pop-btn2" v-else-if="cButton === 'revConfirm'">
            <button type="button" class="btn large_pink" @click="fnAfterCallback">확인</button>  
            <button type="button" class="btn large_white" @click="fnConfirmHide">취소</button>
          </div>
          <div class="pop-btn2" v-else-if="cButton === 'write'">
            <button type="button" class="btn large_pink" @click="fnConfirmHide">계속작성</button>
            <button type="button" class="btn large_white" @click="fnAfterCallback">취소</button>  
          </div>
          <div class="pop-btn2" v-else-if="cButton === 'reg'">
            <button type="button" class="btn large_pink" @click="fnAfterCallback">등록</button>
            <button type="button" class="btn large_white" @click="fnConfirmHide">취소</button>  
          </div>
          <div class="pop-btn2" v-else-if="cButton === 'accept'">
            <button type="button" class="btn large_pink" @click="fnAfterCallback">접수</button>  
            <button type="button" class="btn large_white" @click="fnConfirmHide">취소</button>
          </div>
          <div class="pop-btn2" v-else-if="cButton === 'send'">
            <button type="button" class="btn large_pink" @click="fnAfterCallback">보내기</button>  
            <button type="button" class="btn large_white" @click="fnConfirmHide">취소</button>
          </div>
          <div class="pop-btn2" v-else-if="cButton === 'save'">
          <button type="button" class="btn large_pink" @click="fnAfterCallback">저장</button>
            <button type="button" class="btn large_white" @click="fnConfirmHide">취소</button>
          </div>
          <div class="pop-btn2" v-else-if="cButton === 'del'">
          <button type="button" class="btn large_pink" @click="fnAfterCallback">삭제</button>
            <button type="button" class="btn large_white" @click="fnConfirmHide">취소</button>
          </div>
          <div class="pop-btn2" v-else-if="cButton === 'email'">
          <button type="button" class="btn large_pink" @click="fnAfterCallback">이메일 보내기</button>
            <button type="button" class="btn large_white" @click="fnConfirmHide">취소</button>
          </div>
          <div class="pop-btn2" v-else-if="cButton === 'agreeReq'">
          <button type="button" class="btn large_pink" @click="fnAfterCallback">승인 요청</button>
            <button type="button" class="btn large_white" @click="fnConfirmHide">취소</button>
          </div>
          <div class="pop-btn2" v-else-if="cButton === 'cUpdate'">
          <button type="button" class="btn large_pink" @click="fnConfirmHide">계속 수정</button>
            <button type="button" class="btn large_white" @click="fnAfterCallback">취소</button>
          </div>
          <div class="pop-btn2" v-else-if="cButton === 'update'">
          <button type="button" class="btn large_pink" @click="fnAfterCallback">수정</button>
            <button type="button" class="btn large_white" @click="fnAfterCancleCallback">취소</button>
          </div>
          <div class="pop-btn2" v-else-if="cButton === 'cReg'">
          <button type="button" class="btn large_pink" @click="fnConfirmHide">계속 등록</button>
            <button type="button" class="btn large_white" @click="fnAfterCallback">취소</button>
          </div>
          <div class="pop-btn2" v-else-if="cButton === 'msgChange'">
          <button type="button" class="btn large_pink" @click="fnAfterCallback">전환</button>
            <button type="button" class="btn large_white" @click="fnAfterCancleCallback">취소</button>
          </div>
          <div class="pop-btn2" v-else-if="cButton === 'logout'">
          <button type="button" class="btn large_pink" @click="fnAfterCallback">로그아웃</button>
            <button type="button" class="btn large_white" @click="fnConfirmHide">취소</button>
          </div>
          <div class="pop-btn2" v-else-if="cButton === 'sendNumberReg'">
          <button type="button" class="btn large_pink" @click="fnAfterCallback">발신번호 등록하기</button>
            <button type="button" class="btn large_white" @click="fnConfirmHide">닫기</button>
          </div>
          <div class="pop-btn2" v-else>
            <button type="button" class="btn large_white" @click="fnAfterCancleCallback">취소</button>
            <button type="button" class="btn large_pink" @click="fnAfterCallback">확인</button>
          </div>
          <div class="chkbox" v-if="cButton === 'send'" style="margin-top: 20px; text-align: end;">
            <input type="checkbox" class="chk" id="price" v-model="checked" />
            <label for="price" style="font-size:14px;">한달 동안 보지 않기</label>
          </div>
        </div>
      </div>
    </template>
    
  </div>`,
  created: function () {
    var vm = this;
    eventBus.$on("confirmEventBus", function (value) {
      vm.confirmOpen = true;
      vm.cTitle = value.cTitle;
      vm.cMessage = value.cMessage;
      vm.cButtonView = value.cButtonView;
      vm.cButton = value.cButton;
      vm.cAfterType = value.cAfterType ? value.cAfterType : "";
      vm.cParam = value.cParam;
    });

    eventBus.$on("callbackCancleEventBus", function () {
      //console.log(value);
    });
  },
  data() {
    return {
      confirmOpen: false,
      cTitle: "",
      cMessage: "",
      cButtonView: true,
      cButton: "",
      cAfterType: "",
      cParam: null,
      checked: false,
    };
  },
  methods: {
    fnCallback: function () {
      if (this.cButtonView) {
        eventBus.$emit("callbackEventBus", this.cParam);
      }
      this.fnConfirmHide();
    },
    fnConfirmHide: function () {
      eventBus.$off("callbackEventBus");
      //jQuery("#confirm").modal("hide");
      this.confirmOpen = false;
    },
    fnAfterCallback: function () {
      if (this.cButtonView) {
        eventBus.$emit("callbackEventBus", this.cParam);
      }
      if (this.checked) {
        // 쿠키 저장 후 전송
        let date = new Date();
        date.setDate(date.getDate() + 30);
        this.$cookies.set("prePriceNoti", "Y", date);
        this.checked = false;
      }

      this.fnConfirmHide();
    },
    fnAfterCancleCallback: function () {
      eventBus.$emit("callbackCancleEventBus", this.cParam);
      this.fnConfirmHide();
      eventBus.$off("callbackCancleEventBus");
    },
    fnAfterAlert: function () {
      eventBus.$emit("callbackEventBus", this.cParam);
    },
  },
});

export default {
  fnConfirm,
  fnAlert,
  fnConfirmCancle,
};
