<template>
  <section class="m_section">
    <!--	s: 	contents-->
    <div class="contents">
      <MobileGnb />
      <div class="tabcont_wrap without_tab notice_wrap">
        <h1 class="title">공지사항</h1>
        <form @submit.prevent="" autocomplete="off" class="search_form">
          <div class="search_box notice">
            <div class="group group01">
              <label for="right" class="label">종류</label>
              <div class="select_wrap">
                <select class="select" name="srchGbn" id="srchGbn" v-model="srchGbn">
                  <option value="01" selected>제목</option>
                  <option value="02">내용</option>
                  <option value="03">제목+내용</option>
                </select>
              </div>
            </div>
            <div class="group group02">
              <label for="right" class="label">검색어</label>
              <input type="text" class="search" v-model="srchText" placeholder="검색어를 입력하세요." @keyup.enter="fnSelectNoticeList" />
            </div>
            <button type="button" class="btn" @click="fnSelectNoticeList">검색</button>
          </div>
        </form>
        <div class="cs_tab">
          <ul>
            <li :class="allList ? 'on' : ''"><a @click="fnselectCategory('00')">전체</a></li>
            <li v-for="(data, index) in this.noticeCateList" :key="index" :class="{ on: data.clicked }">
              <a @click="fnselectCategory(data.dtlCd)">{{ data.dtlCdNm }}</a>
            </li>
          </ul>
        </div>
        <div class="list_area">
          <div class="list_info">
            <div class="left_wrap">
              <div class="select_wrap">
                <select class="select" v-model="selected" @change="fnSelected" v-show="false">
                  <option value="30" selected>30</option>
                  <option value="50">50</option>
                  <option value="100">100</option>
                </select>
              </div>
              <p class="total">
                총 <span class="pink">{{ this.totCnt }} </span>건
              </p>
            </div>
          </div>

          <div class="fixed-table-cover">
            <div class="fixed-table-container" style="width: 1074px">
              <div class="fixed-table-header-bg"></div>
              <div class="fixed-table-wrapper">
                <table class="fixed-table" style="table-layout: fixed">
                  <thead>
                    <tr>
                      <th class="td_cate" style="width: 11%">
                        <div class="th-text">구분</div>
                      </th>
                      <th class="td_title" style="width: 63%">
                        <div class="th-text">제목</div>
                      </th>
                      <th class="td_attachments" style="width: 11%">
                        <div class="th-text">첨부파일</div>
                      </th>
                      <th class="td_date" style="width: 15%">
                        <div class="th-text">등록일자</div>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-if="this.noticeList.length == 0 && (this.srchText == null || this.srchText == '')">
                      <td colspan="4">검색된 결과가 없습니다.</td>
                    </tr>
                    <tr v-if="this.noticeList.length == 0 && this.srchText !== null && this.srchText !== ''">
                      <td colspan="4">검색된 결과가 없습니다.</td>
                    </tr>

                    <tr :class="data.emgYn == 'Y' ? 'emergency' : ''" v-for="(data, index) in this.noticeList" :key="index">
                      <td class="td_cate">
                        <div :class="data.emgYn == 'Y' ? 'emergency' : 'event'" class="badge">{{ data.ctgCdNm }}</div>
                      </td>
                      <td class="td_title">
                        <router-link :to="{
                        path: `/serviceCenter/noticeDetail/${data.ntNo}`, query: {
                        num: data.num, ctgCd: selectedCtgCd, srchGbn, srchText } }">{{ data.title }}</router-link>
                      </td>
                      <td class="td_attachments">
                        <div class="btn white attachment" v-if="data.seqNo !== null"></div>
                      </td>
                      <td class="td_date">{{ data.regDt | textSubString(10) }}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>

          <!--
          <div class="list_cover">
            <div class="list_th_wrap" @scroll="handleThScroll($event)">
              <ul class="list_th">
                <li class="td_cate w120"><span>구분</span></li>
                <li class="td_title w670"><span>제목</span></li>
                <li class="td_attachments w120"><span>첨부파일</span></li>
                <li class="td_date w153"><span>등록일자</span></li>
              </ul>
            </div>
            <div class="list_wrap scr_wrap" @scroll="handleScroll($event)">
              <ul class="list">
                긴급일 경우
                <li class="emergency">
                  <span class="td_cate w120"><div class="badge emergency">긴급</div></span>
                </li>
                
                <li :class="data.emgYn == 'Y' ? 'emergency' : ''" v-for="(data, index) in this.noticeList" :key="index">
                  <span class="td_cate w120">
                    <div :class="data.emgYn == 'Y' ? 'emergency' : 'event'" class="badge">{{ data.ctgCdNm }}</div>
                  </span>
                  <span class="td_title w670">
                    <router-link :to="{ name: 'noticeDetail', params: { propsNtNo: data.ntNo } }">{{ data.title }}</router-link>
                  </span>
                  <span class="td_attachments w120">
                    <div class="btn white attachment" v-if="data.filePath !== null"></div>
                  </span>
                  <span class="td_date w153">{{ data.regDt | textSubString(10) }}</span>
                </li>

                <li class="empty" v-if="this.noticeList.length == 0 && (this.srchText == null || this.srchText == '')">
                  <span class="w100p">검색된 결과가 없습니다.</span>
                </li>

                <li class="empty" v-if="this.noticeList.length == 0 && this.srchText !== null && this.srchText !== ''">
                  <span class="w100p">검색된 결과가 없습니다.</span>
                </li>
              </ul>
            </div>
          </div>
        -->

          <div class="paging_wrap" v-show="totCnt > listSize">
            <PageLayer ref="updatePaging" @fnClick="fnSearch" :listTotalCnt="totCnt" :selected="listSize" :pageNum="pageNo"></PageLayer>
          </div>
        </div>
      </div>
    </div>
    <!--	e: 	contents	-->
  </section>
</template>
<script>
import PageLayer from "@/components/PageLayer.vue";
import noticeApi from "../service/serviceCenterApi";
import MobileGnb from "@/components/MobileGnb.vue";

export default {
  name: "defaultName",
  components: {
    PageLayer,
    MobileGnb,
  },
  data() {
    return {
      srchGbn: "01",
      srchText: "",
      allList: true,
      selectedCtgCd : "00",
      noticeList: [
        {
          ntNo: "",
          ctgCd: "",
          ctgCdNm: "",
          title: "",
          seqNo: "",
          regDt: "",
        },
      ],
      noticeCateList: {},
      listSize: 10,
      pageNo: 1,
      totCnt: 0,
      // totOrgCnt: "",
      offset: 0,
      selected: "10",
    };
  },
  beforeCreate() {},
  created() {
    this.fnSelectNoticeCategoryList();
    this.fnSelectNoticeList();
  },
  beforeMount() {},
  mounted() {},
  beforeUpdate() {},
  updated() {},
  beforeUnmount() {},
  unmounted() {},
  methods: {
    fnSelectNoticeList() {
      let params = {
        srchGbn: this.srchGbn,
        srchText: this.srchText,
        listSize: this.listSize, // select 박스 value (출력 갯수 이벤트)
        pageNo: this.pageNo, // 현재 페이징 위치
        totCnt: this.totCnt, //전체 리스트 수
        offset: this.offset, //페이지 시작점
        ctgCd: this.selectedCtgCd, //카테고리 코드
      };

      noticeApi.getSelectNoticeList(params).then((response) => {
        if (response.data.data != null) {
          // this.noticeOrgList = response.data.data;
          this.noticeList = response.data.data;
          this.totCnt = response.data.pageInfo.totCnt;
          // this.totOrgCnt = response.data.pageInfo.totCnt;
        }
      });
    },

    fnSelected() {
      this.listSize = Number(this.selected);
      this.$refs.updatePaging.fnAllDecrease();
    },

    fnSearch(pageNum) {
      //this.$refs.updatePaging.fnAllDecrease();
      this.pageNo = this.$gfnCommonUtils.defaultIfEmpty(pageNum, "1") * 1;
      this.fnSelectNoticeList();
    },

    fnSelectNoticeCategoryList() {
      let params = {
        grpCd: "NTBBS_CTG_CD",
      };
      noticeApi.getNoticeCategoryList(params).then((response) => {
        if (response.data.data != null) {
          this.noticeCateList = response.data.data;
        }
      });
    },

    fnselectCategory(ctgCd) {
      //초기화
      this.noticeList = "";
      this.totCnt = 0;
      this.pageNo = "1";
      this.selectedCtgCd = ctgCd;
      //카테고리 선택시 스타일
      this.noticeCateList.forEach((el) => {
        if (el.dtlCd == ctgCd) {
          el.clicked = true;
        } else {
          el.clicked = false;
        }
      });

      //카테고리 리스트
      if (this.selectedCtgCd != "00") {
        this.allList = false;
      } else this.allList = true;

      this.fnSelectNoticeList();
    },

    // fnSelectByCategory(chDtlCd) {
    //   if(chDtlCd !== "00") {
    //     this.noticeListByCate = this.noticeOrgList.filter((e) => {
    //       return e.ctgCd === chDtlCd;
    //     });

    //     this.noticeList = this.noticeListByCate;
    //     this.allList = false
    //     this.totCnt = this.noticeList.length
    //   } else {
    //     this.noticeList = this.noticeOrgList;
    //     this.allList = true
    //     this.totCnt = this.totOrgCnt

    //   }

    //   this.noticeCateList.forEach(el => {
    //     if(el.dtlCd == chDtlCd) {
    //       el.clicked = true;
    //     } else {
    //       el.clicked = false;
    //     }
    //   });
    // },

    /*handleScroll(e) {
        const chooseListThWrap = e.target.parentNode.children[0];
        chooseListThWrap.scrollLeft = e.target.scrollLeft;
	},
    handleThScroll(e) {
      const chooseListWrap = e.target.parentNode.children[1];
      chooseListWrap.scrollLeft = e.target.scrollLeft;
    },*/
  },
};
</script>
