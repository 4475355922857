import httpClient from "@/common/http-client";

// 비밀번호 확인
const confirmPwd = (params) => {
  return httpClient.post("/api/user/confirmPwd", params, { headers: { "show-layer": "yse"} });
};

// 사용자 정보 조회
const selectUserInfo = (params) => {
  return httpClient.post("/api/user/selectUserInfo", params, { headers: { "show-layer": "yse"} });
};

// 사용자 정보 수정
const updateUserInfo = (params) => {
  return httpClient.post("/api/user/updateUserInfo", params, { headers: { "show-layer": "yse"} });
};

// 사용자 정보 수정
const changePwdExec = (params) => {
  return httpClient.post("/api/user/changePwdExec", params, { headers: { "show-layer": "yse"} });
};

// 대시보드 정보 조회
const getDashBoardInfo = (params) => {
  return httpClient.post("/api/user/getDashBoardInfo", params, { headers: { "show-layer": "yse"} });
};

export default {
  confirmPwd,
  selectUserInfo,
  updateUserInfo,
  changePwdExec,
  getDashBoardInfo
};
