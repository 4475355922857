import httpClient from "@/common/http-client";

const selectAutInfoChnnel = (params) => {
  return httpClient.post("/api/channel/selectAutInfoChnnel", params, { headers: { "show-layer": "Yes" } });
};

/* 알림톡 샌더키*/
const selectSenderKeyList = (params) => {
  return httpClient.post("/templateApi/manage/selectSenderKeyList", params, { headers: { "show-layer": "Yes", activity: "READ" } });
};

// 발신번호 조회
const selectCallbackList = (params) => {
  return httpClient.post("/api/sendMessage/selectCallbackList", params, { headers: { "show-layer": "yse", activity: "READ" } });
};
// SMS, LMS 메시지 전송
const sendMessage = (params) => {
  return httpClient.post("/api/sendMessage/sendMessage", params, { headers: { "show-layer": "Yes", activity: "SAVE", "Content-Type": "multipart/form-data" } });
};

// 080 수신거부 전화번호 조회
const rejectPhoneList = (params) => {
  return httpClient.post("/api/ars080reject/rejectPhoneList", params, { headers: { "show-layer": "Yes" } });
};

// 최신 전송그룹 조회
const selectRecentSendGroup = (params) => {
  return httpClient.post("/api/sendMessage/selectRecentSendGroup", params, { headers: { "show-layer": "Yes", activity: "READ" } });
};
// 최신 전송그룹 리스트 조회
const selectRecentSendRecvInfo = (params) => {
  return httpClient.post("/api/sendMessage/selectRecentSendRecvInfo", params, { headers: { "show-layer": "Yes", activity: "READ" } });
};

// 발신번호 조회
const callerIdList = (params) => {
  return httpClient.post("/api/callerId/callerIdList", params, { headers: { "show-layer": "Yes", activity: "READ" } });
};

// 차단 발신 번호 조회
const blckCallerId = (params) => {
  return httpClient.post("/api/callerId/blckCallerId", params, { headers: { "show-layer": "Yes", activity: "READ" } });
};

// 최근작성문자 조회
const selectRecentSendMessageList = (params) => {
  return httpClient.post("/api/sendMessage/selectRecentSendMessageList", params, { headers: { "show-layer": "Yes", activity: "READ" } });
};

// 예상 금액 조회
const getPreAmount = (params) => {
  return httpClient.post("/api/sendMessage/getPreAmount", params, { headers: { "show-layer": "Yes", activity: "READ" } });
};

// 전송 한도 조회
const getSendLimit = (params) => {
  return httpClient.post("/api/sendMessage/getSendLimit", params, { headers: { "show-layer": "Yes", activity: "READ" } });
};

// 사용 요금 조회
const getUseCharge = (params) => {
  return httpClient.post("/api/sendMessage/getUseCharge", params, { headers: { "show-layer": "Yes", activity: "READ" } });
};

// 카카오톡 채널Id 조회
const selectChannelId = (params) => {
  return httpClient.post("/api/channel/selectChannelId", params, { headers: { "show-layer": "Yes", activity: "READ" } });
};

//주소록 조회
const selectAddressGrpList = (params) => {
  return httpClient.post("/api/address/selectAddressGrpList", params, { headers: { "show-layer": "Yes", activity: "READ" } });
};
const searchAddressGrpList = (params) => {
  return httpClient.post("/api/address/searchAddressGrpList", params, { headers: { "show-layer": "Yes", activity: "READ" } });
};
const selectAddrMemberList = (params) => {
  return httpClient.post("/api/address/selectAddrMemberList", params, { headers: { "show-layer": "Yes", activity: "READ" } });
};
const selectGrpAddrMemberList = (params) => {
  return httpClient.post("/api/address/selectGrpAddrMemberList", params, { headers: { "show-layer": "Yes", activity: "READ" } });
};
const selectAddressGrpSelList = (params) => {
  return httpClient.post("/api/address/selectAddressGrpSelList", params, { headers: { "show-layer": "Yes", activity: "READ" } });
};
const registerAddrMember = (params) => {
  return httpClient.post("/api/address/registerAddrMember", params, { headers: { "show-layer": "Yes", activity: "SAVE" } });
};
const registerAddrGrp = (params) => {
  return httpClient.post("/api/address/registerAddrGrp", params, { headers: { "show-layer": "Yes", activity: "SAVE" } });
};
const massRegAddrMember = (params) => {
  return httpClient.post("/api/address/massRegAddrMember", params, { headers: { "show-layer": "Yes", activity: "SAVE" } });
};

//공유주소록
//주소록 조회
const selectAddressPubGrpList = (params) => {
  return httpClient.post("/api/addressPub/selectAddressPubGrpList", params, { headers: { "show-layer": "Yes", activity: "READ" } });
};
const searchAddressPubGrpList = (params) => {
  return httpClient.post("/api/addressPub/searchAddressPubGrpList", params, { headers: { "show-layer": "Yes", activity: "READ" } });
};
const selectAddrPubMemberList = (params) => {
  return httpClient.post("/api/addressPub/selectAddrPubMemberList", params, { headers: { "show-layer": "Yes", activity: "READ" } });
};
const selectGrpAddrPubMemberList = (params) => {
  return httpClient.post("/api/addressPub/selectGrpAddrPubMemberList", params, { headers: { "show-layer": "Yes", activity: "READ" } });
};
const selectAddressPubGrpSelList = (params) => {
  return httpClient.post("/api/addressPub/selectAddressPubGrpSelList", params, { headers: { "show-layer": "Yes", activity: "READ" } });
};
const registerAddrPubMember = (params) => {
  return httpClient.post("/api/addressPub/registerAddrPubMember", params, { headers: { "show-layer": "Yes", activity: "SAVE" } });
};
const registerAddrPubGrp = (params) => {
  return httpClient.post("/api/addressPub/registerAddrPubGrp", params, { headers: { "show-layer": "Yes", activity: "SAVE" } });
};
const massRegAddrPubMember = (params) => {
  return httpClient.post("/api/addressPub/massRegAddrPubMember", params, { headers: { "show-layer": "Yes", activity: "SAVE" } });
};

//문자 보관함
const insertMsgStorage = (params) => {
  return httpClient.post("/api/msgstorage/insertMsgStorage", params, { headers: { "show-layer": "Yes", activity: "SAVE", "Content-Type": "multipart/form-data" } });
};
const selectMsgStorage= (params) => {
  return httpClient.post("/api/msgstorage/selectMsgStorage", params, { headers: { "show-layer": "Yes", activity: "SAVE" } });
}
const deleteMsgStorage= (params) => {
  return httpClient.post("/api/msgstorage/deleteMsgStorage", params, { headers: { "show-layer": "Yes", activity: "SAVE" } });
}
const updateMsgStoreRegDt= (params) => {
  return httpClient.post("/api/msgstorage/updateMsgStoreRegDt", params, { headers: { "show-layer": "Yes", activity: "SAVE" } });
}
export default {
  selectAutInfoChnnel,
  selectSenderKeyList,
  selectCallbackList,
  selectAddressGrpList,
  searchAddressGrpList,
  selectAddrMemberList,
  selectGrpAddrMemberList,
  sendMessage,
  selectRecentSendGroup,
  selectRecentSendRecvInfo,
  callerIdList,
  selectRecentSendMessageList,
  selectChannelId,
  getPreAmount,
  getUseCharge,
  getSendLimit,
  selectAddressGrpSelList,
  registerAddrMember,
  registerAddrGrp,
  massRegAddrMember,
  rejectPhoneList,
  blckCallerId,
  selectAddressPubGrpList,
  searchAddressPubGrpList,
  selectAddrPubMemberList,
  selectGrpAddrPubMemberList,
  selectAddressPubGrpSelList,
  registerAddrPubMember,
  registerAddrPubGrp,
  massRegAddrPubMember,
  insertMsgStorage,
  selectMsgStorage,
  deleteMsgStorage,
  updateMsgStoreRegDt,
};
