<template>
  <section class="m_section">
    <!--	s: 	contents-->
    <div class="contents">
      <MobileGnb />
      <div class="tabcont_wrap without_tab faq_wrap">
        <h1 class="title">자주하는 질문</h1>
        <div class="search_box faq">
          <div class="group group01">
            <label for="right" class="label">종류</label>
            <div class="select_wrap">
              <select class="select" name="srchGbn" id="srchGbn" v-model="srchGbn">
                <option value="01" selected>제목</option>
                <option value="02">내용</option>
                <option value="03">제목+내용</option>
              </select>
            </div>
          </div>
          <div class="group group02">
            <label for="right" class="label">검색어</label>
            <input type="text" class="search" v-model="srchText" placeholder="검색어를 입력하세요." @keyup.enter="fnSelectFaqList" />
          </div>
          <button type="submit" class="btn" @click="fnSelectFaqList">검색</button>
        </div>
        <div class="cs_tab">
          <ul>
            <li :class="allList ? 'on' : ''"><a @click="fnselectCategory('00')">전체</a></li>
            <li v-for="(data, index) in this.faqCateList" :key="index" :class="{ on: data.clicked }">
              <a @click="fnselectCategory(data.dtlCd)">{{ data.dtlCdNm }}</a>
            </li>
            <!-- 분류 : 변경될 수 있음 -->
          </ul>
        </div>
        <div class="list_area">
          <div class="list_info">
            <div class="left_wrap">
              <div class="select_wrap">
                <!-- <select class="select" v-model="selected" @change="fnSelected">
                  <option value="30" selected>30</option>
                  <option value="50">50</option>
                  <option value="100">100</option>
                </select> -->
              </div>
              <p class="total">
                총 <span class="pink">{{ this.totCnt }} </span>건
              </p>
            </div>
          </div>

          <div class="list_cover">
            <!-- 220928 display:table로 세로 scroll 삭제 가로만 scroll 부여 -->
            <div class="list_table">
              <div class="list_th_wrap">
                <ul class="list_th">
                  <li class="td_cate"><span>구분</span></li>
                  <li class="td_title"><span>제목</span></li>
                </ul>
              </div>
              <div class="list_wrap scr_wrap">
                <ul class="list">
                  <li v-show="false"></li>
                  <li v-for="(data, index) in this.faqList" :key="index">
                    <span class="td_cate">
                      <div class="badge">{{ data.ctgCdNm }}</div>
                    </span>
                    <span class="td_title faq_q">
                      <a id="title2" @click="fnShowAndHide()">Q. {{ data.title }}</a>
                    </span>
                    <div class="td_answer" id="faqSbst" v-html="'A. ' + data.faqSbst"></div>
                  </li>
                  <li class="empty" v-if="this.faqList.length == 0 && (this.srchText == null || this.srchText == '')">
                    <span>데이터가 없습니다.</span>
                  </li>
                  <li class="empty" v-if="this.faqList.length == 0 && this.srchText !== null && this.srchText !== ''">
                    <span>검색된 결과가 없습니다.</span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div class="paging_wrap" v-show="totCnt > listSize">
            <PageLayer ref="updatePaging" @fnClick="fnSearch" :listTotalCnt="totCnt" :selected="listSize" :pageNum="pageNo"></PageLayer>
          </div>
        </div>
      </div>
    </div>
    <!--	e: 	contents	-->
  </section>
</template>
<script>
import PageLayer from "@/components/PageLayer.vue";
import faqApi from "../service/serviceCenterApi";
import MobileGnb from "@/components/MobileGnb.vue";

export default {
  name: "defaultName",
  components: {
    PageLayer,
    MobileGnb,
  },
  data() {
    return {
      srchGbn: "01",
      srchText: "",
      allList: true,
      faqList: [
        {
          faqNo: "",
          ctgCd: "",

          ctgCdNm: "",
          title: "",
          faqSbst: "",
          faqSbstChg: "",
          regDt: "",
        },
      ],
      faqCateList: {},
      listSize: 10,
      pageNo: 1,
      totCnt: 0,
      offset: 0,
      selected: "30",
    };
  },
  mounted() {
    this.fnShowAndHide();
  },
  created() {
    this.fnSelectFaqCategoryList();
    this.fnSelectFaqList();
  },
  methods: {
    //faq 목록 리스트 조회
    async fnSelectFaqList() {
      let params = {
        srchGbn: this.srchGbn,
        srchText: this.srchText,
        listSize: this.listSize, // select 박스 value (출력 갯수 이벤트)
        pageNo: this.pageNo, // 현재 페이징 위치
        totCnt: this.totCnt, //전체 리스트 수
        offset: this.offset, //페이지 시작점
        ctgCd: this.ctgCd, //카테고리 코드
      };

      await faqApi.getSelectFaqList(params).then((response) => {
        if (response.data.data != null) {
          this.faqList = response.data.data;
          this.totCnt = response.data.pageInfo.totCnt;
        }
      });

      var faqQ = document.getElementsByClassName("faq_q");

      for (var i = 0; i < faqQ.length; i++) {
        // 버튼 상태에 입혀진 active 라는 클래스를 지운다.
        faqQ[i].classList.remove("active");
        faqQ[i].nextElementSibling.style.maxHeight = null;
        faqQ[i].parentElement.classList.remove("on");
      }
    },

    //페이징
    fnSelected() {
      this.listSize = Number(this.selected);
      this.$refs.updatePaging.fnAllDecrease();
    },

    fnSearch(pageNum) {
      this.pageNo = this.$gfnCommonUtils.defaultIfEmpty(pageNum, "1") * 1;
      this.fnSelectFaqList();
    },

    //카테고리 리스트 조회
    fnSelectFaqCategoryList() {
      let params = {
        grpCd: "FAQ_CTG_CD",
      };
      faqApi.getFaqCategoryList(params).then((response) => {
        if (response.data.data != null) {
          this.faqCateList = response.data.data;
        }
      });
    },

    fnselectCategory(ctgCd) {
      // 초기화
      this.faqList = "";
      this.totCnt = 0;
      this.pageNo = "1";

      this.ctgCd = ctgCd;
      //카테고리 선택시 스타일
      this.faqCateList.forEach((el) => {
        if (el.dtlCd == ctgCd) {
          el.clicked = true;
        } else {
          el.clicked = false;
        }
      });
      //카테고리 리스트
      if (this.ctgCd != "00") {
        this.allList = false;
      } else this.allList = true;

      this.fnSelectFaqList();
    },

    // fnSelectByCategory(chDtlCd) {
    //   if(chDtlCd !== "00") {
    //     this.faqListByCate = this.faqOrgList.filter((e) => {
    //       return e.ctgCd === chDtlCd;
    //     });

    //     this.faqList = this.faqListByCate;
    //     this.allList = false
    //     this.totCnt = this.faqList.length
    //   } else {
    //     this.faqList = this.faqOrgList;
    //     this.allList = true
    //     this.totCnt = this.totOrgCnt
    //   }

    //   this.faqCateList.forEach(el => {
    //     if(el.dtlCd == chDtlCd) {
    //       el.clicked = true;
    //     } else {
    //       el.clicked = false;
    //     }
    //   });
    // },

    //상세내용
    fnShowAndHide() {
      // 클릭하면 답변 보이기
      var faqQ = document.getElementsByClassName("faq_q");

      for (var i = 0; i < faqQ.length; i++) {
        faqQ[i].onclick = function () {
          // 클릭이 일어났을 때 기존에 열려 있던 아코디언을 접는다. (1개의 아코디언만 열리게)
          for (var j = 0; j < faqQ.length; j++) {
            // 버튼 상태에 입혀진 active 라는 클래스를 지운다.
            faqQ[j].classList.remove("active");
            // 버튼 다음에 있는 div 콘텐츠 높이를 0으로 만든다. == 아코디언을 접는다.
            if (this !== faqQ[j]) {
              faqQ[j].nextElementSibling.style.maxHeight = null;
              faqQ[j].parentElement.classList.remove("on");
            }
          }

          this.classList.toggle("active");
          this.parentElement.classList.toggle("on");
          var faqA = this.nextElementSibling;
          if (faqA.style.maxHeight) {
            this.classList.remove("active");
            this.parentElement.classList.remove("on");
            faqA.style.maxHeight = null;
          } else {
            faqA.style.maxHeight = faqA.scrollHeight + "px";
          }
        };
      }
    },
  },
};
</script>
<style>
#title2 {
  text-decoration: none;
  color: black;
}
</style>
