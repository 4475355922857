export default {
  install(Vue) {
    Vue.prototype.$gfnCommonUtils = {
      /**************************문자열 관련 Utils**************************/
      // Entity 문자열 치환
      fromHtmlEntities(str) {
        return (str + "").replace(/&#\d+;/gm, function (s) {
          return String.fromCharCode(s.match(/\d+/gm)[0]);
        });
      },
      // 파일 문자열 줄이기
      fileTextLengthCut(txt, len, lastTxt) {
        if (!this.isEmpty(txt)) {
          const filePathSplit = txt.split(".");
          if (len == "" || len == null) {
            // 기본값
            len = 15;
          }
          if (lastTxt == "" || lastTxt == null) {
            // 기본값
            lastTxt = "...";
          }
          if (txt.length > len) {
            txt = txt.substr(0, len) + lastTxt + "." + filePathSplit[1];
          }
        }
        return txt;
      },
      // 문자열 줄이기
      textLengthCut(txt, len, lastTxt) {
        if (len == "" || len == null) {
          // 기본값
          len = 15;
        }
        if (lastTxt == "" || lastTxt == null) {
          // 기본값
          lastTxt = "...";
        }
        if (txt.length > len) {
          txt = txt.substr(0, len) + lastTxt;
        }
        return txt;
      },
      //빈 문자열 확인
      isEmpty(str) {
        if (str == "" || str == null || str == undefined || (str != null && typeof str == "object" && !Object.keys(str).length)) return true;
        else return false;
      },
      //str이 빈값이 아니면 str 리턴 빈값이면 defaultStr 리턴
      defaultIfEmpty(str, defaultStr) {
        defaultStr = this.isEmpty(defaultStr) ? "" : defaultStr;
        return this.isEmpty(str) ? defaultStr : str;
      },
      isEmptyStr(str, chgTxt) {
        if (str == "" || str == "null" || str == null || str == undefined || (str != null && typeof str == "object" && !Object.keys(str).length)) {
          return chgTxt;
        } else {
          return str;
        }
      },
      //lpad
      lpad(str, padLen, padStr) {
        if (padStr.length > padLen) return str;
        str += ""; // 문자로
        padStr += ""; // 문자로
        while (str.length < padLen) str = padStr + str;
        str = str.length >= padLen ? str.substring(0, padLen) : str;
        return str;
      },
      // 문자열 줄바꿈 치환
      newLineToBr(str) {
        if (this.isEmpty(str)) return str;
        return str.replace(/(?:\r\n|\r|\n)/g, "<br/>");
      },
      //3자리 콤마, 소수점 존재시 소수점 그대로 리턴
      formatPrice(val) {
        if (val == 0) return "0";
        if (!val) return "";
        val = val.toString();
        if (val.indexOf(".") > 0) {
          let arrVal = val.split(".");
          if (arrVal.length == 2) {
            return arrVal[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",") + "." + arrVal[1];
          }
        }
        return val.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      },
      formatComma(val) {
        if (!val) return "0";
        val = val.toString();

        return val.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      },
      formatUncomma(val) {
        if (!val) return "0";
        val = val.toString();

        return val.replace(/[^0-9]/g, "");
      },
      phoneNumAddDash(val) {
        if (!val) return "";
        val = val.toString();
        val = val.replace(/[^0-9]/g, "");

        let tmp = "";
        if (val.length < 4) {
          return val;
        } else if (val.length < 7) {
          tmp += val.substr(0, 3);
          tmp += "-";
          tmp += val.substr(3);
          return tmp;
        } else if (val.length == 8) {
          tmp += val.substr(0, 4);
          tmp += "-";
          tmp += val.substr(4);
          return tmp;
        } else if (val.length < 10) {
          if (val.substr(0, 2) == "02") {
            //02-123-5678
            tmp += val.substr(0, 2);
            tmp += "-";
            tmp += val.substr(2, 3);
            tmp += "-";
            tmp += val.substr(5);
            return tmp;
          }
        } else if (val.length < 11) {
          if (val.substr(0, 2) == "02") {
            //02-1234-5678
            tmp += val.substr(0, 2);
            tmp += "-";
            tmp += val.substr(2, 4);
            tmp += "-";
            tmp += val.substr(6);
            return tmp;
          } else {
            //010-123-4567
            tmp += val.substr(0, 3);
            tmp += "-";
            tmp += val.substr(3, 3);
            tmp += "-";
            tmp += val.substr(6);
            return tmp;
          }
        } else {
          //010-1234-5678
          tmp += val.substr(0, 3);
          tmp += "-";
          tmp += val.substr(3, 4);
          tmp += "-";
          tmp += val.substr(7);
          return tmp;
        }
      },
      //전화번호 입력 시 대시 입력(상단 함수가 오류날 경우 대체사용)
      hpNumberAddDash(val) {
        if (!val) return "";
        val = val.toString();
        val = val.replace(/[^0-9]/g, "");

        let tmp = "";
        if (val.length < 4) {
          return val;
        } else if (val.length <= 7) {
          tmp += val.substr(0, 3);
          tmp += "-";
          tmp += val.substr(3);
          return tmp;
        } else if (val.length == 8) {
          tmp += val.substr(0, 4);
          tmp += "-";
          tmp += val.substr(4);
          return tmp;
        } else if (val.length < 10) {
          tmp += val.substr(0, 2);
          tmp += "-";
          tmp += val.substr(2, 3);
          tmp += "-";
          tmp += val.substr(5);
          return tmp;
        } else if (val.length < 11) {
          if (val.substr(0, 2) == "02") {
            //02-1234-5678
            tmp += val.substr(0, 2);
            tmp += "-";
            tmp += val.substr(2, 4);
            tmp += "-";
            tmp += val.substr(6);
            return tmp;
          } else {
            //010-123-4567
            tmp += val.substr(0, 3);
            tmp += "-";
            tmp += val.substr(3, 3);
            tmp += "-";
            tmp += val.substr(6);
            return tmp;
          }
        } else {
          //010-1234-5678
          tmp += val.substr(0, 3);
          tmp += "-";
          tmp += val.substr(3, 4);
          tmp += "-";
          tmp += val.substr(7);
          return tmp;
        }
      },
      hpNumberRemoveDash(val) {
        if (!val) return "";
        val = val.toString();
        val = val.replace(/[^0-9]/g, "");

        return val;
      },
      getTextLen(str) {
        var strLength = 0;
        if (str) {
          for (var i = 0; i < str.length; i++) {
            var code = str.charCodeAt(i);
            var ch = str.substr(i, 1).toUpperCase();
            code = parseInt(code);
            if ((ch < "0" || ch > "9") && (ch < "A" || ch > "Z") && (code > 255 || code < 0)) {
              strLength = strLength + 1;
            } else {
              strLength = strLength + 1;
            }
          }
        }
        return strLength;
      },
      getByte(str) {
        var strLength = 0;
        if (str) {
          for (var i = 0; i < str.length; i++) {
            var code = str.charCodeAt(i);
            var ch = str.substr(i, 1).toUpperCase();
            code = parseInt(code);
            if ((ch < "0" || ch > "9") && (ch < "A" || ch > "Z") && (code > 255 || code < 0)) {
              strLength = strLength + 2;
            } else if (ch == "\n") {
              strLength = strLength + 2;
            } else {
              strLength = strLength + 1;
            }
          }
        }
        return strLength;
      },
      textSubString(txt, len) {
        return txt.substring(0, len);
      },
      // Formatbyte
      formatBytes(bytes, decimals = 2) {
        if (bytes === 0) return "0 Bytes";

        const k = 1024;
        const dm = decimals < 0 ? 0 : decimals;
        const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
        const i = Math.floor(Math.log(bytes) / Math.log(k));

        return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
      },
      //바이트 자르기
      cutStr(str, limit) {
        var strLength = 0;
        var strTitle = "";
        var strPiece = "";

        for (let i = 0; i < str.length; i++) {
          var code = str.charCodeAt(i);
          var ch = str.substr(i, 1).toUpperCase();
          //체크 하는 문자를 저장
          strPiece = str.substr(i, 1);
          code = parseInt(code);

          if ((ch < "0" || ch > "9") && (ch < "A" || ch > "Z") && (code > 255 || code < 0)) {
            strLength = strLength + 2; //UTF-8 2byte 로 계산
          } else if (ch == "\n") {
            strLength = strLength + 2;
          } else {
            strLength = strLength + 1;
          }

          if (strLength > limit) {
            //제한 길이 확인
            break;
          } else {
            strTitle = strTitle + strPiece; //제한길이 보다 작으면 자른 문자를 붙여준다.
          }
        }
        return strTitle;
      },
      //글자수 자르기
      cutText(str, limit) {
        return str.substr(0, limit - 1);
      },
      /**************************문자열 관련 Utils**************************/
      /**************************날짜관련 관련 Utils**************************/
      //현재일자 return
      getCurretDate(format) {
        format = this.defaultIfEmpty(format, "yyyy-mm-dd");
        return this.formatDate(new Date(), format);
      },
      //현재월 return
      getCurretMonth: function (format) {
        format = this.defaultIfEmpty(format, "yyyy-mm");
        return this.formatDate(new Date(), format);
      },
      //포맷에 맞는 날짜 return
      formatDate(date, format) {
        const map = {
          mm: this.lpad(date.getMonth() + 1, 2, "0"),
          dd: this.lpad(date.getDate(), 2, "0"),
          yy: date.getFullYear().toString().slice(-2),
          yyyy: date.getFullYear().toString(),
        };
        return format.replace(/mm|dd|yyyy|yy/gi, (matched) => map[matched]);
      },
      //날짜(일) 더하기
      strDateAddDay(dateStr, interval) {
        const dateParts = dateStr.split("-");
        const sDate = new Date(dateParts[0], dateParts[1] - 1, dateParts[2]);
        sDate.setDate(sDate.getDate() + interval);
        return this.formatDate(sDate, "yyyy-mm-dd");
      },
      //날짜(월) 더하기
      strDateAddMonth(dateStr, interval) {
        const dateParts = dateStr.split("-");
        const sDate = new Date(dateParts[0], dateParts[1] - 1, dateParts[2]);
        sDate.setMonth(sDate.getMonth() + interval);
        return this.formatDate(sDate, "yyyy-mm");
      },
      //날짜(월) 더하기
      strDateAddMonthDt(dateStr, interval) {
        const dateParts = dateStr.split("-");
        const sDate = new Date(dateParts[0], dateParts[1] - 1, dateParts[2]);
        sDate.setMonth(sDate.getMonth() + interval);
        return this.formatDate(sDate, "yyyy-mm-dd");
      },
      //날짜(월) 더하기  검색 월 리턴
      strDateAddMonthDtSrch(dateStr, interval) {
        const dateParts = dateStr.split("-");
        const sDate = new Date(dateParts[0], dateParts[1] - 1, dateParts[2]);
        sDate.setMonth(sDate.getMonth() + interval);
        return this.formatDate(sDate, "yyyy-mm");
      },
      //날짜(월) 더하기  검색  리턴
      strDateAddDaySrch(dateStr, interval) {
        const dateParts = dateStr.split("-");
        const sDate = new Date(dateParts[0], dateParts[1] - 1, dateParts[2]);
        sDate.setMonth(sDate.getMonth() + interval);
        return this.formatDate(sDate, "yyyy-mm-dd");
      },
      /**************************날짜관련 관련 Utils**************************/
      /**************************정규식 관련 Utils**************************/
      isRemoveHangle(str) {
        return str.replace(/[ㄱ-ㅎ|ㅏ-ㅣ|가-힣]/g, "");
      },
      isDashNum(str) {
        let regex = /^(\d{2}|\d{3}|\d{4})-?(\d{3}|\d{4})-?(\d{4})$/;
        return regex.test(str.toString());
      },
      isUrl(str) {
        let regex = /(http|https):\/\/(\S+)/g;
        return regex.test(str);
      },
      unescapeXss(str) {
        if (this.isEmpty(str)) return str;
        let unescapedStr = str
          .replace(/&gt;/g, ">")
          .replace(/&lt;/g, "<")
          .replace(/&#40;/g, "(")
          .replace(/&#41;/g, ")")
          .replace(/&amp;/g, "&")
          .replace(/&apos;/g, "'")
          .replace(/&quot;/g, '"')
          .replace(/&nbsp;/g, " ")
          .replace(/&#39;/g, "'");
        return unescapedStr;
      },
      unescapeXssFields(targetObj, fields) {
        if (fields == null || Array.isArray(fields) == false || fields.length == 0) return targetObj;
        fields.forEach((field) => {
          targetObj[field] = this.unescapeXss(targetObj[field]);
        });
      },
      /**************************정규식 관련 Utils**************************/
      /**************************비즈니스 관련 Utils**************************/
      //친구톡 광고성 야간발송 제한(20~8)
      islimitAdMsgSendTime(hours) {
        hours = this.isEmpty(hours) ? new Date().getHours() : hours;
        return hours <= 8 || hours >= 20;
      },
      //ch - SMS, MMS, RCS, ALIMTALK, FRIENDTALK, PUSH
      //str - 검사내용
      //return - result = {
      //            success: ture/false(유효성 통과여부)
      //            message: '', '변수 phone, title, description 은 예약어로 사용하실 수 없습니다.'(통과시 '', 실패시 '' 또는 해당 메시지)
      //          }
      validContainRsvNm(ch, str) {
        let result = {
          success: true,
          message: "",
        };

        if (this.isEmpty(ch) || this.isEmpty(str)) {
          result.success = false;
          result.message = "시스템 오류입니다. 잠시후 다시 시도해주세요.";
          console.error("validContainRsvNm 잘못된 파라미터 정보 ==> ch: " + ch + ", str : " + str);
          return result;
        }

        let rsvNmSet;
        if (ch == "PUSH") {
          rsvNmSet = new Set(["cuid", "phone", "title", "description"]);
        } else if (ch == "SMS" || ch == "MMS" || ch == "ALIMTALK" || ch == "FRIENDTALK") {
          rsvNmSet = new Set(["phone", "title", "description"]);
        } else if (ch == "RCS") {
          rsvNmSet = new Set(["phone"]);
        }

        if (!rsvNmSet || rsvNmSet.length == 0) {
          result.success = false;
          result.message = "시스템 오류입니다. 잠시후 다시 시도해주세요.";
          console.error("validContainRsvNm 잘못된 채널정보입니다. ==> ch: " + ch + ", rsvNmSet : " + rsvNmSet);
          return result;
        }

        if (ch == "RCS") {
          str.replace(/\{\{(([a-z|A-Z|0-9|ㄱ-ㅎ|ㅏ-ㅣ|가-힣|_])+)\}\}/g, function ($0, $1) {
            if (rsvNmSet.has($1)) {
              result.success = false;
              return false;
            }
          });
        } else {
          str.replace(/#\{(([a-z|A-Z|0-9|ㄱ-ㅎ|ㅏ-ㅣ|가-힣|_])+)\}/g, function ($0, $1) {
            if (rsvNmSet.has($1)) {
              result.success = false;
              return false;
            }
          });
        }

        if (result.success == false) {
          const rsvNmStr = Array.from(rsvNmSet).join(", ");
          result.message = "변수 " + rsvNmStr + " 은 예약어로 사용하실 수 없습니다.";
        }

        return result;
      },
      /**************************비즈니스 관련 Utils**************************/
    };
  },
};
