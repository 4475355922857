<template>
  <div class="box_wrap radiobox_wrap">
    <div class="box_top">
      <h3 class="tit">
        전송예약
        <div class="btn_question tt_btn">
          <div class="tooltip l_tooltip">
            <p>예약 또는 반복 예약 내역은</p>
            <p>예약 메시지 내역 메뉴에서 확인할 수 있습니다.</p>
          </div>
        </div>
      </h3>
      <div class="top_right">
        <div class="radio_wrap">
          <ul>
            <li>
              <input type="radio" name="time" class="radio" id="time1" ref="time1" value="N" checked @click="sendTimeSettingView('N')" />
              <label for="time1">바로 전송</label>
            </li>
            <li>
              <input type="radio" name="time" class="radio" id="time2" value="R" @click="sendTimeSettingView('R')" />
              <label for="time2">예약하기</label>
            </li>
            <li>
              <input type="radio" name="time" class="radio" id="time3" value="P" @click="sendTimeSettingView('P')" />
              <label for="time3">반복 예약하기</label>
            </li>
          </ul>
          <ul class="radio_cont_wrap" v-if="nowSendView" :style="{ display: rsSendView || rprsSendView ? 'block' : 'none' }">
            <li class="radio_time2" v-if="rsSendView" :style="{ display: rsSendView ? 'block' : 'none' }">
              <div class="radio_cont">
                <div class="input_wrap">
                  <input
                    type="date"
                    class="date"
                    v-model="sendTimeData.reqDt"
                    :min="this.$gfnCommonUtils.getCurretDate()"
                    @change="
                      getHour(sendTimeData.reqDt);
                      getMin(sendTimeData.reqDt);
                    "
                  />
                  <div class="select_wrap">
                    <select name="" id="" class="select" v-model="rsHour" @change="getMin(sendTimeData.reqDt)">
                      <option :value="String(10 > n + startHour ? '0' + (n + startHour) : n + startHour)" v-for="(n, index) in endHour" :key="index">{{ n + startHour }}</option>
                    </select>
                  </div>
                  <span class="input_txt">시</span>
                  <div class="select_wrap">
                    <select name="" id="" class="select" v-model="rsMin" @change="sumRsvCfg3('R')">
                      <option :value="String(10 > (n + startMin) * 5 ? '0' + (n + startMin) * 5 : (n + startMin) * 5)" v-for="(n, index) in endMin" :key="index">
                        {{ (n + startMin) * 5 }}
                      </option>
                    </select>
                  </div>
                  <span class="input_txt">분</span>
                </div>
                <p class="noti">*현재 시간을 기준으로 5분 이후부터 예약하실 수 있습니다.</p>
              </div>
            </li>
            <li class="radio_time3" v-if="rprsSendView" :style="{ display: rprsSendView ? 'block' : 'none' }">
              <div class="radio_cont">
                <div class="input_wrap">
                  <div class="line">
                    <input
                      type="date"
                      class="date"
                      v-model="sendTimeData.rsvStart"
                      :min="this.$gfnCommonUtils.getCurretDate()"
                      @change="
                        get6MonthDate(sendTimeData.rsvStart);
                        getRpHour(sendTimeData.rsvStart);
                        getRpMin(sendTimeData.rsvStart);
                      "
                    />
                    <span class="input_txt">부터</span>
                    <input type="date" class="date" v-model="sendTimeData.rsvEnd" :min="sendTimeData.rsvStart" :max="sendTimeData.rsvEnd6month" />
                    <span class="input_txt">까지</span>
                  </div>
                  <div class="line">
                    <div class="select_wrap repeatOpt">
                      <select name="" id="" class="select" @change="optCont" v-model="sendTimeData.rsvCfg1">
                        <option value="D">매일</option>
                        <option value="W">매주</option>
                        <option value="M">매월</option>
                      </select>
                    </div>
                    <template v-if="sendTimeData.rsvCfg1 === 'D'">
                      <input type="text" class="number" v-model="sendTimeData.mada" @input="fnRsvCfg2Mada" oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');" />
                      <span class="input_txt">일마다</span>
                    </template>
                    <template v-else-if="sendTimeData.rsvCfg1 === 'W'">
                      <input type="text" class="number" v-model="sendTimeData.mada" @input="fnRsvCfg2dayWeek" oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');" />
                      <span class="input_txt">주마다</span>
                    </template>
                    <template v-else>
                      <input type="text" class="number" v-model="sendTimeData.mada" @input="fnMondivCheck" oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');" />
                      <span class="input_txt">개월마다</span>
                    </template>
                    <!-- 매주 선택시 -->
                    <div class="chk_wrap week" v-show="sendTimeData.rsvCfg1 === 'W'">
                      <input type="checkbox" class="chk" id="week01" v-model="sendTimeData.rsvCfg2dayWeek" value="0" @change="fnRsvCfg2dayWeek" /><label for="week01">일</label>
                      <input type="checkbox" class="chk" id="week02" v-model="sendTimeData.rsvCfg2dayWeek" value="1" @change="fnRsvCfg2dayWeek" /><label for="week02">월</label>
                      <input type="checkbox" class="chk" id="week03" v-model="sendTimeData.rsvCfg2dayWeek" value="2" @change="fnRsvCfg2dayWeek" /><label for="week03">화</label>
                      <input type="checkbox" class="chk" id="week04" v-model="sendTimeData.rsvCfg2dayWeek" value="3" @change="fnRsvCfg2dayWeek" /><label for="week04">수</label>
                      <input type="checkbox" class="chk" id="week05" v-model="sendTimeData.rsvCfg2dayWeek" value="4" @change="fnRsvCfg2dayWeek" /><label for="week05">목</label>
                      <input type="checkbox" class="chk" id="week06" v-model="sendTimeData.rsvCfg2dayWeek" value="5" @change="fnRsvCfg2dayWeek" /><label for="week06">금</label>
                      <input type="checkbox" class="chk" id="week07" v-model="sendTimeData.rsvCfg2dayWeek" value="6" @change="fnRsvCfg2dayWeek" /><label for="week07">토</label>
                    </div>
                    <!-- 매월 선택시 -->
                    <div class="radio_wrap month" v-show="sendTimeData.rsvCfg1 === 'M'">
                      <input type="radio" class="radio" id="period1" name="period" v-model="sendTimeData.monDiv" value="D" @change="fnMondivCheck" checked />
                      <label for="period1">{{ sendTimeData.tomada }}일</label>
                      <input type="radio" class="radio" id="period2" name="period" v-model="sendTimeData.monDiv" value="W" @change="fnMondivCheck" />
                      <label for="period2">{{ sendTimeData.dayOfTheWeekNum }}번째 {{ sendTimeData.dayOfTheWeekStr }}요일</label>
                    </div>
                  </div>
                  <div class="line">
                    <div class="select_wrap">
                      <select name="" id="" class="select" v-model="rprsHour" @change="getRpMin($gfnCommonUtils.getCurretDate())">
                        <option :value="String(10 > n + startRpHour ? '0' + (n + startRpHour) : n + startRpHour)" v-for="(n, index) in endRpHour" :key="index">
                          {{ n + startRpHour }}
                        </option>
                      </select>
                    </div>
                    <span class="input_txt">시</span>
                    <div class="select_wrap">
                      <select name="" id="" class="select" v-model="rprsMin" @change="sumRsvCfg3('P')">
                        <option :value="String(10 > (n + startRpMin) * 5 ? '0' + (n + startRpMin) * 5 : (n + startRpMin) * 5)" v-for="(n, index) in endRpMin" :key="index">
                          {{ (n + startRpMin) * 5 }}
                        </option>
                      </select>
                    </div>
                    <span class="input_txt">분</span>
                  </div>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "sendTime",
  components: {},
  watch: {
    sendTimeData: {
      handler: function () {
        this.fnSetSendTime();
      },
      deep: true,
    },
  },
  mounted() {
    this.getDay();
    this.getDayOfTheWeek();
    this.getHour(this.$gfnCommonUtils.getCurretDate());
    this.getMin(this.$gfnCommonUtils.getCurretDate());
    this.getRpHour(this.$gfnCommonUtils.getCurretDate());
    this.getRpMin(this.$gfnCommonUtils.getCurretDate());
    this.get6MonthDate(this.$gfnCommonUtils.getCurretDate());
  },
  data() {
    return {
      sendTimeData: {
        rsvtType: "N", // 전송타입 (즉시전송 : N / 예약전송 : R / 반복예약전송 : P)
        reqDt: this.$gfnCommonUtils.getCurretDate(), // 예약전송 날짜 변수 선언
        rsvCfg1: "D", // 반본예약전송 매일:d, 매주:w, 매월:m
        rsvCfg2: "", // 매주 : 0,3,6  (일:0 월:1 ~ 토:6) 체크된 값 콤마로 구분하여 전달
        rsvCfg3: "",
        rsvStart: this.$gfnCommonUtils.getCurretDate(), // 반복예약전송 시작일시
        rsvEnd: "", // 반본예약전송 종료일시
        rsvEnd6month: "", // 반본예약전송 6개월 종료일시
        mada: "", // 일마다 주마다 월마다
        tomada: "",
        monDiv: "D", //매달 선택시 라디오 버튼 구분 값 //D : 일마다 , W 주마다 요일
        rsvCfg2dayWeek: [], // 매주 : 0,3,6  (일:0 월:1 ~ 토:6) 체크된 값 콤마로 구분하여 전달
        dayOfTheWeek: "", // 숫자 요일 일:0, 월:1, 화:2
        dayOfTheWeekStr: "", // 한글 요일 => 월,화,수
        dayOfTheWeekNum: "", // 몇번째 주인지 확인
      },
      rsHour: "00", // 예약전송 날짜 변수 선언
      rsMin: "00", // 예약전송 날짜 변수 선언
      rprsHour: "00", // 반본예약전송 시간
      rprsMin: "00", // 반본예약전송 분
      nowSendView: false, // 즉시전송 클릭시 토글 변수
      rsSendView: false, // 예약전송 클릭시 토글 변수
      rprsSendView: false, // 반본예약전송 클릭시 토글 변수

      startHour: 0,
      endHour: 24,
      startMin: 0,
      endMin: 11,

      startRpHour: 0,
      endRpHour: 24,
      startRpMin: 0,
      endRpMin: 11,
    };
  },
  methods: {
    getDay() {
      let nowday = new Date(this.sendTimeData.rsvStart);
      let day = nowday.getDate();
      this.sendTimeData.tomada = day;
    },
    getDayOfTheWeek() {
      const d = new Date();
      const day = d.getDay();
      let dayStr = "";
      if (day == 0) {
        dayStr = "일";
      } else if (day == 1) {
        dayStr = "월";
      } else if (day == 2) {
        dayStr = "화";
      } else if (day == 3) {
        dayStr = "수";
      } else if (day == 4) {
        dayStr = "목";
      } else if (day == 5) {
        dayStr = "금";
      } else if (day == 6) {
        dayStr = "토";
      }

      this.sendTimeData.dayOfTheWeek = day;
      this.sendTimeData.dayOfTheWeekStr = dayStr;
      this.sendTimeData.dayOfTheWeekNum = Math.ceil(d.getDate() / 7);
    },
    getHour(date) {
      if (date === this.$gfnCommonUtils.getCurretDate()) {
        const today = new Date();
        const hour = today.getHours();
        const min = today.getMinutes();
        let changeHour = 1;
        if (min >= 50) {
          changeHour = 1;
        } else {
          changeHour = 0;
        }
        this.startHour = hour - 1 + changeHour;
        this.endHour = 24 - Number(hour) - changeHour;
      } else {
        this.endHour = 24;
        this.startHour = -1;
      }

      this.rsHour = String(10 > this.startHour + 1 ? "0" + (this.startHour + 1) : this.startHour + 1);
    },
    getMin(date) {
      //초기값
      this.startMin = -1;
      this.endMin = 12;

      if (parseInt(this.rsHour) === 0) {
        this.startMin = 2;
        this.endMin = 9;
      }
      if (date === this.$gfnCommonUtils.getCurretDate()) {
        let today = new Date();
        today.setMinutes(today.getMinutes() + 5);
        const hour = today.getHours();
        const min = today.getMinutes();

        if (parseInt(this.rsHour) === parseInt(hour)) {
          this.startMin = parseInt(min / 5);
          this.endMin = parseInt((60 - Number(min)) % 5) === 0 ? parseInt((60 - Number(min)) / 5) - 1 : parseInt((60 - Number(min)) / 5);
        }
      }

      this.rsMin = String(10 > (this.startMin + 1) * 5 ? "0" + (this.startMin + 1) * 5 : (this.startMin + 1) * 5);
      this.sendTimeData.rsvCfg3 = this.rsHour + this.rsMin;
    },
    sumRsvCfg3(type) {
      if (type === "R") {
        this.sendTimeData.rsvCfg3 = this.rsHour + this.rsMin;
      } else {
        this.sendTimeData.rsvCfg3 = this.rprsHour + this.rprsMin;
      }
    },
    getRpHour(date) {
      //초기값
      this.startRpHour = -1;
      this.endRpHour = 24;

      if (date === this.$gfnCommonUtils.getCurretDate()) {
        const today = new Date();
        const hour = today.getHours();
        const min = today.getMinutes();
        let changeHour = 1;
        if (min >= 50) {
          changeHour = 1;
        } else {
          changeHour = 0;
        }
        this.startRpHour = hour - 1 + changeHour;
        this.endRpHour = 24 - Number(hour) - changeHour;
      }

      this.rprsHour = String(10 > this.startRpHour + 1 ? "0" + (this.startRpHour + 1) : this.startRpHour + 1);
      //  시작시간 변경시 매월 데이터 변경
      let nowday = new Date(date);
      let day = nowday.getDate();
      this.sendTimeData.tomada = day;
      const today = nowday.getDay();
      let dayStr = "";
      if (today == 0) {
        dayStr = "일";
      } else if (today == 1) {
        dayStr = "월";
      } else if (today == 2) {
        dayStr = "화";
      } else if (today == 3) {
        dayStr = "수";
      } else if (today == 4) {
        dayStr = "목";
      } else if (today == 5) {
        dayStr = "금";
      } else if (today == 6) {
        dayStr = "토";
      }

      this.sendTimeData.dayOfTheWeek = day;
      this.sendTimeData.dayOfTheWeekStr = dayStr;
      this.sendTimeData.dayOfTheWeekNum = Math.ceil(day / 7);
    },
    getRpMin(date) {
      // 초기값
      this.endRpMin = 12;
      this.startRpMin = -1;

      if (parseInt(this.rprsHour) === 0) {
        this.startRpMin = 2;
      }

      if (date === this.$gfnCommonUtils.getCurretDate()) {
        let today = new Date();
        today.setMinutes(today.getMinutes() + 5);
        const hour = today.getHours();
        const min = today.getMinutes();

        if (parseInt(this.rprsHour) === parseInt(hour)) {
          this.startRpMin = parseInt(min / 5);
          this.endRpMin = parseInt((60 - Number(min)) % 5) === 0 ? parseInt((60 - Number(min)) / 5) - 1 : parseInt((60 - Number(min)) / 5);
        }
      }

      this.rprsMin = String(10 > (this.startRpMin + 1) * 5 ? "0" + (this.startRpMin + 1) * 5 : (this.startRpMin + 1) * 5);
      this.sendTimeData.rsvCfg3 = this.rprsHour + this.rprsMin;
    },
    // 전송시각 토글 오픈 (즉시전송 / 예약전송 / 반복예약전송)
    sendTimeSettingView(type) {
      this.sendTimeView = type;

      if (type === "R") {
        // 예약전송
        this.nowSendView = true;
        this.rsSendView = true;
        this.rprsSendView = false;

        this.sendTimeData.rsvCfg3 = this.rsHour + this.rsMin;
      } else if (type === "P") {
        // 반복예약전송
        this.nowSendView = true;
        this.rsSendView = false;
        this.rprsSendView = true;

        this.sendTimeData.rsvCfg3 = this.rprsHour + this.rprsMin;
      } else {
        // 즉시전송
        this.nowSendView = false;
        this.rsSendView = false;
        this.rprsSendView = false;
        this.sendTimeData.rsvCfg3 = "";
      }
      this.sendTimeData.rsvtType = type;
    },
    optCont() {
      if (this.sendTimeData.rsvCfg1 == "D") {
        this.sendTimeData.rsvCfg2 = "";
        this.sendTimeData.mada = "";
      } else if (this.sendTimeData.rsvCfg1 == "W") {
        this.sendTimeData.rsvCfg2 = "";
        this.sendTimeData.mada = "";
        this.rsvCfg2dayWeek = [];
      } else if (this.sendTimeData.rsvCfg1 == "M") {
        this.sendTimeData.rsvCfg2 = "";
        this.sendTimeData.mada = "";
      }
    },
    fnRsvCfg2dayWeek() {
      if (this.sendTimeData.rsvCfg1 === "W") {
        if (this.sendTimeData.rsvCfg2dayWeek.length > 0) {
          this.sendTimeData.rsvCfg2 = this.sendTimeData.mada + "|" + this.sendTimeData.rsvCfg2dayWeek.join(",");
        }
      } else {
        this.sendTimeData.rsvCfg2 = "";
      }
    },
    fnMondivCheck() {
      if (this.sendTimeData.monDiv === "D") {
        this.sendTimeData.rsvCfg2 = this.sendTimeData.mada + "|" + this.sendTimeData.tomada;
      } else {
        this.sendTimeData.rsvCfg2 = this.sendTimeData.mada + "|" + this.sendTimeData.dayOfTheWeekNum + "|" + this.sendTimeData.dayOfTheWeek;
      }
    },
    fnRsvCfg2Mada(e) {
      this.sendTimeData.rsvCfg2 = e.target.value;
    },
    fnSetSendTime() {
      this.$emit("fnSetSendTime", this.sendTimeData);
    },
    get6MonthDate(date) {
      let selectedDate = new Date(date);
      let AfterSixMonthDate = new Date(selectedDate.setMonth(selectedDate.getMonth() + 6));
      const year = AfterSixMonthDate.getFullYear();
      const month = AfterSixMonthDate.getMonth() + 1;
      const day = AfterSixMonthDate.getDate();
      this.sendTimeData.rsvEnd6month = `${year}-${month >= 10 ? month : "0" + month}-${day >= 10 ? day : "0" + day}`;
      this.sendTimeData.rsvEnd = `${year}-${month >= 10 ? month : "0" + month}-${day >= 10 ? day : "0" + day}`;
    },
  },
};
</script>
