<template>
  <!--	s: m_section	-->
  <section class="m_section p_including">
    <!-- class="p_including" : preview 포함 -->
    <!--	s: 	contents-->
    <div class="contents">
      <MobileGnb />
      <div class="tabcont_wrap without_tab template_write">
        <form @submit.prevent="" autocomplete="off">
          <div class="tabcont on">
            <h1 class="title">알림톡 템플릿 등록<span class="required required_desc">필수 입력</span></h1>
            <!-- 채널 ID -->
            <div class="box_wrap channel_wrap">
              <div class="box_top">
                <h3 class="tit required">채널 아이디</h3>
                <div class="top_right">
                  <div class="select_wrap">
                    <select name="" id="" class="select" v-model="alimTalkInfo.chnlId" @change="fnChnlAdd">
                      <option value="null">채널 아이디를 선택하세요.</option>
                      <option :value="list.chnlId" v-for="(list, index) in channelIdList" :key="index">{{ list.chnlId }}</option>
                      <option value="add">채널 아이디 추가 +</option>
                    </select>
                  </div>
                  <!-- <div class="btn1_wrap">
                    <button class="btn white plus" type="button" @click="$router.push({ path: '/sendNumberManage/channelIdManage' })">채널ID 추가</button>
                  </div> -->
                </div>
              </div>
            </div>
            <!-- 템플릿명 -->
            <div class="box_wrap template_wrap">
              <div class="box_top">
                <h3 class="tit required">템플릿명</h3>
                <div class="top_right">
                  <div class="input_wrap">
                    <input ref="tmpltNm" type="text" class="input" placeholder="템플릿명을 입력하세요 ※ 최대200자, 중복불가" v-model="alimTalkInfo.tmpltNm" @input="setTmpltNm" />
                  </div>
                </div>
              </div>
            </div>
            <!-- 카테고리 -->
            <div class="box_wrap category_wrap">
              <div class="box_top">
                <h3 class="tit required">카테고리</h3>
                <div class="top_right">
                  <div class="select_wrap">
                    <select name="" id="" class="select" @change="fnCate1Code" v-model="cate1Code">
                      <option value="" selected>카테고리그룹 선택</option>
                      <option v-for="(option, i) in cate1" v-bind:value="option.ctgGrpNm" v-bind:key="i">
                        {{ option.ctgGrpNm }}
                      </option>
                    </select>
                  </div>
                  <div class="select_wrap">
                    <select name="" id="" class="select" v-model="cate2Code">
                      <option value="" selected>카테고리 선택</option>
                      <option v-for="(option, i) in cate2" v-bind:value="option.tmpltCtgCd" v-bind:key="i">
                        {{ option.ctgNm }}
                      </option>
                    </select>
                  </div>
                </div>
              </div>
            </div>
            <!-- 보안템플릿여부 -->
            <div class="box_wrap security_wrap">
              <div class="box_top">
                <h3 class="tit">보안 적용 여부</h3>
                <div class="top_right">
                  <input type="checkbox" class="chk" id="template_chk" v-model="alimTalkInfo.securTmpltYn" />
                  <label for="template_chk">
                    템플릿 내용에
                    <span class="under pink">인증번호</span>,<span class="under pink">비밀번호 </span> 등 보안이 필요한 정보가 포함되어 있으면 체크해 주세요.</label
                  >
                </div>
              </div>
            </div>
            <!-- 강조유형 -->
            <div class="box_wrap hightlight_wrap">
              <div class="box_top with_noti">
                <h3 class="tit">강조유형</h3>
                <div class="top_right">
                  <div class="radio_wrap">
                    <ul>
                      <li>
                        <input type="radio" name="emphasis" class="radio" id="emphasis1" @click="emphasisView('NONE')" :checked="this.alimTalkInfo.emphsTpCd === 'NONE'" />
                        <label for="emphasis1">없음</label>
                      </li>
                      <li>
                        <input type="radio" name="emphasis" class="radio" id="emphasis2" @click="emphasisView('TEXT')" :checked="this.alimTalkInfo.emphsTpCd === 'TEXT'" />
                        <label for="emphasis2">강조표기형</label>
                      </li>
                    </ul>
                  </div>
                  <p class="noti" v-if="this.alimTalkInfo.emphsTpCd === 'TEXT'">*강조표기형을 사용할 경우 본문에 있는 내용만 타이틀 등록이 가능합니다.</p>
                </div>
              </div>
            </div>
            <!-- 강조표기형 선택시 -->
            <span class="emphasisCont box_wrap" v-show="this.alimTalkInfo.emphsTpCd === 'TEXT'">
              <!-- 강조표기 타이틀 -->
              <div class="box_wrap">
                <div class="box_top">
                  <h3 class="tit required">강조표기 타이틀</h3>
                  <div class="top_right">
                    <div class="input_wrap">
                      <input type="text" class="input em_tit" placeholder="강조표기할 제목을 입력하세요.(최대50자)" ref="emphsTitle" v-model="alimTalkInfo.emphsTitle" @input="setEmphsTitle" />
                    </div>
                  </div>
                </div>
              </div>
              <!-- 강조표기 보조문구 -->
              <div class="box_wrap">
                <div class="box_top">
                  <h3 class="tit required">강조표기 보조문구</h3>
                  <div class="top_right">
                    <div class="input_wrap">
                      <input type="text" class="input em_sub" placeholder="강조표기할 보조 문구을 입력하세요.(최대50자)" ref="emphsSubtle" v-model="alimTalkInfo.emphsSubtle" @input="setEmphsSubtle" />
                    </div>
                  </div>
                </div>
              </div>
            </span>
            <!-- 템플릿 유형 -->
            <div class="box_wrap select_templ">
              <div class="box_top">
                <h3 class="tit">템플릿 유형</h3>
                <div class="top_right">
                  <div class="select_wrap">
                    <!-- 0623 타입별 추가설명 추가 { -->
                    <div class="select_type_desc">
                      <p class="type01" :style="alimTalkInfo.tmpltTpCd === 'AD' ? 'display: block' : 'display:none'">광고성 정보를 포함하여 총 글자수가 1,000자 이내여야 합니다.</p>
                      <p class="type02" :style="alimTalkInfo.tmpltTpCd === 'EX' ? 'display: block' : 'display:none'">부가정보를 포함하여 총 글자수가 1,000자가 이내여야 합니다.</p>
                      <p class="type03" :style="alimTalkInfo.tmpltTpCd === 'MI' ? 'display: block' : 'display:none'">부가정보, 광고성메시지를 포함하여 총 글자수가 1,000자 이내여야 합니다.</p>
                    </div>
                    <!-- } 0623 타입별 추가설명 추가 -->
                    <select class="select" v-model="alimTalkInfo.tmpltTpCd" @change="optCont">
                      <option value="BA">기본형</option>
                      <option value="AD">광고추가형</option>
                      <option value="EX">부가정보형</option>
                      <option value="MI">복합형</option>
                    </select>
                  </div>
                </div>
              </div>
            </div>
            <!-- 내용 -->
            <div class="box_wrap inputbox_wrap">
              <div class="box_top">
                <h3 class="tit required">내용</h3>
              </div>
              <div class="box_cont inputbox">
                <div class="input_con">
                  <textarea
                    name="sendAlimContent"
                    id="sendAlimContent"
                    ref="sendAlimContent"
                    cols="30"
                    rows="10"
                    class="input_cont scr_wrap"
                    v-model="alimTalkInfo.tmpltSbst"
                    @input="setTmpltSbst"
                    placeholder="내용을 입력하세요. 
자동 변경 정보(변수)로 설정하고자 하는 내용을 #{변수명} 표시로 작성해 주세요.
예) 이름과 출금일 자동 변경 정보(변수) 설정 시, 
#{고객명}님 #{출금일} 출금 예정입니다."
                  ></textarea>
                </div>
                <div class="input_bottom">
                  <div class="count">
                    <span class="byte">{{ computeMsgByte }} <span class="limit">/ 1,000자 </span></span>
                  </div>
                </div>
                <div class="special_wrap">
                  <div class="btn_wrap">
                    <button class="btn white btn_vari" @click="variOn()" type="button">자동 변경 정보</button>
                  </div>
                  <div class="special_char"></div>
                  <div class="vari_txt">
                    <div class="vari_desc">
                      <p>사용하고자 하는 자동 변경 정보를 #{ } 표시로 입력해 주세요.</p>
                      <p>자동 변경 정보에 들어가는 문구는 5자 이내를 권장합니다.<br /></p>
                      <p>자동 변경 정보는 메시지 내용 중 특정 구역(A)를 지정하고, 데이터(B)가 입력된 파일을 이용하여 특정 구역(A)에 데이터(B)가 자동으로 삽입되는 기능입니다.</p>
                      <p><span>예)</span>① 문자에 #{이름} 삽입 <br />② 엑셀에 ‘이름’열(A열)에 김OO님, 이OO님 입력하여 업로드 <br />③#{이름}에 A열의 데이터가 자동으로 삽입되어 전송 됨</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <!-- 자동 변경 정보 -->
            <div class="box_wrap img_wrap" v-if="autoMsg.length > 0" style="margin-top: 15px">
              <div class="box_top">
                <h3 class="tit mg10r" style="width: 130px">
                  자동 변경 정보
                  <div class="btn_question tt_btn">
                    <div class="tooltip l_tooltip">
                      <ul>
                        <li><p>자동 변경 정보(변수)로 설정하고자 하는 내용을 #{변수명} 표시로 작성해 주세요.</p></li>
                        <li><p>예) 이름과 출금일 자동 변경 정보(변수) 설정 시,</p></li>
                        <li><p>#{고객명}님 #{출금일} 출금 예정입니다.</p></li>
                      </ul>
                    </div>
                  </div>
                </h3>
                <div class="btn1_wrap">
                  <span class="btn white small cpink" v-for="(text, index) in autoMsg" :key="index" style="border: 1px solid #f14b84; margin: 0 10px 5px 0">{{ text | textLengthCut(5) }}</span>
                </div>
              </div>
            </div>

            <!-- 부가정보 -->
            <div class="box_wrap inputbox_wrap subinfo_box" :style="alimTalkInfo.tmpltTpCd === 'EX' || alimTalkInfo.tmpltTpCd === 'MI' ? 'display: block' : 'display:none'">
              <div class="box_top">
                <h3 class="tit required">부가정보</h3>
              </div>
              <div class="box_cont inputbox">
                <div class="input_con">
                  <textarea
                    name="sendEtcInfo"
                    id="sendEtcInfo"
                    ref="sendEtcInfo"
                    cols="30"
                    rows="10"
                    class="scr_wrap subinfo"
                    v-model="alimTalkInfo.etcInfo"
                    @input="setEtcInfo"
                    placeholder="이용안내, 주의사항 등을 입력하세요."
                  ></textarea>
                </div>
                <div class="input_bottom">
                  <div class="count">
                    <span class="byte">{{ computeEtcInfoByte }} <span class="limit">/ 500자</span></span>
                  </div>
                </div>
              </div>
            </div>
            <!-- 광고성 메시지 -->
            <div class="box_wrap inputbox_wrap admsg_box" :style="alimTalkInfo.tmpltTpCd === 'AD' || alimTalkInfo.tmpltTpCd === 'MI' ? 'display: block' : 'display:none'">
              <div class="box_top">
                <h3 class="tit">광고성 메시지</h3>
              </div>
              <div class="box_cont inputbox">
                <div class="input_con" style="padding-top: 0; padding-bottom: 0; padding-left:5;">
                  <input
                    name="sendAdMsg"
                    id="sendAdMsg"
                    ref="sendAdMsg"
                    class="scr_wrap admsg"
                    v-model="adMsg"
                    disabled
                  />
                </div>
                <div class="input_bottom" style="padding-top: 0; padding-bottom: 1;">
                  <div class="count">
                    <span class="byte"><span class="limit">34자</span></span>
                  </div>
                </div>
              </div>
            </div>
            <hr />
            <!-- 버튼 -->
            <div class="box_wrap btntype_wrap">
              <div class="box_top">
                <h3 class="tit">버튼</h3>
              </div>
              <div class="box_cont inputbox">
                <div class="select_wrap">
                  <select name="" id="" class="select" v-model="buttonType">
                    <option value="null" selected>버튼타입 선택</option>
                    <option v-for="buttonLinkType in buttonLinkTypeList" :key="buttonLinkType.linkType" :value="buttonLinkType.linkType">
                      {{ buttonLinkType.linkTypeName }}
                    </option>
                  </select>
                </div>
                <div class="btn2_wrap">
                  <button class="btn plus" type="button" @click="fnAddButton(buttonType)">추가</button>
                  <!-- <button class="btn white" type="button" @click="fnDelButton">삭제</button> -->
                  <!-- 0811 삭제버튼 디자인 수정 -->
                </div>
              </div>
              <div class="btnlist_wrap">
                <table class="btnlist_table" style="width: 731px; table-layout: fixed">
                  <colgroup>
                    <col width="40px" />
                    <col width="25%" />
                    <col width="20%" />
                    <col width="calc(55% - 40px)" />
                  </colgroup>
                  <thead>
                    <tr>
                      <th class="td_chk"><input type="checkbox" class="chk_all" id="chk_all" v-model="allChecked" @click="checkedAll($event.target.checked)" /><label for="chk_all"></label></th>
                      <th class="td_type">타입</th>
                      <th class="td_name">버튼이름</th>
                      <th class="td_link">링크 주소(URL)</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(buttonInfo, index) in alimTalkInfo.buttonList" :key="index">
                      <td class="td_chk">
                        <template v-if="buttonInfo.linkType === 'AC'">
                          <input type="checkbox" class="chk" :id="'chk_' + index" :value="index" v-model="buttonInfo.selected" disabled /><label :for="'chk_' + index"></label>
                        </template>
                        <template v-else>
                          <input type="checkbox" class="chk" :id="'chk_' + index" :value="index" v-model="buttonInfo.selected" @change="selected()" /><label :for="'chk_' + index"></label>
                        </template>
                      </td>

                      <td class="td_type">
                        <template v-if="buttonInfo.linkType === 'AC'">
                          <select name="" id="" class="select" v-model="buttonInfo.linkType">
                            <option value="AC" selected>채널 추가</option>
                          </select>
                        </template>
                        <template v-else>
                          <select name="" id="" class="select" v-model="buttonInfo.linkType" @change="fnChgBtnLinkType(index, buttonInfo.linkType)">
                            <option value="" selected>버튼 타입 선택</option>
                            <option v-for="buttonLinkType in buttonLinkTypeList" :key="buttonLinkType.linkType" :value="buttonLinkType.linkType">
                              {{ buttonLinkType.linkTypeName }}
                            </option>
                          </select>
                        </template>
                      </td>

                      <td class="td_name" v-if="buttonInfo.linkType === 'WL' || buttonInfo.linkType === 'AL'">
                        <div class="btn_pre"><input type="text" maxlength="14" v-model="buttonInfo.name" @input="buttonInfo.name = $event.target.value" /></div>
                      </td>

                      <td
                        colspan="2"
                        class="td_name"
                        v-if="
                          buttonInfo.linkType === 'DS' ||
                          buttonInfo.linkType === 'BK' ||
                          buttonInfo.linkType === 'MD' ||
                          buttonInfo.linkType === 'BC' ||
                          buttonInfo.linkType === 'BT' 
                        "
                      >
                        <div class="btn_pre"><input type="text" maxlength="14" v-model="buttonInfo.name" @input="buttonInfo.name = $event.target.value" /></div>
                        <span class="td_delivery_desc" v-if="buttonInfo.linkType === 'DS'">{{ buttonDSDescription }}</span>
                      </td>
                      <td
                        colspan="2"
                        class="td_name"
                        v-if="buttonInfo.linkType === 'AC'">
                        <div class="btn_pre"><input type="text" maxlength="14" v-model="buttonInfo.name" @input="buttonInfo.name = $event.target.value" disabled/></div>
                      </td>

                      <td class="td_link" v-if="buttonInfo.linkType === 'WL'">
                        <div class="">
                          <label class="required"
                            >모바일:
                            <input
                              type="text"
                              v-model="buttonInfo.linkMo"
                              placeholder="링크 인터넷 주소(URL) 앞 부분에 http:// 또는 https:// 형식을 입력해 주세요"
                              title="링크 인터넷 주소(URL) 앞 부분에 http:// 또는 https:// 형식을 입력해 주세요"
                          /></label>
                        </div>
                        <div class="">
                          <label class="required"
                            >PC:
                            <input
                              type="text"
                              v-model="buttonInfo.linkPc"
                              placeholder="링크 인터넷 주소(URL) 앞 부분에 http:// 또는 https:// 형식을 입력해 주세요"
                              title="링크 인터넷 주소(URL) 앞 부분에 http:// 또는 https:// 형식을 입력해 주세요"
                          /></label>
                        </div>
                      </td>

                      <td class="td_link" v-if="buttonInfo.linkType === 'AL'">
                        <div class="">
                          <label class="required"
                            >안드로이드:
                            <input
                              type="text"
                              v-model="buttonInfo.linkAnd"
                              placeholder="링크 인터넷 주소(URL) 앞 부분에 http:// 또는 https:// 형식을 입력해 주세요"
                              title="링크 인터넷 주소(URL) 앞 부분에 http:// 또는 https:// 형식을 입력해 주세요"
                          /></label>
                        </div>
                        <div class="">
                          <label class="required"
                            >애플(IOS):
                            <input
                              type="text"
                              v-model="buttonInfo.linkIos"
                              placeholder="링크 인터넷 주소(URL) 앞 부분에 http:// 또는 https:// 형식을 입력해 주세요"
                              title="링크 인터넷 주소(URL) 앞 부분에 http:// 또는 https:// 형식을 입력해 주세요"
                          /></label>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>

              <div class="btn2_wrap">
                <button class="btn white small" type="button" @click="fnDelButton">삭제</button>
              </div>
            </div>

            <!-- 0706 배송조회 수정 { -->
            <!-- <span class="td_name w525">
				<div class="btn_pre"><input type="text" maxlength="14" value="배송 조회하기"></div>
				<span class="td_delivery_desc">송장번호는 '-'없이 숫자 또는 알파벳 대문자만 입력 가능</span>
			</span>
			-->
          </div>
          <!-- 0623 버튼 순서 수정 { -->
          <div class="btn_wrap">
            <!-- <button class="btn large_pink" type="button" @click="fnAlimProcInspect">검수요청</button> -->
            <button class="btn large_pink" type="button" @click="fnAlimTmpltWrite()">등록</button>
            <button class="btn large_white" type="button" @click="fnCancle()">취소</button>
          </div>
          <!-- } 0623 버튼 순서 수정 -->
        </form>
        <!--	s: m_preview	-->
        <div class="m_preview_wrap">
          <button type="button" class="btn_close" onClick="mPreClose();"></button>
          <div class="m_preview talk_preview">
            <div class="preview_toggle">
              <input type="radio" name="preview_type_toggle" id="btn_sent_toggle" @click="mPreEvent(true)" checked />
              <label for="btn_sent_toggle">미리보기</label>
              <input type="radio" name="preview_type_toggle" id="btn_history_toggle" @click="mPreEvent(false)" />
              <label for="btn_history_toggle">최근 작성 메시지</label>
            </div>
            <div class="preview_top" v-show="preEventToggle">
              <span>{{ alimTalkInfo.chnlId !== "null" ? alimTalkInfo.chnlId : "채널 아이디" }}</span>
              <!-- <input type="radio" name="preview_type" id="btn_history" @click="mPreEvent(false)" /><label
                for="btn_history"><span>최근작성 문자</span></label> -->
            </div>
            <!--	s: 알림톡 미리보기	 -->
            <div class="preview_cont" v-show="preEventToggle">
              <div class="talk_channel">
                <div class="img"><img src="../../../assets/images/preview_icon.png" alt="" /></div>
                <p class="channel_name">{{ alimTalkInfo.chnlId }}</p>
              </div>
              <div class="talk_wrap">
                <div class="talk_top">
                  <span>알림톡 도착</span>
                </div>
                <div class="talk_cont">
                  <div class="talk_tit" v-show="this.alimTalkInfo.emphsTpCd === 'TEXT'">
                    <p class="t_sub" :style="alimTalkInfo.emphsSubtle ? 'color: rgb(18, 16, 6);' : ''">{{ alimTalkInfo.emphsSubtle ? alimTalkInfo.emphsSubtle : "강조유형 보조문구" }}</p>
                    <p class="t_tit" :style="alimTalkInfo.emphsTitle ? 'color: rgb(18, 16, 6);' : ''">{{ alimTalkInfo.emphsTitle ? alimTalkInfo.emphsTitle : "제목을 입력하세요." }}</p>
                  </div>
                  <div class="talk_txt">
                    <p class="cont" :style="alimTalkInfo.tmpltSbst ? 'color: rgb(18, 16, 6);' : ''" v-html="previewMsg ? previewMsg : '내용 미리보기'"></p>
                    <p
                      class="sub_info"
                      v-if="alimTalkInfo.tmpltTpCd === 'EX' || alimTalkInfo.tmpltTpCd === 'MI'"
                      :style="alimTalkInfo.etcInfo ? 'color: rgb(18, 16, 6);' : ''"
                      v-html="previewEtc ? previewEtc : '부가정보를 입력하세요'"
                    ></p>
                    <p
                      class="sub_info"
                      v-if="alimTalkInfo.tmpltTpCd === 'AD' || alimTalkInfo.tmpltTpCd === 'MI'"
                      :style="adMsg ? 'color: rgb(18, 16, 6);' : ''"
                      v-html="previewAd ? previewAd : '채널 추가하고 이 채널의 마케팅 메시지 등을 카카오톡으로 받기'"
                    ></p>
                  </div>
                  <div class="talk_btn" v-for="(button, index) in alimTalkInfo.buttonList" :key="index">{{ button.name ? button.name : button.linkTypeName }}</div>
                </div>
              </div>
            </div>
            <!--	e: 알림톡 미리보기	 -->
            <!--	s: 최근전송내역	-->
            <RecentAlimTalkListVue :preEventToggle="preEventToggle" @recentSendAlimList="fnRecentSendAlimList" :recentSendAlimList="recentSendAlimList" />
            <!-- 	e: 최근전송내역 	-->
          </div>
        </div>
        <!--	s: m_preview	-->
      </div>
    </div>
    <!--	e: 	contents	-->
    <!--	s: m_btn_wrap -모바일버전에서 버튼	-->
    <div class="m_btn_wrap">
      <button type="button" class="m_preview_btn" @click="mPreAlimOn()">미리보기</button>
      <button type="button" class="m_aside_btn" @click="mHisAlimOn()">최근<br />작성메시지</button>
    </div>
    <!--  e: m_btn_wrap -모바일버전에서 버튼  -->
    <!-- s: 모바일 미리보기 -->
    <MobilePreviewAlimVue @recentSendAlimList="fnRecentSendAlimList" :alimTalkInfo="alimTalkInfo" :recentSendAlimList="recentSendAlimList" :adMsg="adMsg"/>
    <!-- e: 모바일 미리보기 -->
  </section>
  <!--	e: m_section	-->
</template>
<script>
import store from "@/store";
import confirm from "@/modules/commonUtil/service/confirm";
import templeteManageApi from "../service/templeteManageApi";
import { eventBus } from "@/modules/commonUtil/service/eventBus";
import RecentAlimTalkListVue from "@/modules/messageSend/components/RecentAlimTalkList.vue";
import MobilePreviewAlimVue from "../components/MobilePreviewAlim.vue";
import MobileGnb from "@/components/MobileGnb.vue";
import messageSendApi from "@/modules/messageSend/service/messageSendApi";

export default {
  name: "templeteWrite",
  components: { RecentAlimTalkListVue, MobileGnb, MobilePreviewAlimVue },
  beforeRouteLeave(to, from, next) {
    if ((this.alimTalkInfo.chnlId !== "null" && this.alimTalkInfo.chnlId !== "add") || this.alimTalkInfo.tmpltNm !== "" || this.alimTalkInfo.tmpltSbst !== "" || this.totalCount >= 1) {
      if (from.path) {
        eventBus.$on("callbackEventBus", () => {
          next();
        });
        confirm.fnConfirm("", "페이지 이동시 모든 내용이 삭제됩니다.", "confrim");
      }
    } else {
      next();
    }
  },
  data() {
    return {
      selectPos: 0, // insertCharacters 초기 값
      loginDiv: false,
      userSeq: "",
      prntsUserSeq: "",
      recentSendAlimList: [], // 최근 작성 알림톡 리스트
      allChecked: false, // 전체 체크박스 값
      alimTalkInfo: {
        chnlId: "null", //채널 ID 값
        tmpltNm: "",
        tmpltCtgCd: "", // 카테고리 코드
        securTmpltYn: true, // 보안템플릿 여부 Y N
        emphsTpCd: "NONE", // 강조유형 사용 유무 (안함 : "NONE" 사용 : "TEXT")
        emphsTitle: "", // 강조유형 제목
        emphsSubtle: "", // 강조유형 보조 문구
        tmpltSbst: "", // 메시지 내용
        etcInfo: "", // 부가 정보
        tmpltTpCd: "BA", // 템플릿유형 코드
        buttonList: [], // 버튼 정보
      },
      adMsg: "채널 추가하고 이 채널의 마케팅 메시지 등을 카카오톡으로 받기", // 광고성 메시지
      tmpltNmByte: "0", // 템플릿명 Bute 변수
      msgTextByte: "0", // 메시지 내용 Byte 변수
      etcInfoTextByte: "0", // 메시지 내용 Byte 변수
      // adMsgTextByte: "0", // 메시지 내용 Byte 변수
      emphsTitleByte: "0", // 강조표기 타이틀 Byte 변수
      emphsSubtleByte: "0", // 강조표기 보조문구 타이틀 Byte 변수
      preEventToggle: true,
      channelIdList: [],
      kkoCategoryGrp: [],
      category: [],
      cate1: [],
      cate2: [],
      cate1Code: "",
      cate2Code: "",
      buttonLimitSize: 5,
      buttonType: "null",
      buttonDSDescription: "송장번호는 '-'없이 숫자 또는 알파벳 대문자만 입력 가능",
      buttonLinkTypeList: [
        { linkType: "DS", linkTypeName: "배송 조회", name: "버튼명 입력" },
        { linkType: "WL", linkTypeName: "웹링크", name: "버튼명 입력", linkMo: "", linkPc: "" },
        { linkType: "AL", linkTypeName: "앱링크", name: "버튼명 입력", linkAnd: "", linkIos: "" },
        { linkType: "BK", linkTypeName: "봇 키워드", name: "버튼명 입력" },
        { linkType: "MD", linkTypeName: "메시지 전달", name: "버튼명 입력" },
        { linkType: "BC", linkTypeName: "상담톡 전환", name: "버튼명 입력" },
        { linkType: "BT", linkTypeName: "챗봇 전환", name: "버튼명 입력" },
      ],
      // 미리보기 화면 swiper
      swiperOption2: {
        slidesOffsetBefore: 0, // slidesOffsetBefore는 첫번째 슬라이드의 시작점에 대한 변경할 때 사용
        slidesOffsetAfter: 0, // slidesOffsetAfter는 마지막 슬라이드 시작점 + 마지막 슬라이드 너비에 해당하는 위치의 변경이 필요할 때 사용
        //freeMode: true,  freeMode를 사용시 스크롤하는 느낌으로 구현 가능
        centerInsufficientSlides: true, // 컨텐츠의 수량에 따라 중앙정렬 여부를 결정함
        loop: false,
        direction: "vertical",
        slidesPerView: 3,
        spaceBetween: 12,
        observer: true,
        observeParents: true,
        // Navigation arrows
        navigation: {
          nextEl: ".swiper-button-prev",
          prevEl: ".swiper-button-next",
        },
      },
      autoMsg: [],
    };
  },
  computed: {
    previewMsg() {
      return this.alimTalkInfo.tmpltSbst ? this.alimTalkInfo.tmpltSbst.split(" ").join("&nbsp;").split("\n").join("<br/>") : "";
    },
    previewEtc() {
      return this.alimTalkInfo.etcInfo ? this.alimTalkInfo.etcInfo.split(" ").join("&nbsp;").split("\n").join("<br/>") : "";
    },
    previewAd() {
      return this.adMsg ? this.adMsg.split(" ").join("&nbsp;").split("\n").join("<br/>") : "";
    },
    // 템플릿명 바이트
    computeTmpltNmByte() {
      return parseInt(this.tmpltNmByte);
    },
    //내용 바이트
    computeMsgByte() {
      return parseInt(this.msgTextByte);
    },
    // 부가정보 바이트
    computeEtcInfoByte() {
      return parseInt(this.etcInfoTextByte);
    },
    //총 글자 바이트
    computeTotalByte() {
      if(this.alimTalkInfo.tmpltTpCd == 'EX' ) {                                  //부가정보형
        return parseInt(this.msgTextByte) + parseInt(this.etcInfoTextByte)
      } else if(this.alimTalkInfo.tmpltTpCd == 'AD'){                             //광고추가형
        return parseInt(this.msgTextByte) + 34
      } else if(this.alimTalkInfo.tmpltTpCd == 'MI'){                             //복합형
        return parseInt(this.msgTextByte) + parseInt(this.etcInfoTextByte) + 34
      } else {
        return parseInt(this.msgTextByte)
      }
    },
    // 강조표기 타이틀 바이트
    computeEmphsTitle() {
      return parseInt(this.emphsTitleByte);
    },
    // 강조표기  바이트
    computeEmphsSubtle() {
      return parseInt(this.emphsSubtleByte);
    },
  },
  watch: {
    computeTmpltNmByte: function (getTotalByte) {
      if (getTotalByte > 200) {
        this.$refs.tmpltNm.blur();
        eventBus.$on("callbackEventBus", () => {
          this.fnTmpltNmCut();
          this.$refs.tmpltNm.focus();
        });
        confirm.fnConfirm("", "200자까지 작성하실 수 있습니다.", "cbAlert");
      }
    },
    computeMsgByte: function (getTotalByte) {
      if (getTotalByte > 1000) {
        this.$refs.sendAlimContent.blur();
        eventBus.$on("callbackEventBus", () => {
          this.fnMessageContentCut();
          this.$refs.sendAlimContent.focus();
        });
        confirm.fnConfirm("", "1,000자까지 작성하실 수 있습니다.", "cbAlert");
      }
    },
    computeEtcInfoByte: function (getAdMsg) {
      if (getAdMsg > 500) {
        confirm.fnAlert("", "500자까지 작성하실 수 있습니다.", "message");
        this.fnetcInfoCut();
      }
    },
    // 강조표기 타이틀
    computeEmphsTitle: function (emphsTitle) {
      if (emphsTitle > 50) {
        confirm.fnAlert("", "50자까지 작성하실 수 있습니다.", "message");
        this.fnEmphsTitle();
      }
    },
    // 강조표기 보조문구
    computeEmphsSubtle: function (emphsSubtle) {
      if (emphsSubtle > 50) {
        confirm.fnAlert("", "50자까지 작성하실 수 있습니다.", "message");
        this.fnEmphsSubtle();
      }
    },
    computeTotalByte: function (getTotalByte) {
      if(getTotalByte > 1000) {
        if(document.activeElement.id == 'sendAlimContent'){
          eventBus.$on("callbackEventBus", () => {
            this.fnMessageContentCut();
            this.$refs.sendAlimContent.focus();
          });
        } else {
          eventBus.$on("callbackEventBus", () => {
            this.fnetcInfoCut();
            this.$refs.sendEtcInfo.focus();
          });
        }
        if(this.alimTalkInfo.tmpltTpCd == 'EX'){
            confirm.fnConfirm("", "부가정보를 포함하여 총 글자수가 1,000자가 이내여야 합니다.", "cbAlert");
          } else if (this.alimTalkInfo.tmpltTpCd == 'AD'){
            confirm.fnConfirm("", "광고성 정보를 포함하여 총 글자수가 1,000자 이내여야 합니다.", "cbAlert");
          } else if (this.alimTalkInfo.tmpltTpCd == 'MI') {
            confirm.fnConfirm("", "부가정보, 광고성메시지를 포함하여 총 글자수가 1,000자 이내여야 합니다.", "cbAlert");
          }
      }
    },

    alimTalkInfo: {
      deep: true,
      handler(newMsg) {
        // 작성하는 텍스트를 자동 변수로 인식하는 기능 추가
        const pattern = /#\{([^#{}]*)\}/g; // 특정 문자 사이 추출 (특정문자 제외)
        const pattern2 = /((#{).*?(\}))/g; // 특정 문자 사이 추출 (특정문자 포함)
        let autoMsgList = [];

        // 빈값 체크
        if (!this.$gfnCommonUtils.isEmpty(newMsg.tmpltSbst.match(pattern))) {
          const matches = newMsg.match(pattern);
          autoMsgList = matches.map(match => match.substring(2, match.length - 1));

          // 자동변경정보 공백 제거
          let msgSpace = autoMsgList.some((e) => {
            return e.indexOf(" ") > -1;
          });

          if (msgSpace) {
            eventBus.$on("callbackEventBus", () => {
              autoMsgList = autoMsgList.map((e) => {
                return e.replace(/ /g, "");
              });

              // 내용 자동변경정보 띄어쓰기 사용시 자동 삭제
              let autoMsgText = "";
              autoMsgText = newMsg.tmpltSbst.replace(pattern2, (match) => {
                return match.replace(/ /g, "");
              });
              this.alimTalkInfo.tmpltSbst = autoMsgText;
              this.msgTextByte = this.$gfnCommonUtils.getTextLen(autoMsgText);
            });
            confirm.fnConfirm("", "자동변경정보 사용시 공백은 포함되지 않습니다.", "cbAlert");
            return false;
          }
          // phone 변수 사용시 안내
          if (autoMsgList.includes("phone")) {
            eventBus.$on("callbackEventBus", this.fnRemoveDefaultAutoMsg);
            confirm.fnConfirm("", "#{phone} 자동변경정보는 [휴대폰 번호]에서 사용하고 있습니다.<br/> 다른 자동변경정보를 사용하세요.", "cbAlert");
            return false;
          }
          // 중복 제거 && phone 변수 제외 처리
          this.autoMsg = autoMsgList.filter((e, index) => {
            return autoMsgList.indexOf(e) === index && e !== "phone";
          });
        } else {
          this.autoMsg = [];
        }
      },
    },
  },
  mounted() {
    this.fnSelectChannelId();
    this.fnSelectAlimtalkTemplateCtg();
    this.fnSelectrecentSendAlimList();
  },
  methods: {
    //체크박스 전체 선택 기능
    checkedAll(checked) {
      this.allChecked = checked;

      this.alimTalkInfo.buttonList.forEach((e, i) => {
        if (e.linkType !== "AC") {
          this.alimTalkInfo.buttonList[i].selected = this.allChecked;
        }
      });
    },
    //체크박스 단일 체크
    selected() {
      if (this.alimTalkInfo.buttonList.every((item) => item.selected == true)) {
        this.allChecked = "checked";
      } else {
        this.allChecked = "";
      }
    },
    //채널ID 가져오기
    fnSelectChannelId() {
      const isAuth = store.getters["login/isAuth"];
      if (isAuth) {
        this.loginDiv = true;
      } else {
        confirm.fnAlert("", "로그인 토큰정보가 없습니다. <br />다시 로그인 해주세요.", "alert");
        return;
      }
      let params = {
        userSeq: "",
        prntsUserSeq: "",
        dormYn: "N",
        blckYn: "N",
      };

      templeteManageApi.selectChannelId(params).then((response) => {
        if (response.data.success) {
          this.channelIdList = response.data.data;
        } else {
          // confirm.fnAlert("알림", "해당 채널 아이디 가져오기에 실패하였습니다.", "alert");
          confirm.fnAlert("", "해당 채널 아이디 가져오기에 실패하였습니다.", "alert");
        }
      });
    },
    //카테고리 그룹 가져오기
    fnSelectAlimtalkTemplateCtg() {
      templeteManageApi.selectAlimtalkTemplateCtg({}).then((response) => {
        const result = response.data;
        if (result.success) {
          this.category = result.data;
          this.cate1 = result.data
            .filter((item, index, callback) => {
              return index === callback.findIndex((t) => t.ctgGrpNm === item.ctgGrpNm);
            })
            .map((e) => {
              return {
                ...e,
                tmpltCtgGrpCd: e.tmpltCtgCd.substr(0, 3),
              };
            });
        } else {
          // confirm.fnAlert("알림", "카테고리 토큰키 가져오기에 실패하였습니다." + response.data.message, "alert");
          confirm.fnAlert("", "카테고리 토큰키 가져오기에 실패하였습니다." + response.data.message, "alert");
        }
      });
    },

    //미리보기 발신화면 최근작성문자 토글 설정
    mPreEvent(toggle) {
      if (toggle) {
        this.preEventToggle = true;
      } else {
        this.preEventToggle = false;
      }
    },
    // 모바일 미리보기
    mPreOn() {
      const mPop = document.querySelector(".m_preview_wrap"),
        dimmed = document.querySelector(".dimmed"),
        btnSent = document.getElementById("btn_sent"),
        btnHistory = document.getElementById("btn_history");
      dimmed.classList.add("open");
      mPop.classList.add("on");
      btnSent.checked = true;
      btnHistory.checked = false;
      this.mPreEvent(true);
    },
    mHisOn() {
      const mPop = document.querySelector(".m_preview_wrap"),
        dimmed = document.querySelector(".dimmed"),
        btnSent = document.getElementById("btn_sent"),
        btnHistory = document.getElementById("btn_history");
      dimmed.classList.add("open");
      mPop.classList.add("on");
      btnSent.checked = false;
      btnHistory.checked = true;
      this.mPreEvent(false);
    },
    mPreClose() {
      const mPop = document.querySelector(".m_preview_wrap"),
        dimmed = document.querySelector(".dimmed");
      mPop.classList.remove("on");
      dimmed.classList.remove("open");
    },
    specialOn() {
      var btnSpecial = document.querySelector(".btn_special"),
        btnVari = document.querySelector(".btn_vari"),
        specailCont = document.querySelector(".special_char"),
        variCont = document.querySelector(".vari_txt");
      btnSpecial.classList.toggle("on");
      specailCont.classList.toggle("on");
      btnVari.classList.remove("on");
      variCont.classList.remove("on");
    },
    variOn() {
      var btnVari = document.querySelector(".btn_vari"),
        specailCont = document.querySelector(".special_char"),
        variCont = document.querySelector(".vari_txt");
      btnVari.classList.toggle("on");
      variCont.classList.toggle("on");
      specailCont.classList.remove("on");
    },
    // 특수 문자 추가
    insertCharacters(character) {
      const chLen = character.length;
      let txtArea = this.$refs.sendAlimContent;
      let txtValue = txtArea.value;
      this.selectPos = txtArea.selectionStart !== 0 ? txtArea.selectionStart : this.selectPos; // 커서 위치 지정
      let beforeTxt = txtValue.substring(0, this.selectPos); // 기존텍스트 ~ 커서시작점 까지의 문자
      this.selectPos = this.selectPos + chLen;
      let afterTxt = txtValue.substring(this.selectPos - chLen, txtValue.length); // 커서끝지점 ~ 기존텍스트 까지의 문자
      let addTxt = character; // 추가 입력할 텍스트
      txtArea.value = beforeTxt + addTxt + afterTxt;
      this.alimTalkInfo.tmpltSbst = txtArea.value;
      this.msgTextByte = this.$gfnCommonUtils.getTextLen(txtArea.value);
    },
    setTmpltNm(e) {
      this.alimTalkInfo.tmpltNm = e.target.value;
      this.tmpltNmByte = this.$gfnCommonUtils.getTextLen(e.target.value);
    },
    setTmpltSbst(e) {
      this.alimTalkInfo.tmpltSbst = e.target.value;
      this.msgTextByte = this.$gfnCommonUtils.getTextLen(e.target.value);
    },
    setEmphsTitle(e) {
      this.alimTalkInfo.emphsTitle = e.target.value;
      this.emphsTitleByte = this.$gfnCommonUtils.getTextLen(e.target.value);
    },
    setEmphsSubtle(e) {
      this.alimTalkInfo.emphsSubtle = e.target.value;
      this.emphsSubtleByte = this.$gfnCommonUtils.getTextLen(e.target.value);
    },
    setEtcInfo(e) {
      this.alimTalkInfo.etcInfo = e.target.value;
      this.etcInfoTextByte = this.$gfnCommonUtils.getTextLen(e.target.value);
    },
    // setAdMsg(e) {
    //   this.alimTalkInfo.adMsg = e.target.value;
    //   this.adMsgTextByte = this.$gfnCommonUtils.getTextLen(e.target.value);
    // },
    fnTmpltNmCut() {
      this.alimTalkInfo.tmpltNm = this.$gfnCommonUtils.cutText(this.$refs.tmpltNm.value, 200);
      this.tmpltNmByte = this.$gfnCommonUtils.getTextLen(this.alimTalkInfo.tmpltNm);
    },
    fnMessageContentCut() {
      this.alimTalkInfo.tmpltSbst = this.$gfnCommonUtils.cutText(this.$refs.sendAlimContent.value, this.computeMsgByte - (this.computeTotalByte - 1000));
      this.msgTextByte = this.$gfnCommonUtils.getTextLen(this.alimTalkInfo.tmpltSbst);
    },

    fnetcInfoCut() {
      if(this.computeEtcInfoByte > 500){
        this.alimTalkInfo.etcInfo = this.$gfnCommonUtils.cutText(this.$refs.sendEtcInfo.value, 500);
      } else {
        this.alimTalkInfo.etcInfo = this.$gfnCommonUtils.cutText(this.$refs.sendEtcInfo.value, this.computeEtcInfoByte - (this.computeTotalByte - 1000));
      }
      this.etcInfoTextByte = this.$gfnCommonUtils.getTextLen(this.alimTalkInfo.etcInfo);
    },
    // fnAdMsgCut() {
    //   this.alimTalkInfo.adMsg = this.$gfnCommonUtils.cutText(this.$refs.sendAdMsg.value, 80);
    //   this.adMsgTextByte = this.$gfnCommonUtils.getTextLen(this.alimTalkInfo.adMsg);
    // },
    fnEmphsTitle() {
      this.alimTalkInfo.emphsTitle = this.$gfnCommonUtils.cutText(this.$refs.emphsTitle.value, 50);
      this.emphsTitleByte = this.$gfnCommonUtils.getTextLen(this.alimTalkInfo.emphsTitle);
    },
    fnEmphsSubtle() {
      this.alimTalkInfo.emphsSubtle = this.$gfnCommonUtils.cutText(this.$refs.emphsSubtle.value, 50);
      this.emphsSubtleByte = this.$gfnCommonUtils.getTextLen(this.alimTalkInfo.emphsSubtle);
    },
    // 카카오톡 첫번째 카테고리 선택시 두번째 카테고리 변경 함수
    fnCate1Code: function (event) {
      this.alimTalkInfo.tmpltCtgCd = "";
      this.cate2Code = "";
      this.cate2 = [];
      if (event.target.value != null && event.target.value != "") {
        this.cate2 = this.category.filter((e) => {
          return e.ctgGrpNm === event.target.value;
        });
        this.cate1Code = event.target.value;
      }
    },
    // 카카오톡 두번째 카테고리 선택시 세번째 카테고리 변경 함수
    fnCate2Code: function (event) {
      if (event.target.value != null && event.target.value != "") {
        this.cate2Code = event.target.value;
      }
      this.alimTalkInfo.tmpltCtgCd = this.cate2Code;
    },
    //템플릿 유형
    optCont(e) {
      this.alimTalkInfo.buttonList = [];
      this.alimTalkInfo.tmpltTpCd = e.target.value;
      if (e.target.value === "MI" || e.target.value === "AD") {
        const baseButtonInfo = { linkType: "AC", linkTypeName: "채널 추가", name: "채널 추가", selected: false };
        this.alimTalkInfo.buttonList.push(baseButtonInfo);
      }
    },
    //강조유형 radio
    emphasisView(type) {
      this.alimTalkInfo.emphsTpCd = type;
    },
    // 버튼 추가
    fnAddButton(buttonType) {
      if (buttonType === "null") {
        // confirm.fnAlert("알림", "추가할 버튼을 선택하세요", "alert");
        confirm.fnAlert("", "추가할 버튼을 선택하세요", "alert");
        return;
      }

      if (this.alimTalkInfo.buttonList.length < this.buttonLimitSize) {
        const baseButtonInfo = {
          linkTypeName: this.buttonLinkTypeList.filter((e) => {
            return e.linkType === buttonType;
          })[0].linkTypeName,
          linkType: buttonType,
          name: this.buttonLinkTypeList.filter((e) => {
            return e.linkType === buttonType;
          })[0].name,
          selected: false,
        };

        if (buttonType === "WL") {
          baseButtonInfo.linkMo = "";
          baseButtonInfo.linkPc = "";
        } else if (buttonType === "AL") {
          baseButtonInfo.linkAnd = "";
          baseButtonInfo.linkIos = "";
        }

        this.alimTalkInfo.buttonList.push(baseButtonInfo);
      } else {
        confirm.fnAlert(this.componentsTitle, "버튼은 최대 " + this.buttonLimitSize + "개 까지 입력 가능합니다.", "alert");
      }
    },
    //버튼 삭제
    fnDelButton() {
      if (this.alimTalkInfo.buttonList.length === 0) {
        // confirm.fnAlert("알림", "추가된 버튼이 없습니다.", "alert");
        confirm.fnAlert("", "추가된 버튼이 없습니다.", "alert");
        return;
      } else if (
        this.alimTalkInfo.buttonList.filter((button) => {
          return button.selected === true;
        }).length <= 0
      ) {
        // confirm.fnAlert("알림", "삭제할 버튼을 체크해주세요.", "alert");
        confirm.fnAlert("", "삭제할 버튼을 체크해주세요.", "alert");
        return;
      }

      this.alimTalkInfo.buttonList = this.alimTalkInfo.buttonList.filter((button) => {
        return button.selected === false;
      });

      if (this.alimTalkInfo.buttonList.length === 0) this.allChecked = false;
    },
    fnChgBtnLinkType(idx, buttonType) {
      this.alimTalkInfo.buttonList[idx].linkTypeName = this.buttonLinkTypeList.filter((e) => {
        return e.linkType === buttonType;
      })[0].linkTypeName;
      this.alimTalkInfo.buttonList[idx].linkType = this.buttonLinkTypeList.filter((e) => {
        return e.linkType === buttonType;
      })[0].linkType;
      this.alimTalkInfo.buttonList[idx].name = this.buttonLinkTypeList.filter((e) => {
        return e.linkType === buttonType;
      })[0].name;
      if (buttonType === "WL") {
        this.alimTalkInfo.buttonList[idx].linkPc = "";
        this.alimTalkInfo.buttonList[idx].linkMo = "";
        delete this.alimTalkInfo.buttonList[idx].linkAnd;
        delete this.alimTalkInfo.buttonList[idx].linkIos;
      } else if (buttonType === "AL") {
        this.alimTalkInfo.buttonList[idx].linkAnd = "";
        this.alimTalkInfo.buttonList[idx].linkIos = "";
        delete this.alimTalkInfo.buttonList[idx].linkPc;
        delete this.alimTalkInfo.buttonList[idx].linkMo;
      } else {
        delete this.alimTalkInfo.buttonList[idx].linkPc;
        delete this.alimTalkInfo.buttonList[idx].linkMo;
        delete this.alimTalkInfo.buttonList[idx].linkAnd;
        delete this.alimTalkInfo.buttonList[idx].linkIos;
      }
    },
    fnCancle() {
      this.$router.push("/templeteManage");
      //eventBus.$on("callbackEventBus", this.fnBackRouter);
      // confirm.fnConfirm("템플릿 등록 취소", "**취소를 누르면 삭제되고 목록으로 이동해야합니다");
      //confirm.fnConfirm("", "취소를 누르면 삭제되고 목록으로 이동해야합니다");
    },
    /* fnBackRouter() {
      this.$router.push("/templeteManage");
    }, */
    // 유효성 체크
    fnValidator() {
      let msg = "";

      if (this.alimTalkInfo.chnlId === "null") {
        msg = "[채널 아이디] 을 입력하세요.";
      }
      if (this.alimTalkInfo.tmpltNm === "") {
        msg = "[템플릿명] 을 입력하세요.";
      }
      if (this.cate1Code === "" || this.cate2Code === "") {
        msg = "[카테고리] 을 입력하세요.";
      }
      if (this.alimTalkInfo.emphsTpCd === "TEXT") {
        if (this.alimTalkInfo.emphsTitle === "") {
          msg = "[강조표기 타이틀] 을 입력하세요.";
        } else if (this.alimTalkInfo.emphsSubtle === "") {
          msg = "[강조표기 보조문구] 을 입력하세요.";
        }
      }
      if (this.alimTalkInfo.tmpltSbst === "") {
        msg = "[내용] 을 입력하세요.";
      }
      if (this.alimTalkInfo.tmpltTpCd === "EX") {
        if (this.alimTalkInfo.etcInfo === "") {
          msg = "[부가정보] 을 입력하세요.";
        }
      }
      if (this.alimTalkInfo.tmpltTpCd === "MI") {
        if (this.alimTalkInfo.etcInfo === "") {
          msg = "[부가정보] 을 입력하세요.";
        } 
      }

      if (this.alimTalkInfo.buttonList.length > 0) {
        const check = this.alimTalkInfo.buttonList
          .filter((e) => {
            return e.linkType === "WL" || e.linkType === "AL";
          })
          .every((e) => {
            let returnData = false;
            if (e.linkType === "WL") {
              returnData = e.linkMo && e.linkPc;
            }

            if (e.linkType === "AL") {
              returnData = e.linkAnd && e.linkIos;
            }
            return returnData;
          });

        if (!check) {
          msg = "버튼 링크 주소(URL)가 입력되지 않았습니다.";
        } else {
          const urlCheck = this.alimTalkInfo.buttonList
            .filter((e) => {
              return e.linkType === "WL" || e.linkType === "AL";
            })
            .every((e) => {
              let returnData = false;
              if (e.linkType === "WL") {
                returnData = this.$gfnCommonUtils.isUrl(e.linkMo) && this.$gfnCommonUtils.isUrl(e.linkPc);
              }

              if (e.linkType === "AL") {
                returnData = this.$gfnCommonUtils.isUrl(e.linkAnd) && this.$gfnCommonUtils.isUrl(e.linkIos);
              }
              return returnData;
            });
          if (!urlCheck) {
            msg = "http://, https:// 포함한 URL으로 작성해주세요. ";
          }
        }
      }

      return msg;
    },

    // 알림톡 템플릿 등록 요청setEtcInfo
    fnAlimTmpltWrite() {
      // 유효성 체크
      const validation = this.fnValidator();
      if (validation !== "") {
        // confirm.fnAlert("입력 누락", validation, "alert");
        confirm.fnAlert("", validation, "alert");
        return;
      }
      eventBus.$on("callbackEventBus", this.fnAlimTmpltWriteReq);

      // confirm.fnConfirm("템플릿을 등록하시겠습니까?", "등록 후 검수요청 시 카카오 검수가 진행됩니다.");
      confirm.fnConfirm("", "템플릿을 등록하시겠습니까?<br />등록 후 검수요청 시 카카오 검수가 진행됩니다.", "reg");
    },
    fnAlimTmpltWriteReq() {
      this.alimTalkInfo.securTmpltYn = this.alimTalkInfo.securTmpltYn === true ? "TEXT" : "NONE";
      this.alimTalkInfo.tmpltCtgCd = this.cate2Code;

      templeteManageApi
        .procApprvRequestKkoTmplt(this.alimTalkInfo)
        .then((response) => {
          if (response.data.success) {
            eventBus.$on("callbackEventBus", this.$router.push({ path: "/templeteManage" }));
            confirm.fnConfirm("", "등록했습니다.", "cbAlert");
          } else {
            confirm.fnAlert("", response.data.message, "alert");
          }
        })
        .catch((error) => {
          if (error.response.status != 402) {
            confirm.fnAlert("", "오류로 인해 등록실패했습니다.",  "alert");
          }
        });
    },
    // 알림톡 템플릿 검수 요청setEtcInfo
    /* fnAlimProcInspect() {
      // 유효성 체크
      const validation = this.fnValidator();8
      if (validation !== "") {
        // confirm.fnAlert("입력 누락", validation, "alert");
        confirm.fnAlert("", validation, "alert");
        return;
      }

      eventBus.$on("callbackEventBus", this.fnAlimProcInspectReq);
      // confirm.fnConfirm("템플릿을 검수요청하시겠습니까?", "사용 승인까지는 영업일 기준 7일 정도 소요되며, 승인 완료 시 템플릿 상태가 자동으로 사용으로 변경됩니다.");
      confirm.fnConfirm("", "템플릿을 검수요청하시겠습니까?<br />사용 승인까지는 영업일 기준 7일 정도 소요되며, 승인 완료 시 템플릿 상태가 자동으로 사용으로 변경됩니다.");
    },
    fnAlimProcInspectReq() {
      const params = [
        {
          chnlId: "@이커머스테크",
          sndrprofKey: "da17d231bbf13f83174a36a4bb0353476ae012b3", // senderKey 발신프로필 키
          tmpltKey: "TPK9Xcf0rL", // templateKey 템플릿 키
        },
      ];

      templeteManageApi
        .procInspectRequestKkoTmplt(params)
        .then((response) => {
          if (response.data.success) {
            // confirm.fnAlert("템플릿 등록완료", "등록했습니다.", "alert");
            confirm.fnAlert("", "등록했습니다.", "alert");
          } else {
            // confirm.fnAlert("템플릿 등록실패", response.data.message, "alert");
            confirm.fnAlert("", response.data.message, "alert");
          }
        })
        .catch((error) => {
          // confirm.fnAlert("오류", "등록 실패했습니다." + error, "alert");
          confirm.fnAlert("", "등록 실패했습니다." + error, "alert");
        });
    }, */
    fnChnlAdd(e) {
      if (e.target.value === "add") {
        this.$router.push({ path: "/sendNumberManage/channelIdManage" });
      }
    },
    // 최근작성알림톡 가져오기
    fnSelectrecentSendAlimList() {
      const params = {
        recentOffset: 10,
        chStr: "KKO_NOTI",
      };
      messageSendApi.selectRecentSendMessageList(params).then((response) => {
        if (response.data.success) {
          this.recentSendAlimList = response.data.data.map((item) => {
            return {
              ad: item.msgInfo ? JSON.parse(item.msgInfo).ad : "",
              adBottom: item.msgInfo ? JSON.parse(item.msgInfo).adBottom : "",
              adTitle: item.msgInfo ? JSON.parse(item.msgInfo).adTitle : "",
              authNumber: item.msgInfo ? JSON.parse(item.msgInfo).authNumber : "",
              callbackNumber: item.msgInfo ? JSON.parse(item.msgInfo).callbackNumber : "",
              chnlId: item.msgInfo ? JSON.parse(item.msgInfo).chnlId : "",
              content: item.msgInfo ? JSON.parse(item.msgInfo).content : "",
              fbFileId: item.msgInfo ? JSON.parse(item.msgInfo).fbFileId : "",
              fbMsg: item.msgInfo ? JSON.parse(item.msgInfo).fbMsg : "",
              fbProduct: item.msgInfo ? JSON.parse(item.msgInfo).fbProduct : "",
              fbTitle: item.msgInfo ? JSON.parse(item.msgInfo).fbTitle : "",
              fileList: item.msgInfo ? JSON.parse(item.msgInfo).fileList : "",
              fileName: item.msgInfo ? JSON.parse(item.msgInfo).fileName : "",
              kakaoAdFlag: item.msgInfo ? JSON.parse(item.msgInfo).kakaoAdFlag : "",
              kakaoButton: item.msgInfo ? JSON.parse(item.msgInfo).kakaoButton : "",
              kakaoSenderKey: item.msgInfo ? JSON.parse(item.msgInfo).kakaoSenderKey : "",
              kakaoTemplateKey: item.msgInfo ? JSON.parse(item.msgInfo).kakaoTemplateKey : "",
              msg: item.msgInfo ? JSON.parse(item.msgInfo).msg : "",
              repSendingYN: item.msgInfo ? JSON.parse(item.msgInfo).repSendingYN : "",
              reqProduct: item.msgInfo ? JSON.parse(item.msgInfo).reqProduct : "",
              sendNm: item.msgInfo ? JSON.parse(item.msgInfo).sendNm : "",
              tmpltName: item.msgInfo ? JSON.parse(item.msgInfo).tmpltName : "",
              trafficType: item.msgInfo ? JSON.parse(item.msgInfo).trafficType : "",
            };
          });
        } else {
          //confirm.fnAlert("최근작성문자", "최근작성문자 불러오기에 실패하였습니다.", "alert");
          //console.log(response);
        }
      });
    },
    // 최근작성문자 가져오기
    fnRecentSendAlimList(msg) {
      this.alimTalkInfo.chnlId = msg.chnlId;
      this.alimTalkInfo.tmpltNm = msg.tmpltName;
      this.alimTalkInfo.tmpltSbst = msg.msg;
      //this.alimTalkInfo.buttonList = JSON.parse(msg.kakaoButton);

      this.title = msg.title ? msg.title : "";
      this.adTitle = msg.adTitle ? msg.adTitle.replace("(광고)", "") : "";
      this.authNumber = msg.authNumber ? msg.authNumber : "";
      this.adBottom = msg.adBottom ? msg.adBottom : "";
      this.msg = msg.content ? msg.content : "";

      // 바이트 계산
      this.msgTextByte = this.$gfnCommonUtils.getTextLen(this.alimTalkInfo.tmpltSbst);
      // this.adMsgTextByte = this.$gfnCommonUtils.getTextLen(this.alimTalkInfo.adMsg);
      this.etcInfoTextByte = this.$gfnCommonUtils.getTextLen(this.alimTalkInfo.etcInfo);

      const btnSent = document.getElementById("btn_sent");
      btnSent.checked = true;
    },
    // 모바일 미리보기
    mPreAlimOn() {
      const PrevAlim = document.querySelectorAll(".preview_swiper");
      const Alimtalk = document.querySelector(".m_preview_alimtalk .talk_preview");
      const dimmed = document.querySelector(".dimmed");
      const mAlimPreview = document.querySelector(".m_preview_alimtalk");
      PrevAlim.forEach((item) => (item.style.display = "none"));
      Alimtalk.style.display = "block";
      dimmed.classList.add("open");
      mAlimPreview.classList.add("on");
    },
    mHisAlimOn() {
      const PrevAlim = document.querySelectorAll(".preview_swiper");
      const Alimtalk = document.querySelector(".m_preview_alimtalk .talk_history");
      const dimmed = document.querySelector(".dimmed");
      const mAlimPreview = document.querySelector(".m_preview_alimtalk");
      PrevAlim.forEach((item) => (item.style.display = "none"));
      Alimtalk.style.display = "block";
      dimmed.classList.add("open");
      mAlimPreview.classList.add("on");
    },
    fnRemoveDefaultAutoMsg() {
      this.alimTalkInfo.tmpltSbst = this.alimTalkInfo.tmpltSbst.replace("#{phone}", "");
      this.msgTextByte = this.$gfnCommonUtils.getTextLen(this.alimTalkInfo.tmpltSbst);
    },
  },
};
</script>
