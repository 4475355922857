<template>
  <Fragment>
    <div class="m_section" v-if="!chgPwd">
      <section v-if="memberInfo" class="m_section p_including">
        <!-- class="p_including" : preview 포함 -->
        <!--	s: 	contents-->
        <div class="contents">
          <MobileGnb />
          <div class="info_modify">
            <h1 class="title with_desc">회원정보 수정</h1>
            <p class="desc">
              사업자 및 관리자 정보를 변경하려면 비즈마켓 홈페이지(bizmarket.uplus.co.kr) 또는 U+ 기업메시징 고객센터 (1544-5992)로 문의해 주세요.<br />
              <span class="filepop_top_desc">- 상담 가능 시간 : 평일 오전 9시~오후 6시, 공휴일과 주말은 제외</span>
            </p>
            <div class="payment">
              <h2 class="title">사업자정보</h2>
              <ul class="info_list">
                <li class="fl">
                  <h3>사업자 종류</h3>
                  <p>{{ userInfo.custTyNm }}</p>
                </li>
                <li class="fl">
                  <h3>사업자등록번호</h3>
                  <p>{{ userInfo.bizrno }}</p>
                </li>
                <li class="fl">
                  <h3>대표자명</h3>
                  <p>{{ userInfo.reprNm }}</p>
                </li>
                <li class="fl">
                  <h3>법인번호</h3>
                  <p>{{ userInfo.corpno }}</p>
                </li>
                <li class="w100p">
                  <h3>업체명</h3>
                  <p>{{ userInfo.custNm | fromHtmlEntities }}</p>
                </li>
                <li class="fl">
                  <h3>업종</h3>
                  <p>{{ userInfo.tpindNm }}</p>
                </li>
                <li class="fl">
                  <h3>업태</h3>
                  <p>{{ userInfo.bizNm }}</p>
                </li>
                <li>
                  <h3>종목</h3>
                  <p>{{ userInfo.anitemNm }}</p>
                </li>
                <li class="cr w100p">
                  <h3>주소</h3>
                  <p>{{ userInfo.comAddr }}</p>
                </li>
              </ul>
            </div>
            <div class="manager">
              <h2 class="title">관리자 정보</h2>
              <ul class="info_list">
                <li>
                  <h3>담당자명</h3>
                  <p>{{ userInfo.parentUserNm }}</p>
                </li>
                <li>
                  <h3>휴대폰 번호</h3>
                  <p>{{ userInfo.parentHpNo }}</p>
                </li>
                <li>
                  <h3>이메일 주소</h3>
                  <p>{{ userInfo.parentEmail }}</p>
                </li>
                <li v-if="autCd === 'H001' || autCd === 'H004'">
                  <h3>비밀번호</h3>
                  <button type="button" @click="fnChangePwd" class="btn">변경하기</button>
                </li>
              </ul>
            </div>
            <!-- <div v-if="autCd == 'H002' || autCd == 'H004'" class="user"> -->
            <div v-if="autCd == 'H002'" class="user">
              <h2 class="title">사용자 정보</h2>
              <ul class="info_list">
                <li>
                  <h3>사용자명</h3>
                  <input type="text" v-model="userInfo.userNm" />
                </li>
                <li>
                  <h3>휴대폰번호</h3>
                  <input type="text" v-model="userInfo.hpNo" @input="fnPhoneDash($event)" />
                </li>
                <li>
                  <h3>이메일 주소</h3>
                  <input type="text" v-model="userInfo.email" />
                </li>
                <li>
                  <h3>비밀번호</h3>
                  <button type="button" @click="fnChangePwd" class="btn">변경하기</button>
                </li>
              </ul>
            </div>
            <!-- <div v-if="autCd == 'H002' || autCd == 'H004'" class="btn_group"> -->
            <div v-if="autCd == 'H002'" class="btn_group">
              <button type="button" class="btn large_white" @click="fnCancelConfirm">취소</button>
              <button type="button" class="btn large_pink" @click="fnModifyConfirm">수정</button>
            </div>
          </div>
        </div>
        <!--	e: 	contents	-->
      </section>
    </div>
    <!--	e: m_section	-->
    <div v-if="chgPwd" class="login_wrap">
      <div class="contents">
        <div class="login_box">
          <div class="login_box-logo">
            <a href="javascript:void(0)">uplus 메시지허브이지</a>
            <p>Easy Messaging Service</p>
          </div>
          <div class="login_box-content">
            <h2 class="title with_desc">비밀번호 변경</h2>
            <p class="desc">개인 정보를 안전하게 보호하기 위해 비밀번호를 변경해 주세요.</p>
            <label for="smo" class="label">현재 비밀번호</label>
            <input type="password" ref="orgPsd" v-model="orgPwd" :placeholder="isMobile ? '현재 비밀번호를 입력하세요.' : '현재 비밀번호를 입력하세요.'" class="mb12" @focus="fnInputScroll" />
            <p class="mb12" v-if="isMobile">※ 영문, 숫자, 특수기호를 포함한 8~16자리</p>
            <label for="smo" class="label mg12t">새 비밀번호</label>
            <input type="password" ref="newPsd" v-model="newPwd" :placeholder="isMobile ? '새 비밀번호를 입력하세요.' : '새 비밀번호를 입력하세요.'" class="mb12" />
            <p class="mb12">※ 영문, 숫자, 특수기호를 포함한 8~16자리</p>
            <label for="smo" class="label mg12t">새 비밀번호 확인</label>
            <input type="password" ref="newPsdCe" v-model="confirmPwd" placeholder="새 비밀번호를 한 번 더 입력하세요." class="mb20" />
            <button type="button" @click="fnChangePwdExec" class="btn large_pink">변경</button>
          </div>
          <!-- <div class="login_box-content">
            <h2 class="title with_desc">비밀번호 변경</h2>
            <p class="desc">개인 정보를 안전하게 보호하기 위해 비밀번호를 변경해 주세요.</p>
            <label for="smo" class="label">현재 비밀번호</label>
            <input type="password" v-model="orgPwd" placeholder="현재 비밀번호를 입력하세요." />
            <p class="mb12">※ 영문, 숫자, 특수기호를 포함한 8~16자리</p>
            <label for="smo" class="label mg12t">새 비밀번호</label>
            <input type="password" v-model="newPwd" placeholder="새 비밀번호를 입력하세요." />
            <p class="mb12">※ 영문, 숫자, 특수기호를 포함한 8~16자리</p>

            <label for="smo" class="label mg12t">새 비밀번호 확인</label>
            <input type="password" v-model="confirmPwd" placeholder="새 비밀번호를 한 번 더 입력하세요." class="mb20" />
            <button type="button" @click="fnChangePwdExec" class="btn large_pink">변경</button>
          </div> -->
        </div>
      </div>
    </div>
  </Fragment>
</template>
<script>
import myInfoApi from "@/modules/myInfo/service/myInfoApi";
import confirm from "@/modules/commonUtil/service/confirm";
import { eventBus } from "@/modules/commonUtil/service/eventBus";
import MobileGnb from "@/components/MobileGnb.vue"; // 로그인 토큰 정보
import { Fragment } from "vue-frag";

export default {
  name: "confirmMemInfo",
  components: { MobileGnb, Fragment },
  props: {
    memberInfo: {
      type: Boolean,
      require: true,
    },
  },
  data() {
    return {
      isMobile: false,
      pcStandard: 896, // PC 화면의 Viewport 기준점
      autCd: "",
      chgPwd: false,
      userInfo: {
        custTyNm: "",
        bizrno: "",
        reprNm: "",
        corpno: "",
        custNm: "",
        tpindNm: "",
        bizNm: "",
        anitemNm: "",
        comAddr: "",
        parentUserNm: "",
        parentHpNo: "",
        parentEmail: "",
        userNm: "",
        hpNo: "",
        email: "",
      },
      orgPwd: "",
      newPwd: "",
      confirmPwd: "",
      mobile: false,
    };
  },
  created() {
    this.checkIsMobile();
  },
  mounted() {
    window.addEventListener("resize", this.handleResize);
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.handleResize);
  },
  methods: {
    fnSelectMemberInfo(ceamho) {
      var param = {
        cfUserPwd: ceamho,
      };
      myInfoApi
        .selectUserInfo(param)
        .then((response) => {
          var result = response.data;
          if (result.success) {
            this.autCd = result.data.userInfo.autCd;
            this.userInfo = result.data.userInfo;
          } else {
            eventBus.$on("callbackEventBus", () => {
              this.$emit("confirmMove");
            });
            confirm.fnConfirm("", "비밀번호를 인증해주세요.", "cbAlert");
          }
        })
        .catch((error) => {
          confirm.fnAlert("", "오류가 발생하였습니다.", "alert");
          console.log(error);
        });
    },
    fnCancelConfirm() {
      eventBus.$on("callbackEventBus", this.fnCancel);
      confirm.fnConfirm("", "수정한 내용이 있습니다.<br />수정을 취소하시겠어요?", "confirm");
    },
    fnCancel() {
      this.$emit("memInfoCancel");
    },
    fnPhoneDash(e) {
      const pattern_notNumber = /[^-0-9]/g; // 숫자가 아닌것 체크
      // 숫자 외 입력시
      if (pattern_notNumber.test(e.target.value)) {
        confirm.fnAlert("", "휴대폰번호는 숫자만 입력 가능 합니다.", "alert");
        this.userInfo.hpNo = e.target.value.replace(/[^0-9]/g, "");
      }
    },
    fnModifyConfirm() {
      if (this.userInfo.userNm == "") {
        confirm.fnAlert("", "담당자명을 입력해 주세요.", "alert");
        return false;
      }
      if (this.userInfo.hpNo == "") {
        confirm.fnAlert("", "휴대폰 번호를 입력해 주세요.", "alert");
        return false;
      } else {
        let regExp = /^01([0|1|6|7|8|9])-?([0-9]{3,4})-?([0-9]{4})$/;
        if (!regExp.test(this.userInfo.hpNo)) {
          confirm.fnAlert("", "휴대폰 번호를 올바르게 입력하여 주십시오.", "alert");
          return false;
        }
      }
      if (this.userInfo.email == "") {
        confirm.fnAlert("", "이메일을 입력해 주세요.", "alert");
        return false;
      }
      eventBus.$on("callbackEventBus", this.fnModifyMemberInfo);
      confirm.fnConfirm("", "수정한 내용을 저장하시겠어요?", "confirm");
    },
    fnModifyMemberInfo() {
      var userInfoParam = {
        userNm: this.userInfo.userNm,
        hpNo: this.userInfo.hpNo,
        email: this.userInfo.email,
      };
      myInfoApi
        .updateUserInfo(userInfoParam)
        .then((response) => {
          var result = response.data;
          if (result.success) {
            confirm.fnAlert("", "수정되었습니다.", "alert");
          }
        })
        .catch((error) => {
          confirm.fnAlert("", "오류가 발생하였습니다.", "alert");
          console.log(error);
        });
    },
    fnChangePwd() {
      this.orgPwd = "";
      this.newPwd = "";
      this.confirmPwd = "";
      this.chgPwd = true;
      window.scrollTo({ top: 0 });
      setTimeout(() => {
        this.$refs.orgPsd.focus();
      }, 200);
    },
    fnUpdateMemberForm() {
      this.chgPwd = false;
    },
    fnChangePwdExec() {
      if (this.orgPwd === "") {
        eventBus.$on("callbackEventBus", () => {
          this.$refs.orgPsd.focus();
        });
        confirm.fnConfirm("", "현재 비밀번호를 입력해 주세요.", "cbAlert");
        return false;
      }

      if (this.newPwd === "") {
        eventBus.$on("callbackEventBus", () => {
          this.$refs.newPsd.focus();
        });
        confirm.fnConfirm("", "새 비밀번호를 입력해 주세요.", "cbAlert");
        return false;
      }

      if (this.confirmPwd === "") {
        eventBus.$on("callbackEventBus", () => {
          this.$refs.newPsdCe.focus();
        });
        confirm.fnConfirm("", "새 비밀번호 확인을 입력해 주세요.", "cbAlert");
        return false;
      }

      if (this.orgPwd === this.newPwd) {
        eventBus.$on("callbackEventBus", () => {
          this.$refs.newPsd.focus();
        });
        confirm.fnConfirm("", "새 비밀번호가 현재 비밀번호와 같습니다.", "cbAlert");
        return false;
      }
      let passwordRule = /^(?=.*[a-zA-Z])(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{8,16}$/;
      if (!passwordRule.test(this.newPwd)) {
        eventBus.$on("callbackEventBus", () => {
          this.$refs.newPsd.focus();
        });
        confirm.fnConfirm("", "비밀번호는 영어, 숫자, 특수기호를 포함한<br/>8자~16자 이어야 합니다.", "cbAlert");
        return false;
      }
      if (this.newPwd != this.confirmPwd) {
        eventBus.$on("callbackEventBus", () => {
          this.$refs.newPsdCe.focus();
        });
        confirm.fnConfirm("", "새 비밀번호와 비밀번호 확인이 일치하지 않습니다.", "cbAlert");
        return false;
      }
      var param = {
        orgPwd: this.orgPwd,
        newPwd: this.newPwd,
        confirmPwd: this.confirmPwd,
      };
      myInfoApi
        .changePwdExec(param)
        .then((response) => {
          var result = response.data;
          if (result.success) {
            eventBus.$on("callbackEventBus", this.fnUpdateMemberForm);
            confirm.fnConfirm("", "비밀번호가 변경되었습니다.", "cbAlert");
          } else {
            if (result.code == "CE_WRONG_PWD") {
              eventBus.$on("callbackEventBus", () => {
                this.$refs.orgPsd.focus();
              });
              confirm.fnConfirm("", "비밀번호를 정확하게 입력해 주세요.", "cbAlert");
            } else if (result.code == "CE_USED_PWD") {
              eventBus.$on("callbackEventBus", () => {
                this.$refs.newPsdCe.focus();
              });
              confirm.fnConfirm("", "새 비밀번호가 현재 비밀번호와 같습니다.", "cbAlert");
            } else if (result.code == "CE_PTN_PWD") {
              eventBus.$on("callbackEventBus", () => {
                this.$refs.newPsdCe.focus();
              });
              confirm.fnConfirm("", "비밀번호는 영어, 숫자, 특수기호를 포함한<br/>8자~16자 이어야 합니다.", "cbAlert");
            } else if (result.code == "CE_PRV_USED_PWD") {
              eventBus.$on("callbackEventBus", () => {
                this.$refs.newPsdCe.focus();
              });
              confirm.fnConfirm("", "이전에 사용했던 비밀번호는 설정할 수 없습니다.", "cbAlert");
            } else {
              confirm.fnAlert("", "오류가 발생하였습니다.", "alert");
            }
          }
        })
        .catch((error) => {
          confirm.fnAlert("", "오류가 발생하였습니다.", "alert");
          console.log(error);
        });
    },

    checkIsMobile: function () {
      this.isMobile = window.innerWidth < this.pcStandard || screen.width < this.pcStandard;
    },

    handleResize: function () {
      this.$nextTick(function () {
        this.checkIsMobile();
      });
    },
    fnInputScroll() {
      if (this.isMobile) window.scrollTo({ top: 110 });
    },
  },
};
</script>
