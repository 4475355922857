import useTerms from "../views/useTerms";
import chargeNotice from "../views/chargeNotice";
import privacyPolicy from "../views/privacyPolicy";
import spamPolicy from "../views/spamPolicy";

export default [
  {
    path: "/policy/useTerms",
    name: "이용약관",
    component: useTerms,
  },
  {
    path: "/policy/chargeNotice",
    name: "요금신고내역",
    component: chargeNotice,
  },
  {
    path: "/policy/privacyPolicy",
    name: "개인정보처리방침",
    component: privacyPolicy,
  },
  {
    path: "/policy/spamPolicy",
    name: "스팸정책",
    component: spamPolicy,
  },
];
