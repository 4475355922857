import sendMessage from "../views/sendMessage";
import sendMessageDetail from "../views/sendMessageDetail";
import rsvSendMessage from "../views/rsvSendMessage";
import rsvMsgSmsModi from "../views/rsvMsgSmsModi";
import rsvAlimModi from "../views/rsvAlimModi";
import sendMessageStats from "../views/sendMessageStats";

export default [
  {
    path: "/sendManage",
    redirect: { path: "/sendManage/sendMessage" },
  },
  {
    path: "/sendManage/sendMessage",
    name: "전송내역",
    component: sendMessage,
  },
  {
    path: "/sendManage/rsvMsgSmsModi/:rsvId",
    name: "메시지 전송내역 상세보기",
    component: rsvMsgSmsModi,
    props: true,
  },
  {
    path: "/sendManage/rsvAlimModi/:rsvId",
    name: "알림톡 전송내역 상세보기",
    component: rsvAlimModi,
    props: true,
  },
  {
    path: "/sendManage/sendMessageDetail/:webReqId",
    name: "sendMessageDetail",
    component: sendMessageDetail,
    props: true,
  },
  {
    path: "/sendManage/rsvSendMessage",
    name: "예약전송내역",
    component: rsvSendMessage,
  },
  {
    path: "/sendManage/sendMessageStats",
    name: "사용자통계",
    component: sendMessageStats,
  },
];
