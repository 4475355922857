<template>
  <div class="contents">
    <div style="display: flex; flex-direction: column; height: 70vh">
      <div class="tabcont_wrap without_tab inquiry_member">
        <h1 class="title with_desc">본인인증이 실패하였습니다.</h1>
        <div class="tabcontTop">
          <ul>
            <li>
              인증 후 결과값은 내부 설정에 따른 값만 리턴받으실 수 있습니다. <br />
              <span class="tabcontTop_extra_desc">일부 결과값이 null로 리턴되는 경우 관리담당자 또는<br />계약부서(02-2122-4615)로 문의바랍니다.</span>
            </li>
          </ul>
        </div>
        <div class="box_wrap">
          <div class="box_top" style="margin-bottom: 10px">
            <h3 class="tit required">복호화한 시간</h3>
            <div class="top_right addtooltip">
              <p>{{ sCipherTime ? sCipherTime : "-" }}</p>
              <!-- (YYMMDDHHMMSS) -->
            </div>
          </div>
          <div class="box_top" style="margin-bottom: 10px">
            <h3 class="tit required">요청 번호</h3>
            <div class="top_right addtooltip">
              <p>{{ sRequestNumber ? sRequestNumber : "-" }}</p>
            </div>
          </div>
          <div class="box_top" style="margin-bottom: 10px">
            <h3 class="tit required">인증수단</h3>
            <div class="top_right addtooltip">
              <p>{{ sAuthType ? sAuthType : "-" }}</p>
            </div>
          </div>
          <div class="box_top">
            <h3 class="tit required">본인인증 실패 코드</h3>
            <div class="top_right addtooltip">
              <p>{{ sErrorCode ? sErrorCode : "-" }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- <div>
    <center>
      <p></p>
      <p></p>
      본인인증이 실패하였습니다.<br />
      <table border="1">
        <tr>
          <td>복호화한 시간</td>
          <td>{{ sCipherTime }} (YYMMDDHHMMSS)</td>
        </tr>
        <tr>
          <td>요청 번호</td>
          <td>{{ sRequestNumber }}</td>
        </tr>
        <tr>
          <td>인증수단</td>
          <td>{{ sAuthType }}</td>
        </tr>
        <tr>
          <td>본인인증 실패 코드</td>
          <td>{{ sErrorCode }}</td>
        </tr>
      </table>
      <br /><br />
      {{ sMessage }}
      <br />
    </center>
  </div> -->
</template>
<script>
import sendNumberApi from "@/modules/sendNumberManage/service/sendNumberApi";
import confirm from "@/modules/commonUtil/service/confirm";

export default {
  props: {
    EncodeData: String,
    //require: true,
  },
  data() {
    return {
      sCipherTime: "",
      sRequestNumber: "",
      sAuthType: "",
      sErrorCode: "",
    };
  },
  mounted() {
    this.fnGetNiceCheckFail();
  },
  methods: {
    fnGetNiceCheckFail() {
      //console.log(this.$router.history.current.query.EncodeData);
      var params = {
        EncodeData: this.EncodeData,
      };

      sendNumberApi.getNiceCheckFail(params).then((response) => {
        const result = response.data;
        //(result);
        if (result.success) {
          this.sCipherTime = result.data.sCipherTime;
          this.sRequestNumber = result.data.sRequestNumber;
          this.sAuthType = result.data.sAuthType;
          this.sErrorCode = result.data.sErrorCode;

          confirm.fnAlert("본인인증 실패", "다시 시도해주시기 바랍니다.");
          self.close();
        } else {
          confirm.fnAlert("본인인증 실패", result.message);
          self.close();
        }
      });
    },
  },
};
</script>
