<template>
  <Fragment>
    <!--	s: m_section	-->
    <section class="m_section">
      <!--	s: 	contents-->
      <div class="contents">
        <MobileGnb />
        <div class="tabcont_wrap without_tab send_detail">
          <div class="tabcont on">
            <h1 class="title">상세 내역 보기</h1>
            <div class="tab_top">
              <p>전송일자: {{ mstStatusDetail.reqDt }}</p>
              <button class="btn white" type="buttone" @click="fnRefresh">새로고침</button>
            </div>
            <div class="cont01">
              <div class="fixed-table-cover">
                <div class="fixed-table-container row2" style="width: 1074px">
                  <div class="fixed-table-header-bg"></div>
                  <div class="fixed-table-wrapper">
                    <table class="fixed-table" style="table-layout: fixed">
                      <thead>
                        <tr>
                          <th class="td_sender" style="width: 10%">
                            <div class="th-text">전송자 명</div>
                          </th>
                          <th class="td_type" style="width: 15%">
                            <div class="th-text">메시지 종류</div>
                          </th>
                          <th class="td_count" style="width: 13%">
                            <div class="th-text">총 전송 건수</div>
                          </th>
                          <th class="td_alt" style="width: 27%">
                            <div class="th-text row">
                              <p>전송 결과</p>
                              <div class="col">
                                <span class="td_alt_success">성공 건수/성공률</span>
                                <span class="td_alt_fail">실패 건수/실패율</span>
                              </div>
                            </div>
                          </th>
                          <th class="td_alt" style="width: 27%">
                            <div class="th-text row">
                              <p>문자메시지 전환 전송</p>
                              <div class="col">
                                <span class="td_alt_success">성공 건수/성공률</span>
                                <span class="td_alt_fail">실패 건수/실패율</span>
                              </div>
                            </div>
                          </th>
                          <th class="td_state" style="width: 8%">
                            <div class="th-text">전송 상태</div>
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td class="td_sender">{{ mstStatusDetail.userNm }}</td>
                          <td class="td_type">{{ mstStatusDetail.chStr ? mstStatusDetail.chStr.replace("KKO_NOTI", "알림톡") : "" }}</td>
                          <td class="td_count">{{ mstStatusDetail.sendCnt }}</td>
                          <td class="td_alt">
                            <div class="row">
                              <div class="col">
                                <span class="td_alt_success blue">{{ mstStatusDetail.succCnt }}/{{ mstStatusDetail.succRt }}%</span>
                                <span class="td_alt_fail red">{{ mstStatusDetail.failCnt }}/{{ mstStatusDetail.failRt }}%</span>
                              </div>
                            </div>
                          </td>
                          <td class="td_alt">
                            <div class="row">
                              <div class="col">
                                <span class="td_alt_success blue">{{ mstStatusDetail.fbSuccCnt }}/{{ mstStatusDetail.fbSuccRt }}%</span>
                                <span class="td_alt_fail red">{{ mstStatusDetail.fbFailCnt }}/{{ mstStatusDetail.fbFailRt }}%</span>
                              </div>
                            </div>
                          </td>
                          <td class="td_state">{{ msgStatus }}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
            <div class="cont02 p_including">
              <!-- s: preview -->
              <div class="m_preview_wrap">
                <button class="btn_close"></button>
                <div class="m_preview" v-if="mstStatusDetail.chStr != 'KKO_NOTI'">
                  <div class="preview_top" style="margin-bottom: 10px">
                    <span>{{ msgStatusPreview.callbackNumber | phoneNumAddDash }}</span>
                  </div>
                  <!--	s: 미리보기	 -->
                  <div class="preview_cont">
                    <!--	s: 이미지 첨부시 -->
                    <swiper v-if="!this.$gfnCommonUtils.isEmpty(fileList)" class="pcont_top" ref="previewSwiper" :options="swiperOption">
                      <swiper-slide class="pcont_img" v-for="(image, index) in fileList" :key="index"><img :src="image.src" /></swiper-slide>
                    </swiper>
                    <div class="swiper-pagination" slot="pagination" v-show="fileList.length > 0"></div>
                    <!-- 	e: 이미지 첨부시 -->
                    <div class="pcont_bottom" :class="this.$gfnCommonUtils.isEmpty(fileList) ? 'sms' : ''" :style="mstStatusDetail.chStr !== 'MMS' ? '' : ''">
                      <!-- 이미지 없을때 class="sms" 추가 -->
                      <div class="pcont_tit">
                        <p class="output_tit" style="color: rgb(18, 16, 6)">{{ msgStatusPreview.title }}</p>
                      </div>
                      <div class="pcont_txt scr_wrap">
                        <p class="cont" style="color: rgb(18, 16, 6)" v-html="msgStatusPreview.msg"></p>
                      </div>
                    </div>
                    <div v-if="!this.$gfnCommonUtils.isEmpty(mstStatusDetail.chStr)" class="pcont_info">
                      <span class="type">{{
                        mstStatusDetail.chStr === "SMS" ? "단문메시지(SMS)" : mstStatusDetail.chStr === "LMS" ? "장문메시지(LMS)" : mstStatusDetail.chStr === "MMS" ? "사진메시지(MMS)" : ""
                      }}</span>
                    </div>
                  </div>
                </div>
                <!--	e: 미리보기	 -->
                <div v-else class="m_preview talk_preview">
                  <!--	s: 알림톡 미리보기	 -->
                  <div class="preview_top" style="margin-bottom: 10px">
                    <span>{{ msgStatusPreview.chnlId }}</span>
                  </div>
                  <div class="preview_cont scr_wrap">
                    <div class="talk_channel">
                      <div class="img"><img src="../../../assets/images/preview_icon.png" alt="" /></div>
                      <p class="channel_name">알림톡</p>
                    </div>
                    <div class="talk_wrap">
                      <div class="talk_top">
                        <span>알림톡 도착</span>
                      </div>
                      <div class="talk_cont">
                        <div v-if="msgStatusPreview.emphsTpCd === 'TEXT'" class="talk_tit">
                          <p class="t_sub" style="color: rgb(18, 16, 6)">
                            {{ msgStatusPreview.emphsSubtle ? msgStatusPreview.emphsSubtle : "강조표기 보조문구 미리보기" }}
                          </p>
                          <p class="t_tit" style="color: rgb(18, 16, 6)">{{ msgStatusPreview.emphsTitle ? msgStatusPreview.emphsTitle : "제목 미리보기" }}</p>
                        </div>
                        <div class="talk_txt">
                          <p class="cont" style="color: rgb(18, 16, 6)" v-html="previewAlimMsg ? previewAlimMsg : '내용 미리보기'"></p>
                          <p
                            class="sub_info"
                            v-if="msgStatusPreview.tmpltTpCd === 'EX' || msgStatusPreview.tmpltTpCd === 'MI'"
                            style="color: rgb(18, 16, 6)"
                            v-html="previewEtc ? previewEtc : '부가정보 미리보기'"
                          ></p>
                          <p
                            class="sub_info"
                            v-if="msgStatusPreview.tmpltTpCd === 'AD' || msgStatusPreview.tmpltTpCd === 'MI'"
                            style="color: rgb(18, 16, 6)"
                            v-html="previewAd ? previewAd : '채널 추가하고 이 채널의 마케팅 메시지 등을 카카오톡으로 받기'"
                          ></p>
                        </div>
                        <div class="talk_btn" v-for="(button, index) in buttonList" :key="index">{{ button.name }}</div>
                      </div>
                    </div>
                  </div>
                  <!--	e: 알림톡 미리보기	 -->
                </div>
                <button v-if="!this.$gfnCommonUtils.isEmpty(mstStatusDetail.fbChStr)" class="btn white" type="button" @click="ModalOpen('alt_sms')">문자메시지 전환시 미리 보기</button>
              </div>
              <!-- e: preview -->
              <!-- s: list_area -->
              <div class="list_area">
                <div class="list_info">
                  <div class="left_wrap">
                    <div class="select_wrap">
                      <select v-model="selected" @change="fnSelected" class="select">
                        <option value="30" selected>30</option>
                        <option value="50">50</option>
                        <option value="100">100</option>
                      </select>
                    </div>
                    <p class="total">
                      전체 <span class="pink">{{ totCnt }} </span>건
                    </p>
                  </div>
                  <div class="right_wrap">
                    <div class="select_wrap">
                      <select v-model="sendCh" class="select">
                        <option value="" selected>메시지 종류 전체</option>
                        <option value="SMS">단문 메시지(SMS)</option>
                        <option value="LMS">장문 메시지(LMS)</option>
                        <option value="MMS">사진/영상 메시지(MMS)</option>
                        <option value="KKO_NOTI">알림톡</option>
                      </select>
                    </div>
                    <div class="search_wrap">
                      <input type="text" v-model="phone" class="search" placeholder="수신번호를 입력하세요." />
                      <button class="btn_search" @click="fnSendMessageDetailSearch"></button>
                    </div>
                  </div>
                </div>

                <div class="fixed-table-cover">
                  <div class="fixed-table-container" style="width: 734px">
                    <div class="fixed-table-header-bg"></div>
                    <div class="fixed-table-wrapper">
                      <table class="fixed-table" style="table-layout: fixed">
                        <thead>
                          <tr>
                            <th class="td_number" style="width: 18%">
                              <div class="th-text">수신번호</div>
                            </th>
                            <th class="td_date" style="width: 24%">
                              <div class="th-text">전송 일자</div>
                            </th>
                            <th class="td_result" style="width: 25%">
                              <div class="th-text">전송 결과 수신 일자</div>
                            </th>
                            <th class="td_type" style="width: 13%">
                              <div class="th-text">메시지 종류</div>
                            </th>
                            <th class="td_agency" style="width: 10%">
                              <div class="th-text">통신사</div>
                            </th>
                            <th class="td_state" style="width: 10%">
                              <div class="th-text">전송 상태</div>
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr v-for="(data, index) in sendMsgStsDtlList" :key="index">
                            <td class="td_number">{{ data.phone }}</td>
                            <td class="td_date">{{ data.sentDate }}</td>
                            <td class="td_result">{{ data.doneDate }}</td>
                            <td class="td_type">{{ data.sendCh }}</td>
                            <td class="td_agency">{{ data.doneTelco }}</td>
                            <td class="td_state">{{ data.doneCodeDesc }}</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>

                <!--
                <div class="list_cover">
                  <div class="list_th_wrap" @scroll="handleThScroll($event)">
                    <ul class="list_th">
                      <li class="td_number w135"><span>수신번호</span></li>
                      <li class="td_date w183"><span>전송 일자</span></li>
                      <li class="td_result w183"><span>전송 결과 수신 일자</span></li>
                      <li class="td_type w100"><span>메시지 종류</span></li>
                      <li class="td_agency w60"><span>통신사</span></li>
                      <li class="td_state w65"><span>전송 상태</span></li>
                    </ul>
                  </div>
                  <div class="list_wrap scr_wrap" @scroll="handleScroll($event)">
                    <ul class="list">
                      <li v-for="(data, index) in sendMsgStsDtlList" :key="index">
                        <span class="td_number w135">{{ data.phone }}</span>
                        <span class="td_date w183">{{ data.sentDate }}</span>
                        <span class="td_result w183">{{ data.doneDate }}</span>
                        <span class="td_type w100">{{ data.sendCh }}</span>
                        <span class="td_agency w60 blue">{{ data.doneTelco }}</span>
                        <span class="td_state w65 red">{{ data.doneCodeDesc }}</span>
                      </li>
                    </ul>
                  </div>
                </div> -->

                <div class="paging_wrap" v-show="totCnt > listSize">
                  <!-- 페이징 컴퍼넌트 -->
                  <PageLayer ref="updatePaging" @fnClick="fnSearch" :listTotalCnt="totCnt" :selected="listSize" :pageNum="pageNo"></PageLayer>
                </div>
              </div>
              <!-- e: list_area -->
            </div>
          </div>
          <div class="btn_wrap">
            <button class="btn large_white" @click="$router.push({ path: '/sendManage/sendMessage' })" type="button">목록</button>
          </div>
        </div>
      </div>
      <!--	e: 	contents	-->
    </section>
    <!--  s: m_btn_wrap -모바일버전에서 버튼  -->
    <!-- <div class="m_btn_wrap">
      <button type="button" class="m_preview_btn" @click="mPreSmsOn()" v-if="mstStatusDetail.chStr != 'KKO_NOTI'">미리보기</button>
      <button type="button" class="m_preview_btn" @click="mPreAlimOn()" v-if="mstStatusDetail.chStr == 'KKO_NOTI'">미리보기</button>
    </div>
    <MobilePreviewSms
      v-if="mstStatusDetail.chStr != 'KKO_NOTI'"
      :fileList="fileList"
      :title="msgStatusPreview.title"
      :msg="msgStatusPreview.msg"
      :reqProduct="mstStatusDetail.chStr"
      :callbackNumber="mstStatusDetail.callbackNumber"
    />
    <MobilePreviewAlim v-if="mstStatusDetail.chStr == 'KKO_NOTI'" :msgStatusPreview="msgStatusPreview" :mstStatusDetail="mstStatusDetail" :buttonList="buttonList" :fileList="fileList" /> -->
    <!--	e: m_section	-->

    <!-- <div class="dimmed" @click="ModalClose()"></div> -->
    <div class="popup-wrap">
      <div class="popup tit alt_sms">
        <div class="pop-head">
          <h3 class="pop-tit">전환되는 문자메시지 내용</h3>
        </div>
        <div class="pop-cont">
          <div class="m_preview_wrap">
            <div class="m_preview">
              <div class="preview_top">
                <span>{{ mstStatusDetail.callbackNumber | phoneNumAddDash }}</span>
              </div>
              <div class="preview_cont">
                <!--	s: 이미지 첨부시 -->
                <swiper v-if="!this.$gfnCommonUtils.isEmpty(fileList)" class="pcont_top" ref="previewSwiper" :options="swiperOption">
                  <swiper-slide class="pcont_img" v-for="(image, index) in fileList" :key="index"><img :src="image.src" /></swiper-slide>
                </swiper>
                <div class="swiper-pagination" slot="pagination" v-show="fileList.length > 0"></div>
                <!-- 	e: 이미지 첨부시 -->
                <div class="pcont_bottom" :class="{ sms: mstStatusDetail.fbChStr != 'MMS' }">
                  <div class="pcont_tit">
                    <p class="output_tit" style="color: rgb(18, 16, 6)">{{ msgStatusPreview.fbTitle }}</p>
                  </div>
                  <div class="pcont_txt scr_wrap">
                    <!-- <p class="name"><span>(광고)</span> 업체명/서비스명</p> -->
                    <p class="cont" style="color: rgb(18, 16, 6)" v-html="msgStatusPreview.fbMsg"></p>
                    <!-- <div class="fixed_txt">
											<p>무료거부 080-863-5863</p>
											<p>인증코드 674538</p>
										</div> -->
                  </div>
                </div>
                <div class="pcont_info">
                  <span class="type">{{ mstStatusDetail.fbChStr }}</span>
                  <span class="date">{{ mstStatusDetail.reqStr }} {{ mstStatusDetail.weekdayStr }}</span>
                </div>
              </div>
            </div>
          </div>
          <button class="btn large_pink" type="button" @click="ModalClose">확인</button>
        </div>
      </div>
    </div>
  </Fragment>
</template>
<script>
import { Fragment } from "vue-frag";
import sendManageApi from "@/modules/sendManage/service/sendManageApi";
import confirm from "@/modules/commonUtil/service/confirm";
import PageLayer from "@/components/PageLayer.vue";
import { swiper, swiperSlide } from "vue-awesome-swiper";
import MobileGnb from "@/components/MobileGnb.vue";
//import MobilePreviewSms from "../components/MobilePreviewSms.vue";
//import MobilePreviewAlim from "../components/MobilePreviewAlim.vue";

export default {
  name: "defaultName",
  components: {
    PageLayer,
    swiper,
    swiperSlide,
    Fragment,
    MobileGnb,
    /*     MobilePreviewSms,
    MobilePreviewAlim, */
  },
  props: {
    webReqId: {
      type: String,
      default: "",
    },
  },
  computed: {
    previewAlimMsg() {
      return this.msgStatusPreview.tmpltSbst ? this.msgStatusPreview.tmpltSbst.split(" ").join("&nbsp;").split("\n").join("<br/>") : "";
    },
    previewEtc() {
      return this.msgStatusPreview.etcInfo ? this.msgStatusPreview.etcInfo.split(" ").join("&nbsp;").split("\n").join("<br/>") : "";
    },
    previewAd() {
      return this.adMsg ? this.adMsg.split(" ").join("&nbsp;").split("\n").join("<br/>") : "";
    },
  },
  data() {
    return {
      selected: "30",
      listSize: 30, // select 박스 value (출력 갯수 이벤트)
      pageNo: 1, // 현재 페이징 위치
      totCnt: 0, //전체 리스트 수
      offset: 0, //페이지 시작점,
      phone: "",
      sendCh: "",
      mstStatusDetail: {},
      msgStatusPreview: {},
      msgStatus: "",
      sendMsgStsDtlList: [],
      fileList: [],
      buttonList: [],
      swiperOption: {
        slidesPerView: "auto",
        spaceBetween: 6, // swiper-slide 사이의 간격 지정
        slidesOffsetBefore: 0, // slidesOffsetBefore는 첫번째 슬라이드의 시작점에 대한 변경할 때 사용
        slidesOffsetAfter: 0, // slidesOffsetAfter는 마지막 슬라이드 시작점 + 마지막 슬라이드 너비에 해당하는 위치의 변경이 필요할 때 사용
        centerInsufficientSlides: true, // 컨텐츠의 수량에 따라 중앙정렬 여부를 결정함
        observer: true,
        observeParents: true,
        pagination: {
          el: ".swiper-pagination",
          clickable: true,
          renderBullet: function (index, className) {
            return '<span class="' + className + '">' + (index + 1) + "</span>";
          },
        },
      },
      adMsg: "채널 추가하고 이 채널의 마케팅 메시지 등을 카카오톡으로 받기", // 광고성 메시지
    };
  },
  created() {
    this.fnSelectMessageDetailInfo();
    this.fnSelectMessageDetailList();
  },
  mounted() {},
  methods: {
    fnSelectMessageDetailInfo() {
      let param = {
        webReqId: this.webReqId,
      };
      sendManageApi.selectMessageDetailInfo(param).then((response) => {
        var result = response.data;
        if (result.success) {
          this.mstStatusDetail = result.data.mstStatusDetail; //전송 상세 내용
          this.msgStatus = result.data.msgStatus; //전송 상태
          //미리보기
          result.data.msgStatusPreview.msg = result.data.msgStatusPreview.msg.replace(/(?:\r\n|\r|\n)/g, "<br />");

          if (result.data.msgStatusPreview.fbMsg) {
            result.data.msgStatusPreview.fbMsg = result.data.msgStatusPreview.fbMsg.replace(/(?:\r\n|\r|\n)/g, "<br />");
          }
          if (result.data.msgStatusPreview.kakaoButton) {
            this.buttonList = JSON.parse(result.data.msgStatusPreview.kakaoButton);
          }
          this.msgStatusPreview = result.data.msgStatusPreview;
          this.fileList = result.data.fileList.map((e) => {
            return {
              ...e,
              src: `/api/v1/fo/preview/${e.src}`,
              fileName: e.src,
            };
          });
        } else {
          confirm.fnAlert("", "오류가 발생하였습니다.", "alert");
        }
      });
    },
    async fnSelectMessageDetailList() {
      let param = {
        pageNo: this.pageNo, //페이징 필수 값
        listSize: this.listSize, //페이징 필수 값
        webReqId: this.webReqId,
        phone: this.phone,
        sendCh: this.sendCh,
      };
      await sendManageApi.selectSendMsgDetailList(param).then((response) => {
        var result = response.data;
        if (result.success) {
          this.sendMsgStsDtlList = result.data.sendMsgStsDtlList; //전송 대상 목록 조회
          this.totCnt = result.data.pageInfo.totCnt; //전체 카운트
          this.offset = result.data.pageInfo.offset; //페이지 번호
        } else {
          confirm.fnAlert("", "오류가 발생하였습니다.", "alert");
        }
      });
    },
    fnSelected() {
      //리스트 갯수 선택
      this.listSize = Number(this.selected);
      this.$refs.updatePaging.fnAllDecrease();
    },
    fnSendMessageDetailSearch() {
      this.$refs.updatePaging.fnAllDecrease();
    },
    fnSearch(pageNum) {
      // 검색
      this.pageNo = this.$gfnCommonUtils.defaultIfEmpty(pageNum, "1") * 1;
      this.fnSelectMessageDetailList();
    },
    fnRefresh() {
      this.fnSelectMessageDetailInfo();
      this.fnSelectMessageDetailList();
    },
    ModalOpen(target) {
      var dimmed = document.getElementsByClassName("dimmed");
      var wrap = document.getElementsByClassName("popup-wrap");
      var obj = document.getElementsByClassName(target);
      obj[0].classList.add("open");
      dimmed[0].style.display = "block";
      wrap[0].style.display = "block";
      obj[0].style.display = "block";
      this.ModalHeight();
    },
    ModalClose() {
      var dimmed = document.getElementsByClassName("dimmed");
      var wrap = document.getElementsByClassName("popup-wrap");
      var obj = wrap[0].childElementCount;
      dimmed[0].style.display = "none";
      wrap[0].style.display = "none";
      for (var i = 0; i < obj; i++) {
        var target = document.getElementsByClassName("popup");
        target[i].style.display = "none";
        target[i].classList.remove("open");
      }
    },
    ModalHeight() {
      //팝업 heght가 device height보다 클때
      var winH = window.innerHeight,
        obj = document.querySelector(".popup.open"),
        objH = obj.clientHeight;
      if (objH >= winH) {
        obj.classList.add("over");
      }
    },
    /*handleScroll(e) {
      const chooseListThWrap = e.target.parentNode.children[0];
      chooseListThWrap.scrollLeft = e.target.scrollLeft;
    },
    handleThScroll(e) {
      const chooseListWrap = e.target.parentNode.children[1];
      chooseListWrap.scrollLeft = e.target.scrollLeft;
    },*/
    // 모바일 미리보기
    mPreSmsOn() {
      const PrevAlim = document.querySelectorAll(".preview_swiper");
      const SMStalk = document.querySelector(".m_preview_alimtalk .sms_preview");
      const dimmed = document.querySelector(".dimmed");
      const mAlimPreview = document.querySelector(".m_preview_alimtalk");
      PrevAlim.forEach((item) => (item.style.display = "none"));
      SMStalk.style.display = "block";
      dimmed.classList.add("open");
      mAlimPreview.classList.add("on");
    },
    mPreAlimOn() {
      //const PrevAlim = document.querySelectorAll(".preview_swiper");
      //const SMStalk = document.querySelector(".m_preview_alimtalk .talk_preview");
      const dimmed = document.querySelector(".dimmed");
      const mAlimPreview = document.querySelector(".m_preview_alimtalk");
      //PrevAlim.forEach((item) => (item.style.display = "none"));
      //SMStalk.style.display = "block";
      dimmed.classList.add("open");
      mAlimPreview.classList.add("on");
    },
  },
};
</script>
