import httpClient from "@/common/http-client";

const selectImageList = (params) => {
  return httpClient.post("/commonApi/selectImageList", params, { headers: { "show-layer": "Yes", activity: "READ" } });
};
const deleteImage = (params) => {
  return httpClient.post("/commonApi/deleteImageFile", params, { headers: { "show-layer": "Yes", activity: "SAVE" } });
};
const uploadImage = (params) => {
  return httpClient.post("/commonApi/uploadImage", params, { headers: { "show-layer": "Yes", activity: "SAVE", "Content-Type": "multipart/form-data" } });
};
const selectImageUrlInfo = (params) => {
  return httpClient.post("/commonApi/selectImageUrlInfo", params, { headers: { "show-layer": "Yes", activity: "READ" } });
};

const selectCodeList = (params) => {
  return httpClient.post("/commonApi/selectCodeList", params, { headers: { "show-layer": "Yes", activity: "READ" } });
};

const downloadFile = (params) => {
  return httpClient.post("/commonApi/file/downloadFile", params, { headers: { "show-layer": "No" }, responseType: "arraybuffer" });
};

// 휴대폰 본인인증 체크
const getNiceCheck = (params) => {
  return httpClient.get("/api/callerId/getNiceCheck", params, { headers: { "show-layer": "Yes", activity: "READ" } });
};

// 휴대폰 본인인증 성공 체크
const getNiceCheckSuccess = (params) => {
  return httpClient.post("/api/callerId/getNiceCheckSuccess", params, { headers: { "show-layer": "Yes", activity: "READ" } });
};

const payProcess = (params) => {
  return httpClient.post("/api/payment/payProcess", params, { headers: { "show-layer": "Yes", activity: "READ" } });
};

export default {
  selectImageList,
  deleteImage,
  uploadImage,
  selectImageUrlInfo,
  selectCodeList,
  downloadFile,
  getNiceCheck,
  getNiceCheckSuccess,
  payProcess
};
