<template>
  <!-- s: 알림톡 템플릿 -->
  <div class="popup large tit template">
    <div class="pop-head">
      <h3 class="pop-tit">알림톡 템플릿 선택</h3>
    </div>
    <div class="pop-cont">
      <div class="template_wrap">
        <form @submit.prevent="" autocomplete="off">
          <div class="template_top">
            <div class="select_wrap">
              <select name="" id="" class="select" v-model="searchData.srchTmpltTpCd" @change="fnSelectAlimTalkTmpltList">
                <option value="null" selected>템플릿 유형 전체</option>
                <option value="BA">기본형</option>
                <option value="AD">광고추가형</option>
                <option value="EX">부가정보형</option>
                <option value="MI">복합형</option>
              </select>
            </div>
            <div class="select_wrap">
              <select name="" id="" class="select" v-model="searchData.srchEmphsTpCd" @change="fnSelectAlimTalkTmpltList">
                <option value="null" selected>강조표기 전체</option>
                <option value="01">선택안함</option>
                <option value="02">강조표기형</option>
                <option value="03">이미지형</option>
              </select>
            </div>
            <div class="search_wrap">
              <div class="search_input">
                <input type="text" class="search" placeholder="템플릿명을 입력하세요." v-model="searchData.srchText" />
                <button class="btn_search" @click="fnSelectAlimTalkTmpltList"></button>
              </div>
            </div>
          </div>
        </form>
        <form @submit.prevent="" autocomplete="off">
          <div class="list_wrap">
            <ul class="list card_list" v-if="tmpList.length > 0">
              <li class="card_wrap" v-for="(templ, index) in tmpList" :key="index">
                <label>
                  <!-- <input type="radio" :value="index" v-model="tmpIndex" class="card_chk" name="template" /> -->
                  <div class="card">
                    <!-- <div class="cont">
                      <img src="../../../assets/images/pop_template01.png" />
                    </div> -->
                    <div class="cont">
                      <p class="tit">{{ adMsg }}</p>
                      <p class="stxt" v-html="templ.tmpltSbst"></p>
                    </div>
                    <div class="cate">{{ templ.tmpltNm }}</div>
                  </div>
                </label>
                <button class="btn white" type="button" @click="fnAlimTemplatePreview(index)">선택</button>
              </li>
            </ul>
            <ul class="list card_list" v-if="tmpList.length === 0">
              <li class="empty">
                <span class="w100p">검색된 결과가 없습니다.</span>
              </li>
            </ul>
          </div>
          <div class="paging_wrap" v-if="tmpList.length > 0" v-show="tmpInfo.totCnt > tmpInfo.listSize">
            <PageLayer ref="updatePaging" @fnClick="fnSearch" :listTotalCnt="tmpInfo.totCnt" :selected="tmpInfo.listSize" :pageNum="tmpInfo.pageNo"></PageLayer>
          </div>
          <div class="pop-btn1">
            <!-- <button type="button" class="btn large_pink" @click="fnAlimTemplatePreview(tmpIndex)">확인</button> -->
            <button type="button" class="btn large_white" @click="ModalClose">닫기</button>
          </div>
        </form>
      </div>
    </div>
  </div>
  <!-- e: 알림톡 태블릿 -->
</template>
<script>
import confirm from "@/modules/commonUtil/service/confirm";
import { eventBus } from "@/modules/commonUtil/service/eventBus";
import templateManageApi from "@/modules/templeteManage/service/templeteManageApi.js";
import PageLayer from "@/components/PageLayer.vue";

export default {
  name: "templatePreview",
  components: { PageLayer },
  props: {
    tmpltParams: Object,
  },
  data() {
    return {
      alimTemplate: "알림톡 템플릿 전달",
      tmpList: [],
      tmpIndex: null,
      tmpInfo: {
        //페이지 정보
        listSize: 10, // select 박스 value (출력 갯수 이벤트)
        pageNo: 1, // 현재 페이징 위치
        totCnt: 0, //전체 리스트 수
        offset: 0, //페이지 시작점
        // 유저 시퀀스
      },
      searchData: {
        srchType: "01", //종류
        srchText: "", //검색어
        srchTmpltTpCd: "null", //템플릿유형:
        srchEmphsTpCd: "null", //강조유형:
        srchTmpltSttusCd: "null", //검수상태:
      },
      adMsg: "채널 추가하고 이 채널의 마케팅 메시지 등을 카카오톡으로 받기", // 광고성 메시지
    };
  },
  mounted() {
    //this.fnGetTemplateList();
  },
  watch: {
    tmpltParams: function (newTmpListData) {
      if (newTmpListData.chnlId === "null") {
        this.tmpList = [];
        return;
      }
      this.fnGetTemplateList(newTmpListData);
    },
  },

  methods: {
    fnGetTemplateList(tmpltParams) {
      let params = {
        ...this.tmpInfo,
        ...tmpltParams,
        ...this.searchData,
        srchTmpltSttusCd: "A",
        tmpltCombo: "Y",
      };

      templateManageApi.selectAlimTalkTmpltList(params).then((response) => {
        if (response.data.success) {
          this.tmpInfo.listSize = response.data.pageInfo.listSize;
          this.tmpInfo.offset = response.data.pageInfo.offset;
          this.tmpInfo.pageNo = response.data.pageInfo.pageNo;
          this.tmpInfo.totCnt = response.data.pageInfo.totCnt;
          if (response.data.data) {
            this.tmpList = response.data.data.map((e) => {
              return {
                ...e,
                selected: false,
              };
            });
          }
        } else {
          // confirm.fnAlert("알림톡 템플릿관리", "해당 리스트 가져오기에 실패하였습니다.", "alert");
          confirm.fnAlert("", "해당 리스트 가져오기에 실패하였습니다.", "alert");
        }
      });
    },
    fnAlimTemplatePreview(index) {
      if (index === null) {
        confirm.fnAlert("", "템플릿을 선택해 주세요.", "alert");
        return;
      }
      eventBus.$on("callbackEventBus", () => {
        this.fnAddAlimTemplate(index);
      });
      // confirm.fnConfirm("템플릿 추가", "선택한 템플릿를 등록하시겠습니까?", "confirm");
      confirm.fnConfirm("", "선택한 템플릿를 등록하시겠습니까?", "confirm");
    },
    fnAddAlimTemplate(index) {
      const selectedTmplt = this.tmpList[index];
      this.$emit("alimTemplate", selectedTmplt);
      this.ModalClose();
    },
    ModalClose() {
      var dimmed = document.getElementsByClassName("dimmed");
      var wrap = document.getElementsByClassName("popup-wrap");
      var obj = wrap[0].childElementCount;
      dimmed[0].style.display = "none";
      wrap[0].style.display = "none";
      for (var i = 0; i < obj; i++) {
        var target = document.getElementsByClassName("popup");
        target[i].style.display = "none";
        target[i].classList.remove("open");
      }
    },
    fnSearch(pageNum) {
      this.tmpInfo.pageNo = this.$gfnCommonUtils.defaultIfEmpty(pageNum, "1") * 1;
      this.fnGetTemplateList();
    },
    // 알림톡 템플릿관리 리스트
    fnSelectAlimTalkTmpltList() {
      this.tmpInfo = {
        // 템플릿유형, 강조표기형 선택 및 검색 버튼 클릭 시 페이징 정보 초기화
        pageNo: "1",
        listSize: this.tmpInfo.listSize,
        offset: "0",
        ...this.searchData,
        srchTmpltSttusCd: "A",
      };

      templateManageApi.selectAlimTalkTmpltList(this.tmpInfo).then((response) => {
        if (response.data.success) {
          if (response.data.data) {
            this.tmpList = response.data.data.map((e) => {
              return {
                ...e,
                selected: false,
              };
            });

            this.tmpInfo = response.data.pageInfo;
          }
        } else {
          // confirm.fnAlert("알림톡 템플릿관리", "해당 리스트 가져오기에 실패하였습니다.", "alert");
          confirm.fnAlert("", "해당 리스트 가져오기에 실패하였습니다.", "alert");
        }
      });
    },
  },
};
</script>
