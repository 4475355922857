<template>
  <div class="popup useredit tit updateUserPop">
    <div class="pop-head">
      <h3 class="pop-tit">사용자 정보</h3>
    </div>
    <div class="pop-cont">
      <div class="regist_row">
        <h3>아이디</h3>
        <div class="input_wrap">
          <p>{{ getUserInfo.userId }}</p>
        </div>
      </div>
      <div class="regist_row">
        <h3>이름</h3>
        <div class="input_wrap">
          <input type="text" class="input" v-model="getUserInfo.userNm" @input="updateChk($event)" />
        </div>
      </div>
      <div class="regist_row">
        <h3>휴대폰번호</h3>
        <div class="input_wrap">
          <input type="text" class="input" v-model="getUserInfo.hpNo" @input="updateChk($event)" />
        </div>
      </div>
      <div class="regist_row">
        <h3>이메일 주소</h3>
        <div class="input_wrap">
          <input type="text" class="input" v-model="getUserInfo.email" @input="updateChk($event)" />
        </div>
      </div>
      <div class="regist_row">
        <h3>사용 상태</h3>
        <div class="input_wrap">
          <span><input type="radio" class="radio" name="use" id="use1" value="01" v-model="getUserInfo.userSttusNm" @input="radioChange($event)" /><label for="use1">사용</label></span>
          <span><input type="radio" class="radio" name="use" id="use2" value="02" v-model="getUserInfo.userSttusNm" @input="radioChange($event)" /><label for="use2">정지</label></span>
        </div>
      </div>
    </div>
    <div class="pop-btn2 center">
      <button type="button" class="btn large_pink" @click="fnUpdateUserConfirm()">수정</button>
      <button type="button" class="btn large_white" @click="updateChkConfirm()">취소</button>
    </div>
  </div>
</template>

<script>
import userManageApi from "../service/userManageApi";
import confirm from "@/modules/commonUtil/service/confirm";
import { eventBus } from "@/modules/commonUtil/service/eventBus";

export default {
  name: "UpdateUserId",
  components: {},
  props: {
    userInfo: {
      userId: String,
      userNm: String,
      hpNo: String,
      email: String,
      userSttusNm: Boolean,
    },
  },
  watch: {
    userInfo: {
      handler: function (newUserInfo) {
        this.getUserInfo = newUserInfo;
      },
      deep: true,
    },
  },
  data() {
    return {
      getUserInfo: {},
      userStatus: "",
      userSttusCd: "",
      eventChk: false,
      chgHpNo: "",
    };
  },
  created() {},
  methods: {
    //변경 저장 확인
    fnUpdateUserConfirm() {
      //수정 유효성 체크
      if (!this.fnPhoneChk()) return false;
      if (!this.fnemailChk()) return false;
      eventBus.$on("callbackEventBus", () => {
        this.fnUpdateUserInfo();
      });
      confirm.fnConfirm("", "수정한 내용을 저장하시겠어요?", "save");
    },

    //휴대폰 번호 체크
    fnPhoneChk() {
      this.chgHpNo = this.getUserInfo.hpNo.replace(/[^0-9]/g, "");
      let regExp = /([0-9])$/;
      if (!regExp.test(this.chgHpNo)) {
        confirm.fnAlert("", "휴대폰번호를 확인해 주세요.", "alert");
        return false;
      }
      if (this.chgHpNo.length != 11) {
        confirm.fnAlert("", "휴대폰번호를 확인해 주세요.", "alert");
        return false;
      }
      return true;
    },

    //이메일 유효성 체크
    fnemailChk() {
      let regExp = /^([\w-]+(?:\.[\w-]+)*)@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$/i;
      if (!regExp.test(this.getUserInfo.email)) {
        // confirm.fnAlert("E-mail 형식 확인", "E-mail 형식에 맞지 않습니다.<br>확인하여 다시 등록 부탁 드립니다.", "alert", "!");
        confirm.fnAlert("", "E-mail 형식에 맞지 않습니다.<br />확인하여 다시 등록 부탁 드립니다.", "alert");
        return false;
      } else {
        return true;
      }
    },

    //사용자 수정 api
    fnUpdateUserInfo() {
      //휴대폰 번호 '-'제외하여 값 보내기
      if (this.userStatus == "") {
        this.userStatus = this.getUserInfo.userSttusNm;
      }
      const params = {
        userId: this.getUserInfo.userId,
        userNm: this.getUserInfo.userNm,
        hpNo: this.chgHpNo,
        email: this.getUserInfo.email,
        userSttusCd: this.userStatus,
      };
      userManageApi.updateUserIdInfo(params).then((response) => {
        var result = response.data;
        if (result.success) {
          this.ModalClose();
          this.$emit("fnselectUserList");
        } else {
          confirm.fnAlert("", "오류가 발생하였습니다.", "alert");
        }
      });
    },

    //변경사항 감지. 이벤트 있으면 eventChk = true
    updateChk(event) {
      if (event) {
        this.eventChk = true;
      }
    },

    //상태 변경 라디오 버튼 input 체크
    radioChange(event) {
      if (event) {
        this.eventChk = true;
      }
      if (event.target.id == "use1") {
        this.userStatus = "01";
      } else if (event.target.id == "use2") {
        this.userStatus = "02";
      }
    },

    //eventChk의 boolean 값 체크
    updateChkConfirm() {
      if (this.eventChk) {
        eventBus.$on("callbackEventBus", () => {
          this.ModalClose();
        });
        confirm.fnConfirm("", "수정한 내용이 있습니다.</ br>수정을 취소하시겠어요?", "cUpdate");
      } else {
        this.getUserInfo.userSttusNm = this.userInfo.userSttusNm;
        this.ModalClose();
      }
    },
    //모달 창 닫기
    ModalClose() {
      //eventChk값 초기화
      this.eventChk = false;
      var dimmed = document.getElementsByClassName("dimmed");
      var wrap = document.getElementsByClassName("popup-wrap");
      var obj = wrap[0].childElementCount;
      dimmed[0].style.display = "none";
      wrap[0].style.display = "none";
      for (var i = 0; i < obj; i++) {
        var target = document.getElementsByClassName("popup");
        target[i].style.display = "none";
        target[i].classList.remove("open");
      }
    },
  },
};
</script>
