<template>
  <section class="m_section">
    <!--	s: 	contents-->
    <div class="contents">
      <MobileGnb />
      <div class="tabcont_wrap without_tab charge_wrap">
        <div class="title_wrap">
          <h1 class="title">충전 하기</h1>
          <span class="required required_desc">필수 입력</span>
        </div>
        
        <div class="box_wrap balance_wrap">
          <div class="box_top">
            <h3 class="tit required">현재 충전 잔액</h3>
            <div class="top_right">
              <!-- 현재 충전 잔액 값 balance -->
              <p class="balance"> {{ userInfo.chrgBlnk }} 원</p>
            </div>
          </div>
        </div>
        <div class="box_wrap amount_wrap">
          <div class="box_top">
            <h3 class="tit required">
              충전 금액
            </h3>
            <div class="top_right">
              <div class="btn2_wrap">
                <button type="button" class="btn" v-for="(item, index) in chargeAmountList" :key="index" :value="item.value" @click="selectChrgAmt(item.value)">{{ item.text }}만원</button>
              </div>
            </div>
            <div class="top_right feebox">
              <div>
                <p>충전 선택 요금</p>
                <span>{{$gfnCommonUtils.formatComma(chargeAmount)}}원</span>
              </div>
              <div>
                <p>
                  총 결제 금액
                  <span>(부가세 포함)</span>
                </p>
                <span>{{ $gfnCommonUtils.formatComma(amount) }}원</span>
              </div>
            </div>
          </div>
        </div>
        <div class="box_wrap radiobox_wrap">
          <div class="box_top">
            <h3 class="tit">
              결제 수단
            </h3>
            <div class="top_right">
              <div class="radio_wrap">
                <ul>
                  <li v-for="(item, index) in methodList" :key="index">
                    <input type="radio" class="radio" :id="item.key" v-model="pickedMethod" :value="item" />
                    <label :for="item.key" class="text">{{ item.value }}</label>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div class="box_wrap list_wrap">
          <!-- 계좌이체/신용카드 유의사항 -->
          <ul class="list">
            <li>
              <span class="td_cate">
                <div class="badge">안내</div>
              </span>
              <span class="td_title faq_q">
                <a id="" @click="fnShowAndHide()">계좌이체/신용카드 유의사항</a>
              </span>
              <div class="td_answer" id="">
                <p style="font-weight: bold;">계좌이체 유의사항</p>
                <ul>
                  <li> • 계좌이체는 은행에 따라 한도에 차이가 있으니, 거래은행 이체 한도를 확인 하신 후 사용하시기 바랍니다.</li>
                  <li> • 가맹점인 경우, 현금영수증을 제공합니다.</li>
                  <li> • 은행 별 이용시간/한도금액을 확인하세요.</li>
                </ul>
                <p style="font-weight: bold;">신용카드 유의사항</p>
                <ul>
                  <li> • 신용카드 결제건에 대해서는 고객님의 이중 세무신고 예방을 위해 세금계산서가 발행되지 않습니다.</li>
                  <li> • 신용카드 매입전표로 세무신고 하시고, 불가피하게 세금계싼서가 필요한 경우에 한해 당월 분에 대해 발급가능하니 고객센터(1544-5992)로 연락 주시기 바랍니다.</li>
                  <li> • 안전결제(ISP) 사용 카드사 : 국민, BC, 우리카드</li>
                  <li> • 안심클릭 사용 카드사 : 신한, 현대, 롯데, 하나, 씨티, 외환, 광주, 전북, 수협카드</li>
                  <li> • 결제 문의 : 토스 고객센터 1599-4905</li>
                </ul>
              </div>
            </li>
          </ul>
          <!-- 환불 처리 안내 -->
          <ul class="list">
            <li>
              <span class="td_cate">
                <div class="badge">안내</div>
              </span>
              <span class="td_title faq_q">
                <a id="" @click="fnShowAndHide()">환불 처리 안내</a>
              </span>
              <div class="td_answer" id="">
                <p>아래의 제출서류를 FAX02-6919-1000로 접수하신 후 SMS고객센터(1544-5992)로 환불 요청 부탁 드립니다.</p>
                <ul>
                  <li> • 신용카드 : 신용카드 결제금액 취소(제출서류 없음)</li>
                  <li> • 계좌이체 : 계좌이체 한 동일계좌로 환불(제출 서류 없음)</li>
                  <li> • 무통장입금 : (법인사업자) 사업자등록증, 법인명의 통장사본 / (개인사업자) 사업자등록증, 개인사업자 명의 통장사본</li>
                </ul>
                <p>※ 결제일이 999일이 경과한 경우는 아래 서류 접수 부탁 드립니다.</p>
                <ul>
                  <li> • 신용카드 : 신용카드 결제금액 취소(제출서류 없음)</li>
                  <li> • 계좌이체/무통장입금 : (법인사업자) 사업자 등록증 사본, 인감증명서 원본, 수금융계좌신고서 원본, 법인명의 통장사본 / (개인사업자) 사업자 등록증 사본, 대표자 인감증명서 원본, 수금융계좌신고서 원본, 개인사업자 명의 통장사본<br></li>
                </ul>
                <p>※ 환불 소요기간은 당월 결제 건의 경우 영업일 기준 3일, 그 이전 결제 건의 경우 영업일 기준 7일이 소요됩니다.</p>
              </div>
            </li>
          </ul>
          <!-- 요금제 변경 안내 -->
          <ul class="list">
            <li>
              <span class="td_cate">
                <div class="badge">안내</div>
              </span>
              <span class="td_title faq_q">
                <a id="" @click="fnShowAndHide()">요금제 변경 안내 </a>
              </span>
              <div class="td_answer" id="">
                <ul>
                  <li> • 변경된 요금제 및 결제방식은 다음달 1일부터 적용됩니다.</li>
                  <li> • 요금제 변경 문의 : 메시지허브이지 고객샌터 (1544-5992)</li>
                </ul>
              </div>
            </li>
          </ul>
        </div>
        <div class="btn_wrap">
          <button type="button" class="btn large_pink" @click="clickPaymentButton">결제하기</button>
        </div>
      </div>      
    </div>
  </section>
</template>
<script>
import chargeApi from "@/modules/charge/service/chargeApi";
import confirm from "@/modules/commonUtil/service/confirm";
import MobileGnb from "@/components/MobileGnb.vue";
import { loadTossPayments } from '@tosspayments/payment-sdk'
import loginApi from "@/modules/login/service/loginApi";

export default {
  name: "tossPayProc",
  components: { MobileGnb },
  data() {
    return {
      userInfo: {
        userNm : "",
        email: "",
        userId : "",
        clientKey : "",
        chrgBlnk : "0",
        joinDiv : ""
      },
      chargeAmount : 0,
      amount : 0,
      chargeAmountList : [
        {text:"10",value:100000}
        ,{text:"30",value:300000}
        ,{text:"50",value:500000}
        ,{text:"100",value:1000000}
      ],
      tossPayments : null,
      methodList : [{key:"CARD",value:"신용카드 결제"},{key:"TRANSFER",value:"계좌이체"},{key:"VIRTUAL_ACCOUNT",value:"가상계좌 결제"}],
      pickedMethod: {key:"CARD",value:"신용카드 결제"}
    };
  },
  async mounted() {
    await this.selectPayUserInfo();
    this.fnShowAndHide();
  },
  methods: {
    selectChrgAmt(amt){
      this.chargeAmount = Number(amt);      

      this.amount = Number(this.chargeAmount) + Number(this.chargeAmount/10);
    },
    async selectPayUserInfo(){
      await chargeApi
        .selectPayUserInfo()
        .then((response) => {
          var result = response.data;
          if (result.success) {
            this.userInfo = result.data;
            this.loadPayment();
          }else{
            confirm.fnAlert("", result.message, "alert");
            this.goHome();
          }
        })
        .catch((error) => {
          confirm.fnAlert("", "오류가 발생하였습니다.", "alert");
          console.log(error);
          this.goHome();
        });
    },
    async loadPayment(){
      this.tossPayments = await loadTossPayments(this.userInfo.clientKey);
    },
    
    clickPaymentButton() {
      
      loginApi.selectAutInfo().then((response) => {
        var result = response.data;
        if (result.success) {
          if (result.data.autCd !== "H003") {
            this.tossPayments.requestPayment(this.pickedMethod.key,{
              amount: this.amount,
              orderId: this.userInfo.orderId+window.btoa(Math.random()).slice(4, 10),
              orderName: "메시지허브이지 선불결제",
              successUrl: window.location.origin + "/commonUtil/tossPaySuccess",
              failUrl: window.location.origin + "/commonUtil/tossPayFail",
              customerEmail: this.userInfo.email, // 고객 이메일 (선택)
              customerName: this.userInfo.userNm // 고객 이름 (선택)
            })
            .then((e) => {
              console.log(e);
            })
            .catch((error) => {
              confirm.fnAlert("", error, "alert");
            });
          } else {
            confirm.fnAlert("", "세션이 종료되어 다시 로그인 해주세요.", "alert");
          }
        } else {
          confirm.fnAlert("", "오류가 발생하였습니다.", "alert");
        }
      });

    },
    //상세내용
    fnShowAndHide() {
      // 클릭하면 답변 보이기
      var faqQ = document.getElementsByClassName("faq_q");

      for (var i = 0; i < faqQ.length; i++) {
        faqQ[i].onclick = function () {
          // 클릭이 일어났을 때 기존에 열려 있던 아코디언을 접는다. (1개의 아코디언만 열리게)
          for (var j = 0; j < faqQ.length; j++) {
            // 버튼 상태에 입혀진 active 라는 클래스를 지운다.
            faqQ[j].classList.remove("active");
            // 버튼 다음에 있는 div 콘텐츠 높이를 0으로 만든다. == 아코디언을 접는다.
            if (this !== faqQ[j]) {
              faqQ[j].nextElementSibling.style.maxHeight = null;
              faqQ[j].parentElement.classList.remove("on");
            }
          }

          this.classList.toggle("active");
          this.parentElement.classList.toggle("on");
          var faqA = this.nextElementSibling;
          if (faqA.style.maxHeight) {
            this.classList.remove("active");
            this.parentElement.classList.remove("on");
            faqA.style.maxHeight = null;
          } else {
            faqA.style.maxHeight = faqA.scrollHeight + "px";
          }
        };
      }
    },
    goHome(){
      this.$router.push("/");
    },
  }
};
</script>
