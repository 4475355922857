<template>
  <Fragment>
    <!--	s: m_section	-->
    <section class="m_section">
      <!--	s: 	contents-->
      <div class="contents">
        <MobileGnb />
        <div class="tabcont_wrap without_tab user_wrap">
          <h1 class="title with_desc">사용자 관리</h1>
          <div class="tabcontTop">
            <ul>
              <!-- 220705 디자인비 수정/p 추가 및 li 문구 변경 -->
              <li>
                직원 별로 사용자 아이디를 등록할 수 있습니다.<br />
                <span>※ 사용자 아이디 관리는 관리자 아이디만 가능합니다.</span>
              </li>
              <li>사용자 아이디로 문자메시지 전송, 발신번호 관리 등을 이용할 수 있습니다.</li>
              <li>사용자 아이디는 100개까지 등록할 수 있습니다.</li>
            </ul>
          </div>
          <form autocomplete="off" class="search_form">
            <div class="search_box user">
              <h3>사용자 등록</h3>
              <div class="group group01">
                <label for="right" class="label">아이디</label>
                <input type="text" class="input" v-on:blur="fnUserIdChk" maxlength="16" v-model="userId" placeholder="아이디를 입력하세요." />
              </div>
              <div class="group group02">
                <label for="right" class="label">이름</label>
                <input type="text" class="input" v-model="userNm" maxlength="100" placeholder="이름을 입력하세요." />
              </div>
              <div class="group group03">
                <label for="right" class="label">휴대폰번호</label>
                <input type="text" class="input" v-model="hpNo" maxlength="11" placeholder="휴대폰 번호를 ‘-’없이 숫자만 입력하세요." />
              </div>
              <div class="group group04">
                <label for="right" class="label">이메일</label>
                <input type="email" class="input" v-model="email" maxlength="100" placeholder="이메일 주소를 입력하세요." />
              </div>
              <button type="button" v-if="loginDiv" class="btn plus" @click="fnCreateUserConfirm">등록</button>
              <button type="button" v-else class="btn plus" @click="fnCantReg">등록</button>
            </div>
          </form>
          <div class="list_area">
            <div class="list_info">
              <div class="select_wrap">
                <select v-model="selected" @change="fnSelected" class="select">
                  <option value="30" selected>30</option>
                  <option value="50">50</option>
                  <option value="100">100</option>
                </select>
              </div>
              <p class="total">
                총 <span class="pink">{{ totCnt | formatPrice }}</span
                >건
              </p>
            </div>
            <div class="fixed-table-cover">
              <div class="fixed-table-container" style="width: 1074px;">
                <div class="fixed-table-header-bg"></div>
                <div class="fixed-table-wrapper">
                  <table class="fixed-table" style="table-layout: fixed">
                    <thead>
                      <tr>
                        <th class="td_chk" style="width: 40px">
                          <div class="th-text">
                            <input type="checkbox" id="listCheck_all" class="chk_all" @change="fnListChkAll" v-model="listAllChecked" /><label for="listCheck_all"></label>
                          </div>
                        </th >
                        <th class="td_id" style="width: 20%">
                          <div class="th-text">아이디</div>
                        </th >
                        <th class="td_name" style="width: 12%">
                          <div class="th-text">이름</div>
                        </th>
                        <th class="td_phone" style="width: 22%">
                          <div class="th-text">휴대폰번호</div>
                        </th>
                        <th class="td_email" style="width: 16%">
                          <div class="th-text">이메일 주소</div>
                        </th>
                        <th class="td_state" style="width: 13%">
                          <div class="th-text">사용 상태</div>
                        </th>
                        <th class="td_btn" style="width: calc(17% - 40px)">
                          <div class="th-text">아이디 관리</div>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-if="totCnt == 0" class="empty">
                        <td colspan="6">검색 결과가 없습니다.</td>
                      </tr>
                      <tr v-for="(data, index) in userList" :key="index" :class="{ on: data.targetChk }">
                        <td class="td_chk">
                          <input type="checkbox" class="chk" :id="'listCheck_' + index" v-model="data.targetChk" @change="fnChkBox" /><label :for="'listCheck_' + index"></label>
                        </td>
                        <td class="td_id">{{ data.userId }}</td>
                        <td class="td_name">{{ data.userNm }}</td>
                        <td class="td_phone">{{ data.hpNo }}</td>
                        <td class="td_email">{{ data.email }}</td>
                        <td class="td_state">{{ data.userSttusNm }}</td>
                        <td class="td_btn">
                          <button class="btn white edit" @click="ModalOpen('updateUserPop', data)"></button>
                          <button class="btn white del2" @click="fnDeleteUserConfirm(data)"></button>
                        </td>
                      </tr>
                      
                    </tbody>
                  </table>
                </div>
              </div>
            </div>

          <!--
            <div class="list_cover">
              <div class="list_th_wrap" @scroll="handleThScroll($event)">
                <ul class="list_th">
                  <li class="td_chk w40"><input type="checkbox" id="listCheck_all" class="chk_all" @change="fnListChkAll" v-model="listAllChecked" /><label for="listCheck_all"></label></li>
                  <li class="td_id w210"><span>아이디</span></li>
                  <li class="td_name w130"><span>이름</span></li>
                  <li class="td_phone w230"><span>휴대폰번호</span></li>
                  <li class="td_email w170"><span>이메일 주소</span></li>
                  <li class="td_state w140"><span>사용 상태</span></li>
                  <li class="td_btn w147"><span>아이디 관리</span></li>
                </ul>
              </div>
              <div class="list_wrap scr_wrap" @scroll="handleScroll($event)">
                <ul class="list">
                  <li v-for="(data, index) in userList" :key="index" :class="{ on: data.targetChk }">
                    <span class="td_chk w40">
                      <input type="checkbox" class="chk" :id="'listCheck_' + index" v-model="data.targetChk" @change="fnChkBox" /><label :for="'listCheck_' + index"></label>
                    </span>
                    <span class="td_id w210">{{ data.userId }}</span>
                    <span class="td_name w130">{{ data.userNm }}</span>
                    <span class="td_phone w230">{{ data.hpNo }}</span>
                    <span class="td_email w170">{{ data.email }}</span>
                    <span class="td_state w140">{{ data.userSttusNm }}</span>
                    <span class="td_btn w147">
                      <button class="btn white edit" @click="ModalOpen('updateUserPop', data)"></button>
                      <button class="btn white del2" @click="fnDeleteUserConfirm(data)"></button>
                    </span>
                  </li>-->
                  <!--
                    <li class="empty">
                      <span class="w100p">데이터가 없습니다.</span>
                    </li> 
                </ul>
              </div>
            </div>
          -->

            <div v-if="checkBox" class="list_bottom">
              <button class="btn white small" type="button" @click="fnDeleteUserChkConfirm">선택 아이디 삭제</button>
            </div>
            <div class="paging_wrap" v-show="totCnt > listSize">
              <!-- 페이징 컴포넌트 -->
              <PageLayer ref="updatePaging" @fnClick="fnSearch" :listTotalCnt="totCnt" :selected="listSize" :pageNum="pageNo"></PageLayer>
            </div>
          </div>
        </div>
      </div>
      <!--	e: 	contents	-->
    </section>
    <!--	e: m_section	-->
    <!--  s: popup  -->
    <!-- <div class="dimmed" @click="ModalClose()"></div> -->
    <div class="popup-wrap">
      <UpdateUserId @fnselectUserList="fnselectUserList" @fnPhoneChk="fnPhoneChk" @fnemailChk="fnemailChk" :userInfo="userInfo" />
    </div>
    <!--  e: popup  -->
  </Fragment>
</template>
<script>
import { Fragment } from "vue-frag";
import store from "@/store";
import userManageApi from "@/modules/userManage/service/userManageApi"; //api호출
import confirm from "@/modules/commonUtil/service/confirm";
import PageLayer from "@/components/PageLayer.vue"; //페이징
import { eventBus } from "@/modules/commonUtil/service/eventBus";
import UpdateUserId from "../components/UpdateUserId.vue";
import MobileGnb from "@/components/MobileGnb.vue";

export default {
  name: "userManage",
  components: { PageLayer, UpdateUserId, Fragment, MobileGnb },
  data() {
    return {
      loginDiv: false,
      //페이징
      selected: "30",
      listSize: 30, // select 박스 value (출력 갯수 이벤트)
      pageNo: 1, // 현재 페이징 위치
      totCnt: 0, //전체 리스트 수
      offset: 0, //페이지 시작점
      userId: "",
      userNm: "",
      hpNo: "",
      email: "",
      userSttusNm: "",
      checked: false,

      userInfo: {
        userId: "",
        userNm: "",
        hpNo: "",
        email: "",
        userSttusNm: "",
      },

      info: "",
      data: "",

      userList: [], //userList 목록
      listAllChecked: false,
      delUserInfo: [],
      checkBox: false,
    };
  },
  created() {
    this.fnInit();

  },
  watch: {
    hpNo(val) {
      const reg = /[ㄱ-ㅎ|ㅏ-ㅣ|가-힣|a-zA-Z|~/',.;@#$%^&*()_+|<>?:{}]/;
      // 한글, 영문 체크
      if (reg.exec(val) !== null) this.hpNo = val.replace(/[^0-9]/g, "");
      // .... 만 입력하게 될 경우 체크
      if (isNaN(parseFloat(val))) this.hpNo = "";
    },
  },
  mounted() {},
  methods: {
    fnInit() {
      const isAuth = store.getters["login/isAuth"];
      if (isAuth) {
        this.loginDiv = true;

        userManageApi
          .selectAutInfo()
          .then((response) => {
            var result = response.data;
            if (result.success) {
              this.autCd = result.data.autCd;
            } else {
              confirm.fnAlert("", "오류가 발생하였습니다.", "alert");
            }
          })
          .catch((error) => {
            //console.log(error.response.status);
            if (error.response != undefined && error.response != null) {
              if (error.response.status == 403) {
                eventBus.$on("callbackEventBus", () => {
                  this.$router.go(-1);
                });
                confirm.fnConfirm("", "관리자ID만 접근 가능합니다.", "cbAlert");
              } else {
                confirm.fnAlert("", "오류가 발생하였습니다.", "alert");
              }
            } else {
              confirm.fnAlert("", "오류가 발생하였습니다.", "alert");
            }
          });

        this.fnselectUserList();
      }
    },
    fnUserInfoinit() {
      (this.userId = ""), (this.userNm = ""), (this.hpNo = ""), (this.email = "");
    },

    bindNumber(event) {
      this.hpNo = event.target.value;
    },

    fnChkBox() {
      let checkDiv = 0;
      this.userList.forEach(function (userInfo) {
        if (userInfo.targetChk) checkDiv++;
      });
      if (checkDiv > 0) {
        this.checkBox = true;
      } else {
        this.checkBox = false;
      }
    },
    //사용자 목록 조회
    async fnselectUserList() {
      if (this.loginDiv) {
        let param = {
          pageNo: this.pageNo, //페이징 필수 값
          listSize: this.listSize,
        };
        await userManageApi.selectUserList(param).then((response) => {
          var result = response.data;
          if (result.success) {
            this.userList = result.data.userList;
            this.totCnt = result.data.pageInfo.totCnt; //전체 카운트
            this.offset = result.data.pageInfo.offset; //페이지 번호
          }
        });
      }
    },
    fnSelected() {
      //리스트 갯수 선택
      this.listSize = Number(this.selected);
      this.$refs.updatePaging.fnAllDecrease();
    },

    fnSearch(pageNum) {
      //검색
      this.pageNo = this.$gfnCommonUtils.defaultIfEmpty(pageNum, "1") * 1;
      this.fnselectUserList();
    },

    //SM,FM,PM+숫자6자리 방지
    fnUserIdChk() {
      let pattern = /\s/g;
      let reg = /([0-9|A-Z|a-z])$/;
      let regEmail = /^([\w-]+(?:\.[\w-]+)*)@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$/i;
      let regExp = /^([(SM|FM|PM)]+[0-9]{6})$/;
      let upperId = this.userId.toUpperCase();

      if (this.userId.match(pattern)) {
        confirm.fnAlert("", "아이디에 공백이 있습니다.", "alert");
        return false;
      }

      if (!reg.test(this.userId)) {
        confirm.fnAlert("", "아이디를 확인해 주세요.", "alert");
        return false;
      } else if (regExp.test(upperId)) {
        confirm.fnAlert("", "아이디를 확인해 주세요.<br />아래 형식의 아이디는 사용할 수 없습니다.<br />SM+숫자6자리<br />PM+숫자6자리<br />FM+숫자6자리", "alert");
        return false;
      } else if (this.userId.length < 6) {
        confirm.fnAlert("", "입력한 아이디의 길이가 6byte보다 작습니다.", "alert");
        return false;
      } else if (this.userId.length > 16) {
        confirm.fnAlert("", "입력한 아이디의 길이가 16byte를 초과했습니다.", "alert");
        return false;
      } else if (regEmail.test(this.userId)) {
        confirm.fnAlert("", "아이디를 확인해 주세요.", "alert");
        return false;
      }
      return true;
    },

    //ID 중복 체크
    fnDupChk() {
      let param = {
        userId: this.userId,
      };
      userManageApi.checkDuplUser(param).then((response) => {
        var result = response.data;
        if (!result.success) {
          confirm.fnAlert("", "중복된 아이디가 있습니다.<br />다른 아이디로 등록해주세요.", "alert");
          return false;
        }
      });
    },

    //ID유효성 체크. 입력 후 다른항목 넘어가는 시점에 체크
    fnIdChk() {
      this.fnDupChk();
      if (!this.fnUserIdChk()) {
        return false;
      }
      return true;
    },

    //이메일 유효성 체크
    fnemailChk() {
      let regExp = /^([\w-]+(?:\.[\w-]+)*)@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$/i;
      if (!regExp.test(this.email)) {
        confirm.fnAlert("", "이메일 주소 형식이 잘못되었습니다.<br>확인 후 다시 입력해 주세요.", "alert");
        return false;
      } else if (this.email.length > 100) {
        confirm.fnAlert("", "이메일 길이를 확인해 주세요.", "alert");
        return false;
      } else {
        return true;
      }
    },

    //휴대폰 번호 체크
    fnPhoneChk() {
      let regExp = /([0-9])$/;
      if (!regExp.test(this.hpNo)) {
        confirm.fnAlert("", "휴대폰번호를 확인해 주세요.", "alert");
        return false;
      }
      if (this.hpNo.length != 11) {
        confirm.fnAlert("", "휴대폰번호를 확인해 주세요.", "alert");
        return false;
      }
      return true;
    },

    fnNameChk() {
      if (this.name.length > 100) {
        // confirm.fnAlert("이름 확인", "이름의 길이를 확인해 주세요.", "alert", "!");
        confirm.fnAlert("", "이름의 길이를 확인해 주세요.", "alert");
        return false;
      } else {
        return true;
      }
    },

    //사용자 갯수 유효성 체크. 100개 이상 X
    fnListCntChk() {
      if (this.totCnt >= 100) {
        // confirm.fnAlert("사용자 등록 불가능", "사용자 아이디는 100개까지 등록할 수 있습니다.", "alert", "!");
        confirm.fnAlert("", "사용자 아이디는 100개까지 등록할 수 있습니다.", "alert");
        this.fnUserInfoinit();
        return false;
      } else {
        return true;
      }
    },
    //
    fnCreateUserConfirm() {
      //유효성 체크
      if (!this.fnIdChk()) return false;
      if (!this.fnListCntChk()) return false;
      if (!this.fnPhoneChk()) return false;
      if (!this.fnemailChk()) return false;


      eventBus.$on("callbackEventBus", () => {
        this.fnCreateUser();
      });
      confirm.fnConfirm("", "사용자id가 대기 상태로 등록되었습니다.<br />서비스 로그인을 위해 입력하신 이메일로 임시비밀번호를 전송하시겠습니까?", "email");
      // confirm.fnConfirm("", "해당 사용자의 이메일로 임시 비밀번호를 전송합니다.<br />입력하신 주소로 임시 비밀번호 안내 이메일을 보내시겠어요?", "email");
    },
    //사용자ID 등록
    fnCreateUser() {
      let param = {
        regId: this.regId,
        custSeq: this.custSeq,
        prntsUserSeq: this.prntsUserSeq,
        userId: this.userId,
        userNm: this.userNm,
        hpNo: this.hpNo,
        email: this.email,
        lineTpCd: this.lineTpCd,
      };

      userManageApi.insertUserInfo(param).then((response) => {
        var result = response.data;
        if (result.success) {
          //입력창 초기화
          confirm.fnAlert("", "사용자가 등록되었습니다.", "alert");
          this.fnUserInfoinit();
          this.fnselectUserList();
        } else {
          confirm.fnAlert("", "오류가 발생하였습니다.", "alert");
        }
      });
    },

    fnListChkAll() {
      if (this.listAllChecked) {
        this.userList.forEach(function (userInfo) {
          userInfo.targetChk = true;
        });
        this.checkBox = true;
      } else {
        this.userList.forEach(function (userInfo) {
          userInfo.targetChk = false;
        });
        this.checkBox = false;
      }
    },

    //사용자ID 삭제 확인(개별)
    fnDeleteUserConfirm(userInfo) {
      var delUserInfo = [];
      var delInfo = {
        userSeq: userInfo.userSeq,
      };
      delUserInfo.push(delInfo);
      eventBus.$on("callbackEventBus", () => {
        this.fnDeleterUser(delUserInfo);
      });
      confirm.fnConfirm("", "선택한 사용자 아이디</ br>를 삭제하시겠어요?", "del");
    },

    //사용자ID 체크박스 삭제
    fnDeleteUserChkConfirm() {
      var delUserInfo = [];
      let checkDiv = 0;

      this.userList.forEach(function (userInfo) {
        if (userInfo.targetChk) checkDiv++;
      });
      if (checkDiv == 0) {
        confirm.fnAlert("", "삭제할 아이디를 선택해 주세요.", "alert");
        return false;
      }

      this.userList.forEach(function (userInfo) {
        if (userInfo.targetChk) {
          var delInfo = {
            userSeq: userInfo.userSeq,
          };

          delUserInfo.push(delInfo);
        }
      });
      eventBus.$on("callbackEventBus", () => {
        this.fnDeleterUser(delUserInfo);
      });
      confirm.fnConfirm("", "선택한 사용자를 삭제하시겠습니까?<br />확인을 누르시면 선택하신 사용자가 삭제됩니다.", "confirm");
    },

    //사용자 ID 삭제
    fnDeleterUser(deleteUserId) {
      let param = {
        deleteUserId: deleteUserId,
      };

      userManageApi.deleteUserId(param).then((response) => {
        var result = response.data;

        if (result.success) {
          eventBus.$on("callbackEventBus", this.fnSearch);
          confirm.fnConfirm("", "사용자ID가 삭제되었습니다.", "cbAlert");
        } else {
          confirm.fnAlert("", "오류가 발생하였습니다.", "alert");
        }
      });
    },

    //모달 창
    ModalOpen(target, data) {
      var dimmed = document.querySelector(".dimmed");
      var wrap = document.querySelector(".popup-wrap");
      var obj = document.getElementsByClassName(target);
      this.setUserInfo(data);
      obj[0].classList.add("open");
      dimmed.style.display = "block";
      wrap.style.display = "block";
      obj[0].style.display = "block";
      this.ModalHeight();
    },
    ModalClose() {
      var dimmed = document.getElementsByClassName("dimmed");
      var wrap = document.getElementsByClassName("popup-wrap");
      var obj = wrap[0].childElementCount;
      dimmed[0].style.display = "none";
      wrap[0].style.display = "none";
      for (var i = 0; i < obj; i++) {
        var target = document.getElementsByClassName("popup");
        target[i].style.display = "none";
        target[i].classList.remove("open");
      }
    },
    ModalHeight() {
      //팝업 heght가 device height보다 클때
      var winH = window.innerHeight,
        obj = document.querySelector(".popup.open"),
        objH = obj.clientHeight;
      if (objH >= winH) {
        obj.classList.add("over");
      }
    },

    //수정 모달에 넘길 데이터 담기
    setUserInfo(data) {
      this.userInfo.userId = data.userId;
      this.userInfo.userNm = data.userNm;
      this.userInfo.hpNo = data.hpNo;
      this.userInfo.email = data.email;

      //상태 값 boolean으로 넘김
      this.userInfo.userSttusNm = data.userSttusNm;
      if (data.userSttusNm == "정상") {
        this.userInfo.userSttusNm = "01";
      } else if (data.userSttusNm == "사용중지") {
        this.userInfo.userSttusNm = "02";
      }
    },
    fnCantReg() {
      confirm.fnAlert("", "로그인 후 이용할 수 있습니다.", "alert");
      return;
    },
    handleScroll(e) {
      const chooseListThWrap = e.target.parentNode.children[0];
      chooseListThWrap.scrollLeft = e.target.scrollLeft;
    },
    handleThScroll(e) {
      const chooseListWrap = e.target.parentNode.children[1];
      chooseListWrap.scrollLeft = e.target.scrollLeft;
    },
  },
};
</script>
