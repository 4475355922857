<template>
  <div class="login_wrap">
    <div class="login_box">
      <div class="login_box-logo">
        <a href="javascript:void(0)">uplus 메시지허브이지</a>
        <p>Easy Messaging Service</p>
      </div>
      <div class="login_box-content">
        <form @submit.prevent="" autocomplete="off">
          <h2 class="title with_desc">비밀번호 변경</h2>
          <p class="desc">개인 정보를 안전하게 보호하기 위해 비밀번호를 변경해 주세요.</p>
          <label for="smo" class="label">현재 비밀번호</label>
          <!-- <input type="password" v-model="orgPwd" placeholder="영문, 숫자, 특수기호를 포함한 8~16자리 현재 비밀번호를 입력하세요." class="mb12"> -->
          <input type="password" ref="orgPsd" v-model="orgPwd" placeholder="현재 비밀번호를 입력하세요." class="mb12" style="margin-bottom: 20px" />

          <label for="smo" class="label">새 비밀번호</label>
          <!-- <input type="password"  v-model="newPwd" placeholder="영문, 숫자, 특수기호를 포함한 8~16자리 새 비밀번호를 입력하세요." class=""> -->
          <input type="password" ref="newPsd" v-model="newPwd" placeholder="새 비밀번호를 입력하세요." class="" />
          <p>※ 영문/숫자/특수기호 혼합하여 8~16자리</p>

          <label for="smo" class="label mg12t">새 비밀번호 확인</label>
          <input type="password" ref="newPsdCe" v-model="confirmPwd" placeholder="새 비밀번호를 한 번 더 입력하세요." class="mb20" />
          <button type="button" @click="fnChangePwdExec" class="btn large_pink">변경</button>
        </form>
      </div>
    </div>
  </div>
</template>
<script>
import confirm from "@/modules/commonUtil/service/confirm";
import { eventBus } from "@/modules/commonUtil/service/eventBus";
import loginApi from "../service/loginApi";

export default {
  name: "passWdReset",
  data() {
    return {
      orgPwd: "",
      newPwd: "",
      confirmPwd: "",
    };
  },
  methods: {
    fnChangePwdExec() {
      if (this.orgPwd === "") {
        eventBus.$on("callbackEventBus", () => {
          this.$refs.orgPsd.focus();
        });
        confirm.fnConfirm("", "현재 비밀번호를 입력해 주세요.", "cbAlert");
        return false;
      }

      if (this.newPwd === "") {
        eventBus.$on("callbackEventBus", () => {
          this.$refs.newPsd.focus();
        });
        confirm.fnConfirm("", "새 비밀번호를 입력해 주세요.", "cbAlert");
        return false;
      }

      if (this.confirmPwd === "") {
        eventBus.$on("callbackEventBus", () => {
          this.$refs.newPsdCe.focus();
        });
        confirm.fnConfirm("", "새 비밀번호 확인을 입력해 주세요.", "cbAlert");
        return false;
      }

      if (this.orgPwd === this.newPwd) {
        eventBus.$on("callbackEventBus", () => {
          this.$refs.newPsd.focus();
        });
        confirm.fnConfirm("", "새 비밀번호가 현재 비밀번호와 같습니다.", "cbAlert");
        return false;
      }

      let passwordRule = /^(?=.*[a-zA-Z])(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{8,16}$/;

      if (!passwordRule.test(this.newPwd)) {
        eventBus.$on("callbackEventBus", () => {
          this.$refs.newPsd.focus();
        });
        confirm.fnConfirm("", "비밀번호는 영문, 숫자, 특수기호를 포함한<br/> 8자~16자리로 입력해 주세요.", "cbAlert");
        return false;
      }

      if (this.newPwd != this.confirmPwd) {
        eventBus.$on("callbackEventBus", () => {
          this.$refs.newPsdCe.focus();
        });
        confirm.fnConfirm("", "새 비밀번호와 비밀번호 확인이 일치하지 않습니다.", "cbAlert");
        return false;
      }

      var param = {
        orgPwd: this.orgPwd,
        newPwd: this.newPwd,
        confirmPwd: this.confirmPwd,
      };
      loginApi
        .changePwdExec(param)
        .then((response) => {
          var result = response.data;
          if (result.success) {
            eventBus.$on("callbackEventBus", this.fnLogout);
            confirm.fnConfirm("", "비밀번호를 변경했습니다. <br /> 새 비밀번호로 다시 로그인해 주세요.", "cbAlert");
          } else {
            if (result.code == "CE_WRONG_PWD") {
              eventBus.$on("callbackEventBus", () => {
                this.$refs.orgPsd.focus();
              });
              confirm.fnAlert("", "비밀번호를 정확하게 입력해 주세요.", "alert");
            } else if (result.code == "CE_USED_PWD") {
              eventBus.$on("callbackEventBus", () => {
                this.$refs.newPsdCe.focus();
              });
              confirm.fnConfirm("", "새 비밀번호가 현재 비밀번호와 같습니다.", "cbAlert");
            } else if (result.code == "CE_PTN_PWD") {
              eventBus.$on("callbackEventBus", () => {
                this.$refs.newPsdCe.focus();
              });
              confirm.fnConfirm("", "비밀번호는 영문, 숫자, 특수기호를 포함한<br/>8자~16자 이어야 합니다.", "cbAlert");
            } else if (result.code == "CE_PRV_USED_PWD") {
              eventBus.$on("callbackEventBus", () => {
                this.$refs.newPsdCe.focus();
              });
              confirm.fnConfirm("", "이전에 사용했던 비밀번호는 설정할 수 없습니다.", "cbAlert");
            } else {
              confirm.fnAlert("", "오류가 발생하였습니다.", "alert");
            }
          }
        })
        .catch((error) => {
          confirm.fnAlert("", "오류가 발생하였습니다.", "alert");
          console.log(error);
        });
    },
    fnLogout() {
      this.$store.commit("login/isAuth", false);
      this.$store.commit("hubEzData", []);
      loginApi.logout({}).then(() => {
        /* var result = response.data;
        console.log(result); */
        this.$router.push({ path: "/login" });
      });
    },
  },
};
</script>

<style scoped>
.content {
  width: 100%;
  height: 80vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
</style>
