import httpClient from "@/common/http-client";

// 알림톡 템플릿 리스트 조회
const selectAlimTalkTmpltList = (params) => {
  return httpClient.post("/api/template/selectAlimTalkTmpltList", params, { headers: { "show-layer": "yse", activity: "READ" } });
};

// 알림톡 템플릿 정보 조회
const selectAlimTalkInfo = (params) => {
  return httpClient.post("/api/template/selectAlimTalkInfo", params, { headers: { "show-layer": "yse", activity: "READ" } });
};

//알림톡 템플릿 수정
const procUpdateRequestKkoTmplt = (params) => {
  return httpClient.post("/api/template/procUpdateRequestKkoTmplt", params, { headers: { "show-layer": "yse", activity: "SAVE" } });
};

//알림톡 템플릿 삭제
const procDeleteRequestKkoTmplt = (params) => {
  return httpClient.post("/api/template/procDeleteRequestKkoTmplt", params, { headers: { "show-layer": "yse", activity: "READ" } });
};

// 알림톡 검수 요청
const procInspectRequestKkoTmplt = (params) => {
  return httpClient.post("/api/template/procInspectRequestKkoTmplt", params, { headers: { "show-layer": "yse", activity: "READ" } });
};

// 알림톡 등록 요청
const procApprvRequestKkoTmplt = (params) => {
  return httpClient.post("/api/template/procApprvRequestKkoTmplt", params, { headers: { "show-layer": "yse", activity: "SAVE" } });
};

// 채널 ID 조회
const selectChannelId = (params) => {
  return httpClient.post("/api/channel/selectChannelId", params, { headers: { "show-layer": "yse", activity: "READ" } });
};

// 사업자 카테고리 그룹 가져오기
const getKkoCategory = (params) => {
  return httpClient.post("/api/channel/getKkoCategory", params, { headers: { "show-layer": "yse", activity: "READ" } });
};

// 카카오톡 카테고리 그룹 가져오기
const selectAlimtalkTemplateCtg = (params) => {
  return httpClient.post("/api/template/selectAlimtalkTemplateCtg", params, { headers: { "show-layer": "yse", activity: "READ" } });
};

export default {
  selectAlimTalkTmpltList,
  selectAlimTalkInfo,
  selectChannelId,
  getKkoCategory,
  procDeleteRequestKkoTmplt,
  procInspectRequestKkoTmplt,
  procApprvRequestKkoTmplt,
  selectAlimtalkTemplateCtg,
  procUpdateRequestKkoTmplt,
};
