import sms from "../views/sms";
import alimtalk from "../views/alimtalk";

export default [
  {
    path: "/messagesend/sms",
    name: "sms",
    component: sms,
    meta: { public: true },
  },
  {
    path: "/messagesend/alimtalk",
    name: "alimtalk",
    component: alimtalk,
    meta: { public: true },
  },
];
