<template>
  <!--	s: m_section	-->
  <section class="m_section p_including">
    <!-- class="p_including" : preview 포함 -->
    <!--	s: 	contents-->
    <div class="contents">
      <MobileGnb />
      <div class="tabcont_wrap without_tab template_detail">
        <form @submit.prevent="" autocomplete="off">
          <div class="tabcont on info_modify">
            <h1 class="title">
              알림톡 템플릿 <span class="state complete" v-if="alimTalkInfo.tmpltSttusCd == 'A'">승인</span>
              <!-- class="rejected" 반려  class="check" 검수-->
            </h1>
            <div class="payment">
              <ul class="info_list">
                <li class="fl">
                  <h3>등록일</h3>
                  <p>{{ alimTalkInfo.tmpltCretday }}</p>
                </li>
                <li class="fl">
                  <h3>승인일</h3>
                  <p v-if="alimTalkInfo.tmpltSttusCd === 'A'">{{ alimTalkInfo.chgDt }}</p>
                  <p v-else></p>
                </li>
                <li class="w100p">
                  <h3>상태</h3>
                  <p v-if="alimTalkInfo.tmpltSttusCd === 'T'">신청완료</p>
                  <p v-if="alimTalkInfo.tmpltSttusCd === 'Q'">검수중</p>
                  <p v-if="alimTalkInfo.tmpltSttusCd === 'A'">승인</p>
                  <p v-if="alimTalkInfo.tmpltSttusCd === 'S'">반려</p>
                  <p v-if="alimTalkInfo.tmpltSttusCd === 'R'">검수요청완료</p>
                </li>
                <li class="fl">
                  <h3>채널 아이디</h3>
                  <p class="input_name">{{ alimTalkInfo.chnlId }}</p>
                </li>
                <li class="cr w100p">
                  <h3>템플릿명</h3>
                  <p>{{ alimTalkInfo.tmpltNm }}</p>
                </li>
                <li class="w100p">
                  <h3>카테고리</h3>
                  <p>{{ alimTalkInfo.ctgGrpNm }} / {{ alimTalkInfo.ctgNm }}</p>
                </li>
                <li class="w100p">
                  <h3>보안 적용 여부</h3>
                  <p>{{ alimTalkInfo.securTmpltYn }}</p>
                </li>
                <li class="w100p">
                  <h3>강조유형</h3>
                  <p>{{ alimTalkInfo.emphsTpCdNm }}</p>
                </li>
                <li class="w100p" v-if="alimTalkInfo.emphsTpCd !== 'NONE'">
                  <h3>강조표기 타이틀</h3>
                  <p class="em_tit">{{ alimTalkInfo.emphsTitle }}</p>
                </li>
                <li class="w100p" v-if="alimTalkInfo.emphsTpCd !== 'NONE'">
                  <h3>강조표기 보조문구</h3>
                  <p class="em_sub">{{ alimTalkInfo.emphsSubtle }}</p>
                </li>
                <li class="w100p">
                  <h3>템플릿 유형</h3>
                  <p>{{ alimTalkInfo.tmpltTpCdNm }}</p>
                </li>
                <li class="w100p">
                  <h3>내용</h3>
                  <p class="input_cont" v-html="previewMsg"></p>
                </li>
                <li class="w100p" v-if="alimTalkInfo.tmpltTpCd === 'EX' || alimTalkInfo.tmpltTpCd === 'MI'">
                  <h3>부가정보</h3>
                  <p class="input_cont" v-html="previewEtc"></p>
                </li>
                <li class="w100p" v-if="alimTalkInfo.tmpltTpCd === 'AD' || alimTalkInfo.tmpltTpCd === 'MI'">
                  <h3>광고성 메시지</h3>
                  <p class="input_cont" v-html="previewAd"></p>
                </li>
              </ul>
            </div>
            <!-- 버튼 -->

            <div class="box_wrap btntype_wrap" v-if="alimTalkInfo.buttonList">
              <div class="box_top">
                <h3 class="tit">버튼</h3>
              </div>
              <div class="btnlist_wrap">
                <table class="btnlist_table" style="width: 731px">
                  <colgroup>
                    <col width="25%" />
                    <col width="20%" />
                    <col width="55%" />
                  </colgroup>
                  <thead>
                    <tr>
                      <th class="td_type">타입</th>
                      <th class="td_name">버튼이름</th>
                      <th class="td_link">링크 주소(URL)</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(button, index) in alimTalkInfo.buttonList" :key="index">
                      <td class="td_type">
                        <p>{{ button.linkTypeName }}</p>
                      </td>

                      <td class="td_name" v-if="button.linkType === 'WL' || button.linkType === 'AL' || button.linkType === 'DS'">
                        <div class="btn_pre">
                          {{ button.name ? button.name : button.linkTypeName }}
                        </div>
                      </td>

                      <td colspan="2" class="td_name" v-else>
                        <div class="btn_pre disabled">
                          {{ button.name ? button.name : button.linkTypeName }}
                        </div>
                      </td>

                      <td class="td_link" v-if="button.linkType === 'DS'"><p>송장 번호</p></td>

                      <td class="td_link" v-if="button.linkType === 'WL'">
                        <div>
                          <label>모바일: {{ button.linkMo }}</label>
                        </div>
                        <div>
                          <label>PC: {{ button.linkPc }}</label>
                        </div>
                      </td>

                      <td class="td_link" v-if="button.linkType === 'AL'">
                        <div>
                          <label>안드로이드: <input type="text" v-model="button.linkAnd" disabled /></label>
                        </div>
                        <div>
                          <label>PC: <input type="text" v-model="button.linkIos" disabled /></label>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </form>
        <div class="btn_wrap">
          <button class="btn white" @click="fnGoListConfirm">목록</button>
          <!-- <button class="btn white" @click="fnDelTmp" :disabled="alimTalkInfo.tmpltSttusCd != 'T'">삭제</button> -->
        </div>

        <!--	s: m_preview	-->
        <div class="m_preview_wrap">
          <button class="btn_close" onClick="mPreClose();"></button>
          <div class="m_preview talk_preview">
            <div class="preview_toggle">
              <input type="radio" name="preview_type_toggle" id="btn_sent_toggle" @click="mPreEvent(true)" checked />
              <label for="btn_sent_toggle">미리보기</label>
              <input type="radio" name="preview_type_toggle" id="btn_history_toggle" @click="mPreEvent(false)" />
              <label for="btn_history_toggle">최근 작성메시지</label>
            </div>
            <div class="preview_top" v-show="preEventToggle">
              <span>{{ alimTalkInfo.chnlId }}</span>
              <!-- <input type="radio" name="preview_type" id="btn_history" @click="mPreEvent(false)" /><label for="btn_history"><span>최근작성 메시지</span></label> -->
            </div>
            <!--	s: 알림톡 미리보기	 -->
            <div class="preview_cont" :style="preEventToggle ? 'background:#bdd6eb' : 'background: #fff'" v-show="preEventToggle">
              <div class="talk_channel">
                <div class="img"><img src="../../../assets/images/preview_icon.png" alt="" /></div>
                <p class="channel_name">{{ alimTalkInfo.chnlId }}</p>
              </div>
              <div class="talk_wrap">
                <div class="talk_top">
                  <span>알림톡 도착</span>
                </div>
                <div class="talk_cont">
                  <div class="talk_tit" v-if="alimTalkInfo.emphsTpCd === 'TEXT'">
                    <p class="t_sub" :style="alimTalkInfo.emphsSubtle ? 'color: rgb(18, 16, 6);' : ''">{{ alimTalkInfo.emphsSubtle ? alimTalkInfo.emphsSubtle : "강조표기 보조문구 미리보기" }}</p>
                    <p class="t_tit" :style="alimTalkInfo.emphsTitle ? 'color: rgb(18, 16, 6);' : ''">{{ alimTalkInfo.emphsTitle ? alimTalkInfo.emphsTitle : "제목 미리보기" }}</p>
                  </div>
                  <div class="talk_txt">
                    <p class="cont" :style="previewMsg ? 'color: rgb(18, 16, 6);' : ''" v-html="previewMsg ? previewMsg : '내용 미리보기'"></p>
                    <p
                      class="sub_info"
                      v-if="alimTalkInfo.tmpltTpCd === 'EX' || alimTalkInfo.tmpltTpCd === 'MI'"
                      :style="alimTalkInfo.etcInfo ? 'color: rgb(18, 16, 6);' : ''"
                      v-html="previewEtc ? previewEtc : '부가정보를 입력하세요'"
                    ></p>
                    <p
                      class="sub_info"
                      v-if="alimTalkInfo.tmpltTpCd === 'AD' || alimTalkInfo.tmpltTpCd === 'MI'"
                      :style="adMsg ? 'color: rgb(18, 16, 6);' : ''"
                      v-html="previewAd ? previewAd : '채널 추가하고 이 채널의 마케팅 메시지 등을 카카오톡으로 받기'"
                    ></p>
                  </div>
                  <div class="talk_btn" v-for="(button, index) in alimTalkInfo.buttonList" :key="index">{{ button.name ? button.name : button.linkTypeName }}</div>
                </div>
              </div>
            </div>
            <!--	e: 알림톡 미리보기	 -->

            <!--	s: 최근전송내역	-->
            <RecentAlimTalkListVue :preEventToggle="preEventToggle" @recentSendAlimList="fnRecentSendAlimList" :recentSendAlimList="recentSendAlimList" :modiAuth="false" />
            <!-- 	e: 최근전송내역 	-->
          </div>
        </div>
        <!--	s: m_preview	-->
      </div>
    </div>
    <!--	e: 	contents	-->
    <!--  s: m_btn_wrap -모바일버전에서 버튼  -->
    <div class="m_btn_wrap">
      <button type="button" class="m_preview_btn" @click="mPreAlimOn()">미리보기</button>
      <button type="button" class="m_aside_btn" @click="mHisAlimOn()">최근<br />작성메시지</button>
    </div>
    <!--  e: m_btn_wrap -모바일버전에서 버튼  -->
    <!-- s: 모바일 미리보기 -->
    <MobilePreviewAlimVue @recentSendAlimList="fnRecentSendAlimList" :alimTalkInfo="alimTalkInfo" :recentSendAlimList="recentSendAlimList" :modiAuth="false" :adMsg="adMsg"/>
    <!-- e: 모바일 미리보기 -->
  </section>
  <!--	e: m_section	-->
</template>
<script>
import templeteManageApi from "../service/templeteManageApi";
import RecentAlimTalkListVue from "@/modules/messageSend/components/RecentAlimTalkList.vue";
import confirm from "@/modules/commonUtil/service/confirm";
import { eventBus } from "@/modules/commonUtil/service/eventBus";
import MobileGnb from "@/components/MobileGnb.vue";
import messageSendApi from "@/modules/messageSend/service/messageSendApi";
import MobilePreviewAlimVue from "../components/MobilePreviewAlim.vue";

export default {
  name: "templeteDetail",
  components: { RecentAlimTalkListVue, MobileGnb, MobilePreviewAlimVue },
  data() {
    return {
      preEventToggle: true,
      alimTalkInfo: {
        tmpltSbst: "",
      },
      adMsg: "채널 추가하고 이 채널의 마케팅 메시지 등을 카카오톡으로 받기",
      buttonList: [],
      recentSendAlimList: [],
      buttonLinkTypeList: [
        { linkType: "DS", linkTypeName: "배송 조회", name: "버튼명 입력" },
        { linkType: "WL", linkTypeName: "웹 링크", name: "버튼명 입력", linkMo: "", linkPc: "" },
        { linkType: "AL", linkTypeName: "앱 링크", name: "버튼명 입력", linkAnd: "", linkIos: "" },
        { linkType: "BK", linkTypeName: "봇 키워드", name: "버튼명 입력" },
        { linkType: "MD", linkTypeName: "메시지 전달", name: "버튼명 입력" },
        { linkType: "BC", linkTypeName: "상담톡 전환", name: "버튼명 입력" },
        { linkType: "BT", linkTypeName: "봇 전환", name: "버튼명 입력" },
      ],
      // 미리보기 화면 swiper
      swiperOption2: {
        slidesOffsetBefore: 0, // slidesOffsetBefore는 첫번째 슬라이드의 시작점에 대한 변경할 때 사용
        slidesOffsetAfter: 0, // slidesOffsetAfter는 마지막 슬라이드 시작점 + 마지막 슬라이드 너비에 해당하는 위치의 변경이 필요할 때 사용
        //freeMode: true, // freeMode를 사용시 스크롤하는 느낌으로 구현 가능
        centerInsufficientSlides: true, // 컨텐츠의 수량에 따라 중앙정렬 여부를 결정함
        loop: false,
        direction: "vertical",
        slidesPerView: 3,
        spaceBetween: 12,
        observer: true,
        observeParents: true,
        // Navigation arrows
        navigation: {
          nextEl: ".swiper-button-prev",
          prevEl: ".swiper-button-next",
        },
      },
    };
  },
  computed: {
    previewMsg() {
      return this.alimTalkInfo.tmpltSbst ? this.alimTalkInfo.tmpltSbst.split(" ").join("&nbsp;").split("\n").join("<br/>") : "";
    },
    previewEtc() {
      return this.alimTalkInfo.etcInfo ? this.alimTalkInfo.etcInfo.split(" ").join("&nbsp;").split("\n").join("<br/>") : "";
    },
    previewAd() {
      return this.adMsg ? this.adMsg.split(" ").join("&nbsp;").split("\n").join("<br/>") : "";
    },
  },
  created() {
    this.fnSelectAlimTalkInfo();
    this.fnSelectrecentSendAlimList();
  },
  methods: {
    //상세 가져오기
    fnSelectAlimTalkInfo() {
      //const pattern = /(?<=#{)(.*?)(?=\})/g; // 특정 문자 사이 추출 (특정문자 제외)
      //const pattern2 = /((#{).*?(\}))/g; // 특정 문자 사이 추출 (특정문자 포함)

      const params = {
        tmpltKey: this.$router.history.current.params.tmpltKey,
        sndrprofKey: this.$router.history.current.query.sndrprofKey,
        userSeq: this.$router.history.current.query.userSeq,
      };

      templeteManageApi
        .selectAlimTalkInfo(params)
        .then((response) => {
          if (response.data.success) {
            /* if (response.data.data.tmpltSttusCd === "S" || response.data.data.tmpltSttusCd === "T") {
              this.$router.push({ path: "/templeteManage" });
              return;
            } */
            if (!response.data.data) {
              // confirm.fnAlert("알림톡 템플릿관리", "검색된 리스트가 없습니다.", "alert");
              confirm.fnAlert("", "검색된 리스트가 없습니다.", "alert");
            } else {
              this.alimTalkInfo = response.data.data;
            }
          } else {
            // confirm.fnAlert("알림톡 템플릿관리", "해당 리스트 가져오기에 실패하였습니다.", "alert");
            confirm.fnAlert("", "해당 리스트 가져오기에 실패하였습니다.", "alert");
          }
        })
        .catch((error) => {
          console.log(error);
          // confirm.fnAlert("알림톡 템플릿관리", "해당 리스트 가져오기에 실패하였습니다." + error, "alert");
          confirm.fnAlert("", "해당 리스트 가져오기에 실패하였습니다." + error, "alert");
        });
    },

    fnDelTmp() {
      eventBus.$on("callbackEventBus", this.fnProcDeleteRequestKkoTmplt);
      // confirm.fnConfirm("삭제", "삭제하면 입력한 모든 정보가 삭제됩니다.<br />그래도 템플릿을 삭제하시겠어요?");
      confirm.fnConfirm("", "삭제하면 입력한 모든 정보가 삭제됩니다.<br />그래도 템플릿을 삭제하시겠어요?");
    },
    // 템플릿 삭제
    fnProcDeleteRequestKkoTmplt() {
      const delTmp = [{ tmpltKey: this.alimTalkInfo.tmpltKey, sndrprofKey: this.alimTalkInfo.sndrprofKey }];

      templeteManageApi
        .procDeleteRequestKkoTmplt(delTmp)
        .then((response) => {
          if (response.data.success) {
            //confirm.fnAlert("완료", "삭제되었습니다.", "alert");
            eventBus.$on("callbackEventBus", this.fnBackRouter);
            // confirm.fnConfirm("완료", "삭제했습니다.", "cbAlert");
            confirm.fnConfirm("", "삭제했습니다.", "cbAlert");
          } else {
            // confirm.fnAlert("오류", response.data.message, "alert");
            confirm.fnAlert("", response.data.message, "alert");
          }
        })
        .catch((error) => {
          // confirm.fnAlert("오류", "삭제 실패했습니다." + error, "alert");
          confirm.fnAlert("", "삭제 실패했습니다." + error, "alert");
        });
    },
    //미리보기 발신화면 최근작성문자 토글 설정
    mPreEvent(toggle) {
      if (toggle) {
        this.preEventToggle = true;
      } else {
        this.preEventToggle = false;
      }
    },
    // 모바일 미리보기
    mPreOn() {
      const mPop = document.querySelector(".m_preview_wrap"),
        dimmed = document.querySelector(".dimmed"),
        btnSent = document.getElementById("btn_sent"),
        btnHistory = document.getElementById("btn_history");
      dimmed.classList.add("open");
      mPop.classList.add("on");
      btnSent.checked = true;
      btnHistory.checked = false;
      this.mPreEvent(true);
    },
    mHisOn() {
      const mPop = document.querySelector(".m_preview_wrap"),
        dimmed = document.querySelector(".dimmed"),
        btnSent = document.getElementById("btn_sent"),
        btnHistory = document.getElementById("btn_history");
      dimmed.classList.add("open");
      mPop.classList.add("on");
      btnSent.checked = false;
      btnHistory.checked = true;
      this.mPreEvent(false);
    },
    mPreClose() {
      const mPop = document.querySelector(".m_preview_wrap"),
        dimmed = document.querySelector(".dimmed");
      mPop.classList.remove("on");
      dimmed.classList.remove("open");
    },
    fnGoListConfirm() {
      this.$router.push("/templeteManage");
      /* eventBus.$on("callbackEventBus", this.fnBackRouter);
      confirm.fnConfirm("", "목록으로 이동하면 수정한 정보는 저장되지 않습니다.<br />그래도 목록으로 이동하시겠어요?"); */
    },
    fnBackRouter() {
      this.$router.push("/templeteManage");
    },
    // 최근작성알림톡 가져오기
    fnSelectrecentSendAlimList() {
      const params = {
        recentOffset: 10,
        chStr: "KKO_NOTI",
      };
      messageSendApi.selectRecentSendMessageList(params).then((response) => {
        if (response.data.success) {
          this.recentSendAlimList = response.data.data.map((item) => {
            item.regDt = item.regDt.substring(0, 10) + "(" + item.regDt.substring(11, 16) + ")";
            return {
              rsvId: item.rsvId,
              regDt: item.regDt,
              ad: item.msgInfo ? JSON.parse(item.msgInfo).ad : "",
              adBottom: item.msgInfo ? JSON.parse(item.msgInfo).adBottom : "",
              adTitle: item.msgInfo ? JSON.parse(item.msgInfo).adTitle : "",
              authNumber: item.msgInfo ? JSON.parse(item.msgInfo).authNumber : "",
              callbackNumber: item.msgInfo ? JSON.parse(item.msgInfo).callbackNumber : "",
              content: item.msgInfo ? JSON.parse(item.msgInfo).content : "",
              dayOfTheWeek: item.msgInfo ? JSON.parse(item.msgInfo).dayOfTheWeek : "",
              dayOfTheWeekNum: item.msgInfo ? JSON.parse(item.msgInfo).dayOfTheWeekNum : "",
              dayOfTheWeekStr: item.msgInfo ? JSON.parse(item.msgInfo).dayOfTheWeekStr : "",
              fileList: item.msgInfo ? JSON.parse(item.msgInfo).fileList : "",
              fileName: item.msgInfo ? JSON.parse(item.msgInfo).fileName : "",
              legacyFiles: item.legacyFiles ? item.legacyFiles : "",
              mada: item.msgInfo ? JSON.parse(item.msgInfo).mada : "",
              monDiv: item.msgInfo ? JSON.parse(item.msgInfo).monDiv : "",
              msg: item.msgInfo ? JSON.parse(item.msgInfo).msg : "",
              reqProduct: item.msgInfo ? JSON.parse(item.msgInfo).reqProduct : "",
              rsvCfg1: item.msgInfo ? JSON.parse(item.msgInfo).rsvCfg1 : "",
              rsvCfg2dayWeek: item.msgInfo ? JSON.parse(item.msgInfo).rsvCfg2dayWeek : "",
              title: item.msgInfo ? JSON.parse(item.msgInfo).title : "",
              tomada: JSON.parse(item.msgInfo).tomada,
              trafficType: item.msgInfo ? JSON.parse(item.msgInfo).trafficType : "",
              tmpltName: item.msgInfo ? JSON.parse(item.msgInfo).tmpltName : "",
              tmpltKey: item.msgInfo ? JSON.parse(item.msgInfo).kakaoTemplateKey : "",
              chnlId: item.msgInfo ? JSON.parse(item.msgInfo).chnlId : "",
              repSendingYN: item.msgInfo ? JSON.parse(item.msgInfo).repSendingYN : "",
            };
          });
        } else {
          //confirm.fnAlert("최근작성문자", "최근작성문자 불러오기에 실패하였습니다.", "alert");
          //console.log(response);
        }
      });
    },
    // 최근작성문자 가져오기
    fnRecentSendAlimList(msg) {
      this.title = msg.title ? msg.title : "";
      this.adTitle = msg.adTitle ? msg.adTitle.replace("(광고)", "") : "";
      this.authNumber = msg.authNumber ? msg.authNumber : "";
      this.adBottom = msg.adBottom ? msg.adBottom : "";
      this.msg = msg.content ? msg.content : "";
      const fileName = msg.fileName ? msg.fileName.split(",") : "";
      this.fileList = msg.legacyFiles
        ? msg.legacyFiles
            .split(",")
            .filter((file) => file !== "")
            .map((file, index) => {
              return {
                name: fileName !== "" ? fileName[index] : file,
                src: `/api/v1/fo/preview/${file}`,
                fileName: file,
              };
            })
        : [];

      // 바이트 계산
      this.textByte = this.$gfnCommonUtils.getByte(this.msg);
      this.adByte = this.$gfnCommonUtils.getByte(this.adTitle) + this.$gfnCommonUtils.getByte(this.adBottom) + this.$gfnCommonUtils.getByte(this.authNumber) + 6;
      const btnSent = document.getElementById("btn_sent");
      this.mPreEvent(true);
      btnSent.checked = true;
      const altCon = document.querySelector(".use_txt");
      altCon.style.display = "block";
    },

    // 모바일 미리보기
    mPreAlimOn() {
      const PrevAlim = document.querySelectorAll(".preview_swiper");
      const Alimtalk = document.querySelector(".m_preview_alimtalk .talk_preview");
      const dimmed = document.querySelector(".dimmed");
      const mAlimPreview = document.querySelector(".m_preview_alimtalk");
      PrevAlim.forEach((item) => (item.style.display = "none"));
      Alimtalk.style.display = "block";
      dimmed.classList.add("open");
      mAlimPreview.classList.add("on");
    },
    mHisAlimOn() {
      const PrevAlim = document.querySelectorAll(".preview_swiper");
      const Alimtalk = document.querySelector(".m_preview_alimtalk .talk_history");
      const dimmed = document.querySelector(".dimmed");
      const mAlimPreview = document.querySelector(".m_preview_alimtalk");
      PrevAlim.forEach((item) => (item.style.display = "none"));
      Alimtalk.style.display = "block";
      dimmed.classList.add("open");
      mAlimPreview.classList.add("on");
    },
  },
};
</script>
