<template>
  <section class="m_section p_including">
    <!-- class="p_including" : preview 포함 -->
    <!--  s:   contents-->
    <div class="contents">
      <MobileGnb />
      <div class="tabcont_wrap without_tab send_edit">
        <form @submit.prevent="" autocomplete="off">
          <div class="tabcont tabcont02 on">
            <h1 class="title">예약 메시지 수정</h1>
            <!-- 내용 -->
            <div class="box_wrap inputbox_wrap">
              <div class="box_top">
                <h3 class="tit required">내용</h3>
                <div class="chkbox">
                  <input type="checkbox" id="ad_sms" class="chk" v-model="ad" :disabled="!modiAuth" />
                  <label for="ad_sms">광고성 문자</label>
                  <div class="btn_question" @click="ModalOpen('adpop')"></div>
                </div>
                <span class="required required_desc">필수 입력</span>
              </div>
              <div class="box_cont inputbox" :class="{ disabled: !modiAuth }">
                <div class="input_top" v-show="reqProduct !== 'SMS'">
                  <input
                    type="text"
                    maxlength="30"
                    v-model="title"
                    @input="changeTitle"
                    class="input_tit"
                    :placeholder="reqProduct === 'SMS' ? 'SMS인 경우 제목은 전송되지 않습니다.' : '제목을 입력하세요.'"
                    :disabled="!modiAuth"
                  />
                  <span class="input_tit_length">{{ titleByte }} <span class="limit">/ 30Byte</span></span>
                </div>
                <div class="input_con">
                  <span class="ad">[Web 발신]</span>
                  <textarea
                    name="sendMessageContent"
                    id="sendMessageContent"
                    ref="sendMessageContent"
                    cols="30"
                    class="input_cont"
                    :readonly="msgReadonly"
                    v-model="msg"
                    @input="changeContent"
                    @keyup="resizeText($event)"
                    @keydown="resizeText($event)"
                    placeholder="내용을 입력하세요. 
※ 90byte를 넘으면 LMS로 자동 전환됩니다.
※ 이미지를 추가하면 MMS로 자동 전환됩니다."
                    :disabled="!modiAuth"
                  ></textarea>
                </div>
                <div class="input_bottom">
                  <div class="fixed_txt" v-if="ad">
                    <p>{{ adBottom }}</p>
                    <p>{{ authNumber }}</p>
                  </div>
                  <div class="count">
                    <span class="type">{{ reqProduct }}</span>
                    <span class="byte"
                      >{{ totalByte }}<span class="limit"> / {{ messageByte | formatComma }}Byte</span></span
                    >
                  </div>
                </div>
                <div class="special_wrap">
                  <div class="btn_wrap">
                    <button type="button" class="btn white btn_special" :disabled="!modiAuth" @click="specialOn()">특수문자</button>
                    <button type="button" class="btn white btn_vari" :disabled="!modiAuth" @click="variOn()">자동 변경 정보</button>
                  </div>
                  <div class="special_char">
                    <ul class="special_ul">
                      <li><button type="button" @click="insertCharacters('☆')">☆</button></li>
                      <li><button type="button" @click="insertCharacters('★')">★</button></li>
                      <li><button type="button" @click="insertCharacters('○')">○</button></li>
                      <li><button type="button" @click="insertCharacters('●')">●</button></li>
                      <li><button type="button" @click="insertCharacters('◎')">◎</button></li>
                      <li><button type="button" @click="insertCharacters('◇')">◇</button></li>
                      <li><button type="button" @click="insertCharacters('◆')">◆</button></li>
                      <li><button type="button" @click="insertCharacters('☜')">☜</button></li>
                      <li><button type="button" @click="insertCharacters('☞')">☞</button></li>
                      <li><button type="button" @click="insertCharacters('□')">□</button></li>
                      <li><button type="button" @click="insertCharacters('■')">■</button></li>
                      <li><button type="button" @click="insertCharacters('△')">△</button></li>
                      <li><button type="button" @click="insertCharacters('▲')">▲</button></li>
                      <li><button type="button" @click="insertCharacters('▽')">▽</button></li>
                      <li><button type="button" @click="insertCharacters('▼')">▼</button></li>
                      <li><button type="button" @click="insertCharacters('♩')">♩</button></li>
                      <li><button type="button" @click="insertCharacters('♪')">♪</button></li>
                      <li><button type="button" @click="insertCharacters('♬')">♬</button></li>
                      <li><button type="button" @click="insertCharacters('→')">→</button></li>
                      <li><button type="button" @click="insertCharacters('←')">←</button></li>
                      <li><button type="button" @click="insertCharacters('↑')">↑</button></li>
                      <li><button type="button" @click="insertCharacters('↓')">↓</button></li>
                      <li><button type="button" @click="insertCharacters('↔')">↔</button></li>
                      <li><button type="button" @click="insertCharacters('↗')">↗</button></li>
                      <li><button type="button" @click="insertCharacters('↙')">↙</button></li>
                      <li><button type="button" @click="insertCharacters('↖')">↖</button></li>
                      <li><button type="button" @click="insertCharacters('↘')">↘</button></li>
                      <li><button type="button" @click="insertCharacters('◁')">◁</button></li>
                      <li><button type="button" @click="insertCharacters('▷')">▷</button></li>
                      <li><button type="button" @click="insertCharacters('◀')">◀</button></li>
                      <li><button type="button" @click="insertCharacters('▶')">▶</button></li>
                      <li><button type="button" @click="insertCharacters('♤')">♤</button></li>
                      <li><button type="button" @click="insertCharacters('♠')">♠</button></li>
                      <li><button type="button" @click="insertCharacters('♡')">♡</button></li>
                      <li><button type="button" @click="insertCharacters('♥')">♥</button></li>
                      <li><button type="button" @click="insertCharacters('♧')">♧</button></li>
                      <li><button type="button" @click="insertCharacters('♣')">♣</button></li>
                      <li><button type="button" @click="insertCharacters('☆')">☆</button></li>
                      <li><button type="button" @click="insertCharacters('◈')">◈</button></li>
                      <li><button type="button" @click="insertCharacters('▣')">▣</button></li>
                      <li><button type="button" @click="insertCharacters('◐')">◐</button></li>
                      <li><button type="button" @click="insertCharacters('◑')">◑</button></li>
                      <li><button type="button" @click="insertCharacters('☏')">☏</button></li>
                      <li><button type="button" @click="insertCharacters('☎')">☎</button></li>
                      <li><button type="button" @click="insertCharacters('㉿')">㉿</button></li>
                    </ul>
                  </div>
                  <div class="vari_txt">
                    <div class="vari_desc">
                      <p>사용하고자 하는 자동 변경 정보를 #{ } 표시로 입력해 주세요.</p>
                      <p>자동 변경 정보에 들어가는 문구는 5자 이내를 권장합니다.<br /></p>
                      <p>자동 변경 정보는 메시지 내용 중 특정 구역(A)를 지정하고, 데이터(B)가 입력된 파일을 이용하여 특정 구역(A)에 데이터(B)가 자동으로 삽입되는 기능입니다.</p>
                      <p><span>예)</span>① 문자에 #{이름} 삽입 <br />② 엑셀에 ‘이름’열(A열)에 김OO님, 이OO님 입력하여 업로드 <br />③#{이름}에 A열의 데이터가 자동으로 삽입되어 전송 됨</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <!-- 자동 변경 정보 -->
            <div class="box_wrap img_wrap" v-if="autoMsg.length > 0" style="margin-top: 15px">
              <div class="box_top">
                <h3 class="tit mg10r" style="width: 130px">
                  자동 변경 정보
                  <div class="btn_question tt_btn">
                    <div class="tooltip l_tooltip">
                      <ul>
                        <li><p>자동 변경 정보(변수)로 설정하고자 하는 내용을 #{변수명} 표시로 작성해 주세요.</p></li>
                        <li><p>예) 이름과 출금일 자동 변경 정보(변수) 설정 시,</p></li>
                        <li><p>#{고객명}님 #{출금일} 출금 예정입니다.</p></li>
                      </ul>
                    </div>
                  </div>
                </h3>
                <div class="btn1_wrap">
                  <span class="btn white small cpink" v-for="(text, index) in autoMsg" :key="index" style="border: 1px solid #f14b84; margin: 0 10px 5px 0">{{ text | textLengthCut(5) }}</span>
                </div>
              </div>
            </div>

            <!-- 이미지 -->
            <div class="box_wrap img_wrap">
              <div class="box_top">
                <h3 class="tit">
                  이미지 첨부
                  <div class="btn_question tt_btn">
                    <div class="tooltip l_tooltip">
                      <ul>
                        <li>
                          <p>최대 크기 : 1500(가로)*1440(세로)px 이하 전체 합계 300KB이하</p>
                        </li>
                        <li>
                          <p>지원 파일 형식과 장수: jpg 파일, 최대 3장</p>
                        </li>
                      </ul>
                    </div>
                  </div>
                </h3>
                <div class="btn1_wrap">
                  <button type="button" class="btn plus" :class="{ disabled: !modiAuth }" @click="ModalOpen('img_pop')">이미지 추가</button>
                </div>
              </div>
              <div class="box_cont imgbox" v-if="fileList.length > 0">
                <ul>
                  <li v-for="(image, index) in fileList" :key="index">
                    <span class="num">{{ index + 1 }}</span>
                    <p class="name">{{ image.name | fileTextLengthCut(6, "…") }}</p>
                    <button type="button" class="btn_close" v-if="modiAuth" @click="handleRemove(index)"></button>
                  </li>
                </ul>
              </div>
            </div>
            <!-- 수신번호 -->
            <div class="box_wrap receiving_wrap">
              <div class="box_top">
                <h3 class="tit">
                  수신번호
                  <div class="btn_question tt_btn">
                    <div class="tooltip l_tooltip">
                      <ul>
                        <li><p>중복된 수신번호에는 메시지가 전송되지 않습니다.</p></li>
                        <li><p>한 번에 20,000건까지 보낼 수 있습니다.</p></li>
                        <li><p>상위 100건까지만 위 화면에 보여집니다.</p></li>
                      </ul>
                    </div>
                  </div>
                </h3>
                <div class="top_right">
                  <div class="select_wrap">
                    <select name="" id="" class="select" :disabled="!modiAuth" v-model="recentSendGroup" @change="fnSelectRecentSendRecvInfo(recentSendGroup)">
                      <option value="null">{{ recentSendGroupList.length > 0 ? "최근 전송 그룹" : "최근 전송 그룹이 없습니다." }}</option>
                      <option v-for="(list, index) in recentSendGroupList" :key="index" :value="list.webReqId">{{ list.sendNm }} ({{ list.reqDt }}) ({{ list.sendCnt | formatComma }}명)</option>
                    </select>
                  </div>
                  <div class="btn2_wrap">
                    <button class="btn addinput" type="button" :class="{ disabled: !modiAuth }" @click="fnDirectAddAddress">직접입력 추가</button>
                    <button type="button" class="btn" :class="{ disabled: !modiAuth }" @click="ModalOpen('address')">주소록에서 추가</button>
                    <button type="button" class="btn" :class="{ disabled: !modiAuth }" @click="ModalOpen('excelpop')">파일로 추가</button>
                  </div>
                </div>
              </div>
              <div class="box_cont">
                <div class="fixed-table-cover">
                  <div class="fixed-table-container" style="width: max-content; min-width: 100%">
                    <div class="fixed-table-header-bg"></div>
                    <div class="fixed-table-wrapper">
                      <table class="fixed-table">
                        <colgroup>
                          <col width="5%" />
                          <col width="47%" v-if="autoMsg.length == 0" />
                          <col width="47%" v-if="autoMsg.length == 0" />
                        </colgroup>
                        <thead>
                          <tr>
                            <th class="td_chk" id="td_chk" style="width: 5%">
                              <div class="th-text">
                                <input
                                  type="checkbox"
                                  class="chk"
                                  name="list"
                                  id="chk_all"
                                  v-model="allChecked"
                                  @click="checkedAll($event.target.checked)"
                                  :disabled="recvInfoList.length === 0"
                                /><label for="chk_all"></label>
                              </div>
                            </th>
                            <th class="td_phone" id="td_phone" :style="autoMsg.length >= 4 ? (isMobile ? 'width: 173px;' : 'width: 320px;') : 'width: 47%'">
                              <div class="th-text">휴대폰 번호</div>
                            </th>
                            <th v-if="autoMsg.length == 0" style="width: 47%"></th>
                            <th :class="index === 0 ? 'td_name' : 'td_' + index" v-for="(msg, index) in autoMsg" :key="index" :id="index === 0 ? 'td_name' : `td_${index}`">
                              <div class="th-text">{{ msg | textLengthCut(5) }}</div>
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr v-for="(data, index) in recvInfoList.slice(0, 100)" :key="index">
                            <td class="td_chk">
                              <input type="checkbox" class="chk" :id="'chk_' + index" :value="index" v-model="data.selected" @change="selected()" /><label :for="'chk_' + index"></label>
                            </td>
                            <td>
                              <input
                                type="text"
                                v-model="data.phone"
                                class="input"
                                :class="data.selfAdd ? 'add' : ''"
                                placeholder="휴대폰번호"
                                @input="fnPhoneDash($event, index)"
                                autocomplete="off"
                              />
                            </td>
                            <td v-if="autoMsg.length == 0"></td>
                            <td v-for="(msg, index) in autoMsg" :key="index">
                              <input
                                type="text"
                                v-model="data[msg]"
                                class="input"
                                :class="data.selfAdd ? 'add' : ''"
                                :placeholder="autoMsg[index] ? $gfnCommonUtils.textLengthCut(autoMsg[index], 5) : ''"
                              />
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>

                <div class="list_info">
                  <div class="total">
                    <p>
                      총 <span class="pink">{{ totalCount | formatComma }} </span>건
                    </p>
                  </div>
                  <div class="btn_wrap">
                    <button type="button" class="btn white" :class="{ disabled: !modiAuth }" @click="fnAddrSelectedDel()">선택삭제</button>
                    <button type="button" class="btn white" :class="{ disabled: !modiAuth }" @click="fnAddrAllDel()">전체삭제</button>
                  </div>
                </div>
              </div>
            </div>
            <!-- 발신번호 -->
            <div class="box_wrap sentbox_wrap">
              <div class="box_top">
                <h3 class="tit required">발신번호</h3>
                <div class="top_right">
                  <div class="select_wrap">
                    <select name="" id="" class="select" :disabled="!modiAuth" v-model="callbackNumber" @change="callbackNumberAdd">
                      <option value="null">발신번호 선택</option>
                      <option :value="list.sndrno" v-for="(list, index) in sendNumberList" :key="index">{{ list.sndrno }} {{ list.sndrnoNm }} {{ list.blckYn === "Y" ? ` (차단번호)` : "" }}</option>
                      <option v-if="loginDiv && userDiv" value="add">번호 추가 +</option>
                    </select>
                  </div>
                  <!-- div class="btn1_wrap">
                    <button class="btn white plus" @click="callbackNumberAdd">발신번호 추가</button>
                  </div -->
                </div>
              </div>
            </div>
            <!-- 전송시각 -->
            <ModiSendTimeVue ref="sendTime" @fnSetSendTime="fnSetSendTime" :modiAuth="modiAuth" />
          </div>
          <div class="btn_wrap" v-if="modiAuth">
            <button class="btn large_pink" type="button" @click="fnMessageSend('sendpop')">수정</button>
            <button class="btn large_white" @click="fnGoList" type="button">취소</button>
          </div>
          <div class="btn_wrap" v-if="!modiAuth" style="display: flex; justify-content: center">
            <button class="btn large_white" @click="$router.push({ path: '/sendManage/rsvSendMessage' })" type="button">목록</button>
          </div>
        </form>

        <!--  s: m_preview  -->
        <div class="m_preview_wrap">
          <button type="button" class="btn_close" @click="mPreClose()"></button>
          <div class="m_preview">
            <div class="preview_toggle">
              <input type="radio" name="preview_type_toggle" id="btn_sent_toggle" @click="mPreEvent(true)" checked />
              <label for="btn_sent_toggle">미리보기</label>
              <input type="radio" name="preview_type_toggle" id="btn_history_toggle" @click="mPreEvent(false)" />
              <label for="btn_history_toggle">최근 작성 메시지</label>
            </div>
            <div class="preview_top" v-show="preEventToggle">
              <input type="radio" name="" id="btn_sent" @click="mPreEvent(true)" checked />
              <label for="btn_sent">
                <span>발신번호</span>
                <input type="text" placeholder="발신번호" :value="callbackNumber !== 'null' && callbackNumber !== 'add' ? callbackNumber : ''" readonly />
              </label>
              <!-- <input type="radio" name="" id="btn_history" @click="mPreEvent(false)" /><label for="btn_history"><span>최근 작성 메시지</span></label> -->
            </div>

            <!--  s: 미리보기   -->
            <div class="preview_cont" v-show="preEventToggle">
              <swiper class="pcont_top" ref="previewSwiper" v-show="fileList.length > 0" :options="swiperOption">
                <swiper-slide class="pcont_img" v-for="(image, index) in fileList" :key="index"><img :src="image.src" class="img-fluid" /></swiper-slide>
              </swiper>
              <div class="swiper-pagination pc_pagination" slot="pagination" v-show="fileList.length > 0"></div>

              <div class="pcont_bottom" :class="fileList.length === 0 ? 'sms' : ''">
                <div class="pcont_tit" v-show="reqProduct !== 'SMS'">
                  <p class="output_tit" :style="title ? 'color:#121006' : ''" v-html="previewTitle ? previewTitle : '제목 미리보기'"></p>
                </div>
                <div class="pcont_txt scr_wrap">
                  <p class="name" style="color:#121006">[Web 발신]</p>
                  <p class="cont" :style="msg ? 'color:#121006' : ''" v-html="previewMsg"></p>
                  <div class="fixed_txt" v-if="ad">
                    <p>{{ adBottom }}</p>
                    <p>{{ authNumber }}</p>
                  </div>
                </div>
              </div>
              <div class="pcont_info">
                <span class="type">{{ reqProduct }}</span>
                <span class="date">{{ today }}</span>
              </div>
            </div>
            <!--  e: 미리보기   -->

            <!--  s: 최근전송내역  -->
            <RecentMessageListVue :preEventToggle="preEventToggle" @recentSendMessageList="fnRecentSendMessageList" :modiAuth="modiAuth" :recentSendMessageList="recentSendMessageList" />
            <!--   e: 최근전송내역   -->
          </div>
        </div>
        <!--  s: m_preview  -->
      </div>
    </div>
    <!--  e:   contents  -->

    <!--  s: m_btn_wrap -모바일버전에서 버튼  -->
    <div class="m_btn_wrap">
      <button type="button" class="m_preview_btn" @click="mPreAlimOn()">미리보기</button>
      <button type="button" class="m_aside_btn" @click="mHisAlimOn()">최근<br />작성메시지</button>
    </div>

    <!-- s: 모바일 미리보기 -->
    <MobilePreviewSmsVue
      :fileList="fileList"
      :title="title"
      :ad="ad"
      :msg="msg"
      :adBottom="adBottom"
      :authNumber="authNumber"
      :reqProduct="reqProduct"
      :today="today"
      :callbackNumber="callbackNumber"
      :recentSendMessageList="recentSendMessageList"
      @recentSendMessageList="fnRecentSendMessageList"
    />
    <!-- e: 모바일 미리보기 -->

    <!--  s: popup  -->
    <!-- <div class="dimmed" @click="ModalClose()"></div> -->
    <div class="popup-wrap">
      <SendAddress ref="sendAddr" @sendAddrInfoSet="fnSendAddrInfoSet" /><!-- 주소록 불러오기 -->
      <!-- <ImageFileAdd ref="fileArea" @setImageInfo="setImageInfo" :totalImagefileSize="totalImagefileSize" :fileList="fileList" /> --><!-- 이미지 파일등록 -->
      <ExcelFileUpload @excelAddrinfoSet="fnExcelAddrinfoSet" :autoMsg="autoMsg" /><!-- 파일불러오기(엑셀파일로 일괄 등록 팝업) -->
      <!-- s: 미리전송 -->
      <SendConfirm
        ref="sendConfirm"
        :rsvId="rsvId"
        :ad="ad"
        :msg="msg"
        :title="title"
        :adBottom="adBottom"
        :authNumber="authNumber"
        :recvInfoList="recvInfoList"
        :fileList="fileList"
        :callbackNumber="callbackNumber"
        :reqProduct="reqProduct"
        :sendTimeData="sendTimeData"
        :rejectPhoneList="rejectPhoneList"
        :autoMsg="autoMsg"
        :adByte="adByte"
      />
      <!-- s: 전송확인 -->
      <!-- s: 광고성 문자 전송시 표기 의무 안내-->
      <AdMessagePopup />
      <!-- e: 광고성 문자 전송시 표기 의무 안내-->
      <!-- 이미지 등록 팝업 s-->
      <div class="popup tit img_pop">
        <div class="pop-head">
          <h3 class="pop-tit">이미지 첨부</h3>
        </div>
        <div class="pop-cont">
          <div class="imgpop_top">
            <div class="left_wrap">
              <ul v-if="imageAddTitle == ''">
                <li><p>최대 크기 : 1500(가로)*1440(세로)px 이하 전체 합계 300KB이하</p></li>
                <li><p>지원 파일 형식과 장수: jpg 파일, 최대 3장</p></li>
              </ul>
              <ul v-else>
                <li>
                  <p class="pink">{{ imageAddTitle }}</p>
                </li>
              </ul>
            </div>

            <div class="btn plus" :class="updateFileList.length >= 3 ? 'disabled' : ''">
              이미지 추가
              <input type="file" accept=".jpg, .jpeg" multiple="multiple" @change="onFileChange" class="form-control-file" id="my-file" ref="imageUploader" />
            </div>
          </div>
          <div class="img_list_wrap" v-if="updateFileList.length > 0">
            <div class="img_list" v-for="(item, index) in updateFileList" :key="index">
              <p class="file_name">{{ item.name | fileTextLengthCut(8, "…") }}</p>
              <button class="btn_del" @click="popupHandleRemove(index)"></button>
            </div>
          </div>
          <div class="img_list_wrap empty" v-else>
            <p>등록된 이미지가 없습니다.</p>
          </div>
        </div>
        <div class="pop-btn1">
          <button type="button" class="btn large_pink" @click="fnSendData()">확인</button>
          <button type="button" class="btn large_white" @click="fileModalClose()">취소</button>
        </div>
      </div>
      <!-- 이미지 등록 팝업 e-->
    </div>
    <!--  e: popup  -->
  </section>
</template>
<script>
import store from "@/store";
import sendManageApi from "@/modules/sendManage/service/sendManageApi";
import messageSendApi from "@/modules/messageSend/service/messageSendApi";
import confirm from "@/modules/commonUtil/service/confirm";
import { eventBus } from "@/modules/commonUtil/service/eventBus";
import SendAddress from "../components/SendAddress.vue";
//import ImageFileAdd from "../components/ImageFileAdd.vue";
import ExcelFileUpload from "@/modules/messageSend/components/ExcelFileUpload.vue";
import SendConfirm from "../components/SendConfirm.vue";
import { swiper, swiperSlide } from "vue-awesome-swiper";
import ToastComponentVue from "@/components/ToastComponent.vue";
import RecentMessageListVue from "@/modules/messageSend/components/RecentMessageList.vue";
import ModiSendTimeVue from "../components/ModiSendTime.vue";
import MobileGnb from "@/components/MobileGnb.vue";
import MobilePreviewSmsVue from "@/modules/messageSend/components/MobilePreviewSms.vue";
import AdMessagePopup from "@/modules/messageSend/components/AdMessagePopup.vue";

export default {
  name: "rsvModiSms",
  components: { SendAddress, ExcelFileUpload, SendConfirm, swiper, swiperSlide, RecentMessageListVue, ModiSendTimeVue, MobileGnb, AdMessagePopup, MobilePreviewSmsVue },

  props: {
    smsModiView: {
      type: Boolean,
      require: true,
    },
  },
  data() {
    return {
      selectPos: 0, // insertCharacters 초기 값
      loginDiv: false,
      userDiv: false,
      modiAuth: true, // 수정 가능 여부
      init: true,
      rsvId: "",
      msg: "", // 메시지 내용
      msgReadonly: false,
      title: "", // 제목
      adBottom: "", //this.$store.getters["getHubEzData"].reject080Number ? "무료거부 " + this.$store.getters["getHubEzData"].reject080Number : "", // 무료 거부 번호
      autCd: "",
      authNumber: "", // 인증코드
      subSttusCd: "",
      fileList: [],
      updateFileList: [], // 업로드한 이미지 파일
      callbackNumber: "null", // 선택된 발신번호
      totalMemCnt: 0,
      recvInfoList: [],
      // 주소록 리스트
      sendTimeData: {}, // 전송시각 오브젝트
      preRecvInfoList: [], // 미리보기 주소록
      recentSendGroup: "null", // 선택된 수신번호 그룹
      recentSendGroupList: [], // 조회된 최신전송그룹 리스트
      sendNumberList: [],
      rejectPhoneList: [], // 080 수신거부 리스트
      preEventToggle: true,
      today: "",
      titleByte: "0",
      textByte: "0",
      adByte: 6,
      reqProductChange: true,
      totalImagefileSize: 0,
      ad: false,
      allChecked: false, // 전체 체크박스 값
      hashNum: new Map(), // 연락처 중복 처리 변수 값 (errorCheck 할 경우 function 마다 초기화 진행)
      sendLimit: true,
      recentSendMessageList: [], // 최근작성문자 가져오기
      imageAddTitle: "",
      // 미리보기 화면 swiper
      swiperOption: {
        slidesPerView: "auto",
        spaceBetween: 6, // swiper-slide 사이의 간격 지정
        slidesOffsetBefore: 0, // slidesOffsetBefore는 첫번째 슬라이드의 시작점에 대한 변경할 때 사용
        slidesOffsetAfter: 0, // slidesOffsetAfter는 마지막 슬라이드 시작점 + 마지막 슬라이드 너비에 해당하는 위치의 변경이 필요할 때 사용
        //freeMode: true, // freeMode를 사용시 스크롤하는 느낌으로 구현 가능
        centerInsufficientSlides: true, // 컨텐츠의 수량에 따라 중앙정렬 여부를 결정함
        pagination: {
          el: ".pc_pagination.swiper-pagination",
          clickable: true,
          renderBullet: function (index, className) {
            return '<span class="' + className + '">' + (index + 1) + "</span>";
          },
        },
      },
      autoMsg: [],
      enterByte: 2,
    };
  },
  computed: {
    // 미리보기 화면 줆바꿈 띄어쓰기 가능하도록 변경
    previewTitle() {
      return this.title ? this.title.split(" ").join("&nbsp;").split("\n").join("<br/>") : "";
    },
    previewMsg() {
      return this.msg ? this.msg.split(" ").join("&nbsp;").split("\n").join("<br/>") : "";
    },
    totalByte() {
      return parseInt(this.textByte) + (this.ad ? parseInt(this.adByte) : 0);
    },
    // 문자 유형 (문자 : SMS, LMS, MMS, 글로벌SMS : SMS_GLOBAL, RCS : RCS, 카카오톡 : KKO_NOTI (알림톡), KKO_FRIEND (친구톡))
    reqProduct() {
      return this.fileList.length > 0 ? "MMS" : this.totalByte > 90 && this.reqProductChange !== true ? "LMS" : "SMS";
    },
    messageByte() {
      return this.fileList.length > 0 ? "2000" : this.totalByte > 90 ? "2000" : "90";
    },
    totalCount() {
      return (
        this.recvInfoList.length -
        this.recvInfoList.filter((e) => {
          return e.selfAdd === true && e.phone === "";
        }).length
      );
    },
    totalFileSize: function () {
      let updateSize = 0;
      this.updateFileList.forEach((e) => {
        updateSize += e.size;
      });
      updateSize;
      return updateSize;
    },
    isMobile: function () {
      return store.getters["getIsMobile"];
    },
  },
  watch: {
    isMobile(value) {
      this.mobile = value;
    },
    totalByte: function (getTotalByte) {
      if (getTotalByte > 90 && this.reqProductChange && this.reqProduct !== "MMS") {
        eventBus.$on("callbackCancleEventBus", this.fnMessageContentCut);
        eventBus.$on("callbackEventBus", this.fnreqProductChange);
        this.msgReadonly = true;
        confirm.fnConfirm("", "장문메시지(LMS)로 전환하시겠습니까?", "msgChange");
      } else if (getTotalByte > 2000) {
        if (this.ad) {
          confirm.fnAlert(
            "",
            "광고 메시지를 체크할 경우, [web 발신]과 (광고),<br/>080수신거부번호가 자동 추가되어 내용이 2,000byte를 넘었습니다. <br/>2000byte에 맞게 내용을 수정해 주세요.",
            "message"
          );
        } else {
          confirm.fnAlert("", "내용이 2,000byte를 넘었습니다. <br/>2000byte에 맞게 내용을 수정해 주세요.", "message");
        }
        this.fnMessageContentCut();
      } else if (getTotalByte <= 90) {
        this.reqProductChange = true;
      }
    },
    reqProduct: function (messageType) {
      if (this.init) {
        this.init = false;
        return;
      }
      const msgType = messageType === "SMS" ? "단문메시지(SMS)" : messageType === "LMS" ? "장문메시지(LMS)" : "사진메시지(MMS)";
      this.fnToastMessage(msgType + "로 변경되었습니다.");
    },
    fileList: function (newFileList) {
      if (this.totalByte > 90) {
        this.reqProductChange = false;
      } else {
        this.reqProductChange = true;
      }
      this.fileList = newFileList;
    },
    ad: function(){
      if(this.ad){
        this.msg = "(광고)"+this.msg;
        this.textByte = this.$gfnCommonUtils.getByte(this.msg)+2;
      }else{
        this.msg = this.msg.replace("(광고)", "");
        this.textByte = this.$gfnCommonUtils.getByte(this.msg);
      }
    },
    msg: function (newMsg) {
      // 작성하는 텍스트를 자동 변수로 인식하는 기능 추가
      const pattern = /#\{([^#{}]*)\}/g; // 특정 문자 사이 추출 (특정문자 제외)
      const pattern2 = /((#{).*?(\}))/g; // 특정 문자 사이 추출 (특정문자 포함)
      let autoMsgList = [];

      // 메시지 내용 패턴 및 빈값 체크
      if (!this.$gfnCommonUtils.isEmpty(newMsg.match(pattern))) {
        const matches = newMsg.match(pattern);
        autoMsgList = matches.map(match => match.substring(2, match.length - 1));

        // 자동변경정보 공백 제거
        let msgSpace = autoMsgList.some((e) => {
          return e.indexOf(" ") > -1;
        });

        if (msgSpace) {
          eventBus.$on("callbackEventBus", () => {
            autoMsgList = autoMsgList.map((e) => {
              return e.replace(/ /g, "");
            });

            // 내용 자동변경정보 띄어쓰기 사용시 자동 삭제
            let autoMsgText = "";
            autoMsgText = newMsg.replace(pattern2, (match) => {
              return match.replace(/ /g, "");
            });
            this.msg = autoMsgText;
            this.textByte = this.$gfnCommonUtils.getByte(autoMsgText);
          });
          confirm.fnConfirm("", "자동변경정보 사용시 공백은 포함되지 않습니다.", "cbAlert");
          return false;
        }
        // phone 변수 사용시 안내
        if (autoMsgList.includes("phone")) {
          eventBus.$on("callbackEventBus", this.fnRemoveDefaultAutoMsg);
          confirm.fnConfirm("", "#{phone} 자동변경정보는 [휴대폰 번호]에서 사용하고 있습니다.<br/> 다른 자동변경정보를 사용하세요.", "cbAlert");
          return false;
        }

        // 중복 제거 && phone 변수 제외 처리
        this.autoMsg = autoMsgList.filter((e, index) => {
          return autoMsgList.indexOf(e) === index && e !== "phone";
        });

        if (this.autoMsg.length > 0) {
          this.tableWidthCalc();
        }
      } else {
        this.autoMsg = [];
      }
    },
  },
  created() {
    this.fnCreated();
  },
  mounted() {
    this.fnRejectPhoneList();
    this.fnCallerIdList();
    this.fnSelectRecentSendGroup();
    this.fnSelectRsvDetailInfo(this.$router.history.current.params.rsvId);
    this.fnreqProductChange();
    this.fnSelectRecentSendMessageList();
  },
  methods: {
    fnCreated() {
      const isAuth = store.getters["login/isAuth"];
      if (isAuth) {
        this.loginDiv = true;
        messageSendApi.selectAutInfoChnnel().then((response) => {
          var result = response.data;
          if (result.success) {
            this.autCd = result.data.autCd;
            this.authNumber = result.data.authcd080 ? "인증코드 " + result.data.authcd080 : "인증코드 없음";
            this.adBottom = this.$store.getters["getHubEzData"].reject080Number ? "무료거부 " + this.$store.getters["getHubEzData"].reject080Number : ""; // 무료 거부 번호
            this.subSttusCd = result.data.subSttusCd;
            if (this.subSttusCd === "02") {
              this.userDiv = true;
            }
          }
        });
      } else {
        eventBus.$on("callbackEventBus", () => {
          this.$router.push({ path: "/sendManage/rsvSendMessage" });
        });
        confirm.fnConfirm("", "로그인 후 이용 가능합니다.", "cbAlert");
      }
    },
    fnInit(rsvId) {
      this.rsvId = rsvId;
      this.getToday();
      //this.fnSelectCallbackList();
      this.fnCallerIdList();
      this.getAdByte();
      this.fnSelectRsvDetailInfo(rsvId);
    },
    fnSelectRsvDetailInfo(rsvId) {
      this.rsvId = rsvId;
      let param = {
        rsvId: rsvId,
      };

      sendManageApi.selectRsvMsgDetail(param).then((response) => {
        var result = response.data;

        if (result.success) {
          var rsvDt = new Date(result.data.rsvMessageInfo.realRsvTm);
          var today = new Date();
          var diffTime = Math.round((rsvDt.getTime() - today.getTime()) / (1000 * 60));

          if (diffTime <= 5) {
            this.modiAuth = false;
          }

          if(this.ad){
            if(result.data.rsvMessageDetail.ad == "true"){
              this.msg = result.data.rsvMessageDetail.content ? result.data.rsvMessageDetail.content.replaceAll("\r\n", "\n") : "";
            }else{
              this.msg = result.data.rsvMessageDetail.content ? "(광고)"+result.data.rsvMessageDetail.content.replaceAll("\r\n", "\n") : "";
              this.ad = false;
            }
          }else{
            if(result.data.rsvMessageDetail.ad == "true"){
              this.msg = result.data.rsvMessageDetail.content ? result.data.rsvMessageDetail.content.replace("(광고)", "").replaceAll("\r\n", "\n") : "";
              this.ad = true;
            }else{
              this.msg = result.data.rsvMessageDetail.content ? result.data.rsvMessageDetail.content.replaceAll("\r\n", "\n") : "";
            }
          } 
          
          this.modiChangeTitle(result.data.rsvMessageDetail.title);
          this.changeUpContent(this.msg);
          this.adBottom = result.data.rsvMessageDetail.adBottom ? result.data.rsvMessageDetail.adBottom : this.adBottom;
          this.authNumber = result.data.rsvMessageDetail.authNumber ? result.data.rsvMessageDetail.authNumber : this.authNumber;
          this.callbackNumber = this.$gfnCommonUtils.hpNumberAddDash(result.data.rsvMessageDetail.callbackNumber);
          this.getAdByte();
          if (this.autoMsg.length > 0) {
            this.tableWidthCalc();
          }

          //legacyFiles
          this.fileList = result.data.fileList.map((e) => {
            return {
              ...e,
              src: `/api/v1/fo/preview/${e.src}`,
              fileName: e.src,
            };
          });

          if (result.data.rsvMessageInfo.rptYn == "Y") {
            this.$refs.sendTime.fnInit("P", result.data.rsvMessageInfo, result.data.rsvMessageDetail);
          } else {
            this.$refs.sendTime.fnInit("R", result.data.rsvMessageInfo, result.data.rsvMessageDetail);
          }

          this.recvInfoList = result.data.sendRsvMessageTgtList.map((e) => {
            return {
              ...e,
              phone: this.$gfnCommonUtils.hpNumberAddDash(e.phone),
              status: "",
              validator: true,
              selected: false,
              ...JSON.parse(e.var5),
            };
          });
        } else {
          confirm.fnAlert("", "오류가 발생하였습니다.", "alert");
        }
      });
    },
    fnMessageContentCut() {
      let textLength = 0;
      if (this.reqProduct === "SMS") {
        textLength = 90;
      } else {
        textLength = 2000;
      }
      this.msgReadonly = false;
      this.msg = this.$gfnCommonUtils.cutStr(this.$refs.sendMessageContent.value, textLength - (this.ad ? this.adByte : 0));
      this.textByte = this.$gfnCommonUtils.getByte(this.msg);
      this.$refs.sendMessageContent.focus();
    },
    fnreqProductChange() {
      this.msgReadonly = false;
      this.reqProductChange = false;
      this.$refs.sendMessageContent.focus();
    },
    // 발신번호 추가 버튼 버튼 누를 경우 우측 미리보기 화면으로 등록
    callbackNumberAdd(e) {
      if (e.target.value === "add") {
        this.$router.push({ path: "/sendNumberManage/sendNumberReg" });
      }
      //confirm.fnAlert("페이지이동", "발신자 추가 페이지로 이동 (작업중)", "alert");
    },
    getToday() {
      let nowday = new Date();
      const WEEKDAY = ["일요일", "월요일", "화요일", "수요일", "목요일", "금요일", "토요일"];
      let week = WEEKDAY[nowday.getDay()];
      let month = nowday.getMonth() + 1;
      let day = nowday.getDate();
      this.today = month + "월 " + day + "일 " + week;
    },

    //한글 동기화
    changeTitle(e) {
      this.title = this.$gfnCommonUtils.cutStr(e.target.value, 30);
      e.target.value = this.title;
      this.titleByte = this.$gfnCommonUtils.getByte(this.title);
    },
    changeContent(e) {
      this.msg = e.target.value;
      if(this.msg != ""){
        if(this.ad){
          this.textByte = this.$gfnCommonUtils.getByte(e.target.value)+2;
        }else{
          this.textByte = this.$gfnCommonUtils.getByte(e.target.value);  
        }
      }else{
        this.textByte = this.$gfnCommonUtils.getByte(e.target.value);
      }
    },
    modiChangeTitle(msg) {
      if (msg) {
        this.title = this.$gfnCommonUtils.cutStr(msg, 30);
        this.titleByte = this.$gfnCommonUtils.getByte(this.title);
      }
    },
    modiChangeContent(msg) {
      if (msg) {
        this.msg = msg;
        this.textByte = this.$gfnCommonUtils.getByte(this.msg);
      }
    },
    changeUpContent(msg) {
      this.textByte = this.$gfnCommonUtils.getByte(msg);
    },
    //페이지 이동
    pageGo(url) {
      const history = "/messagesend/" + url;
      this.$router.push(history);
    },
    // 특수 문자 추가
    insertCharacters(character) {
      const chLen = character.length;
      let txtArea = this.$refs.sendMessageContent;
      let txtValue = txtArea.value;
      this.selectPos = txtArea.selectionStart !== 0 ? txtArea.selectionStart : this.selectPos; // 커서 위치 지정
      let beforeTxt = txtValue.substring(0, this.selectPos); // 기존텍스트 ~ 커서시작점 까지의 문자
      this.selectPos = this.selectPos + chLen;
      let afterTxt = txtValue.substring(this.selectPos - chLen, txtValue.length); // 커서끝지점 ~ 기존텍스트 까지의 문자
      let addTxt = character; // 추가 입력할 텍스트
      txtArea.value = beforeTxt + addTxt + afterTxt;
      this.msg = txtArea.value;
      this.textByte = this.$gfnCommonUtils.getByte(txtArea.value);
    },
    // 발신번호 조회
    fnSelectCallbackList() {
      sendManageApi.selectCallbackList({}).then((response) => {
        if (response.data.success) {
          //confirm.fnAlert("발신번호 조회 ", "성공", "message");
        } else {
          // confirm.fnAlert("발신번호 가져오기", "가 실패되었습니다.", "alert");
          confirm.fnAlert("", "발신번호 가져오기가 실패되었습니다.", "alert");
        }
      });
    },
    // 이미지 업로드 팝업에서 데이터 가져오기
    setImageInfo(value) {
      this.fileList = value;
    },
    // 미리전송 주소록 가져오기
    preSendInfo(value) {
      this.preRecvInfoList = value;
    },
    // 특수문자, 변수 slide
    specialOn() {
      var btnSpecial = document.querySelector(".btn_special"),
        btnVari = document.querySelector(".btn_vari"),
        specailCont = document.querySelector(".special_char"),
        variCont = document.querySelector(".vari_txt");
      btnSpecial.classList.toggle("on");
      specailCont.classList.toggle("on");
      btnVari.classList.remove("on");
      variCont.classList.remove("on");
    },
    variOn() {
      var btnSpecial = document.querySelector(".btn_special"),
        btnVari = document.querySelector(".btn_vari"),
        specailCont = document.querySelector(".special_char"),
        variCont = document.querySelector(".vari_txt");
      btnVari.classList.toggle("on");
      variCont.classList.toggle("on");
      btnSpecial.classList.remove("on");
      specailCont.classList.remove("on");
    },
    //체크박스 전체 선택 기능
    checkedAll(checked) {
      if (this.recvInfoList.length > 0) {
        this.allChecked = checked;
        for (let i in this.recvInfoList.slice(0, 100)) {
          this.recvInfoList[i].selected = this.allChecked;
        }
      }
    },
    //체크박스 단일 체크
    selected() {
      if (this.recvInfoList.every((item) => item.selected == true)) {
        this.allChecked = true;
      } else {
        this.allChecked = false;
      }
    },
    //모달창
    ModalOpen(target) {
      var dimmed = document.getElementsByClassName("dimmed");
      var wrap = document.getElementsByClassName("popup-wrap");
      var obj = document.getElementsByClassName(target);
      obj[0].classList.add("open");
      dimmed[0].style.display = "block";
      wrap[0].style.display = "block";
      obj[0].style.display = "block";
      this.ModalHeight();
      if (target == "address") {
        this.$refs.sendAddr.fnSendAddrInit();
      }

      if (target == "img_pop") {
        this.updateFileList = Object.assign([], this.fileList);
      }
    },
    ModalClose() {
      var dimmed = document.getElementsByClassName("dimmed");
      var wrap = document.getElementsByClassName("popup-wrap");
      var obj = wrap[0].childElementCount;
      dimmed[0].style.display = "none";
      wrap[0].style.display = "none";
      for (var i = 0; i < obj; i++) {
        var target = document.getElementsByClassName("popup");
        target[i].style.display = "none";
        target[i].classList.remove("open");
      }
    },
    ModalHeight() {
      //팝업 heght가 device height보다 클때
      var winH = window.innerHeight,
        obj = document.querySelector(".popup.open"),
        objH = obj.clientHeight;
      if (objH >= winH) {
        obj.classList.add("over");
      }
    },
    //미리보기 발신화면 최근작성문자 토글 설정
    mPreEvent(toggle) {
      if (toggle) {
        this.preEventToggle = true;
      } else {
        this.preEventToggle = false;
      }
    },
    // 모바일 미리보기
    mPreAlimOn() {
      const PrevAlim = document.querySelectorAll(".preview_swiper");
      const SMStalk = document.querySelector(".m_preview_alimtalk .sms_preview");
      const dimmed = document.querySelector(".dimmed");
      const mAlimPreview = document.querySelector(".m_preview_alimtalk");
      PrevAlim.forEach((item) => (item.style.display = "none"));
      SMStalk.style.display = "block";
      dimmed.classList.add("open");
      mAlimPreview.classList.add("on");
    },
    mHisAlimOn() {
      const PrevAlim = document.querySelectorAll(".preview_swiper");
      const SMStalk = document.querySelector(".m_preview_alimtalk .sms_history");
      const dimmed = document.querySelector(".dimmed");
      const mAlimPreview = document.querySelector(".m_preview_alimtalk");
      PrevAlim.forEach((item) => (item.style.display = "none"));
      SMStalk.style.display = "block";
      dimmed.classList.add("open");
      mAlimPreview.classList.add("on");
    },
    mHisOn() {
      const mPop = document.querySelector(".m_preview_wrap"),
        dimmed = document.querySelector(".dimmed"),
        btnSent = document.getElementById("btn_sent"),
        btnHistory = document.getElementById("btn_history");
      dimmed.classList.add("open");
      mPop.classList.add("on");
      btnSent.checked = false;
      btnHistory.checked = true;
      this.mPreEvent(false);
    },
    mPreClose() {
      const mPop = document.querySelector(".m_preview_wrap"),
        dimmed = document.querySelector(".dimmed");
      mPop.classList.remove("on");
      dimmed.classList.remove("open");
    },
    fnSendAddrInfoSet(addrMember) {
      this.recvInfoList.push(...addrMember);
      this.ModalClose();
    },
    fnExcelAddrinfoSet(excelAddrMember) {
      this.recvInfoList.push(...excelAddrMember);
      this.ModalClose();
    },
    // 휴대폰 번호 유효성 체크 항목
    errorCheck(phoneNumber, data) {
      const patten_phone = /^(01[016789]{1}|070)-?[0-9]{3,4}-?[0-9]{4}$/; //핸드폰 번호, 하이픈 상관 X
      const pattern_eng = /[a-zA-Z]/; // 문자
      const pattern_spc = /[~!@#$%^&*()_+|<>?:{}]/; // 특수문자
      const pattern_kor = /[ㄱ-ㅎ|ㅏ-ㅣ|가-힣]/; // 한글체크
      let errorText = "";

      const rejectPhone = this.rejectPhoneList.some((e) => {
        return this.$gfnCommonUtils.hpNumberRemoveDash(e.rcvblckno) === this.$gfnCommonUtils.hpNumberRemoveDash(phoneNumber);
      });

      if (rejectPhone) {
        errorText = "080 수신거부번호";
        this.validator = false;
      } else if (phoneNumber == "" || phoneNumber == undefined) {
        errorText = "번호없음";
        this.validator = false;
        return errorText;
      } else if (pattern_eng.test(phoneNumber) || pattern_spc.test(phoneNumber) || pattern_kor.test(phoneNumber)) {
        errorText = "번호오류";
        this.validator = false;
        return errorText;
      } else if (!patten_phone.test(phoneNumber)) {
        errorText = "번호오류";
        this.validator = false;
        return errorText;
      } else if (this.hashNum.has(phoneNumber.replace(/-/g, ""))) {
        errorText = "번호중복";
        this.validator = false;
        return errorText;
      } else {
        // 자동 변경 정보 체크
        let acText = [];
        this.autoMsg.forEach((element) => {
          if (!data[element]) {
            acText.push(element);
          }
        });

        if (!this.$gfnCommonUtils.isEmpty(acText)) {
          errorText = `자동변경정보 누락 (${acText.join(", ")})`;
          this.validator = false;
          return errorText;
        }

        // 자동변경정보를 통한 byte 계산
        // const pattern = /#\{([^#{}]*)\}/g;
        let autoMsgByte = 0;
        let autoMsgText = "";

        autoMsgText = this.msg.replace(/((#{).*?(\}))/g, (match) => {
          const matches = /#\{([^#{}]*)\}/g.exec(match);
          return data[matches[1]];
        });

        if (this.ad) {
          autoMsgByte = this.$gfnCommonUtils.getByte(autoMsgText) + this.adByte;
        } else {
          autoMsgByte = this.$gfnCommonUtils.getByte(autoMsgText);
        }

        // 문자타입이 SMS이고 90Byte 보다 클 경우 에러 처리
        if (this.reqProduct === "SMS" && autoMsgByte > 90) {
          errorText = `자동 변경 정보 삽입으로<br/>90Byte 초과(${autoMsgByte}Byte)`;
          this.validator = false;
          return errorText;
        }

        // 문자타입이 LMS , MMS 이고 2000byte 보다 클 경우 에러 처리
        if ((this.reqProduct === "LMS" || this.reqProduct === "MMS") && autoMsgByte > 2000) {
          errorText = `자동 변경 정보 삽입으로<br/>2000Byte 초과(${autoMsgByte}Byte)`;
          this.validator = false;
          return errorText;
        }

        errorText = "정상";
        this.validator = true;
        this.hashNum.set(phoneNumber.replace(/-/g, ""), "phone");
      }

      return errorText;
    },
    //주소록 선택삭제
    fnAddrSelectedDel() {
      if (
        this.recvInfoList.every((e) => {
          return e.selected === false;
        })
      ) {
        // confirm.fnAlert("주소록 선택", "선택된 주소록이 없습니다.", "alert");
        confirm.fnAlert("", "선택된 주소록이 없습니다.", "alert");
      } else {
        eventBus.$on("callbackEventBus", this.fnRemoveSelectedAddress);
        // confirm.fnConfirm("수신번호 선택 삭제", "선택한 수신번호를<br/>삭제하시겠어요?", "del");
        confirm.fnConfirm("", "선택한 수신번호를<br/>삭제하시겠어요?", "del");
      }
    },
    fnRemoveSelectedAddress() {
      this.recvInfoList = this.recvInfoList.filter((item) => {
        return item.selected === false; // 체크 안된 리스트
      });
      this.allChecked = false;
    },
    //주소록 전체삭제
    fnAddrAllDel() {
      if (this.recvInfoList.length > 0) {
        eventBus.$on("callbackEventBus", this.fnRemoveAllAddress);
        // confirm.fnConfirm("수신번호 전체 삭제", "수신번호 전체를<br/>삭제하시겠어요?", "del", "!");
        confirm.fnConfirm("", "수신번호 전체를<br/>삭제하시겠어요?", "del");
      } else {
        // confirm.fnAlert("주소록 선택", "주소록이 존재하지 않습니다.", "alert");
        confirm.fnAlert("", "주소록이 존재하지 않습니다.", "alert");
      }
      this.allChecked = false;
    },
    // 선택된 주소록 삭제
    fnRemoveAllAddress() {
      this.recvInfoList = [
        {
          phone: "",
          name: "",
          var1: "",
          var2: "",
          var3: "",
          var4: "",
          status: "",
          validator: false,
          selected: false,
          selfAdd: true,
        },
      ];
    },
    // 반복예약설정 옵션 설정시 노출 여부함수

    handleRemove(index) {
      let fileSizeCur = 0;
      this.fileList.forEach((file) => {
        fileSizeCur += file.size;
      });

      this.totalImagefileSize = fileSizeCur;
      this.totalImagefileSize = this.totalImagefileSize - this.fileList[index].size;

      /* this.orgFileList.forEach((file) => {
        if (file.src == this.fileList[index].src) {
          file.status = "D";
        }
      }); */
      this.fileList.splice(index, 1);
    },
    //준비중입니다.
    fnReady() {
      // confirm.fnAlert("알림", "준비중입니다.", "alert");
      confirm.fnAlert("", "준비중입니다.", "alert");
    },
    fnToastMessage(msg) {
      const content = {
        component: ToastComponentVue,
        props: {
          msg,
        },
      };

      this.$toast(content, {
        position: "bottom-right",
        timeout: 3000,
        closeOnClick: true,
        pauseOnFocusLoss: true,
        pauseOnHover: true,
        draggable: true,
        draggablePercent: 0.5,
        showCloseButtonOnHover: false,
        hideProgressBar: true,
        closeButton: "button",
        icon: false,
        rtl: false,
        toastClassName: "toast",
      });
    },
    async fnMessageSend(type) {
      this.$store.commit("startSpinner");
      // 전송 전 차단발신번호 확인
      let callChk = "N";
      const params = {
        sndrno: this.$gfnCommonUtils.hpNumberRemoveDash(this.callbackNumber),
      };

      await messageSendApi.blckCallerId(params).then((response) => {
        if (response.data.success) {
          if (Number(response.data.data) > 0) {
            callChk = "Y";
          }
        }
      });

      if (callChk === "Y") {
        confirm.fnAlert("", "차단된 번호는 발신이 불가합니다.", "alert");
        return;
      }

      if (this.totalCount > 20000) {
        confirm.fnAlert("", "최대 전송할 수 있는 건수가 초과되어 전송이 불가합니다.", "alert");
        return;
      }

      if (!this.modiAuth) {
        confirm.fnAlert("", "예약 메시지는 전송 5분전에는 취소가 불가합니다.", "alert");
        return false;
      }

      const validator = this.fnValidator(type);

      if (validator.msg) {
        if (validator.targetFocus) {
          eventBus.$on("callbackEventBus", () => {
            document.getElementById(validator.targetFocus).focus();
          });
          confirm.fnConfirm(validator.title, validator.msg, "cbAlert");
          this.$store.commit("endSpinner");
        } else {
          confirm.fnAlert(validator.title, validator.msg, "alert");
          this.$store.commit("endSpinner");
        }
        return;
      }

      if (type === "sendpop") {
        //중복 번호 체크 초기화
        this.hashNum = new Map();
        this.recvInfoList = this.recvInfoList
          .filter((e) => {
            return e.phone !== "";
          })
          .map((e, index) => {
            return {
              ...e,
              status: this.errorCheck(e.phone, { ...e }),
              validator: this.validator,
              index,
            };
          });

        const preAmountParams = {
          channelDiv: this.reqProduct, // 전송 채널(SMS, LMS, MMS, ATLK)
          preSendingCnt: this.recvInfoList.filter((e) => e.validator === true).length, // 예상 전송 건수
          repSendingYN: "N", // 대체 전송 여부 (메시지 전송이기 떄문에 항시 N)
          repSendingChnnel: "", // 대체 전송 채널(SMS, LMS, MMS, ATLK)
        };

        await this.fnGetSendLimit(preAmountParams); // 전송 한도 조회

        if (!this.sendLimit) {
          confirm.fnAlert("", "전송 한도가 초과되어 메시지를 보낼 수 없습니다.", "alert");
          return;
        }

        await this.$refs.sendConfirm.fnGetPreAmount(preAmountParams); // 예상 차감 금액
      } else {
        this.ModalOpen(type);
        this.$store.commit("endSpinner");
      }
    },
    // 전송 한도 조회
    async fnGetSendLimit(param) {
      await messageSendApi
        .getSendLimit(param)
        .then((response) => {
          if (response.data.success) {
            if (response.data.data.list[0].limitAvailableYN == "Y") {
              this.sendLimit = false;
            } else {
              this.sendLimit = true;
            }
            this.sendLimit = response.data.data.list[0].limitAvailableYN === "Y" ? true : false;
          } else {
            eventBus.$on("callbackEventBus", this.ModalClose);
            confirm.fnConfirm("", "전송한도금액 가져오기에 실패하였습니다", "cbAlert");
          }
        })
        .catch((error) => {
          console.log(error);
          eventBus.$on("callbackEventBus", this.ModalClose);
          confirm.fnConfirm("", "전송한도금액 가져오기에 실패하였습니다", "cbAlert");
        });
    },
    fnSelectRecentSendGroup() {
      const params = {
        userSeq: "",
      };

      sendManageApi.selectRecentSendGroup(params).then((response) => {
        if (response.data.success) {
          this.recentSendGroupList = response.data.data;
        } else {
          // confirm.fnAlert("최신전송그룹", "해당 전송그룹 가져오기에 실패하였습니다.", "alert");
          confirm.fnAlert("", "해당 전송그룹 가져오기에 실패하였습니다.", "alert");
        }
      });
    },
    fnSelectRecentSendRecvInfo(webReqId) {
      const params = {
        webReqId,
      };

      sendManageApi.selectRecentSendRecvInfo(params).then((response) => {
        if (response.data.success) {
          this.recvInfoList = response.data.data.map((e) => {
            const mergeData = e.mergeData ? JSON.parse(e.mergeData) : "";
            return {
              phone: this.$gfnCommonUtils.hpNumberAddDash(e.phone),
              name: mergeData ? mergeData.name : "",
              var1: mergeData ? mergeData.var1 : "",
              var2: mergeData ? mergeData.var2 : "",
              var3: mergeData ? mergeData.var3 : "",
              var4: mergeData ? mergeData.var4 : "",
              validator: true,
              status: "",
              selected: false,
            };
          });
        } else {
          // confirm.fnAlert("최신전송그룹 리스트", "해당 전송그룹 리스트 가져오기에 실패하였습니다.", "alert");
          confirm.fnAlert("", "해당 전송그룹 리스트 가져오기에 실패하였습니다.", "alert");
        }
      });
    },
    //발신번호 목록 조회
    fnCallerIdList() {
      const params = {
        sttusCd: "02", // 승인된 발신번호만 가져오기
        useYn: "Y",
        sndrnoCombo: "Y",
      };

      sendManageApi.callerIdList(params).then((response) => {
        if (response.data.success) {
          this.sendNumberList = response.data.data;
        }
      });
    },
    // 전송시각 데이터 가져오기
    fnSetSendTime(value) {
      this.sendTimeData = value;
    },
    getAdByte() {
      this.adByte =
        this.enterByte +
        this.$gfnCommonUtils.getByte(this.adBottom) +
        this.$gfnCommonUtils.getByte(this.authNumber);
    },
    // 주소록 직접입력추가
    fnDirectAddAddress() {
      const obj = {
        phone: "",
        name: "",
        var1: "",
        var2: "",
        var3: "",
        var4: "",
        status: "",
        validator: "",
        selected: false,
      };
      this.recvInfoList.unshift(obj);
    },
    // 최근작성문자 가져오기
    fnRecentSendMessageList(msg) {
      this.title = this.$gfnCommonUtils.cutStr(msg.title ? msg.title : "", 30);
      if(this.ad){
        if(msg.ad == "true"){
          this.msg = msg.content ? msg.content.replaceAll("\r\n", "\n") : "";
        }else{
          this.msg = msg.content ? "(광고)"+msg.content.replaceAll("\r\n", "\n") : "";
          this.ad = false;
        }
      }else{
        if(msg.ad == "true"){
          this.msg = msg.content ? msg.content.replace("(광고)", "").replaceAll("\r\n", "\n") : "";
          this.ad = true;
        }else{
          this.msg = msg.content ? msg.content.replaceAll("\r\n", "\n") : "";
        }
      } 
      const fileName = msg.fileName ? msg.fileName.split(",") : "";
      this.fileList = msg.legacyFiles
        ? msg.legacyFiles
            .split(",")
            .filter((file) => file !== "")
            .map((file, index) => {
              return {
                name: fileName !== "" ? fileName[index] : file,
                src: `/api/v1/fo/preview/${file}`,
                fileName: file,
              };
            })
        : [];
      // 바이트 계산
      this.titleByte = this.$gfnCommonUtils.getByte(this.title);
      this.textByte = this.$gfnCommonUtils.getByte(this.msg) + 2;
      this.adByte =
        this.enterByte +
        this.$gfnCommonUtils.getByte(this.adBottom) +
        this.$gfnCommonUtils.getByte(this.authNumber);
      const btnSent = document.getElementById("btn_sent");
      this.mPreEvent(true);
      btnSent.checked = true;
    },
    fnValidator() {
      let title = "";
      let msg = "";
      let targetFocus = "";
      // 월말/월초 23:30~00:30 사이 전송 시 F-1 안내 얼랏
      let nDate = new Date();
      const today = Number(this.getCurrentDate());
      nDate.setDate(1);
      const monthStart = this.getDateFormat(nDate, "s");
      nDate.setMonth(nDate.getMonth() + 1);
      nDate.setDate(nDate.getDate() - 1);
      const monthEnd = this.getDateFormat(nDate, "e");

      // 현재 시간 15분 이후로 예약전송이 가능 처리
      let reqDtYn = false;
      if (this.sendTimeData.rsvtType === "N") {
        reqDtYn = true;
      } else if (this.sendTimeData.rsvtType === "R") {
        const reqDt = new Date(this.sendTimeData.reqDt + " " + this.sendTimeData.rsvCfg3.substring(0, 2) + ":" + this.sendTimeData.rsvCfg3.substring(2, 4) + ":00");
        let rToday = new Date();
        rToday.setMinutes(rToday.getMinutes() + 5);
        if (reqDt > rToday) {
          reqDtYn = true;
        }
      } else if (this.sendTimeData.rsvtType === "P") {
        const rsvDt = new Date(this.sendTimeData.rsvStart + " " + this.sendTimeData.rsvCfg3.substring(0, 2) + ":" + this.sendTimeData.rsvCfg3.substring(2, 4) + ":00");
        let pToday = new Date();
        pToday.setMinutes(pToday.getMinutes() + 5);
        if (rsvDt > pToday) {
          reqDtYn = true;
        }
      }

      //반복예약설정시 현재날짜보다 6개월 이상 일 경우 전송 안되도록 설정
      let AfterSixMonthDateYN = true;
      if (this.sendTimeData.rsvtType === "P") {
        const startDate = new Date(this.sendTimeData.rsvStart);
        const endDate = new Date(this.sendTimeData.rsvEnd);
        const AfterSixMonthDate = new Date(startDate.setMonth(startDate.getMonth() + 6));
        if (endDate > AfterSixMonthDate) {
          AfterSixMonthDateYN = false;
        }
      }
      /////////////////////////

      if (!this.msg) {
        // title = "내용 확인";
        title = "";
        msg = "[내용]을 작성해 주세요.";
        targetFocus = "sendMessageContent";
      } else if (this.totalCount < 1) {
        // title = "수신번호 확인";
        title = "";
        msg = "[수신번호]를 작성해 주세요.";
        targetFocus = "recentSendGroup";
      } else if (this.callbackNumber === "null" || this.callbackNumber === "add") {
        // title = "발신번호 확인";
        title = "";
        msg = "[발신번호]를 선택해 주세요.";
        targetFocus = "callbackNumber";
      } else if (today < monthStart || today > monthEnd) {
        // title = "지금은 서비스 점검 시간입니다.";
        title = "";
        msg = "지금은 서비스 점검 시간입니다.<br/>잠시 후 다시 이용해 주세요. 이용에 불편을 드려 죄송합니다.<br/>- 서비스 점검 시간 : 오후 11시 30분 ~ 밤 12시 30분";
      } else if (!AfterSixMonthDateYN) {
        // title = "예약전송 시간 선택";
        title = "";
        msg = "※ 반복예약 시작 날짜부터 최대 6개월까지<br/> 날짜만 예약전송이 가능합니다.";
      } else if (!reqDtYn) {
        // title = "예약전송 시간 선택";
        title = "";
        msg = "※ 현재 시간을 기준으로 5분<br/> 이후부터 예약전송이 가능합니다.";
      } else if (this.sendTimeData.rsvtType === "P") {
        if (!this.sendTimeData.rsvEnd) {
          // title = "반복예약전송 확인";
          title = "";
          msg = "[반복예약전송 날짜]를 선택해 주세요.";
        }
        if (this.sendTimeData.rsvCfg1 === "D") {
          if (!this.sendTimeData.rsvCfg2) {
            // title = "반복예약전송 확인";
            title = "";
            msg = "[매일 반복설정]을 작성해 주세요.";
          }
        } else if (this.sendTimeData.rsvCfg1 === "W") {
          const mada = this.sendTimeData.rsvCfg2 && this.sendTimeData.rsvCfg2.split("|");
          const rsvCfg2dayWeekLength = this.sendTimeData.rsvCfg2dayWeek.length;
          if (!mada || !mada[0] || rsvCfg2dayWeekLength === 0) {
            // title = "반복예약전송 확인";
            title = "";
            msg = "[매주 반복설정]을 작성해 주세요.";
          }
        } else if (this.sendTimeData.rsvCfg1 === "M") {
          const mada = this.sendTimeData.rsvCfg2 && this.sendTimeData.rsvCfg2.split("|");
          if (!mada || !mada[0] || !mada[1]) {
            // title = "반복예약전송 확인";
            title = "";
            msg = "[매월 반복설정]을 작성해 주세요.";
          }
        } else if (this.subSttusCd === "04") {
          title = "";
          msg = "요금을 2개월 이상 내지 않아 메시지를 보낼 수 없습니다.<br/>요금을 납부한 후 이용해 주세요.";
        }
      }
      return { title, msg, targetFocus };
    },
    getDateFormat(date, type) {
      let newDate = new Date();
      if (date != null) newDate = date;

      let yy = String(newDate.getFullYear());
      let mm = newDate.getMonth() + 1;
      if (mm < 10) mm = "0" + mm;

      let dd = newDate.getDate();
      if (dd < 10) dd = "0" + dd;

      let time = "000000";
      if (type === "s") {
        time = "003000";
      } else {
        time = "233000";
      }
      return Number(yy + mm + "" + dd + time);
    },
    getCurrentDate() {
      let date = new Date();
      let year = date.getFullYear().toString();

      let month = date.getMonth() + 1;
      month = month < 10 ? "0" + month.toString() : month.toString();

      let day = date.getDate();
      day = day < 10 ? "0" + day.toString() : day.toString();

      let hour = date.getHours();
      hour = hour < 10 ? "0" + hour.toString() : hour.toString();

      let minites = date.getMinutes();
      minites = minites < 10 ? "0" + minites.toString() : minites.toString();

      let seconds = date.getSeconds();
      seconds = seconds < 10 ? "0" + seconds.toString() : seconds.toString();

      return year + month + day + hour + minites + seconds;
    },
    fnPhoneDash(phoneNumber, index) {
      this.recvInfoList[index].phone = this.$gfnCommonUtils.hpNumberAddDash(this.$gfnCommonUtils.cutStr(phoneNumber.target.value.replace(/[^-0-9]/g, ""), 13));
      const pattern_notNumber = /[^-0-9]/g; // 숫자가 아닌것 체크
      // 숫자 외 입력시
      if (pattern_notNumber.test(phoneNumber.target.value)) {
        this.fnToastMessage("숫자만 입력해 주세요.");
        phoneNumber.target.value = this.recvInfoList[index].phone;
      }
    },
    // 최근작성문자 가져오기
    fnSelectRecentSendMessageList() {
      const params = {
        userSeq: "",
        recentOffset: 10,
      };
      messageSendApi.selectRecentSendMessageList(params).then((response) => {
        if (response.data.success) {
          this.recentSendMessageList = response.data.data
            .filter((item) => {
              return JSON.parse(item.msgInfo).reqProduct !== "KKO_NOTI";
            })
            .map((item) => {
              item.regDt = item.regDt.substring(0, 10) + "(" + item.regDt.substring(11, 16) + ")";
              return {
                rsvId: item.rsvId,
                regDt: item.regDt,
                ad: item.msgInfo ? JSON.parse(item.msgInfo).ad : "",
                adBottom: item.msgInfo ? JSON.parse(item.msgInfo).adBottom : "",
                authNumber: item.msgInfo ? JSON.parse(item.msgInfo).authNumber : "",
                callbackNumber: item.msgInfo ? JSON.parse(item.msgInfo).callbackNumber : "",
                content: item.msgInfo ? JSON.parse(item.msgInfo).content : "",
                dayOfTheWeek: item.msgInfo ? JSON.parse(item.msgInfo).dayOfTheWeek : "",
                dayOfTheWeekNum: item.msgInfo ? JSON.parse(item.msgInfo).dayOfTheWeekNum : "",
                dayOfTheWeekStr: item.msgInfo ? JSON.parse(item.msgInfo).dayOfTheWeekStr : "",
                fileList: item.msgInfo ? JSON.parse(item.msgInfo).fileList : "",
                fileName: item.msgInfo ? JSON.parse(item.msgInfo).fileName : "",
                legacyFiles: item.legacyFiles ? item.legacyFiles : "",
                mada: item.msgInfo ? JSON.parse(item.msgInfo).mada : "",
                monDiv: item.msgInfo ? JSON.parse(item.msgInfo).monDiv : "",
                msg: item.msgInfo ? JSON.parse(item.msgInfo).msg : "",
                reqProduct: item.msgInfo ? JSON.parse(item.msgInfo).reqProduct : "",
                rsvCfg1: item.msgInfo ? JSON.parse(item.msgInfo).rsvCfg1 : "",
                rsvCfg2dayWeek: item.msgInfo ? JSON.parse(item.msgInfo).rsvCfg2dayWeek : "",
                title: item.msgInfo ? JSON.parse(item.msgInfo).title : "",
                tomada: JSON.parse(item.msgInfo).tomada,
                trafficType: item.msgInfo ? JSON.parse(item.msgInfo).trafficType : "",
              };
            });
        }
      });
    },
    fnRejectPhoneList() {
      const params = {
        userTpCd: "",
      };
      messageSendApi.rejectPhoneList(params).then((response) => {
        if (response.data.success) {
          const getData = response.data.data;
          if (Object.keys(getData).includes("rejectPhoneList")) {
            if (!this.$gfnCommonUtils.isEmpty(getData.rejectPhoneList)) {
              this.rejectPhoneList = getData.rejectPhoneList;
            } else {
              this.rejectPhoneList = [];
            }
          } else {
            this.rejectPhoneList = [];
          }
        } else {
          confirm.fnAlert("", "오류가 발생했습니다.", "alert");
        }
      });
    },
    /////////////////////////////////// 파일 첨부 ////////////////////////////////////////
    onFileChange(event) {
      const files = event.target.files || event.dataTransfer.files;
      if (!files.length) return;
      if (files.length > 3) {
        confirm.fnAlert(
          // "이미지 첨부 기준 안내",
          "",
          "<li>최대 3장 첨부할 수 있습니다.</li>",
          "info"
        );
        this.imageAddTitle = "이미지는 최대 3장까지 첨부할 수 있습니다.";
        return;
      } else if (this.updateFileList.length + files.length > 3) {
        confirm.fnAlert(
          // "이미지 첨부 기준 안내",
          "",
          "<li>최대 3장 첨부할 수 있습니다.</li>",
          "info"
        );
        this.imageAddTitle = "이미지는 최대 3장까지 첨부할 수 있습니다.";
        return;
      }

      this.addFiles(files);
    },
    async addFiles(files) {
      for (let i = 0; i < files.length; i++) {
        const src = await this.readFiles(files[i]);
        files[i].src = src;

        if (!(files[i].name.toLowerCase().indexOf("jpg") > -1 || files[i].name.toLowerCase().indexOf("jpeg") > -1)) {
          confirm.fnAlert("", "<li>jpg파일 형식만 첨부할 수 있습니다.</li>", "info");
          this.imageAddTitle = "jpg파일 형식만 첨부할 수 있습니다.";
          continue;
        } else if (files[i].size > 300000) {
          confirm.fnAlert(
            // "이미지 첨부 기준 안내",
            "",
            "<li>전체 크기 합계가 300KB 이하여야 합니다.</li>",
            "info"
          );
          this.imageAddTitle = "전체 크기 합계가 300KB 이하여야 합니다.";
          continue;
        } else if (files[i].size + this.totalFileSize > 300000) {
          confirm.fnAlert(
            // "이미지 첨부 기준 안내",
            "",
            "<li>전체 크기 합계가 300KB 이하여야 합니다.</li>",
            "info"
          );
          this.imageAddTitle = "전체 크기 합계가 300KB 이하여야 합니다.";
          continue;
        } else if (this.getImageSize(src) === false) {
          confirm.fnAlert(
            // "이미지 첨부 기준 안내",
            "",
            "<li>1,500(가로)x1,440(세로)px 이하 크기만 첨부할 수 있습니다.</li>",
            "info"
          );
          this.imageAddTitle = "1,500(가로)x1,440(세로)px 이하 크기만 첨부할 수 있습니다.";
          continue;
        } else {
          files[i].status = "update";
          files[i].index = this.updateFileList.length;
          this.updateFileList.push(files[i]);
          confirm.fnAlert("", "이미지가 정상적으로 추가 되었습니다.", "alert");
          this.imageAddTitle = "";
        }
      }
      this.$refs.imageUploader.value = ""; // 이미지 중복 가능하도록 input 초기화
    },
    // FileReader를 통해 파일을 읽어 thumbnail 영역의 src 값으로 셋팅
    async readFiles(files) {
      return new Promise((resolve) => {
        const reader = new FileReader();
        reader.onload = async (e) => {
          resolve(e.target.result);
        };
        reader.readAsDataURL(files);
      });
    },
    getImageSize(src) {
      const img = new Image();
      let _width = 0;
      let _height = 0;
      img.src = src;

      img.onload = function () {
        _width = img.width;
        _height = img.height;
        if (_width > 1500 || _height > 1440) {
          return false;
        } else {
          return true;
        }
      };
    },
    fnSendData() {
      this.fileList = Object.assign([], this.updateFileList);
      this.fileModalClose();
      this.$forceUpdate();
    },
    fileModalClose() {
      var dimmed = document.getElementsByClassName("dimmed");
      var wrap = document.getElementsByClassName("popup-wrap");
      var obj = wrap[0].childElementCount;
      dimmed[0].style.display = "none";
      wrap[0].style.display = "none";
      for (var i = 0; i < obj; i++) {
        var target = document.getElementsByClassName("popup");
        target[i].style.display = "none";
        target[i].classList.remove("open");
      }
      this.updateFileList = [];
    },
    popupHandleRemove(index) {
      this.updateFileList.splice(index, 1);
    },
    /*handleScroll(e) {
      const chooseListThWrap = e.target.parentNode.children[0];
      chooseListThWrap.scrollLeft = e.target.scrollLeft;
    },
    handleThScroll(e) {
      const chooseListWrap = e.target.parentNode.children[1];
      chooseListWrap.scrollLeft = e.target.scrollLeft;
    },*/
    resizeText(e) {
      e.target.style.height = `${e.target.scrollHeight}px`;
    },
    fnGoList() {
      eventBus.$on("callbackEventBus", () => {
        this.$router.push({ path: "/sendManage/rsvSendMessage" });
      });
      confirm.fnConfirm("알림", "페이지 이동시 모든 내용이 삭제됩니다.", "confrim");
    },
    tableWidthCalc() {
      let myRef = [];

      this.autoMsg.forEach((e, index) => {
        if (index === 0) {
          myRef.push("td_name");
        } else {
          myRef.push("td_" + index);
        }
      });

      setTimeout(() => {
        myRef.forEach((e) => {
          if (this.autoMsg.length === 1) {
            document.getElementById(e).style.width = "47%";
          } else if (this.autoMsg.length === 2) {
            document.getElementById(e).style.width = "23.5%";
          } else if (this.autoMsg.length === 3) {
            document.getElementById(e).style.width = "16%";
          } else {
            document.getElementById(e).style.width = "120px";
          }
        });
      }, 1);
    },
    fnRemoveDefaultAutoMsg() {
      this.msg = this.msg.replace("#{phone}", "");
      this.textByte = this.$gfnCommonUtils.getByte(this.msg);
    },
  },
};
</script>
