import httpClient from "@/common/http-client";

// 서버 api 호출하는 부분. 컨트롤러에 정의된 부분, 호출 할 일 있을 때 하위에 계속 추가해주면 된다.
const selectRejectPhoneList = (params) => {
  return httpClient.post('/api/ars080reject/rejectPhoneList', params, { headers: { "show-layer": "Yes"} });
};

const deleteRejectPhoneList = (params) => {
  return httpClient.post('/api/ars080reject/deleteRejectPhone', params, { headers: { "show-layer": "Yes"} });
};

const insertRejectPhone = (params) => {
  return httpClient.post('/api/ars080reject/insertRejectPhone', params, { headers: { "show-layer": "Yes"} });
};



export default {
  selectRejectPhoneList,
  deleteRejectPhoneList,
  insertRejectPhone,
  
};
