import httpClient from "@/common/http-client";

// 서버 api 호출하는 부분. 컨트롤러에 정의된 부분, 호출 할 일 있을 때 하위에 계속 추가해주면 된다.
const selectAutInfo = (params) => {
  return httpClient.post('/api/statistics/selectAutInfo', params, { headers: { "show-layer": "Yes"} });
};

//유저 리스트 조회
const selectUserList = (params) => {
  return httpClient.post('/api/user/selectUserList', params, { headers: { "show-layer": "Yes"} });
};

//사용자 유저 등록
const insertUserInfo = (params) => {
  return httpClient.post('/api/user/insertUserInfo', params, { headers: { "show-layer": "Yes"} });
};

//사용자 수정
const updateUserIdInfo = (params) => {
  return httpClient.post('/api/user/updateUserIdInfo', params, { headers: { "show-layer": "Yes"} });
};

//ID 중복 조회
const checkDuplUser = (params) => {
  return httpClient.post('/api/user/checkDuplUser', params, { headers: { "show-layer": "Yes"} });
};

//사용자 삭제
const deleteUserId = (params) => {
  return httpClient.post('/api/user/deleteUserId', params, { headers: { "show-layer": "Yes"} });
};

export default {
  selectAutInfo,
  selectUserList,
  insertUserInfo,
  updateUserIdInfo,
  checkDuplUser,
  deleteUserId
};
