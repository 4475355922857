import loginMain from "../views/loginMain";
import findUserId from "../views/findUserId";
import passWdReset from "../views/passWdReset";
import passWdInit from "../views/passWdInit";
import loginCertify from "../views/loginCertify";
import passWdResetTemp from "../views/passWdResetTemp";
import registGuide from "../views/registGuide";

export default [
  {
    path: "",
    component: loginMain,
  },
  {
    path: "/login/findUserId",
    component: findUserId,
    meta: { public: true },
  },
  {
    path: "/login/passWdReset",
    component: passWdReset,
    meta: { public: true },
  },
  {
    path: "/login/passWdInit",
    component: passWdInit,
    meta: { public: true },
  },
  {
    path: "/login/loginCertify",
    component: loginCertify,
    meta: { public: true },
  },
  {
    path: "/login/passWdResetTemp",
    component: passWdResetTemp,
    meta: { public: true },
  },
  {
    path: "/login/registGuide",
    component: registGuide,
    meta: { public: true },
  }
];
