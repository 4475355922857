<template>
  <div class="wrap">
    <MainHeader :menuList="menuList" />
    <div class="contents_wrap myinfo_wrap">
      <div class="contents">
        <MobileGnb />
        <router-view />
      </div>
    </div>

    <!--	s: btn_top	-->
    <div id="btn_top" @click="fnTopScroll"></div>
    <!--	e: btn_top	-->
    <MainFooter />
    <div
      class="dimmed"
      @click="
        mnClose();
        mPreAlimClose();
      "
    ></div>

    <!-- 로딩레이어-->
    <div class="loading_layer">
      <div class="loader"></div>
    </div>
    <div class="loading hidden">
      <div class="uil-ring-css" style="transform: scale(0.79)">
        <div></div>
      </div>
    </div>
    <!-- Nav Popup -->
    <NavPopup />
    <!-- Nav Popup -->

    <confirm></confirm>
  </div>
</template>

<script>
import Vue from "vue";
import confirm from "@/modules/commonUtil/service/confirm";

import MainHeader from "@/components/MainHeader.vue";
import MobileGnb from "@/components/MobileGnb.vue";
import MainFooter from "@/components/MainFooter.vue";
import store from "../store/index";
import loginApi from "@/modules/login/service/loginApi";
import NavPopup from "@/components/NavPopup.vue";

Vue.use(confirm);

export default {
  name: "publicLayout",
  components: {
    MainHeader,
    MainFooter,
    MobileGnb,
    NavPopup,
  },
  data() {
    return {
      isLogin: "",
      loggedIn: "",
      navShow: true, //네비게이션 온오프
      menuList: [],
    };
  },
  computed: {
    getIsAuth: function () {
      return store.getters["login/isAuth"];
    },
  },
  watch: {
    getIsAuth(value) {
      if (value) {
        this.navShow = true;
      } else {
        this.navShow = false;
      }
    },
  },
  mounted() {
    this.fnMenuList();
  },
  methods: {
    authView() {
      this.isLogin = store.getters["login/isLogin"];
    },
    async fnMenuList() {
      const isAuth = this.$store.getters["login/isAuth"];
      if (isAuth) this.isAuth = true;          

      await loginApi.getMenuForRole().then((response) => {
        var result = response.data;
        if (result.success && result.data != null) {
          this.menuList = result.data.children;
        }
      });
    },
    fnTopScroll() {
      let Top = document.querySelector("#btn_top");

      Top.addEventListener("click", function (e) {
        e.preventDefault();
        window.scrollTo({ top: 0, behavior: "smooth" });
      });
      //
    },
    ModalClose() {
      var dimmed = document.getElementsByClassName("dimmed");
      var wrap = document.getElementsByClassName("popup-wrap");
      var obj = wrap[0].childElementCount;
      dimmed[0].style.display = "none";
      wrap[0].style.display = "none";
      for (var i = 0; i < obj; i++) {
        var target = document.getElementsByClassName("popup");
        target[i].style.display = "none";
        target[i].classList.remove("open");
      }
    },
    mnClose() {
      const tmWrap = document.querySelector(".tm_wrap"),
        dimmed = document.querySelector(".dimmed");

      tmWrap.classList.remove("on");
      dimmed.classList.remove("open");
    },
    mPreAlimClose() {
      const path = this.$route.path;
      let preview = false;
      if (path.indexOf("/sms") > -1 || path.indexOf("/alimtalk") > -1 || path.indexOf("/rsvAlimModi") > -1 || path.indexOf("/rsvMsgSmsModi") > -1) {
        preview = true;
      }

      if (preview) {
        const mAlimPreview = document.querySelector(".m_preview_alimtalk");
        mAlimPreview.classList.remove("on");
      }
      const dimmed = document.querySelector(".dimmed");
      dimmed.classList.remove("open");
    },
  },
};
</script>
