<template>
  <Fragment>
    <section class="m_section">
      <!--	s: 	contents-->
      <div class="contents">
        <MobileGnb />
        <div class="tabcont_wrap without_tab channel_wrap">
          <h1 class="title with_desc">카카오 채널 아이디</h1>
          <div class="tabcontTop">
            <ul>
              <li>카카오의 알림톡과 친구톡을 이용하려면 채널 아이디를 등록해야 합니다.</li>
              <li>
                [채널 아이디 발급 안내]를 눌러 화면에 안내에 따라 채널 아이디를 만든 후 아래 [채널 아이디 등록]을 눌러 정보를 등록해 주세요.
                <a
                  href="https://center-pf.kakao.com
"
                  target="_blank"
                  >[채널 아이디 발급 안내]</a
                >
              </li>
              <li>관리자 아이디로만 카카오 채널 아이디를 등록, 수정, 삭제할 수 있습니다</li>
            </ul>
          </div>
          <div class="list_area">
            <div class="list_info">
              <button type="button" v-if="!loginDiv" class="btn plus" @click="fnNouUser">채널 아이디 등록</button>
              <button type="button" v-if="loginDiv && userDiv" class="btn plus" @click="fnChannelReg">채널 아이디 등록</button>
              <form @submit.prevent="" autocomplete="off" class="search_form">
                <div class="right_wrap">
                  <input type="text" class="search" placeholder="채널 아이디 입력" v-model="srchChnlId" />
                  <button class="btn_search" @click="fnSearch"></button>
                </div>
              </form>
            </div>

            <div class="fixed-table-cover">
              <div class="fixed-table-container" style="width: 1074px;">
                <div class="fixed-table-header-bg"></div>
                <div class="fixed-table-wrapper">
                  <table class="fixed-table" style="table-layout: fixed">
                    <thead>
                      <tr>
                        <th class="td_num" style="width: 6%">
                          <div class="th-text">순번</div>
                        </th>
                        <th class="td_date" style="width: 20%">
                          <div class="th-text">등록일</div>
                        </th >
                        <th class="td_id" style="width: 15%">
                          <div class="th-text">채널 아이디</div>
                        </th>
                        <th class="td_key" style="width: 30%">
                          <div class="th-text">발신프로필Key</div>
                        </th>
                        <th class="td_state" style="width:14%">
                          <div class="th-text">사용 상태</div>
                        </th>
                        <th class="td_management" style="width:15%">
                          <div class="th-text">채널 아이디 관리</div>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <template v-if="chnlList.length > 0">
                        <tr v-for="(list, index) in chnlList" :key="index">
                          <td class="td_num">{{ index + 1 }}</td>
                          <td class="td_date">{{ list.regDt }}</td>
                          <td class="td_id">{{ list.chnlId }}</td>
                          <td class="td_key">{{ list.sndrprofKey }}</td>
                          <td class="td_state">{{ list.dormYnNm }}</td>
                          <td class="td_management">
                            <button v-if="userDiv && list.dormYn === 'Y'" class="btn white edit" @click="fnChnlIdEdit(index)"></button>
                            <button v-if="userDiv" class="btn white del2" @click="fnChnlDel(list.userSeq, list.chnlId)"></button>
                          </td>
                        </tr>
                      </template>
                      <template v-else>
                        <tr class="empty">
                          <td colspan="6">검색된 결과가 없습니다.</td>
                        </tr>
                      </template>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>

            <!--
            <div class="list_cover">
              <div class="list_th_wrap" @scroll="handleThScroll($event)">
                <ul class="list_th">
                  <li class="td_num w97"><span>순번</span></li>
                  <li class="td_date w200"><span>등록일</span></li>
                  <li class="td_id w200"><span>채널 아이디</span></li>
                  <li class="td_key w300"><span>발신프로필Key</span></li>
                  <li class="td_state w150"><span>사용 상태</span></li>
                  <li class="td_management w120"><span>채널 아이디 관리</span></li>
                </ul>
              </div>
              <div class="list_wrap scr_wrap" @scroll="handleScroll($event)">
                <ul class="list">
                  <template v-if="chnlList.length > 0">
                    <li v-for="(list, index) in chnlList" :key="index">
                      <span class="td_num w97">{{ index + 1 }}</span>
                      <span class="td_date w200">{{ list.regDt }}</span>
                      <span class="td_id w200">{{ list.chnlId }}</span>
                      <span class="td_key w300">{{ list.sndrprofKey }}</span>
                      <span class="td_state w150">{{ list.dormYnNm }}</span>
                      <span class="td_management w120">
                        <button v-if="userDiv && list.dormYn === 'Y'" class="btn white edit" @click="fnChnlIdEdit(index)"></button>
                        <button v-if="userDiv" class="btn white del2" @click="fnChnlDel(list.userSeq, list.chnlId)"></button>
                      </span>
                    </li>
                  </template>
                  <template v-else>
                    <li class="empty">
                      <span class="w100p">검색된 결과가 없습니다.</span>
                    </li>
                  </template>
                </ul>
              </div>
            </div> -->
            <div class="list_bottom">
              <div class="select_wrap">
                <select @change="fnSelected" class="select">
                  <option value="30" selected>30</option>
                  <option value="50">50</option>
                  <option value="100">100</option>
                </select>
              </div>
              <p class="total">
                총 <span class="pink">{{ pageInfo.totCnt }} </span>건
              </p>
            </div>
            <div class="paging_wrap" v-show="pageInfo.totCnt > pageInfo.listSize">
              <PageLayer ref="updatePaging" @fnClick="fnSearch" :listTotalCnt="pageInfo.totCnt" :selected="pageInfo.listSize" :pageNum="pageInfo.pageNo"></PageLayer>
            </div>
          </div>
        </div>
      </div>
      <!--	e: 	contents	-->
    </section>

    <!-- <div class="dimmed" @click="ModalClose()"></div> -->
    <div class="popup-wrap">
      <ChannelIdRegVue ref="ChannelIdRegVue" @kkoChnlIdListUpdate="fnKkoChnlIdListUpdate" />
      <ChannelIdEditVue ref="channelEdit" :chnlData="chnlData" />
    </div>
  </Fragment>
</template>
<script>
import { Fragment } from "vue-frag";
import store from "@/store";
import sendNumberApi from "../service/sendNumberApi.js";
import confirm from "@/modules/commonUtil/service/confirm";
import { eventBus } from "@/modules/commonUtil/service/eventBus";
import PageLayer from "@/components/PageLayer.vue";
//팝업
import ChannelIdRegVue from "../components/ChannelIdReg.vue";
import ChannelIdEditVue from "../components/ChannelIdEdit.vue";
import MobileGnb from "@/components/MobileGnb.vue";

export default {
  name: "channelIdManage",
  components: { Fragment, ChannelIdRegVue, PageLayer, ChannelIdEditVue, MobileGnb },
  data() {
    return {
      showModal: false,
      showChannel: false,
      loginDiv: false,
      userDiv: false,
      phoneNumber: "",
      chnlList: [],
      chnlData: {},
      category: [],
      cate1: [],
      cate2: [],
      cate3: [],
      cate1Code: "",
      cate2Code: "",
      categoryCode: "00100010001",
      save_status: "C",
      pageInfo: {
        //페이지 정보
        listSize: 10, // select 박스 value (출력 갯수 이벤트)
        pageNo: 1, // 현재 페이징 위치
        totCnt: 0, //전체 리스트 수
        offset: 0, //페이지 시작점
      },
      srchChnlId: "",
      prntsUserSeq: "",
      subSttusCd:""
    };
  },
  mounted() {
    this.fnInit();
  },
  methods: {
    fnInit() {
      const isAuth = store.getters["login/isAuth"];
      if (isAuth) {
        this.loginDiv = true;
        sendNumberApi.selectAutInfoChnnel().then((response) => {
          var result = response.data;
          if (result.success) {
            this.autCd = result.data.autCd;
            this.subSttusCd = result.data.subSttusCd;
            if (this.autCd !== "H002" && this.subSttusCd !== "00" && this.subSttusCd !== "01") {
              this.userDiv = true;
            }
          }
        });
      } else {
        this.autCd == "H003";
      }
      this.fnSelectChannelId();
    },
    fnSearch(pageNum) {
      this.pageInfo.pageNo = this.$gfnCommonUtils.defaultIfEmpty(pageNum, "1") * 1;
      this.fnSelectChannelId();
    },
    fnSelected(e) {
      this.pageInfo.listSize = Number(e.target.value);
      this.$refs.updatePaging.fnAllDecrease();
    },
    fnSelectChannelId() {
      const params = {
        ...this.pageInfo,
        srchChnlId: this.srchChnlId,
      };
      sendNumberApi
        .selectChannelId(params)
        .then((response) => {
          if (response.data.success) {
            this.pageInfo.listSize = response.data.pageInfo.listSize;
            this.pageInfo.offset = response.data.pageInfo.offset;
            this.pageInfo.pageNo = response.data.pageInfo.pageNo;
            this.pageInfo.totCnt = response.data.pageInfo.totCnt;
            this.chnlList = response.data.data;
          } else {
            // confirm.fnAlert("장애", "채널ID 가져오기가 실패하였습니다.", "alert");
            confirm.fnAlert("", "채널ID 가져오기가 실패하였습니다.", "alert");
          }
        })
        .catch((error) => {
          // confirm.fnAlert("장애", "채널ID 가져오기가 실패하였습니다. <br/>" + error, "alert");
          confirm.fnAlert("", "채널ID 가져오기가 실패하였습니다. <br/>" + error, "alert");
        });
    },
    fnChannelReg() {
      this.ModalOpen("chnlReg");
      this.$refs.ChannelIdRegVue.fnInit();
    },
    fnChnlIdEdit(index) {
      this.ModalOpen("modal25");
      this.chnlData = this.chnlList[index];
      this.$refs.channelEdit.getKkoCategory();
    },
    // 카카오톡 채널 삭제
    fnChnlDel(userSeq, chnlId) {
      const params = {
        userSeq: userSeq,
        chnlId: chnlId,
      };

      eventBus.$on("callbackEventBus", () => {
        this.fnChnlDelProc(params);
      });
      confirm.fnConfirm("", "해당 채널 아이디를 삭제하시겠어요?", "del");
    },
    fnChnlDelProc(params) {
      console.log(params);
      setTimeout(() => {
        // confirm.fnAlert("알림", "테스트기간 동안 채널 삭제는 잠시 보류처리 됩니다.", "alert");
        confirm.fnAlert("", "테스트기간 동안 채널 삭제는 잠시 보류처리 됩니다.", "alert");
      }, 500);
      sendNumberApi.deleteKkoChannel(params).then((respose) => {
        if (respose.data.success) {
          confirm.fnAlert("삭제", "채널이 삭제되었습니다.");
          this.fnSelectChannelId();
        } else {
          confirm.fnAlert("오류", respose.data.message, "alert");
        }
      });
    },
    //모달창
    ModalOpen(target) {
      var dimmed = document.getElementsByClassName("dimmed");
      var wrap = document.getElementsByClassName("popup-wrap");
      var obj = document.getElementsByClassName(target);
      obj[0].classList.add("open");
      dimmed[0].style.display = "block";
      wrap[0].style.display = "block";
      obj[0].style.display = "block";
      this.ModalHeight();
      if (target == "address") {
        this.$refs.sendAddr.fnSendAddrInit();
      }
    },
    ModalClose() {
      var dimmed = document.getElementsByClassName("dimmed");
      var wrap = document.getElementsByClassName("popup-wrap");
      var obj = wrap[0].childElementCount;
      dimmed[0].style.display = "none";
      wrap[0].style.display = "none";
      for (var i = 0; i < obj; i++) {
        var target = document.getElementsByClassName("popup");
        target[i].style.display = "none";
        target[i].classList.remove("open");
      }
    },
    ModalHeight() {
      //팝업 heght가 device height보다 클때
      var winH = window.innerHeight,
        obj = document.querySelector(".popup.open"),
        objH = obj.clientHeight;
      if (objH >= winH) {
        obj.classList.add("over");
      }
    },
    fnKkoChnlIdListUpdate() {
      this.fnSelectChannelId();
    },
    fnNouUser() {
      confirm.fnAlert("", "로그인 후 이용할 수 있습니다.", "alert");
      return;
    },
    /*handleScroll(e) {
      const chooseListThWrap = e.target.parentNode.children[0];
      chooseListThWrap.scrollLeft = e.target.scrollLeft;
    },
    handleThScroll(e) {
      const chooseListWrap = e.target.parentNode.children[1];
      chooseListWrap.scrollLeft = e.target.scrollLeft;
    },*/
  },
};
</script>
