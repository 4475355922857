<template>
  <div class="m_preview_alimtalk">
    <button class="btn_close" @click="mPreAlimClose()"></button>
    <div class="m_preview previews">
      <div class="swiper m_preview_swiper">
        <div class="swiper-slide preview_swiper sms_preview" style="display: none">
          <p class="preview_title">문자메시지 미리보기</p>
          <div class="preview_top">
            <input type="radio" name="preview_type" id="btn_sent" checked /><label for="btn_sent"
              ><span>{{ callbackNumber !== "null" ? callbackNumber : "발신번호" }}</span> <input type="text" placeholder="발신번호" :value="callbackNumber !== 'null' ? callbackNumber : ''" readonly
            /></label>
          </div>
          <!--	s: 미리보기	 -->
          <div class="preview_cont">
            <!--	s: 이미지 첨부시 -->
            <div class="pcont_top" :style="fileList.length > 0 ? 'display:block' : 'display:none'">
              <swiper class="pcont_top" ref="previewSwiper" v-show="fileList.length > 0" :options="swiperFileOption">
                <swiper-slide class="pcont_img" v-for="(image, index) in fileList" :key="index"><img :src="image.src" class="img-fluid" /></swiper-slide>
              </swiper>
              <div class="swiper-pagination mo_pagination" v-show="fileList.length > 0"></div>
            </div>
            <!-- 	e: 이미지 첨부시 -->
            <div class="pcont_bottom" :class="fileList.length === 0 ? 'sms' : ''">
              <!-- 이미지 없을때 class="sms" 추가 -->
              <div class="pcont_tit" v-if="reqProduct !== 'SMS'">
                <p class="output_tit" :style="title ? 'color:#121006' : ''" v-html="previewTitle ? previewTitle : '제목 미리보기'"></p>
              </div>
              <div class="pcont_txt scr_wrap">
                <p class="cont" :style="msg ? 'color:#121006' : ''" v-html="previewMsg ? previewMsg : '내용 미리보기'"></p>
                <div class="fixed_txt" v-if="ad">
                  <p>{{ adBottom }}</p>
                  <p v-if="authNumber">{{ authNumber }}</p>
                </div>
              </div>
            </div>
            <div class="pcont_info">
              <span class="type">{{ reqProduct }}</span>
              <span class="date">{{ today }}</span>
            </div>
          </div>
          <!--	e: 미리보기	 -->
        </div>
        <div class="swiper-slide preview_swiper sms_history">
          <p class="preview_title">문자 최근 작성메시지</p>
          <!--	s: 최근전송내역	-->
          <div class="preview_cont">
            <div class="history_wrap">
              <div class="history">
                <div class="history_items_wrap scr_wrap" v-if="recentSendMessageList.length > 0">
                  <swiper class="history_items swiper-wrapper" :options="swiperOption2">
                    <swiper-slide class="history_item swiper-slide" v-for="(message, index) in recentSendMessageList" :key="index">
                      <template v-if="message.reqProduct === 'MMS'">
                        <div class="history_cont">
                          <div class="txt_wrap img_wrap">
                            <img :src="`/api/v1/fo/preview/${message.legacyFiles.split(',')[0]}`" style="width: 100%; height: 100%; object-fit: cover" alt="" />
                          </div>
                        </div>
                        <div class="history_desc">
                          <div class="history_txt">
                            <p class="date">{{ message.regDt }}</p>
                          </div>
                          <div class="type MMS"></div>
                        </div>
                      </template>
                      <template v-if="message.reqProduct === 'SMS' || message.reqProduct === 'LMS'">
                        <div class="history_cont">
                          <div class="txt_wrap">
                            <p class="stxt">{{ message.content }}</p>
                          </div>
                        </div>
                        <div class="history_desc">
                          <div class="history_txt">
                            <p class="date">{{ message.regDt }}</p>
                          </div>
                          <div class="type" :class="message.reqProduct"></div>
                        </div>
                      </template>
                    </swiper-slide>
                  </swiper>
                </div>
                <div class="btn_wrap" v-if="recentSendMessageList.length > 0">
                  <button class="btn_up swiper-button-next"></button>
                  <button class="btn_down swiper-button-prev"></button>
                </div>
              </div>
            </div>
          </div>

          <!-- 	e: 최근전송내역 	-->
        </div>
      </div>
      <div class="m_preview_alimtalk_pagination"></div>
    </div>
  </div>
</template>
<script>
import { swiper, swiperSlide } from "vue-awesome-swiper";
import { eventBus } from "@/modules/commonUtil/service/eventBus";
import confirm from "@/modules/commonUtil/service/confirm";

export default {
  name: "MobilePreviewAlim",
  components: { swiper, swiperSlide },
  props: {
    fileList: Array,
    title: String,
    ad: Boolean,
    msg: String,
    adBottom: String,
    authNumber: String,
    reqProduct: String,
    today: String,
    callbackNumber: String,
    recentSendMessageList: Array,
    recentSendAlimList: Array,
  },
  data() {
    const vm = this;
    return {
      // 미리보기 화면 swiper
      swiperOption: {
        slidesPerView: 1,
        spaceBetween: 10,
        observer: true,
        observeParents: true,
        pagination: {
          el: ".m_preview_alimtalk_pagination",
          clickable: true,
        },
      },
      swiperOption2: {
        slidesOffsetBefore: 0, // slidesOffsetBefore는 첫번째 슬라이드의 시작점에 대한 변경할 때 사용
        slidesOffsetAfter: 0, // slidesOffsetAfter는 마지막 슬라이드 시작점 + 마지막 슬라이드 너비에 해당하는 위치의 변경이 필요할 때 사용
        //freeMode: true, // freeMode를 사용시 스크롤하는 느낌으로 구현 가능
        centerInsufficientSlides: true, // 컨텐츠의 수량에 따라 중앙정렬 여부를 결정함
        loop: false,
        direction: "vertical",
        slidesPerView: 3,
        spaceBetween: 12,
        // Navigation arrows
        navigation: {
          nextEl: ".swiper-button-prev",
          prevEl: ".swiper-button-next",
        },
        observer: true,
        observeParents: true,
        on: {
          click: function () {
            const clickedIndex = this.clickedIndex;
            vm.handleClickSlide(clickedIndex);
          },
        },
      },
      swiperFileOption: {
        slidesPerView: 1,
        spaceBetween: 6, // swiper-slide 사이의 간격 지정
        slidesOffsetBefore: 0, // slidesOffsetBefore는 첫번째 슬라이드의 시작점에 대한 변경할 때 사용
        slidesOffsetAfter: 0, // slidesOffsetAfter는 마지막 슬라이드 시작점 + 마지막 슬라이드 너비에 해당하는 위치의 변경이 필요할 때 사용
        //freeMode: true, // freeMode를 사용시 스크롤하는 느낌으로 구현 가능
        centerInsufficientSlides: true, // 컨텐츠의 수량에 따라 중앙정렬 여부를 결정함
        observer: true,
        observeParents: true,
        pagination: {
          el: ".swiper-pagination.mo_pagination",
          clickable: true,
          renderBullet: function (index, className) {
            return '<span class="' + className + '">' + (index + 1) + "</span>";
          },
        },
      },
    };
  },
  computed: {
    // 미리보기 화면 줆바꿈 띄어쓰기 가능하도록 변경
    previewTitle() {
      return this.title ? this.title.split(" ").join("&nbsp;").split("\n").join("<br/>") : "";
    },
    previewMsg() {
      return this.msg ? this.msg.split(" ").join("&nbsp;").split("\n").join("<br/>") : "";
    },
  },
  created() {},
  mounted() {},
  methods: {
    mPreAlimClose() {
      const mAlimPreview = document.querySelector(".m_preview_alimtalk");
      const dimmed = document.querySelector(".dimmed");

      mAlimPreview.classList.remove("on");
      dimmed.classList.remove("open");
    },
    handleClickSlide(index) {
      eventBus.$on("callbackEventBus", () => {
        this.fnSendData(index);
      });
      // confirm.fnConfirm("최근작성문자 가져오기", "최근 작성문자로 기존 내용에 덮어 씌워집니다. <br/>계속 진행하시겠습니까?", "confirm");
      confirm.fnConfirm("", "최근 작성문자로 기존 내용에 덮어 씌워집니다. <br/>계속 진행하시겠습니까?", "confirm");
    },
    fnSendData(index) {
      this.$emit("recentSendMessageList", this.recentSendMessageList[index]);
      this.mPreAlimClose();
    },
  },
};
</script>
