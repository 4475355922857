<template>
  <div class="wrap">
    <header class="header_wrap">
      <div class="header">
        <div class="logo"><router-link to="/" class="router-link-active"></router-link></div>
      </div>
    </header>
    <router-view />
  </div>
</template>

<script>
export default {
  name: "NiceLayout",
};
</script>
