import { render, staticRenderFns } from "./addressPubManage.vue?vue&type=template&id=418c50cd&scoped=true"
import script from "./addressPubManage.vue?vue&type=script&lang=js"
export * from "./addressPubManage.vue?vue&type=script&lang=js"
import style0 from "./addressPubManage.vue?vue&type=style&index=0&id=418c50cd&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "418c50cd",
  null
  
)

export default component.exports