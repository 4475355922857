<template>
  <div class="wrap">

    <div class="contents_wrap myinfo_wrap">
      <div class="contents">
        <router-view />
      </div>
    </div>

  </div>
</template>

<script>
export default {
  name: "popupLayout",
  components: {},
  data() {
    return {};
  },
  computed: {},
  watch: {},
  mounted() {},
  methods: {},
};
</script>
