import niceSuccess from "../views/nice-success";
import niceFail from "../views/nice-fail";
import tossPaySuccess from "../views/tossPay-success";
import tossPayFail from "../views/tossPay-fail";

export default [
  {
    path: "/commonUtil/niceSuccess",
    name: "niceSuccess",
    component: niceSuccess,
  },
  {
    path: "/commonUtil/niceFail",
    name: "niceFail",
    component: niceFail,
  },
  {
    path: "/commonUtil/tossPaySuccess",
    name: "tossPaySuccess",
    component: tossPaySuccess
  },
  {
    path: "/commonUtil/tossPayFail",
    name: "tossPayFail",
    component: tossPayFail
  }

  
];
