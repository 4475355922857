<template>
  <!--	s: m_section	-->
  <section class="m_section">
    <!--	s: 	contents-->
    <div class="contents">
      <MobileGnb />
      <div class="tabcont_wrap without_tab template_management">
        <h1 class="title with_desc">알림톡 템플릿관리</h1>
        <div class="tabcontTop">
          <ul>
            <li>
              알림톡 템플릿은 검수가 ‘승인’ 상태인 경우 전송이 가능합니다.<br />
              <span class="tabcontTop_extra_desc">알림톡 템플릿 검수는 ㈜카카오가 진행합니다.</span>
            </li>
            <li>검수 상태가 ‘승인’이나 ‘검수 중’일 때는 템플릿을 수정하거나 삭제할 수 없습니다.</li>
            <li>알림톡 템플릿은 등록 (등록완료) 인 상태에서만 검수요청, 삭제가 가능합니다.</li>
          </ul>
        </div>
        <form @submit.prevent="" autocomplete="off" class="search_form">
          <!--
          <div class="template_sample">
            <a class="btn white" href="">템플릿 샘플 보기</a>
          </div>
          -->
          <div class="search_box template_search">
            <div class="group group01">
              <label for="right" class="label">종류</label>
              <div class="select_wrap">
                <select v-model="searchData.srchType" class="select">
                  <option value="01" selected>템플릿명</option>
                  <option value="02" selected>채널ID</option>
                </select>
              </div>
            </div>
            <div class="group group02">
              <label for="right" class="label">검색어</label>
              <input type="text" class="search" v-model="searchData.srchText" placeholder="검색어를 입력하세요." />
            </div>
            <div class="group group03">
              <label for="search" class="label">템플릿유형</label>
              <div class="select_wrap">
                <select class="select" v-model="searchData.srchTmpltTpCd">
                  <option value="null" selected>템플릿유형 전체</option>
                  <option value="BA">기본형</option>
                  <option value="AD">광고추가형</option>
                  <option value="EX">부가정보형</option>
                  <option value="MI">복합형</option>
                </select>
              </div>
            </div>
            <div class="group group04">
              <label for="search" class="label">강조유형</label>
              <div class="select_wrap">
                <select class="select" v-model="searchData.srchEmphsTpCd">
                  <option value="null" selected>강조유형 전체</option>
                  <option value="01">없음</option>
                  <option value="02">강조표기형</option>
                  <option value="03">이미지형</option>
                </select>
              </div>
            </div>
            <div class="group group05">
              <label for="search" class="label">검수 상태</label>
              <div class="select_wrap">
                <select name="" id="" class="select" v-model="searchData.srchTmpltSttusCd">
                  <option value="null" selected>검수 상태 전체</option>
                  <option value="T">신청완료</option>
                  <option value="Q">검수중</option>
                  <option value="A">승인</option>
                  <option value="S">반려</option>
                  <option value="R">검수요청완료</option>
                </select>
              </div>
            </div>
            <button class="btn" @click="fnSelectAlimTalkTmpltList">조회</button>
          </div>
        </form>
        <div class="list_area">
          <div class="list_info">
            <div class="left_wrap">
              <div class="select_wrap">
                <select @change="fnSelected" class="select">
                  <option value="30" selected>30</option>
                  <option value="50">50</option>
                  <option value="100">100</option>
                </select>
              </div>
              <p class="total">
                총 <span class="pink">{{ tmpInfo.totCnt }} </span>건
              </p>
            </div>
            <div class="right_wrap">
              <button class="btn" type="button" @click="fnProcInspectRequestKkoTmpltConfirm">검수요청</button>
              <router-link v-if="loginDiv" to="/templeteManage/templeteWrite" class="btn white plus">템플릿 등록</router-link>
            </div>
          </div>

          <div class="fixed-table-cover">
            <div class="fixed-table-container" style="width: 1074px">
              <div class="fixed-table-header-bg"></div>
              <div class="fixed-table-wrapper">
                <table class="fixed-table" style="table-layout: fixed">
                  <thead>
                    <tr>
                      <th class="td_chk" style="width: 40px">
                        <div class="th-text">
                          <input
                            type="checkbox"
                            class="chk_all"
                            id="chk_all"
                            v-model="allChecked"
                            :disabled="
                              tmpList.every((e) => {
                                return e.tmpltSttusCd !== 'T';
                              })
                            "
                            @click="checkedAll($event.target.checked)"
                          /><label for="chk_all"></label>
                        </div>
                      </th>
                      <th class="td_id" style="width: calc(25% - 40px)">
                        <div class="th-text">채널 아이디</div>
                      </th>
                      <th class="td_template" style="width: 23%">
                        <div class="th-text">템플릿명</div>
                      </th>
                      <th class="td_message" style="width: 13%">
                        <div class="th-text">템플릿유형</div>
                      </th>
                      <th class="td_emphasis" style="width: 14%">
                        <div class="th-text">강조유형</div>
                      </th>
                      <th class="td_state" style="width: 11%">
                        <div class="th-text">검수 상태</div>
                      </th>
                      <th class="td_date" style="width: 14%">
                        <div class="th-text">등록일</div>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <template v-if="tmpList.length > 0">
                      <tr v-for="(list, index) in tmpList" :key="index">
                        <td class="td_chk">
                          <input type="checkbox" class="chk" :id="'chk_' + index" :value="index" v-model="list.selected" :disabled="list.tmpltSttusCd !== 'T'" @change="selected()" />
                          <label :for="'chk_' + index"></label>
                        </td>
                        <td class="td_id">{{ list.chnlId }}</td>
                        <td class="td_template">
                          <router-link
                            v-if="list.tmpltSttusCd === 'S' || list.tmpltSttusCd === 'T'"
                            :to="{ path: `/templeteManage/templeteUpdate/${list.tmpltKey}`, query: { sndrprofKey: list.sndrprofKey, userSeq: list.userSeq } }"
                            >{{ list.tmpltNm }}</router-link
                          >
                          <router-link v-else :to="{ path: `/templeteManage/templete/${list.tmpltKey}`, query: { sndrprofKey: list.sndrprofKey, userSeq: list.userSeq } }">{{
                            list.tmpltNm
                          }}</router-link>
                        </td>
                        <td class="td_message">{{ list.tmpltTpCdNm }}</td>
                        <td class="td_emphasis">{{ list.emphsTpCdNm }}</td>
                        <td class="td_state">{{ list.rejctRsn ? "반려" : list.tmpltSttusCdNm }}</td>
                        <td class="td_date">{{ list.regDt | textSubString(11) }}</td>
                      </tr>
                    </template>
                    <template v-else>
                      <tr>
                        <td colspan="7">검색 결과가 없습니다.</td>
                      </tr>
                    </template>
                  </tbody>
                </table>
              </div>
            </div>
          </div>

          <!--
          <div class="list_cover">
            <div class="list_th_wrap" @scroll="handleThScroll($event)">
              <ul class="list_th">
                <li class="td_chk w40">
                  <input
                    type="checkbox"
                    class="chk_all"
                    id="chk_all"
                    v-model="allChecked"
                    :disabled="
                      tmpList.every((e) => {
                        return e.tmpltSttusCd !== 'T';
                      })
                    "
                    @click="checkedAll($event.target.checked)"
                  />
                  <label for="chk_all"></label>
                </li>
                <li class="td_id w240"><span>채널 아이디</span></li>
                <li class="td_template w240"><span>템플릿명</span></li>
                <li class="td_message w142"><span>템플릿유형</span></li>
                <li class="td_emphasis w145"><span>강조유형</span></li>
                <li class="td_state w115"><span>검수 상태</span></li>
                <li class="td_date w145"><span>등록일</span></li>
              </ul>
            </div>
            <div class="list_wrap scr_wrap" @scroll="handleScroll($event)">
              <ul class="list">
                <template v-if="tmpList.length > 0">
                  <li v-for="(list, index) in tmpList" :key="index">
                    <span class="td_chk w40">
                      <input type="checkbox" class="chk" :id="'chk_' + index" :value="index" v-model="list.selected" :disabled="list.tmpltSttusCd !== 'T'" @change="selected()" />
                      <label :for="'chk_' + index"></label>
                    </span>
                    <span class="td_id w240">{{ list.chnlId }}</span>
                    <span class="td_template w240">
                      <router-link
                        v-if="list.tmpltSttusCd === 'S' || list.tmpltSttusCd === 'T'"
                        :to="{ path: `/templeteManage/templeteUpdate/${list.tmpltKey}`, query: { sndrprofKey: list.sndrprofKey, userSeq: list.userSeq } }"
                        >{{ list.tmpltNm }}</router-link
                      >
                      <router-link v-else :to="{ path: `/templeteManage/templete/${list.tmpltKey}`, query: { sndrprofKey: list.sndrprofKey, userSeq: list.userSeq } }">{{ list.tmpltNm }}</router-link>
                    </span>
                    <span class="td_message w142">{{ list.tmpltTpCdNm }}</span>
                    <span class="td_emphasis w145">{{ list.emphsTpCdNm }}</span>
                    <span class="td_state w115">{{ list.rejctRsn ? "반려" : list.tmpltSttusCdNm }}</span>
                    <span class="td_date w145">{{ list.regDt | textSubString(11) }}</span>
                  </li>
                </template>
                <template v-else>
                  <li class="empty">
                    <span class="w100p">검색 결과가 없습니다.</span>
                  </li>
                </template>
              </ul>
            </div>
          </div>
-->

          <div class="list_bottom">
            <button class="btn white small" type="button" @click="fnProcDeleteRequestKkoTmpltConfirm()">템플릿 삭제</button>
          </div>
          <div class="paging_wrap" v-show="tmpInfo.totCnt > tmpInfo.listSize">
            <PageLayer ref="updatePaging" @fnClick="fnSearch" :listTotalCnt="tmpInfo.totCnt" :selected="tmpInfo.listSize" :pageNum="tmpInfo.pageNo"></PageLayer>
          </div>
        </div>
      </div>
    </div>
    <!--	e: 	contents	-->
  </section>
  <!--	e: m_section	-->
</template>
<script>
import store from "@/store";
import PageLayer from "@/components/PageLayer.vue";
import confirm from "@/modules/commonUtil/service/confirm";
import { eventBus } from "@/modules/commonUtil/service/eventBus";
import templeteManageApi from "@/modules/templeteManage/service/templeteManageApi.js";
import MobileGnb from "@/components/MobileGnb.vue";

export default {
  name: "templeteManage",
  components: { PageLayer, MobileGnb },
  data() {
    return {
      loginDiv: false,
      allChecked: false,
      tmpList: [],
      tmpInfo: {
        //페이지 정보
        listSize: 30, // select 박스 value (출력 갯수 이벤트)
        pageNo: 1, // 현재 페이징 위치
        totCnt: 0, //전체 리스트 수
        offset: 0, //페이지 시작점
        // 유저 시퀀스
        userSeq: "",
      },
      searchData: {
        srchType: "01", //종류
        srchText: "", //검색어
        srchTmpltTpCd: "null", //템플릿유형:
        srchEmphsTpCd: "null", //강조유형:
        srchTmpltSttusCd: "null", //검수상태:
      },
      listScrollPostion: 0,
    };
  },
  mounted() {
    this.fnInit();
  },
  methods: {
    fnInit() {
      const isAuth = store.getters["login/isAuth"];
      if (isAuth) {
        this.loginDiv = true;
        this.fnSelectAlimTalkTmpltList();
      }
    },
    //체크박스 전체 선택 기능
    checkedAll(checked) {
      this.allChecked = checked;
      this.tmpList.forEach((e, index) => {
        if (e.tmpltSttusCd === "T") {
          this.tmpList[index].selected = this.allChecked;
        }
      });
    },
    //체크박스 단일 체크
    selected() {
      if (this.tmpList.every((item) => item.selected == true)) {
        this.allChecked = "checked";
      } else {
        this.allChecked = "";
      }
    },
    // 페이지 리스트 검색
    fnSearch(pageNum) {
      this.tmpInfo.pageNo = this.$gfnCommonUtils.defaultIfEmpty(pageNum, "1") * 1;
      this.fnSelectAlimTalkTmpltList();
    },
    // 알림톡 템플릿관리 리스트
    fnSelectAlimTalkTmpltList() {
      if (this.loginDiv) {
        this.tmpInfo = {
          pageNo: this.tmpInfo.pageNo,
          listSize: this.tmpInfo.listSize,
          offset: this.tmpInfo.offset,
          ...this.searchData,
        };

        templeteManageApi.selectAlimTalkTmpltList(this.tmpInfo).then((response) => {
          if (response.data.success) {
            if (response.data.data) {
              this.tmpList = response.data.data.map((e) => {
                return {
                  ...e,
                  selected: false,
                };
              });

              this.tmpInfo = response.data.pageInfo;
            }
          } else {
            // confirm.fnAlert("알림톡 템플릿관리", "해당 리스트 가져오기에 실패하였습니다.", "alert");
            confirm.fnAlert("", "해당 리스트 가져오기에 실패하였습니다.", "alert");
          }
        });
      }
    },

    // 삭제 요청 확인
    fnProcDeleteRequestKkoTmpltConfirm() {
      const tmp = this.tmpList.some((e) => {
        return e.selected === true;
      });

      if (this.tmpList.length === 0) {
        confirm.fnAlert("", "알림톡 템플릿 리스트가 없습니다.", "alert");
        return;
      } else if (!tmp) {
        confirm.fnAlert("", "템플릿을 선택해주세요.", "alert");
        return;
      }

      eventBus.$on("callbackEventBus", () => {
        this.fnProcDeleteRequestKkoTmplt();
      });

      // confirm.fnConfirm("템플릿 삭제", "삭제하면 템플릿이 삭제됩니다.<br />그래도 템플릿을 삭제하시겠어요?", "confirm");
      confirm.fnConfirm("", "삭제하면 템플릿이 삭제됩니다.<br />그래도 템플릿을 삭제하시겠어요?", "del");
    },

    // 템플릿 삭제
    fnProcDeleteRequestKkoTmplt() {
      const delTmpList = this.tmpList
        .filter((e) => {
          return e.selected === true;
        })
        .map((e) => {
          return {
            sndrprofKey: e.sndrprofKey,
            tmpltKey: e.tmpltKey,
          };
        });

      templeteManageApi
        .procDeleteRequestKkoTmplt(delTmpList)
        .then((response) => {
          if (response.data.success) {
            //eventBus.$on("callbackEventBus", this.$router.go());
            eventBus.$on("callbackEventBus", () => {
              this.$router.go();
            });
            // confirm.fnConfirm("완료", "삭제했습니다.", "cbAlert");
            confirm.fnConfirm("", "삭제했습니다.", "cbAlert");
          } else {
            // confirm.fnAlert("오류", response.data.message, "alert");
            confirm.fnAlert("", response.data.message, "alert");
          }
        })
        .catch((error) => {
          // confirm.fnAlert("오류", "삭제 실패했습니다." + error, "alert");
          confirm.fnAlert("", "삭제 실패했습니다." + error, "alert");
        });
    },

    // 검수요청 확인
    fnProcInspectRequestKkoTmpltConfirm() {
      const tmp = this.tmpList.some((e) => {
        return e.selected === true;
      });

      if (this.tmpList.length === 0) {
        confirm.fnAlert("", "알림톡 템플릿 리스트가 없습니다.", "alert");
        return;
      } else if (!tmp) {
        confirm.fnAlert("", "템플릿을 선택해주세요.", "alert");
        return;
      }

      eventBus.$on("callbackEventBus", () => {
        this.fnProcInspectRequestKkoTmplt();
      });

      // confirm.fnConfirm("템플릿 검수요청", "선택한 템플릿의 검수를 요청하시겠어요?<br />검수는 평일 기준 7일 정도 걸리며,<br /> 승인이 되면 검수 상태가 ‘사용’으로 변경됩니다.", "confirm");
      confirm.fnConfirm("", "선택한 템플릿의 검수를 요청하시겠어요?<br />검수는 평일 기준 7일 정도 걸리며,<br /> 승인이 되면 검수 상태가 ‘사용’으로 변경됩니다.", "confirm");
    },

    // 검수 요청
    fnProcInspectRequestKkoTmplt() {
      /*
      const tmp = this.tmpList.some((e) => {
        return e.selected === true;
      });

      if (this.tmpList.length === 0) {
        confirm.fnAlert("알림", "알림톡 템플릿 리스트가 없습니다.", "alert");
        return;
      } else if (!tmp) {
        confirm.fnAlert("알림", "선택된 템플릿이 없습니다.", "alert");
        return;
      }
      */
      const procInsList = this.tmpList.filter((e) => {
        return e.selected === true;
      });

      templeteManageApi
        .procInspectRequestKkoTmplt(procInsList)
        .then((response) => {
          if (response.data.success) {
            //eventBus.$on("callbackEventBus", this.$router.go());
            eventBus.$on("callbackEventBus", () => {
              this.$router.go();
            });
            // confirm.fnConfirm("완료", "검수를 요청했습니다.", "cbAlert");
            confirm.fnConfirm("", "검수를 요청했습니다.", "cbAlert");
          } else {
            // confirm.fnAlert("오류", response.data.message, "alert");
            confirm.fnAlert("", response.data.message, "alert");
          }
        })
        .catch((error) => {
          // confirm.fnAlert("오류", "검수 요청이 실패했습니다." + error, "alert");
          confirm.fnAlert("", "검수 요청이 실패했습니다." + error, "alert");
        });
    },
    fnSelected(e) {
      this.tmpInfo.listSize = Number(e.target.value);
      this.$refs.updatePaging.fnAllDecrease();
    },
    fnCantReg() {
      if (!this.loginDiv) {
        // confirm.fnAlert("알림", "비로그인은 템플릿 등록을 할 수 없습니다.", "alert");
        confirm.fnAlert("", "비로그인은 템플릿 등록을 할 수 없습니다.", "alert");
        return;
      }
    },
    handleScroll(e) {
      const chooseListThWrap = e.target.parentNode.children[0];
      chooseListThWrap.scrollLeft = e.target.scrollLeft;
    },
    handleThScroll(e) {
      const chooseListWrap = e.target.parentNode.children[1];
      chooseListWrap.scrollLeft = e.target.scrollLeft;
    },
  },
};
</script>
