<template>
    <div class="login_wrap join_wrap">
      <h2>회원가입</h2>
      <div class="join_box">
        <div class="join_prepaid" @mouseover="onMouseOver(1)" @mouseout="onMouseOut">
          <router-link to="/serviceInfo/main?action=pre">
            <div class="box" :class="{ 'disable': isHovered === 2 }">
              <div class="img">
                <img src="../../../assets/images/icon_join1.png" alt="">
              </div>
              <div class="txt">
                <p class="btxt">선불요금제</p>
                <p class="stxt">가입하러가기&nbsp;></p>
              </div>
            </div>
          </router-link>
        </div>
        <div class="join_postpaid"  @mouseover="onMouseOver(2)" @mouseout="onMouseOut">
          <a href="https://bizmarket.uplus.co.kr/" target="_blank">
            <div class="box" :class="{ 'disable': isHovered === 1 }">
              <div class="img">
                <img src="../../../assets/images/icon_join2.png" alt="">
              </div>
              <div class="txt">
                <p class="btxt">후불정액제<span class="block"></span>/자유요금제</p>
                <p class="stxt">가입하러가기&nbsp;></p>
              </div>
            </div>
          </a>
        </div>
      </div>
    </div>
  </template>
  
  <script>

export default {
  name: "registGuide",
  components: {},
  data() {
    return {
      isHovered: 0,
    };
  },
  methods: {
    onMouseOver(imageNumber) {
      this.isHovered = imageNumber;
    },
    onMouseOut() {
      this.isHovered = 0;
    },
  },
};
</script>
