import charge from "../views/chargeProc";
import chargingHistory from "../views/chargingHistory";

export default [
  {
    path: "/charge/chargeProc",
    name: "충전 하기",
    component: charge
  }, 
  {
    path: "/charge/chargingHistory",
    name: "충전 내역",
    component: chargingHistory
  }

];