import httpClient from '@/common/http-client';

// *********************공유 주소록 ************************

const selectAddressPubGrpList = (params) => {
	return httpClient.post('/api/addressPub/selectAddressPubGrpList', params, { headers: {"show-layer": "Yes", "activity":"READ"} });
};

const searchAddressPubGrpList = (params) => {
	return httpClient.post('/api/addressPub/searchAddressPubGrpList', params, { headers: {"show-layer": "Yes", "activity":"READ"} });
};

const selectAddrPubMemberList = (params) => {
	return httpClient.post('/api/addressPub/selectAddrPubMemberList', params, { headers: {"show-layer": "Yes", "activity":"READ"} });
};
const selectAddrPubMemberExcelList = (params) => {
	return httpClient.post('/api/addressPub/selectAddrPubMemberExcelList', params, { headers: {"show-layer": "Yes", "activity":"READ"} });
};

const registerAddrPubGrp = (params) => {
	return httpClient.post('/api/addressPub/registerAddrPubGrp', params, { headers: {"show-layer": "Yes", "activity":"SAVE"} });
};

const modifyAddrPubGrp = (params) => {
	return httpClient.post('/api/addressPub/modifyAddrPubGrp', params, { headers: {"show-layer": "Yes", "activity":"SAVE"} });
};

const deleteAddressPubGrp = (params) => {
	return httpClient.post('/api/addressPub/deleteAddressPubGrp', params, { headers: {"show-layer": "Yes", "activity":"SAVE"} });
};
const selectAddressPubGrpSelList = (params) => {
	return httpClient.post('/api/addressPub/selectAddressPubGrpSelList', params, { headers: {"show-layer": "Yes", "activity":"READ"} });
};

const selectModiAddrPubGrpSelList = (params) => {
	return httpClient.post('/api/addressPub/selectModiAddrPubGrpSelList', params, { headers: {"show-layer": "Yes", "activity":"READ"} });
};

const addressPubGrpExec = (params) => {
	return httpClient.post('/api/addressPub/addressPubGrpExec', params, { headers: {"show-layer": "Yes", "activity":"READ"} });
};

const registerAddrPubMember = (params) => {
	return httpClient.post('/api/addressPub/registerAddrPubMember', params, { headers: {"show-layer": "Yes", "activity":"SAVE"} });
};

const modifyAddrPubMember = (params) => {
	return httpClient.post('/api/addressPub/modifyAddrPubMember', params, { headers: {"show-layer": "Yes", "activity":"SAVE"} });
};

const deleteAddressPubMem = (params) => {
	return httpClient.post('/api/addressPub/deleteAddressPubMem', params, { headers: {"show-layer": "Yes", "activity":"SAVE"} });
};

const addressPubMemExec = (params) => {
	return httpClient.post('/api/addressPub/addressPubMemExec', params, { headers: {"show-layer": "Yes", "activity":"READ"} });
};

const selectAddressPubSelList = (params) => {
	return httpClient.post('/api/addressPub/selectAddressPubSelList', params, { headers: {"show-layer": "Yes", "activity":"READ"} });
};

const massRegisterAddrPubMember = (params) => {
	return httpClient.post('/api/addressPub/massRegisterAddrPubMember', params, { headers: {"show-layer": "Yes", "activity":"SAVE"} });
};

export default {
	selectAddressPubGrpList,
	searchAddressPubGrpList,
	selectAddrPubMemberList,
	selectAddrPubMemberExcelList,
	registerAddrPubGrp,
	modifyAddrPubGrp,
	deleteAddressPubGrp,
	selectAddressPubGrpSelList,
	selectModiAddrPubGrpSelList,
	addressPubGrpExec,
	registerAddrPubMember,
	modifyAddrPubMember,
	deleteAddressPubMem,
	addressPubMemExec,
	selectAddressPubSelList,
	massRegisterAddrPubMember
};


