<template>
  <div class="mo_tm_wrap">
    <ul class="tm mo_tm">
      <li class="tml01">
        <a href="#" class="tma01" @click="mGnbToggle">메시지 보내기</a>
        <div class="mo_depth2_wrap">
          <ul class="depth2">
            <li class="gnba12">
              <router-link to="/messageSend/sms" class="tma02">문자메시지 전송</router-link>
              <router-link to="/messagesend/alimtalk" class="tma02">카카오 비즈메시지 전송</router-link>
            </li>
          </ul>
        </div>
      </li>
      <li class="tml01">
        <a href="#" class="tma01" @click="mGnbToggle">전송 관리</a>
        <div class="mo_depth2_wrap">
          <ul class="depth2">
            <li class="gnba12">
              <router-link to="/sendManage/sendMessage" class="tma02">전송 메시지 내역</router-link>
              <router-link to="/sendManage/rsvSendMessage" class="tma02">예약 메시지 내역</router-link>
              <router-link to="/sendManage/sendMessageStats" class="tma02">사용자 전송 통계</router-link>
            </li>
          </ul>
        </div>
      </li>
      <li class="tml01">
        <a href="#" class="tma01" @click="mGnbToggle">서비스 소개</a>
        <div class="mo_depth2_wrap">
          <ul class="depth2">
            <li class="gnba12">
              <router-link to="/serviceInfo/main" class="tma02">서비스 소개</router-link>
            </li>
          </ul>
        </div>
      </li>
    </ul>
  </div>
</template>
<script>
export default {
  name: "mobileGnb",
  components: {},
  data() {
    return {
      example: "",
    };
  },
  mounted() {},
  methods: {
    mGnbToggle(e) {
      const target = e.target;
      const targetNext = target.nextSibling;

      if (targetNext.classList.contains("on")) {
        targetNext.classList.remove("on");
        return false;
      }

      const targetNexts = document.querySelectorAll(".mo_tm_wrap .mo_depth2_wrap");
      for (let i = 0; i < targetNexts.length; i++) {
        targetNexts[i].classList.remove("on");
      }
      targetNext.classList.add("on");
    },
  },
};
</script>
