<template>
  <Fragment>
    <PasswordConfirm :pwdConfirm="pwdConfirm" @confirmPwd="fnConfirmPwd" @confirmCancel="fnConfirmCancel" />
    <ConfirmMemInfo ref="memInfo" :memberInfo="memberInfo" @memInfoCancel="fnMemInfoCancel" @confirmMove="fnAmhoConfirmMove" />
  </Fragment>
</template>
<script>
import ConfirmMemInfo from "../components/ConfirmMemInfo.vue";
import PasswordConfirm from "../components/PasswordConfirm.vue";
import { Fragment } from "vue-frag";

export default {
  name: "memInfo",
  components: {
    ConfirmMemInfo,
    PasswordConfirm,
    Fragment,
  },
  data() {
    return {
      pwdConfirm: false,
      memberInfo: false,
    };
  },
  mounted() {
    this.pwdConfirm = true;
  },
  methods: {
    fnConfirmPwd(ceamho) {
      this.$refs.memInfo.fnSelectMemberInfo(ceamho);
      this.pwdConfirm = false;
      this.memberInfo = true;
    },
    fnAmhoConfirmMove() {
      this.memberInfo = false;
      this.pwdConfirm = true;
    },
    fnConfirmCancel() {
      this.$router.go(-1);
    },
    fnMemInfoCancel() {
      this.$router.go(-1);
    },
  },
};
</script>
