<template>
  <div class="popup tit excelpop">
    <div class="pop-head">
      <h3 class="pop-tit">파일로 추가하기</h3>
    </div>
    <div class="pop-cont">
      <div class="filepop_top">
        <ul>
          <li><p>한번에 20,000개까지 번호를 등록할 수 있습니다.</p></li>
          <li><p>업로드할 수 있는 파일 형식은 xls, xlsx, csv, txt입니다.</p></li>
          <li>
            <p>
              정상적으로 정보를 입력한 번호만 주소록에 추가할 수 있습니다.
              <span class="filepop_top_desc">[오류 검사]를 누르면 오류 여부를 바로 확인할 수 있습니다.</span>
            </p>
          </li>
          <li>
            <p>메시지 내용에 자동 변경 정보를 입력한 경우 샘플 파일을 내려받으신 후 확인할 수 있습니다.</p>
          </li>
        </ul>
        <div class="download_wrap">
          <p>샘플 파일 내려 받기</p>
          <a @click="excelDown('xls')" class="sample xls" download></a>
          <a @click="excelDown('csv')" class="sample csv" download></a>
          <a @click="txtDown" class="sample txt" download></a>
        </div>
      </div>
      <div class="filepop_cont">
        <div class="list_top">
          <div class="btn plus" :class="fileName ? 'disabled' : ''">
            파일 불러오기
            <input
              type="file"
              ref="ExcelFile"
              accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel,text/comma-separated-values, text/csv, application/csv, text/plain"
              @change="readExcel"
            />
          </div>
          <div class="img_list_wrap" v-if="fileName !== ''">
            <div class="img_list">
              <p class="file_name">{{ fileName | fileTextLengthCut(5, "…") }}</p>
              <button class="btn_del" @click="removeFile"></button>
            </div>
          </div>
        </div>

        <div class="fixed-table-cover">
          <div class="fixed-table-container" :style="autoMsg.length > 4 ? 'width: max-content; min-width: 938px; padding-top: 0px;' : 'padding-top: 0px;'">
            <div class="fixed-table-header-bg" style="position: absolute"></div>
            <div class="fixed-table-wrapper">
              <table class="fixed-table">
                <colgroup>
                  <col width="40px" />
                  <col width="40px" />
                  <col :width="autoMsg.length > 4 ? '150px' : '*'" />
                  <col width="100px" v-for="i in autoMsg.length" :key="i" />
                  <col width="150px" />
                </colgroup>
                <thead>
                  <tr>
                    <th class="td_chk" style="position: sticky; top: 0">
                      <div class="th-text" style="position: relative; height: auto">
                        <input type="checkbox" class="chk_all" id="excelUpload_chk_all" v-model="allChecked" @click="checkedAll($event.target.checked)" :disabled="excelData.paramData.length === 0" />
                        <label for="excelUpload_chk_all"></label>
                      </div>
                    </th>
                    <th class="td_num" style="position: sticky; top: 0">
                      <div class="th-text" style="position: relative; height: auto">No</div>
                    </th>
                    <th class="td_phone" style="position: sticky; top: 0">
                      <div class="th-text" style="position: relative; height: auto">휴대폰 번호</div>
                    </th>
                    <th :class="index === 0 ? 'td_name' : 'td_' + index" v-for="(msg, index) in autoMsg" :key="index" style="position: sticky; top: 0">
                      <div class="th-text" style="position: relative; height: auto">{{ msg | textLengthCut(5) }}</div>
                    </th>
                    <th class="td_state" style="position: sticky; top: 0">
                      <div class="th-text" style="position: relative; height: auto">상태</div>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <template v-if="fileName === '' || excelCount === 0">
                    <tr class="empty">
                      <td :colspan="this.autoMsg.length + 4">등록된 파일이 없습니다.</td>
                    </tr>
                  </template>
                  <template v-else>
                    <tr v-for="(data, index) in excelData.paramData.slice(0, 100)" :key="index" :class="data.validator ? '' : 'on'">
                      <td class="td_chk">
                        <input type="checkbox" class="chk" :id="'excelUpload_chk_' + (index + 1)" :value="index" v-model="data.selected" @change="selected()" />
                        <label :for="'excelUpload_chk_' + (index + 1)"></label>
                      </td>
                      <td class="td_num">{{ index + 1 }}</td>
                      <td class="td_phone">
                        <input type="text" class="input" v-model="data.phone" @input="fnPhoneDash(data.phone, index)" />
                      </td>
                      <td :class="index === 0 ? 'td_name' : 'td_' + index" v-for="(msg, index) in autoMsg" :key="index">
                        <input type="text" class="input" v-model="data[msg]" :placeholder="autoMsg[index] ? $gfnCommonUtils.textLengthCut(autoMsg[index], 5) : ''" />
                      </td>
                      <td class="td_state">
                        <p :class="data.validator ? '' : 'red'">{{ data.status }}</p>
                      </td>
                    </tr>
                  </template>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div class="list_info">
          <button class="btn white small" @click="fnExcelDataDel">삭제</button>
          <div class="right_wrap">
            <p class="total">
              총 <span class="pink">{{ excelCount | formatPrice }} </span>건
            </p>
            <button class="btn white small" @click="errorReCheck()">오류 검사</button>
          </div>
        </div>
      </div>
    </div>
    <div class="pop-btn2 center">
      <button type="button" :class="passNumber > 0 ? 'btn large_pink' : 'btn large_pink disabled'" @click="sendAddress()">추가</button>
      <button type="button" class="btn large_white" @click="ModalClose()">취소</button>
    </div>
  </div>
</template>
<script>
import * as XLSX from "xlsx";
import confirm from "@/modules/commonUtil/service/confirm";
import ToastComponentVue from "@/components/ToastComponent.vue";

export default {
  name: "FileExcelUpload",
  props: {
    autoMsg: Array,
  },
  data() {
    return {
      validator: false,
      hashNum: new Map(), // 연락처 중복 처리 변수 값 (errorCheck 할 경우 function 마다 초기화 진행)
      allChecked: false, // 전체 체크박스 값
      excelData: {
        // 엑셀파일 변수 초기화
        paramData: [],
      },
      filePath: false,
      fileName: "",
      headerInfo: [], // 자동 변수 정보
      passNumber: 0,
    };
  },
  computed: {
    excelCount() {
      return this.excelData["paramData"].length;
    },
  },
  watch: {
    excelData: {
      handler(newData) {
        this.passNumber = newData["paramData"].filter((e) => {
          return e.validator === true;
        }).length;
        this.excelData["paramData"] = newData["paramData"];
      },
      deep: true,
    },
  },
  methods: {
    // 엑셀 데이터 삭제
    removeFile() {
      this.$refs.ExcelFile.value = ""; // 이미지 중복 가능하도록 input 초기화
      this.fnInit();
      this.allChecked = false;
    },
    // 선택된 주소록 삭제
    fnExcelDataDel() {
      this.excelData["paramData"] = this.excelData["paramData"].filter((item) => {
        return item.selected === false; // 체크 안된 리스트
      });
      this.allChecked = false;
    },
    // 수정된 파일 오류 검사 실시
    errorReCheck() {
      this.hashNum = new Map();
      // sppinner 작동
      this.$store.commit("startSpinner");
      setTimeout(() => {
        this.excelData["paramData"] = this.excelData["paramData"].map((e, index) => {
          return {
            // 유효성 체크 항목 추가
            ...e,
            status: this.errorCheck(index, e["phone"], { ...e }),
            validator: this.validator,
            selected: false,
          };
        });
        /* .sort(function (a, b) {
            if (a.validator < b.validator) return -1;
            if (a.validator > b.validator) return 1;
            if (a.validator === b.validator) return 0;
          }); */

        this.$store.commit("endSpinner");
      }, 100);

      /* this.passNumber = this.excelData["paramData"].filter((e) => {
        return e.validator === true;
      }).length; */
    },
    //체크박스 전체 선택 기능
    checkedAll(checked) {
      if (this.excelData.paramData.length > 0) {
        this.allChecked = checked;
        for (let i in this.excelData.paramData.slice(0, 100)) {
          this.excelData.paramData[i].selected = this.allChecked;
        }
      }
    },
    //체크박스 단일 체크
    selected() {
      if (this.excelData.paramData.every((item) => item.selected == true)) {
        this.allChecked = true;
      } else {
        this.allChecked = false;
      }
    },
    // 엑셀 주소록 읽기
    readExcel(event) {
      // sppinner 작동
      this.$store.commit("startSpinner");
      // 확장자 검사
      const file = event.target.files[0];
      const filePath = event.currentTarget.value;
      const filePathSplit = filePath.split("\\");
      const filePathLength = filePathSplit.length;
      const fileNameSplit = filePathSplit[filePathLength - 1].split(".");
      const fileExt = fileNameSplit[1];
      this.filePath = filePath;
      this.fileName = event.target.files[0].name;

      if (!(fileExt === "xlsx" || fileExt === "xls" || fileExt === "csv" || fileExt === "txt")) {
        confirm.fnAlert("", "업로드할 수 없는 파일 형식입니다.<br/>샘플 파일을 내려 받아 사용해주세요.", "alert");
        this.removeFile();
        this.$store.commit("endSpinner");
        return false;
      }

      let reader = new FileReader();
      if (fileExt === "txt") {
        reader.readAsText(file);
      } else {
        reader.readAsBinaryString(file);
      }

      reader.onload = () => {
        let data = reader.result;
        let workBook = XLSX.read(data, { type: "binary" });
        let rows = new Array();
        workBook.SheetNames.forEach(function (sheetName) {
          rows = XLSX.utils.sheet_to_json(workBook.Sheets[sheetName], {
            defval: "",
            raw: false,
          });
        });

        if (rows.length > 0) {
          // 엑셀파일 읽어올 경우 헤더값 확인
          const headerInfo = ["휴대폰번호"].concat(this.autoMsg);
          let headerCheck = false;

          if (headerInfo.length === this.autoMsg.length + 1) {
            headerCheck = JSON.stringify(headerInfo) === JSON.stringify(Object.keys(rows[0]));
          }

          if (!headerCheck) {
            confirm.fnAlert("", "샘플 파일의 자동 변경 정보를 확인해 주세요. <br/>메시지 내용에 입력한 자동 변경 정보만 입력 가능합니다.", "alert");
            this.$store.commit("endSpinner");
            this.removeFile();
            return;
          }
        } else {
          confirm.fnAlert("", "주소록이 없습니다.", "alert");
          this.$store.commit("endSpinner");
          this.ModalClose();
          return;
        }

        if (rows.length > 20000) {
          // confirm.fnAlert("최대 전송 건수 초과", "최대 전송할 수 있는 건수가 초과되어 전송이 불가합니다.", "alert");
          confirm.fnAlert("", "최대 전송할 수 있는 건수가 초과되어 전송이 불가합니다.", "alert");
          this.$store.commit("endSpinner");
          this.ModalClose();
          return;
        }
        // 중복 함수 초기화
        this.hashNum = new Map();
        this.excelData["paramData"] = rows
          .map((e, index) => {
            return {
              // 유효성 체크 항목 추가
              ...e,
              phone: this.$gfnCommonUtils.hpNumberAddDash(e["휴대폰번호"]),
              status: this.errorCheck(index, e["휴대폰번호"], { ...e }),
              validator: this.validator,
              selected: false,
            };
          })
          .sort(function (a, b) {
            if (a.validator < b.validator) return -1;
            if (a.validator > b.validator) return 1;
            if (a.validator === b.validator) return 0;
          });
        /* this.passNumber = this.excelData["paramData"].filter((e) => {
          return e.validator === true;
        }).length; */
        this.$forceUpdate();
        this.$store.commit("endSpinner");
      };

      //this.excelFile = reader.readAsBinaryString(file);
    },
    // 휴대폰 번호 유효성 체크 항목
    errorCheck(index, phoneNumber, data) {
      const patten_phone = /^(01[016789]{1}|070)-?[0-9]{3,4}-?[0-9]{4}$/; //핸드폰 번호, 하이픈 상관 X
      const pattern_eng = /[a-zA-Z]/; // 문자
      const pattern_spc = /[~!@#$%^&*()_+|<>?:{}]/; // 특수문자
      const pattern_kor = /[ㄱ-ㅎ|ㅏ-ㅣ|가-힣]/; // 한글체크
      let errorText = "";
      // 휴대폰 번호, 이름, 중복 체크
      if (phoneNumber == "" || phoneNumber == undefined) {
        errorText = "번호없음";
        this.validator = false;
        return errorText;
      } else if (pattern_eng.test(phoneNumber) || pattern_spc.test(phoneNumber) || pattern_kor.test(phoneNumber)) {
        errorText = "번호오류";
        this.validator = false;
        return errorText;
      } else if (!patten_phone.test(phoneNumber)) {
        errorText = "번호오류";
        this.validator = false;
        return errorText;
      } else if (this.hashNum.has(phoneNumber.replace(/-/g, ""))) {
        errorText = "번호중복";
        this.validator = false;
        return errorText;
      } else {
        // 자동 변경 정보 체크
        let acText = [];

        this.autoMsg.forEach((element) => {
          if (!data[element]) {
            acText.push(element);
          }
        });

        if (!this.$gfnCommonUtils.isEmpty(acText)) {
          errorText = `자동변경정보 누락 (${acText.join(", ")})`;
          this.validator = false;
        } else {
          errorText = "정상";
          this.validator = true;
          this.hashNum.set(phoneNumber.replace(/-/g, ""), "phone");
        }
      }
      return errorText;
    },
    // 주소록 등록
    sendAddress() {
      this.$store.commit("startSpinner");
      if (this.excelData["paramData"].length > 0) {
        this.$emit(
          "excelAddrinfoSet",
          this.excelData["paramData"].filter((e) => {
            return e.validator === true;
          })
        );

        this.ModalClose();
      } else {
        this.ModalClose();
      }
      this.fnInit();
      this.$store.commit("endSpinner");
    },
    //초기화
    fnInit() {
      this.validator = false;
      this.allChecked = false;
      this.excelData = {
        paramData: [],
      };
      this.filePath = false;
      this.fileName = "";
    },
    ModalClose() {
      var dimmed = document.getElementsByClassName("dimmed");
      var wrap = document.getElementsByClassName("popup-wrap");
      var obj = wrap[0].childElementCount;
      dimmed[0].style.display = "none";
      wrap[0].style.display = "none";
      for (var i = 0; i < obj; i++) {
        var target = document.getElementsByClassName("popup");
        target[i].style.display = "none";
        target[i].classList.remove("open");
      }
      this.removeFile();
    },
    fnToastMessage(msg) {
      this.$toast({ component: ToastComponentVue, props: { msg } });
    },
    fnPhoneDash(phoneNumber, index) {
      const pattern_notNumber = /[^-0-9]/g; // 숫자가 아닌것 체크
      const pattern_kor = /[ㄱ-ㅎ|ㅏ-ㅣ|가-힣]/;

      this.excelData["paramData"][index].phone = this.$gfnCommonUtils.hpNumberAddDash(this.$gfnCommonUtils.cutStr(phoneNumber.replace(pattern_kor, "").replace(pattern_notNumber, ""), 16));

      // 숫자 외 입력시
      if (pattern_notNumber.test(phoneNumber) || pattern_kor.test(phoneNumber)) {
        this.fnToastMessage("숫자만 입력해 주세요.");
        this.excelData["paramData"][index].phone = phoneNumber.replace(pattern_kor, "").replace(pattern_notNumber, "");
      }
    },
    excelDown(type) {
      let obj = {};
      const phone = { 휴대폰번호: "" };
      this.autoMsg.forEach((e) => {
        obj[e] = "";
      });

      const excelHeader = [Object.assign(phone, obj)];
      // 엑셀 워크시트로 json 내보내기, 배열만 가능
      const dataWS = XLSX.utils.json_to_sheet(excelHeader);
      // 엑셀의 workbook(엑셀파일에 지정된 이름)을 만든다
      const wb = XLSX.utils.book_new();
      // workbook에 워크시트 추가, 시트명은 'addres'
      XLSX.utils.book_append_sheet(wb, dataWS, "address");
      // 엑셀 파일을 내보낸다. 엑셀 파일 저장명은 'people.xlsx'
      if (type === "xls") {
        XLSX.writeFile(wb, "address.xls");
      }
      if (type === "csv") {
        XLSX.writeFile(wb, "address.csv");
      }
    },
    txtDown() {
      const text = ["휴대폰번호"].concat(this.autoMsg).join(",");
      let filename = "address.txt";
      let element = document.createElement("a");
      element.setAttribute("href", "data:application/json;charset=utf-8," + encodeURIComponent(text));
      element.setAttribute("download", filename);

      element.style.display = "none";
      document.body.appendChild(element);

      element.click();
      document.body.removeChild(element);
    },
    /*handleScroll(e) {
      const chooseListThWrap = e.target.parentNode.children[0];
      chooseListThWrap.scrollLeft = e.target.scrollLeft;
    },
    handleThScroll(e) {
      const chooseListWrap = e.target.parentNode.children[1];
      chooseListWrap.scrollLeft = e.target.scrollLeft;
    },*/
  },
};
</script>
