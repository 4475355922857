<template>
    <!--	s: m_section	-->
    <section class="m_section">
      <!--	s: 	contents-->
      <div class="contents">
        <MobileGnb />
        <div class="tabcont_wrap without_tab charge_wrap">
        <h1 class="title">충전 내역</h1>
        <div class="search_box">
          <div class="group">
            <label for="right" class="label">날짜</label>
            <div class="date_wrap">
              <input type="date" class="date" v-model="startDt" :max="endDt" @change="fnChargeHistSearch"/><span></span>
              <input type="date" class="date" v-model="endDt" :min="startDt" :max="$gfnCommonUtils.getCurretDate()" @change="fnChargeHistSearch"/>
            </div>
          </div>

          <button class="btn" @click="fnChargeHistSearch">조회</button>
        </div>
        <div class="list_area">

          <div class="fixed-table-cover">
              <div class="fixed-table-container" style="width: 1074px;">
                <div class="fixed-table-header-bg"></div>
                <div class="fixed-table-wrapper">
                  <table class="fixed-table" style="table-layout: fixed">
                    <thead>
                      <tr>
                        <th class="td_date" style="width: 20%">
                          <div class="th-text">충전일</div>
                        </th>
                        <th class="td_pay" style="width: 20%">
                          <div class="th-text">결제금액</div>
                        </th>
                        <th class="td_charge" style="width: 20%">
                          <div class="th-text">충전금액</div>
                        </th>
                        <th class="td_method" style="width: 20%">
                          <div class="th-text">결제방법</div>
                        </th>  
                        <th class="td_download" style="width: 20%">
                          <div class="th-text">영수증</div>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-if="totCnt == 0" class="empty">
                        <td colspan="5">검색 결과가 없습니다.</td>
                      </tr>
                      <tr v-for="(item,i) in list" :key="i">
                        <td class="td_date">{{item.approvedAt}}</td>
                        <td class="td_pay">{{item.amount}}원</td>
                        <td class="td_charge">{{item.chargeAmt}}원</td>
                        <td class="td_method">{{item.method}}</td>
                        <td class="td_download">
                          <button class="btn white download" @click="receiptOpen(item.receiptUrl)">영수증 출력</button>
                        </td>
                      </tr>
                      
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          <div class="paging_wrap" v-show="totCnt > params.listSize">
            <!-- 페이징 컴포넌트 -->
            <PageLayer ref="updatePaging" @fnClick="fnPaging" :listTotalCnt="totCnt" :selected="params.listSize" :pageNum="params.pageNo"></PageLayer>
          </div>
        </div>
      </div>
      </div>
    <!--	e: 	contents	-->
    </section>
    <!--	e: m_section	-->
</template>
<script>
  import MobileGnb from "@/components/MobileGnb.vue";
  import PageLayer from "@/components/PageLayer.vue";
  import chargeApi from "@/modules/charge/service/chargeApi";
  import confirm from "@/modules/commonUtil/service/confirm";
  
  export default {
    name: "chargingHistory",
    components: { 
      PageLayer, 
      MobileGnb 
    },
    props: {
      
    },
    data() {
      return {
        startDt: this.$gfnCommonUtils.getCurretDate(), //검색 시작일
        endDt: this.$gfnCommonUtils.getCurretDate(), //검색 종료일
        nowDate: "",
        list : [],
        totCnt: 0,
        params: {
          startDt: '', //검색 시작일
          endDt: '', //검색 종료일
          listSize: 10,
          pageNo: 1
        }
      };
    },
    created() {
     
    },
    mounted() {
      this.fnSearch();
    },
    methods: {
      fnPaging(pageNo){
        this.params.pageNo = pageNo;
        this.fnSearch();
      },
      fnChargeHistSearch(){
        this.$refs.updatePaging.fnAllDecrease();
      },
      async fnSearch(){
        const isAuth = this.$store.getters["login/isAuth"];
        
        if (isAuth) {
          if (this.$gfnCommonUtils.isEmpty(this.startDt)) {
            console.log("2");
            confirm.fnAlert("", "검색 시작일을 선택해 주세요.", "alert");
            return false;
          }

          if (this.$gfnCommonUtils.isEmpty(this.endDt)) {
            confirm.fnAlert("", "검색 종료일을 선택해 주세요.", "alert");
            return false;
          }

          if (this.startDt.replaceAll("-", "") > this.endDt.replaceAll("-", "")) {
            confirm.fnAlert("", "검색 시작일이 종료일보다 클 수 없습니다.", "alert");
            return false;
          }
          
          this.list = [];
          this.totCnt = 0;

          this.params.startDt = this.$gfnCommonUtils.formatDate(new Date(this.startDt),"yyyymmdd");
          this.params.endDt = this.$gfnCommonUtils.formatDate(new Date(this.endDt),"yyyymmdd");
          
          await chargeApi
            .payHist(this.params)
            .then((response) => {
              var result = response.data;
              if (result.success) {
                if(result.data != null){
                  this.list = result.data.list;
                  this.totCnt = result.data.totCnt;
                }
              }else{
                confirm.fnAlert("", result.data.message, "alert");
              }
            })
            .catch((error) => {
              confirm.fnAlert("", "오류가 발생하였습니다.", "alert");
              console.log(error);
            });
        }
      },
      receiptOpen(receiptUrl){
        window.open(receiptUrl,"receipPopup","top=0, left=50, width=1046px, height=650px");
      }
    },
  };
  </script>