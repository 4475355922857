<template>
  <div class="cont">{{ msg }}</div>
</template>
<script>
export default {
  props: ["msg"],
  methods: {
    /* clicked() {
       Emit a "click" event when clicked.
       Can be any event though and even pass parameters back
      this.$emit("click");
    }, */
  },
};
</script>
