import Vue from "vue";

// Entity Html 치환
Vue.filter("fromHtmlEntities", (value) => {
  return Vue.prototype.$gfnCommonUtils.fromHtmlEntities(value);
});

//핸도폰 번호 '-' 적용 필터
Vue.filter("phoneNumAddDash", (value) => {
  return Vue.prototype.$gfnCommonUtils.phoneNumAddDash(value);
});

//핸도폰 번호 '-' 적용 필터2
Vue.filter("hpNumberAddDash", (value) => {
  return Vue.prototype.$gfnCommonUtils.hpNumberAddDash(value);
});

//핸도폰 번호 '-' 삭제 필터
Vue.filter("hpNumberRemoveDash", (value) => {
  return Vue.prototype.$gfnCommonUtils.hpNumberRemoveDash(value);
});

//파일문자열줄이기
Vue.filter("fileTextLengthCut", (value, len, lastTxt) => {
  return Vue.prototype.$gfnCommonUtils.fileTextLengthCut(value, len, lastTxt);
});

//빈값 치환
Vue.filter("emptyString", (value, chgTxt) => {
  return Vue.prototype.$gfnCommonUtils.isEmptyStr(value, chgTxt);
});
//빈값
Vue.filter("isEmpty", (value) => {
  return Vue.prototype.$gfnCommonUtils.isEmpty(value);
});

//문자열줄이기
Vue.filter("textLengthCut", (value, len, lastTxt) => {
  return Vue.prototype.$gfnCommonUtils.textLengthCut(value, len, lastTxt);
});

// 문자열 substring
Vue.filter("textSubString", (txt, len) => {
  return Vue.prototype.$gfnCommonUtils.textSubString(txt, len);
});

// 엔터키 치환 \n => <br/>
Vue.filter("newLineToBr", (txt) => {
  return Vue.prototype.$gfnCommonUtils.newLineToBr(txt);
});

//가격 콤마 처리
Vue.filter("formatPrice", (value) => {
  return Vue.prototype.$gfnCommonUtils.formatPrice(value);
});
//숫자 콤마 처리(아무 값 입력 안되어있는 경우 리턴 0)
Vue.filter("formatComma", (value) => {
  return Vue.prototype.$gfnCommonUtils.formatComma(value);
});
//날짜 형식 변경
Vue.filter("formatDate", (value) => {
  return Vue.prototype.$gfnCommonUtils.formatDate(value);
});
//xss unescape
Vue.filter("unescapeXss", (value) => {
  return Vue.prototype.$gfnCommonUtils.unescapeXss(value);
});
// byte format
Vue.filter("formatBytes", (value, decimals) => {
  return Vue.prototype.$gfnCommonUtils.formatBytes(value, decimals);
});

//NOTICE 아이콘 class(noticeType -> class)
Vue.filter("getNotiTypeClass", (noticeType) => {
  if (noticeType == "INFO") return "Information";
  else if (noticeType == "INSPEC") return "Inspect";
  else if (noticeType == "FAULT") return "Fault";
  else return "newIcon";
});

//MMS -> LMS/MMS
Vue.filter("lmsmms", (value) => {
  if (!value) return "";
  else if (value == "MMS") return "LMS/MMS";
  else return value;
});

//FCM(안드로이드), APNS(IOS)
Vue.filter("pushSvcCd", (value) => {
  if (!value) return "";
  else if (value == "APNS") return "APNS(IOS)";
  else if (value == "FCM") return "FCM(안드로이드)";
  else return value;
});
