<template>
  <div class="wrap" :class="wrapClass">
    <!-- 해더메뉴 -->
    <MainHeader
      :menuList="menuList"
      :remainingAmt="remainingAmt"
      :pricePlanBalance="pricePlanBalance"
      :cFwdBalance="cFwdBalance"
      :chrgBalance="chrgBalance"
      :usedAmt="usedAmt"
      :pricePlanUsedAmt="pricePlanUsedAmt"
      :cFwdBalanceUsedAmt="cFwdBalanceUsedAmt"
      :addUsedAmt="addUsedAmt"
      :chrgAmt="chrgAmt"
      :userNm="userNm"
      :userId="userId"
      :subSttusCd="subSttusCd"
      :openDay="openDay"
      :joinDiv="joinDiv"
      :chargeNm="chargeNm"
    />

    <div class="contents_wrap" :class="contentsWrapClass">
      <!-- 네비게이션 -->
      <Nav
        :menuList="menuList"
        :isSms="isSms"
        :isAlt="isAlt"
        :chargeNm="chargeNm"
        :rsvSendDiv="rsvSendDiv"
        :rsvSendStr="rsvSendStr"
        :joinDiv="joinDiv"
        :remainingAmt="remainingAmt"
        :pricePlanBalance="pricePlanBalance"
        :cFwdBalance="cFwdBalance"
        :chrgBalance="chrgBalance"
        :usedAmt="usedAmt"
        :pricePlanUsedAmt="pricePlanUsedAmt"
        :cFwdBalanceUsedAmt="cFwdBalanceUsedAmt"
        :addUsedAmt="addUsedAmt"
        :chrgAmt="chrgAmt"
        :userNm="userNm"
        :userId="userId"
        :isMobile="isMobile"
        :subSttusCd="subSttusCd"
        :openDay="openDay"
      />
      <router-view @chargeInfoCall="fnGetChargeInfo" :autCd="autCd" :joinDiv="joinDiv" />
    </div>

    <!--	s: btn_top	-->
    <div id="btn_top" @click="fnTopScroll"></div>
    <!--	e: btn_top	-->
    <MainFooter />
    <div
      class="dimmed"
      @click="
        mnClose();
        mPreAlimClose();
      "
    ></div>
    <!-- 로딩레이어-->
    <div class="loading_layer">
      <div class="loader"></div>
    </div>
    <div class="loading hidden">
      <div class="uil-ring-css" style="transform: scale(0.79)">
        <div></div>
      </div>
    </div>

    <!-- 모바일 네비게이션 -->
    <NavPopup
      :isSms="isSms"
      :isAlt="isAlt"
      :chargeNm="chargeNm"
      :rsvSendDiv="rsvSendDiv"
      :rsvSendStr="rsvSendStr"
      :joinDiv="joinDiv"
      :remainingAmt="remainingAmt"
      :pricePlanBalance="pricePlanBalance"
      :cFwdBalance="cFwdBalance"
      :chrgBalance="chrgBalance"
      :usedAmt="usedAmt"
      :pricePlanUsedAmt="pricePlanUsedAmt"
      :cFwdBalanceUsedAmt="cFwdBalanceUsedAmt"
      :addUsedAmt="addUsedAmt"
      :chrgAmt="chrgAmt"
      :isAuth="isAuth"
      :userNm="userNm"
      :userId="userId"
      :isMobile="isMobile"
      :subSttusCd="subSttusCd"
      :openDay="openDay"
    />
    <!-- Nav Popup -->

    <confirm></confirm>
  </div>
</template>

<script>
import Vue from "vue";
import confirm from "@/modules/commonUtil/service/confirm";
import MainHeader from "@/components/MainHeader.vue";
import MainFooter from "@/components/MainFooter.vue";
import Nav from "../components/Nav";
import NavPopup from "@/components/NavPopup.vue";
import store from "../store/index";
import httpClient from "@/common/http-client";
import loginApi from "@/modules/login/service/loginApi";

Vue.use(confirm);

export default {
  name: "publicLayout",
  components: {
    MainHeader,
    MainFooter,
    Nav,
    NavPopup,
  },
  data() {
    return {
      isMobile: false,
      pcStandard: 896, // PC 화면의 Viewport 기준점
      navShow: true, //네비게이션 온오프
      isAuth: "",
      menuList: [],
      wrapClass: "",
      contentsWrapClass: "",
      isSms: false,
      isAlt: false,
      chargeNm: "",
      rsvSendDiv: false,
      rsvSendStr: "",
      joinDiv:"",
      remainingAmt: 0, //남은 금액
      pricePlanBalance: 0, //요금제 잔액
      cFwdBalance: 0, //이월잔액
      chrgBalance : 0, //충전잔액
      usedAmt: 0, //사용한 금액
      pricePlanUsedAmt: 0, //요금제 사용한 금액
      cFwdBalanceUsedAmt: 0, //이월 사용한 금액
      addUsedAmt: 0, //추가 사용한 금액
      chrgAmt : 0, //충전 사용한 금액
      userNm: "",
      userId: "",
      autCd: "",
      subSttusCd: "",
      openDay: false, // 가입한달
    };
  },
  created() {
    this.serviceClass();
    this.fnSelectSysInfo();
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.handleResize);
  },
  mounted() {
    this.fnUserAgent();
    this.checkIsMobile();
    //this.authView();
    //console.log(process.env.VUE_APP_TARGET_URL);
    window.addEventListener("resize", this.handleResize);
    this.fnMenuList();
    this.fnGetChargeInfo();
    // 모바일 메뉴 제어
    var gnbBtn = document.getElementsByClassName("gnba11");

    for (var i = 0; i < gnbBtn.length; i++) {
      gnbBtn[i].onclick = function () {
        // 클릭이 일어났을 때 기존에 열려 있던 아코디언을 접는다. (1개의 아코디언만 열리게)
        for (var j = 0; j < gnbBtn.length; j++) {
          // 버튼 상태에 입혀진 active 라는 클래스를 지운다.
          gnbBtn[j].classList.remove("active");
          // 버튼 다음에 있는 div 콘텐츠 높이를 0으로 만든다. == 아코디언을 접는다.
          if (this !== gnbBtn[j]) {
            gnbBtn[j].nextElementSibling.style.maxHeight = null;
            gnbBtn[j].parentElement.classList.remove("on");
          }
        }

        this.classList.toggle("active");
        this.parentElement.classList.toggle("on");
        var gnbDepth = this.nextElementSibling;
        if (gnbDepth.style.maxHeight) {
          this.classList.remove("active");
          this.parentElement.classList.remove("on");
          gnbDepth.style.maxHeight = null;
        } else {
          gnbDepth.style.maxHeight = gnbDepth.scrollHeight + "px";
        }
      };
    }

    let Top = document.querySelector("#btn_top"),
      pageInner = window.innerHeight;
    //footerH = document.querySelector(".footer_wrap").clientHeight;
    window.addEventListener("scroll", function () {
      if (this.scrollY > pageInner) {
        Top.classList.add("on");
      } else {
        Top.classList.remove("on");
      }
    });
  },
  computed: {
    getIsAuth: function () {
      return store.getters["login/isAuth"];
    },
  },
  watch: {
    getIsAuth(value) {
      if (value) {
        this.isAuth = true;
        this.fnSelectSysInfo();
        this.fnMenuList();
        this.fnGetChargeInfo();
      } else {
        this.isAuth = false;
      }
    },
    $route(to, from) {
      if (to.path != from.path) {
        /* router path가 변경될 때마다 서버로 접근로그를 저장한다. */
        this.serviceClass();
      }
    },
  },
  methods: {
    authView() {
      this.isAuth = store.getters["login/isAuth"];
      if (!this.isAuth) {
        this.show = false;
      }
    },
    fnSelectSysInfo() {
      httpClient.post("/api/common/selectSysInfo", { headers: { "show-layer": "Yes" } }).then((response) => {
        if (response.data.data) {
          store.commit("hubEzData", response.data.data.hubEzData);
          this.userNm = response.data.data.userNm;
          this.autCd = response.data.data.autCd;
          this.subSttusCd = response.data.data.subSttusCd;
          this.userId = response.data.data.userId;

          if (response.data.data.userId != undefined && response.data.data.userId != null && response.data.data.userId != "") {
            this.$store.commit("login/isAuth", true);
            this.isAuth = true;
          } else {
            this.isAuth = false;
          }
        }
      });
    },
    fnTopScroll() {
      window.scrollTo({ top: 0, behavior: "smooth" });
    },
    async fnMenuList() {
      const isAuth = this.$store.getters["login/isAuth"];
      if (isAuth) this.isAuth = true;

      await loginApi.getMenuForRole().then((response) => {
        var result = response.data;
        if (result.success && result.data != null) {
          this.menuList = result.data.children;
        }
      });
    },
    async fnGetChargeInfo() {
      const isAuth = this.$store.getters["login/isAuth"];
      if (isAuth) {
        await loginApi.getUserChargeInfo().then((response) => {
          var result = response.data;
          store.commit("userChargeInfo", result);

          if (result.success && result.data != null) {
            this.chargeNm = result.data.chargeNm;
            this.rsvSendCnt = result.data.rsvSendCnt;
            const rsvSendCntData = result.data.rsvSendCnt ? result.data.rsvSendCnt : "";
            if (rsvSendCntData) {
              this.rsvSendDiv = true;
              this.rsvSendStr = "문자 " + result.data.rsvSendCnt.chSms + "건, 카카오 비즈 " + result.data.rsvSendCnt.chKko + "건";
            } else {
              this.rsvSendDiv = false;
            }

            if (result.data.useChargeData !== "N") {
              //NAV 쪽 금액 정보 셋팅
              const chargeData = result.data.useChargeData ? result.data.useChargeData : "";
              let nowDay = this.$gfnCommonUtils.getCurretMonth();
              let openDay = this.$gfnCommonUtils.formatDate(new Date(chargeData.subsInfo.openDay), "yyyy-mm");
              //가입한 달 로그인
              if (nowDay == openDay) {
                this.openDay = true;
              }
              if (chargeData) {
                /*
                  remainingAmt        남은 금액
                  pricePlanBalance    요금제 잔액
                  cFwdBalance         이월잔액
                  chrgBalance         충전 잔액(이월 충전금 + 소멸 충전금)
                  usedAmt             사용한 금액
                  pricePlanUsedAmt    요금제 사용한 금액
                  cFwdBalanceUsedAmt  이월 사용한 금액
                  addUsedAmt          추가 사용한 금액
                  chrgAmt             충전금 사용한 금액(이월 충전금 + 소멸 충전금)
                */
                this.joinDiv = chargeData.subsInfo.joinDiv;
                this.remainingAmt = Math.round((chargeData.chargeInfo.remainingAmt * 1 + Number.EPSILON) * 100) / 100;
                this.pricePlanBalance = Math.round((chargeData.chargeInfo.pricePlanBalance * 1 + Number.EPSILON) * 100) / 100;
                this.cFwdBalance = Math.round((chargeData.chargeInfo.cfwdBalance * 1 + Number.EPSILON) * 100) / 100;
                this.chrgBalance = Math.round((chargeData.chargeInfo.chrgBalance * 1 + Number.EPSILON) * 100) / 100;
                this.usedAmt = Math.round((chargeData.chargeInfo.usedAmt * 1 + Number.EPSILON) * 100) / 100;
                this.pricePlanUsedAmt = Math.round((chargeData.chargeInfo.pricePlanUsedAmt * 1 + Number.EPSILON) * 100) / 100;
                this.cFwdBalanceUsedAmt = Math.round((chargeData.chargeInfo.cfwdBalanceUsedAmt * 1 + Number.EPSILON) * 100) / 100;
                this.addUsedAmt = Math.round((chargeData.chargeInfo.addUsedAmt * 1 + Number.EPSILON) * 100) / 100;
                this.chrgAmt = Math.round((chargeData.chargeInfo.chrgAmt * 1 + Number.EPSILON) * 100) / 100;
              }
            }

            if (result.data.smsUseDiv == "Y") {
              this.isSms = true;
            }
            if (result.data.altUseDiv == "Y") {
              this.isAlt = true;
            }
          }
        });
      }
    },
    ModalClose() {
      var dimmed = document.getElementsByClassName("dimmed");
      var wrap = document.getElementsByClassName("popup-wrap");
      var obj = wrap[0].childElementCount;
      dimmed[0].style.display = "none";
      wrap[0].style.display = "none";
      for (var i = 0; i < obj; i++) {
        var target = document.getElementsByClassName("popup");
        target[i].style.display = "none";
        target[i].classList.remove("open");
      }
    },
    serviceClass() {
      const route = this.$router.history.current.fullPath;
      if (route.indexOf("/main") > -1) {
        if (route.indexOf("/serviceInfo/main") > -1) {
        this.wrapClass = "main_wrap service";
        this.contentsWrapClass = "service_contents";
        }else{
          this.wrapClass = "main_wrap";
          this.contentsWrapClass = "main_contents";
        }
      } else {
        this.wrapClass = "";
        this.contentsWrapClass = "";
      }
    },
    checkIsMobile: function () {
      this.isMobile = window.innerWidth < this.pcStandard || screen.width < this.pcStandard;
      store.commit("isMobile", this.isMobile);
      if (!this.isMobile) {
        this.mnClose();
      }
    },
    handleResize: function () {
      this.$nextTick(function () {
        this.checkIsMobile();
      });
    },
    mnClose() {
      const tmWrap = document.querySelector(".tm_wrap"),
        dimmed = document.querySelector(".dimmed");

      tmWrap.classList.remove("on");
      dimmed.classList.remove("open");
    },
    mPreAlimClose() {
      const path = this.$route.path;
      let preview = false;
      if (path.indexOf("/sms") > -1 || path.indexOf("/alimtalk") > -1 || path.indexOf("/rsvAlimModi") > -1 || path.indexOf("/rsvMsgSmsModi") > -1) {
        preview = true;
      }

      if (preview) {
        const mAlimPreview = document.querySelector(".m_preview_alimtalk");
        mAlimPreview.classList.remove("on");
      }
      const dimmed = document.querySelector(".dimmed");
      dimmed.classList.remove("open");
    },
    fnUserAgent() {
      const agent = window.navigator.userAgent.toLowerCase();
      let checkBrowser = false;
      switch (true) {
        case agent.indexOf("edge") > -1:
          checkBrowser = true;
          break;
        case agent.indexOf("edg/") > -1:
          checkBrowser = true;
          break;
        case agent.indexOf("safari") > -1:
          checkBrowser = true;
          break;
        case agent.indexOf("chrome") > -1:
          checkBrowser = true;
          break;
        default:
          checkBrowser = false;
      }
      if (!checkBrowser) {
        confirm.fnAlert("", "해당 웹사이트는 크롬(Chrome), 엣지(Edge), 사파리(Safari)에 최적화되어 있습니다.", "alert");
      }
    },
  },
};
</script>
