import templeteManage from "../views/templeteManage";
import templeteDetail from "../views/templeteDetail";
import templeteDetailUpdate from "../views/templeteDetailUpdate";
import templeteWrite from "../views/templeteWrite";

export default [
  {
    path: "/templeteManage",
    name: "알림톡 템플릿관리",
    component: templeteManage,
  },
  {
    path: "/templeteManage/templete/:tmpltKey",
    name: "알림톡 승인 템플릿 상세보기",
    component: templeteDetail,
    props: true,
  },
  {
    path: "/templeteManage/templeteUpdate/:tmpltKey",
    name: "알림톡 반려 템플릿 상세보기",
    component: templeteDetailUpdate,
    props: true,
  },
  {
    path: "/templeteManage/templeteWrite",
    name: "알림톡 템플릿등록",
    component: templeteWrite,
  },
];
