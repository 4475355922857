<template>
  <section class="m_section">
    <Inquiry v-if="inquiry" />
    <InquiryMember v-if="isAuth === true" />
    <div class="contents" v-if="isAuth === false && inquiry === false">
      <MobileGnb />
      <div class="tabcont_wrap without_tab inquiry_login">
        <h2 class="title with_desc">상담하기</h2>
        <!-- <p class="desc">회원이 아니신 경우 <span class="pink">비회원 상담하기</span>를 클릭 하시면 상담을 받을 수 있습니다.</p> 0705 3차 언어검수 삭제 -->
        <div class="privacy_box">
          <p>U+메시지허브이지는 고객님의 문의사항에 답변하기 위해 이름, 이메일 주소, 휴대폰 번호, 전화번호 수집 후 1개월 동안 보유 및 이용합니다.</p>
          <!-- 0705 3차 언어검수 수정 -->
        </div>
        <div class="chkbox">
          <input type="checkbox" class="chk" id="privacy" v-model="idvChk" />
          <label for="privacy">(필수) 개인정보 수집 및 이용에 동의합니다.</label>
        </div>
        <div class="btn_wrap">
          <a class="btn large_pink" @click="fnInquiry">비회원으로 상담하기</a>
          <router-link to="/login" class="btn large_white">로그인</router-link>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import confirm from "@/modules/commonUtil/service/confirm";
import Inquiry from "../components/Inquiry.vue";
import InquiryMember from "../components/InquiryMember.vue";
import MobileGnb from "@/components/MobileGnb.vue";

export default {
  name: "defaultName",
  components: { Inquiry, InquiryMember, MobileGnb },
  mounted() {
    this.isAuth = this.$store.getters["login/isAuth"];
  },
  data() {
    return {
      isAuth: false,
      inquiry: false,
      idvChk:false
    };
  },
  methods: {
    //비회원 상담하기
    fnInquiry() {
      if(this.idvChk){
        this.inquiry = true;
      }else{
        confirm.fnAlert("", "개인정보 수집 및 이용 동의가<br />있어야 비회원 상담이 가능합니다.", "alert");
        return false;
      }
    }
  },
};
</script>
