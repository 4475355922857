<template>
  <div class="popup idedit tit modal25">
    <div class="pop-head">
      <h3 class="pop-tit">채널 아이디 수정</h3>
    </div>
    <form action="" autocomplete="off">
      <div class="pop-cont">
        <div class="regist_row aic">
          <h3 class="lh150">채널 검색용 아이디 입력</h3>
          <div class="input_wrap">
            <input type="text" class="input" v-model="chnlId" disabled />
          </div>
        </div>
        <div class="regist_row">
          <h3>업종 카테고리</h3>
          <div class="input_wrap">
            <select name="" id="" v-model="categoryCode" class="select" disabled>
              <option value="null" selected>카테고리 선택</option>
              <option :value="cate.code" v-for="(cate, index) in categoryList" :key="index">{{ cate.name }}</option>
            </select>
          </div>
        </div>
        <div class="regist_row">
          <h3>상태</h3>
          <div class="input_wrap">
            <span><input type="radio" class="radio" id="state01" value="Y" v-model="dormYn" disabled /><label for="state01">휴면</label></span>
            <span><input type="radio" class="radio" id="state02" value="N" v-model="dormYn" /><label for="state02">사용 상태</label></span>
          </div>
        </div>
      </div>
      <div class="pop-btn2">
        <!-- 220615 수정 : 위치변경 -->
        <button type="button" class="btn large_pink" :disabled="orgDormYn === 'N' && dormYn === 'N'" @click="fnChnlEdit">수정</button>
        <button type="button" class="btn large_white" @click="ModalClose()">취소</button>
      </div>
    </form>
  </div>
</template>
<script>
import sendNumberApi from "../service/sendNumberApi.js";
import loginApi from "@/modules/login/service/loginApi";
import confirm from "@/modules/commonUtil/service/confirm.js";

export default {
  name: "ChannelIdEdit",
  components: {},
  props: {
    chnlData: Object,
  },
  watch: {
    chnlData: {
      handler: function (newChnlData) {
        this.categoryCode = newChnlData.chnlCtgCd;
        this.chnlId = newChnlData.chnlId;
        (this.orgDormYn = this.chnlData.dormYn), (this.dormYn = newChnlData.dormYn);
        this.senderKey = newChnlData.sndrprofKey;
      },
      deep: true,
    },
  },
  data() {
    return {
      dormYn: "", // 휴먼 여부
      orgDormYn: "",
      chnlId: "", // 채널ID
      senderKey: "", // senderKey 휴면 해제시 필요
      category: [],
      categoryList: [],
      categoryCode: "null",
    };
  },
  mounted() {
    //this.getKkoCategory();
  },
  methods: {
    // 카카오톡 카테고리 가져오기
    getKkoCategory() {
      sendNumberApi.getKkoCategory({}).then((response) => {
        if (response.data.success) {
          this.category = response.data.data;
          const cate1 = Object.keys(this.category).filter((e) => e.length === 3);
          let cate = [];
          let name = "";
          cate1.forEach((e) => {
            name = this.category[e].name;
            let name2 = "";
            this.category[e].childs.forEach((e) => {
              name2 = e.name;
              let name3 = "";
              e.childs.forEach((e) => {
                name3 = e.name;
                cate.push({ name: name + "/" + name2 + "/" + name3, code: e.code });
              });
            });
          });

          this.categoryList = cate;
        } else {
          confirm.fnAlert("", "카테고리 불러오기에 실패했습니다.", "alert");
        }
      });
    },
    logout() {
      this.ModalClose();
      this.$store.commit("login/isAuth", false);
      this.$store.commit("hubEzData", []);
      loginApi.logout().then((response) => {
        if (response.data.success) {
            this.$router.push({  path: "/",  });
        }
      });
    },

    ModalClose() {
      var dimmed = document.getElementsByClassName("dimmed");
      var wrap = document.getElementsByClassName("popup-wrap");
      var obj = wrap[0].childElementCount;
      dimmed[0].style.display = "none";
      wrap[0].style.display = "none";
      for (var i = 0; i < obj; i++) {
        var target = document.getElementsByClassName("popup");
        target[i].style.display = "none";
        target[i].classList.remove("open");
      }
    },
    // 카카오톡 채널 휴면 해제
    fnChnlEdit() {
      const params = {
        sndrprofKey: this.senderKey,
      };

      sendNumberApi.saveRecoverKkoCh(params).then((respose) => {
        if (respose.data.success) {
          //console.log(respose);
        } else {
          confirm.fnAlert("", respose.data.message, "alert");
        }
      });
    },
  },
};
</script>
