<template>
  <!--	s: m_section	-->
  <section class="m_section">
    <!--	s: 	contents	-->
    <div class="contents">
      <MobileGnb />
      <!-- s: ser_sect ser_sect01 -->
      <div class="ser_sect ser_sect01">
        <div class="cont">
          <div class="title">
            <p class="mtxt">매장, 사무실 어디서든 쉽게 보내는</p>
            <img src="../../../assets/images/logo-white.svg" alt="" />
          </div>
          <div class="box_wrap">
            <ul>
              <li data-aos="fade-up" data-aos-duration="1000" @click="winScroll('ser02')">
                <div class="img"><img src="../../../assets/images/ser_0101.png" alt="" /></div>
                <div class="txt">
                  <p class="btxt">일반문자</p>
                  <p class="stxt">전송 실패 걱정할 필요 없이<span class="block"></span>가장 안정적인 <b>SMS/LMS/MMS</b></p>
                </div>
              </li>
              <li data-aos="fade-up" data-aos-duration="1000" data-aos-delay="100" @click="winScroll('ser03')">
                <div class="img"><img src="../../../assets/images/ser_0102.png" alt="" /></div>
                <div class="txt">
                  <p class="btxt">카카오 비즈메시지</p>
                  <p class="stxt">매일 쓰는 카카오톡으로 메시지를<span class="block"></span>저렴하게 <b>카카오 비즈메시지</b></p>
                </div>
              </li>
              <li data-aos="fade-up" data-aos-duration="1000" data-aos-delay="200" @click="winScroll('ser04')">
                <div class="img"><img src="../../../assets/images/ser_0103.png" alt="" /></div>
                <div class="txt">
                  <p class="btxt">RCS &nbsp;<img src="../../../assets/images/icon_service_before.png?ver=1" alt=""/></p>
                  <p class="stxt">매장 프로필, 템플릿, 바로가기 버튼 등<span class="block"></span>더욱 다양한 기능의 <b>차세대 문자메시지</b></p>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <!-- e: ser_sect ser_sect01 -->
      <!-- s: ser_sect ser_sect02 : 문자메시지 -->
      <div class="ser_sect ser_sect02" id="ser02">
        <div class="cont">
          <div class="title">
            <p class="btxt">일반문자</p>
          </div>
          <div class="sect02_slider swiper-slider">
            <template v-if="isMobile">
              <swiper class="swiper-wrapper" :options="sect02Swiper">
                <swiper-slide class="swiper-slide" data-aos="fade-up" data-aos-duration="1000">
                  <div class="img"><img src="../../../assets/images/ser_0201.png" alt="" /></div>
                  <div class="txt">
                    <p class="tit">SMS</p>
                    <p class="desc">한글 45자</p>
                  </div>
                </swiper-slide>
                <swiper-slide class="swiper-slide" data-aos="fade-up" data-aos-duration="1000" data-aos-delay="100">
                  <div class="img"><img src="../../../assets/images/ser_0202.png" alt="" /></div>
                  <div class="txt">
                    <p class="tit">LMS</p>
                    <p class="desc">한글 1,000자</p>
                  </div>
                </swiper-slide>
                <swiper-slide class="swiper-slide" data-aos="fade-up" data-aos-duration="1000" data-aos-delay="200">
                  <div class="img"><img src="../../../assets/images/ser_0203.png" alt="" /></div>
                  <div class="txt">
                    <p class="tit">MMS</p>
                    <p class="desc">한글 1,000자 + 사진 3장, 최대 합계 300kbyte</p>
                  </div>
                </swiper-slide>
              </swiper>
              <div class="tab02_pagination"></div>
            </template>
            <template v-if="!isMobile">
              <div class="swiper-wrapper">
                <div class="swiper-slide" data-aos="fade-up" data-aos-duration="1000">
                  <div class="img"><img src="../../../assets/images/ser_0201.png" alt="" /></div>
                  <div class="txt">
                    <p class="tit">SMS</p>
                    <p class="desc">한글 45자</p>
                  </div>
                </div>
                <div class="swiper-slide" data-aos="fade-up" data-aos-duration="1000" data-aos-delay="100">
                  <div class="img"><img src="../../../assets/images/ser_0202.png" alt="" /></div>
                  <div class="txt">
                    <p class="tit">LMS</p>
                    <p class="desc">한글 1,000자</p>
                  </div>
                </div>
                <div class="swiper-slide" data-aos="fade-up" data-aos-duration="1000" data-aos-delay="200">
                  <div class="img"><img src="../../../assets/images/ser_0203.png" alt="" /></div>
                  <div class="txt">
                    <p class="tit">MMS</p>
                    <p class="desc">한글 1,000자 + 사진 3장, 최대 합계 300kbyte</p>
                  </div>
                </div>
              </div>
            </template>
          </div>
        </div>
      </div>
      <!-- e: ser_sect ser_sect02 : 문자메시지 -->
      <!-- s: ser_sect ser_sect03 : 카카오톡비즈 -->
      <div class="ser_sect ser_sect03" id="ser03">
        <div class="title">
          <p class="btxt">카카오 비즈메시지</p>
        </div>
        <div class="tab_list tab_list1">
          <ul>
            <li class="tab_li tab_li1 on" @click="openTab1($event, 'tabcont31')">알림톡</li>
            <li class="tab_li tab_li1" @click="openTab1($event, 'tabcont32')">친구톡</li>
          </ul>
        </div>
        <div class="tab_cont">
          <div class="tabcont tabcont1 tabcont31 on" id="tabcont31">
            <div class="topTxt">
              <p class="mtxt">공지사항, 배송, 주문 등 중요한 정보가 담긴 메시지를 보낼 수 있어요.</p>
            </div>
            <div class="tabcon">
              <div class="tab_swiper31">
                <template v-if="isMobile">
                  <swiper class="swiper-wrapper" :options="sect31Swiper">
                    <swiper-slide class="swiper-slide" data-aos="fade-up" data-aos-duration="1000">
                      <div class="img"><img src="../../../assets/images/ser_0311.png" alt="" /></div>
                      <div class="txt">
                        <div class="tit">알림톡 텍스트형</div>
                        <div class="desc">한글/영어 1,000자 + 버튼 5개</div>
                      </div>
                    </swiper-slide>
                    <swiper-slide class="swiper-slide" data-aos="fade-up" data-aos-duration="1000" data-aos-delay="100">
                      <div class="img"><img src="../../../assets/images/ser_0312.png" alt="" /></div>
                      <div class="txt">
                        <div class="tit">알림톡 이미지형</div>
                        <div class="desc">한글/영어 1,000자 + 버튼 5개 + 이미지(최대 500kbyte, 1장)</div>
                      </div>
                    </swiper-slide>
                  </swiper>
                  <!-- 삭제 <p class="pink">※ 카카오 인증마크를 통해 고객 신뢰를 얻을 수 있어요</p> -->
                  <div class="tab31_pagination"></div>
                </template>
                <template v-if="!isMobile">
                  <ul class="swiper-wrapper">
                    <li class="swiper-slide" data-aos="fade-up" data-aos-duration="1000">
                      <div class="img"><img src="../../../assets/images/ser_0311.png" alt="" /></div>
                      <div class="txt">
                        <div class="tit">알림톡 텍스트형</div>
                        <div class="desc">한글/영어 1,000자 + 버튼 5개</div>
                      </div>
                    </li>
                    <li class="swiper-slide" data-aos="fade-up" data-aos-duration="1000" data-aos-delay="100">
                      <div class="img"><img src="../../../assets/images/ser_0312.png" alt="" /></div>
                      <div class="txt">
                        <div class="tit">알림톡 이미지형</div>
                        <div class="desc">한글/영어 1,000자 + 버튼 5개 + 이미지(최대 500kbyte, 1장)</div>
                      </div>
                    </li>
                  </ul>
                  <!-- 삭제 <p class="pink">※ 카카오 인증마크를 통해 고객 신뢰를 얻을 수 있어요</p> -->
                </template>
              </div>
            </div>
          </div>
          <div class="tabcont tabcont1 tabcont32" id="tabcont32" style="opacity: 0">
            <div class="topTxt">
              <p class="mtxt">매장/기업 채널을 친구로 등록한 고객에게 이벤트, 매장 홍보 등 광고 메시지를 보낼 수 있어요.</p>
            </div>
            <div class="tabcon">
              <div class="tab_swiper32">
                <template v-if="isMobile">
                  <swiper class="swiper-wrapper" :options="sect32Swiper">
                    <swiper-slide class="swiper-slide" data-aos="fade-up" data-aos-duration="1000">
                      <div class="img"><img src="../../../assets/images/ser_0321a.png" alt="" /></div>
                      <div class="txt">
                        <div class="tit">친구톡 텍스트형</div>
                        <div class="desc">한글/영어 1,000자 + 버튼 5개</div>
                      </div>
                    </swiper-slide>
                    <swiper-slide class="swiper-slide" data-aos="fade-up" data-aos-duration="1000" data-aos-delay="100">
                      <div class="img"><img src="../../../assets/images/ser_0322a.png" alt="" /></div>
                      <div class="txt">
                        <div class="tit">친구톡 이미지형</div>
                        <div class="desc">한글/영어 400자 + 버튼 5개<span class="block"></span>+ 이미지(최대 500kbyte, 1장)</div>
                      </div>
                    </swiper-slide>
                    <swiper-slide class="swiper-slide" data-aos="fade-up" data-aos-duration="1000" data-aos-delay="200">
                      <div class="img"><img src="../../../assets/images/ser_0323a.png" alt="" /></div>
                      <div class="txt">
                        <div class="tit">친구톡 이미지형</div>
                        <div class="desc">한글/영어 76자 + 버튼 1개<span class="block"></span>+ 이미지(최대 500kbyte, 1장)</div>
                      </div>
                    </swiper-slide>
                  </swiper>
                  <div class="tab32_pagination"></div>
                </template>
                <template v-if="!isMobile">
                  <ul class="swiper-wrapper">
                    <li class="swiper-slide" data-aos="fade-up" data-aos-duration="1000">
                      <div class="img"><img src="../../../assets/images/ser_0321a.png" alt="" /></div>
                      <div class="txt">
                        <div class="tit">친구톡 텍스트형</div>
                        <div class="desc">한글/영어 1,000자 + 버튼 5개</div>
                      </div>
                    </li>
                    <li class="swiper-slide" data-aos="fade-up" data-aos-duration="1000" data-aos-delay="100">
                      <div class="img"><img src="../../../assets/images/ser_0322a.png" alt="" /></div>
                      <div class="txt">
                        <div class="tit">친구톡 이미지형</div>
                        <div class="desc">한글/영어 400자 + 버튼 5개<span class="block"></span>+ 이미지(최대 500kbyte, 1장)</div>
                      </div>
                    </li>
                    <li class="swiper-slide" data-aos="fade-up" data-aos-duration="1000" data-aos-delay="200">
                      <div class="img"><img src="../../../assets/images/ser_0323a.png" alt="" /></div>
                      <div class="txt">
                        <div class="tit">친구톡 이미지형</div>
                        <div class="desc">한글/영어 76자 + 버튼 1개<span class="block"></span>+ 이미지(최대 500kbyte, 1장)</div>
                      </div>
                    </li>
                  </ul>
                </template>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- e: ser_sect ser_sect03 : 카카오톡비즈 -->
      <!-- s: ser_sect ser_sect04 : RCS -->
      <div class="ser_sect ser_sect04" id="ser04">
        <div class="title">
          <p class="btxt">RCS &nbsp;<img src="../../../assets/images/icon_service_before.png?ver=1" alt=""/></p>
          <p class="mtxt">100자 SMS 부터 바로가기 버튼까지 가장 진화한 차세대 문자메시지 서비스 입니다.</p>
        </div>
        <div class="tab_list">
          <ul>
            <li class="tab_li tab_li2 on" @click="openTab2($event, 'tabcont41')">RCS 템플릿</li>
            <li class="tab_li tab_li2" @click="openTab2($event, 'tabcont42')">RCS SMS/LMS/MMS</li>
            <li class="tab_li tab_li2" @click="openTab2($event, 'tabcont43')">RCS 특징</li>
          </ul>
        </div>
        <div class="tab_cont">
          <div class="tabcont tabcont2 tabcont41 on" id="tabcont41" style="display: block; opacity: 1">
            <div class="topTxt">
              <p class="mtxt">결제나 배송 정보, 공지사항 등을 정해진 디자인 형식에 맞춰 깔끔하게 보낼 수 있습니다.</p>
            </div>
            <div class="tabcon">
              <div class="tab_swiper41">
                <template v-if="isMobile && rcsTab === 'tabcont41'">
                  <swiper class="swiper-wrapper" :options="sect41Swiper">
                    <swiper-slide class="swiper-slide" data-aos="fade-up" data-aos-duration="1000">
                      <div class="img"><img src="../../../assets/images/ser_0411.png" alt="" class="pc" /><img src="../../../assets/images/ser_0411_m.png" alt="" class="mobile" /></div>
                      <div class="txt">
                        <div class="tit grey">SMS</div>
                        <div class="tit">RCS 템플릿 전환시</div>
                      </div>
                    </swiper-slide>
                    <swiper-slide class="swiper-slide" data-aos="fade-up" data-aos-duration="1000" data-aos-delay="100">
                      <div class="img"><img src="../../../assets/images/ser_0412.png" alt="" class="pc" /><img src="../../../assets/images/ser_0412_m.png" alt="" class="mobile" /></div>
                      <div class="txt">
                        <div class="tit grey">LMS</div>
                        <div class="tit">RCS 템플릿 전환시</div>
                      </div>
                    </swiper-slide>
                  </swiper>
                  <div class="tab41_pagination"></div>
                </template>
                <template v-if="!isMobile">
                  <swiper class="swiper-wrapper" :options="sect41Swiper">
                    <swiper-slide class="swiper-slide" data-aos="fade-up" data-aos-duration="1000">
                      <div class="img"><img src="../../../assets/images/ser_0411.png" alt="" class="pc" /><img src="../../../assets/images/ser_0411_m.png" alt="" class="mobile" /></div>
                      <div class="txt">
                        <div class="tit grey">SMS</div>
                        <div class="tit">RCS 템플릿 전환시</div>
                      </div>
                    </swiper-slide>
                    <swiper-slide class="swiper-slide" data-aos="fade-up" data-aos-duration="1000" data-aos-delay="100">
                      <div class="img"><img src="../../../assets/images/ser_0412.png" alt="" class="pc" /><img src="../../../assets/images/ser_0412_m.png" alt="" class="mobile" /></div>
                      <div class="txt">
                        <div class="tit grey">LMS</div>
                        <div class="tit">RCS 템플릿 전환시</div>
                      </div>
                    </swiper-slide>
                  </swiper>
                  <div class="tab41_pagination"></div>
                </template>
              </div>
            </div>
          </div>
          <div class="tabcont tabcont2 tabcont42" id="tabcont42" style="opacity: 0">
            <div class="topTxt">
              <p class="mtxt">다양한 이미지, 버튼, 형식을 통해 고객과 소통할 수 있는 문자메시지 서비스입니다.</p>
            </div>
            <div class="tabcon">
              <div class="tab_swiper42">
                <template v-if="isMobile && rcsTab === 'tabcont42'">
                  <swiper class="swiper-wrapper" :options="sect42Swiper">
                    <swiper-slide class="swiper-slide" data-aos="fade-up" data-aos-duration="1000">
                      <div class="img"><img src="../../../assets/images/ser_0421.png" alt="" /></div>
                      <div class="txt"><div class="tit">SMS</div></div>
                    </swiper-slide>
                    <swiper-slide class="swiper-slide" data-aos="fade-up" data-aos-duration="1000" data-aos-delay="100">
                      <div class="img"><img src="../../../assets/images/ser_0422.png" alt="" /></div>
                      <div class="txt"><div class="tit">LMS</div></div>
                    </swiper-slide>
                    <swiper-slide class="swiper-slide" data-aos="fade-up" data-aos-duration="1000" data-aos-delay="200">
                      <div class="img"><img src="../../../assets/images/ser_0423.png" alt="" /></div>
                      <div class="txt"><div class="tit">MMS</div></div>
                    </swiper-slide>
                  </swiper>
                  <div class="tab42_pagination"></div>
                </template>
                <template v-if="!isMobile">
                  <ul class="swiper-wrapper">
                    <li class="swiper-slide" data-aos="fade-up" data-aos-duration="1000">
                      <div class="img"><img src="../../../assets/images/ser_0421.png" alt="" /></div>
                      <div class="txt"><div class="tit">SMS</div></div>
                    </li>
                    <li class="swiper-slide" data-aos="fade-up" data-aos-duration="1000" data-aos-delay="100">
                      <div class="img"><img src="../../../assets/images/ser_0422.png" alt="" /></div>
                      <div class="txt"><div class="tit">LMS</div></div>
                    </li>
                    <li class="swiper-slide" data-aos="fade-up" data-aos-duration="1000" data-aos-delay="200">
                      <div class="img"><img src="../../../assets/images/ser_0423.png" alt="" /></div>
                      <div class="txt"><div class="tit">MMS</div></div>
                    </li>
                  </ul>
                </template>
              </div>
            </div>
          </div>
          <div class="tabcont tabcont2 tabcont43" id="tabcont43" style="opacity: 0">
            <div class="topTxt">
              <p class="mtxt">고객을 사로잡는 광고메시지를<span class="block"></span>이미지/동영상 배치, 바로가기 버튼, 다양한 메시지 형식으로 보낼 수 있어요.</p>
            </div>
            <div class="tabcon">
              <div class="tab_swiper43">
                <template v-if="isMobile && rcsTab === 'tabcont43'">
                  <swiper class="swiper-wrapper" :options="sect43Swiper">
                    <swiper-slide class="swiper-slide" data-aos="fade-up" data-aos-duration="1000">
                      <div class="img"><img src="../../../assets/images/ser_0431.png" alt="" /></div>
                      <div class="txt">
                        <div class="tit">브랜드 홈</div>
                        <div class="desc">카카오 프로필처럼 메시지에 담지 못하는<span class="block"></span>매장/기업의 정보(위치, 홈페이지, 전화번호)를 안내</div>
                      </div>
                    </swiper-slide>
                    <swiper-slide class="swiper-slide" data-aos="fade-up" data-aos-duration="1000" data-aos-delay="100">
                      <div class="img"><img src="../../../assets/images/ser_0432.png" alt="" /></div>
                      <div class="txt">
                        <div class="tit">좌우 슬라이딩</div>
                        <div class="desc">최대 6장의 이미지를 카드로<span class="block"></span>구성하여 한번에 전송</div>
                      </div>
                    </swiper-slide>
                    <swiper-slide class="swiper-slide" data-aos="fade-up" data-aos-duration="1000" data-aos-delay="200">
                      <div class="img"><img src="../../../assets/images/ser_0433.png" alt="" /></div>
                      <div class="txt">
                        <div class="tit">URL 대신 바로가기 버튼</div>
                        <div class="desc">다양한 정보를 바로가기 버튼으로<span class="block"></span>깔끔하게 대체하여 클릭 유도</div>
                      </div>
                    </swiper-slide>
                  </swiper>
                  <div class="tab43_pagination"></div>
                </template>
                <template v-if="!isMobile">
                  <ul class="swiper-wrapper">
                    <li class="swiper-slide" data-aos="fade-up" data-aos-duration="1000">
                      <div class="img"><img src="../../../assets/images/ser_0431.png" alt="" /></div>
                      <div class="txt">
                        <div class="tit">브랜드 홈</div>
                        <div class="desc">카카오 프로필처럼 메시지에 담지 못하는<span class="block"></span>매장/기업의 정보(위치, 홈페이지, 전화번호)를 안내</div>
                      </div>
                    </li>
                    <li class="swiper-slide" data-aos="fade-up" data-aos-duration="1000" data-aos-delay="100">
                      <div class="img"><img src="../../../assets/images/ser_0432.png" alt="" /></div>
                      <div class="txt">
                        <div class="tit">좌우 슬라이딩</div>
                        <div class="desc">최대 6장의 이미지를 카드로<span class="block"></span>구성하여 한번에 전송</div>
                      </div>
                    </li>
                    <li class="swiper-slide" data-aos="fade-up" data-aos-duration="1000" data-aos-delay="200">
                      <div class="img"><img src="../../../assets/images/ser_0433.png" alt="" /></div>
                      <div class="txt">
                        <div class="tit">URL 대신 바로가기 버튼</div>
                        <div class="desc">다양한 정보를 바로가기 버튼으로<span class="block"></span>깔끔하게 대체하여 클릭 유도</div>
                      </div>
                    </li>
                  </ul>
                </template>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- e: ser_sect ser_sect04 : RCS -->
      <!-- s: ser_sect ser_sect05 : 비교 -->
      <div class="ser_sect ser_sect05">
        <div class="cont">
          <div class="title">
            <p class="mtxt">일반문자에 비해 <b>보낼 수 있는 글자수가 많고 </b>바로가기 버튼, 템플릿 등<span class="block"></span><b>다양한 기능</b>으로 메시지를 효과적으로 보낼 수 있습니다.</p>
          </div>
          <div class="box_wrap">
            <div class="box" data-aos="fade-up" data-aos-duration="1000">
              <p class="tit">일반문자</p>
              <div class="img"><img src="../../../assets/images/ser_message.png" alt="" class="pc" /><img src="../../../assets/images/ser_message_m.png" alt="" class="mobile" /></div>
            </div>
            <div class="box" data-aos="fade-up" data-aos-duration="1000" data-aos-delay="100">
              <p class="tit pink">RCS &nbsp;<img src="../../../assets/images/icon_service_before.png?ver=1" alt=""/></p>
              <div class="img"><img src="../../../assets/images/ser_rcs.png" alt="" class="pc" /><img src="../../../assets/images/ser_rcs_m.png" alt="" class="mobile" /></div>
            </div>
          </div>
        </div>
      </div>
      <!-- e: ser_sect ser_sect05 : 비교 -->
      <!-- s: ser_sect ser_sect06 : 요금 -->
      <div class="ser_sect ser_sect06">
        <div class="cont">
          <div class="title">
            <p class="btxt">요금 안내</p>
          </div>
          <div class="box_wrap">
            <div class="txt" data-aos="fade-up" data-aos-duration="1000">
              <p class="btxt">U<span class="lg">+</span>기업 서비스와 결합하면 할인!</p>
              <p class="stxt">오피스넷(인터넷), 기업 인터넷전화, 클라우드고객센터 등 <span class="block"></span>U+기업 서비스와 결합하면 <span class="pink b">‘최대 20% 할인’ </span> 받을 수 있어요</p>
              <a :href="serviceJoinUrl" target="_blank" class="link">서비스 가입하기</a>
            </div>
            <div class="img" data-aos="fade-up" data-aos-duration="1000" data-aos-delay="100">
              <img src="../../../assets/images/msect02.png" alt="" class="pc" /><img src="../../../assets/images/ser_0601_m.png" alt="" class="mobile" />
            </div>
          </div>
        </div>
      </div>
      <!-- e: ser_sect ser_sect06 : 요금 -->
      <!-- s: ser_sect ser_sect07 : 요금제 -->
      <div class="ser_sect ser_sect07">
        <div class="cont">
          <div class="title">
            <p class="btxt">요금제 안내</p>
          </div>
          <div class="txt center" data-aos="fade-up" data-aos-duration="1000">
            <div class="tit">후불 정액제(6종)</div>
            <div class="desc">아래와 같이 정액 상품 6종 중 선택하여 월 정액 만큼 사용하실 수 있으며, <span class="block"></span>
              월 정액 금액을 모두 사용하면 추가로 더 사용 가능하고 사용한만큼 요금을 계산하여 청구합니다.
            </div>
          </div>
          <div class="img" data-aos="fade-up" data-aos-duration="1000" data-aos-delay="100">
            <img src="../../../assets/images/ser_0701.png" alt="" class="pc">
            <img src="../../../assets/images/ser_0701_m.png" alt="" class="mobile">
          </div>
          <div class="table_wrap" data-aos="fade-up" data-aos-duration="1000">
            <table class="table_col">
              <colgroup>
                <col width="20%" />
                <col width="20%" />
                <col width="20%" />
                <col width="20%" />
                <col width="20%" />
              </colgroup>
              <thead>
                <tr>
                  <th class="gray_bg br_13" colspan="5"> 후불요금제 (6종)</th>
                </tr>
                <tr>
                  <th rowspan="2">요금제명</th>
                  <th rowspan="2">기본료(A)</th>
                  <th class="pink_bg" colspan="2">U<span class="lg">+ </span>기업 서비스 결합 시</th>
                  <th rowspan="2">실 청구액(D)</th>
                </tr>
                <tr>
                  <th class="pink_bg">할인율(B)</th>
                  <th class="pink_bg">할인금액(C)</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>11요금제</td>
                  <td>11,000원</td>
                  <td class="pink_bg">5%</td>
                  <td class="pink_bg">550원</td>
                  <td>10,450원</td>
                </tr>
                <tr>
                  <td>33요금제</td>
                  <td>33,000원</td>
                  <td class="pink_bg">10%</td>
                  <td class="pink_bg">3,300원</td>
                  <td>29,700원</td>
                </tr>
                <tr>
                  <td>55요금제</td>
                  <td>55,000원</td>
                  <td class="pink_bg">15%</td>
                  <td class="pink_bg">8,250원</td>
                  <td>46,750원</td>
                </tr>
                <tr>
                  <td>110요금제</td>
                  <td>110,000원</td>
                  <td class="pink_bg">20%</td>
                  <td class="pink_bg">22,000원</td>
                  <td>88,000원</td>
                </tr>
                <tr>
                  <td>330요금제</td>
                  <td>330,000원</td>
                  <td class="pink_bg">20%</td>
                  <td class="pink_bg">66,000원</td>
                  <td>264,000원</td>
                </tr>
                <tr>
                  <td>550요금제</td>
                  <td>550,000원</td>
                  <td class="pink_bg">20%</td>
                  <td class="pink_bg">110,000원</td>
                  <td>440,000원</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="txt center" data-aos="fade-up" data-aos-duration="1000">
            <div class="tit">자유 요금제(준비중)</div>
            <div class="desc">서비스를 사용한 후, 1개월마다 사용량을 정산하여 그 금액만큼 후불로 결제하는 요금제입니다.<span class="block"></span>
              매월 1일~말일의 발송 건수에 대한 요금이 익월 청구됩니다.
            </div>
          </div>
          <div class="img" data-aos="fade-up" data-aos-duration="1000" data-aos-delay="100">
            <img src="../../../assets/images/ser_0702.png" alt="" class="pc">
            <img src="../../../assets/images/ser_0702_m.png" alt="" class="mobile">
          </div>
          <div class="txt center" data-aos="fade-up" data-aos-duration="1000" id="pre">
            <div class="tit">선불 요금제</div>
            <div class="desc">일정금액을 선결제하여 충전한 후, 메시지를 발송할 때마다 충전된 금액이 차감되는 요금제입니다.<span class="block"></span>
              충전된 금액의 유효 기간은 5년 입니다.
            </div>
          </div>
          <div class="img" data-aos="fade-up" data-aos-duration="1000" data-aos-delay="100">
            <img src="../../../assets/images/ser_0703.png" alt="" class="pc" style="margin-bottom:35px;">
            <img src="../../../assets/images/ser_0703_m.png" alt="" class="mobile">
          </div>
          <div class="img" data-aos="fade-up" data-aos-duration="1000" data-aos-delay="100">
            <img src="../../../assets/images/ser_0704.png" alt="" class="pc" style="margin-bottom:210px;">
            <img src="../../../assets/images/ser_0704_m.png" alt="" class="mobile">
          </div>
          <div class="title">
            <p class="btxt">문자메시지 개별 단가</p>
          </div>
          <div class="table_wrap" data-aos="fade-up" data-aos-duration="1000">
            <table>
              <colgroup>
                <col width="20%" />
                <col width="20%" />
                <col width="20%" />
                <col width="20%" />
                <col width="20%" />
              </colgroup>
              <thead>
                <tr>
                  <th class="gray_bg">구분</th>
                  <th class="gray_bg">SMS</th>
                  <th class="gray_bg">LMS</th>
                  <th class="gray_bg">MMS</th>
                  <th class="gray_bg">알림톡</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>단가</td>
                  <td>12.1원</td>
                  <td>36.3원</td>
                  <td>104.5원</td>
                  <td>9.35원</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="txt">
            <p class="dot" data-aos="fade-up" data-aos-duration="1000">부가세가 포함된 금액입니다.</p>
            <p class="dot" data-aos="fade-up" data-aos-duration="1000">오피스넷(인터넷), 기업 인터넷전화, 클라우드고객센터 등 U+기업 서비스와 결합해야 할인 금액이 적용됩니다.</p>
            <p class="dot" data-aos="fade-up" data-aos-duration="1000">모든 요금은 서비스를 이용한 뒤 청구되며(후불), 사용한만큼 월정액에서 차감됩니다.</p>
            <p class="dot" data-aos="fade-up" data-aos-duration="1000">
              기본료를 다 쓰면 가입 요금제의 최대 2배까지의 금액을 더 사용하실 수 있고, 사용하신 만큼 요금을 계산하여 청구합니다.<br />
              예시) 55요금제를 모두 소진한 후, 최대 50,000원까지 추가로 사용할 수 있습니다.<br />
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;55요금제를 모두 소진한 후, SMS를 1,000건(12,100원=12.1원X1,000건) 더 사용하면 67,100원이 청구됩니다.
            </p>
            <p class="dot" data-aos="fade-up" data-aos-duration="1000">이번 달에 다 사용하지 못한 잔액은 최대 6개월까지 이월할 수 있습니다.</p>
            <!-- <p class="dot" data-aos="fade-up" data-aos-duration="1000">남은 월정액은 최대 6개월까지 미뤄서 사용할 수 있습니다.</p> -->
            <p class="dot" data-aos="fade-up" data-aos-duration="1000">요금은 계좌 이체 또는 신용카드 자동이체 및 지로 납부할 수 있습니다.</p>
            <p class="dot" data-aos="fade-up" data-aos-duration="1000">
              자세한 요금 문의는 U+기업메시징 고객센터 1544-5992(유료)로 문의하시기 바랍니다. <br />
              - 상담 가능 시간 : 평일 오전 9시~오후 6시(주말과 공휴일은 휴무)
            </p>
          </div>
        </div>
      </div>
      <!-- e: ser_sect ser_sect07 : 요금제 -->
      <!-- s: ser_sect ser_sect08 : 더 알아보기 배너 -->
      <div class="ser_sect ser_sect08">
        <div class="cont">
          <div class="txt">
            <p class="title">쉬운 API 연동은 <br />「메시지허브」</p>
            <a href="https://msghub.uplus.co.kr/public/main" target="_blank" class="more btn link aos-init aos-animate">더 알아보기</a>
          </div>
        </div>
      </div>
    </div>
    <!-- e: ser_sect ser_sect08 : 더 알아보기 배너 -->
    <!--	e: 	contents	-->
  </section>
  <!--	e: m_section	-->
</template>
<script>
import { swiper, swiperSlide } from "vue-awesome-swiper";
import MobileGnb from "@/components/MobileGnb.vue";
import confirm from "@/modules/commonUtil/service/confirm";

export default {
  name: "serviceInfo",
  components: { MobileGnb, swiper, swiperSlide },
  data() {
    return {
      serviceJoinUrl: "/",
      isMobile: false,
      pcStandard: 896, // PC 화면의 Viewport 기준점
      bizTab: "tabcont31",
      rcsTab: "tabcont41",
      sect02Swiper: {
        slidesPerView: 1.4,
        centeredSlides: true,
        observer: true,
        observeParents: true,
        pagination: {
          clickable: true,
          el: ".tab02_pagination",
        },
        loop: false,
        speed: 800,
      },
      sect31Swiper: {
        slidesPerView: 1.4,
        centeredSlides: true,
        observer: true,
        observeParents: true,
        pagination: {
          clickable: true,
          el: ".tab31_pagination",
        },
        loop: false,
        speed: 800,
      },
      sect32Swiper: {
        slidesPerView: 1.4,
        centeredSlides: true,
        observer: true,
        observeParents: true,
        pagination: {
          clickable: true,
          el: ".tab32_pagination",
        },
        loop: false,
        speed: 800,
      },
      sect41Swiper: {
        slidesPerView: 1,
        spaceBetween: 10,
        observer: true,
        observeParents: true,
        pagination: {
          clickable: true,
          el: ".tab41_pagination",
        },
        loop: false,
        speed: 800,
      },
      sect42Swiper: {
        slidesPerView: 1.4,
        centeredSlides: true,
        observer: true,
        observeParents: true,
        pagination: {
          clickable: true,
          el: ".tab42_pagination",
        },
        loop: false,
        speed: 800,
      },
      sect43Swiper: {
        slidesPerView: 1.4,
        centeredSlides: true,
        observer: true,
        observeParents: true,
        pagination: {
          clickable: true,
          el: ".tab43_pagination",
        },
        loop: false,
        speed: 800,
      },
    };
  },
  created() {
    this.checkIsMobile();
  },
  mounted() {
    window.addEventListener("resize", this.handleResize);
    const baseUrl = window.location.origin;
    // 개발기일 경우 서비스 가입 주소
    if (baseUrl.indexOf("mhez-dev.uplus.co.kr") > -1) {
      this.serviceJoinUrl = "https://dev.bizmarket.uplus.co.kr/bizSvcDetail?sltnId=BPZ0000015";
      // 검수기일 경우 서비스 가입 주소
    } else if (baseUrl.indexOf("mhez-qa.uplus.co.kr") > -1) {
      this.serviceJoinUrl = "https://staging.bizmarket.uplus.co.kr/bizSvcDetail?sltnId=BPZ0000015";
      // 상용기일 경우 서비스 가입 주소
    } else if (baseUrl.indexOf("mhez.uplus.co.kr") > -1) {
      this.serviceJoinUrl = "https://bizmarket.uplus.co.kr/bizSvcDetail?sltnId=BPZ0000015";
    }

    if(this.$route.query.action == "pre"){
      setTimeout(() => {
        this.winScroll("pre");
      }, 300);
    } 
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.handleResize);
  },

  methods: {
    // 문자채널 탭
    openTab1(evt, tabName) {
      this.bizTab = tabName;
      var i, tabcontent, tablinks;
      tabcontent = document.getElementsByClassName("tabcont1");
      for (i = 0; i < tabcontent.length; i++) {
        tabcontent[i].style.display = "none";
        tabcontent[i].style.opacity = "0";
        tabcontent[i].classList.remove("on");
        tabcontent[i].classList.remove("aos-init");
        tabcontent[i].classList.remove("aos-animate");
      }
      tablinks = document.getElementsByClassName("tab_li1");
      for (i = 0; i < tablinks.length; i++) {
        tablinks[i].className = tablinks[i].className.replace(" on", "");
      }
      document.getElementById(tabName).style.display = "block";
      document.getElementById(tabName).style.opacity = "1";
      document.getElementById(tabName).classList.add("on");
      document.getElementById(tabName).classList.add("aos-init");
      document.getElementById(tabName).classList.add("aos-animate");
      evt.currentTarget.className += " on";
    },
    openTab2(evt, tabName) {
      this.rcsTab = tabName;
      var i, tabcontent, tablinks;
      tabcontent = document.getElementsByClassName("tabcont2");
      for (i = 0; i < tabcontent.length; i++) {
        tabcontent[i].style.display = "none";
        tabcontent[i].style.opacity = "0";
        tabcontent[i].classList.remove("on");
        tabcontent[i].classList.remove("aos-init");
        tabcontent[i].classList.remove("aos-animate");
      }
      tablinks = document.getElementsByClassName("tab_li2");
      for (i = 0; i < tablinks.length; i++) {
        tablinks[i].className = tablinks[i].className.replace(" on", "");
      }
      document.getElementById(tabName).style.display = "block";
      document.getElementById(tabName).style.opacity = "1";
      document.getElementById(tabName).classList.add("on");
      document.getElementById(tabName).classList.add("aos-init");
      document.getElementById(tabName).classList.add("aos-animate");
      evt.currentTarget.className += " on";
    },
    checkIsMobile: function () {
      this.isMobile = window.innerWidth < this.pcStandard || screen.width < this.pcStandard;
    },
    handleResize: function () {
      this.$nextTick(function () {
        this.checkIsMobile();
      });
    },
    winScroll(target) {
      var location = document.getElementById(target).offsetTop;
      window.scrollTo({ top: location, behavior: "smooth" });
    },
    bizReady() {
      confirm.fnAlert("", "비즈마켓 서비스 신규 오픈 작업으로 이용이 제한됩니다. <br/>(서비스 오픈일 : 2022년 12월 12일)", "alert");
    },
  },
};
</script>
