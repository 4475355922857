<template>
  <div class="history_wrap" :style="preEventToggle ? 'visibility: hidden;' : ''">
    <div class="history">
      <div class="history_items_wrap scr_wrap" v-if="recentSendAlimList.length > 0">
        <swiper class="history_items swiper-wrapper" :options="swiperOption2" ref="rcsMsgList">
          <swiper-slide class="history_item swiper-slide" v-for="(message, index) in recentSendAlimList" :key="index">
            <div class="history_cont">
              <div class="txt_wrap">
                <p class="btxt">{{ message.tmpltName }}</p>
                <p class="stxt" v-html="message.msg.replace(/(?:\r\n|\r|\n)/g, '<br />')"></p>
              </div>
            </div>
            <div class="history_desc">
              <div class="history_txt">
                <p class="date">{{ message.regDt }}</p>
                <p class="tit">{{ message.chnlId }}</p>
              </div>
              <div class="type kakao"></div>
            </div>
          </swiper-slide>
        </swiper>
      </div>
      <div class="history_items_wrap scr_wrap" v-else>
        <!-- <p>최근작성문자 없음</p> -->
      </div>
      <div class="btn_wrap" v-if="recentSendAlimList.length > 0">
        <button class="btn_up swiper-alim-button-next"></button>
        <button class="btn_down swiper-alim-button-prev"></button>
      </div>
    </div>
  </div>
</template>
<script>
import { swiper, swiperSlide } from "vue-awesome-swiper";
import { eventBus } from "@/modules/commonUtil/service/eventBus";
import confirm from "@/modules/commonUtil/service/confirm";

export default {
  name: "RecentMessageList",
  components: { swiper, swiperSlide },
  props: {
    preEventToggle: Boolean,
    modiAuth: {
      type: Boolean,
      default: true,
    },
    recentSendAlimList: Array,
  },
  computed: {
    swiper() {
      return this.$refs.rcsMsgList;
    },
  },
  mounted() {},
  data() {
    const vm = this;
    return {
      // 미리보기 화면 swiper
      swiperOption2: {
        slidesOffsetBefore: 0, // slidesOffsetBefore는 첫번째 슬라이드의 시작점에 대한 변경할 때 사용
        slidesOffsetAfter: 0, // slidesOffsetAfter는 마지막 슬라이드 시작점 + 마지막 슬라이드 너비에 해당하는 위치의 변경이 필요할 때 사용
        //freeMode: true, // freeMode를 사용시 스크롤하는 느낌으로 구현 가능
        centerInsufficientSlides: true, // 컨텐츠의 수량에 따라 중앙정렬 여부를 결정함
        loop: false,
        direction: "vertical",
        slidesPerView: 3,
        spaceBetween: 12,
        // Navigation arrows
        navigation: {
          nextEl: ".swiper-alim-button-prev",
          prevEl: ".swiper-alim-button-next",
        },
        observer: true,
        observeParents: true,
        on: {
          click: function () {
            if (vm.modiAuth) {
              const clickedIndex = this.clickedIndex;
              vm.handleClickSlide(clickedIndex);
            }
          },
        },
      },
    };
  },
  methods: {
    handleClickSlide(index) {
      eventBus.$on("callbackEventBus", () => {
        this.fnSendData(index);
      });
      // confirm.fnConfirm("최근작성문자 가져오기", "최근 작성문자로 기존 내용에 덮어 씌워집니다. <br/>계속 진행하시겠습니까?", "confirm");
      confirm.fnConfirm("", "최근 작성문자로 기존 내용에 덮어 씌워집니다. <br/>계속 진행하시겠습니까?", "confirm");
    },
    fnSendData(index) {
      this.$emit("recentSendAlimList", this.recentSendAlimList[index]);
    },
  },
};
</script>
