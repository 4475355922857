import httpClient from "@/common/http-client";


const selectAutInfoChnnel = (params) => {
  return httpClient.post('/api/channel/selectAutInfoChnnel', params, { headers: { "show-layer": "Yes"} });
};

const selectAutInfo = (params) => {
  return httpClient.post('/api/statistics/selectAutInfo', params, { headers: { "show-layer": "Yes"} });
};

// 카카오톡 채널Id 조회
const selectChannelId = (params) => {
  return httpClient.post("/api/channel/selectChannelId", params, { headers: { "show-layer": "Yes", activity: "READ" } });
};

// 휴대폰 본인인증 체크
const getNiceCheck = (params) => {
  return httpClient.get("/api/callerId/getNiceCheck", params, { headers: { "show-layer": "Yes", activity: "READ" } });
};

// 휴대폰 본인인증 성공 체크
const getNiceCheckSuccess = (params) => {
  return httpClient.post("/api/callerId/getNiceCheckSuccess", params, { headers: { "show-layer": "Yes", activity: "READ" } });
};
// 휴대폰 본인인증 실패 체크
const getNiceCheckFail = (params) => {
  return httpClient.post("/api/callerId/getNiceCheckFail", params, { headers: { "show-layer": "Yes", activity: "READ" } });
};

//카카오톡 SenderKey Token 받기
const getSenderKeyToken = (params) => {
  return httpClient.post("/api/channel/getSenderKeyToken", params, { headers: { "show-layer": "Yes", activity: "READ" } });
};
//카카오톡 카테고리 가져오기
const getKkoCategory = (params) => {
  return httpClient.post("/api/channel/getKkoCategory", params, { headers: { "show-layer": "Yes", activity: "READ" } });
};

// 카카오톡 채널 ID 저장
const saveKkoChForApi = (params) => {
  return httpClient.post("/api/channel/saveKkoChForApi", params, { headers: { "show-layer": "Yes", activity: "READ" } });
};

// 카카오톡 휴면 해제
const saveRecoverKkoCh = (params) => {
  return httpClient.post("/api/channel/saveRecoverKkoCh", params, { headers: { "show-layer": "Yes", activity: "READ" } });
};
// 카카오톡 채널 삭제
const deleteKkoChannel = (params) => {
  return httpClient.post("/api/channel/deleteKkoChannel", params, { headers: { "show-layer": "Yes", activity: "READ" } });
};

// 카카오 통신용 access-token 발급
const getAccessToken = (params) => {
  return httpClient.get("/api/channel/getAccessToken", params, { headers: { "show-layer": "Yes", activity: "READ" } });
};

// 발신번호 목록
const getSendNumberIdList = (params) => {
  return httpClient.post("/api/callerId/callerIdList", params, { headers: { "show-layer": "Yes", activity: "READ" } });
};

// 발신 번호 사용 상태 업데이트
const updateSendNumber = (params) => {
  return httpClient.post("/api/callerId/updateCallerId", params, { headers: { "show-layer": "Yes", activity: "READ" } });
};

// 발신번호 등록 승인요청
const callerIdReg = (params) => {
  return httpClient.post("/api/callerId/callerIdReg", params, { headers: { "show-layer": "Yes", activity: "READ", "Content-Type": "multipart/form-data" } });
};

// 발신 번호 삭제
const deleteSendNumber = (params) => {
  return httpClient.post("/api/callerId/deleteCallerId", params, { headers: { "show-layer": "Yes", activity: "READ" } });
};

export default {
  selectAutInfoChnnel,
  selectAutInfo,
  selectChannelId,
  getNiceCheck,
  getNiceCheckSuccess,
  getSenderKeyToken,
  getKkoCategory,
  saveKkoChForApi,
  getAccessToken,
  getSendNumberIdList,
  updateSendNumber,
  deleteSendNumber,
  callerIdReg,
  saveRecoverKkoCh,
  deleteKkoChannel,
  getNiceCheckFail,
};
