<template>
  <section class="m_section">
    <!--	s: 	contents-->
    <div class="contents">
      <h1></h1>
    </div>
    <confirm></confirm>
  </section>
</template>
<script>
import confirm from "@/modules/commonUtil/service/confirm";
import { eventBus } from "@/modules/commonUtil/service/eventBus";
export default {
  name: "tossPayProc",
  data(){
    return{
      param : {
        orderId:"",
        code:"",
        message:0
      }
    }
  },
  mounted() {
    eventBus.$on("callbackEventBus", this.movePayProc);
    confirm.fnConfirm("", this.$route.query.code+"<br/>"+this.$route.query.message, "cbAlert");
  },
  methods: {
    movePayProc(){
      window.top.location.href = "/charge/chargeProc";
    }
  }
}
</script>