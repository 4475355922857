<template>
  <Fragment>
    <!--	s: m_section	-->
    <section class="m_section">
      <!--	s: 	contents-->
      <div class="contents">
        <MobileGnb />
        <div class="tabcont_wrap without_tab user_wrap">
          <h1 class="title with_desc">사이트맵</h1>
          <div class="tabcontTop">
            <ul>
              <li>경로 클릭시 해당 페이지로 이동합니다.</li>
            </ul>
          </div>
          <table cellpadding="0" cellspacing="0" class="fixed-table" style="table-layout: fixed">
            <col class="col0" />
            <col class="col1" />
            <col class="col2" />
            <col class="col3" />
            <col class="col4" />

            <tbody>
              <tr class="row0">
                <td>1depth</td>
                <td>2depth</td>
                <td>page</td>
                <td>경로</td>
                <td>특이사항</td>
              </tr>
              <tr class="row1">
                <td>메인</td>
                <td></td>
                <td></td>
                <td><router-link to="/main">/main</router-link></td>
                <td></td>
              </tr>
              <tr class="row2">
                <td rowspan="5">로그인</td>
                <td rowspan="5">로그인</td>
                <td>로그인</td>
                <td><router-link to="/login">/login</router-link></td>
                <td></td>
              </tr>
              <tr class="row3">
                <td>2차휴대폰 문자 인증</td>
                <td><router-link to="/login/loginCertify">/login/loginCertify</router-link></td>
                <td>로그인 후 확인 가능</td>
              </tr>
              <tr class="row4">
                <td>비밀번호 초기화</td>
                <td><router-link to="/login/passWdInit">/login/passWdInit</router-link></td>
                <td>로그인 후 확인 가능</td>
              </tr>
              <tr class="row6">
                <td>아이디 찾기-입력</td>
                <td><router-link to="/login/findUserId">/login/findUserId</router-link></td>
                <td></td>
              </tr>
              <tr class="row8">
                <td>아이디 찾기-결과</td>
                <td><router-link to="/login/findUserId">/login/findUserId</router-link></td>
                <td>아이디 찾기 후 확인 가능</td>
              </tr>
              <tr class="row11">
                <td>서비스소개</td>
                <td>서비스소개</td>
                <td></td>
                <td><router-link to="/serviceInfo/main">/serviceInfo/main</router-link></td>
                <td></td>
              </tr>
              <tr class="row12">
                <td rowspan="2">메시지전송</td>
                <td>문자전송</td>
                <td>문자전송</td>
                <td><router-link to="/messagesend/sms">/messagesend/sms</router-link></td>
                <td></td>
              </tr>
              <tr class="row13">
                <td>알림톡작성</td>
                <td>알림톡작성</td>
                <td><router-link to="/messagesend/alimtalk">/messagesend/alimtalk</router-link></td>
                <td></td>
              </tr>
              <tr class="row14">
                <td rowspan="6">전송관리</td>
                <td rowspan="2">전송내역</td>
                <td>전송내역조회</td>
                <td><router-link to="/sendManage/sendMessage">/sendManage/sendMessage</router-link></td>
                <td></td>
              </tr>
              <tr class="row15">
                <td>상세내역보기*</td>
                <td><router-link to="/sendManage/sendMessageDetail/:id">/sendManage/sendMessageDetail/:id</router-link></td>
                <td></td>
              </tr>
              <tr class="row16">
                <td rowspan="3">예약전송내역</td>
                <td>예약전송내역</td>
                <td><router-link to="/sendManage/rsvSendMessage">/sendManage/rsvSendMessage</router-link></td>
                <td></td>
              </tr>
              <tr class="row17">
                <td>예약전송수정-수정(문자메시지)*</td>
                <td><router-link to="/sendManage/rsvMsgSmsModi/:id">/sendManage/rsvMsgSmsModi/:id</router-link></td>
                <td></td>
              </tr>
              <tr class="row18">
                <td>예약전송수정-수정(알림톡)*</td>
                <td><router-link to="/sendManage/rsvAlimModi/:id">/sendManage/rsvAlimModi/:id</router-link></td>
                <td></td>
              </tr>
              <tr class="row19">
                <td>사용자통계</td>
                <td>사용자통계</td>
                <td><router-link to="/sendManage/sendMessageStats">/sendManage/sendMessageStats</router-link></td>
                <td></td>
              </tr>
              <tr class="row20">
                <td rowspan="5">고객센터</td>
                <td rowspan="2">공지사항</td>
                <td>공지사항-리스트</td>
                <td><router-link to="/serviceCenter/notice">/serviceCenter/notice</router-link></td>
                <td></td>
              </tr>
              <tr class="row21">
                <td>공지사항-상세</td>
                <td><router-link to="/serviceCenter/noticeDetail">/serviceCenter/noticeDetail</router-link></td>
                <td></td>
              </tr>
              <tr class="row22">
                <td>FAQ</td>
                <td>FAQ</td>
                <td><router-link to="/serviceCenter/faq">/serviceCenter/faq</router-link></td>
                <td></td>
              </tr>
              <tr class="row23">
                <td rowspan="2">상담하기</td>
                <td>비회원 문의</td>
                <td><router-link to="/serviceCenter/consulting">/serviceCenter/consulting</router-link></td>
                <td></td>
              </tr>
              <tr class="row25">
                <td>회원 문의</td>
                <td><router-link to="/serviceCenter/consulting">/serviceCenter/consulting</router-link></td>
                <td></td>
              </tr>
              <tr class="row26">
                <td rowspan="4">발신번호관리</td>
                <td rowspan="3">문자발신번호관리</td>
                <td>발신번호관리</td>
                <td><router-link to="/sendNumberManage/sendNumber">/sendNumberManage/sendNumber</router-link></td>
                <td></td>
              </tr>
              <tr class="row27">
                <td>발신번호등록*</td>
                <td><router-link to="/sendNumberManage/sendNumberReg">/sendNumberManage/sendNumberReg</router-link></td>
                <td></td>
              </tr>
              <tr class="row28">
                <td>발신번호대량등록*</td>
                <td><router-link to="/sendNumberManage/sendNumberManyReg">/sendNumberManage/sendNumberManyReg</router-link></td>
                <td></td>
              </tr>
              <tr class="row29">
                <td>채널ID관리</td>
                <td>카카오 채널 ID 관리*</td>
                <td><router-link to="/sendNumberManage/channelIdManage">/sendNumberManage/channelIdManage</router-link></td>
                <td></td>
              </tr>
              <tr class="row30">
                <td rowspan="5">템플릿관리</td>
                <td rowspan="5">알림톡템플릿관리</td>
                <td>템플릿 관리</td>
                <td><router-link to="/templeteManage">/templeteManage</router-link></td>
                <td></td>
              </tr>
              <tr class="row31">
                <td>템플릿 작성</td>
                <td><router-link to="/templeteManage/templeteWrite">/templeteManage/templeteWrite</router-link></td>
                <td></td>
              </tr>
              <tr class="row32">
                <td>상세페이지(승인,검수)</td>
                <td><router-link to="/templeteManage/templete/:id">/templeteManage/templete/:id</router-link></td>
                <td>상세페이지 클릭시 접근 가능</td>
              </tr>
              <tr class="row33">
                <td>상세페이지(반려)</td>
                <td><router-link to="/templeteManage/templeteUpdate/:id">/templeteManage/templeteUpdate/:id</router-link></td>
                <td>상세페이지 클릭시 접근 가능</td>
              </tr>
              <tr class="row34">
                <td>상세페이지(등록)</td>
                <td><router-link to="/templeteManage/templeteUpdate/:id">/templeteManage/templeteUpdate/:id</router-link></td>
                <td>상세페이지 클릭시 접근 가능</td>
              </tr>
              <tr class="row35">
                <td>주소록</td>
                <td>주소록관리</td>
                <td>개인 주소록</td>
                <td><router-link to="/addressManage/addressManage">/addressManage/addressManage</router-link></td>
                <td></td>
              </tr>
              <tr class="row35">
                <td>주소록</td>
                <td>주소록관리</td>
                <td>공유 주소록</td>
                <td><router-link to="/addressManage/addressPubManage">/addressManage/addressPubManage</router-link></td>
                <td></td>
              </tr>
              <tr class="row36">
                <td>080수신거부</td>
                <td>080수신거부</td>
                <td>080수신거부</td>
                <td><router-link to="/sendRejectManage">/sendRejectManage</router-link></td>
                <td></td>
              </tr>
              <tr class="row37">
                <td>사용자ID관리</td>
                <td>사용자ID관리</td>
                <td>사용자 ID관리</td>
                <td><router-link to="/userManage">/userManage</router-link></td>
                <td></td>
              </tr>
              <tr class="row38">
                <td rowspan="4">내정보</td>
                <td>대시보드</td>
                <td>대시보드</td>
                <td><router-link to="/myInfo/dashBoard">/myInfo/dashBoard</router-link></td>
                <td></td>
              </tr>
              <tr class="row39">
                <td rowspan="3">회원정보수정</td>
                <td>01 회원정보수정(비밀번호 재확인)</td>
                <td><router-link to="/myInfo/memInfo">/myInfo/memInfo</router-link></td>
                <td></td>
              </tr>
              <tr class="row40">
                <td>02 회원정보수정(관리자)</td>
                <td><router-link to="/myInfo/memInfo">/myInfo/memInfo</router-link></td>
                <td>관리자 로그인</td>
              </tr>
              <tr class="row41">
                <td>03 회원정보수정(사용자)</td>
                <td><router-link to="/myInfo/memInfo">/myInfo/memInfo</router-link></td>
                <td>사용자 로그인</td>
              </tr>
              <!-- <tr class="row42">
                <td rowspan="5">이메일</td>
                <td>로그인</td>
                <td>최초가입안내메일</td>
                <td><router-link to=""></router-link>/home/login-mail.html</td>
                <td></td>
              </tr>
              <tr class="row43">
                <td>고객센터</td>
                <td></td>
                <td><router-link to=""></router-link>/home/cs-mail.html</td>
                <td></td>
              </tr>
              <tr class="row44">
                <td rowspan="2">발신번호관리</td>
                <td>발신번호등록</td>
                <td><router-link to=""></router-link>/home/caller-mail1.html</td>
                <td></td>
              </tr>
              <tr class="row45">
                <td>발신번호삭제</td>
                <td><router-link to=""></router-link>/home/caller-mail2.html</td>
                <td></td>
              </tr>
              <tr class="row46">
                <td>사용자관리</td>
                <td></td>
                <td><router-link to=""></router-link>/home/user-mail.html</td>
                <td></td>
              </tr> -->
              <tr class="row47">
                <td>기타</td>
                <td>에러페이지</td>
                <td>시스템</td>
                <td><router-link to="/500">/500</router-link></td>
                <td></td>
              </tr>
              <tr class="row48">
                <td></td>
                <td></td>
                <td>페이지</td>
                <td><router-link to="/404">/404</router-link></td>
                <td></td>
              </tr>
              <tr class="row49">
                <td rowspan="4">footer</td>
                <td>이용약관</td>
                <td></td>
                <td><router-link to="/policy/useTerms">/policy/useTerms</router-link></td>
                <td></td>
              </tr>
              <tr class="row50">
                <td>요금신고내역</td>
                <td></td>
                <td><router-link to="/policy/chargeNotice">/policy/chargeNotice</router-link></td>
                <td></td>
              </tr>
              <tr class="row51">
                <td>개인정보처리방침</td>
                <td></td>
                <td><router-link to="/policy/privacyPolicy">/policy/privacyPolicy</router-link></td>
                <td></td>
              </tr>
              <tr class="row52">
                <td>스팸정책</td>
                <td></td>
                <td><router-link to="/policy/spamPolicy">/policy/spamPolicy</router-link></td>
                <td></td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <!--	e: 	contents	-->
    </section>
    <!--	e: m_section	-->
  </Fragment>
</template>
<script>
export default {
  name: "flieList",
  components: {},
  data() {
    return {
      example: "",
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {},
  mounted() {},
  beforeUpdate() {},
  updated() {},
  beforeUnmount() {},
  unmounted() {},
  methods: {},
};
</script>
