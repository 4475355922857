<template>
  <section class="m_section">
    <!--	s: 	contents-->
    <div class="contents">
      <MobileGnb />
      <div class="tabmenu_wrap">
        <ul class="tabmenu">
          <li class="tabl" @click="$router.push({ path: '/policy/useTerms' })"><span>이용약관</span></li>
          <li class="tabl" @click="$router.push({ path: '/policy/chargeNotice' })"><span>요금신고<br />내역</span></li>
          <li class="tabl" @click="$router.push({ path: '/policy/privacyPolicy' })"><span>개인정보<br />처리방침</span></li>
          <li class="tabl on"><span>스팸정책</span></li>
        </ul>
      </div>
      <div class="tabcont_wrap policy_wrap">
        <div class="policy privacyPolicy">
           <div class="policy_top">
             <h3 class="policy_title">스팸정책</h3>
             <p class="policy_sub">고객만족을 위해 LG U+ 문자서서비스는 최선을 다하겠습니다.</p>
             <p class="location">HOME - 스팸정책</p>
           </div>
           <div class="requestWrap">
               <div class="requestTextArea" style="max-height:395px;"><!-- 스팸정책 동의 박스 시작. -->
				<strong>1. 스팸규제법령</strong>
				<span>정보통신망 이용 촉진 및 정보보호 등에 관한 법률 제 50조(영리목적의 광고성 정보전송의 제한) 부터 제 50조의 8</span>

				<strong>2. 스팸정의</strong>
				<span>수신자의 의사에 반(反)하여 정보통신망을 통해 일방적으로 전송되는 영리목적의 광고성 정보</span>

				<strong>3.스팸관리 대상</strong>
				<ul>
					<li>①	스팸으로 신고된 문자 중 대출, 도박, 의약품, 성인 관련 내용을 포함하여 전송된 문자(SMS, MMS, Callback-URL SMS)</li>
					<li>②	법령에서 정의하는 광고메시지 표기 의무를 위반하여 전송된 문자(SMS, MMS, Callback-URL SMS)</li>
				</ul>

				<strong>4.스팸관리</strong>
				<ul>
					<li>① 신규고객 유치제한 : 대출, 도박, 의약품, 성인 관련 내용 발송업체 유치불가</li>
					<li>
						② 기존 고객 : 대출, 도박, 의약품, 성인 관련 내용 유입 확인 시 단계별 차단관리 시행
						<ul>
							<li>- 1차 발송 확인시: 10건/초 서비스 속도 제한 후 주의 고지(전화 및 E-mail, 고객 및 영업사원 대상)</li>
							<li>- 2차 발송 확인시: 1건/초 서비스 속도 제한 후 주의 고지</li>
							<li>- 3차 발송 확인시: 해당 ID 중지 후 통지(전화 및 E-mail, 고객 및 영업사원 대상)</li>
							<li>-월 누적 3회 ID 중지시: 서비스 이용중지</li>
							<li>※ 1,2차는 조치결과 공문 접속 시 원복</li>
							<li>※ KISA(한국인터넷진흥원) 오탐 시 규제대상에서 제외</li>
						</ul>
					</li>
				</ul>

				<strong>5. 서비스 이용 중지 정책</strong>
				<ul>
					<li>KISA(한국인터넷진흥원), 전파관리소 등 관련 기관에서 서비스 중지 요청이 들어올 경우 즉시 차단</li>
				</ul>
				</div>
				</div>
			</div>
		</div>
      </div>
    <!--	e: 	contents	-->
  </section>
</template>
<script>
import MobileGnb from "@/components/MobileGnb.vue";
export default {
  name: "spamPolicy",
  components: { MobileGnb },
  data() {
    return {
      example: "",
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {},
  mounted() {},
  beforeUpdate() {},
  updated() {},
  beforeUnmount() {},
  unmounted() {},
  methods: {},
};
</script>
