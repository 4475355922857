import memInfo from "../views/memInfo";
import dashBoard from "../views/dashBoard";

export default [
  {
    path: "/myInfo/memInfo",
    name: "회원정보수정",
    component: memInfo,
  },
  {
    path: "/myInfo/dashBoard",
    name: "대시보드",
    component: dashBoard,
  },
];
