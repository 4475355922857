import channelIdManage from "../views/channelIdManage";
import sendNumber from "../views/sendNumber";
import sendNumberReg from "../views/sendNumberReg";
import sendNumberManyReg from "../views/sendNumberManyReg";

export default [
  {
    path: "/sendNumberManage/sendNumber",
    name: "문자발신번호관리",
    component: sendNumber,
  },
  {
    path: "/sendNumberManage/sendNumberReg",
    name: "발신번호 등록",
    component: sendNumberReg,
  },
  {
    path: "/sendNumberManage/sendNumberManyReg",
    name: "발신번호 대량등록",
    component: sendNumberManyReg,
  },
  {
    path: "/sendNumberManage/channelIdManage",
    name: "채널Id관리",
    component: channelIdManage,
  },
];
