<template>
  <div class="popup tit excelpop">
    <div class="pop-head">
      <h3 class="pop-tit">공유 주소록 대량 등록하기</h3>
    </div>
    <div class="pop-cont">
      <div class="filepop_top">
        <ul>
          <li><p>한번에 20,000개까지 번호를 등록할 수 있습니다.</p></li>
          <li><p>업로드할 수 있는 파일 형식은 xls, xlsx, csv, txt입니다.</p></li>
          <li>
            <p>정상적으로 정보를 입력한 번호만 주소록에 추가할 수 있습니다.<br /><span class="filepop_top_desc">[오류 검사]를 누르면 오류 여부를 바로 확인할 수 있습니다.</span></p>
          </li>
        </ul>
        <div class="download_wrap">
          <p>샘플 파일 내려 받기</p>
          <a @click="excelDown('xls')" class="sample xls" download></a>
          <a @click="excelDown('xlsx')" class="sample xlsx" download></a>
          <a @click="excelDown('csv')" class="sample csv" download></a>
          <a @click="txtDown" class="sample txt" download></a>
        </div>
      </div>

      <div class="filepop_cont">
        <div class="list_top mass">
          <div class="left_wrap">
            <div class="btn plus">
              파일불러오기
              <input
                type="file"
                ref="ex_file"
                accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel,text/comma-separated-values, text/csv, application/csv, text/plain"
                @change="readExcel"
              />
            </div>
            <div v-if="fileName != ''" class="img_list_wrap">
              <div class="img_list">
                <p class="file_name">{{ fileName }}</p>
                <button class="btn_del" @click="removeFile"></button>
              </div>
            </div>
          </div>
          <div class="select_wrap">
            <select name="addrGrp" class="select" v-model="selectedAddrGrp" @change="fnselAddr()">
              <option value="N" selected>그룹선택</option>
              <option v-for="(addrGrp, index) in addrGrpList" :key="index" :value="addrGrp">
                {{ addrGrp.groupNm }}
              </option>
              <option value="GA">그룹추가 +</option>
            </select>
            <div v-if="groupArea" class="add_group on">
              <!-- select option 그룹추가 선택시 class="on" ( display ) -->
              <input type="text" class="input" v-model="regGroupNm" placeholder="그룹명을 입력하세요." />
              <!-- <button class="btn white" type="button" @click="fnSelectArea">취소</button> -->
            </div>
          </div>
        </div>
        <div class="list_cover">
          <div class="list_th_wrap" @scroll="handleThScroll($event)">
            <ul class="list_th">
              <li class="td_chk w40">
                <input type="checkbox" v-model="allChecked" class="chk_all" id="chk_all2" @click="checkedAll($event.target.checked)" :disabled="excelData.paramData.length === 0" />
                <label for="chk_all2"></label>
              </li>
              <li class="td_num w60"><span>No</span></li>
              <li class="td_phone w278"><span>휴대폰 번호</span></li>
              <li class="td_name w278"><span>이름</span></li>
              <li class="td_state w278"><span>상태</span></li>
            </ul>
          </div>
          <div class="list_wrap scr_wrap" @scroll="handleScroll($event)">
            <ul class="list" :style="fileName != '' && 'min-width: auto;'">
              <li v-if="excelTotCnt == 0" class="empty"><p>등록된 파일이 없습니다.</p></li>
              <li v-else v-for="(data, index) in excelData.paramData.slice(0, 100)" :key="index" :class="{ on: !data.validator }">
                <span class="td_chk w40">
                  <input type="checkbox" class="chk" :id="'check_' + index" :value="index" v-model="data.selected" @change="selected($event)" />
                  <label :for="'check_' + index"></label>
                </span>
                <span class="td_num w60">{{ index + 1 }}</span>
                <span class="td_phone w278"><input type="text" class="input" v-model="data.phone" @input="fnPhoneDash($event, index)" /></span>
                <span class="td_name w278"><input type="text" class="input" v-model="data.name" /></span>
                <span class="td_state w278"
                  ><p class="red">{{ data.status }}</p></span
                >
              </li>
            </ul>
          </div>
        </div>
        <div class="list_info">
          <div class="right_wrap">
            <button class="btn white small" @click="removeAddress">삭제</button>
            <p class="total">
              총 <span class="pink">{{ excelTotCnt | formatPrice }} </span>건
            </p>
          </div>
          <button class="btn white small" @click="errorReCheck(excelData)">오류 검사</button>
        </div>
      </div>
    </div>
    <div class="pop-btn2 center">
      <button type="button" class="btn large_pink" @click="sendAddress(excelData)">등록</button>
      <button type="button" class="btn large_white" @click="fnClose">취소</button>
    </div>
  </div>
</template>
<script>
import * as XLSX from "xlsx";
import addressApi from "../service/addressPubApi";
import confirm from "@/modules/commonUtil/service/confirm";
import { eventBus } from "@/modules/commonUtil/service/eventBus";

export default {
  data() {
    return {
      validator: false,
      regGroupNm: "",
      groupArea: false,
      hashNum: [], // 연락처 중복 처리 변수 값 (errorCheck 할 경우 function 마다 초기화 진행)
      allChecked: false, // 전체 체크박스 값
      excelTotCnt: 0,
      fileName: "",
      excelData: {
        // 엑셀파일 변수 초기화
        paramData: [
          {
            // 주소록 상태값 확인 변수
            status: "", // 유효성 체크후 결과 텍스트를 담는 변수
            validator: false, // 유효성 체크 변수
            selected: false, // 셀렉트박스 선택
            //신규 파람
            levDepth: "",
            name: "",
            phone: "",
          },
        ],
      },
      addrGrpList: [], // 주소록 그룹 조회 데이터 담는 변수
      addrListHash: {},
      filePath: false,
      selectedAddrGrp: "N", // 선택된 그룹 정보
      sendData: {}, // 전송 데이터 변수
      addrArrayData: {},
      listScrollPostion: 0,
      addrDiv : "G"
    };
  },
  methods: {
    //초기화
    fnInit() {
      this.validator = false;
      this.allChecked = false;
      this.sendData = {};
      this.hashNum = {};
      this.excelTotCnt = 0;
      this.excelData = {
        paramData: [
          {
            status: "",
            validator: false,
            selected: false,
            levDepth: "",
            name: "",
            phone: "",
          },
        ],
      };
      this.filePath = false;
      this.fileName = "";
      this.fileLength = 0;
    },
    // 수정된 파일 오류 검사 실시
    errorReCheck(rows) {
      this.hashNum = new Map();
      // 중복 함수 초기화
      this.excelData["paramData"] = rows["paramData"].map((e, index) => {
        return {
          // 유효성 체크 항목 추가
          ...e,
          status: this.errorCheck(index, e.phone),
          validator: this.validator,
          selected: false,
        };
      });
      /* .sort(function (a, b) {
          if (a.validator < b.validator) return -1;
          if (a.validator > b.validator) return 1;
          if (a.validator === b.validator) return 0;
        }); */
    },
    //체크박스 전체 선택 기능
    checkedAll(checked) {
      this.allChecked = checked;
      for (let i in this.excelData.paramData.slice(0, 100)) {
        this.excelData.paramData[i].selected = this.allChecked;
      }
    },
    //체크박스 단일 체크
    selected() {
      if (this.excelData.paramData.every((item) => item.selected == true)) {
        this.allChecked = true;
      } else {
        this.allChecked = false;
      }
    },
    // 엑셀 주소록 읽기
    readExcel(event) {
      this.$store.commit("startSpinner");
      // 확장자 검사
      const file = event.target.files[0];
      const filePath = event.currentTarget.value;
      const filePathSplit = filePath.split("\\");
      const filePathLength = filePathSplit.length;
      const fileNameSplit = filePathSplit[filePathLength - 1].split(".");
      const fileExt = fileNameSplit[1];
      this.filePath = filePath;
      this.fileName = filePathSplit[filePathLength - 1];
      if (!(fileExt === "xlsx" || fileExt === "xls" || fileExt === "csv" || fileExt === "txt")) {
        confirm.fnAlert("", "업로드할 수 없는 파일 형식입니다.", "alert");
        event.currentTarget.value = "";
        this.$store.commit("endSpinner");
        return false;
      }

      // 중복 함수 초기화
      let reader = new FileReader();
      if (fileExt === "txt") {
        reader.readAsText(file);
      } else {
        reader.readAsBinaryString(file);
      }
      this.hashNum = new Map();
      reader.onload = () => {
        let data = reader.result;
        let workBook = XLSX.read(data, { type: "binary" });
        let rows = new Array();
        workBook.SheetNames.forEach(function (sheetName) {
          rows = XLSX.utils.sheet_to_json(workBook.Sheets[sheetName], {
            defval: "",
            raw: false,
          });
        });

        if (rows.length > 0) {
          // 엑셀파일 읽어올 경우 헤더값 확인
          const headerInfo = ["휴대폰번호", "이름"];
          const headerCheck = Object.keys(rows[0]).every((e) => headerInfo.includes(e));

          if (!headerCheck || Object.keys(rows[0]).length !== 2) {
            confirm.fnAlert("", "샘플 파일의 자동 변경 정보를 확인해 주세요. <br/>메시지 내용에 입력한 자동 변경 정보만 입력 가능합니다.", "alert");
            this.$store.commit("endSpinner");
            this.removeFile();
            return;
          }
        } else {
          confirm.fnAlert("", "주소록이 없습니다.", "alert");
          this.fnInit();
          this.$store.commit("endSpinner");
          return;
        }

        if (rows.length > 20000) {
          confirm.fnAlert("", "최대 등록할 수 있는 건수가 초과되어 등록이 불가합니다.", "alert");
          this.fnInit();
          this.$store.commit("endSpinner");
          return;
        }

        this.excelData["paramData"] = rows
          .map((e, index) => {
            return {
              // 유효성 체크 항목 추가
              phone: e["휴대폰번호"].replace(/-/g, ""),
              name: e["이름"],
              status: this.errorCheck(index, e["휴대폰번호"]),
              validator: this.validator,
              selected: false,
            };
          })
          .sort(function (a, b) {
            if (a.validator < b.validator) return -1;
            if (a.validator > b.validator) return 1;
            if (a.validator === b.validator) return 0;
          });
        this.excelTotCnt = this.excelData.paramData.length;
        this.$forceUpdate();
        this.$store.commit("endSpinner");
      };
    },
    fnPhoneDash(e, i) {
      this.excelData["paramData"][i].phone = this.$gfnCommonUtils.cutStr(e.target.value.replace(/[^-0-9]/g, ""), 16);
    },
    removeFile() {
      this.$refs.ex_file.value = "";
      this.fnInit();
      this.allChecked = false;
    },
    // 휴대폰 번호 유효성 체크 항목
    errorCheck(index, phoneNumber) {
      const patten_phone = /^(01[016789]{1}|070)-?[0-9]{3,4}-?[0-9]{4}$/; //핸드폰 번호, 하이픈 상관 X
      const pattern_eng = /[a-zA-Z]/; // 문자
      const pattern_spc = /[~!@#$%^&*()_+|<>?:{}]/; // 특수문자
      const pattern_kor = /[ㄱ-ㅎ|ㅏ-ㅣ|가-힣]/; // 한글체크

      let errorText = "";

      // 휴대폰 번호, 이름, 중복 체크
      if (phoneNumber == "" || phoneNumber == undefined) {
        errorText = "번호없음";
        this.validator = false;
        return errorText;
      } else if (pattern_eng.test(phoneNumber) || pattern_spc.test(phoneNumber) || pattern_kor.test(phoneNumber)) {
        errorText = "번호오류(휴대폰번호만입력)";
        this.validator = false;
        return errorText;
      } else if (!patten_phone.test(phoneNumber)) {
        errorText = "번호오류(휴대폰번호만입력)";
        this.validator = false;
        return errorText;
      } 
      // else if (name == "" || name == undefined) {
      //   errorText = "이름 없음";
      //   this.validator = false;
      //   return errorText;
      // } 
      else {
        // 중복체크
        if (this.hashNum.has(phoneNumber.replace(/-/g, ""))) {
          errorText = "번호중복";
          this.validator = false;
          return errorText;
        } else {
          errorText = "정상";
          this.validator = true;
          this.hashNum.set(phoneNumber.replace(/-/g, ""), "phone");
        }
      }
      return errorText;
    },
    // 선택된 주소록 삭제
    removeAddress() {
      this.excelData["paramData"] = this.excelData.paramData.filter((item) => {
        return item.selected === false; // 체크 안된 리스트
      });
      this.excelTotCnt = this.excelData.paramData.length;
      this.allChecked = false;
      this.$forceUpdate();
    },
    // 주소록 등록
    sendAddress(arg) {
      if (this.selectedAddrGrp === "" || this.selectedAddrGrp === "undefind") {
        confirm.fnAlert("", "주소록 그룹이 선택되지 않았습니다.", "alert");
        return false;
      }

      if (!this.filePath) {
        confirm.fnAlert("", "업로드 주소록이 없습니다.", "alert");
        return false;
      }

      if (this.selectedAddrGrp == "GA") {
        if (this.regGroupNm === "") {
          confirm.fnAlert("", "추가 할 그룹명을 입력해 주세요.", "alert");
          return false;
        }

        if (this.regGroupNm == "전체") {
          confirm.fnAlert("", " 전체 그룹명은 사용할 수 없습니다.", "alert");
          return false;
        }
        if (this.regGroupNm == "그룹 없음" || this.regGroupNm == "그룹없음") {
          confirm.fnAlert("", " 그룹 없음 그룹명은 사용할 수 없습니다.", "alert");
          return false;
        }

        let isGroupNm = true;
        let groupNm = this.regGroupNm;
        this.addrGrpList.forEach(function (grp) {
          if (grp.orgGroupNm.toUpperCase() == groupNm.toUpperCase()) {
            isGroupNm = false;
          }
        });

        if (!isGroupNm) {
          confirm.fnAlert("", "추가 그룹명이 이미 사용중인 그룹명입니다.", "alert");
          return false;
        }
      }

      var dupChk = false;
      for (var i = 0; i < arg["paramData"].length; i++) {
        if (this.addrListHash.has(arg["paramData"][i].phone.replace(/-/g, ""))) {
          dupChk = true;
          break;
        }
      }
      if (dupChk) {
        confirm.fnAlert("", "등록하려는 그룹에 중복된 번호가 있어 등록 할 수 없습니다.", "alert");
        return false;
      }

      this.errorReCheck(this.excelData);
      if (!arg["paramData"].every((item) => item.validator === true)) {
        confirm.fnAlert("", "입력하신 번호 중 잘못된 번호가 있습니다.<br />해당 번호는 제외하고 등록됩니다.", "alert");
      }

      let levDepth = "";
      let groupSeq = "";
      let parentGroupSeq = "";

      if (this.selectedAddrGrp == "N") {
        confirm.fnAlert("", "그룹을 선택해주세요", "alert");
        return false;
        // levDepth = "1";
        // groupSeq = "0";
        // parentGroupSeq = "0";
      } else if (this.selectedAddrGrp == "GA") {
        levDepth = "1";
        groupSeq = "";
        parentGroupSeq = "";
      } else {
        (levDepth = this.selectedAddrGrp.levDepth), (groupSeq = this.selectedAddrGrp.groupSeq);
        parentGroupSeq = this.selectedAddrGrp.parentGroupSeq;
      }

      // 통과한 데이터만 전달될 수 있도록 필터 설정
      this.sendData = arg["paramData"]
        .filter((item) => {
          return item.validator === true;
        })
        .map((item) => {
          return {
            ...item,
            phone: item.phone.replace(/-/g, ""),
            levDepth: levDepth,
            groupSeq: groupSeq,
            parentGroupSeq: parentGroupSeq,
          };
        });
      if(this.sendData.length == 0){
      confirm.fnAlert("", "등록할 수 있는 정상 주소가 없습니다.", "alert");
      return false;
      }
      if (this.selectedAddrGrp == "N") {
        this.addrArrayData = {
          excelRegDiv: "N",
          addressDtoArray: this.sendData,
        };
      } else if (this.selectedAddrGrp == "GA") {
        this.addrArrayData = {
          levDepth: "1",
          lev1: this.regGroupNm,
          lev2: "",
          excelRegDiv: "GA",
          addressDtoArray: this.sendData,
        };
      } else {
        this.addrArrayData = {
          excelRegDiv: "E",
          addressDtoArray: this.sendData,
        };
      }
      eventBus.$on("callbackEventBus", this.fnSendAddressExec);

      confirm.fnConfirm("", "주소록 대량 등록하시겠어요?", "revConfirm");
    },
    fnSendAddressExec() {
      addressApi.massRegisterAddrPubMember(this.addrArrayData).then((response) => {
        var result = response.data;
        if (result.success) {
          eventBus.$on("callbackEventBus", this.fnClose);
          confirm.fnConfirm("", "파일을 올렸습니다.", "cbAlert");
          this.removeFile();
        } else {
          confirm.fnAlert("", result.message, "alert");
        }
      });
    },
    // 주소록 그룹 가져오기
    getSelectAddressCateGrpList() {
      this.fileName = "";
      this.validator = false;
      this.regGroupNm = "";
      this.groupArea = false;
      this.hashNum = {};
      this.allChecked = false;
      this.excelTotCnt = 0;
      this.excelData = {
        paramData: [
          {
            status: "",
            validator: false,
            selected: false,
            levDepth: "",
            name: "",
            phone: "",
          },
        ],
      };
      this.addrGrpList = [];
      this.addrListHash = {};
      this.filePath = false;
      this.selectedAddrGrp = "N";
      this.sendData = {};
      this.addrArrayData = {};

      // 로그인 정보
      const addrInfo = {
        userSeq: "",
        addrDiv : this.addrDiv
      };
      addressApi.selectAddressPubGrpSelList(addrInfo).then((response) => {
        var result = response.data;
        if (result.success) {
          this.addrGrpList = result.data.addrGrpSelList;
          this.fnselAddr();
        } else {
          confirm.fnAlert("", "오류가 발생하였습니다.", "alert");
        }
      });
    },
    fnselAddr() {
      this.regGroupNm = "";
      if (this.selectedAddrGrp == "GA") {
        this.groupArea = true;
      } else {
        this.groupArea = false;
        var param = {};
        if (this.selectedAddrGrp == "N") {
          param = {
            excelRegDiv: "N",
          };
        } else {
          param = {
            groupSeq: this.selectedAddrGrp.groupSeq,
            parentGroupSeq: this.selectedAddrGrp.parentGroupSeq,
            excelRegDiv: "E",
          };
        }
        var addrHash = new Map();
        this.addrListHash = new Map();
        addressApi.selectAddressPubSelList(param).then((response) => {
          var result = response.data;
          if (result.success) {
            result.data.addrSelList.forEach(function (data) {
              addrHash.set(data, "phone");
            });
            this.addrListHash = addrHash;
          } else {
            confirm.fnAlert("", "오류가 발생하였습니다.", "alert");
          }
        });
      }
    },
    fnSelectArea() {
      this.regGroupNm = "";
      this.selectedAddrGrp = "N";
      this.groupArea = false;
    },
    fnClose() {
      this.removeFile();
      this.$emit("close");
    },
    sampleDownload(type) {
      let headers = [
        {
          key: "phone",
          name: "휴대폰번호",
        },
        {
          key: "name",
          name: "이름",
        },
      ];
      let options = {
        header: headers,
        headProps: "header",
      };
      this.sampleExport(options, headers, type);
    },
    sampleExport(options, headers, type) {
      // data row별 header에 바인딩 될 매핑값 설정
      let headProps = [];
      if (Array.isArray(options.headProps)) {
        headProps = options.headProps;
      } else if (options.headProps === "header") {
        for (let h of headers) {
          headProps.push(h.key);
        }
      }

      this.visibleHeadProps = headProps;
      this.instance = document.createElement("table");
      // Header 세팅
      let header = [];
      if (!Array.isArray(headers[0])) {
        header.push(headers);
      } else {
        header = headers;
      }

      let thead = document.createElement("thead");
      for (let row of header) {
        let tr = document.createElement("tr");
        for (let h of row) {
          let rowspan = h.rowspan || "1";
          let colspan = h.colspan || "1";
          let th = document.createElement("th");
          th.setAttribute("rowspan", rowspan);
          th.setAttribute("colspan", colspan);
          th.setAttribute("align", "center");
          th.innerText = h.name;
          tr.appendChild(th);
        }
        thead.appendChild(tr);
      }
      this.instance.appendChild(thead);
      // Body 세팅
      this.data;
      let tbody = document.createElement("tbody");
      let tr = document.createElement("tr");

      let td1 = document.createElement("td");
      td1.innerText = "01012341234";
      tr.appendChild(td1);
      let td2 = document.createElement("td");
      td2.innerText = "홍길동";
      tr.appendChild(td2);
      let td3 = document.createElement("td");
      td3.innerText = "a";
      tr.appendChild(td3);
      let td4 = document.createElement("td");
      td4.innerText = "b";
      tr.appendChild(td4);
      let td5 = document.createElement("td");
      td5.innerText = "c";
      tr.appendChild(td5);
      let td6 = document.createElement("td");
      td6.innerText = "d";
      tr.appendChild(td6);

      tbody.appendChild(tr);

      this.instance.appendChild(tbody);
      // instance에 만들어진 table을 엑셀파일로 저장
      let config = { raw: true, type: "string" };
      let ws = XLSX.utils.table_to_sheet(this.instance, config);
      let wb = XLSX.utils.book_new();

      XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
      if (type == "csv") {
        XLSX.writeFile(wb, "주소록샘플.csv");
      } else if (type == "xlsx") {
        XLSX.writeFile(wb, "주소록샘플.xlsx");
      } else {
        XLSX.writeFile(wb, "주소록샘플.xls");
      }
    },
    sampleTxtDownload() {
      const text = "휴대폰번호,이름,#1#,#2#,#3#,#4#\n01012341234,홍길동1,a,b,c,d";
      // 저장하고자하는 파일명
      const filename = "주소록샘플.txt";
      const element = document.createElement("a");
      element.setAttribute("href", "data:text/csv;charset=utf-8," + encodeURIComponent(text));
      element.setAttribute("download", filename);
      document.body.appendChild(element);
      element.click();
    },
    handleScroll(e) {
      const chooseListThWrap = e.target.parentNode.children[0];
      chooseListThWrap.scrollLeft = e.target.scrollLeft;
    },
    handleThScroll(e) {
      const chooseListWrap = e.target.parentNode.children[1];
      chooseListWrap.scrollLeft = e.target.scrollLeft;
    },
    excelDown(type) {
      const phone = { 휴대폰번호: "", 이름: "" };

      const excelHeader = [Object.assign(phone)];
      // 엑셀 워크시트로 json 내보내기, 배열만 가능
      const dataWS = XLSX.utils.json_to_sheet(excelHeader);
      // 엑셀의 workbook(엑셀파일에 지정된 이름)을 만든다
      const wb = XLSX.utils.book_new();
      // workbook에 워크시트 추가, 시트명은 'addres'
      XLSX.utils.book_append_sheet(wb, dataWS, "address");
      // 엑셀 파일을 내보낸다. 엑셀 파일 저장명은 'people.xlsx'
      if (type === "xls") {
        XLSX.writeFile(wb, "address.xls");
      } else if (type === "xlsx") {
        XLSX.writeFile(wb, "address.xlsx");
      } else if (type === "csv") {
        XLSX.writeFile(wb, "address.csv");
      }
    },
    txtDown() {
      const text = ["휴대폰번호", "이름"];
      let filename = "address.txt";
      let element = document.createElement("a");
      element.setAttribute("href", "data:application/json;charset=utf-8," + encodeURIComponent(text));
      element.setAttribute("download", filename);

      element.style.display = "none";
      document.body.appendChild(element);

      element.click();
      document.body.removeChild(element);
    },
  },
};
</script>
