<template>
  <div class="popup idregist tit modal24 chnlReg">
    <div class="pop-head">
      <h3 class="pop-tit">채널 아이디 등록</h3>
      <p class="pop-desc">[카카오톡채널관리자센터]와 동일한 정보를 입력해 주세요.</p>
    </div>
    <form @submit.prevent="" autocomplete="off">
      <div class="pop-cont">
        <div class="regist_row aic">
          <h3 class="lh150">채널 검색용 아이디 입력</h3>
          <div class="input_wrap">
            <span>@</span>
            <input v-model="kkoChId" type="text" ref="kkoChId" id="kkoChId" class="input" placeholder="채널 아이디를 입력하세요" style="width: 100%" />
          </div>
        </div>
        <div class="regist_row">
          <h3>휴대폰 번호</h3>
          <div class="input_wrap">
            <p>*채널 아이디를 만들 때 인증한 관리자의 휴대폰 번호를 입력해 주세요.</p>
            <div class="certification certification1">
              <input type="text" class="input" id="kkoPhoneNumber" v-model="phoneNumber" placeholder="-없이 숫자만 입력하세요." />
              <button class="btn white" @click="getSenderKeyToken">인증번호 받기</button>
            </div>
          </div>
        </div>
        <div class="regist_row">
          <h3>인증 번호</h3>
          <div class="input_wrap">
            <div class="time_wrap" style="width: 100%">
              <input type="text" class="input" v-model="token" id="kkoToken" placeholder="인증번호를 입력하세요." style="width: 100%" />
            </div>
          </div>
        </div>
        <div class="regist_row">
          <h3>업종 카테고리</h3>
          <div class="input_wrap">
            <select class="select" @change="fnCate1Code" v-model="cate1Code">
              <option value="">카테고리 선택</option>
              <option v-for="(option, i) in cate1" v-bind:value="option.code" v-bind:key="i">
                {{ option.name }}
              </option></select
            >&nbsp;
            <select class="select" @change="fnCate2Code" v-model="cate2Code">
              <option value="">카테고리 선택</option>
              <option v-for="(option, i) in cate2" v-bind:value="option.code" v-bind:key="i">
                {{ option.name }}
              </option></select
            >&nbsp;
            <select class="select" v-model="categoryCode">
              <option value="">카테고리 선택</option>
              <option v-for="(option, i) in cate3" v-bind:value="option.code" v-bind:key="i">
                {{ option.name }}
              </option>
            </select>
            <!-- <select name="" id="" v-model="categoryCode" class="select" style="width: 32%">
              <option value="null" selected>카테고리 선택</option>
              <option :value="cate.code" v-for="(cate, index) in categoryList" :key="index">{{ cate.name }}</option>
            </select> -->
          </div>
        </div>
      </div>
      <div class="pop-btn2">
        <!-- 220615 수정 : 위치변경 -->
        <button type="button" class="btn large_pink" @click="saveKkoChForApi()">등록</button>
        <button type="button" class="btn large_white" @click="ModalClose()">취소</button>
      </div>
    </form>
  </div>
</template>
<script>
import sendNumberApi from "../service/sendNumberApi.js";
import loginApi from "@/modules/login/service/loginApi";
import confirm from "@/modules/commonUtil/service/confirm";
import { eventBus } from "@/modules/commonUtil/service/eventBus";

export default {
  name: "ChannelIdReg",
  components: {},
  data() {
    return {
      kkoChId: "",
      phoneNumber: "",
      token: "",
      category: [],
      categoryList: [],
      cate1: [],
      cate2: [],
      cate3: [],
      cate1Code: "",
      cate2Code: "",
      categoryCode: "",

      /* authConfirm: false,
      Timer: null,
      TimeCounter: 180,
      TimerStr: "03분 00초", */
      kkoAuthCheck: false,
      interval: "",
    };
  },
  mounted() {
    this.getAccessToken();
  },
  watch: {
    phoneNumber(val) {
      if (val !== "") {
        let regExp = /^[0-9]+$/;
        if (!regExp.test(val)) {
          confirm.fnAlert("", "휴대폰번호는 숫자만 입력 가능 합니다.", "alert");
          this.phoneNumber = val.replace(/[^0-9]/g, "");
        }
      }
    },
  },
  methods: {
    // 카카오톡 토큰 가져오기
    getAccessToken() {
      sendNumberApi.getAccessToken().then((response) => {
        if (response.data.success) {
          //console.log(response);
        } else {
          // confirm.fnAlert("카카오톡 토큰", "[카카오톡 토큰] 가져오기에 실패하였습니다.", "alert");
          confirm.fnAlert("", "[카카오톡 토큰] 가져오기에 실패하였습니다.", "alert");
        }
      });
    },
    // 인증번호받기
    getSenderKeyToken() {
      const patten_phone = /^[0-9]+$/; //핸드폰 번호, 하이픈  X
      if (!this.phoneNumber) {
        eventBus.$on("callbackEventBus", () => {
          document.getElementById("kkoPhoneNumber").focus();
        });
        confirm.fnConfirm("", "휴대폰 번호를 입력해 주세요.", "cbAlert");
        return;
      }

      if (!patten_phone.test(this.phoneNumber)) {
        eventBus.$on("callbackEventBus", () => {
          document.getElementById("kkoPhoneNumber").focus();
        });
        confirm.fnConfirm("", "휴대폰 번호를 확인해 주세요.", "cbAlert");
        return;
      }

      if (!this.kkoChId) {
        eventBus.$on("callbackEventBus", () => {
          document.getElementById("kkoChId").focus();
        });
        confirm.fnConfirm("", "채널 검색용 아이디를 입력해 주세요.", "cbAlert");
        return;
      }
      // 카카오톡 인증 받지 않은 경우에 실행
      if (!this.kkoAuthCheck) {
        var params = {
          kkoChId: "@" + this.kkoChId,
          phoneNumber: this.phoneNumber,
        };
        sendNumberApi.getSenderKeyToken(params).then((response) => {
          this.kkoAuthCheck = true;

          if (response.data.success) {
            // confirm.fnAlert("카카오톡 인증", "카카오톡으로 전송된 인증번호를 입력해주세요.", "alert");
            eventBus.$on("callbackEventBus", () => {
              document.getElementById("kkoToken").focus();
            });
            confirm.fnConfirm("", "휴대폰으로 인증번호를 보냈습니다.<br />인증번호를 입력해 주세요.", "cbAlert");
            this.showChannel = true;
          } else {
            // confirm.fnAlert("오류", "카카오톡 인증이 실패하였습니다.", "alert");
            confirm.fnAlert("", "카카오톡 인증이 실패하였습니다.", "alert");
            this.kkoAuthCheck = false;
          }
        });
      }
    },
    // 채널ID 저장
    saveKkoChForApi() {
      if (this.kkoChId === "") {
        // confirm.fnAlert("업종 카테고리.", "[업종 카테고리]를 선택해주세요.", "alert");
        confirm.fnAlert("", "채널 검색용 아이디를 입력해주세요.", "alert");
        return;
      } else if (!this.kkoAuthCheck) {
        confirm.fnAlert("", "카카오톡 인증을 진행해주세요.", "alert");
        return;
      } else if (this.token === "") {
        // confirm.fnAlert("업종 카테고리.", "[업종 카테고리]를 선택해주세요.", "alert");
        confirm.fnAlert("", "인증 번호를 입력해주세요.", "alert");
        return;
      } else if (this.categoryCode === "") {
        // confirm.fnAlert("업종 카테고리.", "[업종 카테고리]를 선택해주세요.", "alert");
        confirm.fnAlert("", "업종 카테고리를 선택해주세요.", "alert");
        return;
      }

      let params = {
        sts: "C",
        phoneNumber: this.phoneNumber,
        token: this.token,
        categoryCode: this.categoryCode,
        kkoChId: "@" + this.kkoChId,
      };

      sendNumberApi.saveKkoChForApi(params).then((response) => {
        if (response.data.success) {
          eventBus.$on("callbackEventBus", this.ModalClose);
          confirm.fnConfirm("채널ID", response.data.message, "cbAlert");
          this.showModal = false;
          this.$emit("kkoChnlIdListUpdate");
        } else if (response.data.code == "NOT_LOGIN"){
          eventBus.$on("callbackEventBus", this.logout);
          confirm.fnConfirm("", "세션연결이 끊어져, 로그아웃 됩니다.", "cbAlert");  
        } else {
          confirm.fnAlert("", response.data.message, "alert");
        }
      });
    },

    logout() {
      this.ModalClose();
      this.$store.commit("login/isAuth", false);
      this.$store.commit("hubEzData", []);
      loginApi.logout().then((response) => {
        if (response.data.success) {
            this.$router.push({  path: "/",  });
        }
      });
    },
    // 카카오톡 카테고리 가져오기
    getKkoCategory() {
      sendNumberApi.getKkoCategory({}).then((response) => {
        if (response.data.success) {
          this.category = response.data.data;
          /* const cate1 = Object.keys(this.category).filter((e) => e.length === 3);
          let cate = [];
          let name = "";
          cate1.forEach((e) => {
            name = this.category[e].name;
            let name2 = "";
            this.category[e].childs.forEach((e) => {
              name2 = e.name;
              let name3 = "";
              e.childs.forEach((e) => {
                name3 = e.name;
                cate.push({ name: name + "/" + name2 + "/" + name3, code: e.code });
              });
            });
          });
          this.categoryList = cate; */

          this.category = response.data.data;
          this.cate1 = this.category.root.childs;
        } else if (response.data.code == "NOT_LOGIN"){
          eventBus.$on("callbackEventBus", this.logout);
          confirm.fnConfirm("", "세션연결이 끊어져, 로그아웃 됩니다.", "cbAlert");  
        } else {
          confirm.fnAlert("", "카테고리 불러오기에 실패했습니다.", "alert");
        }
      });
      //await this.$api("/channelApi/manage/getKkoCategory", "post", {});
    },

    fnInit() {
      this.getKkoCategory();
      this.kkoChId = "";
      this.phoneNumber = "";
      this.token = "";
      this.category = [];
      this.categoryList = [];
      this.cate1 = [];
      this.cate2 = [];
      this.cate3 = [];
      this.cate1Code = "";
      this.cate2Code = "";
      this.categoryCode = "";
    },
    ModalClose() {
      var dimmed = document.getElementsByClassName("dimmed");
      var wrap = document.getElementsByClassName("popup-wrap");
      var obj = wrap[0].childElementCount;
      dimmed[0].style.display = "none";
      wrap[0].style.display = "none";
      for (var i = 0; i < obj; i++) {
        var target = document.getElementsByClassName("popup");
        target[i].style.display = "none";
        target[i].classList.remove("open");
      }
    },
    fnCate1Code: function (event) {
      this.cate2Code = "";
      this.categoryCode = "";
      this.cate2 = [];
      this.cate3 = [];
      if (event.target.value != null && event.target.value != "") {
        this.cate2 = this.category[event.target.value].childs;
        //this.cate2Code = this.cate2[0].code;
      }
    },
    fnCate2Code: function (event) {
      this.categoryCode = "";
      this.cate3 = [];
      if (event.target.value != null && event.target.value != "") {
        this.cate3 = this.category[event.target.value].childs;
        //this.categoryCode = this.cate3[0].code;
      }
    },
  },
};
</script>
