<template>
  <section class="m_section">
    <!--	s: 	contents-->
    <div class="contents">
      <h1></h1>
    </div>
    <confirm></confirm>
  </section>
</template>
<script>
import confirm from "@/modules/commonUtil/service/confirm";
import commonUtilApi from "../../commonUtil/service/commonUtilApi.js";
import { eventBus } from "@/modules/commonUtil/service/eventBus";
export default {
  name: "tossPayProc",
  data(){
    return{
    }
  },
  mounted() {
   this.successProc();
  },
  methods: {
    async successProc(){
      const params = {
        paymentKey : this.$route.query.paymentKey,
        orderId : this.$route.query.orderId,
        amount : this.$route.query.amount
      }
      
      const result = await commonUtilApi.payProcess(params);
      if(result.data.success){
        eventBus.$on("callbackEventBus", this.movePayProc);
        const msg = " 완료 되었습니다.";

        confirm.fnConfirm("",(result.data == "가상계좌" ? "가상계좌 발급이" : "결제가") + msg, "cbAlert");
      }else{
        eventBus.$on("callbackEventBus", this.movePayProc);
        confirm.fnConfirm("", result.data.message, "cbAlert");
      }
    },
    movePayProc(){
      window.top.location.href = "/charge/chargeProc";
    }
  }
}
</script>