<template>
  <div class="popup tit presendpop">
    <div class="pop-head">
      <h3 class="pop-tit">테스트 전송</h3>
    </div>
    <div class="pop-cont">
      <div class="list_top">
        <ul>
          <li><p>테스트 전송도 요금이 차감됩니다.</p></li>
          <li><p>테스트 전송은 최대 5명에게 메시지를 보낼 수 있습니다.</p></li>
          <li><p>실제 수신된 메시지 내용을 휴대폰에서 미리 확인하는 용도입니다. 고객들에게 보내기 전에 소유하신 휴대폰으로 확인해보세요.</p></li>
        </ul>
      </div>

      <div class="fixed-table-cover">
        <div class="fixed-table-container" style="width: 938px">
          <div class="fixed-table-header-bg"></div>
          <div class="fixed-table-wrapper">
            <table class="fixed-table border" style="table-layout: fixed">
              <colgroup>
                <template v-if="autoMsg.length === 0">
                  <col width="33%" />
                  <col width="33%" />
                  <col width="33%" />
                </template>
                <template v-else>
                  <col :width="Number(100 / (autoMsg.length + 1)) + '%'" v-for="(msg, index) in autoMsg" :key="index" />
                </template>
              </colgroup>
              <thead>
                <tr>
                  <th v-if="autoMsg.length === 0"></th>
                  <th class="td_phone" :style="autoMsg.length === 0 ? 'width:33%' : 'width:' + Number(100 / (autoMsg.length + 1)) + '%'">
                    <div class="th-text">휴대폰 번호</div>
                  </th>
                  <th v-if="autoMsg.length === 0"></th>
                  <th :class="index === 0 ? 'td_name' : 'td_' + index" v-for="(msg, index) in autoMsg" :key="index" :style="'width:' + Number(100 / (autoMsg.length + 1)) + '%'">
                    <div class="th-text">{{ msg | textLengthCut(5) }}</div>
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(list, index) in preRecvInfoList" :key="index">
                  <td v-if="autoMsg.length === 0"></td>
                  <td class="td_phone">
                    <input type="text" class="input add" v-model="list.phone" placeholder="‘-’없이 숫자만 입력하세요." @input="fnPhoneDash(list.phone, index)" />
                  </td>
                  <td :class="index === 0 ? 'td_name' : 'td_' + index" v-for="(msg, index) in autoMsg" :key="index">
                    <input type="text" class="input" v-model="list[msg]" :placeholder="autoMsg[index] ? $gfnCommonUtils.textLengthCut(autoMsg[index], 5) : ''" />
                  </td>
                  <td v-if="autoMsg.length === 0"></td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
    <div class="pop-btn2 center">
      <button type="button" class="btn large_pink" @click="fnPreSendMessage">테스트 전송</button>
      <button type="button" class="btn large_white" @click="ModalClose">취소</button>
    </div>
  </div>
</template>

<script>
import confirm from "@/modules/commonUtil/service/confirm";

export default {
  name: "preSendMessage",
  props: {
    rejectPhoneList: Array,
    msg: String,
    reqProduct: String,
    adByte: Number,
    autoMsg: Array,
    ad: Boolean,
    alimSendData: Object,
    tmplt: Object,
  },
  data() {
    return {
      preRecvInfoList: [],
      hashNum: new Map(), // 연락처 중복 처리 변수 값 (errorCheck 할 경우 function 마다 초기화 진행)
    };
  },
  watch: {
    excelData: {
      handler(newData) {
        this.passNumber = newData["paramData"].filter((e) => {
          return e.validator === true;
        }).length;
        this.excelData["paramData"] = newData["paramData"];
      },
      deep: true,
    },
    autoMsg: {
      handler(newAutoMsg) {
        this.testSendList(newAutoMsg);
      },
      deep: true,
    },
  },
  mounted() {
    this.testSendList([]);
  },
  methods: {
    //모달창
    ModalOpen(target) {
      var dimmed = document.getElementsByClassName("dimmed");
      var wrap = document.getElementsByClassName("popup-wrap");
      var obj = document.getElementsByClassName(target);
      obj[0].classList.add("open");
      dimmed[0].style.display = "block";
      wrap[0].style.display = "block";
      obj[0].style.display = "block";
      this.ModalHeight();
      if (target == "address") {
        this.$refs.sendAddr.fnSendAddrInit();
      }
    },
    ModalClose() {
      var dimmed = document.getElementsByClassName("dimmed");
      var wrap = document.getElementsByClassName("popup-wrap");
      var obj = wrap[0].childElementCount;
      dimmed[0].style.display = "none";
      wrap[0].style.display = "none";
      for (var i = 0; i < obj; i++) {
        var target = document.getElementsByClassName("popup");
        target[i].style.display = "none";
        target[i].classList.remove("open");
      }
    },
    ModalHeight() {
      //팝업 heght가 device height보다 클때
      var winH = window.innerHeight,
        obj = document.querySelector(".popup.open"),
        objH = obj.clientHeight;
      if (objH >= winH) {
        obj.classList.add("over");
      }
    },
    fnPreSendMessage() {
      this.hashNum = new Map();
      if (
        this.preRecvInfoList.filter((e) => {
          return e.phone !== "";
        }).length === 0
      ) {
        // confirm.fnAlert("알림", "연락처가 등록되지 않았습니다.", "alert");
        confirm.fnAlert("", "연락처가 등록되지 않았습니다.", "alert");
        return;
      }

      this.preRecvInfoList.map((e, index) => {
        return {
          // 유효성 체크 항목 추가
          ...e,
          phone: this.$gfnCommonUtils.hpNumberAddDash(e["휴대폰번호"]),
          status: this.errorCheck(index, e["phone"], { ...e }),
          validator: this.validator,
          selected: false,
          index,
        };
      });

      this.$emit(
        "preSendInfo",
        this.preRecvInfoList.filter((e) => {
          return e.phone !== "";
        })
      );
      this.ModalClose();
    },

    errorCheck(index, phoneNumber, data) {
      const patten_phone = /^(01[016789]{1}|070)-?[0-9]{3,4}-?[0-9]{4}$/; //핸드폰 번호, 하이픈 상관 X
      const pattern_eng = /[a-zA-Z]/; // 문자
      const pattern_spc = /[~!@#$%^&*()_+|<>?:{}]/; // 특수문자
      const pattern_kor = /[ㄱ-ㅎ|ㅏ-ㅣ|가-힣]/; // 한글체크

      const rejectPhone = this.rejectPhoneList.some((e) => {
        return this.$gfnCommonUtils.hpNumberRemoveDash(e.rcvblckno) === this.$gfnCommonUtils.hpNumberRemoveDash(phoneNumber);
      });
      // 휴대폰 번호, 이름, 중복 체크
      if (rejectPhone) {
        this.preRecvInfoList[index].status = "080 수신거부번호";
        this.preRecvInfoList[index].validator = false;
      } else if (phoneNumber == "" || phoneNumber == undefined) {
        this.preRecvInfoList[index].status = "번호없음";
        this.preRecvInfoList[index].validator = false;
        //return errorText;
      } else if (pattern_eng.test(phoneNumber) || pattern_spc.test(phoneNumber) || pattern_kor.test(phoneNumber)) {
        this.preRecvInfoList[index].status = "번호오류";
        this.preRecvInfoList[index].validator = false;
        //return errorText;
      } else if (!patten_phone.test(phoneNumber)) {
        this.preRecvInfoList[index].status = "번호오류";
        this.preRecvInfoList[index].validator = false;
        //return errorText;
      } else if (this.hashNum.has(phoneNumber.replace(/-/g, ""))) {
        this.preRecvInfoList[index].status = "번호중복";
        this.preRecvInfoList[index].validator = false;
      } else {
        // 중복체크

        // 자동 변경 정보 체크
        let acText = [];

        this.autoMsg.forEach((element) => {
          if (!data[element]) {
            acText.push(element);
          }
        });

        if (!this.$gfnCommonUtils.isEmpty(acText)) {
          this.preRecvInfoList[index].status = "자동변경정보 누락 (" + acText.join(", ") + ")";
          this.preRecvInfoList[index].validator = false;
          return;
        }

        // 자동변경정보를 통한 byte 계산
        // const pattern = /(?<=#{)(.*?)(?=})/g;
        let autoMsgByte = 0;
        let autoMsgText = "";

        //알림톡 byte 체크
        if (this.alimSendData) {
          autoMsgText = this.tmplt.tmpltSbst.replace(/((#{).*?(\}))/g, (match) => {
            const matches = /#\{([^#{}]*)\}/g.exec(match);
            return data[matches[1]];
          });
          autoMsgByte = this.$gfnCommonUtils.getTextLen(autoMsgText);
          if (autoMsgByte > 1000) {
            this.preRecvInfoList[index].status = `자동 변경 정보 삽입으로<br/>1000자 초과(${autoMsgByte}자)`;
            this.validator = false;
            return;
          }
        }

        autoMsgText = this.msg.replace(/((#{).*?(\}))/g, (match) => {
          const matches = /#\{([^#{}]*)\}/g.exec(match);
          return data[matches[1]];
        });

        if (this.ad) {
          autoMsgByte = this.$gfnCommonUtils.getByte(autoMsgText) + this.adByte;
        } else {
          autoMsgByte = this.$gfnCommonUtils.getByte(autoMsgText);
        }
        // 문자타입이 SMS이고 90Byte 보다 클 경우 에러 처리
        if (this.reqProduct === "SMS" && autoMsgByte > 90) {
          this.preRecvInfoList[index].status = `자동 변경 정보 삽입으로<br/>90Byte 초과(${autoMsgByte}Byte)`;
          this.preRecvInfoList[index].validator = false;
          return;
        }

        // 문자타입이 LMS , MMS 이고 2000byte 보다 클 경우 에러 처리
        if ((this.reqProduct === "LMS" || this.reqProduct === "MMS") && autoMsgByte > 2000) {
          this.preRecvInfoList[index].status = `자동 변경 정보 삽입으로<br/>2000Byte 초과(${autoMsgByte}Byte)`;
          this.preRecvInfoList[index].validator = false;
          return;
        }

        this.preRecvInfoList[index].status = "정상";
        this.preRecvInfoList[index].validator = true;
        this.hashNum.set(phoneNumber.replace(/-/g, ""), "phone");
      }

      //return errorText;
    },
    fnPhoneDash(phoneNumber, index) {
      this.preRecvInfoList[index].phone = this.$gfnCommonUtils.hpNumberAddDash(this.$gfnCommonUtils.cutStr(phoneNumber.replace(/[^-0-9]/g, ""), 16));
    },
    handleScroll(e) {
      const chooseListThWrap = e.target.parentNode.children[0];
      chooseListThWrap.scrollLeft = e.target.scrollLeft;
    },
    handleThScroll(e) {
      const chooseListWrap = e.target.parentNode.children[1];
      chooseListWrap.scrollLeft = e.target.scrollLeft;
    },
    testSendList(newAutoMsg) {
      let myList = [];
      let obj = { phone: "" };

      newAutoMsg.forEach((e) => {
        obj[e] = "";
      });
      obj["status"] = "";
      obj["validator"] = "";
      obj["selected"] = "";

      for (let index = 0; index < 5; index++) {
        myList.push(obj);
      }

      myList = myList.map((e, index) => {
        return {
          ...e,
          index,
        };
      });
      this.preRecvInfoList = myList;
    },
  },
};
</script>
