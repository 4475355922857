<template>
  <div class="m_preview_alimtalk">
    <button class="btn_close" @click="mPreAlimClose()"></button>
    <div class="m_preview previews">
      <div class="swiper m_preview_swiper">
        <div class="swiper-wrapper">
          <div class="swiper-slide preview_swiper talk_preview">
            <p class="preview_title">알림톡 미리보기</p>
            <div class="preview_top">
              <input type="radio" name="preview_type" id="btn_sent" checked="" /><label for="btn_sent"
                ><span>{{ alimTalkInfo.chnlId !== "null" ? alimTalkInfo.chnlId : "채널아이디" }}</span>
                <input type="text" placeholder="채널 아이디" :value="alimTalkInfo.chnlId !== 'null' ? alimTalkInfo.chnlId : ''" readonly
              /></label>
            </div>

            <div class="preview_cont" style="display: block">
              <div class="talk_channel">
                <div class="img"><img src="../../../assets/images/preview_icon.png" alt="" /></div>
                <p class="channel_name">{{ alimTalkInfo.chnlId }}</p>
              </div>
              <div class="talk_wrap">
                <div class="talk_top">
                  <span>알림톡 도착</span>
                </div>
                <div class="talk_cont">
                  <div class="talk_tit" v-if="alimTalkInfo.emphsTpCd === 'TEXT'">
                    <p class="t_sub" :style="alimTalkInfo.emphsSubtle ? 'color: rgb(18, 16, 6);' : ''">{{ alimTalkInfo.emphsSubtle ? alimTalkInfo.emphsSubtle : "강조유형 보조문구 미리보기" }}</p>
                    <p class="t_tit" :style="alimTalkInfo.emphsTitle ? 'color: rgb(18, 16, 6);' : ''">{{ alimTalkInfo.emphsTitle ? alimTalkInfo.emphsTitle : "제목 미리보기" }}</p>
                  </div>
                  <div class="talk_txt">
                    <p class="cont" :style="alimTalkInfo.tmpltSbst ? 'color: rgb(18, 16, 6);' : ''" v-html="previewMsg ? previewMsg : '내용 미리보기'"></p>
                    <p
                      class="sub_info"
                      v-if="alimTalkInfo.tmpltTpCd === 'EX' || alimTalkInfo.tmpltTpCd === 'MI'"
                      :style="alimTalkInfo.etcInfo ? 'color: rgb(18, 16, 6);' : ''"
                      v-html="previewEtc ? previewEtc : '부가정보를 입력하세요'"
                    ></p>
                    <p
                      class="sub_info"
                      v-if="alimTalkInfo.tmpltTpCd === 'AD' || alimTalkInfo.tmpltTpCd === 'MI'"
                      :style="adMsg ? 'color: rgb(18, 16, 6);' : ''"
                      v-html="previewAd ? previewAd : '채널 추가하고 이 채널의 마케팅 메시지 등을 카카오톡으로 받기'"
                    ></p>
                  </div>
                  <!-- <div class="talk_btn">배송 조회하기</div> -->
                  <div class="talk_btn" v-for="(button, index) in alimTalkInfo.buttonList" :key="index">{{ button.name }}</div>
                </div>
              </div>
            </div>
          </div>
          <div class="swiper-slide preview_swiper talk_history">
            <p class="preview_title">알림톡 최근 작성메시지</p>
            <!--	s: 최근전송내역	-->
            <div class="preview_cont">
              <div class="history_wrap">
                <div class="history">
                  <div class="history_items_wrap scr_wrap" v-if="recentSendAlimList.length > 0">
                    <swiper class="history_items swiper-wrapper" :options="swiperOption2" ref="rcsMsgList">
                      <swiper-slide class="history_item swiper-slide" v-for="(message, index) in recentSendAlimList" :key="index">
                        <div class="history_cont">
                          <div class="txt_wrap">
                            <p class="btxt">{{ message.tmpltName }}</p>
                            <p class="stxt">{{ message.msg }}</p>
                          </div>
                        </div>
                        <div class="history_desc">
                          <div class="history_txt">
                            <p class="date">{{ message.regDt }}</p>
                            <p class="tit">{{ message.chnlId }}</p>
                          </div>
                          <div class="type kakao"></div>
                        </div>
                      </swiper-slide>
                    </swiper>
                  </div>
                  <div class="history_items_wrap scr_wrap" v-else>
                    <!-- <p>최근작성문자 없음</p> -->
                  </div>
                  <div class="btn_wrap" v-if="recentSendAlimList.length > 0">
                    <button class="btn_up swiper-button-next"></button>
                    <button class="btn_down swiper-button-prev"></button>
                  </div>
                </div>
              </div>
            </div>

            <!-- 	e: 최근전송내역 	-->
          </div>
        </div>
      </div>
      <!-- <div class="m_preview_alimtalk_pagination"></div> -->
    </div>
  </div>
</template>
<script>
import { swiper, swiperSlide } from "vue-awesome-swiper";
import { eventBus } from "@/modules/commonUtil/service/eventBus";
import confirm from "@/modules/commonUtil/service/confirm";

export default {
  name: "MobilePreviewAlim",
  components: { swiper, swiperSlide },
  props: {
    alimTalkInfo: Object,
    recentSendAlimList: Array,
    modiAuth: {
      type: Boolean,
      default: true,
    },
    adMsg: String,
  },
  data() {
    const vm = this;
    return {
      swiperOption2: {
        slidesOffsetBefore: 0, // slidesOffsetBefore는 첫번째 슬라이드의 시작점에 대한 변경할 때 사용
        slidesOffsetAfter: 0, // slidesOffsetAfter는 마지막 슬라이드 시작점 + 마지막 슬라이드 너비에 해당하는 위치의 변경이 필요할 때 사용
        //freeMode: true, // freeMode를 사용시 스크롤하는 느낌으로 구현 가능
        centerInsufficientSlides: true, // 컨텐츠의 수량에 따라 중앙정렬 여부를 결정함
        loop: false,
        direction: "vertical",
        slidesPerView: 3,
        spaceBetween: 12,
        // Navigation arrows
        navigation: {
          nextEl: ".swiper-button-prev",
          prevEl: ".swiper-button-next",
        },
        observer: true,
        observeParents: true,
        on: {
          click: function () {
            if (vm.modiAuth) {
              const clickedIndex = this.clickedIndex;
              vm.handleClickSlide(clickedIndex);
            }
          },
        },
      },
    };
  },
  computed: {
    // 미리보기 화면 줄바꿈 처리
    previewMsg() {
      return this.alimTalkInfo.tmpltSbst ? this.alimTalkInfo.tmpltSbst.split(" ").join("&nbsp;").split("\n").join("<br/>") : "";
    },
    previewEtc() {
      return this.alimTalkInfo.etcInfo ? this.alimTalkInfo.etcInfo.split(" ").join("&nbsp;").split("\n").join("<br/>") : "";
    },
    previewAd() {
      return this.adMsg ? this.adMsg.split(" ").join("&nbsp;").split("\n").join("<br/>") : "";
    },
  },
  created() {},
  mounted() {},
  methods: {
    mPreAlimClose() {
      const mAlimPreview = document.querySelector(".m_preview_alimtalk");
      const dimmed = document.querySelector(".dimmed");

      mAlimPreview.classList.remove("on");
      dimmed.classList.remove("open");
    },
    handleClickSlide(index) {
      eventBus.$on("callbackEventBus", () => {
        this.fnSendData(index);
      });
      // confirm.fnConfirm("최근작성문자 가져오기", "최근 작성문자로 기존 내용에 덮어 씌워집니다. <br/>계속 진행하시겠습니까?", "confirm");
      confirm.fnConfirm("", "최근 작성문자로 기존 내용에 덮어 씌워집니다. <br/>계속 진행하시겠습니까?", "confirm");
    },
    fnSendData(index) {
      this.$emit("recentSendAlimList", this.recentSendAlimList[index]);
      this.mPreAlimClose();
    },
  },
};
</script>
