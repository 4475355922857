import notice from "../views/notice";
import noticeDetail from "../views/noticeDetail";
import faq from "../views/faq";
import consulting from "../views/consulting";

export default [
  {
    path: "/serviceCenter/notice",
    name: "notice",
    component: notice,
  },
  {
    path: "/serviceCenter/noticeDetail/:ntNo",
    name: "noticeDetail",
    component: noticeDetail,
    props: true,
  },
  {
    path: "/serviceCenter/faq",
    name: "faq",
    component: faq,
  },
  {
    path: "/serviceCenter/consulting",
    name: "consulting",
    component: consulting,
  },
];
