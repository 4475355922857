import httpClient from "@/common/http-client";

const selectAutInfo = (params) => {
  return httpClient.post("/api/auth/selectAutInfo", params, { headers: { "show-layer": "Yes" } });
};

const login = (params) => {
  return httpClient.post("/api/auth/login", params, { headers: { "show-layer": "Yes" } });
};

const logout = () => {
  return httpClient.get("/api/auth/logout", { headers: { "show-layer": "Yes" } });
};

const getMenuForRole = (params) => {
  return httpClient.post("/api/auth/getMenuForRole", params, { headers: { "show-layer": "Yes" } });
};

const getProjectForUser = (params) => {
  return httpClient.post("/api/auth/getProjectForUser", params, { headers: { "show-layer": "Yes" } });
};

const getProjectChUseListForUser = (params) => {
  return httpClient.post("/api/auth/getProjectChUseListForUser", params, { headers: { "show-layer": "Yes" } });
};

const changePwdExec = (params) => {
  return httpClient.post("/api/public/changePwdExec", params, { headers: { "show-layer": "Yes" } });
};

const findLoginId = (params) => {
  return httpClient.post("/api/public/findLoginId", params, { headers: { "show-layer": "Yes" } });
};

const secondCertify = (params) => {
  return httpClient.post("/api/auth/secondCertify", params, { headers: { "show-layer": "Yes" } });
};

const certifyNumber = (params) => {
  return httpClient.post("/api/auth/secondCertifyProcess", params, { headers: { "show-layer": "Yes" } });
};

const findCertifyPwdInit = (params) => {
  return httpClient.post("/api/auth/findCertifyPwdInit", params, { headers: { "show-layer": "Yes" } });
};

const resetPwdExec = (params) => {
  return httpClient.post("/api/public/resetPwdExec", params, { headers: { "show-layer": "Yes" } });
};

const getUserChargeInfo = (params) => {
  return httpClient.post("/api/auth/getUserChargeInfo", params, { headers: { "show-layer": "Yes" } });
};

const firstCertifyConfirm = (params) => {
  return httpClient.post("/api/auth/firstCertifyConfirm", params, { headers: { "show-layer": "Yes" } });
};

const pwInitIdNumberCheck = (params) => {
  return httpClient.post("/api/auth/findCertifyPwdInit", params, { headers: { "show-layer": "Yes" } });
};

const workStateCheck = (params) => {
  return httpClient.post("/api/auth/workStateCheck", params, { headers: { "show-layer": "Yes" } });
};

export default {
  selectAutInfo,
  login,
  logout,
  getMenuForRole,
  getProjectForUser,
  getProjectChUseListForUser,
  changePwdExec,
  findLoginId,
  secondCertify,
  certifyNumber,
  findCertifyPwdInit,
  resetPwdExec,
  getUserChargeInfo,
  firstCertifyConfirm,
  pwInitIdNumberCheck,
  workStateCheck
};
