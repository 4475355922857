import httpClient from "@/common/http-client";

const applyPrivacyPolicy = (params) => {
  return httpClient.get("/api/policy/privacyPolicy", params, { headers: { "show-layer": "Yes" } });
};

const applyPrivacyPolicyHistory = (params) => {
  return httpClient.get("/api/policy/privacyPolicy/history", params, { headers: { "show-layer": "Yes" } });
};

export default {
  applyPrivacyPolicy,
  applyPrivacyPolicyHistory
};
