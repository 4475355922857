import Vue from "vue";
import Vuex from "vuex";
import login from "@/modules/login/store";
import createPersistedState from "vuex-persistedstate";

Vue.use(Vuex);

const state = {
  LoadingStatus: false,
  hubEzData: [],
  userChargeInfo: [],
  isMobile: false,
};

export default new Vuex.Store({
  state,
  getters: {
    getHubEzData: (state) => state.hubEzData,
    getUserChargeInfo: (state) => state.userChargeInfo,
    getIsMobile: (state) => state.isMobile,
  },
  mutations: {
    startSpinner(state) {
      state.LoadingStatus = true;
    },
    endSpinner(state) {
      state.LoadingStatus = false;
    },
    hubEzData(state, value) {
      state.hubEzData = value;
    },
    userChargeInfo(state, value) {
      state.userChargeInfo = value;
    },
    isMobile(state, value) {
      state.isMobile = value;
    },
  },
  modules: {
    login,
  },
  //vuex plugin 명시 로그인시 스토어에 계속 저장됨.
  plugins: [createPersistedState({ paths: ["login", "hubEzData", "isMobile"] })],
});
