<template>
  <section class="m_section">
    <!--	s: 	contents-->
    <div class="contents">
      <MobileGnb />
      <div class="tabcont_wrap without_tab dashboard_wrap">
        <div class="box_wrap w33p fee">
          <div class="tit">
            <h2>이번 달 사용요금</h2>
            <p class="range">{{ searchStartDate }}01일~{{ searchEndDate }}</p>
          </div>
          <div class="total_wrap">
            <div class="chart_wrap">
              <div id="total">
                <apexchart class="total_chart" ref="totChgChart" type="radialBar" width="100%" height="100%" :options="total.chartOptions" :series="total.series"></apexchart>
              </div>
              <div class="txt_wrap">
                <p class="stxt">사용요금</p>
                <p class="total">
                  <span class="pink">{{ totalUseAmt | formatPrice }}</span
                  >원
                </p>
              </div>
            </div>
            <!-- <p v-show="mrtBalance > 0" class="mtxt"> -->
              <!-- 후불 CASE -->
            <p class="mtxt" v-if="joinDiv != '02' && prodNm != '자유요금제'">  
              요금제 남은 금액 <span class="bold">{{ mrtBalance | formatPrice }}</span
              >원<br />
              <span class="bold">장문메시지(LMS) {{ lmsAvailableCnt | formatPrice }}</span
              >건 <span>보낼 수 있습니다.</span>
            </p>
              <!-- 선불 충전 CASE  -->
            <p class="mtxt" v-else-if="joinDiv == '02'">  
              충전 잔여금액 <span class="bold">{{ mrtBalance | formatPrice }}</span
              >원<br />
              <span class="bold">장문메시지(LMS) {{ lmsAvailableCnt | formatPrice }}</span
              >건 <span>보낼 수 있습니다.</span>
            </p>
            <!-- <p v-show="mrtBalance == 0" class="mtxt">이번 달 사용 요금은 0원입니다</p> -->
          </div>
          <div class="detail">
            <ul class="detail_list">
              <li>상세 사용요금</li>
              <li>
                <p class="cate">단문메시지(SMS)</p>
                <p class="price">
                  <span>{{ smsCharge | formatPrice }}</span
                  >원
                </p>
              </li>
              <li>
                <p class="cate">장문메시지(LMS)</p>
                <p class="price">
                  <span>{{ lmsCharge | formatPrice }}</span
                  >원
                </p>
              </li>
              <li>
                <p class="cate">사진메시지(MMS)</p>
                <p class="price">
                  <span>{{ mmsCharge | formatPrice }}</span
                  >원
                </p>
              </li>
              <li>
                <p class="cate">알림톡</p>
                <p class="price">
                  <span>{{ altCharge | formatPrice }}</span
                  >원
                </p>
              </li>
              <!-- <li>
                <p class="cate">친구톡</p>
                <p class="price"><span>7,000</span>원</p>
              </li> -->
            </ul>
          </div>
        </div>
        <div class="box_wrap w33p case">
          <div class="tit">
            <h2>이번 달 사용 건수</h2>
            <p class="range">{{ searchStartDate }}01일~{{ searchEndDate }}</p>
          </div>
          <div class="box">
            <div class="txt_wrap">
              <p class="cate">일반문자</p>
              <p class="total">
                총 <span class="pink">{{ smsCntTot | formatPrice }}</span
                >건
              </p>
            </div>
            <div class="chart_wrap">
              <div v-show="smsCntTot > 0" id="sms">
                <apexchart ref="smsCntChart" type="bar" height="200" :options="sms.chartOptions" :series="sms.series"></apexchart>
              </div>
              <div v-show="smsCntTot == 0" class="no_data">
                <p>이번 달에 보낸 메시지가 없습니다</p>
              </div>
            </div>
          </div>
          <div class="box">
            <div class="txt_wrap">
              <p class="cate">카카오 비즈메시지</p>
              <p class="total">
                총 <span class="pink">{{ bizCntTot | formatPrice }}</span
                >건
              </p>
            </div>
            <div class="chart_wrap">
              <div v-show="bizCntTot > 0" id="biz">
                <apexchart ref="bizCntChart" type="bar" height="110" :options="biz.chartOptions" :series="biz.series"></apexchart>
              </div>
              <div v-show="bizCntTot == 0" class="no_data">
                <p>이번 달에 보낸 메시지가 없습니다</p>
              </div>
            </div>
          </div>
        </div>
        <div class="box_wrap w33p unit">
          <div class="tit">
            <h2>메시지 별 단가</h2>
          </div>
          <div class="box">
            <div class="group_wrap sms">
              <div class="group_top">
                <div class="img"></div>
                <p>일반문자</p>
              </div>
              <ul class="detail_list">
                <li>
                  <p class="cate">단문메시지(SMS)</p>
                  <p class="price">
                    <span>{{ smsUnitPrice | formatPrice }}</span
                    >원
                  </p>
                </li>
                <li>
                  <p class="cate">장문메시지(LMS)</p>
                  <p class="price">
                    <span>{{ lmsUnitPrice | formatPrice }}</span
                    >원
                  </p>
                </li>
                <li>
                  <p class="cate">사진메시지(MMS)</p>
                  <p class="price">
                    <span>{{ mmsUnitPrice | formatPrice }}</span
                    >원
                  </p>
                </li>
              </ul>
            </div>
          </div>
          <div class="box">
            <div class="group_wrap biz">
              <div class="group_top">
                <div class="img"></div>
                <p>카카오 비즈메시지</p>
              </div>
              <ul class="detail_list">
                <li>
                  <p class="cate">알림톡</p>
                  <p class="price">
                    <span>{{ altUnitPrice | formatPrice }}</span
                    >원
                  </p>
                </li>
                <!--
                <li>
                  <p class="cate">친구톡</p>
                  <p class="price"><span>15</span>원</p>
                </li>
                -->
              </ul>
            </div>
          </div>
        </div>
        <div class="box_wrap w100p">
          <div class="tit">
            <h2>최근 6개월 메시지 종류 별 전송 건수</h2>
          </div>
          <div class="chart_wrap">
            <div v-show="monthTotal > 0" id="channel">
              <apexchart ref="channelChart" type="bar" height="260" :options="channel.chartOptions" :series="channel.series"></apexchart>
            </div>
            <div v-show="monthTotal == 0" class="no_data">
              <p>최근 6개월 동안 보낸 메시지가 없습니다.</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--	e: 	contents	-->
  </section>
</template>
<script>
import VueApexCharts from "vue-apexcharts";
import confirm from "@/modules/commonUtil/service/confirm";
import myInfoApi from "@/modules/myInfo/service/myInfoApi";
import MobileGnb from "@/components/MobileGnb.vue";

export default {
  name: "defaultName",
  components: {
    apexchart: VueApexCharts,
    MobileGnb,
  },
  data() {
    const _vm = this;
    return {
      searchStartDate: this.$gfnCommonUtils.getCurretMonth("yyyy년mm월"),
      searchEndDate: this.$gfnCommonUtils.getCurretDate("yyyy년mm월dd일"),
      totalPixAmt: 0,
      totalUseAmt: 0,
      useAvailableAmt: 0,
      smsCharge: 0,
      lmsCharge: 0,
      mmsCharge: 0,
      altCharge: 0,
      smsCntTot: 0,
      bizCntTot: 0,
      smsUnitPrice: 0,
      lmsUnitPrice: 0,
      mmsUnitPrice: 0,
      altUnitPrice: 0,
      lmsAvailableCnt: 0,
      prodAmt: 0,
      prodNm: "",
      mrtBalance: 0,
      monthTotal: 0,
      monthCategorys: [],
      totalValue: 0,
      joinDiv: "",
      total: {
        series: [0], // 사용금액/전체금액*100
        chartOptions: {
          labels: "0",
          chart: {
            type: "radialBar",
            offsetY: 0,
            sparkline: {
              enabled: true,
            },
          },
          plotOptions: {
            radialBar: {
              startAngle: -90,
              endAngle: 90,
              track: {
                background: "#e7e7e7",
                strokeWidth: "100%",
                dropShadow: {
                  enabled: true,
                  top: 4,
                  left: 0,
                  color: "#999",
                  opacity: 0.3,
                  blur: 2,
                },
              },
              dataLabels: {
                enabled: true,
                name: { show: true },
                value: {
                  offsetY: 40,
                  fontSize: "24px",
                  color: "#eb008b",
                  fontWeight: "700",
                },
                total: {
                  show: true,
                  label: "" /* 220802 금액 라벨 '사용요금'은 위치 이동을 위해 css 가상선택자로 수정합니다 */,
                  formatter: function (val) {
                    return val.globals.labels + " 원"; //사용금액
                  },
                },
              },
            },
          },
          fill: {
            type: "gradient",
            gradient: {
              shade: "dark",
              type: "vertical",
              gradientToColors: ["#da3acd"],
              stops: [0, 100],
            },
          },
          colors: ["#7736b1"],
        },
      },
      sms: {
        series: [
          {
            data: [0, 0, 0],
          },
        ],
        chartOptions: {
          chart: {
            type: "bar",
            height: 135,
            toolbar: {
              show: false,
            },
            animations: {
              enabled: true,
              speed: 1000,
              animateGradually: {
                enabled: true,
                delay: 800,
              },
              dynamicAnimation: {
                enabled: true,
                speed: 1000,
              },
            },
          },

          plotOptions: {
            bar: {
              horizontal: true,
              barHeight: "40%",
              distributed: true,
            },
          },
          dataLabels: {
            enabled: true,
          },
          xaxis: {
            categories: ["SMS", "LMS", "MMS"],
          },
          tooltip: {
            y: {
              formatter: function (val) {
                return _vm.$gfnCommonUtils.formatPrice(val) + " 건 사용";
              },
              title: {
                formatter: function () {
                  return "";
                },
              },
            },
            x: {
              formatter: function (val) {
                return val;
              },
            },
          },
          colors: ["#d43acb", "#af38c1", "#8837b6"],
          legend: {
            show: false,
          },
        },
      },
      biz: {
        series: [
          {
            data: [0],
          },
        ],
        chartOptions: {
          chart: {
            type: "bar",
            height: 100,
            toolbar: {
              show: false,
            },
            animations: {
              enabled: true,
              speed: 1000,
              animateGradually: {
                enabled: true,
                delay: 800,
              },
              dynamicAnimation: {
                enabled: true,
                speed: 1000,
              },
            },
          },
          plotOptions: {
            bar: {
              horizontal: true,
              barHeight: "40%",
              distributed: true,
            },
          },
          dataLabels: {
            enabled: true,
            style: {
              colors: ["#000"],
            },
          },
          tooltip: {
            y: {
              formatter: function (val) {
                return _vm.$gfnCommonUtils.formatPrice(val) + " 건 사용";
              },
              title: {
                formatter: function () {
                  return "";
                },
              },
            },
            x: {
              formatter: function (val) {
                return val;
              },
            },
          },
          xaxis: {
            categories: ["알림톡"],
          },
          colors: ["#f9e000"],
          legend: {
            show: false,
          },
        },
      },
      channel: {
        series: [
          {
            name: "단문메시지(SMS)",
            data: [0, 0, 0, 0, 0, 0],
          },
          {
            name: "장문메시지(LMS)",
            data: [0, 0, 0, 0, 0, 0],
          },
          {
            name: "사진메시지(MMS)",
            data: [0, 0, 0, 0, 0, 0],
          },
          {
            name: "알림톡",
            data: [0, 0, 0, 0, 0, 0],
          },
          /* {
            name: "친구톡",
            data: [35, 41, 36, 26, 45, 48],
          }, */
        ],
        chartOptions: {
          chart: {
            type: "bar",
            height: 260,
          },
          plotOptions: {
            bar: {
              horizontal: false,
              columnWidth: "35%",
            },
          },
          dataLabels: {
            enabled: false,
          },
          stroke: {
            show: true,
            width: 2,
            colors: ["transparent"],
          },
          xaxis: {
            categories: ["", "", "", "", "", ""],
          },
          fill: {
            opacity: 1,
          },
          tooltip: {
            y: {
              formatter: function (val) {
                return _vm.$gfnCommonUtils.formatPrice(val) + " 건 사용";
              },
              /* title: {
       			 formatter: function (seriesName) {
       			   return ''
       			 }}*/
            },
          },
          colors: ["#d43acb", "#af38c1", "#8837b6", "#f9e000", "#e5d439"],
        },
      },
    };
  },
  mounted() {
    this.$emit("chargeInfoCall");
    this.fnSelectDashBoardInfo();
  },
  methods: {
    fnSelectDashBoardInfo() {
      myInfoApi.getDashBoardInfo().then((response) => {
        var result = response.data;

       
        if (result.success) {
          var totalCnt = 0;

          this.joinDiv = result.data.useChargeData.subsInfo.joinDiv;
          this.prodNm = result.data.useChargeData.subsInfo.prodNm;

          //임시개통, 비즈마켓 개통 상태
          if(result.data.useChargeData == "N"){
            this.smsCharge    = 0;
            this.lmsCharge    = 0;
            this.mmsCharge    = 0;
            this.altCharge    = 0;
            this.totalUseAmt  = 0;
            this.prodAmt      = 0;
            this.mrtBalance   = 0;
            this.totalPixAmt  = 0;
            this.sms.series[0].data = [0, 0, 0];
            this.biz.series[0].data = [0];
            this.smsCntTot = 0;
            this.bizCntTot = 0;
            
            this.monthCategorys = result.data.categorys;
            const channel = result.data.channel;
            channel.forEach((element) => {
              totalCnt += element.data.reduce((a, b) => a + b, 0);
            });
            this.monthTotal = totalCnt;
            this.updateSeriesLine(channel);
            const unitPrice = result.data.unitPrice;
            this.smsUnitPrice = unitPrice.smsUnitPrice;
            this.lmsUnitPrice = unitPrice.lmsUnitPrice;
            this.mmsUnitPrice = unitPrice.mmsUnitPrice;
            this.altUnitPrice = unitPrice.altUnitPrice;
            this.lmsAvailableCnt =0;
          }else{
            const chargeList = result.data.useChargeData ? result.data.useChargeData.list : "";
            if (chargeList) {
              const data = result.data.useChargeData;

              chargeList.forEach((element) => {
                if (element.channelDiv == "SMS") {
                  this.smsCharge = element.useCharge;
                }
                if (element.channelDiv == "LMS") {
                  this.lmsCharge = element.useCharge;
                }
                if (element.channelDiv == "MMS") {
                  this.mmsCharge = element.useCharge;
                }
                if (element.channelDiv == "ALIMTALK") {
                  this.altCharge = element.useCharge;
                }
              });

              this.smsCharge = Math.round((this.smsCharge * 1 + Number.EPSILON) * 100) / 100;
              this.lmsCharge = Math.round((this.lmsCharge * 1 + Number.EPSILON) * 100) / 100;
              this.mmsCharge = Math.round((this.mmsCharge * 1 + Number.EPSILON) * 100) / 100;
              this.altCharge = Math.round((this.altCharge * 1 + Number.EPSILON) * 100) / 100;
              this.totalUseAmt = Math.round((data.chargeInfo.usedAmt * 1 + Number.EPSILON) * 100) / 100;  //사용요금
              this.prodAmt = Math.round((data.subsInfo.prodAmt * 1 + Number.EPSILON) * 100) / 100;
              this.mrtBalance = Math.round((data.chargeInfo.remainingAmt * 1 + Number.EPSILON) * 100) / 100; //요금제 남은 금액
              
              //총금액 : 남은 금액 + 사용한 금액
              this.totalPixAmt =
                Math.round((data.chargeInfo.remainingAmt * 1 + data.chargeInfo.usedAmt * 1 + Number.EPSILON) * 100) / 100;
            }
            const cntList = result.data.useCntData ? result.data.useCntData.list : "";
            if (cntList) {
              let smsCnt = 0;
              let lmsCnt = 0;
              let mmsCnt = 0;
              let altCnt = 0;
              cntList.forEach((element) => {
                if (element.channelDiv == "SMS") {
                  smsCnt = element.useCnt;
                }
                if (element.channelDiv == "LMS") {
                  lmsCnt = element.useCnt;
                }
                if (element.channelDiv == "MMS") {
                  mmsCnt = element.useCnt;
                }
                if (element.channelDiv == "ALIMTALK") {
                  altCnt = element.useCnt;
                }
              });
              this.sms.series[0].data = [smsCnt, lmsCnt, mmsCnt];
              this.biz.series[0].data = [altCnt];

              this.smsCntTot = smsCnt * 1 + lmsCnt * 1 + mmsCnt * 1;
              this.bizCntTot = altCnt * 1;
            }
            this.monthCategorys = result.data.categorys;
            const channel = result.data.channel;
            channel.forEach((element) => {
              totalCnt += element.data.reduce((a, b) => a + b, 0);
            });
            this.monthTotal = totalCnt;
            this.updateSeriesLine(channel);
            const unitPrice = result.data.unitPrice;
            this.smsUnitPrice = unitPrice.smsUnitPrice;
            this.lmsUnitPrice = unitPrice.lmsUnitPrice;
            this.mmsUnitPrice = unitPrice.mmsUnitPrice;
            this.altUnitPrice = unitPrice.altUnitPrice;

            //사용 가능 lms 건수
            if (this.lmsUnitPrice * 1 > 0 && this.mrtBalance * 1 >= this.lmsUnitPrice * 1) {
              this.lmsAvailableCnt = Math.floor((this.mrtBalance * 1) / (this.lmsUnitPrice * 1));
            }
          }
        } else {
          confirm.fnAlert("", result.message, "alert");
        }
      });
    },
    updateSeriesLine(channel) {
      if (this.prodAmt * 1 == 0) {
        this.total.series = [0]; // ( 총 사용금액 / 총 금액(요금제+종량+이월) ) * 100
      } else {
        this.total.series = [Number(((this.totalUseAmt / this.totalPixAmt) * 100).toFixed(1))]; // (  총 사용금액 / 총 금액(요금제+종량+이월) ) * 100
      }
      if (this.smsCntTot > 0) {
        this.$refs.smsCntChart.updateSeries([{ data: this.sms.series[0].data }]);
      }
      if (this.bizCntTot > 0) {
        this.$refs.bizCntChart.updateSeries([{ data: this.biz.series[0].data }]);
      }
      if (this.monthTotal > 0) {
        this.$refs.channelChart.updateOptions({ xaxis: { categories: this.monthCategorys } });
        this.channel.series = channel.map((e) => {
          return { ...e, name: e.name === "SMS" ? "단문메시지(SMS)" : e.name === "LMS" ? "장문메시지(LMS)" : e.name === "MMS" ? "사진메시지(MMS)" : e.name };
        });
      }
      this.$refs.totChgChart.updateOptions({ labels: String(this.$gfnCommonUtils.formatPrice(this.totalUseAmt * 1)) });
    },
  },
};
</script>
