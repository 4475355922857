<template>
  <section class="m_section">
    <!--	s: 	contents-->
    <div class="contents">
      <MobileGnb />
      <div class="tabmenu_wrap">
        <ul class="tabmenu">
          <li class="tabl" @click="$router.push({ path: '/policy/useTerms' })"><span>이용약관</span></li>
          <li class="tabl" @click="$router.push({ path: '/policy/chargeNotice' })">
            <span>요금신고<br />내역</span>
          </li>
          <li class="tabl on">
            <span>개인정보<br />처리방침</span>
          </li>
          <li class="tabl" @click="$router.push({ path: '/policy/spamPolicy' })"><span>스팸정책</span></li>
        </ul>
      </div>
      <div class="tabcont_wrap policy_wrap">
        <div class="policy privacyPolicy">
          <div class="policy_top">
            <h3 class="policy_title">개인정보처리방침</h3>
            <p class="policy_sub">LGU+는 이용자들의 개인정보를 소중히 다루고 관련 법령을 준수하고 있습니다.</p>
            <p class="location">HOME - 개인정보처리방침</p>
          </div>
          <div class="requestWrap">
            <div class="requestTextArea" style="max-height: 650px">
              <div v-html="policyContents"></div>
              <div>
                <div> 개인정보처리방침 버전번호 : {{ policyVersion }}<br> 개인정보처리방침 시행일: {{ applyDtStr }}</div>
                <ul>
                  <li v-for="(item, i) in policyHistory" v-bind:key="i"><a href="javascript:void(0);" @click="privacyPolicyPopup(item.applyDt);" style="text-decoration: underline;">개인정보취급방침 변경일자 : {{ item.applyDtStr }}</a></li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--	e: 	contents	-->
  </section>
</template>

<script>
import MobileGnb from "@/components/MobileGnb.vue";
import policyApi from "../service/policyApi";
import confirm from "@/modules/commonUtil/service/confirm";
export default {
  name: "privacyPolicy",
  components: { MobileGnb },
  data() {
    return {
      policyContents: "",
      applyDtStr: "",
      policyVersion: "",
      policyHistory: []
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {},
  mounted() {
    this.getPrivacyPolicy();
    this.getPrivacyPolicyHistory();
  },
  beforeUpdate() {},
  updated() {},
  beforeUnmount() {},
  unmounted() {},
  methods: {
    getPrivacyPolicy() {
      policyApi.applyPrivacyPolicy(null).then((response) => {
          var result = response.data;
          if (result.success) {
            this.policyContents = result.data.contents;
            this.policyVersion = result.data.policyVersion;
            this.applyDtStr = result.data.applyDtStr;
          }else{
            confirm.fnAlert("개인정보처리방침 오류",result.message,"alert");
          }
        });
    },
    getPrivacyPolicyHistory() {
      policyApi.applyPrivacyPolicyHistory(null).then((response) => {
          var result = response.data;
          if (result.success) {
            this.policyHistory = result.data;
          }else{
            confirm.fnAlert("개인정보처리방침 오류",result.message,"alert");
          }
        });
    },
    privacyPolicyPopup(applyDt) {
      window.open("/popup/privacyPolicyPopup?applyDt="+applyDt,"policyPopup","top=0, left=50, width=1046px, height=650px, resizable=no,status=no");
    }
  },
};
</script>
