<template>
  <Fragment>
    <!--	s: m_section	-->
    <section class="m_section p_including">
      <!-- class="p_including" : preview 포함 -->
      <!--	s: 	contents-->
      <div class="contents">
        <MobileGnb />
        <div class="tabcont_wrap without_tab send_edit talk_wrap">
          <form @submit.prevent="" autocomplete="off">
            <div class="tabcont_flex alimtalk" :style="alimSendData.repSendingYN == 'true' ? 'border-bottom: 1px solid #c9c9c9; padding-bottom: 30px;' : ''">
              <div class="tabcont tabcont02 on">
                <h1 class="title">예약 알림톡 수정</h1>
                <!-- 채널 ID -->
                <div class="box_wrap channel_wrap">
                  <div class="box_top">
                    <h3 class="tit required">채널 아이디</h3>
                    <div class="top_right">
                      <div class="select_wrap">
                        <select v-model="alimSendData.chnlId" class="select" @change="fnGetTemplateList(alimSendData.chnlId)" :disabled="!modiAuth">
                          <option value="null" selected>채널 아이디를 선택하세요.</option>
                          <option :value="chnl.chnlId" v-for="(chnl, index) in sendData.channelIdList" :key="index">{{ chnl.chnlId }}</option>
                          <option value="add" selected>채널 아이디 추가 +</option>
                        </select>
                      </div>
                    </div>
                    <span class="required required_desc">필수 입력</span>
                  </div>
                </div>
                <!-- 템플릿 -->
                <div class="box_wrap channel_wrap">
                  <div class="box_top">
                    <h3 class="tit required">템플릿</h3>
                    <div class="top_right">
                      <div class="template_select">
                        <div class="select_wrap">
                          <select v-model="alimSendData.tmpltKey" class="select" @change="fnTemplateSelected(alimSendData.tmpltKey)" :disabled="!modiAuth">
                            <option value="null">템플릿을 선택하세요.</option>
                            <option :value="tmp.tmpltKey" v-for="(tmp, index) in sendData.tmpList" :key="index">{{ tmp.tmpltNm }}</option>
                            <option value="add">템플릿 추가 +</option>
                          </select>
                        </div>
                        <button class="template_add" @click="ModalOpen('template')" type="button"></button>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- s: 템플릿 선택시 - 수정불가 -->
                <div class="use_template box_wrap" v-if="getTempl" :style="getTempl ? 'display : block' : 'display:hidden'">
                  <!-- 카테고리 -->
                  <div class="box_wrap category_wrap">
                    <div class="box_top">
                      <h3 class="tit required">카테고리</h3>
                      <div class="top_right">
                        <div class="select_wrap">
                          <select name="" id="" class="select" disabled>
                            <option value="">카테고리그룹 선택</option>
                            <option value="" selected>배송 안내</option>
                          </select>
                        </div>
                        <div class="select_wrap">
                          <select name="" id="" class="select" disabled>
                            <option value="">카테고리 선택</option>
                            <option value="" selected>배송 상태</option>
                          </select>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- 보안템플릿여부 -->
                  <div class="box_wrap">
                    <div class="box_top">
                      <h3 class="tit">보안템플릿여부</h3>
                      <div class="top_right">
                        <input type="checkbox" class="chk" disabled :checked="tmplt.securTmpltYn === 'Y'" />
                        <label> 템플릿 내용에 <span class="under pink">인증번호</span>,<span class="under pink">비밀번호 </span>등의 내용이 포함되어 있는 경우 체크해주세요. </label>
                      </div>
                    </div>
                  </div>
                  <!-- 강조유형 -->
                  <div class="box_wrap">
                    <div class="box_top">
                      <h3 class="tit">강조유형</h3>
                      <div class="top_right">
                        <div class="radio_wrap">
                          <ul>
                            <li>
                              <input type="radio" name="emphasis" class="radio" id="emphasis1" onChange="emphasisView();" :checked="tmplt.emphsTpCd === 'NONE'" disabled />
                              <label for="emphasis1">없음</label>
                            </li>
                            <li>
                              <input type="radio" name="emphasis" class="radio" id="emphasis2" onChange="emphasisView();" :checked="tmplt.emphsTpCd === 'TEXT'" disabled />
                              <label for="emphasis2">강조표기형</label>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- 강조표기형 선택시 -->
                  <div class="emphasisCont box_wrap" v-if="tmplt.emphsTpCd === 'TEXT'">
                    <!-- 강조표기 타이틀 -->
                    <div class="box_wrap">
                      <div class="box_top">
                        <h3 class="tit required">강조표기 타이틀</h3>
                        <div class="top_right">
                          <div class="input_wrap">
                            <input type="text" class="input em_tit" :value="tmplt.emphsTitle" disabled />
                          </div>
                        </div>
                      </div>
                    </div>
                    <!-- 강조표기 보조문구 -->
                    <div class="box_wrap">
                      <div class="box_top">
                        <h3 class="tit required">강조표기 보조문구</h3>
                        <div class="top_right">
                          <div class="input_wrap">
                            <input type="text" class="input em_sub" :value="tmplt.emphsSubtle" disabled />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- 템플릿 유형 -->
                  <div class="box_wrap select_templ">
                    <div class="box_top">
                      <h3 class="tit">템플릿유형</h3>
                      <div class="top_right">
                        <div class="select_wrap">
                          <!-- 0623 타입별 추가설명 추가 { -->
                          <div class="select_type_desc">
                            <p class="type01" :style="tmplt.tmpltTpCd === 'AD' ? 'display: block' : 'display:none'">광고성 정보를 포함하여 총 글자수가 1,000자 이내여야 합니다.</p>
                            <p class="type02" :style="tmplt.tmpltTpCd === 'EX' ? 'display: block' : 'display:none'">부가정보를 포함하여 총 글자수가 1,000자가 이내여야 합니다.</p>
                            <p class="type03" :style="tmplt.tmpltTpCd === 'MI' ? 'display: block' : 'display:none'">부가정보, 광고성메시지를 포함하여 총 글자수가 1,000자 이내여야 합니다.</p>
                          </div>
                          <!-- } 0623 타입별 추가설명 추가 -->
                          <select name="" id="" v-model="tmplt.tmpltTpCd" class="select template_type" disabled>
                            <option value="BA">기본형</option>
                            <option value="AD">광고추가형</option>
                            <option value="EX">부가정보형</option>
                            <option value="MI">복합형</option>
                          </select>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- 내용 -->
                  <div class="box_wrap inputbox_wrap">
                    <div class="box_top">
                      <h3 class="tit required">내용</h3>
                    </div>
                    <div class="box_cont inputbox disabled">
                      <div class="input_con">
                        <textarea name="tmpltSbst" id="tmpltSbst" ref="tmpltSbst" cols="30" rows="10" class="input_cont scr_wrap" v-model="tmplt.tmpltSbst" placeholder="내용을 입력하세요." readonly>
                        </textarea>
                      </div>
                      <div class="input_bottom">
                        <div class="count">
                          <span class="byte"
                            ><span class="byte_info">{{ tmpltSbstByte }} </span>/ 1000자</span
                          >
                        </div>
                      </div>
                      <div class="special_wrap">
                        <div class="btn_wrap">
                          <button class="btn white btn_special" @click="specialOn()" type="button" disabled>특수문자</button>
                          <button class="btn white btn_vari" @click="variOn()" type="button" disabled>자동 변경 정보</button>
                        </div>
                        <div class="special_char">
                          <ul class="special_ul">
                            <li><button type="button">☆</button></li>
                            <li><button type="button">★</button></li>
                            <li><button type="button">○</button></li>
                            <li><button type="button">●</button></li>
                            <li><button type="button">◎</button></li>
                            <li><button type="button">◇</button></li>
                            <li><button type="button">◆</button></li>
                            <li><button type="button">☜</button></li>
                            <li><button type="button">☞</button></li>
                            <li><button type="button">□</button></li>
                            <li><button type="button">■</button></li>
                            <li><button type="button">△</button></li>
                            <li><button type="button">▲</button></li>
                            <li><button type="button">▽</button></li>
                            <li><button type="button">▼</button></li>
                            <li><button type="button">♩</button></li>
                            <li><button type="button">♪</button></li>
                            <li><button type="button">♬</button></li>
                            <li><button type="button">→</button></li>
                            <li><button type="button">←</button></li>
                            <li><button type="button">↑</button></li>
                            <li><button type="button">↓</button></li>
                            <li><button type="button">↔</button></li>
                            <li><button type="button">↗</button></li>
                            <li><button type="button">↙</button></li>
                            <li><button type="button">↖</button></li>
                            <li><button type="button">↘</button></li>
                            <li><button type="button">◁</button></li>
                            <li><button type="button">▷</button></li>
                            <li><button type="button">◀</button></li>
                            <li><button type="button">▶</button></li>
                            <li><button type="button">♤</button></li>
                            <li><button type="button">♠</button></li>
                            <li><button type="button">♡</button></li>
                            <li><button type="button">♥</button></li>
                            <li><button type="button">♧</button></li>
                            <li><button type="button">♣</button></li>
                            <li><button type="button">☆</button></li>
                            <li><button type="button">◈</button></li>
                            <li><button type="button">▣</button></li>
                            <li><button type="button">◐</button></li>
                            <li><button type="button">◑</button></li>
                            <li><button type="button">☏</button></li>
                            <li><button type="button">☎</button></li>
                            <li><button type="button">㉿</button></li>
                          </ul>
                        </div>
                        <div class="vari_txt">
                          <div class="vari_li">
                            <ul class="special_ul">
                              <li><button type="button">이름</button></li>
                              <li><button type="button">기타1</button></li>
                              <li><button type="button">기타2</button></li>
                              <li><button type="button">기타3</button></li>
                              <li><button type="button">기타4</button></li>
                            </ul>
                          </div>
                          <div class="vari_desc">
                            <p>알림톡 템플릿 내용에 자동 변경 정보가 있는 경우 문자 메시지 내용에 동일한 자동 변경 정보를 입력해야 정상 발송 됩니다.</p>
                            <!-- <p>사용하고자 하는 자동 변경 정보를 표에서 선택해주세요<br /></p>
                            <p>자동 변경 정보는 메시지 내용 중 특정 구역(A)를 지정하고, 데이터(B)가 입력된 파일을 이용하여 특정 구역(A)에 데이터(B)가 자동으로 삽입되는 기능입니다.</p>
                            <p><span>예)</span>① 문자에 #이름# 삽입 <br />② 엑셀에 ‘이름’열(A열)에 김OO님, 이OO님 입력하여 업로드 <br />③#이름#에 A열의 데이터가 자동으로 삽입되어 전송 됨</p> -->
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- 자동 변경 정보 -->
                  <div class="box_wrap img_wrap" v-if="autoMsg.length > 0" style="margin-top: 15px">
                    <div class="box_top">
                      <h3 class="tit mg10r" style="width: 130px">
                        자동 변경 정보
                        <div class="btn_question tt_btn">
                          <div class="tooltip l_tooltip">
                            <ul>
                              <li><p>자동 변경 정보(변수)로 설정하고자 하는 내용을 #{변수명} 표시로 작성해 주세요.</p></li>
                              <li><p>예) 이름과 출금일 자동 변경 정보(변수) 설정 시,</p></li>
                              <li><p>#{고객명}님 #{출금일} 출금 예정입니다.</p></li>
                            </ul>
                          </div>
                        </div>
                      </h3>
                      <div class="btn1_wrap">
                        <span class="btn white small cpink" v-for="(text, index) in autoMsg" :key="index" style="border: 1px solid #f14b84; margin: 0 10px 5px 0">{{ text | textLengthCut(5) }}</span>
                      </div>
                    </div>
                  </div>

                  <!-- 부가정보 -->
                  <div class="box_wrap inputbox_wrap subinfo_box" :style="tmplt.tmpltTpCd === 'EX' || tmplt.tmpltTpCd === 'MI' ? 'display: block;' : 'display:none;'">
                    <div class="box_top">
                      <h3 class="tit required">부가정보</h3>
                    </div>
                    <div class="box_cont inputbox disabled">
                      <div class="input_con">
                        <textarea name="etcInfo" id="etcInfo" ref="etcInfo" cols="30" rows="10" class="scr_wrap subinfo" v-model="tmplt.etcInfo" disabled></textarea>
                      </div>
                      <div class="input_bottom">
                        <div class="count">
                          <span class="byte">{{ etcInfoByte }} <span class="limit">/ 1000자</span></span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- 광고성 메시지 -->
                  <div class="box_wrap inputbox_wrap admsg_box" :style="tmplt.tmpltTpCd === 'AD' || tmplt.tmpltTpCd === 'MI' ? 'display: block;' : 'display:none;'">
                    <div class="box_top">
                      <h3 class="tit">광고 메시지</h3>
                    </div>
                    <div class="box_cont inputbox disabled">
                      <div class="input_con" style="padding-top: 0; padding-bottom: 0; padding-left: 5;">
                        <input name="adMsg" id="adMsg" ref="adMsg" cols="30" rows="10" class="scr_wrap admsg" v-model="adMsg" disabled/>
                      </div>
                    </div>
                  </div>
                  <!-- 버튼 -->
                  <div class="box_wrap btntype_wrap">
                    <div class="box_top">
                      <h3 class="tit">버튼</h3>
                    </div>
                    <div class="box_cont inputbox">
                      <div class="select_wrap">
                        <select name="" id="" class="select" disabled>
                          <option value="" selected>버튼타입 선택</option>
                          <option v-for="buttonLinkType in buttonLinkTypeList" :key="buttonLinkType.linkType" :value="buttonLinkType.linkType">
                            {{ buttonLinkType.linkTypeName }}
                          </option>
                        </select>
                      </div>
                      <div class="btn2_wrap">
                        <button class="btn plus" type="button" disabled>추가</button>
                        <button class="btn white" type="button" disabled>삭제</button>
                      </div>
                    </div>
                    <div class="btnlist_wrap disabled">
                      <table class="btnlist_table" style="width: 731px">
                        <colgroup>
                          <!-- <col width="40px"> -->
                          <col width="25%" />
                          <col width="20%" />
                          <col width="55%" />
                        </colgroup>
                        <thead>
                          <tr>
                            <!-- <th class="td_chk"><label><input type="checkbox" class="chk_all" disabled /></label></th> -->
                            <th class="td_type">타입</th>
                            <th class="td_name">버튼이름</th>
                            <th class="td_link">링크 주소(URL)</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr v-for="(button, index) in sendData.buttonList" :key="index">
                            <!-- <td class="td_chk">
                              <label><input type="checkbox" class="chk" disabled /></label>
                            </td> -->
                            <td class="td_type">
                              <div class="btn_pre disabled">
                                {{
                                  button.linkTypeName
                                }}
                              </div>

                            <td class="td_name" v-if="button.linkType === 'DS' || button.linkType === 'WL' || button.linkType === 'AL'">
                              <div class="btn_pre disabled">
                                {{
                                  button.name
                                }}
                              </div>
                            </td>

                            <td colspan="2" class="td_name" v-if="button.linkType === 'BK' || button.linkType === 'MD' || button.linkType === 'BC' || button.linkType === 'BT'">
                              <div class="btn_pre disabled">
                                {{
                                  button.name
                                }}
                              </div>
                            </td>

                            <td class="td_link" v-if="button.linkType === 'DS'">[배송조회]</td>

                            <td class="td_link" v-if="button.linkType === 'WL'">
                              <div>
                                <label>모바일: <input type="text" v-model="button.linkMo" disabled /></label>
                              </div>
                              <div>
                                <label>PC: <input type="text" v-model="button.linkPc" disabled /></label>
                              </div>
                            </td>

                            <td class="td_link" v-if="button.linkType === 'AL'">
                              <div>
                                <label>안드로이드: {{ button.linkAnd }}</label>
                              </div>
                              <div>
                                <label>애플(IOS): {{ button.linkIos }}</label>
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
                <!-- e: 템플릿 선택시 -->
                <!-- 수신번호 -->
                <div class="box_wrap receiving_wrap">
                  <div class="box_top">
                    <h3 class="tit required">
                      수신번호
                      <div class="btn_question tt_btn">
                        <div class="tooltip l_tooltip">
                          <ul>
                            <li><p>중복된 수신번호에는 메시지가 전송되지 않습니다.</p></li>
                            <li><p>한 번에 20,000건까지 보낼 수 있습니다.</p></li>
                            <li><p>상위 100건까지만 위 화면에 보여집니다.</p></li>
                          </ul>
                        </div>
                      </div>
                    </h3>
                    <div class="top_right">
                      <div class="select_wrap">
                        <select name="" id="" class="select" v-model="recentSendGroup" @change="fnSelectRecentSendRecvInfo(recentSendGroup)" :disabled="!modiAuth">
                          <option value="null">{{ recentSendGroupList.length > 0 ? "최근 전송 그룹" : "최근 전송 그룹이 없습니다." }}</option>
                          <option v-for="(list, index) in recentSendGroupList" :key="index" :value="list.webReqId">{{ list.sendNm }} ({{ list.reqDt }}) ({{ list.sendCnt | formatComma }}명)</option>
                        </select>
                      </div>
                      <div class="btn2_wrap">
                        <button class="btn addinput" :class="{ disabled: !modiAuth }" type="button" @click="fnDirectAddAddress">직접입력추가</button>
                        <button class="btn" :class="{ disabled: !modiAuth }" @click="ModalOpen('address')" type="button">주소록에서 추가</button>
                        <button class="btn" :class="{ disabled: !modiAuth }" @click="ModalOpen('excelpop')" type="button">파일로 추가</button>
                      </div>
                    </div>
                  </div>
                  <div class="box_cont">
                    <div class="fixed-table-cover">
                      <div class="fixed-table-container" style="width: max-content; min-width: 100%">
                        <div class="fixed-table-header-bg"></div>
                        <div class="fixed-table-wrapper">
                          <table class="fixed-table">
                            <colgroup>
                              <col width="5%" />
                              <col width="47%" v-if="autoMsg.length == 0" />
                              <col width="47%" v-if="autoMsg.length == 0" />
                            </colgroup>
                            <thead>
                              <tr>
                                <th class="td_chk" id="td_chk" style="width: 5%">
                                  <div class="th-text">
                                    <input
                                      type="checkbox"
                                      class="chk"
                                      name="list"
                                      id="chk_all"
                                      v-model="allChecked"
                                      @click="checkedAll($event.target.checked)"
                                      :disabled="recvInfoList.length === 0"
                                    /><label for="chk_all"></label>
                                  </div>
                                </th>
                                <th class="td_phone" id="td_phone" :style="autoMsg.length >= 4 ? (isMobile ? 'width: 173px;' : 'width: 320px;') : 'width: 47%'">
                                  <div class="th-text">휴대폰 번호</div>
                                </th>
                                <th v-if="autoMsg.length == 0" style="width: 47%"></th>
                                <th :class="index === 0 ? 'td_name' : 'td_' + index" v-for="(msg, index) in autoMsg" :key="index" :id="index === 0 ? 'td_name' : `td_${index}`">
                                  <div class="th-text">{{ msg | textLengthCut(5) }}</div>
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr v-for="(data, index) in recvInfoList.slice(0, 100)" :key="index">
                                <td class="td_chk">
                                  <input type="checkbox" class="chk" :id="'chk_' + index" :value="index" v-model="data.selected" @change="selected()" /><label :for="'chk_' + index"></label>
                                </td>
                                <td>
                                  <input
                                    type="text"
                                    v-model="data.phone"
                                    class="input"
                                    :class="data.selfAdd ? 'add' : ''"
                                    placeholder="휴대폰번호"
                                    @input="fnPhoneDash($event, index)"
                                    autocomplete="off"
                                  />
                                </td>
                                <td v-if="autoMsg.length == 0"></td>
                                <td v-for="(msg, index) in autoMsg" :key="index">
                                  <input
                                    type="text"
                                    v-model="data[msg]"
                                    class="input"
                                    :class="data.selfAdd ? 'add' : ''"
                                    :placeholder="autoMsg[index] ? $gfnCommonUtils.textLengthCut(autoMsg[index], 5) : ''"
                                  />
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>

                    <div class="list_info">
                      <div class="total">
                        <p>
                          총 <span class="pink">{{ totalCount | formatComma }} </span>건
                        </p>
                      </div>
                      <div class="btn_wrap">
                        <button type="button" class="btn white small" :class="{ disabled: !modiAuth }" @click="fnAddrSelectedDel()">선택삭제</button>
                        <button type="button" class="btn white small" :class="{ disabled: !modiAuth }" @click="fnAddrAllDel()">전체삭제</button>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- 전송시각 -->
                <ModiSendTimeVue @fnSetSendTime="fnSetSendTime" ref="sendTime" :modiAuth="modiAuth" />
                <!-- 대체문자 -->
                <div class="box_wrap alternative_wrap">
                  <div class="box_top line130">
                    <h3 class="tit">
                      일반문자로 <br />전환
                      <div class="btn_question tt_btn">
                        <div class="tooltip l_tooltip">
                          <p>알림톡으로 보낼 수 없는 경우 일반문자로 전환하여 보냅니다.</p>
                        </div>
                      </div>
                    </h3>
                    <div class="top_right">
                      <div class="radio_wrap">
                        <ul>
                          <li>
                            <input type="radio" name="textUse" class="radio" id="text1" v-model="alimSendData.repSendingYN" value="false" :disabled="!modiAuth" @click="fnRepSendingData('false')" />
                            <label for="text1">전환 안 함</label>
                          </li>
                          <li>
                            <input type="radio" name="textUse" class="radio" id="text2" v-model="alimSendData.repSendingYN" value="true" :disabled="!modiAuth" @click="fnRepSendingData('true')" />
                            <label for="text2">전환</label>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <!--	s: m_preview	-->
              <div class="m_preview_wrap">
                <button type="button" class="btn_close" @click="mPreClose()"></button>
                <div class="m_preview talk_preview">
                  <div class="preview_toggle">
                    <input type="radio" name="preview_type_toggle" id="btn_sent_toggle" @click="mPreEvent(true)" checked />
                    <label for="btn_sent_toggle">미리보기</label>
                    <input type="radio" name="preview_type_toggle" id="btn_history_toggle" @click="mPreEvent(false)" />
                    <label for="btn_history_toggle">최근 작성메시지</label>
                  </div>
                  <div class="preview_top" v-show="preEventToggle">
                    <input type="radio" name="preview_type" id="btn_sent" @click="mPreEvent(true)" checked /><label for="btn_sent"
                      ><span>{{ alimSendData.chnlId !== "null" ? alimSendData.chnlId : "채널아이디" }}</span>
                      <input type="text" placeholder="채널아이디" :value="alimSendData.chnlId !== 'null' ? alimSendData.chnlId : ''"
                    /></label>
                    <!-- <input type="radio" name="preview_type" id="btn_history" @click="mPreEvent(false)" /><label for="btn_history"><span>최근작성 메시지</span></label> -->
                  </div>

                  <!--	s: 알림톡 미리보기	 -->
                  <div class="preview_cont scr_wrap" v-show="preEventToggle">
                    <div class="talk_channel">
                      <div class="img"><img src="../../../assets/images/preview_icon.png" alt="" /></div>
                      <p class="channel_name">{{ alimSendData.chnlId }}</p>
                    </div>
                    <div class="talk_wrap">
                      <div class="talk_top">
                        <span>알림톡 도착</span>
                      </div>
                      <div class="talk_cont">
                        <div class="talk_tit" v-if="tmplt.emphsTpCd === 'TEXT'">
                          <p class="t_sub" :style="tmplt.emphsSubtle ? 'color: rgb(18, 16, 6);' : ''">{{ tmplt.emphsSubtle ? tmplt.emphsSubtle : "강조표기 보조문구 미리보기" }}</p>
                          <p class="t_tit" :style="tmplt.emphsTitle ? 'color: rgb(18, 16, 6);' : ''">{{ tmplt.emphsTitle ? tmplt.emphsTitle : "제목 미리보기" }}</p>
                        </div>
                        <div class="talk_txt">
                          <p class="cont" :style="tmplt.tmpltSbst ? 'color: rgb(18, 16, 6);' : ''" v-html="previewAlimMsg ? previewAlimMsg : '내용 미리보기'"></p>
                          <p
                            class="sub_info"
                            v-if="tmplt.tmpltTpCd === 'EX' || tmplt.tmpltTpCd === 'MI'"
                            :style="tmplt.etcInfo ? 'color: rgb(18, 16, 6);' : ''"
                            v-html="previewEtc ? previewEtc : '부가정보 미리보기'"
                          ></p>
                          <p
                            class="ad_msg"
                            v-if="tmplt.tmpltTpCd === 'AD' || tmplt.tmpltTpCd === 'MI'"
                            :style="adMsg ? 'color: rgb(18, 16, 6);' : ''"
                            v-html="previewAd ? previewAd : '채널 추가하고 이 채널의 마케팅 메시지 등을 카카오톡으로 받기'"
                          ></p>
                        </div>
                        <div class="talk_btn" v-for="(button, index) in sendData.buttonList" :key="index">{{ button.name }}</div>
                      </div>
                    </div>
                  </div>
                  <!--	e: 알림톡 미리보기	 -->
                  <!--	s: 최근전송내역	-->
                  <RecentAlimTalkListVue :preEventToggle="preEventToggle" @recentSendAlimList="fnRecentSendAlimList" :recentSendAlimList="recentSendAlimList" />
                  <!-- 	e: 최근전송내역 	-->
                </div>
              </div>
              <!--	s: m_preview	-->
            </div>
            <div class="tabcont_flex smstalk">
              <!-- 220729 tabcont_flex smstalk 클래스 추가 / 해당 클래스 안에 대체문자 콘텐츠 및 대체문자 미리보기 이동 -->
              <div class="tabcont tabcont02 on">
                <h1 class="title" :style="alimSendData.repSendingYN == 'true' ? 'display: block' : 'display: none'">일반문자 보내기<span class="required required_desc">필수 입력</span></h1>
                <!-- s: 대체문자 사용시 -->
                <div class="use_txt box_wrap" :style="alimSendData.repSendingYN == 'true' ? 'display: block' : 'display: none'">
                  <!-- 내용 -->
                  <div class="box_wrap inputbox_wrap">
                    <div class="box_top">
                      <h3 class="tit required">내용</h3>
                      <div class="chkbox">
                        <input type="checkbox" checked id="ad_sms" class="chk" v-model="ad" :disabled="!modiAuth" />
                        <label for="ad_sms">광고성 문자 </label>
                        <div class="btn_question" @click="ModalOpen('adpop')"></div>
                      </div>
                    </div>
                    <div class="box_cont inputbox">
                      <div class="input_top" v-show="reqProduct !== 'SMS'">
                        <input
                          type="text"
                          class="input_tit"
                          maxlength="15"
                          v-model="title"
                          @input="changeTitle"
                          :placeholder="reqProduct === 'SMS' ? 'SMS인 경우 제목은 전송되지 않습니다.' : '제목을 입력하세요.'"
                          :disabled="!modiAuth"
                        />
                        <span class="input_tit_length">{{ titleByte }} <span class="limit">/ 30Byte</span></span>
                      </div>
                      <div class="input_con">
                        <span class="ad">[Web 발신]</span>
                        <textarea
                          name="sendAlimContent"
                          id="sendAlimContent"
                          ref="sendAlimContent"
                          cols="30"
                          class="input_cont scr_wrap"
                          :readonly="msgReadonly"
                          v-model="msg"
                          @input="changeContent"
                          @keyup="resizeText($event)"
                          @keydown="resizeText($event)"
                          placeholder="내용을 입력하세요. 
※ 90byte를 넘으면 LMS로 자동 전환됩니다.
※ 이미지를 추가하면 MMS로 자동 전환됩니다."
                          style="height: 266px"
                        ></textarea>
                      </div>
                      <div class="input_bottom">
                        <div class="fixed_txt" v-if="ad">
                          <p>{{ adBottom }}</p>
                          <p v-if="authNumber">{{ authNumber }}</p>
                        </div>
                        <div class="count">
                          <span class="type">{{ reqProduct }}</span>
                          <span class="byte"
                            >{{ totalByte }} <span class="limit">/ {{ messageByte | formatComma }}Byte</span></span
                          >
                        </div>
                      </div>
                      <div class="special_wrap">
                        <div class="btn_wrap">
                          <button type="button" class="btn white btn_special" @click="specialOn()" :disabled="!modiAuth">특수문자</button>
                          <button type="button" class="btn white btn_vari on" @click="variOn()" :disabled="!modiAuth">자동 변경 정보</button>
                        </div>
                        <div class="special_char">
                          <ul class="special_ul">
                            <li><button type="button" @click="insertCharacters('☆')">☆</button></li>
                            <li><button type="button" @click="insertCharacters('★')">★</button></li>
                            <li><button type="button" @click="insertCharacters('○')">○</button></li>
                            <li><button type="button" @click="insertCharacters('●')">●</button></li>
                            <li><button type="button" @click="insertCharacters('◎')">◎</button></li>
                            <li><button type="button" @click="insertCharacters('◇')">◇</button></li>
                            <li><button type="button" @click="insertCharacters('◆')">◆</button></li>
                            <li><button type="button" @click="insertCharacters('☜')">☜</button></li>
                            <li><button type="button" @click="insertCharacters('☞')">☞</button></li>
                            <li><button type="button" @click="insertCharacters('□')">□</button></li>
                            <li><button type="button" @click="insertCharacters('■')">■</button></li>
                            <li><button type="button" @click="insertCharacters('△')">△</button></li>
                            <li><button type="button" @click="insertCharacters('▲')">▲</button></li>
                            <li><button type="button" @click="insertCharacters('▽')">▽</button></li>
                            <li><button type="button" @click="insertCharacters('▼')">▼</button></li>
                            <li><button type="button" @click="insertCharacters('♩')">♩</button></li>
                            <li><button type="button" @click="insertCharacters('♪')">♪</button></li>
                            <li><button type="button" @click="insertCharacters('♬')">♬</button></li>
                            <li><button type="button" @click="insertCharacters('→')">→</button></li>
                            <li><button type="button" @click="insertCharacters('←')">←</button></li>
                            <li><button type="button" @click="insertCharacters('↑')">↑</button></li>
                            <li><button type="button" @click="insertCharacters('↓')">↓</button></li>
                            <li><button type="button" @click="insertCharacters('↔')">↔</button></li>
                            <li><button type="button" @click="insertCharacters('↗')">↗</button></li>
                            <li><button type="button" @click="insertCharacters('↙')">↙</button></li>
                            <li><button type="button" @click="insertCharacters('↖')">↖</button></li>
                            <li><button type="button" @click="insertCharacters('↘')">↘</button></li>
                            <li><button type="button" @click="insertCharacters('◁')">◁</button></li>
                            <li><button type="button" @click="insertCharacters('▷')">▷</button></li>
                            <li><button type="button" @click="insertCharacters('◀')">◀</button></li>
                            <li><button type="button" @click="insertCharacters('▶')">▶</button></li>
                            <li><button type="button" @click="insertCharacters('♤')">♤</button></li>
                            <li><button type="button" @click="insertCharacters('♠')">♠</button></li>
                            <li><button type="button" @click="insertCharacters('♡')">♡</button></li>
                            <li><button type="button" @click="insertCharacters('♥')">♥</button></li>
                            <li><button type="button" @click="insertCharacters('♧')">♧</button></li>
                            <li><button type="button" @click="insertCharacters('♣')">♣</button></li>
                            <li><button type="button" @click="insertCharacters('☆')">☆</button></li>
                            <li><button type="button" @click="insertCharacters('◈')">◈</button></li>
                            <li><button type="button" @click="insertCharacters('▣')">▣</button></li>
                            <li><button type="button" @click="insertCharacters('◐')">◐</button></li>
                            <li><button type="button" @click="insertCharacters('◑')">◑</button></li>
                            <li><button type="button" @click="insertCharacters('☏')">☏</button></li>
                            <li><button type="button" @click="insertCharacters('☎')">☎</button></li>
                            <li><button type="button" @click="insertCharacters('㉿')">㉿</button></li>
                          </ul>
                        </div>
                        <div class="vari_txt on">
                          <div class="vari_desc">
                            <p>※ 알림톡 템플릿 내용에 자동 변경 정보가 있는 경우 문자 메시지 내용에 동일한 자동 변경 정보를 입력해야 정상 발송 됩니다.</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <!-- 자동 변경 정보 -->
                  <div class="box_wrap img_wrap" v-if="autoMsg.length > 0" style="margin-top: 15px">
                    <div class="box_top">
                      <h3 class="tit mg10r" style="width: 130px">
                        자동 변경 정보
                        <div class="btn_question tt_btn">
                          <div class="tooltip l_tooltip">
                            <ul>
                              <li><p>자동 변경 정보(변수)로 설정하고자 하는 내용을 #{변수명} 표시로 작성해 주세요.</p></li>
                              <li><p>예) 이름과 출금일 자동 변경 정보(변수) 설정 시,</p></li>
                              <li><p>#{고객명}님 #{출금일} 출금 예정입니다.</p></li>
                            </ul>
                          </div>
                        </div>
                      </h3>
                      <div class="btn1_wrap">
                        <span class="btn white small cpink" v-for="(text, index) in autoMsg" :key="index" style="border: 1px solid #f14b84; margin: 0 10px 5px 0">{{ text | textLengthCut(5) }}</span>
                      </div>
                    </div>
                  </div>

                  <!-- 이미지 -->
                  <div class="box_wrap img_wrap">
                    <div class="box_top">
                      <h3 class="tit">
                        이미지 첨부
                        <div class="btn_question tt_btn">
                          <div class="tooltip l_tooltip">
                            <ul>
                              <li><p>최대 크기 : 1500(가로)*1440(세로)px 이하 전체 합계 300KB이하</p></li>
                              <li><p>지원 파일 형식과 장수 :jpg 파일, 최대 3장</p></li>
                            </ul>
                          </div>
                        </div>
                      </h3>
                      <div class="top_right">
                        <div class="btn1_wrap">
                          <button class="btn plus" :class="{ disabled: !modiAuth }" @click="ModalOpen('img_pop')" type="button">이미지 추가</button>
                        </div>
                      </div>
                    </div>
                    <div class="box_cont imgbox" v-if="fileList.length > 0">
                      <ul>
                        <li v-for="(image, index) in fileList" :key="index">
                          <span class="num">{{ index + 1 }}</span>
                          <p class="name">{{ image.name | fileTextLengthCut(6, "…") }}</p>
                          <button type="button" class="btn_close" @click="handleRemove(index)"></button>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <!-- 발신번호 -->
                  <div class="box_wrap sentbox_wrap">
                    <div class="box_top">
                      <h3 class="tit required">발신번호</h3>
                      <div class="top_right">
                        <div class="select_wrap">
                          <select name="" id="" class="select" v-model="callbackNumber" :disabled="!modiAuth">
                            <option value="null">번호를 선택하세요</option>
                            <option :value="list.sndrno" v-for="(list, index) in sendNumberList" :key="index">
                              {{ list.sndrno }} {{ list.sndrnoNm }} {{ list.blckYn === "Y" ? ` (차단번호)` : "" }}
                            </option>
                          </select>
                        </div>
                        <!-- <div class="btn1_wrap">
                        <a href="" class="btn white plus">발신번호 추가</a>
                      </div> -->
                      </div>
                    </div>
                  </div>
                </div>
                <!-- e: 대체문자 사용시 -->
              </div>

              <!--	s: m_preview	-->
              <div class="m_preview_wrap" v-show="alimSendData.repSendingYN == 'true'">
                <button type="button" class="btn_close" @click="mPreClose()"></button>
                <div class="m_preview">
                  <div class="preview_toggle">
                    <input type="radio" name="preview_type_toggle_sms" id="btn_sent_toggle_sms" @click="mPreSmsEvent(true)" checked />
                    <label for="btn_sent_toggle_sms">미리보기</label>
                    <input type="radio" name="preview_type_toggle_sms" id="btn_history_toggle_sms" @click="mPreSmsEvent(false)" />
                    <label for="btn_history_toggle_sms">최근 작성메시지</label>
                  </div>
                  <div class="preview_top" v-show="preEventSmsToggle">
                    <input type="radio" name="preview_type" id="btn_sent" @click="mPreEvent(true)" checked /><label for="btn_sent"
                      ><span>{{ callbackNumber !== "null" ? callbackNumber : "발신번호" }}</span>
                      <input type="text" placeholder="발신번호" :value="callbackNumber !== 'null' ? callbackNumber : ''" readonly
                    /></label>
                    <!-- <input type="radio" name="preview_type" id="btn_history" @click="mPreEvent(false)" /><label for="btn_history"><span>최근작성 메시지</span></label> -->
                  </div>

                  <!--	s: 알림톡 미리보기	 -->
                  <div class="preview_cont" v-show="preEventSmsToggle">
                    <swiper class="pcont_top" ref="previewSwiper" v-show="fileList.length > 0" :options="swiperOption">
                      <swiper-slide class="pcont_img" v-for="(image, index) in fileList" :key="index"><img :src="image.src" class="img-fluid" /></swiper-slide>
                    </swiper>
                    <div class="swiper-pagination" slot="pagination" v-show="fileList.length > 0"></div>

                    <div class="pcont_bottom" :class="fileList.length === 0 ? 'sms' : ''">
                      <div class="pcont_tit" v-show="reqProduct !== 'SMS'">
                        <p class="output_tit" :style="title ? 'color:#121006' : ''" v-html="previewTitle ? previewTitle : '제목 미리보기'"></p>
                      </div>
                      <div class="pcont_txt scr_wrap">
                        <p class="name" style="color:#121006">[Web 발신]</p>
                        <p class="cont" :style="msg ? 'color:#121006' : ''" v-html="previewMsg ? previewMsg : '내용 미리보기'"></p>
                        <div class="fixed_txt" v-if="ad">
                          <p>{{ adBottom }}</p>
                          <p v-if="authNumber">{{ authNumber }}</p>
                        </div>
                      </div>
                    </div>
                    <div class="pcont_info">
                      <span class="type">{{ reqProduct }}</span>
                      <span class="date">{{ today }}</span>
                    </div>
                  </div>
                  <!--	e: 알림톡 미리보기	 -->
                  <!--	s: 최근전송내역	-->
                  <RecentMessageListVue :preEventToggle="preEventSmsToggle" @recentSendMessageList="fnRecentSendMessageList" :recentSendMessageList="recentSendMessageList" />
                  <!-- 	e: 최근전송내역 	-->
                </div>
              </div>
              <!--	s: m_preview	-->
            </div>

            <div class="btn_wrap" v-if="modiAuth">
              <button class="btn large_pink" type="button" @click="fnMessageSend()">수정</button>
              <button class="btn large_white" @click="fnGoList" type="button">취소</button>
            </div>
            <div style="display: flex; justify-content: center" v-if="!modiAuth">
              <button class="btn large_white" @click="$router.push({ path: '/sendManage/rsvSendMessage' })" type="button">목록</button>
            </div>
          </form>
        </div>
      </div>
      <!--	e: 	contents	-->
    </section>
    <!--	e: m_section	-->

    <!--	s: m_btn_wrap -모바일버전에서 버튼	-->
    <div class="m_btn_wrap">
      <button type="button" class="m_preview_btn" @click="mPreAlimOn()">미리보기</button>
      <button type="button" class="m_aside_btn" @click="mHisAlimOn()">최근<br />작성메시지</button>
    </div>

    <!-- s: 모바일 미리보기 -->
    <MobilePreviewAlimVue
      :tmplt="tmplt"
      :fileList="fileList"
      :title="title"
      :ad="ad"
      :msg="msg"
      :adBottom="adBottom"
      :authNumber="authNumber"
      :reqProduct="reqProduct"
      :today="today"
      :alimSendData="alimSendData"
      :callbackNumber="callbackNumber"
      :recentSendMessageList="recentSendMessageList"
      :recentSendAlimList="recentSendAlimList"
      @recentSendMessageList="fnRecentSendMessageList"
      @recentSendAlimList="fnRecentSendAlimList"
      :adMsg="adMsg"
    />
    <!-- e: 모바일 미리보기 -->

    <!--  s: popup  -->
    <!-- <div class="dimmed" @click="ModalClose()"></div> -->
    <div class="popup-wrap">
      <TempletePreview @alimTemplate="fnAlimTemplate" :tmpltParams="tmpltParams" />
      <SendAddress ref="sendAddr" @sendAddrInfoSet="fnSendAddrInfoSet" /><!-- 주소록 불러오기 -->
      <ExcelFileUpload @excelAddrinfoSet="fnExcelAddrinfoSet" :autoMsg="autoMsg" /><!-- 파일불러오기(엑셀파일로 일괄 등록 팝업) -->
      <SendConfirm
        ref="sendConfirm"
        :rsvId="rsvId"
        :ad="ad"
        :msg="msg"
        :title="title"
        :adBottom="adBottom"
        :authNumber="authNumber"
        :recvInfoList="recvInfoList"
        :fileList="fileList"
        :callbackNumber="callbackNumber"
        :reqProduct="reqProduct"
        :sendTimeData="sendTimeData"
        :alimSendData="alimSendData"
        :tmplt="tmplt"
        :rejectPhoneList="rejectPhoneList"
        :autoMsg="autoMsg"
        :adByte="adByte"
        @fnSuccess="fnSuccess"
      />
      <!-- s: 광고성 문자 전송시 표기 의무 안내-->
      <AdMessagePopupVue />
      <!-- e: 광고성 문자 전송시 표기 의무 안내-->
      <!-- 이미지 등록 팝업 s-->
      <div class="popup tit img_pop">
        <div class="pop-head">
          <h3 class="pop-tit">이미지 첨부</h3>
        </div>
        <div class="pop-cont">
          <div class="imgpop_top">
            <div class="left_wrap">
              <ul v-if="imageAddTitle == ''">
                <li><p>최대 크기 : 1500(가로)*1440(세로)px 이하 전체 합계 500KB이하</p></li>
                <li><p>지원 파일 형식과 장수: jpg 파일, 최대 1장</p></li>
              </ul>
              <ul v-else>
                <li>
                  <p class="pink">{{ imageAddTitle }}</p>
                </li>
              </ul>
            </div>

            <div class="btn plus" :class="updateFileList.length >= 1 ? 'disabled' : ''">
              이미지 추가
              <input type="file" accept=".jpg, .jpeg" @change="onFileChange" class="form-control-file" id="my-file" ref="imageUploader" />
            </div>
          </div>
          <div class="img_list_wrap" v-if="updateFileList.length > 0">
            <div class="img_list" v-for="(item, index) in updateFileList" :key="index">
              <p class="file_name">{{ item.name | fileTextLengthCut(8, "…") }}</p>
              <button class="btn_del" @click="popupHandleRemove(index)"></button>
            </div>
          </div>
          <div class="img_list_wrap empty" v-else>
            <p>등록된 이미지가 없습니다.</p>
          </div>
        </div>
        <div class="pop-btn1">
          <button type="button" class="btn large_pink" @click="fnSendData()">확인</button>
          <button type="button" class="btn large_white" @click="fileModalClose()">취소</button>
        </div>
      </div>
      <!-- 이미지 등록 팝업 e-->
    </div>
    <!--  e: popup  -->
  </Fragment>
</template>
<script>
import { Fragment } from "vue-frag";
import store from "@/store";
import confirm from "@/modules/commonUtil/service/confirm";
import { eventBus } from "@/modules/commonUtil/service/eventBus";
import messageSendApi from "@/modules/messageSend/service/messageSendApi";
import { swiper, swiperSlide } from "vue-awesome-swiper";
import RecentAlimTalkListVue from "@/modules/messageSend/components/RecentAlimTalkList.vue";
import RecentMessageListVue from "@/modules/messageSend/components/RecentMessageList.vue";
import MobilePreviewAlimVue from "@/modules/messageSend/components/MobilePreviewAlim.vue";
import ToastComponentVue from "@/components/ToastComponent.vue";
import ModiSendTimeVue from "@/modules/sendManage/components/ModiSendTime.vue";
import templateManageApi from "@/modules/templeteManage/service/templeteManageApi.js";
import AdMessagePopupVue from "@/modules/messageSend/components/AdMessagePopup.vue";
import MobileGnb from "@/components/MobileGnb.vue";
import sendManageApi from "@/modules/sendManage/service/sendManageApi";

//팝업
import TempletePreview from "@/modules/messageSend/components/TempletePreview.vue";
import SendAddress from "../components/SendAddress.vue";
import ExcelFileUpload from "@/modules/messageSend/components/ExcelFileUpload.vue";
import SendConfirm from "../components/SendConfirm.vue";

export default {
  name: "defaultName",
  components: {
    SendAddress,
    ExcelFileUpload,
    swiper,
    swiperSlide,
    TempletePreview,
    SendConfirm,
    RecentAlimTalkListVue,
    ModiSendTimeVue,
    Fragment,
    MobileGnb,
    AdMessagePopupVue,
    RecentMessageListVue,
    MobilePreviewAlimVue,
  },
  data() {
    return {
      selectPos: 0, // insertCharacters 초기 값
      loginDiv: false,
      userDiv: false,
      modiAuth: true, // 수정 가능 여부
      init: true,
      rsvId: "",
      msg: "",
      msgReadonly: false,
      title: "",
      adBottom: "", //this.$store.getters["getHubEzData"].reject080Number ? "무료거부 " + this.$store.getters["getHubEzData"].reject080Number : "", // 무료 거부 번호
      autCd: "",
      authNumber: "", // 인증코드
      fileList: [],
      updateFileList: [], // 업로드한 이미지 파일
      callbackNumber: "null", // 선택된 발신번호
      recvInfoList: [],
      sendTimeData: {}, // 전송시각 오브젝트
      preRecvInfoList: [], // 미리전송 주소록 리스트
      recentSendGroup: "null", // 선택된 수신번호 그룹
      recentSendGroupList: [], // 조회된 최신전송그룹 리스트
      sendNumberList: [], // 발신번호
      rejectPhoneList: [], // 080 수신거부 리스트
      preEventToggle: true,
      preEventSmsToggle: true,
      today: "",
      titleByte: "0",
      textByte: "0",
      ad: false,
      adByte: 6,
      reqProductChange: true,
      totalImagefileSize: 0,
      allChecked: false, // 전체 체크박스 값
      hashNum: new Map(), // 연락처 중복 처리 변수 값 (errorCheck 할 경우 function 마다 초기화 진행)
      validator: false,
      tmpltSbstByte: 0,
      etcInfoByte: 0,
      // adMsgByte: 0,
      adMsg: "채널 추가하고 이 채널의 마케팅 메시지 등을 카카오톡으로 받기", // 광고성 메시지
      tmplt: {}, // 템플릿 리스트
      tmpltParams: {},
      getTempl: false,
      sendLimit: true,
      recentSendAlimList: [], // 최근전송된 알림톡 리스트
      recentSendMessageList: [], // 최근전송된 문자메시지 리스트

      // 알림톡 전송 Data
      alimSendData: {
        sndrprofKey: "null", // 채널ID
        tmpltKey: "null", // 선택한 템플릿키
        repSendingYN: "false", // 대체문자 사용여부
        chnlId: "null",
        tmpltName: "",
      },

      //========================================= 위 내용까지 데이터 전송 확인

      // end
      searchData: {
        useCh: "ALIMTALK",
        senderKeyType: "S",
        senderKey: "",
        searchCondi: "tmpltName",
        searchText: "",
        searchTmpltStatCodes: ["A"],
        pageNo: 1,
        listSize: 999999999,
      },

      sendData: {
        chGrp: "KKO",
        ch: "ALIMTALK",
        requiredCuid: false,
        requiredCuPhone: true,
        msgKind: "I", //알림톡은 광고성을 보내지 않는다.
        rplcSendType: "NONE", //NONE, SMS, LMS, MMS
        cuInputType: "", //DICT, ADDR, EXCEL
        rsrvSendYn: "N", //예약전송여부
        rsrvDate: this.$gfnCommonUtils.getCurretDate(),
        rsrvHH: "00",
        rsrvMM: "00",
        campaignId: "",
        cuInfo: "",
        senderKeyType: "",
        senderKey: "",
        tmpltCode: "",
        emphasizeType: "",
        tmpltEmpsTitle: "",
        tmpltEmpsSubTitle: "",
        tmpltContent: "",
        contsVarNms: [], //메세지 내용 변수명
        buttonList: [],
        fbInfo: {}, //대체전송정보
        tmpList: [], // 템플릿리스트
        channelIdList: [], // 카카오 채널 리스트
        excelLimitRow: 0,
      },
      //senderKeyList: [], // 카카오톡 샌더키 Result값
      // 미리보기 화면 swiper
      imageAddTitle: "",
      swiperOption: {
        slidesPerView: "auto",
        spaceBetween: 6, // swiper-slide 사이의 간격 지정
        slidesOffsetBefore: 0, // slidesOffsetBefore는 첫번째 슬라이드의 시작점에 대한 변경할 때 사용
        slidesOffsetAfter: 0, // slidesOffsetAfter는 마지막 슬라이드 시작점 + 마지막 슬라이드 너비에 해당하는 위치의 변경이 필요할 때 사용
        //freeMode: true, // freeMode를 사용시 스크롤하는 느낌으로 구현 가능
        centerInsufficientSlides: true, // 컨텐츠의 수량에 따라 중앙정렬 여부를 결정함
        observer: true,
        observeParents: true,
        pagination: {
          el: ".swiper-pagination",
          clickable: true,
          renderBullet: function (index, className) {
            return '<span class="' + className + '">' + (index + 1) + "</span>";
          },
        },
      },
      buttonLinkTypeList: [
        { linkType: "DS", linkTypeName: "배송 조회", name: "버튼명 입력" },
        { linkType: "WL", linkTypeName: "웹 링크", name: "버튼명 입력", linkMo: "", linkPc: "" },
        { linkType: "AL", linkTypeName: "앱 링크", name: "버튼명 입력", linkAnd: "", linkIos: "" },
        { linkType: "BK", linkTypeName: "봇 키워드", name: "버튼명 입력" },
        { linkType: "MD", linkTypeName: "메시지 전달", name: "버튼명 입력" },
        { linkType: "BC", linkTypeName: "상담톡 전환", name: "버튼명 입력" },
        { linkType: "BT", linkTypeName: "봇 전환", name: "버튼명 입력" },
      ],
      autoMsg: [],
      impMsg: "", // 임시 메시지 저장
      enterByte: 2,
    };
  },
  computed: {
    // 미리보기 화면 줆바꿈 띄어쓰기 가능하도록 변경
    previewTitle() {
      return this.title ? this.title.split(" ").join("&nbsp;").split("\n").join("<br/>") : "";
    },
    previewMsg() {
      return this.msg ? this.msg.split(" ").join("&nbsp;").split("\n").join("<br/>") : "";
    },
    previewAlimMsg() {
      return this.tmplt.tmpltSbst ? this.tmplt.tmpltSbst.split(" ").join("&nbsp;").split("\n").join("<br/>") : "";
    },
    previewEtc() {
      return this.tmplt.etcInfo ? this.tmplt.etcInfo.split(" ").join("&nbsp;").split("\n").join("<br/>") : "";
    },
    previewAd() {
      return this.adMsg ? this.adMsg.split(" ").join("&nbsp;").split("\n").join("<br/>") : "";
    },
    totalByte() {
      return parseInt(this.textByte) + (this.ad ? parseInt(this.adByte) : 0);
    },
    // 문자 유형 (문자 : SMS, LMS, MMS, 글로벌SMS : SMS_GLOBAL, RCS : RCS, 카카오톡 : KKO_NOTI (알림톡), KKO_FRIEND (친구톡))
    reqProduct() {
      return this.fileList.length > 0 ? "MMS" : this.totalByte > 90 && this.reqProductChange !== true ? "LMS" : "SMS";
    },
    messageByte() {
      return this.fileList.length > 0 ? "2000" : this.totalByte > 90 ? "2000" : "90";
    },
    totalCount() {
      return (
        this.recvInfoList.length -
        this.recvInfoList.filter((e) => {
          return e.selfAdd === true && e.phone === "";
        }).length
      );
    },
    totalFileSize: function () {
      let updateSize = 0;
      this.updateFileList.forEach((e) => {
        updateSize += e.size;
      });
      updateSize;
      return updateSize;
    },
    isMobile: function () {
      return store.getters["getIsMobile"];
    },
  },
  watch: {
    isMobile(value) {
      this.mobile = value;
    },
    totalByte: function (getTotalByte) {
      if (getTotalByte > 90 && this.reqProductChange && this.reqProduct !== "MMS") {
        eventBus.$on("callbackCancleEventBus", this.fnMessageContentCut);
        eventBus.$on("callbackEventBus", this.fnReqProductChange);
        // confirm.fnConfirm("90byte 초과", "장문메시지(LMS)로 전환하시겠습니까?", "msgChange", "!");
        this.msgReadonly = true;
        confirm.fnConfirm("", "장문메시지(LMS)로 전환하시겠습니까?", "msgChange");
      } else if (getTotalByte > 2000) {
        if (this.ad) {
          confirm.fnAlert(
            "",
            "광고 메시지를 체크할 경우, [web 발신]과 (광고),<br/>080수신거부번호가 자동 추가되어 내용이 2,000byte를 넘었습니다. <br/>2000byte에 맞게 내용을 수정해 주세요.",
            "message"
          );
        } else {
          confirm.fnAlert("", "내용이 2,000byte를 넘었습니다. <br/>2000byte에 맞게 내용을 수정해 주세요.", "message");
        }
        this.fnMessageContentCut();
      } else if (getTotalByte <= 90) {
        this.reqProductChange = true;
      }
    },
    reqProduct: function (messageType) {
      if (this.init) {
        this.init = false;
        return;
      }
      const msgType = messageType === "SMS" ? "단문메시지(SMS)" : messageType === "LMS" ? "장문메시지(LMS)" : "사진메시지(MMS)";
      this.fnToastMessage(msgType + "로 변경되었습니다.");
    },
    ad: function(){
      if(this.ad){
        this.msg = "(광고)"+this.msg;
        this.textByte = this.$gfnCommonUtils.getByte(this.msg)+2;
      }else{
        this.msg = this.msg.replace("(광고)", "");
        this.textByte = this.$gfnCommonUtils.getByte(this.msg);
      }
    },
    /* msg: function (newMsg) {
      // 작성하는 텍스트를 자동 변수로 인식하는 기능 추가
      const pattern = /(?<=#{)(.*?)(?=\})/g; // 특정 문자 사이 추출 (특정문자 제외)
      //const pattern2 = /((#{).*?(\}))/g; // 특정 문자 사이 추출 (특정문자 포함)
      let autoMsgList = [];
      let sumMsg = this.tmplt.tmpltSbst + " " + newMsg;
      if (!this.$gfnCommonUtils.isEmpty(sumMsg.match(pattern))) {
        autoMsgList = sumMsg.match(pattern).filter((msg) => {
          return !this.$gfnCommonUtils.isEmpty(msg);
        });

        // 중복 제거
        this.autoMsg = autoMsgList.filter((e, index) => {
          return autoMsgList.indexOf(e) === index;
        });
      } else {
        this.autoMsg = [];
      }
    }, */
    tmplt: {
      handler(newTmplt) {
        // 작성하는 텍스트를 자동 변수로 인식하는 기능 추가
        const pattern = /#\{([^#{}]*)\}/g; // 특정 문자 사이 추출 (특정문자 제외)
        //const pattern2 = /((#{).*?(\}))/g; // 특정 문자 사이 추출 (특정문자 포함)
        let autoMsgList = [];
        if (!this.$gfnCommonUtils.isEmpty(newTmplt.tmpltSbst.match(pattern))) {
          const matches = newTmplt.tmpltSbst.match(pattern);
          autoMsgList = matches.map(match => match.substring(2, match.length - 1)).filter((msg) => {
            return !this.$gfnCommonUtils.isEmpty(msg);
          });

          // 중복 제거
          this.autoMsg = autoMsgList.filter((e, index) => {
            return autoMsgList.indexOf(e) === index;
          });

          this.tableWidthCalc();
        } else {
          this.autoMsg = [];
        }
      },
      deep: true,
    },
  },
  created() {
    this.fnCreated();
  },
  mounted() {
    this.fnRejectPhoneList();
    //this.fnSelectSenderKeyList();
    this.getToday();
    this.fnSelectChannelId();
    this.fnSelectRecentSendGroup();
    this.fnCallerIdList();
    this.fnSelectrecentSendAlimList();
    this.fnSelectRecentSendMessageList();
    this.recvInfoList = [
      {
        phone: "",
        /* name: "",
        var1: "",
        var2: "",
        var3: "",
        var4: "", */
        status: "",
        validator: false,
        selected: false,
        selfAdd: true,
      },
    ];
  },
  methods: {
    fnCreated() {
      const isAuth = store.getters["login/isAuth"];
      if (isAuth) {
        this.loginDiv = true;
        messageSendApi.selectAutInfoChnnel().then((response) => {
          var result = response.data;
          if (result.success) {
            this.autCd = result.data.autCd;
            this.authNumber = result.data.authcd080 ? "인증코드 " + result.data.authcd080 : "인증코드 없음";
            this.adBottom = this.$store.getters["getHubEzData"].reject080Number ? "무료거부 " + this.$store.getters["getHubEzData"].reject080Number : ""; // 무료 거부 번호
            this.subSttusCd = result.data.subSttusCd;
            if (this.subSttusCd === "02") {
              this.userDiv = true;
            }
          }
        });
      } else {
        eventBus.$on("callbackEventBus", () => {
          this.$router.push({ path: "/sendManage/rsvSendMessage" });
        });
        confirm.fnConfirm("", "로그인 후 이용 가능합니다.", "cbAlert");
      }
    },
    fnSelectRsvDetailInfo(rsvId) {
      this.rsvId = rsvId;
      let param = {
        rsvId: rsvId,
      };
      sendManageApi.selectRsvMsgDetail(param).then((response) => {
        const result = response.data;
        if (result.success) {
          var rsvDt = new Date(result.data.rsvMessageInfo.realRsvTm);
          var today = new Date();
          var diffTime = Math.round((rsvDt.getTime() - today.getTime()) / (1000 * 60));

          if (diffTime <= 5) {
            this.modiAuth = false;
          }

          this.alimSendData.chnlId = result.data.rsvMessageDetail.chnlId;
          this.alimSendData.tmpltKey = result.data.rsvMessageDetail.kakaoTemplateKey;
          this.fnGetTemplateList(result.data.rsvMessageDetail.chnlId, result.data.rsvMessageDetail.kakaoTemplateKey);
          this.callbackNumber = result.data.rsvMessageDetail.callbackNumber ? this.$gfnCommonUtils.hpNumberAddDash(result.data.rsvMessageDetail.callbackNumber) : "null";
          this.alimSendData.repSendingYN = result.data.rsvMessageDetail.fbMsg ? "true" : "false";
          if (this.alimSendData.repSendingYN === "true") {
            if(this.ad){
              if(result.data.rsvMessageDetail.ad == "true"){
                this.msg = result.data.rsvMessageDetail.content ? result.data.rsvMessageDetail.content.replaceAll("\r\n", "\n") : "";
              }else{
                this.msg = result.data.rsvMessageDetail.content ? "(광고)"+result.data.rsvMessageDetail.content.replaceAll("\r\n", "\n") : "";
                this.ad = false;
              }
            }else{
              if(result.data.rsvMessageDetail.ad == "true"){
                this.msg = result.data.rsvMessageDetail.content ? result.data.rsvMessageDetail.content.replace("(광고)", "").replaceAll("\r\n", "\n") : "";
                this.ad = true;
              }else{
                this.msg = result.data.rsvMessageDetail.content ? result.data.rsvMessageDetail.content.replaceAll("\r\n", "\n") : "";
              }
            } 
          } else {
            this.ad = false;
          }

          this.fnReqProductChange();
          this.modiChangeTitle(result.data.rsvMessageDetail.fbTitle);
          this.modiChangeContent(this.msg);
          if (this.ad) {
            this.getAdByte();
          }
          this.fileList = result.data.fileList.map((e) => {
            return {
              ...e,
              src: `/api/v1/fo/preview/${e.src}`,
              fileName: e.src,
            };
          });
          if (result.data.rsvMessageInfo.rptYn == "Y") {
            this.$refs.sendTime.fnInit("P", result.data.rsvMessageInfo, result.data.rsvMessageDetail);
          } else {
            this.$refs.sendTime.fnInit("R", result.data.rsvMessageInfo, result.data.rsvMessageDetail);
          }

          this.recvInfoList = result.data.sendRsvMessageTgtList.map((e) => {
            return {
              ...e,
              phone: this.$gfnCommonUtils.hpNumberAddDash(e.phone),
              status: "",
              validator: true,
              selected: false,
              ...JSON.parse(e.var5),
            };
          });
        } else {
          confirm.fnAlert("", "오류가 발생하였습니다.", "alert");
        }
      });
    },
    //준비중입니다.
    fnReady() {
      // confirm.fnAlert("알림", "준비중입니다.", "alert");
      confirm.fnAlert("", "준비중입니다.", "alert");
    },
    //카카오톡 발신 프로필키 리스트 조회
    /* fnSelectSenderKeyList() {
      const params = { kkoSvc: this.searchData.useCh, senderKeyType: this.searchData.senderKeyType };
      messageSendApi.selectSenderKeyList(params).then((response) => {
        const result = response.data;
        if (result.success) {
          this.senderKeyList = Object.assign([], result.data);
        } else {
          confirm.fnAlert("채널ID 가져오기가", result.message);
        }
      });
    }, */

    async fnSelectChannelId() {
      const params = {
        userSeq: "",
        dormYn: "N",
        blckYn: "N",
      };

      await messageSendApi
        .selectChannelId(params)
        .then((response) => {
          if (response.data.success) {
            this.sendData.channelIdList = response.data.data;
          } else {
            // confirm.fnAlert("장애", "채널ID 가저오기가 실패하였습니다.", "alert");
            confirm.fnAlert("", "채널ID 가저오기가 실패하였습니다.", "alert");
          }
          this.fnSelectRsvDetailInfo(this.$router.history.current.params.rsvId);
        })
        .catch((error) => {
          // confirm.fnAlert("장애", "채널ID 가저오기가 실패하였습니다. <br/>" + error, "alert");
          confirm.fnAlert("", "채널ID 가저오기가 실패하였습니다. <br/>" + error, "alert");
        });

      // 예약 알림톡 수정 파일 가져오기
    },
    //체크박스 전체 선택 기능
    checkedAll(checked) {
      if (this.recvInfoList.length > 0) {
        this.allChecked = checked;
        for (let i in this.recvInfoList.slice(0, 100)) {
          this.recvInfoList[i].selected = this.allChecked;
        }
      }
    },
    //체크박스 단일 체크
    selected() {
      if (this.recvInfoList.every((item) => item.selected == true)) {
        this.allChecked = true;
      } else {
        this.allChecked = false;
      }
    },
    //모달창
    ModalOpen(target) {
      if (target === "template") {
        if (!this.modiAuth) {
          return;
        }
      }
      var dimmed = document.getElementsByClassName("dimmed");
      var wrap = document.getElementsByClassName("popup-wrap");
      var obj = document.getElementsByClassName(target);
      obj[0].classList.add("open");
      dimmed[0].style.display = "block";
      wrap[0].style.display = "block";
      obj[0].style.display = "block";
      this.ModalHeight();
      if (target == "address") {
        this.$refs.sendAddr.fnSendAddrInit();
      }

      if (target == "img_pop") {
        this.updateFileList = Object.assign([], this.fileList);
      }
    },
    ModalClose() {
      var dimmed = document.getElementsByClassName("dimmed");
      var wrap = document.getElementsByClassName("popup-wrap");
      var obj = wrap[0].childElementCount;
      dimmed[0].style.display = "none";
      wrap[0].style.display = "none";
      for (var i = 0; i < obj; i++) {
        var target = document.getElementsByClassName("popup");
        target[i].style.display = "none";
        target[i].classList.remove("open");
      }
    },
    ModalHeight() {
      //팝업 heght가 device height보다 클때
      var winH = window.innerHeight,
        obj = document.querySelector(".popup.open"),
        objH = obj.clientHeight;
      if (objH >= winH) {
        obj.classList.add("over");
      }
    },
    fnSendAddrInfoSet(addrMember) {
      this.recvInfoList.push(...addrMember);
      this.ModalClose();
    },
    fnExcelAddrinfoSet(excelAddrMember) {
      this.recvInfoList.push(...excelAddrMember);
      this.ModalClose();
    },
    //주소록 선택삭제
    fnAddrSelectedDel() {
      if (
        this.recvInfoList.every((e) => {
          return e.selected === false;
        })
      ) {
        // confirm.fnAlert("주소록 선택", "선택된 주소록이 없습니다.", "alert");
        confirm.fnAlert("", "선택된 주소록이 없습니다.", "alert");
      } else {
        eventBus.$on("callbackEventBus", this.fnRemoveSelectedAddress);
        // confirm.fnConfirm("수신번호 선택 삭제", "선택한 수신번호를<br/>삭제하시겠어요?", "del");
        confirm.fnConfirm("", "선택한 수신번호를<br/>삭제하시겠어요?", "del");
      }
    },
    fnRemoveSelectedAddress() {
      this.recvInfoList = this.recvInfoList.filter((item) => {
        return item.selected === false; // 체크 안된 리스트
      });
      this.allChecked = false;
    },
    //주소록 전체삭제
    fnAddrAllDel() {
      if (this.recvInfoList.length > 0) {
        eventBus.$on("callbackEventBus", this.fnRemoveAllAddress);
        // confirm.fnConfirm("수신번호 전체 삭제", "수신번호 전체를<br/>삭제하시겠어요?", "del", "!");
        confirm.fnConfirm("", "수신번호 전체를<br/>삭제하시겠어요?", "del");
      } else {
        // confirm.fnAlert("주소록 선택", "주소록이 존재하지 않습니다.", "alert");
        confirm.fnAlert("", "주소록이 존재하지 않습니다.", "alert");
      }
      this.allChecked = false;
    },
    // 선택된 주소록 삭제
    fnRemoveAllAddress() {
      this.recvInfoList = [
        {
          phone: "",
          /* name: "",
          var1: "",
          var2: "",
          var3: "",
          var4: "",
          status: "", */
          validator: false,
          selected: false,
          selfAdd: true,
        },
      ];
    },
    //미리보기 발신화면 최근작성문자 토글 설정
    mPreEvent(type) {
      this.preEventToggle = type;
    },
    //  대체전송 문자  미리보기
    mPreSmsEvent(type) {
      this.preEventSmsToggle = type;
    },
    // 모바일 미리보기
    mPreAlimOn() {
      const PrevAlim = document.querySelectorAll(".preview_swiper");
      const Alimtalk = document.querySelector(".m_preview_alimtalk .talk_preview");
      const SMStalk = document.querySelector(".m_preview_alimtalk .sms_preview");
      const dimmed = document.querySelector(".dimmed");
      const mAlimPreview = document.querySelector(".m_preview_alimtalk");
      PrevAlim.forEach((item) => (item.style.display = "none"));
      Alimtalk.style.display = "block";
      dimmed.classList.add("open");
      mAlimPreview.classList.add("on");
      if (this.alimSendData.repSendingYN === "true") {
        SMStalk.style.display = "block";
      }
    },
    mHisAlimOn() {
      const PrevAlim = document.querySelectorAll(".preview_swiper");
      const Alimtalk = document.querySelector(".m_preview_alimtalk .talk_history");
      const SMStalk = document.querySelector(".m_preview_alimtalk .sms_history");
      const dimmed = document.querySelector(".dimmed");
      const mAlimPreview = document.querySelector(".m_preview_alimtalk");
      PrevAlim.forEach((item) => (item.style.display = "none"));
      Alimtalk.style.display = "block";
      dimmed.classList.add("open");
      mAlimPreview.classList.add("on");
      if (this.alimSendData.repSendingYN === "true") {
        SMStalk.style.display = "block";
      }
    },
    mPreClose() {
      const mPop = document.querySelector(".m_preview_wrap"),
        dimmed = document.querySelector(".dimmed");
      mPop.classList.remove("on");
      dimmed.classList.remove("open");
    },
    // 특수문자, 변수 slide
    specialOn() {
      var btnSpecial = document.querySelector(".use_txt .btn_special"),
        btnVari = document.querySelector(".use_txt .btn_vari"),
        specailCont = document.querySelector(".use_txt .special_char"),
        variCont = document.querySelector(".use_txt .vari_txt");
      btnSpecial.classList.toggle("on");
      specailCont.classList.toggle("on");
      btnVari.classList.remove("on");
      variCont.classList.remove("on");
    },
    variOn() {
      var btnSpecial = document.querySelector(".use_txt .btn_special"),
        btnVari = document.querySelector(".use_txt .btn_vari"),
        specailCont = document.querySelector(".use_txt .special_char"),
        variCont = document.querySelector(".use_txt .vari_txt");
      btnVari.classList.toggle("on");
      variCont.classList.toggle("on");
      btnSpecial.classList.remove("on");
      specailCont.classList.remove("on");
    },
    // 특수 문자 추가
    insertCharacters(character) {
      const chLen = character.length;
      let txtArea = this.$refs.sendAlimContent;
      let txtValue = txtArea.value;
      this.selectPos = txtArea.selectionStart !== 0 ? txtArea.selectionStart : this.selectPos; // 커서 위치 지정
      let beforeTxt = txtValue.substring(0, this.selectPos); // 기존텍스트 ~ 커서시작점 까지의 문자
      this.selectPos = this.selectPos + chLen;
      let afterTxt = txtValue.substring(this.selectPos - chLen, txtValue.length); // 커서끝지점 ~ 기존텍스트 까지의 문자
      let addTxt = character; // 추가 입력할 텍스트
      txtArea.value = beforeTxt + addTxt + afterTxt;
      this.msg = txtArea.value;
      this.textByte = this.$gfnCommonUtils.getByte(txtArea.value);
    },
    changeTitle(e) {
      this.title = this.$gfnCommonUtils.cutStr(e.target.value, 30);
      e.target.value = this.title;
      this.titleByte = this.$gfnCommonUtils.getByte(this.title);
    },
    changeContent(e) {
      this.msg = e.target.value;
      if(this.msg != ""){
        if(this.ad){
          this.textByte = this.$gfnCommonUtils.getByte(e.target.value)+2;
        }else{
          this.textByte = this.$gfnCommonUtils.getByte(e.target.value);  
        }
      }else{
        this.textByte = this.$gfnCommonUtils.getByte(e.target.value);
      }
    },
    modiChangeTitle(msg) {
      if (msg) {
        this.title = this.$gfnCommonUtils.cutStr(msg, 30);
        this.titleByte = this.$gfnCommonUtils.getByte(this.title);
      }
    },
    modiChangeContent(msg) {
      if (msg) {
        this.msg = msg;
        this.textByte = this.$gfnCommonUtils.getByte(msg);
      }
    },
    fnToastMessage(msg) {
      const content = {
        component: ToastComponentVue,
        props: {
          msg: msg,
        },
      };

      this.$toast(content);
    },
    async fnMessageSend() {
      this.$store.commit("startSpinner");

      // 전송 전 차단발신번호 확인
      let callChk = "N";
      if (this.alimSendData.repSendingYN === "true") {
        const params = {
          sndrno: this.$gfnCommonUtils.hpNumberRemoveDash(this.callbackNumber),
        };

        await messageSendApi.blckCallerId(params).then((response) => {
          if (response.data.success) {
            if (Number(response.data.data) > 0) {
              callChk = "Y";
            }
          }
        });

        if (callChk === "Y") {
          confirm.fnAlert("", "차단된 번호는 발신이 불가합니다.", "alert");
          return;
        }
      }

      if (this.totalCount > 20000) {
        confirm.fnAlert("", "최대 전송할 수 있는 건수가 초과되어 전송이 불가합니다.", "alert");
        return;
      }

      if (!this.modiAuth) {
        confirm.fnAlert("", "예약 알림톡은 전송 5분전에는 취소가 불가합니다.", "alert");
        return false;
      }

      const validator = this.fnValidator();
      if (validator.msg) {
        confirm.fnAlert(validator.title, validator.msg, "alert");
        this.$store.commit("endSpinner");
        return;
      }
      //중복 번호 체크 초기화
      this.hashNum = new Map();
      this.recvInfoList = this.recvInfoList
        .filter((e) => {
          return e.phone !== "";
        })
        .map((e, index) => {
          return {
            ...e,
            status: this.errorCheck(e.phone, { ...e }),
            validator: this.validator,
            index,
          };
        });

      const preAmountParams = {
        channelDiv: "ALIMTALK",
        preSendingCnt: this.recvInfoList.filter((e) => e.validator === true).length, // 예상 전송 건수
        repSendingYN: this.alimSendData.repSendingYN == "true" ? "Y" : "N",
        repSendingChnnel: this.reqProduct, // 대체 전송 채널(SMS, LMS, MMS, ATLK)
      };

      await this.fnGetSendLimit(preAmountParams); // 전송 한도 조회

      if (!this.sendLimit) {
        confirm.fnAlert("", "전송 한도가 초과되어 메시지를 보낼 수 없습니다.", "alert");
        return;
      }
      await this.$refs.sendConfirm.fnGetPreAmount(preAmountParams); // 자식 컴퍼넌트 함수 호출 예상 금액 조회
    },
    // 전송 한도 조회
    async fnGetSendLimit(param) {
      await messageSendApi
        .getSendLimit(param)
        .then((response) => {
          if (response.data.success) {
            if (response.data.data.list[0].limitAvailableYN == "Y") {
              this.sendLimit = false;
            } else {
              this.sendLimit = true;
            }
            this.sendLimit = response.data.data.list[0].limitAvailableYN === "Y" ? true : false;
          } else {
            eventBus.$on("callbackEventBus", this.ModalClose);
            confirm.fnConfirm("", "전송한도금액 가져오기에 실패하였습니다", "cbAlert");
          }
        })
        .catch((error) => {
          console.log(error);
          eventBus.$on("callbackEventBus", this.ModalClose);
          confirm.fnConfirm("", "전송한도금액 가져오기에 실패하였습니다", "cbAlert");
        });
    },
    fnMessageContentCut() {
      let textLength = 0;
      if (this.reqProduct === "SMS") {
        textLength = 90;
      } else {
        textLength = 2000;
      }
      this.msgReadonly = false;
      this.msg = this.$gfnCommonUtils.cutStr(this.$refs.sendAlimContent.value, textLength - (this.ad ? this.adByte : 0));
      this.textByte = this.$gfnCommonUtils.getByte(this.msg);
      this.$refs.sendAlimContent.focus();
    },
    fnReqProductChange() {
      this.reqProductChange = false;
      this.msgReadonly = false;
      this.$refs.sendAlimContent.focus();
    },
    // 이미지 업로드 팝업에서 데이터 가져오기
    setImageInfo(value) {
      this.fileList = value;
    },
    getToday() {
      let nowday = new Date();
      const WEEKDAY = ["일요일", "월요일", "화요일", "수요일", "목요일", "금요일", "토요일"];
      let week = WEEKDAY[nowday.getDay()];
      let month = nowday.getMonth() + 1;
      let day = nowday.getDate();
      this.today = month + "월 " + day + "일 " + week;
    },
    handleRemove(index) {
      let fileSizeCur = 0;
      this.fileList.forEach((file) => {
        fileSizeCur += file.size;
      });
      this.totalImagefileSize = fileSizeCur;
      this.totalImagefileSize = this.totalImagefileSize - this.fileList[index].size;
      this.fileList.splice(index, 1);
    },

    fnSelectRecentSendGroup() {
      const params = {
        userSeq: "",
      };
      messageSendApi.selectRecentSendGroup(params).then((response) => {
        if (response.data.success) {
          this.recentSendGroupList = response.data.data;
        } else {
          // confirm.fnAlert("최신전송그룹", "해당 전송그룹 가져오기에 실패하였습니다.", "alert");
          confirm.fnAlert("", "해당 전송그룹 가져오기에 실패하였습니다.", "alert");
        }
      });
    },
    fnSelectRecentSendRecvInfo(webReqId) {
      const params = {
        webReqId,
      };

      messageSendApi.selectRecentSendRecvInfo(params).then((response) => {
        if (response.data.success) {
          this.recvInfoList = response.data.data.map((e) => {
            //const mergeData = e.mergeData ? JSON.parse(e.mergeData) : "";
            return {
              phone: this.$gfnCommonUtils.hpNumberAddDash(e.phone),
              /* name: mergeData ? mergeData.name : "",
              var1: mergeData ? mergeData.var1 : "",
              var2: mergeData ? mergeData.var2 : "",
              var3: mergeData ? mergeData.var3 : "",
              var4: mergeData ? mergeData.var4 : "", */
              validator: true,
              status: "",
              selected: false,
            };
          });
        } else {
          // confirm.fnAlert("최신전송그룹 리스트", "해당 전송그룹 리스트 가져오기에 실패하였습니다.", "alert");
          confirm.fnAlert("", "해당 전송그룹 리스트 가져오기에 실패하였습니다.", "alert");
        }
      });
    },
    // 미리전송 주소록 가져오기
    preSendInfo(value) {
      this.preRecvInfoList = value;
    },
    //템플릿 가져오기
    fnAlimTemplate(value) {
      this.tmplt = value;
      this.getTempl = true;
      this.alimSendData.tmpltKey = value.tmpltKey;
      this.tmpltSbstByte = this.$gfnCommonUtils.getTextLen(value.tmpltSbst);
      this.etcInfoByte = this.$gfnCommonUtils.getTextLen(value.etcInfo);
      // this.adMsgByte = this.$gfnCommonUtils.getTextLen(value.adMsg);
    },
    // 전송시각 데이터 가져오기
    fnSetSendTime(value) {
      this.sendTimeData = value;
    },
    getAdByte() {
      this.adByte =
        this.enterByte +
        this.$gfnCommonUtils.getByte(this.adBottom) +
        this.$gfnCommonUtils.getByte(this.authNumber);
    },
    // 주소록 직접입력추가
    fnDirectAddAddress() {
      const obj = {
        phone: "",
        /*  name: "",
        var1: "",
        var2: "",
        var3: "",
        var4: "", */
        status: "",
        validator: false,
        selected: false,
        selfAdd: true,
      };
      this.recvInfoList.unshift(obj);
    },

    async fnGetTemplateList(chnlId, kakaoTemplateKey) {
      if (chnlId === "null") {
        this.sendData.tmpList = [];
        this.alimSendData.tmpltKey = "null";
        this.getTempl = false;
        this.tmpltParams = {
          chnlId: "null",
        };
        return;
      } else if (chnlId === "add") {
        this.$router.push({ path: "/sendNumberManage/channelIdManage" });
      }

      this.tmpltParams = {
        chnlId,
        srchTmpltSttusCd: "A",
        tmpltCombo: "Y",
      };

      this.alimSendData.sndrprofKey = this.sendData.channelIdList.filter((e) => {
        return e.chnlId === chnlId;
      })[0].sndrprofKey;

      await templateManageApi.selectAlimTalkTmpltList(this.tmpltParams).then((response) => {
        if (response.data.success) {
          if (response.data.data) {
            this.sendData.tmpList = response.data.data.map((e) => {
              return {
                ...e,
                selected: false,
              };
            });
          } else {
            this.alimSendData.tmpltKey = "null";
          }

          if (kakaoTemplateKey) {
            this.fnTemplateSelected(kakaoTemplateKey);
          }
        } else {
          // confirm.fnAlert("알림톡 템플릿관리", "해당 리스트 가져오기에 실패하였습니다.", "alert");
          confirm.fnAlert("", "해당 리스트 가져오기에 실패하였습니다.", "alert");
        }
      });
    },
    fnTemplateSelected(tmpltKey) {
      if (tmpltKey === "null") {
        this.getTempl = false;
        this.tmplt.emphsSubtle = "";
        this.tmplt.emphsTitle = "";
        this.tmplt.tmpltSbst = "";
        this.tmplt.tmpltTpCd = "BA";
        this.sendData.buttonList = [];
        return;
      } else if (tmpltKey === "add") {
        this.$router.push({ path: "/templeteManage" });
      } else {
        this.tmplt = this.sendData.tmpList.filter((tmp) => tmp.tmpltKey === tmpltKey)[0];
        this.alimSendData.tmpltName = this.tmplt.tmpltNm;
        this.getTempl = true;
        setTimeout(() => {
          this.tmpltSbstByte = this.$gfnCommonUtils.getTextLen(this.$refs.tmpltSbst.value);
          this.etcInfoByte = this.$gfnCommonUtils.getTextLen(this.$refs.etcInfo.value);
          // this.adMsgByte = this.$gfnCommonUtils.getTextLen(this.$refs.adMsg.value);
        }, 1);
        this.sendData.buttonList = this.tmplt.tmpltJsonDat ? JSON.parse(this.tmplt.tmpltJsonDat) : "";
        this.getTempl = true;
      }
    },
    //발신번호 목록 조회
    fnCallerIdList() {
      const params = {
        sttusCd: "02", // 승인된 발신번호만 가져오기
        useYn: "Y",
        sndrnoCombo: "Y",
      };

      messageSendApi
        .callerIdList(params)
        .then((response) => {
          if (response.data.success) {
            this.sendNumberList = response.data.data;
          } else {
            // confirm.fnAlert("알림", "발신번호 가져오기가 실패되었습니다.", "alert");
            confirm.fnAlert("", "발신번호 가져오기가 실패되었습니다.", "alert");
          }
        })
        .catch((error) => {
          // confirm.fnAlert("알림", "발신번호 에러가 발생되었습니다. <br/>" + error, "alert");
          confirm.fnAlert("", "발신번호 에러가 발생되었습니다. <br/>" + error, "alert");
        });
    },
    // 휴대폰 번호 유효성 체크 항목
    errorCheck(phoneNumber, data) {
      const patten_phone = /^(01[016789]{1}|070)-?[0-9]{3,4}-?[0-9]{4}$/; //핸드폰 번호, 하이픈 상관 X
      const pattern_eng = /[a-zA-Z]/; // 문자
      const pattern_spc = /[~!@#$%^&*()_+|<>?:{}]/; // 특수문자
      const pattern_kor = /[ㄱ-ㅎ|ㅏ-ㅣ|가-힣]/; // 한글체크
      let errorText = "";

      const rejectPhone = this.rejectPhoneList.some((e) => {
        return this.$gfnCommonUtils.hpNumberRemoveDash(e.rcvblckno) === this.$gfnCommonUtils.hpNumberRemoveDash(phoneNumber);
      });

      if (rejectPhone) {
        errorText = "080 수신거부번호";
        this.validator = false;
      } else if (phoneNumber == "" || phoneNumber == undefined) {
        errorText = "번호없음";
        this.validator = false;
        return errorText;
      } else if (pattern_eng.test(phoneNumber) || pattern_spc.test(phoneNumber) || pattern_kor.test(phoneNumber)) {
        errorText = "번호오류";
        this.validator = false;
        return errorText;
      } else if (!patten_phone.test(phoneNumber)) {
        errorText = "번호오류";
        this.validator = false;
        return errorText;
      } else if (this.hashNum.has(phoneNumber.replace(/-/g, ""))) {
        errorText = "번호중복";
        this.validator = false;
        return errorText;
      } else {
        // 자동 변경 정보 체크
        let acText = [];
        this.autoMsg.forEach((element) => {
          if (!data[element]) {
            acText.push(element);
          }
        });

        if (!this.$gfnCommonUtils.isEmpty(acText)) {
          errorText = `자동변경정보 누락 (${acText.join(", ")})`;
          this.validator = false;
          return errorText;
        }

        // 자동변경정보를 통한 byte 계산
        // const pattern = /#\{([^#{}]*)\}/g;
        let autoMsgByte = 0;
        let autoMsgText = "";

        //알림톡 byte 체크
        if (this.alimSendData) {
          autoMsgText = this.tmplt.tmpltSbst.replace(/((#{).*?(\}))/g, (match) => {
            const matches = /#\{([^#{}]*)\}/g.exec(match);
            return data[matches[1]];
          });
          autoMsgByte = this.$gfnCommonUtils.getTextLen(autoMsgText);
          if (autoMsgByte > 1000) {
            errorText = `자동 변경 정보 삽입으로<br/>1000자 초과(${autoMsgByte}자)`;
            this.validator = false;
            return errorText;
          }
        }

        autoMsgText = this.msg.replace(/((#{).*?(\}))/g, (match) => {
          const matches = /#\{([^#{}]*)\}/g.exec(match);
          return data[matches[1]];
        });

        if (this.ad) {
          autoMsgByte = this.$gfnCommonUtils.getByte(autoMsgText) + this.adByte;
        } else {
          autoMsgByte = this.$gfnCommonUtils.getByte(autoMsgText);
        }

        // 문자타입이 SMS이고 90Byte 보다 클 경우 에러 처리
        if (this.reqProduct === "SMS" && autoMsgByte > 90) {
          errorText = `자동 변경 정보 삽입으로<br/>90Byte 초과(${autoMsgByte}Byte)`;
          this.validator = false;
          return errorText;
        }

        // 문자타입이 LMS , MMS 이고 2000byte 보다 클 경우 에러 처리
        if ((this.reqProduct === "LMS" || this.reqProduct === "MMS") && autoMsgByte > 2000) {
          errorText = `자동 변경 정보 삽입으로<br/>2000Byte 초과(${autoMsgByte}Byte)`;
          this.validator = false;
          return errorText;
        }

        errorText = "정상";
        this.validator = true;
        this.hashNum.set(phoneNumber.replace(/-/g, ""), "phone");
      }

      return errorText;
    },
    fnValidator() {
      let title = "";
      let msg = "";
      // 월말/월초 23:30~00:30 사이 전송 시 F-1 안내 얼랏
      let nDate = new Date();
      const today = Number(this.getCurrentDate());
      nDate.setDate(1);
      const monthStart = this.getDateFormat(nDate, "s");
      nDate.setMonth(nDate.getMonth() + 1);
      nDate.setDate(nDate.getDate() - 1);
      const monthEnd = this.getDateFormat(nDate, "e");

      // 현재 시간 5분 이후로 예약전송이 가능 처리
      let reqDtYn = false;
      if (this.sendTimeData.rsvtType === "N") {
        reqDtYn = true;
      } else if (this.sendTimeData.rsvtType === "R") {
        const reqDt = new Date(this.sendTimeData.reqDt + " " + this.sendTimeData.rsvCfg3.substring(0, 2) + ":" + this.sendTimeData.rsvCfg3.substring(2, 4) + ":00");
        let rToday = new Date();
        rToday.setMinutes(rToday.getMinutes() + 5);
        if (reqDt > rToday) {
          reqDtYn = true;
        }
      } else if (this.sendTimeData.rsvtType === "P") {
        const rsvDt = new Date(this.sendTimeData.rsvStart + " " + this.sendTimeData.rsvCfg3.substring(0, 2) + ":" + this.sendTimeData.rsvCfg3.substring(2, 4) + ":00");
        let pToday = new Date();
        pToday.setMinutes(pToday.getMinutes() + 5);
        if (rsvDt > pToday) {
          reqDtYn = true;
        }
      }

      //반복예약설정시 현재날짜보다 6개월 이상 일 경우 전송 안되도록 설정
      let AfterSixMonthDateYN = true;
      if (this.sendTimeData.rsvtType === "P") {
        const startDate = new Date(this.sendTimeData.rsvStart);
        const endDate = new Date(this.sendTimeData.rsvEnd);
        const AfterSixMonthDate = new Date(startDate.setMonth(startDate.getMonth() + 6));
        if (endDate > AfterSixMonthDate) {
          AfterSixMonthDateYN = false;
        }
      }
      /////////////////////////

      if (this.alimSendData.chnlId == "null") {
        // title = "채널ID 확인";
        title = "";
        msg = "[채널ID]를 선택해주세요.";
      } else if (this.alimSendData.tmpltKey == "null" || this.alimSendData.tmpltKey == "" || !this.alimSendData.tmpltKey) {
        // title = "템플릿 확인";
        title = "";
        msg = "[템플릿]를 선택해주세요.";
      } else if (this.totalCount < 1) {
        // title = "수신번호 확인";
        title = "";
        msg = "[수신번호]를 작성해 주세요.";
      } else if (!AfterSixMonthDateYN) {
        // title = "예약전송 시간 선택";
        title = "";
        msg = "※ 반복예약 시작 날짜부터 최대 6개월까지<br/> 날짜만 예약전송이 가능합니다.";
      } else if (!reqDtYn) {
        // title = "예약전송 시간 선택";
        title = "";
        msg = "※ 현재 시간을 기준으로 5분<br/> 이후부터 예약전송이 가능합니다.";
      } else if (this.sendTimeData.rsvtType === "P") {
        if (!this.sendTimeData.rsvEnd) {
          // title = "반복예약전송 확인";
          title = "";
          msg = "[반복예약전송 날짜]를 선택해 주세요.";
        }
        if (this.sendTimeData.rsvCfg1 === "D") {
          if (!this.sendTimeData.rsvCfg2) {
            // title = "반복예약전송 확인";
            title = "";
            msg = "[매일 반복설정]을 작성해 주세요.";
          }
        } else if (this.sendTimeData.rsvCfg1 === "W") {
          const mada = this.sendTimeData.rsvCfg2 && this.sendTimeData.rsvCfg2.split("|");
          const rsvCfg2dayWeekLength = this.sendTimeData.rsvCfg2dayWeek.length;
          if (!mada || !mada[0] || rsvCfg2dayWeekLength === 0) {
            // title = "반복예약전송 확인";
            title = "";
            msg = "[매주 반복설정]을 작성해 주세요.";
          }
        } else if (this.sendTimeData.rsvCfg1 === "M") {
          const mada = this.sendTimeData.rsvCfg2 && this.sendTimeData.rsvCfg2.split("|");
          if (!mada || !mada[0] || !mada[1]) {
            // title = "반복예약전송 확인";
            title = "";
            msg = "[매월 반복설정]을 작성해 주세요.";
          }
        }
      }

      if (this.alimSendData.repSendingYN == "true") {
        if (!this.msg) {
          // title = "내용 확인";
          title = "";
          msg = "[내용]을 작성해 주세요.";
        } else if (this.callbackNumber === "null" || this.callbackNumber === "add") {
          // title = "발신번호 확인";
          title = "";
          msg = "[발신번호]를 선택해 주세요.";
        }
      }

      if (this.subSttusCd === "04") {
        title = "";
        msg = "요금을 2개월 이상 내지 않아 메시지를 보낼 수 없습니다.<br/>요금을 납부한 후 이용해 주세요.";
      }

      if (today < monthStart || today > monthEnd) {
        // title = "지금은 서비스 점검 시간입니다.";
        title = "";
        msg = "지금은 서비스 점검 시간입니다.<br/>잠시 후 다시 이용해 주세요. 이용에 불편을 드려 죄송합니다.<br/>- 서비스 점검 시간 : 오후 11시 30분 ~ 밤 12시 30분";
      }
      return { title, msg };
    },
    getDateFormat(date, type) {
      let newDate = new Date();
      if (date != null) newDate = date;

      let yy = String(newDate.getFullYear());
      let mm = newDate.getMonth() + 1;
      if (mm < 10) mm = "0" + mm;

      let dd = newDate.getDate();
      if (dd < 10) dd = "0" + dd;

      let time = "000000";
      if (type === "s") {
        time = "003000";
      } else {
        time = "233000";
      }
      return Number(yy + mm + "" + dd + time);
    },
    getCurrentDate() {
      let date = new Date();
      let year = date.getFullYear().toString();

      let month = date.getMonth() + 1;
      month = month < 10 ? "0" + month.toString() : month.toString();

      let day = date.getDate();
      day = day < 10 ? "0" + day.toString() : day.toString();

      let hour = date.getHours();
      hour = hour < 10 ? "0" + hour.toString() : hour.toString();

      let minites = date.getMinutes();
      minites = minites < 10 ? "0" + minites.toString() : minites.toString();

      let seconds = date.getSeconds();
      seconds = seconds < 10 ? "0" + seconds.toString() : seconds.toString();

      return year + month + day + hour + minites + seconds;
    },
    fnInit() {
      this.recvInfoList = [
        {
          phone: "",
          name: "",
          var1: "",
          var2: "",
          var3: "",
          var4: "",
          status: "",
          validator: false,
          selected: false,
          selfAdd: true,
        },
      ];
      this.alimSendData.chnlId = "null";
      this.alimSendData.tmpltKey = "null";
      this.recentSendGroup = "null";
      this.tmplt.emphsSubtle = "";
      this.tmplt.emphsTitle = "";
      this.tmplt.tmpltSbst = "";
      this.tmplt.tmpltTpCd = "BA";
      this.sendData.buttonList = [];
      this.alimSendData.repSendingYN = "false";
      this.msg = "";
      this.title = "";
      this.fileList = [];
      this.callbackNumber = "null";
      this.getTempl = false;
    },
    fnSuccess(value) {
      if (value) {
        //this.fnInit();
        this.$emit("chargeInfoCall");
      }
    },
    fnPhoneDash(phoneNumber, index) {
      this.recvInfoList[index].phone = this.$gfnCommonUtils.hpNumberAddDash(this.$gfnCommonUtils.cutStr(phoneNumber.target.value.replace(/[^-0-9]/g, ""), 13));
      const pattern_notNumber = /[^-0-9]/g; // 숫자가 아닌것 체크
      // 숫자 외 입력시
      if (pattern_notNumber.test(phoneNumber.target.value)) {
        this.fnToastMessage("숫자만 입력해 주세요.");
        phoneNumber.target.value = this.recvInfoList[index].phone;
      }
    },
    // 최근작성알림톡 가져오기
    fnSelectrecentSendAlimList() {
      const params = {
        recentOffset: 10,
        chStr: "KKO_NOTI",
      };
      messageSendApi.selectRecentSendMessageList(params).then((response) => {
        if (response.data.success) {
          this.recentSendAlimList = response.data.data.map((item) => {
            item.regDt = item.regDt.substring(0, 10) + "(" + item.regDt.substring(11, 16) + ")";
            return {
              rsvId: item.rsvId,
              regDt: item.regDt,
              ad: item.msgInfo ? JSON.parse(item.msgInfo).ad : "",
              adBottom: item.msgInfo ? JSON.parse(item.msgInfo).adBottom : "",
              authNumber: item.msgInfo ? JSON.parse(item.msgInfo).authNumber : "",
              callbackNumber: item.msgInfo ? JSON.parse(item.msgInfo).callbackNumber : "null",
              content: item.msgInfo ? JSON.parse(item.msgInfo).content : "",
              dayOfTheWeek: item.msgInfo ? JSON.parse(item.msgInfo).dayOfTheWeek : "",
              dayOfTheWeekNum: item.msgInfo ? JSON.parse(item.msgInfo).dayOfTheWeekNum : "",
              dayOfTheWeekStr: item.msgInfo ? JSON.parse(item.msgInfo).dayOfTheWeekStr : "",
              fileList: item.msgInfo ? JSON.parse(item.msgInfo).fileList : "",
              fileName: item.msgInfo ? JSON.parse(item.msgInfo).fileName : "",
              legacyFiles: item.legacyFiles ? item.legacyFiles : "",
              mada: item.msgInfo ? JSON.parse(item.msgInfo).mada : "",
              monDiv: item.msgInfo ? JSON.parse(item.msgInfo).monDiv : "",
              msg: item.msgInfo ? JSON.parse(item.msgInfo).msg : "",
              reqProduct: item.msgInfo ? JSON.parse(item.msgInfo).reqProduct : "",
              rsvCfg1: item.msgInfo ? JSON.parse(item.msgInfo).rsvCfg1 : "",
              rsvCfg2dayWeek: item.msgInfo ? JSON.parse(item.msgInfo).rsvCfg2dayWeek : "",
              title: item.msgInfo ? JSON.parse(item.msgInfo).title : "",
              tomada: JSON.parse(item.msgInfo).tomada,
              trafficType: item.msgInfo ? JSON.parse(item.msgInfo).trafficType : "",
              tmpltName: item.msgInfo ? JSON.parse(item.msgInfo).tmpltName : "",
              tmpltKey: item.msgInfo ? JSON.parse(item.msgInfo).kakaoTemplateKey : "",
              chnlId: item.msgInfo ? JSON.parse(item.msgInfo).chnlId : "",
              repSendingYN: item.msgInfo ? JSON.parse(item.msgInfo).repSendingYN : "",
            };
          });
        } else {
          //confirm.fnAlert("최근작성문자", "최근작성문자 불러오기에 실패하였습니다.", "alert");
        }
      });
    },
    // 최근작성문자 가져오기
    fnSelectRecentSendMessageList() {
      const params = {
        recentOffset: 10,
      };
      messageSendApi.selectRecentSendMessageList(params).then((response) => {
        if (response.data.success) {
          this.recentSendMessageList = response.data.data
            .filter((item) => {
              return JSON.parse(item.msgInfo).reqProduct !== "KKO_NOTI";
            })
            .map((item) => {
              item.regDt = item.regDt.substring(0, 10) + "(" + item.regDt.substring(11, 16) + ")";
              return {
                rsvId: item.rsvId,
                regDt: item.regDt,
                ad: item.msgInfo ? JSON.parse(item.msgInfo).ad : "",
                adBottom: item.msgInfo ? JSON.parse(item.msgInfo).adBottom : "",
                authNumber: item.msgInfo ? JSON.parse(item.msgInfo).authNumber : "",
                callbackNumber: item.msgInfo ? JSON.parse(item.msgInfo).callbackNumber : "null",
                content: item.msgInfo ? JSON.parse(item.msgInfo).content : "",
                dayOfTheWeek: item.msgInfo ? JSON.parse(item.msgInfo).dayOfTheWeek : "",
                dayOfTheWeekNum: item.msgInfo ? JSON.parse(item.msgInfo).dayOfTheWeekNum : "",
                dayOfTheWeekStr: item.msgInfo ? JSON.parse(item.msgInfo).dayOfTheWeekStr : "",
                fileList: item.msgInfo ? JSON.parse(item.msgInfo).fileList : "",
                fileName: item.msgInfo ? JSON.parse(item.msgInfo).fileName : "",
                legacyFiles: item.legacyFiles ? item.legacyFiles : "",
                mada: item.msgInfo ? JSON.parse(item.msgInfo).mada : "",
                monDiv: item.msgInfo ? JSON.parse(item.msgInfo).monDiv : "",
                msg: item.msgInfo ? JSON.parse(item.msgInfo).msg : "",
                reqProduct: item.msgInfo ? JSON.parse(item.msgInfo).reqProduct : "",
                rsvCfg1: item.msgInfo ? JSON.parse(item.msgInfo).rsvCfg1 : "",
                rsvCfg2dayWeek: item.msgInfo ? JSON.parse(item.msgInfo).rsvCfg2dayWeek : "",
                title: item.msgInfo ? JSON.parse(item.msgInfo).title : "",
                tomada: JSON.parse(item.msgInfo).tomada,
                trafficType: item.msgInfo ? JSON.parse(item.msgInfo).trafficType : "",
              };
            });
        }
      });
    },
    // 최근작성알림톡 가져오기
    fnRecentSendAlimList(msg) {
      if (msg.repSendingYN === "Y") {
        this.alimSendData.repSendingYN = "true";
      } else {
        this.alimSendData.repSendingYN = "false";
      }

      this.alimSendData.chnlId = msg.chnlId;
      this.fnGetTemplateList(this.alimSendData.chnlId, msg.tmpltKey);
      this.alimSendData.tmpltKey = msg.tmpltKey;

      this.title = this.$gfnCommonUtils.cutStr(msg.title ? msg.title : "", 30);
      if(this.ad){
        if(msg.ad == "true"){
          this.msg = msg.content ? msg.content.replaceAll("\r\n", "\n") : "";
        }else{
          this.msg = msg.content ? "(광고)"+msg.content.replaceAll("\r\n", "\n") : "";
          this.ad = false;
        }
      }else{
        if(msg.ad == "true"){
          this.msg = msg.content ? msg.content.replace("(광고)", "").replaceAll("\r\n", "\n") : "";
          this.ad = true;
        }else{
          this.msg = msg.content ? msg.content.replaceAll("\r\n", "\n") : "";
        }
      } 

      const fileName = msg.fileName ? msg.fileName.split(",") : "";
      this.fileList = msg.legacyFiles
        ? msg.legacyFiles
            .split(",")
            .filter((file) => file !== "")
            .map((file, index) => {
              return {
                name: fileName !== "" ? fileName[index] : file,
                src: `/api/v1/fo/preview/${file}`,
                fileName: file,
              };
            })
        : [];

      // 바이트 계산
      this.titleByte = this.$gfnCommonUtils.getByte(this.title);
      this.textByte = this.$gfnCommonUtils.getByte(this.msg);
      this.adByte =
        this.enterByte +
        this.$gfnCommonUtils.getByte(this.adBottom) +
        this.$gfnCommonUtils.getByte(this.authNumber);
      const btnSent = document.getElementById("btn_sent");
      //this.mPreEvent(true);
      btnSent.checked = true;
      const altCon = document.querySelector(".use_txt");
      altCon.style.display = "block";
    },
    // 최근작성문자 가져오기
    fnRecentSendMessageList(msg) {
      this.title = this.$gfnCommonUtils.cutStr(msg.title ? msg.title : "", 30);
      
      if(this.ad){
        if(msg.ad == "true"){
          this.msg = msg.content ? msg.content.replaceAll("\r\n", "\n") : "";
        }else{
          this.msg = msg.content ? "(광고)"+msg.content.replaceAll("\r\n", "\n") : "";
          this.ad = false;
        }
      }else{
        if(msg.ad == "true"){
          this.msg = msg.content ? msg.content.replace("(광고)", "").replaceAll("\r\n", "\n") : "";
          this.ad = true;
        }else{
          this.msg = msg.content ? msg.content.replaceAll("\r\n", "\n") : "";
        }
      } 
      
      const fileName = msg.fileName ? msg.fileName.split(",") : "";
      this.fileList = msg.legacyFiles
        ? msg.legacyFiles
            .split(",")
            .filter((file) => file !== "")
            .map((file, index) => {
              return {
                name: fileName !== "" ? fileName[index] : file,
                src: `/api/v1/fo/preview/${file}`,
                fileName: file,
              };
            })
        : [];
      // 바이트 계산
      this.titleByte = this.$gfnCommonUtils.getByte(this.title);
      this.textByte = this.$gfnCommonUtils.getByte(this.msg) + 2;
      this.adByte =
        this.enterByte +
        this.$gfnCommonUtils.getByte(this.adBottom) +
        this.$gfnCommonUtils.getByte(this.authNumber);
      const btnSent = document.getElementById("btn_sent");
      //this.mPreEvent(true);
      btnSent.checked = true;
    },
    fnRejectPhoneList() {
      const params = {
        userTpCd: "",
      };
      messageSendApi.rejectPhoneList(params).then((response) => {
        if (response.data.success) {
          const getData = response.data.data;
          if (Object.keys(getData).includes("rejectPhoneList")) {
            if (!this.$gfnCommonUtils.isEmpty(getData.rejectPhoneList)) {
              this.rejectPhoneList = getData.rejectPhoneList;
            } else {
              this.rejectPhoneList = [];
            }
          } else {
            this.rejectPhoneList = [];
          }
        } else {
          confirm.fnAlert("", "오류가 발생했습니다.", "alert");
        }
      });
    },
    /////////////////////////////////// 파일 첨부 ////////////////////////////////////////
    onFileChange(event) {
      const files = event.target.files || event.dataTransfer.files;
      if (!files.length) return;
      if (files.length > 1) {
        confirm.fnAlert(
          // "이미지 첨부 기준 안내",
          "",
          "<li>이미지는 1장까지 첨부할 수 있습니다.</li>",
          "info"
        );
        this.imageAddTitle = "이미지는 1장까지 첨부할 수 있습니다.";
        //confirm.fnAlert("알림", "첨부파일은 최대 3개까지 가능합니다.", "alert");
        return;
      } else if (this.updateFileList.length + files.length > 3) {
        confirm.fnAlert(
          // "이미지 첨부 기준 안내",
          "",
          "<li>이미지는 1장까지 첨부할 수 있습니다.</li>",
          "info"
        );
        this.imageAddTitle = "이미지는 1장까지 첨부할 수 있습니다.";
        // confirm.fnAlert("알림", "첨부파일은 최대 3개까지 가능합니다.", "alert");
        return;
      }

      this.addFiles(files);
    },
    async addFiles(files) {
      for (let i = 0; i < files.length; i++) {
        const src = await this.readFiles(files[i]);
        files[i].src = src;

        if (!(files[i].name.toLowerCase().indexOf("jpg") > -1 || files[i].name.toLowerCase().indexOf("jpeg") > -1)) {
          confirm.fnAlert("", "<li>jpg파일 형식만 첨부할 수 있습니다.</li>", "info");
          this.imageAddTitle = "jpg파일 형식만 첨부할 수 있습니다.";
          continue;
        } else if (files[i].size > 500000) {
          confirm.fnAlert(
            // "이미지 첨부 기준 안내",
            "",
            "<li>전체 크기 합계가 500KB 이하여야 합니다.</li>",
            "info"
          );
          this.imageAddTitle = "전체 크기 합계가 500KB 이하여야 합니다.";
          //confirm.fnAlert("이미지 사이즈 초과", "300KB이하 이미지만 등록 가능합니다.", "alert");
          continue;
        } else if (files[i].size + this.totalFileSize > 500000) {
          confirm.fnAlert(
            // "이미지 첨부 기준 안내",
            "",
            "<li>전체 크기 합계가 500KB 이하여야 합니다.</li>",
            "info"
          );
          this.imageAddTitle = "전체 크기 합계가 500KB 이하여야 합니다.";
          //confirm.fnAlert("이미지 사이즈 초과", "전체 합계 300KB이하 이미지만 등록 가능합니다.", "alert");
          continue;
        } else if (this.getImageSize(src) === false) {
          confirm.fnAlert(
            // "이미지 첨부 기준 안내",
            "",
            "<li>1,500(가로)x1,440(세로)px 이하 크기만 첨부할 수 있습니다.</li>",
            "info"
          );
          this.imageAddTitle = "1,500(가로)x1,440(세로)px 이하 크기만 첨부할 수 있습니다.";
          //confirm.fnAlert("이미지 크기 초과", "1500 * 1440 이하 이미지만 등록 가능합니다.", "alert");
          continue;
        } else {
          files[i].status = "update";
          files[i].index = this.updateFileList.length;
          this.updateFileList.push(files[i]);
          confirm.fnAlert("", "이미지가 정상적으로 추가 되었습니다.", "alert");
          this.imageAddTitle = "";
        }
      }
      this.$refs.imageUploader.value = ""; // 이미지 중복 가능하도록 input 초기화
    },
    // FileReader를 통해 파일을 읽어 thumbnail 영역의 src 값으로 셋팅
    async readFiles(files) {
      return new Promise((resolve) => {
        const reader = new FileReader();
        reader.onload = async (e) => {
          resolve(e.target.result);
        };
        reader.readAsDataURL(files);
      });
    },
    getImageSize(src) {
      const img = new Image();
      let _width = 0;
      let _height = 0;
      img.src = src;

      img.onload = function () {
        _width = img.width;
        _height = img.height;
        if (_width > 1500 || _height > 1440) {
          return false;
        } else {
          return true;
        }
      };
    },
    fnSendData() {
      this.fileList = Object.assign([], this.updateFileList);
      this.fileModalClose();
      this.$forceUpdate();
    },
    fileModalClose() {
      var dimmed = document.getElementsByClassName("dimmed");
      var wrap = document.getElementsByClassName("popup-wrap");
      var obj = wrap[0].childElementCount;
      dimmed[0].style.display = "none";
      wrap[0].style.display = "none";
      for (var i = 0; i < obj; i++) {
        var target = document.getElementsByClassName("popup");
        target[i].style.display = "none";
        target[i].classList.remove("open");
      }
      this.updateFileList = [];
    },
    popupHandleRemove(index) {
      this.updateFileList.splice(index, 1);
    },
    /*handleScroll(e) {
      const chooseListThWrap = e.target.parentNode.children[0];
      chooseListThWrap.scrollLeft = e.target.scrollLeft;
    },
    handleThScroll(e) {
      const chooseListWrap = e.target.parentNode.children[1];
      chooseListWrap.scrollLeft = e.target.scrollLeft;
    },*/
    resizeText(e) {
      e.target.style.height = `${e.target.scrollHeight}px`;
    },
    fnGoList() {
      eventBus.$on("callbackEventBus", () => {
        this.$router.push({ path: "/sendManage/rsvSendMessage" });
      });
      confirm.fnConfirm("알림", "페이지 이동시 모든 내용이 삭제됩니다.", "confrim");
    },
    fnRepSendingData(type) {
      if (type === "true") {
        this.msg = this.impMsg ? this.impMsg : this.tmplt.tmpltSbst;

        // 작성하는 텍스트를 자동 변수로 인식하는 기능 추가
        const pattern = /#\{([^#{}]*)\}/g; // 특정 문자 사이 추출 (특정문자 제외)
        //const pattern2 = /((#{).*?(\}))/g; // 특정 문자 사이 추출 (특정문자 포함)
        let autoMsgList = [];
        let sumMsg = this.tmplt.tmpltSbst + " " + this.msg;
        if (!this.$gfnCommonUtils.isEmpty(sumMsg.match(pattern))) {
          const matches = sumMsg.match(pattern);
          autoMsgList = matches.map(match => match.substring(2, match.length - 1)).filter((msg) => {
            return !this.$gfnCommonUtils.isEmpty(msg);
          });

          // 중복 제거
          this.autoMsg = autoMsgList.filter((e, index) => {
            return autoMsgList.indexOf(e) === index;
          });
        }
      }

      if (type === "false") {
        this.impMsg = this.msg;
        this.msg = "";
        // 작성하는 텍스트를 자동 변수로 인식하는 기능 추가
        const pattern = /#\{([^#{}]*)\}/g; // 특정 문자 사이 추출 (특정문자 제외)
        //const pattern2 = /((#{).*?(\}))/g; // 특정 문자 사이 추출 (특정문자 포함)
        let autoMsgList = [];
        let sumMsg = this.tmplt.tmpltSbst;
        if (!this.$gfnCommonUtils.isEmpty(sumMsg.match(pattern))) {
          const matches = sumMsg.match(pattern);
          autoMsgList = matches.map(match => match.substring(2, match.length - 1)).filter((msg) => {
            return !this.$gfnCommonUtils.isEmpty(msg);
          });

          // 중복 제거
          this.autoMsg = autoMsgList.filter((e, index) => {
            return autoMsgList.indexOf(e) === index;
          });
        }
      }
    },
    tableWidthCalc() {
      let myRef = [];

      this.autoMsg.forEach((e, index) => {
        if (index === 0) {
          myRef.push("td_name");
        } else {
          myRef.push("td_" + index);
        }
      });

      setTimeout(() => {
        myRef.forEach((e) => {
          if (this.autoMsg.length === 1) {
            document.getElementById(e).style.width = "47%";
          } else if (this.autoMsg.length === 2) {
            document.getElementById(e).style.width = "23.5%";
          } else if (this.autoMsg.length === 3) {
            document.getElementById(e).style.width = "16%";
          } else {
            document.getElementById(e).style.width = "120px";
          }
        });
      }, 1);
    },
  },
};
</script>
