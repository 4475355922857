import httpClient from "@/common/http-client";

const selectPayUserInfo = () => {
  return httpClient.post("/api/payment/selectPayUserInfo", null, { headers: { "show-layer": "yse"} });
};

const payHist = (params) => {
  return httpClient.post("/api/payment/payHist", params, { headers: { "show-layer": "yse"} });
};

export default {
  selectPayUserInfo,
  payHist
};
