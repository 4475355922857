import httpClient from "@/common/http-client";

// selectCallbackList 삭제하시고 여기서부터 작성하면 됩니다.
const getSelectNoticeList = (params) => {
  return httpClient.post("/api/notice/selectNoticeList", params, { headers: { "show-layer": "Yes"} });
};

const getSelectNoticeInfo = (params) => {
  return httpClient.post("/api/notice/selectNoticeInfo", params, { headers: { "show-layer": "Yes"} });
};

const getNoticeCategoryList = (params) => {
  return httpClient.post("/api/common/selectEzCd", params, { headers: { "show-layer": "Yes"} });
}

const getSelectFaqList = (params) => {
  return httpClient.post("/api/faq/selectFaqList", params, { header: { " show-layer": "Yes"} });
}

const getFaqCategoryList = (params) => {
  return httpClient.post("/api/common/selectEzCd", params, { header: { " show-layer": "Yes"} });
}

// 사용자 정보 조회
const selectUserInfo = (params) => {
  return httpClient.post("/api/consult/consultMemberInfo", params, { headers: { "show-layer": "yse"} });
};

const sendInquiry = (params) => {
  return httpClient.post("/api/consult/insertConsult", params, { headers: { "show-layer": "Yes", "Content-Type": "multipart/form-data" } });
};
const getPopupInfo = (params) => {
  return httpClient.post("/api/notice/selectPopup", params, { headers: { "show-layer": "Yes", activity: "READ" } });
};

export default {
  getSelectNoticeList,
  getSelectNoticeInfo,
  getNoticeCategoryList,
  getSelectFaqList,
  getFaqCategoryList,
  selectUserInfo,
  sendInquiry,
  getPopupInfo,
};
