import sitemap from "../views/sitemap";

export default [
  {
    path: "/sitemap/view",
    name: "sitemap",
    component: sitemap,
    meta: { public: true },
  },
];
